import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Input, Row, Col, Button, Card, Avatar, Divider } from 'antd';
import { PlayCircleOutlined, UserOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { UserProfile, UpdateProfile, Updatepassword } from './../../src/Store/Action/Profile';
import { toast } from 'react-toastify';
const _ = require("lodash");


const mapStateToProps = (state) => ({
    profiles: state.Profile.profile,

});
const MyProfile = ({
    UserProfile,
    UpdateProfile,
    Updatepassword,
    profiles: { profiledata }

}) => {
    console.log(profiledata,'profiledata')
    const { TextArea } = Input;
    const location = useLocation();
    const validator = useRef(new SimpleReactValidator());
    const formvalidator = useRef(new SimpleReactValidator());
    const [changepassword, Setchangepassword] = useState();
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    // const [passwordcard, setPasswordcard] = useState(false);
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue');
    const paramid = location.pathname;
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const login = callername.login_response.menu['My Accounts'];
    const [password, setPassword] = useState({
        current_password: '',
        new_password: '',
        confirm_password: '',
    })
    const [profile, setProfile] = useState({
        email_id: '',
        emp_contact_country_code: '',
        emp_contact_code: '',
        emp_contact_no: '',
        mobile_no_country_code: '',
        mobile_no: '',
        address: ''
    })
    const [formdata, Setformdata] = useState({
        form_url: '',
        form_key: ''
    });
    const [number, Setnumber] = useState({
        emp_contact_country_code: '',
        emp_contact_code: '',
        emp_contact_no: '',
        mobile_no_country_code: '',
        mobile_no: '',
        address: '',
        email: ''

    });

    console.log(number,'numbernumber')

    // const [Entity, setEntity] = useState("All Legal Entity")
    const onUserDetailOnChange = (e) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value
        })
        Setnumber({
            ...number,
            [e.target.name]: e.target.value
        })

    }
    // const passwordmodel = (e) => {
    //     setPasswordcard(true)

    // }

    useEffect(() => {
        if (isAuth && formdata.form_key) {
            console.log(formdata.form_key, 'form_key')
            UserProfile({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetUserProfile",
                            {}
                        ]
                    }
                ],
                paramid: paramid,
                formkey: formdata.form_key
            })
        }
    }, [isAuth, formdata.form_key])

    console.log(profiledata, 'profiledata');

    useEffect(() => {
        if (profiledata && profiledata.user_profile && profiledata.user_profile.length > 0) {
            console.log(profiledata,'profiledata')
            let myArray = [];
            let temparr = [];
            if (profiledata.user_profile[0].mob_no != null) {
                let text = profiledata.user_profile[0].mob_no;
                console.log(text.split('-'), 'text');
                myArray = text.split('-');
            }
            if (profiledata.user_profile[0].con_no != null) {
                let text1 = profiledata.user_profile[0].con_no;
                temparr = text1.split('-');
            }

            Setnumber({
                ...number,
                emp_contact_country_code: temparr && temparr.length > 0 ? temparr[0] : '',
                emp_contact_code: temparr && temparr.length > 0 ? temparr[1] : '',
                emp_contact_no: temparr && temparr.length > 0 ? temparr[2] : '',
                mobile_no_country_code: myArray && myArray.length > 0 ? myArray[0] : '',
                mobile_no: myArray && myArray.length > 0 ? myArray[1] : '',
                email: profiledata && profiledata.user_profile && profiledata.user_profile[0].email_id,
                address: profiledata && profiledata.user_profile && profiledata.user_profile[0].address
            })

        }
    }, [profiledata])

    useEffect(() => {
        if (isAuth) {
            const getUrl = callername.login_response.menu['My Accounts']
            const profiles = _.filter(getUrl, { form_name: 'View Profile' })
            const ChangePassword = _.filter(getUrl, { form_name: 'Change Password' })
            // Setformurl(profile[0].form_url)
            Setchangepassword(ChangePassword[0].form_url)
            Setformdata({
                ...formdata,
                form_url: profiles[0].form_url,
                form_key: profiles[0].form_key
            })
        }
    }, [isAuth])

    useEffect(() => {
        validator.current.showMessages()
        formvalidator.current.showMessages()
    }, [])

    // const SubmitPassword = () => {
    //     setAddFormSubmit1(true);
    //     if (formvalidator.current.allValid()) {
    //         if (password.new_password === password.confirm_password) {
    //             Updatepassword({
    //                 payload: [
    //                     authtoken,
    //                     [
    //                         "ChangePassword",
    //                         {
    //                             "session_token": "undefined-" + authtoken,
    //                             "current_password": password.current_password,
    //                             "new_password": password.new_password,
    //                             "confirm_password": password.confirm_password
    //                         }
    //                     ]
    //                 ],
    //                 paramid: paramid,
    //                 formkey: formdata.form_key
    //             })
    //         } else {
    //             toast.error("New password and Confirn password Should Match")
    //         }
    //     }
    // }

    const userDetailOnSubmit = () => {

        let converttoarray = Array.from(String(number.mobile_no_country_code), Number)
        let converttoarrayPlus = Array.from(String(number.mobile_no_country_code), String);
        let checkallarray = converttoarray.every(item => item === 0)
        let checkallarrayPlus = converttoarrayPlus.every(item => item == '+')
        if (checkallarray || checkallarrayPlus) {
            toast.error('Mobile No. International Code Invalid')
        } else {
            if (validator.current.allValid()) {
                console.log(number.address,'number.address')
                UpdateProfile({
                    payload: [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UpdateUserProfile",
                                {
                                    "email_id": number.email ? number.email : profiledata && profiledata.user_profile && profiledata.user_profile[0].email_id,
                                    "con_no": number.emp_contact_country_code + "-" + number.emp_contact_code + "-" + number.emp_contact_no,
                                    "mob_no": number.mobile_no_country_code + "-" + number.mobile_no,
                                    "address": number.address ? number.address : (profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].address : ''),
                                    "emp_code": profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].emp_code : '',
                                    "emp_name": profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].emp_name : ''
                                }
                            ]
                        }
                    ],
                    paramid: paramid,
                    formkey: formdata.form_key
                })
                setAddFormSubmit(true);
            }
        }

    }

    const homescreen = () => {
        window.location.href = '/dashboard'
    }

    console.log(number, 'number');
    console.log(callername, 'sess');



    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-1">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>My Accounts</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span>My Profile</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="
        col-lg-4 col-md-6
        d-none d-md-flex
        align-items-center
        justify-content-end
      "
                            >
                            </div>
                        </div>
                    </div>
                    {/* {passwordcard == false ? */}
                    <div className="page-titles  pb-2 pt-1">
                        <div className="container">
                            <div className="main-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="usrPro card">
                                            <div className="card-body">
                                                <div className="d-flex flex-column align-items-center text-center">
                                                    {/* <img src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="Admin" className="rounded-circle p-1 bg-primary" width="110"> */}
                                                    <Avatar size={67} icon={<UserOutlined />} />
                                                    <div className="mt-3">
                                                        <h4>{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].emp_name : ''}</h4>
                                                        <p className="text-secondary mb-1">{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].email_id : ''}</p>
                                                        {/* <button className="btn btn-primary">Follow</button>
									<button className="btn btn-outline-primary">Message</button> */}
                                                    </div>
                                                </div>
                                                <hr className="my-4" />
                                                <div className="col-md-12 mt-1">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="">Short Name</label>
                                                                <p className="text-secondary mb-1 ml-2">{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].short_name : ''}</p>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="">Employee ID</label>
                                                                <p className="text-secondary mb-1">{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].emp_code : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="">User ID</label>
                                                                <p className="text-secondary mb-1">{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].user_name : ''}</p>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor="">User Group</label>
                                                                <p className="text-secondary mb-1">{profiledata && profiledata.user_profile && profiledata.user_profile.length > 0 ? profiledata.user_profile[0].u_g_name : ''}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row mb-3">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Email ID <span style={{ "color": "red" }}>*</span></h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        {callername && callername.login_response && callername.login_response.usr_cat_id != 1 ?
                                                            <div className="form-group">
                                                                <Input placeholder='user@domain.com' type="text" className="form-control"
                                                                    onChange={(e) => {
                                                                        Setnumber({
                                                                            ...number,
                                                                            email: e.target.value
                                                                        })
                                                                    }} value={number.email} />
                                                                {validator.current.message(
                                                                    'email',
                                                                    number.email,
                                                                    ['required', { regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }],
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Email ID Required',
                                                                            regex: 'Invalid Email ID'
                                                                        }
                                                                    })}
                                                            </div>
                                                            :
                                                            <input type="text" className="form-control" value={number.email} disabled={true} />}
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Contact No</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        <Input.Group size="default">
                                                            <Row gutter={8}>
                                                                <Col span={4}>
                                                                    <Input placeholder='+00' value={number.emp_contact_country_code} name='emp_contact_country_code'
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9 +]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        maxlength='4'
                                                                        onChange={onUserDetailOnChange} />
                                                                    {validator.current.message(
                                                                        'emp_contact_country_code',
                                                                        number.emp_contact_country_code,
                                                                        [{ regex: /^(\+?\d{1,3}|\d{1,4})$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                regex: 'Enter valid mobile number'
                                                                            }
                                                                        })}
                                                                </Col>
                                                                <Col span={4}>
                                                                    <Input placeholder='000' value={number.emp_contact_code}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9 +]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        maxlength='4'
                                                                        name='emp_contact_code' onChange={onUserDetailOnChange} />
                                                                    {validator.current.message(
                                                                        'emp_contact_code',
                                                                        profile.emp_contact_code,
                                                                        [{ regex: /^(\+\d{1,3}[- ]?)?\d{3}$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                regex: 'Enter valid mobile number'
                                                                            }
                                                                        })}
                                                                </Col>
                                                                <Col span={10}>
                                                                    <Input placeholder='1234567890'
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        minlength='6'
                                                                        maxlength='10'
                                                                        value={number.emp_contact_no} name='emp_contact_no' onChange={onUserDetailOnChange} />
                                                                    {validator.current.message(
                                                                        'emp_contact_no',
                                                                        profile.emp_contact_no,
                                                                        [{ regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                regex: 'Enter valid mobile number'
                                                                            }
                                                                        })}
                                                                </Col>
                                                            </Row>
                                                        </Input.Group>
                                                        [+91 452 1234567]
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Mobile Number <span style={{ "color": "red" }}>*</span></h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        <Input.Group size="default">
                                                            <Row gutter={8}>
                                                                <Col span={4}>
                                                                    <Input placeholder='+00' name='mobile_no_country_code'
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9 +]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}                                                                       
                                                                        maxlength='4'
                                                                        onChange={onUserDetailOnChange} value={number.mobile_no_country_code} />
                                                                    {validator.current.message(
                                                                        'mobile_no_country_code',
                                                                        number.mobile_no_country_code,
                                                                        ['required', { regex: /^(\+?\d{1,3}|\d{1,4})$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Mobile No. International Code Required',
                                                                                regex: 'Enter valid mobile number'
                                                                            }
                                                                        })}
                                                                </Col>
                                                                <Col span={10}>
                                                                    <Input placeholder='1234567890'
                                                                        value={number.mobile_no}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        maxlength='10'
                                                                        name='mobile_no'
                                                                        onChange={onUserDetailOnChange} />
                                                                    {validator.current.message(
                                                                        'mobile_no',
                                                                        number.mobile_no,
                                                                        ['required', { regex: /^[0-9\b]+$/ }, `min:10`, `max:10`],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Mobile Number Required',
                                                                                regex: 'Enter Numbers Only',
                                                                                min: 'Mobile Number should be 10 Numbers',
                                                                                max: 'Mobile Number should be 10 Numbers'
                                                                            }
                                                                        })}
                                                                </Col>

                                                            </Row>
                                                        </Input.Group>
                                                        [+91 9876543210]
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Address</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        <TextArea rows={2} name="address" id="" placeholder='Enter Address' value={number.address} className='form-control' onChange={onUserDetailOnChange} />
                                                    </div>
                                                </div>
                                                <div className='text-center popupbtncolour mt-4'>

                                                    <Button shape="round" className='addbutton me-1' style={{ width: "100px" }} onClick={userDetailOnSubmit}
                                                        icon={<PlayCircleOutlined />} >
                                                        Submit
                                                    </Button>
                                                    {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", width: "150px" }}
                                                                onClick={passwordmodel}
                                                            >
                                                                Change password
                                                            </Button> */}
                                                    <Button shape="round" className='addbutton ' style={{ width: "150px" }}>
                                                        <Link to={changepassword ? changepassword : ''}
                                                        >
                                                            Change Password
                                                        </Link>
                                                    </Button>

                                                </div>

                                            </div>
                                        </div>
                                        {/* <div className="row">
						<div className="col-sm-12">
							<div className="card">
								<div className="card-body">
									<h5 className="d-flex align-items-center mb-3">Project Status</h5>
									<p>Web Design</p>
									<div className="progress mb-3" style={{height: '5px'}}>
										<div className="progress-bar bg-primary" role="progressbar" style={{width: '80%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<p>Website Markup</p>
									<div className="progress mb-3" style={{height: '5px'}}>
										<div className="progress-bar bg-danger" role="progressbar" style={{width: '72%'}} aria-valuenow="72" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<p>One Page</p>
									<div className="progress mb-3" style={{height: '5px'}}>
										<div className="progress-bar bg-success" role="progressbar" style={{width: '89%'}} aria-valuenow="89" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<p>Mobile Template</p>
									<div className="progress mb-3" style={{height: '5px'}}>
										<div className="progress-bar bg-warning" role="progressbar" style={{width: '55%'}} aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<p>Backend API</p>
									<div className="progress" style={{height: '5px'}}>
										<div className="progress-bar bg-info" role="progressbar" style={{width: '66%'}} aria-valuenow="66" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
								</div>
							</div>
						</div>
					</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* : 
                        <div className="page-titles  pb-2 pt-1">
                            <Card title='Change Password'>
                                <div className="container-fluid pt-1">
                                    <div className="row">
                                        <div className="col-12">
                                             <div className="card" >
                                             <div className="card-body" > 
                                            <div className="col-md-12">
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label><b>Current Password</b><span style={{ color: "red" }}>*</span></label>
                                                            <input onmouseenter="hjdsbfdjsfb" placeholder="Enter Current Password"
                                                                style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    setPassword({
                                                                        ...password,
                                                                        current_password: e.target.value
                                                                    })
                                                                }} />
                                                            {formvalidator.current.message(
                                                                'Password',
                                                                password.current_password,
                                                                ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        regex: 'Enter Strong Password'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-4'>
                                                        </div>
                                                        <div className='col-md-6'>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <label><b>New Password</b><span style={{ color: "red" }}>*</span></label>
                                                            <input onmouseenter="hjdsbfdjsfb" placeholder="Enter New Password" style={{ width: '100%' }}
                                                                onChange={(e) => {
                                                                    setPassword({
                                                                        ...password,
                                                                        new_password: e.target.value
                                                                    })
                                                                }} />
                                                            {formvalidator.current.message(
                                                                'Password',
                                                                password.new_password,
                                                                ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        regex: 'Enter Strong Password'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <label><b>Confirm Password</b><span style={{ color: "red" }}>*</span></label>
                                                            <input placeholder="Enter Confirm Password" style={{ width: '100%' }} onChange={(e) => {
                                                                setPassword({
                                                                    ...password,
                                                                    confirm_password: e.target.value
                                                                })
                                                            }} />
                                                            {formvalidator.current.message(
                                                                'Password',
                                                                password.confirm_password,
                                                                ['required', { regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/ }],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        regex: 'Enter Strong Password'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-6'>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                        </div>

                                                        <div className='col-md-6'>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div><br />
                                            <div className='col-md-12' >
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4'>
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "39px", marginRight: "10px", width: "100px" }} onClick={SubmitPassword}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     </div> 
                                     </div> 

                                </div>
                            </Card>
                        </div> */}

                </div >

            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    UserProfile,
    UpdateProfile,
    Updatepassword
})(MyProfile);