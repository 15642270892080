import React, { useEffect, useState, useRef, Fragment, useCallback, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Row, Tooltip, DatePicker, Form, Popconfirm, Card, Select, Tabs, Tree, Space, Button, Checkbox, Drawer } from 'antd';
import { PlayCircleOutlined, DownloadOutlined, DoubleRightOutlined, CloseCircleOutlined, CloudUploadOutlined, DoubleLeftOutlined, DeleteOutlined, PlusOutlined, CloseOutlined, FullscreenOutlined, FilterOutlined, UpOutlined, EyeOutlined, ControlOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import EncryptDecrypt from '../../Libs/Encrypt&Decrypt'
import { GetGroupListData, FetchEmails, emailsave, verifypassword, updateblockoption } from '../../Store/Action/Master/CV_CertificateMailMapping';
import { compliancefilters, GetOnOccurrenceCompliances, onoccurrencetransaction, startonoccurrencecompliance } from '../../Store/Action/Transcations/EXE_OnOccurrenceCompliance';
import { constant, filter, indexOf, merge } from 'lodash';
import { UploadFile1 } from '../../Store/Action/Transcations/Exe_ComplianceTaskDetails';
import { InfoCircleOutlined, PlusCircleOutlined, SearchOutlined, FilterFilled, ExclamationCircleTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { getFilterDataValues, UploadFile, removedocument, Downloadfile, updateStatus, saveStatus, getCalenderView, getCurrentTaskDetails, getUpcomigTaskDetails, fileListTemp } from '../../Store/Action/Transcations/Exe_ComplianceTaskDetails';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import { getremarkhistory } from '../../Store/Action/Transcations/ComplianceApproval';
import { Toaster } from '../../Libs/Toaster';
import { toast } from 'react-toastify';
// import Calendar from 'react-awesome-calendar';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment/moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EXE_OnOccurrenceCompliance from '../../Store/Reducer/Transactions/EXE_OnOccurrenceCompliance';
import { RESET_REMARK_HISTORY } from '../../Store/types';
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from '../Scrollbar';
import url, { getUrl } from './../../Libs/URL'
import { HomeRedirect } from './../../Libs/country'
import Stickicon from "./Stickyicon";
const homepage = HomeRedirect()
const urls = getUrl()

const _ = require("lodash");

const mapStateToProps = (state) => ({
    // onoccurrencecompliances: state.EXE_OnOccurrenceCompliance.onoccurrencecompliance,
    filterrecall: state.recallfilters.recallfilters,
    complianceTaskDetail: state.EXE_ComplianceTaskDetails.complianceTaskDetails,
    nextRecords: state.ComplianceApproval.complianceApproval.nextrecord,
    remarklist: state.ComplianceApproval.complianceApproval.remarkmodaldata,
    fileDatas: state.EXE_ComplianceTaskDetails.complianceTaskDetails.fileData,

})


const ComplianceTaskDetails = ({
    compliancefilters,
    FetchEmails,
    emailsave,
    verifypassword,
    onoccurrencetransaction,
    getremarkhistory,
    UploadFile,
    Downloadfile,
    fileDatas,
    fileListTemp,
    removedocument,
    UploadFile1,
    getCalenderView,
    remarklist,
    GetOnOccurrenceCompliances,
    nextRecords,
    startonoccurrencecompliance,
    updateStatus,
    saveStatus,
    getFilterDataValues,
    getCurrentTaskDetails,
    getUpcomigTaskDetails,
    GetRemarksData,
    filterrecall: { recallremarks },
    complianceTaskDetail: { compliancefilter, compliancefiltershow, upcomingTask, currentCompliance },
    // onoccurrencecompliances: { compliancefilter, compliancefiltershow, complianceview }
}) => {
    const { Panel } = Collapse;
    const { Option } = Select;
    const { Search, TextArea } = Input;
    const [tab, setTab] = useState()
    console.log(tab, 'TABTAB')
    const [nextDatas, setNextData] = useState([])
    console.log(nextDatas, 'nextDatas');
    const location = useLocation();
    const [show, setShow] = useState(false)
    const [all, setAll] = useState("All");
    console.log(show, 'show show')
    const [defaultRemarks, setDefaultRemarks] = useState();
    const [eventValue, setEventValue] = useState(0)
    console.log(eventValue, 'eventValue');
    const [showMore, setShowMore] = useState(false)
    let [counts, setCount] = useState(0)
    const [edit, setEdit] = useState(false)
    const [update, setUpdate] = useState(false)
    const currentDate = new Date().toJSON().slice(0, 10);
    const [FormShow, setFormShow] = useState(false);
    const [updatedArray, setUpdatedArray] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [tempFilterArray, setTempFilterArray] = useState([]);
    const [isModalOpenremark, setIsModalOpenremark] = useState(false);
    const [titleDynamic, setTitleDynamic] = useState([]);
    const [passvisible, Setpassvisible] = useState(false);
    const [index, setIndex] = useState()
    const [date, setDate] = useState(new Date())
    const searchInput = useRef(null);
    const [key, setkey] = useState()
    const [checked, setChecked] = useState({
        All: false,
        periodical: false,
        flexi: false,
        review: false,
        on_occurrence: false,
        one_time: false

    })
    console.log(fileDatas, 'fileDatas');
    const [filterValues, setFilterValues] = useState(
        {
            All: '',
            periodical: '',
            flexi: '',
            review: '',
            on_occurrence: '',
            one_time: ''
        }
    )
    const [status, setStatus] = useState({
        overDue: false,
        inprogress: false,
        recall: false
    })
    const columnsremark = [
        {
            title: '#',
            dataIndex: false,
            width: '10px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{(page - 1) * 10 + index + 1}</p>
            }
        },
        {
            title: 'Activity Date',
            dataIndex: "activity_on",
            key: "activity_on",
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{record.activity_on}</p>
            }
        },
        {
            title: 'Usertype',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return record.action === "Submitted" ? <p>Assignee</p> : <p>Approver</p>
            }
        },
        {
            title: 'Reviewer Report',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <a href={`${urls + '/compliance_format/' + record.documents}`} target="_blank" >
                    {record.documents}
                </a>

            }
        },
        {
            title: 'Remarks',
            dataIndex: "remarks",
            key: "remarks",
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{record.remarks}</p>
            }
        }
    ]
    const [type, setType] = useState("")
    console.log(type, 'type');
    const [searchedColumn, setSearchedColumn] = useState('');
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [documentNo, setDocumentNo] = useState("")
    const [tempList, setTempList] = useState([])
    const [dataList, setDataList] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [showValueModal, setShowValueModal] = useState(false)
    const [particularevent, setParticularevent] = useState({
        data: '',
        value: false
    })
    const wrapperRef = useRef(null);
    const [showDropDown, setshowDropDown] = useState(false);
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        active_status: ''
    })
    console.log(passwordata, 'passwordata');
    const [completionDate, setCompletionDate] = useState("")
    console.log(completionDate, 'completionDate')
    const [valueModal, setValueModal] = useState({
        unit: '',
        recall: '',
        address: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: [],
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        description: "",
        completion_dadue_datete: "",
        unit_id: null,
        approve_status: "",
        actiondata: "",
        start_date: "",
        domain_id: null,
        history_count: '',
        recall_history_count: '',
        occurrence_remarks: '',
        due_date: ''
    })
    console.log(valueModal, 'valuemodal')
    const paramid = location.pathname;
    const [checkdata, setCheckdata] = useState([])
    console.log(upcomingTask, 'check data')
    const { TabPane } = Tabs;
    var [count1, setCount1] = useState(1);
    const [titledata, Settitledata] = useState()
    const currentdate = new Date();
    const [today, setToday] = useState(moment(currentdate).format('DD-MMM-YYYY'))
    let [count, setcount] = useState(0)
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info

    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [eventData, setEventData] = useState([])
    const localizer = momentLocalizer(moment);
    const validator = useRef(new SimpleReactValidator());
    const [showdata, Setshowdata] = useState({
        domain: '',
        unit: '',
        frequency: '',
        category: '',
        act: '',
        division: '',
        // legal_entity: '',
        legal_entity: localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? '' : localStorage.getItem('SelectedEntityid')

    })
    console.log(showdata, 'SHOWDATA')
    const validator1 = useRef(new SimpleReactValidator());
    const validator2 = useRef(new SimpleReactValidator());
    const [fileData, setFileData] = useState([])
    const [valueForFileUpload, setValueForFileUpload] = useState({
        d_id: '',
        u_id: '',
        start_date: '',
        file_info: [],
    })
    const [fileupload, setFileUpload] = useState([])
    const [fileSize, setFileSize] = useState(0)
    console.log(fileupload, 'fileupload')
    const [currentMonth, setCurrentMonth] = useState(moment(new Date()).startOf('month'))
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalVisible, setModaVisible] = useState(false)
    const [taskvalue, setTaskvalue] = useState()
    const [division, setDivision] = useState([])
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [units, setUnits] = useState([])
    const [domain, setDomain] = useState([])
    const [legalentity, setLegalentity] = useState()
    const [modalData, setModalData] = useState({
        password: '',
    })
    // const [compliancedata, setComplianceData] = useState({
    //     startdate: '',
    //     remarks: ''
    // });
    const [tempvalue, setTempvalue] = useState([])
    const [remarks, SetRemarks] = useState(null);
    console.log(remarks, 'fileupload rema')
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [currentDetails, setCurrentDetails] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const dispatch = useDispatch()
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const RemarksColumn = [
        {
            title: 'Sno',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                // setModalData({
                //     ...modalData,
                //     password: text
                // })
                // setComplianceData[{
                //     ...compliancedata
                // }]
                SetRemarks(record.r_description)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]
    const handleCancelremark = () => {
        setIsModalOpenremark(false);
    };
    const showModalremark = () => {
        setIsModalOpenremark(true);
    };
    useEffect(() => {
        if (currentCompliance && currentCompliance.current_compliances) {
            let arrayTemp = [];
            for (let i in currentCompliance && currentCompliance.current_compliances) {
                arrayTemp.push(currentCompliance && currentCompliance.current_compliances[i]);
            }
            var merged = [].concat.apply([], arrayTemp);
            setUpdatedArray([...merged]);

        }
    }, [currentCompliance && currentCompliance.current_compliances]);

    useEffect(() => {
        if (fileDatas !== '') {
            if (fileDatas == 'FileUploadSuccess') {
                if (update == true) {
                    const payload1 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveInterimComplianceHistory",
                                {
                                    "legal_entity_id": showdata && showdata.legal_entity,
                                    "compliance_history_id": valueModal?.compliance_history_id,
                                    "documents": fileupload.length > 0 ? fileupload : null,
                                    "uploaded_documents": null,
                                    "remarks": remarks,
                                    "file_size": 0
                                }
                            ]
                            // "request": [
                            //     "SaveInterimComplianceHistory",
                            //     {
                            //         "legal_entity_id": showdata && showdata.legal_entity,
                            //         "compliance_history_id": valueModal.compliance_history_id,
                            //         "documents": fileupload.length > 0 ? fileupload : null,
                            //         "uploaded_documents": null,
                            //         "remarks": remarks,
                            //         "file_size": 0
                            //     }
                            // ]
                        }
                    ]
                    const payload2 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetCurrentComplianceDetail",
                                {
                                    "le_id": showdata.legal_entity,
                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                    "current_start_count": 0,
                                    "cal_view": null,
                                    "cal_date": null,
                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                    "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                    "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                }
                            ]
                        }
                    ]


                    updateStatus({
                        payload: payload1,
                        paramid: paramid,
                        setShowValueModal: setShowValueModal,
                        nextData: nextDatas,
                        payload2: payload2
                    })
                }
                else {
                    const pay = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UpdateComplianceDetail",
                                {
                                    "le_id": showdata.legal_entity,
                                    "compliance_history_id": valueModal.compliance_history_id,
                                    "documents": fileupload && fileupload.length > 0 ? fileupload : null,
                                    "uploaded_documents": null,
                                    "removed_documents": [],
                                    "completion_date": moment(completionDate && completionDate._d).format('DD-MMM-YYYY'),
                                    "validity_date": valueModal.validity_date !== null ? moment(valueModal.validity_date._d).format('DD-MMM-YYYY') : null,
                                    "next_due_date": valueModal.next_due_date !== null ? valueModal.next_due_date : null,
                                    "remarks": null,
                                    "file_size": 0,
                                    "tz_date": moment(new Date()).format('DD-MMM-YYYY hh:mm'),
                                    "doc_ref_num": valueModal.document_reference_number != '' ? valueModal.document_reference_number : null,
                                    "password": moment(valueModal.due_date).isSameOrBefore(date) ? passwordata.passvalue : '',
                                    "pwd_validate": moment(valueModal.due_date).isSameOrBefore(date) ? true : false
                                }
                            ]
                        }
                    ]
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetCurrentComplianceDetail",
                                {
                                    "le_id": showdata.legal_entity,
                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                    "current_start_count": 0,
                                    "cal_view": null,
                                    "cal_date": null,
                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                    "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                    "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                }
                            ]
                        }
                    ]
                    saveStatus({
                        payload: pay,
                        paramid: paramid,
                        setValueModal: setValueModal,
                        setCompletionDate: setCompletionDate,
                        SetRemarks: SetRemarks,
                        valueModal: valueModal,
                        nextData: nextDatas,
                        payload2: payload,
                        Setpassvisible: Setpassvisible
                    })
                }

            }
        }
    }, [fileDatas])

    useEffect(() => {
        showValueModal && setFileSize(0)
    }, [showValueModal])
    const compliancetaskfilter = (e, type) => {
        let filterTable;
        if (type == "Compliance_Task") {
            filterTable = updatedArray.filter((o) => {
                if (all == "All") {
                    if (o["statu"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["statu"].toLowerCase().includes(e.toLowerCase()) &&
                        o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        } else if (type == "Domain") {
            filterTable = updatedArray.filter((o) => {
                if (all == "All") {
                    if (o["domain_name"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["domain_name"].toLowerCase().includes(e.toLowerCase()) &&
                        o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        } else if (type == "Start Date") {
            filterTable = updatedArray.filter((o) => {
                if (all == "All") {
                    if (o["start_date"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["start_date"].toLowerCase().includes(e.toLowerCase()) &&
                        o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        } else if (type == "Due Date") {
            filterTable = updatedArray.filter((o) => {
                if (all == "All") {
                    if (o["due_date"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["due_date"].toLowerCase().includes(e.toLowerCase()) &&
                        o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        }
        console.log(filterTable, 'filterTable');
        let totalList = [];
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = [];
        if (filterTable && filterTable.length > 0) {
            // assigneename &&
            //     assigneename.length > 0 &&
            //     assigneename.map(function (user) {
            //         if (arr.indexOf(user.assignee_name) < 0)
            //             arr.push({
            //                 assignee_name: user.assignee_name,
            //                 unit_name: user.unit_name,
            //             });
            //     });

            let tempArray = []
            filterTable &&
                filterTable.map((subMenuList, childIndex) => {
                    {

                        let entity = {
                            address: subMenuList.address,
                            ageing: subMenuList.ageing,
                            approve_status: subMenuList.approve_status,
                            assigned_on: subMenuList.assigned_on,
                            category_name: subMenuList.category_name,
                            comp_history_count: subMenuList.comp_history_count,
                            compfielaw_url: subMenuList.compfielaw_url,
                            compliance_description: subMenuList.compliance_description,
                            compliance_download_url: subMenuList.compliance_download_url,
                            compliance_file_name: subMenuList.compliance_file_name,
                            compliance_history_id: subMenuList.compliance_history_id,
                            compliance_id: subMenuList.compliance_id,
                            compliance_name: subMenuList.compliance_name,
                            compliance_status: subMenuList.compliance_status,
                            compliance_task_frequency: subMenuList.compliance_task_frequency,
                            division_name: subMenuList.division_name,
                            document_reference_number: subMenuList.document_reference_number,
                            document_size: subMenuList.document_size,
                            domain_id: subMenuList.domain_id,
                            domain_name: subMenuList.domain_name,
                            due_date: subMenuList.due_date,
                            duration_type: subMenuList.duration_type,
                            extend_due_date: subMenuList.extend_due_date,
                            file_names: subMenuList.file_names,
                            interim_count: subMenuList.interim_count,
                            is_active: subMenuList.is_active,
                            is_ctcy_task: subMenuList.is_ctcy_task,
                            next_due_date: subMenuList.next_due_date,
                            occurrence_remarks: subMenuList.occurrence_remarks,
                            penal_consequences: subMenuList.penal_consequences,
                            recall_status: subMenuList.recall_status,
                            recallremarks: subMenuList.recallremarks,
                            remarks: subMenuList.remarks,
                            remarks_count: subMenuList.remarks_count,
                            start_date: subMenuList.start_date,
                            statu: subMenuList.statu,
                            system_remarks: subMenuList.system_remarks,
                            tz_date: subMenuList.tz_date,
                            tz_name: subMenuList.tz_name,
                            unit_id: subMenuList.unit_id,
                            unit_name: subMenuList.unit_name,
                            validity_date: subMenuList.validity_date,
                            validity_settings_days: subMenuList.validity_settings_days,
                            uniqueId: Math.floor(Math.random() * 100)
                        }
                        tempArray.push(entity);
                        // unit_name.push(subMenuList.unit_name);

                    }
                });
            console.log(tempArray, 'tempArray');
            setDataList(tempArray)
            // titleDynamic[index] = pmenu;
            // countrylisttempdata.push(totalList);
            tempSetArray.push(tempArray);

            console.log([...new Set([].concat.apply([], parId))], "parIdId");
            setTitleDynamic([...titleDynamic]);
            var merged = [].concat.apply([], tempSetArray);
            // setTemp(countrylisttempdata);
            // setMergedArray(merged);
            setTempFilterArray([...new Set([].concat.apply([], parId))]);
        } else {
            // setMergedArray([]);
            // setTemp([]);
            setTitleDynamic([]);
            setTempFilterArray([]);
            setDataList([])
        }
    };
    const onFinish = () => {
        const res = []
        const keys = Object.keys(filterValues)
        keys.forEach(key => {
            filterValues[key] === true && res.push(
                key
            )
        })
        console.log(res, 'SSSSS')
        let filterTable;
        let temp = []
        let a
        if (res[0] == "All" || res.length == 0) {
            filterTable = updatedArray;
            // settotalRecord(filterTable.length);
        } else {
            for (let i in res) {
                a = updatedArray.filter((o) =>
                    Object.keys(o).some((k) =>
                        String(o[k]).toLowerCase().includes(res[i].toLowerCase())
                    )
                );

                console.log(temp, a, 'LLLLLLLL')
                Array.prototype.push.apply(temp, a);


            }
            filterTable = temp
        }

        let totalList = [];
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = [];
        if (filterTable && filterTable.length > 0) {
            // assigneename &&
            //     assigneename.length > 0 &&
            //     assigneename.map(function (user) {
            //         if (arr.indexOf(user.assignee_name) < 0)
            //             arr.push({
            //                 assignee_name: user.assignee_name,
            //                 unit_name: user.unit_name,
            //             });
            //     });
            // const res = []
            // const keys = Object.keys(filterValues)
            // keys.forEach(key => {
            //     filterValues[key] === true && res.push(
            //         key
            //     )
            // })
            // console.log(res, 'SSSSS')
            // let filterTable;
            // let temp = []
            // let a
            // if (res[0] == "All" || res.length == 0) {
            //     filterTable = updatedArray;
            //     // settotalRecord(filterTable.length);
            // } else {
            //     for (let i in res) {
            //         a = updatedArray.filter((o) =>
            //             Object.keys(o).some((k) =>
            //                 String(o[k]).toLowerCase().includes(res[i].toLowerCase())
            //             )
            //         );

            //         console.log(temp, a, 'LLLLLLLL')
            //         Array.prototype.push.apply(temp, a);


            //     }
            //     filterTable = temp
            //     console.log(filterTable, 'filterTable');
            //     // settotalRecord(filterTable.length);
            // }

            // let totalList = [];
            // let arr = [];
            // let countrylisttempdata = [];
            // let tempSetArray = [];
            // let parId = [];
            // if (filterTable && filterTable.length > 0) {
            // assigneename &&
            //     assigneename.length > 0 &&
            //     assigneename.map(function (user) {
            //         if (arr.indexOf(user.assignee_name) < 0)
            //             arr.push({
            //                 assignee_name: user.assignee_name,
            //                 unit_name: user.unit_name,
            //             });
            //     });
            let tempArray = []
            filterTable &&
                filterTable.map((subMenuList, childIndex) => {
                    {

                        let entity = {
                            address: subMenuList.address,
                            ageing: subMenuList.ageing,
                            approve_status: subMenuList.approve_status,
                            assigned_on: subMenuList.assigned_on,
                            category_name: subMenuList.category_name,
                            comp_history_count: subMenuList.comp_history_count,
                            compfielaw_url: subMenuList.compfielaw_url,
                            compliance_description: subMenuList.compliance_description,
                            compliance_download_url: subMenuList.compliance_download_url,
                            compliance_file_name: subMenuList.compliance_file_name,
                            compliance_history_id: subMenuList.compliance_history_id,
                            compliance_id: subMenuList.compliance_id,
                            compliance_name: subMenuList.compliance_name,
                            compliance_status: subMenuList.compliance_status,
                            compliance_task_frequency: subMenuList.compliance_task_frequency,
                            division_name: subMenuList.division_name,
                            document_reference_number: subMenuList.document_reference_number,
                            document_size: subMenuList.document_size,
                            domain_id: subMenuList.domain_id,
                            domain_name: subMenuList.domain_name,
                            due_date: subMenuList.due_date,
                            duration_type: subMenuList.duration_type,
                            extend_due_date: subMenuList.extend_due_date,
                            file_names: subMenuList.file_names,
                            interim_count: subMenuList.interim_count,
                            is_active: subMenuList.is_active,
                            is_ctcy_task: subMenuList.is_ctcy_task,
                            next_due_date: subMenuList.next_due_date,
                            occurrence_remarks: subMenuList.occurrence_remarks,
                            penal_consequences: subMenuList.penal_consequences,
                            recall_status: subMenuList.recall_status,
                            recallremarks: subMenuList.recallremarks,
                            remarks: subMenuList.remarks,
                            remarks_count: subMenuList.remarks_count,
                            start_date: subMenuList.start_date,
                            statu: subMenuList.statu,
                            system_remarks: subMenuList.system_remarks,
                            tz_date: subMenuList.tz_date,
                            tz_name: subMenuList.tz_name,
                            unit_id: subMenuList.unit_id,
                            unit_name: subMenuList.unit_name,
                            validity_date: subMenuList.validity_date,
                            validity_settings_days: subMenuList.validity_settings_days,
                            uniqueId: Math.floor(Math.random() * 100)
                        }
                        tempArray.push(entity);
                        // unit_name.push(subMenuList.unit_name);

                    }
                });
            // titleDynamic[index] = pmenu;
            // countrylisttempdata.push(totalList);
            tempSetArray.push(tempArray);
            // parId.push(unit_name);
            setDataList(tempArray)
            setCurrentDetails(tempArray)
            console.log([...new Set([].concat.apply([], parId))], "parIdId");
            setTitleDynamic([...titleDynamic]);
            var merged = [].concat.apply([], tempSetArray);
            // setTemp(countrylisttempdata);
            // setMergedArray(merged);
            setTempFilterArray([...new Set([].concat.apply([], parId))]);
        } else {
            // setMergedArray([]);
            // setTemp([]);
            setTitleDynamic([]);
            setTempFilterArray([]);
            setDataList([])
            setCurrentDetails([])
        }


        setshowDropDown(false)
    }



    useEffect(() => {
        if (recallremarks && recallremarks.length > 0) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
            if (temp && temp.length == 0) {
                Toaster.error("No Remarks Available")
            }
            else {
                setRemarksModal(true);
                dispatch({
                    type: RESET_REMARK_HISTORY
                })
            }
        }

    }, [recallremarks])
    //   useEffect(() => {
    //     if (list && list.length > 0) {
    //         let uniqueObjArray = [...new Map(list && list.length && list.map((item) => [item["unit_id"], item])).values()];
    //         const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
    //             return item.unit_id
    //         })
    //         let tempArr = []
    //         for (let i in uniqueIds) {
    //             let filteredArr = _.filter(list, { unit_id: uniqueIds[i] })
    //             tempArr.push(filteredArr)

    //             setFilteredArray([...filteredArray, tempArr])
    //         }
    //     }
    // }, [list])
    console.log(moment(completionDate).format('DD-MMM-YYYY'), 'currentDetails');
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const tablehandleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
        setFilteredInfo(filters);
    };
    const onsubmit = () => {
        setUpdate(false)
        setAddFormSubmit1(false)
        Setpassword({
            ...passwordata,
            passvalue: ''
        })

        console.log(valueModal.startdate, 'valueModal.due_date');
        if (completionDate != '') {
            let date = moment(completionDate && completionDate._d).format('DD-MMM-YYYY')
            if (moment(valueModal.start_date).isSameOrBefore(date)) {

                if (moment(valueModal.due_date).isSameOrBefore(date)) {
                    if (completionDate != '') {
                        Swal.fire({
                            title: 'Are You Sure?',
                            text: 'Task will move to Delayed Complied Status,do you still want to proceed',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                Setpassvisible(true);
                                setShowValueModal(false)

                                // Swal.fire('Closed.!', 'Closed...', 'success');
                            }
                        });
                    }

                    else {
                        Toaster.error("Task Completion Date / Document Issued Date Required")
                    }
                }
                else {
                    if (completionDate != '') {

                        if (fileupload.length == 0) {
                            const pay = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "UpdateComplianceDetail",
                                        {
                                            "le_id": showdata.legal_entity,
                                            "compliance_history_id": valueModal.compliance_history_id,
                                            "documents": fileupload && fileupload.length > 0 ? fileupload : null,
                                            "uploaded_documents": null,
                                            "removed_documents": [],
                                            "completion_date": moment(completionDate && completionDate._d).format('DD-MMM-YYYY'),
                                            "validity_date": valueModal.validity_date !== null ? moment(valueModal.validity_date._d).format('DD-MMM-YYYY') : null,
                                            "next_due_date": valueModal.next_due_date !== null ? valueModal.next_due_date : null,
                                            "remarks": null,
                                            "file_size": 0,
                                            "tz_date": moment(new Date()).format('DD-MMM-YYYY hh:mm'),
                                            "doc_ref_num": valueModal.document_reference_number != '' ? valueModal.document_reference_number : null,
                                            "password": passwordata.passvalue,
                                            "pwd_validate": false
                                        }
                                    ]
                                }
                            ]
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetCurrentComplianceDetail",
                                        {
                                            "le_id": showdata.legal_entity,
                                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                            "current_start_count": 0,
                                            "cal_view": null,
                                            "cal_date": null,
                                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                        }
                                    ]
                                }
                            ]

                            saveStatus({
                                payload: pay,
                                paramid: paramid,
                                setValueModal: setValueModal,
                                setCompletionDate: setCompletionDate,
                                SetRemarks: SetRemarks,
                                valueModal: valueModal,
                                nextData: nextDatas,
                                payload2: payload,
                                Setpassvisible: Setpassvisible
                            })
                        }
                        if (fileupload.length > 0) {
                            UploadFile1({
                                payload: [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "UploadComplianceTaskFileNew",
                                            {
                                                "le_id": showdata && showdata.legal_entity,
                                                "c_id": 1,
                                                "selected_task_info": [
                                                    {
                                                        "d_id": Number(valueModal.domain_id),
                                                        "u_id": Number(valueModal.unit_id),
                                                        "start_date": valueModal.start_date,
                                                        "file_info": fileupload,
                                                        "compliance_history_id": Number(valueModal.compliance_history_id)
                                                    }
                                                ],
                                                "type": "",
                                                "password": "",
                                                "pwd_validate": false

                                            }
                                        ]
                                    }


                                ],









                                paramid: paramid
                            })
                        }

                    }
                    else {
                        Toaster.error("Task Completion Date / Document Issued Date Required")
                    }
                }
            }
            else {
                toast.error('Task Completion Date/Document Issued Date must be Greater than or equal to Start Date')
            }
        }

        else {
            Toaster.error("Task Completion Date / Document Issued Date Required")
        }
    }
    const Cancelmodal = () => {
        // setAddFormSubmit1(false)
        setShowValueModal(false);
        // setValueModal({
        //     ...valueModal,
        //     action: '',
        //     remarks: '',
        //     validity_date: '',
        //     next_due_date: ''
        // })
        // setEdit(false)

    }

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') == null || localStorage.getItem('SelectedEntityid') == 'null') {

        } else {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            if (localStorage.getItem('SelectedEntityid')) {
                // let mail = _.filter(compliancefilter, { legal_entity_id: localStorage.getItem('SelectedEntityid') })
                // setMail(compliancefilter.user_units)

                setLegalentity(localStorage.getItem('SelectedEntityid'))
            }
        }
    }, [localStorage.getItem('SelectedEntityid'), compliancefilter, entityid])
    useEffect(() => {
        if (dataList && dataList.length > 0) {
            console.log(currentCompliance && currentCompliance.current_compliances, 'currentCompliance && currentCompliance.current_compliances ');
            console.log(dataList, 'dataList');
            if (showMore == true) {
                let tempArr = tempList.concat(dataList)
                setTempList(tempArr)
                // setDataList([dataList,statutoryUnitList && statutoryUnitList.statutoryListByUnit])
            }
            else {
                setTempList(dataList)
            }

        }
    }, [dataList])
    useEffect(() => {
        if (currentCompliance && currentCompliance.current_compliances && currentCompliance.current_compliances.length > 0) {
            let temp = []
            for (let i in currentCompliance && currentCompliance.current_compliances) {
                let entity = {
                    address: currentCompliance && currentCompliance.current_compliances[i].address,
                    ageing: currentCompliance && currentCompliance.current_compliances[i].ageing,
                    approve_status: currentCompliance && currentCompliance.current_compliances[i].approve_status,
                    assigned_on: currentCompliance && currentCompliance.current_compliances[i].assigned_on,
                    category_name: currentCompliance && currentCompliance.current_compliances[i].category_name,
                    comp_history_count: currentCompliance && currentCompliance.current_compliances[i].comp_history_count,
                    compfielaw_url: currentCompliance && currentCompliance.current_compliances[i].compfielaw_url,
                    compliance_description: currentCompliance && currentCompliance.current_compliances[i].compliance_description,
                    compliance_download_url: currentCompliance && currentCompliance.current_compliances[i].compliance_download_url,
                    compliance_file_name: currentCompliance && currentCompliance.current_compliances[i].compliance_file_name,
                    compliance_history_id: currentCompliance && currentCompliance.current_compliances[i].compliance_history_id,
                    compliance_id: currentCompliance && currentCompliance.current_compliances[i].compliance_id,
                    compliance_name: currentCompliance && currentCompliance.current_compliances[i].compliance_name,
                    compliance_status: currentCompliance && currentCompliance.current_compliances[i].compliance_status,
                    compliance_task_frequency: currentCompliance && currentCompliance.current_compliances[i].compliance_task_frequency,
                    division_name: currentCompliance && currentCompliance.current_compliances[i].division_name,
                    document_reference_number: currentCompliance && currentCompliance.current_compliances[i].document_reference_number,
                    document_size: currentCompliance && currentCompliance.current_compliances[i].document_size,
                    domain_id: currentCompliance && currentCompliance.current_compliances[i].domain_id,
                    domain_name: currentCompliance && currentCompliance.current_compliances[i].domain_name,
                    due_date: currentCompliance && currentCompliance.current_compliances[i].due_date,
                    duration_type: currentCompliance && currentCompliance.current_compliances[i].duration_type,
                    extend_due_date: currentCompliance && currentCompliance.current_compliances[i].extend_due_date,
                    file_names: currentCompliance && currentCompliance.current_compliances[i].file_names,
                    interim_count: currentCompliance && currentCompliance.current_compliances[i].interim_count,
                    is_active: currentCompliance && currentCompliance.current_compliances[i].is_active,
                    is_ctcy_task: currentCompliance && currentCompliance.current_compliances[i].is_ctcy_task,
                    next_due_date: currentCompliance && currentCompliance.current_compliances[i].next_due_date,
                    occurrence_remarks: currentCompliance && currentCompliance.current_compliances[i].occurrence_remarks,
                    penal_consequences: currentCompliance && currentCompliance.current_compliances[i].penal_consequences,
                    recall_status: currentCompliance && currentCompliance.current_compliances[i].recall_status,
                    recallremarks: currentCompliance && currentCompliance.current_compliances[i].recallremarks,
                    remarks: currentCompliance && currentCompliance.current_compliances[i].remarks,
                    remarks_count: currentCompliance && currentCompliance.current_compliances[i].remarks_count,
                    start_date: currentCompliance && currentCompliance.current_compliances[i].start_date,
                    statu: currentCompliance && currentCompliance.current_compliances[i].statu,
                    system_remarks: currentCompliance && currentCompliance.current_compliances[i].system_remarks,
                    tz_date: currentCompliance && currentCompliance.current_compliances[i].tz_date,
                    tz_name: currentCompliance && currentCompliance.current_compliances[i].tz_name,
                    unit_id: currentCompliance && currentCompliance.current_compliances[i].unit_id,
                    unit_name: currentCompliance && currentCompliance.current_compliances[i].unit_name,
                    validity_date: currentCompliance && currentCompliance.current_compliances[i].validity_date,
                    validity_settings_days: currentCompliance && currentCompliance.current_compliances[i].validity_settings_days,
                    uniqueId: Math.floor(Math.random() * 100)
                }
                temp.push(entity)
            }
            setDataList(temp)

        }
    }, [currentCompliance])

    const filterLegends = (temp) => {
        console.log(temp, 'temptemptemp')
        let checkarray = []
        let listArray_One_time = _.filter(temp, { compliance_task_frequency: "One Time" });
        console.log(listArray_One_time, 'listArray_One_time')
        if (listArray_One_time && listArray_One_time.length > 0) {
            for (let i in listArray_One_time) {
                checkarray.push(listArray_One_time[i])
            }
        }
        let listArray_periodical = _.filter(temp, { compliance_task_frequency: "Periodical" });
        if (listArray_periodical && listArray_periodical.length > 0) {
            for (let i in listArray_periodical) {
                checkarray.push(listArray_periodical[i])
            }
        }
        let listArray_Review = _.filter(temp, { compliance_task_frequency: "Review" });
        if (listArray_Review && listArray_Review.length > 0) {
            for (let i in listArray_Review) {
                checkarray.push(listArray_Review[i])
            }
        }
        let listArray_Flexi_Review = _.filter(temp, { compliance_task_frequency: "Flexi Review" });
        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
            for (let i in listArray_Flexi_Review) {
                checkarray.push(listArray_Flexi_Review[i])
            }
        }
        let listArray_On_Occurrence = _.filter(temp, { compliance_task_frequency: "On Occurrence" });
        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
            for (let i in listArray_On_Occurrence) {
                checkarray.push(listArray_On_Occurrence[i])
            }
        }
    }


    console.log(tempList, 'tempList');
    useEffect(() => {
        let temp = []
        let temp2 = []
        let temp3 = []
        let temp4 = []
        let finalTemp = []
        if (tempList && tempList.length > 0) {
            let recallData = _.filter(tempList, { compliance_status: "Recall" })
            temp4 = recallData
            for (let i in tempList) {
                if (tempList[i].compliance_status != 'Recall') {
                    temp.push(tempList[i])
                }
            }
            if (temp && temp.length > 0) {
                for (let j in temp) {
                    // if ((moment(temp[j].due_date).format('DD-MMM-YYYY')) < (moment(new Date()).format('DD-MMM-YYYY'))) {
                    //     console.log(temp[j], 'temp[j]');
                    //     temp2.push(temp[j])
                    // }
                    if (moment(temp[j].due_date).isSameOrAfter(today)) {
                        temp2.push(temp[j])
                    }

                    else {
                        temp3.push(temp[j])
                    }
                }
            }

            // let checkarray = []
            // let listArray_One_time = _.filter(temp4, { compliance_task_frequency: "One Time" });
            // console.log(listArray_One_time, 'listArray_One_time')
            // if (listArray_One_time && listArray_One_time.length > 0) {
            //     for (let i in listArray_One_time) {
            //         checkarray.push(listArray_One_time[i])
            //     }
            // }
            // let listArray_periodical = _.filter(temp4, { compliance_task_frequency: "Periodical" });
            // if (listArray_periodical && listArray_periodical.length > 0) {
            //     for (let i in listArray_periodical) {
            //         checkarray.push(listArray_periodical[i])
            //     }
            // }
            // let listArray_Review = _.filter(temp4, { compliance_task_frequency: "Review" });
            // if (listArray_Review && listArray_Review.length > 0) {
            //     for (let i in listArray_Review) {
            //         checkarray.push(listArray_Review[i])
            //     }
            // }
            // let listArray_Flexi_Review = _.filter(temp4, { compliance_task_frequency: "Flexi Review" });
            // if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
            //     for (let i in listArray_Flexi_Review) {
            //         checkarray.push(listArray_Flexi_Review[i])
            //     }
            // }
            // let listArray_On_Occurrence = _.filter(temp4, { compliance_task_frequency: "On Occurrence" });
            // if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
            //     for (let i in listArray_On_Occurrence) {
            //         checkarray.push(listArray_On_Occurrence[i])
            //     }
            // }

            // // temp3 

            // let checkarray2 = []
            // let listArray_One_time2 = _.filter(temp3, { compliance_task_frequency: "One Time" });
            // console.log(listArray_One_time2, 'listArray_One_time2')
            // if (listArray_One_time2 && listArray_One_time2.length > 0) {
            //     for (let i in listArray_One_time2) {
            //         checkarray2.push(listArray_One_time2[i])
            //     }
            // }
            // let listArray_periodical2 = _.filter(temp3, { compliance_task_frequency: "Periodical" });
            // if (listArray_periodical2 && listArray_periodical2.length > 0) {
            //     for (let i in listArray_periodical2) {
            //         checkarray2.push(listArray_periodical2[i])
            //     }
            // }
            // let listArray_Review2 = _.filter(temp3, { compliance_task_frequency: "Review" });
            // if (listArray_Review2 && listArray_Review2.length > 0) {
            //     for (let i in listArray_Review2) {
            //         checkarray2.push(listArray_Review2[i])
            //     }
            // }
            // let listArray_Flexi_Review2 = _.filter(temp3, { compliance_task_frequency: "Flexi Review" });
            // if (listArray_Flexi_Review2 && listArray_Flexi_Review2.length > 0) {
            //     for (let i in listArray_Flexi_Review2) {
            //         checkarray2.push(listArray_Flexi_Review2[i])
            //     }
            // }
            // let listArray_On_Occurrence2 = _.filter(temp3, { compliance_task_frequency: "On Occurrence" });
            // if (listArray_On_Occurrence2 && listArray_On_Occurrence2.length > 0) {
            //     for (let i in listArray_On_Occurrence2) {
            //         checkarray2.push(listArray_On_Occurrence2[i])
            //     }
            // }

            // //  temp2 

            // let checkarray3 = []
            // let listArray_One_time3 = _.filter(temp2, { compliance_task_frequency: "One Time" });
            // console.log(listArray_One_time3, 'listArray_One_time3')
            // if (listArray_One_time3 && listArray_One_time3.length > 0) {
            //     for (let i in listArray_One_time3) {
            //         checkarray3.push(listArray_One_time3[i])
            //     }
            // }
            // let listArray_periodical3 = _.filter(temp2, { compliance_task_frequency: "Periodical" });
            // if (listArray_periodical3 && listArray_periodical3.length > 0) {
            //     for (let i in listArray_periodical3) {
            //         checkarray3.push(listArray_periodical3[i])
            //     }
            // }
            // let listArray_Review3 = _.filter(temp2, { compliance_task_frequency: "Review" });
            // if (listArray_Review3 && listArray_Review3.length > 0) {
            //     for (let i in listArray_Review3) {
            //         checkarray3.push(listArray_Review3[i])
            //     }
            // }
            // let listArray_Flexi_Review3 = _.filter(temp2, { compliance_task_frequency: "Flexi Review" });
            // if (listArray_Flexi_Review3 && listArray_Flexi_Review3.length > 0) {
            //     for (let i in listArray_Flexi_Review3) {
            //         checkarray3.push(listArray_Flexi_Review3[i])
            //     }
            // }
            // let listArray_On_Occurrence3 = _.filter(temp2, { compliance_task_frequency: "On Occurrence" });
            // if (listArray_On_Occurrence3 && listArray_On_Occurrence3.length > 0) {
            //     for (let i in listArray_On_Occurrence3) {
            //         checkarray3.push(listArray_On_Occurrence3[i])
            //     }
            // }

            if (temp4 && temp4.length > 0) {
                finalTemp.push({
                    "status": "Recall Compliance",
                    "child": temp4
                })
            }
            if (temp3 && temp3.length > 0) {
                finalTemp.push({
                    "status": "Over Due Compliance",
                    "child": temp3
                })
            }
            if (temp2 && temp2.length > 0) {
                finalTemp.push({
                    "status": "Inprogress Compliance",
                    "child": temp2
                })
            }
            setCurrentDetails(finalTemp)
        }
    }, [tempList])
    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()

    }, [])
    const [fullscreen, setfullscreen] = useState(false);
    console.log(fullscreen, 'fullscreenfullscreen')
    const fullscreenMode = () => {
        let topBar = document.getElementById('top-nav')
        let leftbar = document.getElementsByClassName('left-sidebar');
        let leftnone = leftbar[0];
        let pageWrapper = document.getElementById('page-wrapper')
        if (fullscreen == false) {
            document.documentElement.requestFullscreen();
            topBar.style.display = 'none'
            leftnone.style.display = 'none'
            pageWrapper.style.padding = '0'
            pageWrapper.style.marginLeft = '0'
            setfullscreen(true);
        } else {
            document.exitFullscreen();
            topBar.style.display = 'block'
            leftnone.style.display = 'block'
            pageWrapper.style.marginLeft = '65px'
            pageWrapper.style.paddingTop = '70px'
            setfullscreen(false);
        }
    }
    useEffect(() => {
        if (compliancefiltershow) {
            let temp = []
            if (compliancefiltershow.widget_data && compliancefiltershow.widget_data.length) {
                let arr = compliancefiltershow.widget_data[0].CurrentMonth.split('-')
                arr.pop()
                // arr.toString("-")
                for (let i in compliancefiltershow.widget_data[0].data) {
                    if (compliancefiltershow.widget_data[0].data[i].overdue > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "over Due",
                                title: compliancefiltershow.widget_data[0].data[i].overdue,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#f32d2b',
                                entity: showdata.legal_entity,
                                tooltip: `Over Due ${compliancefiltershow.widget_data[0].data[i].overdue}`


                            },
                        )
                    }
                    if (compliancefiltershow.widget_data[0].data[i].inprogress > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Inprogress",
                                title: compliancefiltershow.widget_data[0].data[i].inprogress,
                                allDay: true,
                                start: date,
                                end: date,
                                color: 'rgb(255 174 44)',
                                entity: showdata.legal_entity,
                                tooltip: `Inprogress ${compliancefiltershow.widget_data[0].data[i].inprogress}`
                            },
                        )
                    }
                    if (compliancefiltershow.widget_data[0].data[i].upcoming > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Upcoming",
                                title: compliancefiltershow.widget_data[0].data[i].upcoming,
                                allDay: true,
                                start: date,
                                end: date,
                                color: 'rgb(42 125 153)',
                                entity: showdata.legal_entity,
                                tooltip: `Upcoming ${compliancefiltershow.widget_data[0].data[i].upcoming}`
                            },
                        )
                    }
                    if (compliancefiltershow.widget_data[0].data[i].duedate > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Due Date",
                                title: compliancefiltershow.widget_data[0].data[i].duedate,
                                allDay: true,
                                start: date,
                                end: date,
                                color: 'rgb(149 46 154)',
                                entity: showdata.legal_entity,
                                tooltip: `Due Date ${compliancefiltershow.widget_data[0].data[i].duedate}`
                            },
                        )
                    }
                    if (compliancefiltershow.widget_data[0].data[i].taskrecall > 0
                    ) {
                        let date = new Date(`${arr[0]}-${arr[1]}-${compliancefiltershow.widget_data[0].data[i].date}`).toString()
                        temp.push(
                            {
                                id: "Task Recall",
                                title: compliancefiltershow.widget_data[0].data[i].taskrecall,
                                allDay: true,
                                start: date,
                                end: date,
                                color: '#da6d25',
                                entity: showdata.legal_entity,
                                tooltip: `Task Recall ${compliancefiltershow.widget_data[0].data[i].taskrecall}`

                            },
                        )
                    }


                }
            }
            setEventData(temp)
        }

    }, [compliancefiltershow])
    const onsubmitData = () => {

        setFormShow(true)
        if (formValidator.current.allValid()) {

            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setShow(true)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCalendarView",
                        {
                            "le_id": showdata.legal_entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "cal_date": null,
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "div_id": showdata.division != '' ? Number(showdata.division) : null,
                            "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]
            console.log(payload, 'showdata payload')

            if (showdata.legal_entity != '') {
                getCalenderView({
                    payload: payload,
                    paramid: paramid
                })
            }
            setTab('1')

        }
    }
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "complianceTaskFilters",
                        {
                            "le_id": showdata.legal_entity
                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getFilterDataValues({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, showdata.legal_entity])
    useEffect(() => {
        if (compliancefilter) {
            setDomain(compliancefilter && compliancefilter.domains_list
            )
            setfrequency(compliancefilter && compliancefilter.frequency_list
            )
            setCategoryList(compliancefilter && compliancefilter.categories_list)
            setDivision(compliancefilter && compliancefilter.divisions_list)
            setUnits(compliancefilter && compliancefilter.user_units
            )

        }


    }, [compliancefilter])

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
        validator2.current.showMessages()
    }, [])

    const OnCompliance = (data) => {
        const lasttransaction = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    [
                        "OnOccurrenceLastTransaction",
                        {
                            "le_id": entityid,
                            "compliance_id": data.compliance_id,
                            "unit_id": data.unit_id
                        }
                    ]
            }
        ]
        onoccurrencetransaction({
            payload: lasttransaction,
            paramid: paramid
        })
    }

    const handleCancel = () => {
        setRemarksModal(false)
    };


    const OnSubmited = () => {
        setAddFormSubmit1(true)
        if (validator1.current.allValid()) {
            startonoccurrencecompliance({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "StartOnOccurrenceCompliance",
                            {
                                "le_id": entityid,
                                "ac_id": tempvalue.ac_id,
                                "compliance_id": tempvalue.compliance_id,
                                "start_date": taskvalue && taskvalue[0].startdate ? taskvalue[0].startdate : taskvalue[1].startdate,
                                "unit_id": tempvalue.unit_id,
                                "duration": tempvalue.complete_within_days,
                                "remarks": taskvalue && taskvalue[0].remarks ? taskvalue[0].remarks : taskvalue[1].remarks,
                                "password": modalData.password
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
            setModaVisible(false)
        }
    }

    const passwordcancel = () => {
        setModaVisible(false)
        Setpassvisible(false);
        setModalData({
            ...modalData,
            password: ""
        })
    }
    const onSelectEvent = useCallback((calEvent) => {
        console.log(calEvent, 'calEvent');
        setTempList([])
        setEventValue(calEvent && calEvent.title)
        setType(calEvent && calEvent.id)
        setCount1(0)
        setShowMore(false)
        if (calEvent && calEvent.id == 'over Due') {
            setTab('2')
            setParticularevent({
                data: "over Due",
                value: true
            })
            setStatus({
                overDue: true,
                inprogress: false,
                recall: false
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "current_start_count": 0,
                            "cal_view": "OVERDUE",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            })
        }
        else if (calEvent && calEvent.id == 'Task Recall') {
            setStatus({
                overDue: false,
                inprogress: false,
                recall: true
            })
            setTab('2')
            setParticularevent({
                data: "Task Recall",
                value: true
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "current_start_count": 0,
                            "cal_view": "TASKRECALL",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            })
        }
        else if (calEvent && calEvent.id == 'Due Date') {
            setStatus({
                overDue: true,
                inprogress: true,
                recall: false
            })
            setTab('2')
            setParticularevent({
                data: "Due Date",
                value: true
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "current_start_count": 0,
                            "cal_view": "DUEDATE",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            })
        }
        else if (calEvent && calEvent.id == 'Inprogress') {
            setTab('2')
            setParticularevent({
                data: "Inprogress",
                value: true
            })
            // setParticularevent(true)
            setStatus({
                overDue: true,
                inprogress: true,
                recall: false
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCurrentComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "current_start_count": 0,
                            "cal_view": "INPROGRESS",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]

            getCurrentTaskDetails({

                payload: payload,
                paramid: paramid
            })
        }
        else if (calEvent && calEvent.id == 'Upcoming') {
            setTab('3')
            setCheckdata([])
            Settitledata([])
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUpcomingComplianceDetail",
                        {
                            "le_id": calEvent && calEvent.entity,
                            "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                            "upcoming_start_count": 0,
                            "cal_view": "UPCOMING",
                            "cal_date": moment(calEvent && calEvent.start).format('DD-MMM-YYYY'),
                            "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                            "division_id": showdata.division != '' ? Number(showdata.division) : null,
                            "category_id": showdata.category != '' ? Number(showdata.category) : null,
                            "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                        }
                    ]
                }
            ]

            getUpcomigTaskDetails({

                payload: payload,
                paramid: paramid
            })

        }
    }, [])

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
    };

    const onInputChange = e => {
        const { value } = e.target;
        const re = /^[A-Z0-9a-z _ .,-]+$/;
        if (value === "" || re.test(value)) {
            SetRemarks(value)
        }
    }

    const onInputChange1 = e => {
        const { value } = e.target;
        const re = /^[A-Z0-9a-z- _.,/)(]+$/;
        if (value === "" || re.test(value)) {
            setValueModal({
                ...valueModal,
                document_reference_number: value
            })
        }
    }

    const { views } = useMemo(() => ({
        views: {
            month: true
        }
    }), [])
    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }
    const now = new Date();
    const events = [
        {
            id: "over Due",
            title: '5',
            allDay: true,
            start: now,
            end: now,
        },
        {
            id: "Inprogress",
            title: 'All Day Event',
            allDay: true,
            start: 11 / 12 / 2022,
            end: 11 / 12 / 2022,
        },
    ];

    useEffect(() => {
        if (upcomingTask && upcomingTask.upcoming_compliances && upcomingTask.upcoming_compliances.length > 0) {
            let temp = checkdata
            for (let i in upcomingTask.upcoming_compliances) {
                temp.push(upcomingTask.upcoming_compliances[i])
            }
            setCheckdata([...temp])
        }
    }, [upcomingTask])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (checkdata) {
            const ab = checkdata.map(function (item) {
                if ((array.indexOf(item.unit_name) < 0))
                    array.push(item.unit_name)
            })
            array.map((data, i) => {
                // let array2 = [];
                let temp = [];
                let entityArray = [];
                if (data) {
                    checkdata.map((child, childIndex) => {
                        if (data === child.unit_name) {
                            entityArray = {
                                "address": child.address,
                                "assigned_on": child.assigned_on,
                                "category": child.category,
                                "compfielaw_url": child.compfielaw_url,
                                "compliance_description": child.compliance_description,
                                "compliance_name": child.compliance_name,
                                "domain_name": child.domain_name,
                                "due_date": child.due_date,
                                "frequency": child.frequency,
                                "division": child.division,
                                "penal_consequences": child.penal_consequences,
                                "start_date": child.start_date,
                                "statu": child.statu,
                                "unit_name": child.unit_name,
                                "upcoming_format_file_name": child.upcoming_format_file_name,
                                'childId': childIndex,
                                'parentId': i,
                                'indexNo': count1
                            }
                            temp.push(entityArray)
                            console.log(temp, 'TEMPPPPPPP')
                            { <span hidden>{count1 = count1 + 1}</span> }
                        }
                    })
                }

                let checkarray = []
                let listArray_One_time = _.filter(temp, { frequency: "One Time" });
                console.log(listArray_One_time, 'listArray_One_time')
                if (listArray_One_time && listArray_One_time.length > 0) {
                    for (let i in listArray_One_time) {
                        checkarray.push(listArray_One_time[i])
                    }
                }
                let listArray_periodical = _.filter(temp, { frequency: "Periodical" });
                if (listArray_periodical && listArray_periodical.length > 0) {
                    for (let i in listArray_periodical) {
                        checkarray.push(listArray_periodical[i])
                    }
                }
                let listArray_Review = _.filter(temp, { frequency: "Review" });
                if (listArray_Review && listArray_Review.length > 0) {
                    for (let i in listArray_Review) {
                        checkarray.push(listArray_Review[i])
                    }
                }
                let listArray_Flexi_Review = _.filter(temp, { frequency: "Flexi Review" });
                if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
                    for (let i in listArray_Flexi_Review) {
                        checkarray.push(listArray_Flexi_Review[i])
                    }
                }
                let listArray_On_Occurrence = _.filter(temp, { frequency: "On Occurrence" });
                if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
                    for (let i in listArray_On_Occurrence) {
                        checkarray.push(listArray_On_Occurrence[i])
                    }
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: checkarray
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
            // getData()
        }

    }, [checkdata])
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value.trim(" ")] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const submitpassword = () => {
        setAddFormSubmit1(true)

        if (formValidator1.current.allValid()) {
            if (fileupload.length == 0) {
                const pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateComplianceDetail",
                            {
                                "le_id": showdata.legal_entity,
                                "compliance_history_id": valueModal.compliance_history_id,
                                "documents": fileupload && fileupload.length > 0 ? fileupload : null,
                                "uploaded_documents": null,
                                "removed_documents": [],
                                "completion_date": moment(completionDate && completionDate._d).format('DD-MMM-YYYY'),
                                "validity_date": valueModal.validity_date !== null ? moment(valueModal.validity_date._d).format('DD-MMM-YYYY') : null,
                                "next_due_date": valueModal.next_due_date !== null ? valueModal.next_due_date : null,
                                "remarks": null,
                                "file_size": 0,
                                "tz_date": moment(new Date()).format('DD-MMM-YYYY hh:mm'),
                                "doc_ref_num": valueModal.document_reference_number != '' ? valueModal.document_reference_number : null,
                                "password": passwordata.passvalue,
                                "pwd_validate": true
                            }
                        ]
                    }
                ]
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetCurrentComplianceDetail",
                            {
                                "le_id": showdata.legal_entity,
                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                "current_start_count": 0,
                                "cal_view": null,
                                "cal_date": null,
                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                            }
                        ]
                    }
                ]
                saveStatus({
                    payload: pay,
                    paramid: paramid,
                    setValueModal: setValueModal,
                    setCompletionDate: setCompletionDate,
                    SetRemarks: SetRemarks,
                    valueModal: valueModal,
                    nextData: nextDatas,
                    payload2: payload,
                    Setpassvisible: Setpassvisible
                })
            }
            if (fileupload.length > 0) {
                UploadFile1({
                    payload: [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UploadComplianceTaskFileNew",
                                {
                                    "le_id": showdata && showdata.legal_entity,
                                    "c_id": 1,
                                    "selected_task_info": [
                                        {
                                            "d_id": Number(valueModal.domain_id),
                                            "u_id": Number(valueModal.unit_id),
                                            "start_date": valueModal.start_date,
                                            "file_info": fileupload,
                                            "compliance_history_id": Number(valueModal.compliance_history_id)
                                        }
                                    ],
                                    "type": "",
                                    "password": "",
                                    "pwd_validate": false

                                }
                            ]
                        }


                    ],









                    paramid: paramid
                })
            }

        }
        Setpassvisible(false)

    }
    const showValue = () => {
        setShowValueModal(true);
    };
    useEffect(() => {
        if (nextRecords.length > 0) {
            setValueModal({
                ...valueModal,
                domain_id: nextRecords && nextRecords[0].domain_id,
                start_date: nextRecords && nextRecords[0].start_date,
                uploaded_document: nextRecords && nextRecords[0].uploaded_documents,
                actiondata: nextRecords && nextRecords[0].action,
                approve_status: nextRecords && nextRecords[0].approve_status,
                action: nextRecords && nextRecords[0].compliance_status,
                unit_id: nextRecords && nextRecords[0].unit_id,
                due_date: nextRecords && nextRecords[0].due_date,
                completion_date: nextRecords && nextRecords[0].completion_date,
                remarks: nextRecords && nextRecords[0].remarks,
                compliance_description: nextRecords && nextRecords[0].compliance_description,
                description: nextRecords && nextRecords[0].compliance_description,
                compliance_history_id: nextRecords && nextRecords[0].compliance_history_id,
                unit: nextRecords && nextRecords[0].unit_name,
                address: nextRecords && nextRecords[0].address,
                recall: nextRecords && nextRecords[0].recallremarks,
                compliance_task: nextRecords && nextRecords[0].compliance_name,
                compliance_frequency: nextRecords && nextRecords[0].compliance_task_frequency,
                document_issued_date: nextRecords && nextRecords[0].completion_date,
                document_reference_number: nextRecords && nextRecords[0].document_reference_number,
                next_due_date1: nextRecords && nextRecords[0].next_due_date != null ? nextRecords && nextRecords[0].next_due_date : null,
                next_due_date: nextRecords && nextRecords[0].next_due_date != null ? nextRecords && nextRecords[0].next_due_date : nextRecords && nextRecords[0].due_date,
                history_count: nextRecords && nextRecords[0].remarks_count,
                uniqueId: nextRecords && nextRecords[0].uniqueId,
                occurrence_remarks: nextRecords && nextRecords[0].occurrence_remarks,
                status: nextRecords && nextRecords[0].compliance_status,
                address: nextRecords && nextRecords[0].address

            })
            setValueForFileUpload({
                d_id: nextRecords && nextRecords[0].domain_id,
                u_id: nextRecords && nextRecords[0].unit_id,
                start_date: nextRecords && nextRecords[0].start_date,
            })
            showValue()
            setCompletionDate('')
            SetRemarks('')
        }
        else {
            setShowValueModal(false)
        }

    }, [nextRecords])
    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    autoFocus={'on'}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    // const CustomToolbar=()=>{
    //     return (
    //         <div className="toolbar-container">

    //           <div className="back-next-buttons">
    //             <button className="btn btn-back">
    //               <BackIcon/>
    //             </button>
    //             <label className='label-date'>Aug-Sept 2016</label>
    //           </div>

    //           <div className="filter-container">
    //             <ButtonGroup>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Day</span></Button>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Week</span></Button>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Month</span></Button>
    //               <Button className="bg-filter-off"><span className="label-filter-off">Year</span></Button>
    //             </ButtonGroup>


    //           </div>
    //         </div >
    //       )
    // }
    const CustomToolbar = (e) => {
        let date1 = new Date()
        let month = new Date(
            date1.getFullYear(),
            date1.getMonth() + 5,
            5);

        return (

            <div className="col-md-12">

                <div className="row">

                    <div className="col-md-4">
                        {(moment(e.date).format('MMM') == moment(new Date()).format('MMM')) ? false :
                            <Button icon={<DoubleLeftOutlined />}
                                onClick={() => {
                                    let date1 = e.date
                                    let date = new Date(
                                        date1.getFullYear(),
                                        date1.getMonth() - 1,
                                        1)

                                    let startMonth = moment(date).startOf('month');
                                    const payload = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "GetCalendarView",
                                                {
                                                    "le_id": showdata.legal_entity,
                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                    "cal_date": ((moment((currentMonth && currentMonth._d)).format('DD-MMM-YYYY')) === (moment(startMonth._d).format('DD-MMM-YYYY'))) ? null : moment(startMonth._d).format('DD-MMM-YYYY'),
                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                    "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                    "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                }
                                            ]
                                        }
                                    ]
                                    if (showdata.legal_entity != '') {
                                        getCalenderView({
                                            payload: payload,
                                            paramid: paramid
                                        })
                                    }
                                    setDate(date)


                                }}>



                            </Button>}

                    </div>

                    <div className="col-md-4 text-center align-self-center">

                        <label ><b>{e.label.replace(" ", " - ")}</b></label>

                    </div>

                    <div className="col-md-4" >
                        {(moment(e.date).format('MMM') == moment(month).format('MMM')) ? false :
                            <Button
                                onClick={() => {
                                    let date1 = e.date
                                    let date = new Date(
                                        date1.getFullYear(),
                                        date1.getMonth() + 1,
                                        1);

                                    let startMonth = moment(date).startOf('month');

                                    const payload = [
                                        authtoken,
                                        {
                                            "session_token": authtoken,
                                            "request": [
                                                "GetCalendarView",
                                                {
                                                    "le_id": showdata.legal_entity,
                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                    "cal_date": ((moment((currentMonth && currentMonth._d)).format('DD-MMM-YYYY')) === (moment(startMonth._d).format('DD-MMM-YYYY'))) ? null : moment(startMonth._d).format('DD-MMM-YYYY'),
                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                    "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                    "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                }
                                            ]
                                        }
                                    ]
                                    if (showdata.legal_entity != '') {
                                        getCalenderView({
                                            payload: payload,
                                            paramid: paramid
                                        })
                                    }
                                    setDate(date)


                                }}
                                icon={<DoubleRightOutlined />} style={{ float: 'right' }}>



                            </Button>


                        }
                    </div>

                </div>



            </div>

        )

    }

    const showmore = () => {
        setShowMore(true)
        setCount(counts + 100)

        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetCurrentComplianceDetail",
                    {
                        "le_id": showdata && showdata.legal_entity,
                        "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                        "current_start_count": counts + 100,
                        "cal_view": type == 'over Due' ? "OVERDUE" : type == 'Task Recall' ? "TASKRECALL" : type == 'Inprogress' || type == 'Due Date' ? "INPROGRESS" : null,
                        "cal_date": null,
                        "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                        "division_id": showdata.division != '' ? Number(showdata.division) : null,
                        "category_id": showdata.category != '' ? Number(showdata.category) : null,
                        "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                    }
                ]
            }
        ]
        console.log(payload, 'payload565555555');
        getCurrentTaskDetails({

            payload: payload,
            paramid: paramid
        })
    }


    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    useEffect(() => {
        if (fileupload && fileupload.length > 0) {
            const filePayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    // "request": [
                    //     "UploadComplianceTask_temp_upload",
                    //     {
                    //         "le_id": showdata.legal_entity,
                    //         "c_id": 1,
                    //         "d_id": valueForFileUpload.d_id,
                    //         "u_id": valueForFileUpload.u_id,
                    //         "start_date": valueForFileUpload.start_date,
                    //         "file_info": results,
                    //         "compliance_history_id": Number(valueModal.compliance_history_id),
                    //     }
                    // ]
                    "request": [
                        "UploadComplianceTask_temp_upload",
                        {
                            "le_id": showdata.legal_entity,
                            "c_id": 1,
                            "d_id": valueForFileUpload.d_id,
                            "u_id": valueForFileUpload.u_id,
                            "start_date": valueForFileUpload.start_date,
                            "file_info": fileupload,
                            "compliance_history_id": Number(valueModal.compliance_history_id),
                        }
                    ]
                }
            ]
            console.log(filePayload, 'filePayload555');
            UploadFile({
                payload: filePayload,
                paramid: paramid
            })
        }
    }, [fileupload])
    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': size,
            'file_name': name,
            'file_content': content,

        };
        setFileSize(size)

        return result;
    }

    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }
    const SavepastRecordOnChange = (e) => {
        if (e.target.name === 'file') {
            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            let files = e.target.files
            let file_max_size = 26214400
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                if (fileRegex.test(f_name) === false) {
                    return;
                }
                let file_size = file.size;
                if (file_size > file_max_size) {
                    toast.error("Maximum filesize limit exceed")
                    return;
                }
                if (file) {
                    convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                        var fN = name.substring(0, name.lastIndexOf('.'));
                        var fE = name.substring(name.lastIndexOf('.') + 1);
                        var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                        var f_Name = fN + '-' + uniqueId + '.' + fE;

                        var result = uploadFileFormat(size, f_Name, file_content);
                        setFileData(result)
                        results.push(result);


                        let temp1 = fileupload
                        let temp = []
                        if (results && results.length == 1) {
                            for (let i in results) {
                                temp1.push(results[i])
                            }
                            setFileUpload([...temp1])
                        }
                        else {
                            for (let i in results) {
                                temp.push(results[i])
                            }
                            setFileUpload([...temp])
                        }


                    });
                }

            }
        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            )
        })
    }
    const columns = [
        // {
        //     title: <Fragment>
        //         <input type="checkbox" name="allchecked" />
        //     </Fragment>,
        //     dataIndex: false,
        //     key: false,
        //     ellipsis: true,
        //     width: '7px',
        //     align: 'center',
        //     render: (text, record) => {
        //         return (
        //             <Fragment>
        //                 <input type="checkbox"
        //                     name={'checked' + record.parentId}
        //                     onClick={(e) => {
        //                         let checked = e.target.checked;
        //                         addSelectedDataValues(
        //                             checked,
        //                             record.compliance_history_id,
        //                         );
        //                     }}
        //                 />
        //             </Fragment>
        //         )
        //     }
        // },
        {
            title: "#",
            render: (row, record, index) => {
                if (record.frequency == 'Periodical') {
                    return <Tooltip title={record && record.frequency}><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span>
                        <i style={{ color: "green" }} class="bi bi-square-fill"></i>
                    </p></Tooltip>
                } else if (record.frequency == 'FlexiReview') {
                    return <Tooltip title={record && record.frequency}><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "pink" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (record.frequency == 'Review') {
                    return <Tooltip title={record && record.frequency}><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "orange" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
                else if (record.frequency == 'On Occurrence') {
                    return <Tooltip title={record && record.frequency}><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "yellow" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else {
                    return <Tooltip title={record && record.frequency}><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "red" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
            },
            width: '30px',
            align: 'center',
            filters: [
                {
                    text: <span><i style={{ color: "green" }} class="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "pink" }} class="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'FlexiReview',
                },
                {
                    text: <span><i style={{ color: "orange" }} class="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "yellow" }} class="bi bi-square-fill"></i> On Occurrence</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "red" }} class="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.frequency == value,
            //: 'tree',
        },
        {
            title: "Compliance Task",
            dataIndex: 'compliance_name',
            key: 'compliance_name',
            ...getColumnSearchProps('compliance_name', 'Compliance Task'),


            // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
            render: (row, record) => {

                const content = (
                    <table style={{ display: 'block', width: '500px' }}  >
                        <tbody>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Unit Name</th>
                                <td style={{ border: "1px solid #636262" }}>{record.unit_name}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Address</th>
                                <td style={{ border: "1px solid #636262" }}>{record.address}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Division</th>
                                <td style={{ border: "1px solid #636262" }}>{record.division}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Category</th>
                                <td style={{ border: "1px solid #636262" }}>{record.category}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Act / Rules</th>
                                <td style={{ border: "1px solid #636262" }}>{record.statu}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Compliance Description</th>
                                <td style={{ border: "1px solid #636262" }}>{record.compliance_description}</td>
                            </tr>
                            <tr style={{ borderRight: '1px solid grey' }}>
                                <th style={{ border: "1px solid #636262" }}>Penal Consequences</th>
                                <td style={{ border: "1px solid #636262" }}>{record.penal_consequences}</td>
                            </tr>
                            {console.log(record, 'recorddddddddddd')}
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <span
                        // onClick={() => {
                        //     showValue()
                        //     setValueModal({
                        //         compliance_history_id: record.compliance_history_id,
                        //         unit: record.unit_name,
                        //         compliance_task: record.compliance_name,
                        //         compliance_frequency: record.compliance_frequency,
                        //         document_issued_date: record.completion_date,
                        //         document_reference_number: record.document_reference_number,
                        //         next_due_date: record.next_due_date,
                        //         address: record.address,
                        //         compliance_description: record.compliance_description,
                        //         uploaded_document: record.upload_docs,
                        //         interim_details: record.interim_docs,
                        //         remarks: record.remarks

                        //     })
                        // }}
                        >{record.statu}</span>
                        <div>
                            <div className="mb-0">
                                <Tooltip title={content}>
                                    {/* <i class="bi-info-circle-fill" style={{ color: '#5DAAE3' }}></i> */}
                                    <InfoCircleOutlined style={{ color: '#5DAAE3' }} />
                                </Tooltip>{record.compliance_name}
                            </div>
                            <span>Assigned on : {record.assigned_on}</span>
                        </div>

                    </>
                )
            },
            width: '250px',
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        },
        {
            title: "Domain",
            dataIndex: 'domain_name',
            key: 'domain_name',
            width: '135px',
            ...getColumnSearchProps('domain_name', 'Domain'),


        },
        {
            title: "Start Date",
            dataIndex: 'start_date',
            key: 'start_date',
            ellipsis: true,
            width: '60px',
            ...getColumnSearchProps('start_date', 'Start Date'),

        },

        {
            title: "Due Date",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '60px',
            ...getColumnSearchProps('due_date', 'Due Date'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }

        },
        {
            title: "Format",
            dataIndex: 'compliance_file_name',
            align: 'center',
            key: 'compliance_file_name',
            width: '40px',
            render: (row, record) => {
                console.log(record, 'record')
                let file = record.upcoming_format_file_name && record.upcoming_format_file_name.length > 0 && record.upcoming_format_file_name[0]
                return (
                    <>
                        {
                            record.upcoming_format_file_name && record.upcoming_format_file_name.length > 0 ?
                                <>
                                    <Tooltip title='Download Format'>
                                        <DownloadOutlined
                                            onClick={() => {
                                                console.log(file, 'file')
                                            }}
                                        />
                                    </Tooltip>
                                </> : ''
                        }
                    </>
                )
            }



        },


    ];
    const columns2 = [
        // {
        //     title: <Fragment>
        //         <input type="checkbox" name="allchecked" />
        //     </Fragment>,
        //     dataIndex: false,
        //     key: false,
        //     ellipsis: true,
        //     width: '7px',
        //     align: 'center',
        //     render: (text, record) => {
        //         return (
        //             <Fragment>
        //                 <input type="checkbox"
        //                     name={'checked' + record.parentId}
        //                     onClick={(e) => {
        //                         let checked = e.target.checked;
        //                         addSelectedDataValues(
        //                             checked,
        //                             record.compliance_history_id,
        //                         );
        //                     }}
        //                 />
        //             </Fragment>
        //         )
        //     }
        // },
        {
            title: "Sno",
            render: (row, record, index) => {
                if (record.compliance_task_frequency == 'Periodical') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "green" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (record.compliance_task_frequency == 'FlexiReview') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "pink" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (record.compliance_task_frequency == 'Review') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "orange" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (record.compliance_task_frequency == 'On Occurrence') {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "yellow" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else {
                    return <Tooltip title={record && record.compliance_task_frequency
                    }><p><span style={{ padding: '5px', display: 'block' }}>{index + 1}</span><i style={{ color: "red" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
            },
            width: '30px',
            align: 'center',
            filters: [
                {
                    text: <span><i style={{ color: "green" }} class="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "pink" }} class="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'FlexiReview',
                },
                {
                    text: <span><i style={{ color: "orange" }} class="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "yellow" }} class="bi bi-square-fill"></i> On Occurrence</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "red" }} class="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.compliance_task_frequency == value,
            // onFilter: (value, record) => record.compliance_task_frequency == value,
            //: 'tree',
            // ...getColumnSearchProps('compliance_frequency'), record.compliance_task_frequency.startsWith(value)
        },
        {
            title: "Compliance Task",
            dataIndex: 'compliance_name',
            key: 'compliance_name',
            ...getColumnSearchProps('compliance_name', 'Compliance Task'),


            // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
            render: (row, record) => {
                const content = (
                    <table style={{ width: '500px' }}>
                        <tbody>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Unit Name</th>
                                <td style={{ border: "1px solid #636262" }}>{record.unit_name}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Address</th>
                                <td style={{ border: "1px solid #636262" }}>{record.address}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Division</th>
                                <td style={{ border: "1px solid #636262" }}>{record.division}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Category</th>
                                <td style={{ border: "1px solid #636262" }}>{record.category}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Act / Rules</th>
                                <td style={{ border: "1px solid #636262" }}>{record.statu}</td>
                            </tr>
                            <tr>
                                <th style={{ border: "1px solid #636262" }}>Compliance Description</th>
                                <td style={{ border: "1px solid #636262" }}>{record.compliance_description}</td>
                            </tr>
                            <tr style={{ borderRight: '1px solid grey' }}>
                                <th style={{ border: "1px solid #636262" }}>Penal Consequences</th>
                                <td style={{ border: "1px solid #636262" }}>{record.penal_consequences}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <span style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                            onClick={() => {
                                console.log(record, 'recordrecord')
                                setShowValueModal(true)
                                setValueModal({
                                    ...valueModal,
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.compliance_description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    address: record.address,
                                    due_date: record.due_date,
                                    recall: record.recallremarks,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.document_reference_number,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    next_due_date1: record.next_due_date != null ? record.next_due_date : null,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks,
                                    status: record.compliance_status
                                })
                            }}
                        // onClick={() => {
                        //     showValue()
                        //     setValueModal({
                        //         compliance_history_id: record.compliance_history_id,
                        //         unit: record.unit_name,
                        //         compliance_task: record.compliance_name,
                        //         compliance_frequency: record.compliance_frequency,
                        //         document_issued_date: record.completion_date,
                        //         document_reference_number: record.document_reference_number,
                        //         next_due_date: record.next_due_date,
                        //         address: record.address,
                        //         compliance_description: record.compliance_description,
                        //         uploaded_document: record.upload_docs,
                        //         interim_details: record.interim_docs,
                        //         remarks: record.remarks

                        //     })
                        // }}
                        >{record.statu}</span>
                        <div>
                            <div className="mt-2 mb-2" onClick={() => {
                                setShowValueModal(true)
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                                setValueModal({
                                    ...valueModal,
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.compliance_description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    address: record.address,
                                    due_date: record.due_date,
                                    recall: record.recallremarks,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.document_reference_number,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    next_due_date1: record.next_due_date != null ? record.next_due_date : null,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks,
                                    status: record.compliance_status
                                })
                            }}>
                                <Tooltip title={content}>
                                    {/* <Button style={{ border: 0 }}> */}
                                    <span style={{ marginRight: '3px' }}>
                                        <i class="bi-info-circle-fill" style={{ color: '#5DAAE3' }}></i>
                                    </span>
                                    {/* </Button> */}
                                    <span style={{ color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}></span> </Tooltip> <a >{record.compliance_name}</a>
                            </div>
                        </div>

                    </>
                )
            },
            width: '150px',
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        },
        {
            title: "Domain",
            dataIndex: false,
            key: false,
            width: '140px',
            ...getColumnSearchProps('domain_name', 'Domain'),
            render: (row, record) => {

                return (<p style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }} onClick={() => {
                    setShowValueModal(true)
                    setValueForFileUpload({
                        d_id: record.domain_id,
                        u_id: record.unit_id,
                        start_date: record.start_date,
                    })
                    setValueModal({
                        ...valueModal,
                        domain_id: record.domain_id,
                        start_date: record.start_date,
                        uploaded_document: record.uploaded_documents,
                        actiondata: record.action,
                        approve_status: record.approve_status,
                        unit_id: record.unit_id,
                        completion_date: record.completion_date,
                        remarks: record.remarks,
                        description: record.compliance_description,
                        compliance_history_id: record.compliance_history_id,
                        unit: record.unit_name,
                        address: record.address,
                        due_date: record.due_date,
                        recall: record.recallremarks,
                        compliance_task: record.compliance_name,
                        compliance_frequency: record.compliance_task_frequency,
                        document_issued_date: record.completion_date,
                        document_reference_number: record.document_reference_number,
                        next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                        next_due_date1: record.next_due_date != null ? record.next_due_date : null,
                        history_count: record.history_count,
                        occurrence_remarks: record.occurrence_remarks,
                        status: record.compliance_status
                    })
                }}>{record.domain_name}</p>
                )
            },


        },
        {
            title: "Start Date",
            dataIndex: 'start_date',
            key: 'start_date',
            // ellipsis: true,
            width: '100px',
            align: 'center',
            ...getColumnSearchProps('start_date', 'Start Date'),
            render: (row, record) => {

                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true)
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        })
                        setValueModal({
                            ...valueModal,
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            due_date: record.due_date,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        })
                    }}>{moment(record.start_date).format('DD-MMM-YYYY')}</p>
                )
            },


        },

        {
            title: "Due Date",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '100px',
            align: 'center',
            ...getColumnSearchProps('due_date', 'Due Date'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {

                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true)
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        })
                        setValueModal({
                            ...valueModal,
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            due_date: record.due_date,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        })
                    }}
                >{record.due_date}</p>
                )
            },

        },
        {
            title: "Days",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '80px',
            ...getColumnSearchProps('due_date', 'Days'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {
                let split = record && record.ageing.split(" ")
                console.log(split, 'split8888888888')
                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : 'red' }}
                    onClick={() => {
                        setShowValueModal(true)
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        })
                        setValueModal({
                            ...valueModal,
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            due_date: record.due_date,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        })
                    }}
                >{split && split.length == 6 ? split[2] : split[3]} {split && split.length == 6 ? split[3] : split[4]}</p>
                )
            },

        },
        {
            title: "Time Line",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '100px',
            ...getColumnSearchProps('due_date', 'Time Line'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {

                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true)
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        })
                        setValueModal({
                            ...valueModal,
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            due_date: record.due_date,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        })
                    }}
                >{(moment(record.due_date).isSameOrAfter(today)) ? "Left" : "Over Due"}</p>
                )
            },

        },
        {
            title: "Status",
            dataIndex: 'due_date',
            key: 'due_date',
            width: '80px',
            ...getColumnSearchProps('due_date', 'Status'),
            // render: (text, record) => {
            //     return moment(record.due_date).format('DD-MMM-YYYY')
            // }
            render: (row, record) => {

                return (<p
                    style={{ cursor: 'pointer', color: record.compliance_status == 'Recall' ? 'brown' : record.compliance_status == 'Rectify' ? 'red' : 'black' }}
                    onClick={() => {
                        setShowValueModal(true)
                        setValueForFileUpload({
                            d_id: record.domain_id,
                            u_id: record.unit_id,
                            start_date: record.start_date,
                        })
                        setValueModal({
                            ...valueModal,
                            domain_id: record.domain_id,
                            start_date: record.start_date,
                            uploaded_document: record.uploaded_documents,
                            actiondata: record.action,
                            approve_status: record.approve_status,
                            unit_id: record.unit_id,
                            due_date: record.due_date,
                            completion_date: record.completion_date,
                            remarks: record.remarks,
                            description: record.compliance_description,
                            compliance_history_id: record.compliance_history_id,
                            unit: record.unit_name,
                            address: record.address,
                            recall: record.recallremarks,
                            compliance_task: record.compliance_name,
                            compliance_frequency: record.compliance_task_frequency,
                            document_issued_date: record.completion_date,
                            document_reference_number: record.document_reference_number,
                            next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                            history_count: record.history_count,
                            occurrence_remarks: record.occurrence_remarks,
                            status: record.compliance_status
                        })
                    }}
                >{record.compliance_status}</p>
                )
            },

        },
        {
            title: "Format",
            dataIndex: 'compliance_file_name',
            key: 'compliance_file_name',
            align: 'center',
            width: '60px',
            render: (row, record) => {
                console.log(record, 'record2')
                let file = record.compliance_file_name && record.compliance_file_name.length > 0 && record.compliance_file_name[0]
                return (
                    <div className='text-center'>
                        {
                            record.compliance_file_name && record.compliance_file_name.length > 0 ?
                                <>
                                    <Tooltip title='Download Format'>
                                        <DownloadOutlined
                                            onClick={() => {
                                                fileListTemp({
                                                    payload: [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "File_List_Temp",
                                                                {
                                                                    "le_id": showdata && showdata.legal_entity,
                                                                    "compliance_history_id": Number(record.compliance_history_id),
                                                                    "u_id": Number(record.unit_id)
                                                                }
                                                            ]
                                                        }
                                                    ],
                                                    paramid: paramid
                                                })
                                                window.open(urls + '' + file)
                                            }}
                                        />
                                    </Tooltip>
                                </> : ''
                        }
                    </div>
                )
            }






        },


    ];
    const download = (item) => {
        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFile_temp",
                        {
                            "le_id": showdata.legal_entity,
                            "c_id": 1,
                            "d_id": valueForFileUpload.d_id,
                            "u_id": valueForFileUpload.u_id,
                            "start_date": valueForFileUpload.start_date,
                            "compliance_history_id": valueModal.compliance_history_id,
                            "upload_type": 2,
                            "file_name": item,
                        }
                    ]
                    // "request": [
                    //     "DownloadFile_temp",
                    //     {
                    //         "le_id": "zppX",
                    //         "c_id": 1,
                    //         "d_id": 1,
                    //         "u_id": 38781,
                    //         "start_date": "08-Mar-2023 12:00",
                    //         "file_name": "Registers 1-28726-21192-59458.pdf",
                    //         "upload_type": 2,
                    //         "compliance_history_id": 1432
                    //     }
                    // ]

                }
            ]
        console.log(downloadpayload, 'downloadpayload');
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });
        // if (item) {
        //     window.open('https:/' + item);
        // }
    }
    const Remove = (index) => {

        let temp = fileupload
        for (let i in fileupload) {
            const removepayload =
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "RemoveFile_temp",
                            {
                                "le_id": showdata.legal_entity,
                                "c_id": 1,
                                "selected_task_info": [
                                    {
                                        "d_id": valueForFileUpload.d_id,
                                        "u_id": valueForFileUpload.u_id,
                                        "start_date": valueForFileUpload.start_date,
                                        "compliance_history_id": valueModal.compliance_history_id,
                                        "file_info": [
                                            {
                                                "file_size": fileupload.file_size,
                                                "file_name": fileupload.file_name,
                                                "file_content": null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            removedocument({
                payload: removepayload,
                paramid: paramid
            })


            if (fileupload[i]['file_name'] == index) {
                fileupload.splice(i, 1)
            }

        }
        setFileUpload([...fileupload])

    }

    return (
        <div className="page-wrapper" id='page-wrapper'>
            {/* {document.body.scrollHeight > document.body.clientHeight ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''}
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} /> */}
            <div className='back-to-top'>
                <ScrollButton />
            </div>

            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <nav className="col-lg-5 col-md-6 col-12 align-self-center" aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 d-flex align-items-center">
                            <li className="breadcrumb-item">
                                <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i>
                                </span>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                <span style={{ fontSize: '16px' }}>Transaction</span>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: 'bold' }}>
                                <span>Compliance Task Details</span>
                            </li>
                        </ol>
                    </nav>
                    <div className="col-lg-7 text-end">
                        {/* <Button 
                className="mx-1"
                style={{
                    display: filterTaskAcc ? "none" : "initial",
                  }}
                 
                shape="round" disabled>

                            {"Total compliance"} : {compliancefiltershow.total_count ? compliancefiltershow.total_count : '0'}
                            </Button>
                            <Button 
                className="mx-1"
                style={{
                    display: filterTaskAcc ? "none" : "initial",
                  }}
                type="primary"
                shape="round" disabled>

                            {/* {"Total Units"} : {tempshowdata ? tempshowdata.length : '0'} 
                            </Button> */}
                        <Button shape={'round'} className='mx-1' style={{ display: tab == '2' ? 'initial' : 'none' }} disabled>Over Due : <span> &nbsp; {currentCompliance?.overdue_count}</span></Button>
                        <Button shape={'round'} className='mx-1' style={{ display: tab == '2' ? 'initial' : 'none' }} disabled>In-progress : <span> &nbsp; {currentCompliance?.inprogress_count}</span></Button>
                        <Button shape={'round'} className='mx-1' style={{ display: tab == '2' ? 'initial' : 'none' }} disabled>Task Recall : <span> &nbsp; {currentCompliance?.recall_count}</span></Button>
                        <Button shape={'round'} className='mx-1' style={{ display: tab == '3' ? 'initial' : 'none' }} disabled>Upcoming Compliance : <span> &nbsp; {upcomingTask?.total_count}</span> </Button>

                        <Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button>

                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-1 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                title={fullscreen ? "Exit Full Screen Mode" : "Full Screen Mode"}
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div style={{ borderRadius: '0px' }} className={localStorage.getItem('currentTheme')}>
                            <Collapse accordion defaultActiveKey={['1']}
                                style={{ display: exitCollapse ? "none" : "block" }}>
                                <Panel header="Compliance Task Details" key="1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4" >
                                                {/* <div className="col-md-8" > */}
                                                {/* <div className="mb-3"> */}
                                                {/* <label>Legal Entity:</label> */}
                                                <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}>*</span><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select

                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Select Legal Entity"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    // Setlegalstate(true)
                                                                    // setCurrentEntity(data)
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        legal_entity: data,

                                                                    })
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        legal_entity: '',
                                                                        domain: '',
                                                                        division: '',
                                                                        category: '',
                                                                        unit: '',
                                                                        frequency: ''

                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.legal_entity || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'legal_entity',
                                                            showdata.legal_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                                {/* </div> */}
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Domain: </b></label><br />
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        disabled={showdata.legal_entity ? false : true}
                                                        name="domain"
                                                        id="domain"
                                                        placeholder="Enter domain"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: data,
                                                                    // unit: '',
                                                                    // frequency: '',
                                                                    // asignee: '',
                                                                    // due_month: '',
                                                                    // act: ''

                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: '',
                                                                    // unit: '',
                                                                    // frequency: '',
                                                                    // asignee: '',
                                                                    // due_month: '',
                                                                    // act: ''

                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.domain || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    // disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        {domain && domain.length > 0 && domain.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {/* {formValidator.current.message(
                                                        'domain',
                                                        showdata.domain,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })} */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Division: </b></label><br />
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Division"
                                                        disabled={showdata.legal_entity ? false : true}
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    division: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    division: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.division || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    // disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Category: </b></label>
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Category"
                                                        disabled={showdata.legal_entity ? false : true}
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    category: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    category: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.category || undefined}

                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {category && category.length > 0 && category.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">

                                                <div className="form-group">

                                                    <label htmlFor=""><b>Unit: </b></label>
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Unit"
                                                        disabled={showdata.legal_entity ? false : true}
                                                        onChange={(data) => {
                                                            console.log(data, 'showdata')
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    unit: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    unit: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.unit || undefined}

                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {units && units.length > 0 && units.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {/* {formValidator.current.message(
                                                        'date',
                                                        showdata.due_month,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Due Month Required',
                                                            }
                                                        })} */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Frequency: </b></label>
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        disabled={showdata.legal_entity ? false : true}
                                                        defaultValue={"All"}
                                                        placeholder="Enter Frequency"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: ''
                                                                })
                                                            }
                                                        }}
                                                        // showSearch
                                                        // optionFilterProp="children"
                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.frequency || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    // disabled={showdata.legal_entity === "" ? true : false}
                                                    >
                                                        <Option key=''>All</Option>
                                                        {frequency && frequency.length > 0 && frequency.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-md-12 mt-3 popupbtncolour " + localStorage.getItem('currentTheme')}>
                                        {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                        {/* <a href={() => false} style={{ marginLeft: '40%' }} onClick={gettaskrecalllist} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</a> */}
                                        <Button shape="round" className={
                                            "addbutton " + localStorage.getItem("currentTheme")
                                        }
                                            style={{ marginLeft: '46%', marginTop: "5px" }}
                                            size='default'
                                            icon={<EyeOutlined />} onClick={onsubmitData}
                                        >Show
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                onCancel={setcancelFilter}
                                footer={false}
                                title="Compliance Task Details"
                                className={
                                    "add-service-prv remark-header cons-report " +
                                    localStorage.getItem("currentTheme")
                                }
                                visible={filterModalVisible}
                            >
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4" >
                                            {/* <div className="col-md-8" > */}
                                            {/* <div className="mb-3"> */}
                                            {/* <label>Legal Entity:</label> */}
                                            <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}>*</span><br />
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="form-group">
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}

                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Select Legal Entity"
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                // Setlegalstate(true)
                                                                // setCurrentEntity(data)
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    legal_entity: data,

                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    legal_entity: '',
                                                                    domain: '',
                                                                    division: '',
                                                                    category: '',
                                                                    unit: '',
                                                                    frequency: ''

                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.legal_entity || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {formValidator.current.message(
                                                        'legal_entity',
                                                        showdata.legal_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div> :
                                                <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                            }
                                            {/* </div> */}
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Domain: </b></label><br />
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={false}
                                                    size="default"
                                                    name="domain"
                                                    id="domain"
                                                    placeholder="Enter domain"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                domain: data,
                                                                // unit: '',
                                                                // frequency: '',
                                                                // asignee: '',
                                                                // due_month: '',
                                                                // act: ''

                                                            })
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                domain: '',
                                                                // unit: '',
                                                                // frequency: '',
                                                                // asignee: '',
                                                                // due_month: '',
                                                                // act: ''

                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.domain || undefined}
                                                    style={{ width: '100%', marginTop: "5px" }}
                                                // disabled={showdata.legal_entity === "" ? true : false}
                                                >
                                                    {domain && domain.length > 0 && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {/* {formValidator.current.message(
                                    'domain',
                                    showdata.domain,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Domain Required',
                                        }
                                    })} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Division: </b></label><br />
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter Division"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                division: data
                                                            })
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                division: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.division || undefined}
                                                    style={{ width: '100%', marginTop: "5px" }}
                                                // disabled={showdata.legal_entity === "" ? true : false}
                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Category: </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter Category"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                category: data
                                                            })
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                category: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.category || undefined}

                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    {category && category.length > 0 && category.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">

                                            <div className="form-group">

                                                <label htmlFor=""><b>Unit: </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter Unit"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                unit: data
                                                            })
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                unit: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.unit || undefined}

                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    {units && units.length > 0 && units.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {item.unit_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {/* {formValidator.current.message(
                                    'date',
                                    showdata.due_month,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Due Month Required',
                                        }
                                    })} */}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Frequency: </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={false}
                                                    size="default"
                                                    defaultValue={"All"}
                                                    placeholder="Enter Frequency"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                frequency: data
                                                            })
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                frequency: ''
                                                            })
                                                        }
                                                    }}
                                                    // showSearch
                                                    // optionFilterProp="children"
                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.frequency || undefined}
                                                    style={{ width: '100%', marginTop: "5px" }}
                                                // disabled={showdata.legal_entity === "" ? true : false}
                                                >
                                                    <Option key=''>All</Option>
                                                    {frequency && frequency.length > 0 && frequency.map((item, i) => {
                                                        return (
                                                            <Option key={item.frequency_id}>
                                                                {item.frequency_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-12 mt-3 popupbtncolour " + localStorage.getItem('currentTheme')}>
                                    {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                    {/* <a href={() => false} style={{ marginLeft: '40%' }} onClick={gettaskrecalllist} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</a> */}
                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: '46%', marginTop: "5px" }}
                                        size='default'
                                        onClick={onsubmitData}
                                        icon={<EyeOutlined />}
                                    >Show
                                    </Button>
                                </div>
                            </Modal>

                            {show == true ?

                                <div className="card p-2 rounded-3 mt-0">

                                    <Tabs type="card"
                                        defaultActiveKey='1'
                                        onChange={(key) => {
                                            console.log(key, 'tabbbbbbb')
                                            setTab(key)
                                            if (key == 1) {

                                                setDate(new Date())
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetCalendarView",
                                                            {
                                                                "le_id": showdata.legal_entity,
                                                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                "cal_date": null,
                                                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                            }
                                                        ]
                                                    }
                                                ]
                                                if (showdata.legal_entity != '') {
                                                    getCalenderView({
                                                        payload: payload,
                                                        paramid: paramid
                                                    })
                                                }
                                                setTempList([])
                                                setEventValue(0)

                                            }
                                            if (key == 2) {
                                                setStatus({
                                                    overDue: true,
                                                    inprogress: true,
                                                    recall: true
                                                })
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetCurrentComplianceDetail",
                                                            {
                                                                "le_id": showdata.legal_entity,
                                                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                "current_start_count": 0,
                                                                "cal_view": null,
                                                                "cal_date": null,
                                                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                            }
                                                        ]
                                                    }
                                                ]

                                                getCurrentTaskDetails({

                                                    payload: payload,
                                                    paramid: paramid
                                                })
                                                setTempList([])
                                                setEventValue(0)
                                            }
                                            if (key == 3) {
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetUpcomingComplianceDetail",
                                                            {
                                                                "le_id": showdata.legal_entity,
                                                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                "upcoming_start_count": 0,
                                                                "cal_view": null,
                                                                "cal_date": null,
                                                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                            }
                                                        ]
                                                    }
                                                ]

                                                getUpcomigTaskDetails({

                                                    payload: payload,
                                                    paramid: paramid
                                                })

                                            }


                                        }}
                                        //  onChange={() => {
                                        //     setCategory({
                                        //         compfie: '',
                                        //         compie_litigation: '',
                                        //         compfie_vendor: ''
                                        //     })
                                        // }}
                                        activeKey={tab}

                                    >

                                        <TabPane



                                            tab={
                                                <span
                                                    style={{ "margin-top": "-16px", padding: "0px 50px", fontSize: "16px" }}
                                                >
                                                    <b>Calendar View</b>
                                                </span>
                                            }
                                            key="1">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card">
                                                            <Calendar
                                                                style={{ height: '300pt', }}
                                                                tooltipAccessor={(e) => {
                                                                    return e.tooltip
                                                                }}
                                                                views={views}
                                                                // components={(e) => {
                                                                //     Event(e)
                                                                // }}
                                                                components={{

                                                                    toolbar: CustomToolbar
                                                                }}
                                                                eventPropGetter={(event, start, end, isSelected) => {
                                                                    var backgroundColor = event.color;
                                                                    var style = {
                                                                        backgroundColor: backgroundColor,
                                                                        borderRadius: '25px',
                                                                        float: 'left',
                                                                        fontSize: '10px !important',
                                                                        height: '20px',
                                                                        textAlign: 'center',
                                                                        lineHeight: '20px',
                                                                        // verticalAlign: 'middle',
                                                                        horizontalAlign: 'middle',
                                                                        marginLeft: '5px',
                                                                        width: '50px',
                                                                        // opacity: 0.8,
                                                                        // color: 'black',
                                                                        // border: '0px',
                                                                        // display: 'block'
                                                                    };
                                                                    return {
                                                                        style: style
                                                                    };
                                                                }}
                                                                date={date}
                                                                events={eventData}
                                                                startAccessor="start"
                                                                endAccessor="end"
                                                                defaultDate={moment().toDate()}
                                                                localizer={localizer}
                                                                eventOrder="horizontal"
                                                                defaultView='month'
                                                                onSelectEvent={onSelectEvent}
                                                                onNavigate={(date) => {
                                                                    let startMonth = moment(date).startOf('month');
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetCalendarView",
                                                                                {
                                                                                    "le_id": showdata.legal_entity,
                                                                                    "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                                    "cal_date": ((moment((currentMonth && currentMonth._d)).format('DD-MMM-YYYY')) === (moment(startMonth._d).format('DD-MMM-YYYY'))) ? null : moment(startMonth._d).format('DD-MMM-YYYY'),
                                                                                    "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                                    "div_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                                    "cat_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                                    "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    if (showdata.legal_entity != '') {
                                                                        getCalenderView({
                                                                            payload: payload,
                                                                            paramid: paramid
                                                                        })
                                                                    }
                                                                    setDate(date)
                                                                }}


                                                            />
                                                            <div className="col-md-12 mt-4 text-center">
                                                                <div className="row">

                                                                    <div style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                                                        <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                            <div style={{ background: ' #da6d25', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                            <div style={{ float: 'left' }}>Task Recall</div>
                                                                        </div>
                                                                        <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                            <div style={{ background: 'rgb(149 46 154)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                            <div style={{ float: 'left' }}>Due Date</div>
                                                                        </div>
                                                                        <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                            <div style={{ background: 'rgb(255 174 44)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                            <div style={{ float: 'left' }}>Inprogress</div>
                                                                        </div>
                                                                        <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                            <div style={{ background: '#f32d2b', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                            <div style={{ float: 'left' }}>Over Due</div>
                                                                        </div>
                                                                        <div style={{ display: 'inline-block', marginRight: '15px' }}>
                                                                            <div style={{ background: '  rgb(42 125 153)', height: '20px', width: '20px', borderRadius: '25px', float: 'left', marginRight: '5px' }}></div>
                                                                            <div style={{ float: 'left' }}>Upcoming</div>
                                                                        </div>
                                                                        {/* <div style="display: inline-block; margin-right: 15px; ">
                            <div style="background: rgb(149 46 154);height: 15px; width: 15px; border-radius:25px;float: left;margin-right: 5px;"></div>
                            <div style="float: left; ">Due Date</div>
                        </div>
                        <div style="display: inline-block;margin-right: 15px; ">
                            <div style="background:  rgb(255 174 44);height: 15px; width: 15px; border-radius:25px;float: left;margin-right: 5px;"></div>
                            <div style="float: left; ">Inprogress</div>
                        </div>
                        <div style="display: inline-block; margin-right: 15px;">
                            <div style="background:  #f32d2b;height: 15px; width: 15px;border-radius:25px;float: left;margin-right: 5px;"></div>
                            <div style="float: left; ">Over Due</div>
                        </div>
                        <div style="display: inline-block;margin-right: 15px; ">
                            <div style="background:  rgb(42 125 153);height: 15px; width: 15px; border-radius:25px;float: left;margin-right: 5px;"></div>
                            <div style="float: left; ">Upcoming</div>
                        </div> */}
                                                                        &nbsp; </div>


                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <Divider /> */}


                                            <div>


                                            </div>

                                        </TabPane>


                                        <TabPane
                                            // disabled={tabValue.compfie_litigation == false ? true : false}

                                            tab={

                                                <span style={{ padding: "0px 50px", fontSize: "16px" }}
                                                    onClick={() => {
                                                        setDataList([])
                                                    }}     >


                                                    <b>Current Tasks {tab == '2' ? `: ${moment(new Date()).format('DD-MMM-YYYY')} (UTC)` : false}</b>
                                                </span>
                                            }
                                            key="2">
                                            <div className="col-md-12">
                                                <div className="card p-4 py-2 mb-0 rounded user-mapping1 bg-white">
                                                    <div className="d-flex justify-content-between">
                                                        <div></div>
                                                        <div>
                                                            <i style={{ color: "red" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>One Time</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "green" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>Periodical</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "orange" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>Review</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "pink" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>Flexi Review</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "yellow" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>On Occurrence</label>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </div>

                                                <div
                                                    className={
                                                        localStorage.getItem("currentTheme") +
                                                        " ant-table-wrapper"
                                                    } style={{ width: '100%' }}
                                                >
                                                    <div className="row">

                                                        <div className="ant-table ant-table-bordered ant-table-fixed-header" style={{ width: showValueModal == true ? '60%' : '98%', height: showValueModal == true ? '750px' : '100%', overflowY: showValueModal == true ? 'scroll' : '' , marginLeft : '1%'}}>
                                                            <div className="ant-table-container">
                                                                <div className="ant-table-content taskrecalltable service-table">

                                                                    <table className="table-fixed" >
                                                                        <thead className="ant-table-thead">
                                                                            <tr>
                                                                                <th className="ant-table-cell" style={{ width: "5px" }}>
                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                        <span className="me-1">Sno </span>
                                                                                        <div className="position-relative" ref={wrapperRef}>
                                                                                            <button onClick={(e) => { setshowDropDown(current => !current) }}>
                                                                                                <FilterFilled />
                                                                                            </button>

                                                                                            {/* <button className="col-form-label" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                              <FilterFilled />
                                            </button> */}
                                                                                            {showDropDown && (
                                                                                                <Form onFinish={onFinish} className='bg-white position-absolute text-start shadow-lg' style={{ borderRadius: '15px', top: '32px', width: '165px' }}>

                                                                                                    <Form.Item className="mt-1" name={'periodical'} >
                                                                                                        &nbsp;<Checkbox id={'periodical'} value={'periodical'} checked={checked.periodical} onChange={(e) => {
                                                                                                            e.target.checked ? setChecked({
                                                                                                                ...checked,
                                                                                                                periodical: true
                                                                                                            }) : setChecked({
                                                                                                                ...checked,
                                                                                                                periodical: false
                                                                                                            })
                                                                                                            console.log(e.target.checked)
                                                                                                            setFilterValues({
                                                                                                                ...filterValues,
                                                                                                                periodical: e.target.checked
                                                                                                            })
                                                                                                        }} />&nbsp; <label className="fw-normal user-select-none" htmlFor="periodical"><i style={{ color: 'green' }} className="bi bi-square-fill"></i> Periodical</label>
                                                                                                    </Form.Item>
                                                                                                    <Form.Item name={'flexi'}>
                                                                                                        &nbsp;<Checkbox id="flexi" value={'flexi'} checked={checked.flexi} onChange={(e) => {
                                                                                                            e.target.checked ? setChecked({
                                                                                                                ...checked,
                                                                                                                flexi: true
                                                                                                            }) : setChecked({
                                                                                                                ...checked,
                                                                                                                flexi: false
                                                                                                            })
                                                                                                            console.log(e, e.target.value)
                                                                                                            setFilterValues({
                                                                                                                ...filterValues,
                                                                                                                flexi: e.target.checked
                                                                                                            })
                                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="flexi"><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</label>
                                                                                                    </Form.Item>
                                                                                                    <Form.Item name={'Review'}>
                                                                                                        &nbsp;<Checkbox id="Review" value={'Review'} checked={checked.review} onChange={(e) => {
                                                                                                            e.target.checked ? setChecked({
                                                                                                                ...checked,
                                                                                                                review: true
                                                                                                            }) : setChecked({
                                                                                                                ...checked,
                                                                                                                review: false
                                                                                                            })
                                                                                                            console.log(e, e.target.value)
                                                                                                            setFilterValues({
                                                                                                                ...filterValues,
                                                                                                                review: e.target.checked
                                                                                                            })
                                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="Review"><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</label>
                                                                                                    </Form.Item>
                                                                                                    <Form.Item name={'On Occurrence'}>
                                                                                                        &nbsp;<Checkbox id="On-Occurrence" value={'On Occurrence'} checked={checked.on_occurrence} onChange={(e) => {
                                                                                                            e.target.checked ? setChecked({
                                                                                                                ...checked,
                                                                                                                on_occurrence: true
                                                                                                            }) : setChecked({
                                                                                                                ...checked,
                                                                                                                on_occurrence: false
                                                                                                            })
                                                                                                            console.log(e, e.target.value)
                                                                                                            setFilterValues({
                                                                                                                ...filterValues,
                                                                                                                on_occurrence: e.target.checked
                                                                                                            })
                                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="On-Occurrence"><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> On Occurrence</label>
                                                                                                    </Form.Item>
                                                                                                    <Form.Item className="mb-1" name={' One Time'}>
                                                                                                        &nbsp;<Checkbox id="One-Time" value={'One Time'} checked={checked.one_time} onChange={(e) => {
                                                                                                            e.target.checked ? setChecked({
                                                                                                                ...checked,
                                                                                                                one_time: true
                                                                                                            }) : setChecked({
                                                                                                                ...checked,
                                                                                                                one_time: false
                                                                                                            })
                                                                                                            console.log(e, e.target.value)
                                                                                                            setFilterValues({
                                                                                                                ...filterValues,
                                                                                                                one_time: e.target.checked
                                                                                                            })
                                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="One-Time"><i style={{ color: "red" }} className="bi bi-square-fill"></i> One Time</label>
                                                                                                    </Form.Item>
                                                                                                    <Form.Item className="submitButtonFrm" >
                                                                                                        <Button style={{ borderRadius: '10px' }} className="statistic" type="primary" htmlType="submit">OK</Button>
                                                                                                    </Form.Item>
                                                                                                </Form>
                                                                                            )}
                                                                                        </div>


                                                                                        {/* <button className="col-form-label" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <FilterFilled />
                                                                    </button> */}
                                                                                        {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                        <li onClick={(e) => { frequencyfilter('All') }}><a className="dropdown-item"><b>All</b></a></li>
                                                                        <li onClick={(e) => { frequencyfilter('periodical') }}><a className="dropdown-item" style={{ background: "green", color: "white" }}>Periodical</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('flexi') }}><a className="dropdown-item" style={{ background: "pink", color: "black" }}>FlexiReview</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('Review') }}><a className="dropdown-item" style={{ background: "orange", color: "black" }}>Review</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('On Occurrence') }}><a className="dropdown-item" style={{ background: "yellow", color: "black" }}>OnOccurrence</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('One Time') }}><a className="dropdown-item" style={{ background: "red", color: "white" }}>Onetime</a></li>
                                                                    </ul> */}
                                                                                    </div>
                                                                                    {/* <div id="filter" style={{position: 'relative'}}>
                                            <div style={{position: 'absolute',marginLeft: '33px', cursor: 'pointer' }} > # 
                                          <FilterFilled style={{ marginLeft: '8px' }} /></div>
                                          <Select  style={{ width: "80px", opacity: 0 }} value={all}
                                          
                                            onChange={(data) => {
                                              frequencyfilter(data);
                                            }}
                                          >
                                            <Option l value="All">All</Option>
                                            <Option value="periodical" style={{ background: "green", color: "white" }}>
                                              Periodical
                                            </Option>
                                            <Option
                                              value="flexi"
                                              style={{
                                                background: "pink",
                                                color: "black",
                                              }}
                                            >
                                              FlexiReview
                                            </Option>
                                            <Option
                                              value="Review"
                                              style={{
                                                background: "orange",
                                                color: "black",
                                              }}
                                            >
                                              Review
                                            </Option>
                                            <Option
                                              value="On Occurrence"
                                              style={{
                                                background: "yellow",
                                                color: "black",
                                              }}
                                            >
                                              OnOccurrence
                                            </Option>
                                            <Option
                                              value="One Time"
                                              style={{
                                                background: "red",
                                                color: "white",
                                              }}
                                            >
                                              Onetime
                                            </Option>
                                          </Select>
                                          </div> */}
                                                                                </th>
                                                                                {/* <th
                                          className="ant-table-cell"
                                          style={{ width: "20px" }}
                                        >
                                          {/* Action {' '} */}
                                                                                {/* <input
                                                            type="checkbox"
                                                            title="Click here to approve all"
                                                            name="allchecked"
                                                            className={"allChecked"}
                                                            onClick={(e) => {
                                                                let checked = e.target.checked;
                                                                addAllSelectedDataValues(checked);
                                                            }}
                                                        /> */}
                                                                                {/* </th> */}
                                                                                <th
                                                                                    className="ant-table-cell"
                                                                                    style={{ width: "288px" }}
                                                                                >
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <p
                                                                                            className="p-0 mb-0"
                                                                                            style={{
                                                                                                marginLeft: "36%",
                                                                                                display: "inline",
                                                                                            }}
                                                                                        >
                                                                                            Compliance Task
                                                                                        </p>{" "}
                                                                                        <Popconfirm
                                                                                            placement="bottom"
                                                                                            title={
                                                                                                <Input
                                                                                                    placeholder="Compliance Task"
                                                                                                    autoFocus={'on'}
                                                                                                    onKeyUp={(e) => {
                                                                                                        compliancetaskfilter(
                                                                                                            e.target.value,
                                                                                                            "Compliance_Task"
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            <SearchOutlined
                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                            />
                                                                                        </Popconfirm>
                                                                                    </div>
                                                                                    {/* <Input placeholder='Compliance Task' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Compliance_Task')
                                                                          }} /> */}
                                                                                </th>
                                                                                {/* <th className='ant-table-cell' style={{ width: '10%' }}>Assignee <Input placeholder='Assignee' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Assignee')
                                                                          }} /></th> */}
                                                                                <th
                                                                                    className="ant-table-cell"
                                                                                    style={{ width: "139px" }}
                                                                                >
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <p
                                                                                            className="mb-0"
                                                                                            style={{
                                                                                                marginLeft: "2px",
                                                                                                display: "inline",
                                                                                            }}
                                                                                        >
                                                                                            Domain
                                                                                        </p>
                                                                                        <Popconfirm
                                                                                            placement="bottom"
                                                                                            title={
                                                                                                <Input
                                                                                                    placeholder="Domain"
                                                                                                    autoFocus={'on'}
                                                                                                    onKeyUp={(e) => {
                                                                                                        compliancetaskfilter(
                                                                                                            e.target.value,
                                                                                                            "Domain"
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            <SearchOutlined
                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                            />
                                                                                        </Popconfirm>
                                                                                    </div>
                                                                                    {/* <Input placeholder='Compliance Status' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Compliance_Status')
                                                                          }} /> */}
                                                                                </th>
                                                                                <th
                                                                                    className="ant-table-cell"
                                                                                    style={{ width: "85px" }}
                                                                                >
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <p className="mb-0"
                                                                                            style={{
                                                                                                marginLeft: "20%",
                                                                                                display: "inline",
                                                                                            }}
                                                                                        >
                                                                                            Start Date
                                                                                        </p>{" "}
                                                                                        <Popconfirm
                                                                                            placement="bottom"
                                                                                            title={
                                                                                                <Input
                                                                                                    placeholder="Start Date"
                                                                                                    autoFocus={'on'}
                                                                                                    onKeyUp={(e) => {
                                                                                                        compliancetaskfilter(
                                                                                                            e.target.value,
                                                                                                            "Start Date"
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            <SearchOutlined
                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                            />
                                                                                        </Popconfirm>
                                                                                    </div>

                                                                                    {/* <Input placeholder='Due Date' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Due_Date')
                                                                          }} /> */}
                                                                                </th>

                                                                                <th
                                                                                    className="ant-table-cell"
                                                                                    style={{ width: "78px" }}
                                                                                >
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <p className="mb-0"
                                                                                            style={{
                                                                                                marginLeft: "12%",
                                                                                                display: "inline",
                                                                                            }}
                                                                                        >
                                                                                            Due Date
                                                                                        </p>
                                                                                        <Popconfirm
                                                                                            placement="bottom"
                                                                                            title={
                                                                                                <Input
                                                                                                    placeholder="Due Date"
                                                                                                    autoFocus={'on'}
                                                                                                    onKeyUp={(e) => {
                                                                                                        compliancetaskfilter(
                                                                                                            e.target.value,
                                                                                                            "Due Date"
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            <SearchOutlined
                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                            />
                                                                                        </Popconfirm>
                                                                                    </div>


                                                                                    {/* <Input placeholder='Completion Date' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Completion_Date')
                                                                          }} /> */}
                                                                                </th>
                                                                                <th
                                                                                    className="ant-table-cell"
                                                                                    style={{ width: "78px" }}
                                                                                >
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <p className="mb-0"
                                                                                            style={{
                                                                                                marginLeft: "20%",
                                                                                                display: "inline",
                                                                                            }}
                                                                                        >
                                                                                            Days
                                                                                        </p>{" "}
                                                                                        <Popconfirm
                                                                                            placement="bottom"
                                                                                            title={
                                                                                                <Input
                                                                                                    placeholder="Days"
                                                                                                    autoFocus={'on'}
                                                                                                    onKeyUp={(e) => {
                                                                                                        compliancetaskfilter(
                                                                                                            e.target.value,
                                                                                                            "Days"
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            <SearchOutlined
                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                            />
                                                                                        </Popconfirm>
                                                                                    </div>

                                                                                    {/* <Input placeholder='Due Date' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Due_Date')
                                                                          }} /> */}
                                                                                </th>
                                                                                <th className="ant-table-cell"
                                                                                    style={{ width: "90px" }}>
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <p className="mb-0"
                                                                                            style={{
                                                                                                marginLeft: "12%",
                                                                                                display: "inline",
                                                                                            }}
                                                                                        >
                                                                                            Timeline
                                                                                        </p>
                                                                                        <Popconfirm
                                                                                            placement="bottom"
                                                                                            title={
                                                                                                <Input
                                                                                                    placeholder="Timeline"
                                                                                                    autoFocus={'on'}
                                                                                                    onKeyUp={(e) => {
                                                                                                        compliancetaskfilter(
                                                                                                            e.target.value,
                                                                                                            "Timeline"
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            <SearchOutlined
                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                            />
                                                                                        </Popconfirm>
                                                                                    </div>
                                                                                </th>
                                                                                <th className="ant-table-cell"
                                                                                    style={{ width: "90px" }}>
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <p className="mb-0"
                                                                                            style={{
                                                                                                marginLeft: "12%",
                                                                                                display: "inline",
                                                                                            }}
                                                                                        >
                                                                                            Status
                                                                                        </p>
                                                                                        <Popconfirm
                                                                                            placement="bottom"
                                                                                            title={
                                                                                                <Input
                                                                                                    placeholder="Status"
                                                                                                    autoFocus={'on'}
                                                                                                    onKeyUp={(e) => {
                                                                                                        compliancetaskfilter(
                                                                                                            e.target.value,
                                                                                                            "Status"
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            <SearchOutlined
                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                            />
                                                                                        </Popconfirm>
                                                                                    </div>
                                                                                </th>
                                                                                <th className="ant-table-cell"
                                                                                    style={{ width: "50px" }}>
                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                        <p className="mb-0"
                                                                                            style={{
                                                                                                marginLeft: "12%",
                                                                                                display: "inline",
                                                                                            }}
                                                                                        >
                                                                                            Format
                                                                                        </p>
                                                                                        <Popconfirm
                                                                                            placement="bottom"
                                                                                            title={
                                                                                                <Input
                                                                                                    placeholder="Format"
                                                                                                    autoFocus={'on'}
                                                                                                    onKeyUp={(e) => {
                                                                                                        compliancetaskfilter(
                                                                                                            e.target.value,
                                                                                                            "Format"
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                        >
                                                                                            <SearchOutlined
                                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                                            />
                                                                                        </Popconfirm>
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="ant-table-tbody">



                                                                            {currentDetails && currentDetails.length > 0 ? (
                                                                                currentDetails.map((itemdata, i) => {

                                                                                    return (
                                                                                        <Fragment key={i}>
                                                                                            <tr className="ant-table-row ant-table-row-level-0">
                                                                                                <td
                                                                                                    colSpan={12}
                                                                                                    className="ant-table-cell"
                                                                                                >
                                                                                                    <b>
                                                                                                        {
                                                                                                            itemdata.status

                                                                                                        }
                                                                                                    </b>
                                                                                                </td>
                                                                                            </tr>
                                                                                            {itemdata.child && itemdata.child.length > 0 && itemdata.child.map(
                                                                                                (items, l) => {
                                                                                                    const content = (
                                                                                                        <table style={{ display: 'block', width: '500px' }}  >
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <th style={{ border: "1px solid #636262" }}>Unit Name</th>
                                                                                                                    <td style={{ border: "1px solid #636262" }}>{items.unit_name}</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th style={{ border: "1px solid #636262" }}>Address</th>
                                                                                                                    <td style={{ border: "1px solid #636262" }}>{items.address}</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th style={{ border: "1px solid #636262" }}>Division</th>
                                                                                                                    <td style={{ border: "1px solid #636262" }}>{items.division}</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th style={{ border: "1px solid #636262" }}>Category</th>
                                                                                                                    <td style={{ border: "1px solid #636262" }}>{items.category}</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th style={{ border: "1px solid #636262" }}>Act / Rules</th>
                                                                                                                    <td style={{ border: "1px solid #636262" }}>{items.statu}</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th style={{ border: "1px solid #636262" }}>Compliance Description</th>
                                                                                                                    <td style={{ border: "1px solid #636262" }}>{items.compliance_description}</td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <th style={{ border: "1px solid #636262" }}>Penal Consequences</th>
                                                                                                                    <td style={{ border: "1px solid #636262" }}>{items.penal_consequences}</td>
                                                                                                                </tr>

                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    );
                                                                                                    let split = items && items.ageing.split(" ")
                                                                                                    let finalsplit = { days: '', timeline: '' }
                                                                                                    for (let i in split) {
                                                                                                        if (split[i] !== '' && Number(split[i])) {
                                                                                                            console.log(split[i], 'Number(split[i])')
                                                                                                            finalsplit.days = split[i]
                                                                                                            finalsplit.timeline = split[Number(i) + 1]
                                                                                                            console.log(finalsplit, 'kkksksksksksksksksksksk')
                                                                                                        }

                                                                                                        // console.log(split, finalsplit,Number(split[i]),'kkksksksksksksksksksksk')
                                                                                                    }
                                                                                                    console.log(finalsplit, 'split2222222222')
                                                                                                    let file = items.compliance_file_name && items.compliance_file_name.length > 0 && items.compliance_file_name[0]
                                                                                                    return (
                                                                                                        <tr
                                                                                                            className="ant-table-row ant-table-row-level-0"
                                                                                                            key={l}
                                                                                                            style={{
                                                                                                                backgroundColor:
                                                                                                                    ((valueModal && valueModal.uniqueId == items.uniqueId && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_four'))
                                                                                                                        ? '#ffff66' : ((valueModal && valueModal.uniqueId == items.uniqueId && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_three')) ? '#DDA0DD' : ((valueModal && valueModal.uniqueId == items.uniqueId && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_one')) ? '#F0F8FF' : ((valueModal && valueModal.uniqueId == items.uniqueId && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_two')) ? '#D3D3D3' : ''
                                                                                                            }}
                                                                                                        >
                                                                                                            <td className="ant-table-cell">
                                                                                                                <center>
                                                                                                                    <span>{count + 1}</span>
                                                                                                                    <span
                                                                                                                        hidden
                                                                                                                    >
                                                                                                                        {
                                                                                                                            (count =
                                                                                                                                count +
                                                                                                                                1)
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                    {items.compliance_task_frequency ==
                                                                                                                        "Periodical" ? (
                                                                                                                        <p>
                                                                                                                            <i
                                                                                                                                style={{
                                                                                                                                    color:
                                                                                                                                        "green",
                                                                                                                                }}
                                                                                                                                title={
                                                                                                                                    items.compliance_task_frequency
                                                                                                                                }
                                                                                                                                className="bi bi-square-fill"
                                                                                                                            ></i>
                                                                                                                        </p>
                                                                                                                    ) : items.compliance_task_frequency ==
                                                                                                                        "FlexiReview" ? (
                                                                                                                        <p>
                                                                                                                            <i
                                                                                                                                style={{
                                                                                                                                    color:
                                                                                                                                        "pink",
                                                                                                                                }}
                                                                                                                                title={
                                                                                                                                    items.compliance_task_frequency
                                                                                                                                }
                                                                                                                                className="bi bi-square-fill"
                                                                                                                            ></i>
                                                                                                                        </p>
                                                                                                                    ) : items.compliance_task_frequency ==
                                                                                                                        "Review" ? (
                                                                                                                        <p>
                                                                                                                            <i
                                                                                                                                style={{
                                                                                                                                    color:
                                                                                                                                        "orange",
                                                                                                                                }}
                                                                                                                                title={
                                                                                                                                    items.compliance_task_frequency
                                                                                                                                }
                                                                                                                                className="bi bi-square-fill"
                                                                                                                            ></i>
                                                                                                                        </p>
                                                                                                                    ) : items.compliance_task_frequency ==
                                                                                                                        "On Occurrence" ? (
                                                                                                                        <p>
                                                                                                                            <i
                                                                                                                                style={{
                                                                                                                                    color:
                                                                                                                                        "yellow",
                                                                                                                                }}
                                                                                                                                title={
                                                                                                                                    items.compliance_task_frequency
                                                                                                                                }
                                                                                                                                className="bi bi-square-fill"
                                                                                                                            ></i>
                                                                                                                        </p>
                                                                                                                    ) : (
                                                                                                                        <p>
                                                                                                                            <i
                                                                                                                                style={{
                                                                                                                                    color:
                                                                                                                                        "red",
                                                                                                                                }}
                                                                                                                                title={
                                                                                                                                    items.compliance_task_frequency
                                                                                                                                }
                                                                                                                                className="bi bi-square-fill"
                                                                                                                            ></i>
                                                                                                                        </p>
                                                                                                                    )}
                                                                                                                </center>


                                                                                                                <Fragment>
                                                                                                                    <center>
                                                                                                                        {/* <input
                                                                                                                            type="checkbox"
                                                                                                                            title="Click here to Task Recall"
                                                                                                                            name={
                                                                                                                                "checked"
                                                                                                                            }
                                                                                                                            onClick={(
                                                                                                                                e
                                                                                                                            ) => {
                                                                                                                                let checked =
                                                                                                                                    e
                                                                                                                                        .target
                                                                                                                                        .checked;
                                                                                                                                // addSelectedDataValues(
                                                                                                                                //     checked,
                                                                                                                                //     items.compliance_history_id,
                                                                                                                                //     items.parentId
                                                                                                                                // );
                                                                                                                            }}
                                                                                                                        /> */}
                                                                                                                    </center>
                                                                                                                </Fragment>
                                                                                                            </td>
                                                                                                            <td className='pointer'>
                                                                                                                <>




                                                                                                                    <span
                                                                                                                        style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : 'black' }}
                                                                                                                        onClick={() => {
                                                                                                                            setNextData(itemdata.child[l + 1])
                                                                                                                            console.log(items, 'itemsss')
                                                                                                                            showValue()
                                                                                                                            setCompletionDate("")
                                                                                                                            SetRemarks("")
                                                                                                                            setEdit(false)
                                                                                                                            setFileUpload([])
                                                                                                                            setValueModal({
                                                                                                                                ...valueModal,
                                                                                                                                domain_id: items.domain_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                                action: items.compliance_status,
                                                                                                                                uploaded_document: items.uploaded_documents,
                                                                                                                                actiondata: items.action,
                                                                                                                                approve_status: items.approve_status,
                                                                                                                                unit_id: items.unit_id,
                                                                                                                                due_date: items.due_date,
                                                                                                                                completion_date: items.completion_date,
                                                                                                                                remarks: items.remarks,
                                                                                                                                compliance_description: items.compliance_description,
                                                                                                                                description: items.compliance_description,
                                                                                                                                compliance_history_id: items.compliance_history_id,
                                                                                                                                unit: items.unit_name,
                                                                                                                                address: items.address,
                                                                                                                                recall: items.recallremarks,
                                                                                                                                compliance_task: items.compliance_name,
                                                                                                                                compliance_frequency: items.compliance_task_frequency,
                                                                                                                                document_issued_date: items.completion_date,
                                                                                                                                document_reference_number: items.document_reference_number,
                                                                                                                                next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                history_count: items.remarks_count,
                                                                                                                                uniqueId: items.uniqueId,
                                                                                                                                occurrence_remarks: items.occurrence_remarks,
                                                                                                                                status: items.compliance_status,
                                                                                                                                address: items.address

                                                                                                                            })
                                                                                                                            setValueForFileUpload({
                                                                                                                                d_id: items.domain_id,
                                                                                                                                u_id: items.unit_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                            })
                                                                                                                        }}



                                                                                                                    // onClick={() => {
                                                                                                                    //     showValue()
                                                                                                                    //     setValueModal({
                                                                                                                    //         compliance_history_id: record.compliance_history_id,
                                                                                                                    //         unit: record.unit_name,
                                                                                                                    //         compliance_task: record.compliance_name,
                                                                                                                    //         compliance_frequency: record.compliance_frequency,
                                                                                                                    //         document_issued_date: record.completion_date,
                                                                                                                    //         document_reference_number: record.document_reference_number,
                                                                                                                    //         next_due_date: record.next_due_date,
                                                                                                                    //         address: record.address,
                                                                                                                    //         compliance_description: record.compliance_description,
                                                                                                                    //         uploaded_document: record.upload_docs,
                                                                                                                    //         interim_details: record.interim_docs,
                                                                                                                    //         remarks: record.remarks

                                                                                                                    //     })
                                                                                                                    // }}
                                                                                                                    >{items.statu}</span>
                                                                                                                    {/* <div>
                                                                                                                        <div className="mb-0">
                                                                                                                            <Tooltip title={content}>
                                                                                                                                {/* <i class="bi-info-circle-fill" style={{ color: '#5DAAE3' }}></i> */}
                                                                                                                    {/* <InfoCircleOutlined style={{ color: '#5DAAE3' }} />
                                                                                                                            </Tooltip>{items.compliance_name}
                                                                                                                        </div>
                                                                                                                        <span>Assigned on : {items.assigned_on}</span>
                                                                                                                    </div> */}



                                                                                                                    <div className="approve-task-reacall-tooltip">
                                                                                                                        <Tooltip placement="topRight" title={content} > <ExclamationCircleTwoTone /> </Tooltip><span onClick={() => {
                                                                                                                            showValue()
                                                                                                                            setNextData(itemdata.child[l + 1])
                                                                                                                            setCompletionDate("")
                                                                                                                            SetRemarks("")
                                                                                                                            setFileUpload([])
                                                                                                                            setEdit(false)
                                                                                                                            setValueModal({
                                                                                                                                ...valueModal,
                                                                                                                                domain_id: items.domain_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                                action: items.compliance_status,
                                                                                                                                due_date: items.due_date,
                                                                                                                                uploaded_document: items.uploaded_documents,
                                                                                                                                actiondata: items.action,
                                                                                                                                address: items.address,
                                                                                                                                approve_status: items.approve_status,
                                                                                                                                unit_id: items.unit_id,
                                                                                                                                completion_date: items.completion_date,
                                                                                                                                remarks: items.remarks,
                                                                                                                                description: items.description,
                                                                                                                                compliance_description: items.compliance_description,
                                                                                                                                compliance_history_id: items.compliance_history_id,
                                                                                                                                unit: items.unit_name,
                                                                                                                                compliance_task: items.compliance_name,
                                                                                                                                compliance_frequency: items.compliance_task_frequency,
                                                                                                                                document_issued_date: items.completion_date,
                                                                                                                                document_reference_number: items.document_reference_number,
                                                                                                                                next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                history_count: items.remarks_count,
                                                                                                                                occurrence_remarks: items.occurrence_remarks,
                                                                                                                                uniqueId: items.uniqueId
                                                                                                                            })
                                                                                                                            setValueForFileUpload({
                                                                                                                                d_id: items.domain_id,
                                                                                                                                u_id: items.unit_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                            })
                                                                                                                            // window.scrollTo(0, 0);
                                                                                                                        }}>{items.compliance_name}</span>
                                                                                                                        <span>Assigned on : {items.assigned_on}</span>
                                                                                                                    </div>

                                                                                                                </>
                                                                                                            </td>
                                                                                                            {/* <td>
                                                                                                                  {items.assignee_name}
                                                                                                              </td> */}
                                                                                                            <td className='pointer'>
                                                                                                                <p
                                                                                                                    style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : 'black' }}
                                                                                                                    onClick={() => {
                                                                                                                        showValue()
                                                                                                                        setNextData(itemdata.child[l + 1])
                                                                                                                        setCompletionDate("")
                                                                                                                        SetRemarks("")
                                                                                                                        setEdit(false)
                                                                                                                        setFileUpload([])
                                                                                                                        setValueModal({
                                                                                                                            ...valueModal,
                                                                                                                            domain_id: items.domain_id,
                                                                                                                            start_date: items.start_date,
                                                                                                                            uploaded_document: items.uploaded_documents,
                                                                                                                            actiondata: items.action,
                                                                                                                            action: items.compliance_status,
                                                                                                                            approve_status: items.approve_status,
                                                                                                                            unit_id: items.unit_id,
                                                                                                                            due_date: items.due_date,
                                                                                                                            completion_date: items.completion_date,
                                                                                                                            remarks: items.remarks,
                                                                                                                            compliance_description: items.compliance_description,
                                                                                                                            description: items.compliance_description,
                                                                                                                            compliance_history_id: items.compliance_history_id,
                                                                                                                            unit: items.unit_name,
                                                                                                                            address: items.address,
                                                                                                                            recall: items.recallremarks,
                                                                                                                            compliance_task: items.compliance_name,
                                                                                                                            compliance_frequency: items.compliance_task_frequency,
                                                                                                                            document_issued_date: items.completion_date,
                                                                                                                            document_reference_number: items.document_reference_number,
                                                                                                                            next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                            next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                            history_count: items.remarks_count,
                                                                                                                            uniqueId: items.uniqueId,
                                                                                                                            occurrence_remarks: items.occurrence_remarks,
                                                                                                                            status: items.compliance_status,
                                                                                                                            address: items.address

                                                                                                                        })
                                                                                                                        setValueForFileUpload({
                                                                                                                            d_id: items.domain_id,
                                                                                                                            u_id: items.unit_id,
                                                                                                                            start_date: items.start_date,
                                                                                                                        })
                                                                                                                        // window.scrollTo(0, 0);
                                                                                                                    }}>{
                                                                                                                        items.domain_name
                                                                                                                    }</p>
                                                                                                            </td>
                                                                                                            <td className='pointer'>
                                                                                                                <center>
                                                                                                                    <p
                                                                                                                        style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : 'black' }}
                                                                                                                        onClick={() => {
                                                                                                                            showValue()
                                                                                                                            setNextData(itemdata.child[l + 1])
                                                                                                                            setCompletionDate("")
                                                                                                                            SetRemarks("")
                                                                                                                            setEdit(false)
                                                                                                                            setFileUpload([])
                                                                                                                            setValueModal({
                                                                                                                                ...valueModal,
                                                                                                                                domain_id: items.domain_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                                uploaded_document: items.uploaded_documents,
                                                                                                                                actiondata: items.action,
                                                                                                                                action: items.compliance_status,
                                                                                                                                approve_status: items.approve_status,
                                                                                                                                unit_id: items.unit_id,
                                                                                                                                due_date: items.due_date,
                                                                                                                                completion_date: items.completion_date,
                                                                                                                                remarks: items.remarks,
                                                                                                                                compliance_description: items.compliance_description,
                                                                                                                                description: items.compliance_description,
                                                                                                                                compliance_history_id: items.compliance_history_id,
                                                                                                                                unit: items.unit_name,
                                                                                                                                address: items.address,
                                                                                                                                recall: items.recallremarks,
                                                                                                                                compliance_task: items.compliance_name,
                                                                                                                                compliance_frequency: items.compliance_task_frequency,
                                                                                                                                document_issued_date: items.completion_date,
                                                                                                                                document_reference_number: items.document_reference_number,
                                                                                                                                next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                history_count: items.remarks_count,
                                                                                                                                uniqueId: items.uniqueId,
                                                                                                                                occurrence_remarks: items.occurrence_remarks,
                                                                                                                                status: items.compliance_status,
                                                                                                                                address: items.address

                                                                                                                            })
                                                                                                                            setValueForFileUpload({
                                                                                                                                d_id: items.domain_id,
                                                                                                                                u_id: items.unit_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                            })
                                                                                                                            // window.scrollTo(0, 0);

                                                                                                                        }}>{moment(items.start_date).format('DD-MMM-YYYY')}
                                                                                                                    </p>
                                                                                                                </center>
                                                                                                            </td>

                                                                                                            <td className='pointer'>
                                                                                                                <center>
                                                                                                                    <p
                                                                                                                        style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : 'black' }}
                                                                                                                        onClick={() => {
                                                                                                                            showValue()
                                                                                                                            setNextData(itemdata.child[l + 1])
                                                                                                                            setCompletionDate("")
                                                                                                                            SetRemarks("")
                                                                                                                            setEdit(false)
                                                                                                                            setFileUpload([])
                                                                                                                            setValueModal({
                                                                                                                                ...valueModal,
                                                                                                                                domain_id: items.domain_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                                uploaded_document: items.uploaded_documents,
                                                                                                                                actiondata: items.action,
                                                                                                                                action: items.compliance_status,
                                                                                                                                approve_status: items.approve_status,
                                                                                                                                unit_id: items.unit_id,
                                                                                                                                due_date: items.due_date,
                                                                                                                                completion_date: items.completion_date,
                                                                                                                                remarks: items.remarks,
                                                                                                                                compliance_description: items.compliance_description,
                                                                                                                                description: items.compliance_description,
                                                                                                                                compliance_history_id: items.compliance_history_id,
                                                                                                                                unit: items.unit_name,
                                                                                                                                address: items.address,
                                                                                                                                recall: items.recallremarks,
                                                                                                                                compliance_task: items.compliance_name,
                                                                                                                                compliance_frequency: items.compliance_task_frequency,
                                                                                                                                document_issued_date: items.completion_date,
                                                                                                                                document_reference_number: items.document_reference_number,
                                                                                                                                next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                history_count: items.remarks_count,
                                                                                                                                uniqueId: items.uniqueId,
                                                                                                                                occurrence_remarks: items.occurrence_remarks,
                                                                                                                                status: items.compliance_status,
                                                                                                                                address: items.address

                                                                                                                            })
                                                                                                                            setValueForFileUpload({
                                                                                                                                d_id: items.domain_id,
                                                                                                                                u_id: items.unit_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                            })
                                                                                                                            // window.scrollTo(0, 0);
                                                                                                                        }}>{items.due_date}
                                                                                                                    </p>
                                                                                                                </center>
                                                                                                            </td>
                                                                                                            <td className='pointer'>

                                                                                                                <center>
                                                                                                                    <p
                                                                                                                        style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : 'red' }}
                                                                                                                        onClick={() => {
                                                                                                                            setShowValueModal(true)
                                                                                                                            setNextData(itemdata.child[l + 1])
                                                                                                                            setValueForFileUpload({
                                                                                                                                d_id: items.domain_id,
                                                                                                                                u_id: items.unit_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                            })
                                                                                                                            setCompletionDate("")
                                                                                                                            SetRemarks("")
                                                                                                                            setEdit(false)
                                                                                                                            setFileUpload([])
                                                                                                                            setValueModal({
                                                                                                                                ...valueModal,
                                                                                                                                domain_id: items.domain_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                                uploaded_document: items.uploaded_documents,
                                                                                                                                actiondata: items.action,
                                                                                                                                action: items.compliance_status,
                                                                                                                                approve_status: items.approve_status,
                                                                                                                                unit_id: items.unit_id,
                                                                                                                                due_date: items.due_date,
                                                                                                                                completion_date: items.completion_date,
                                                                                                                                remarks: items.remarks,
                                                                                                                                compliance_description: items.compliance_description,
                                                                                                                                description: items.compliance_description,
                                                                                                                                compliance_history_id: items.compliance_history_id,
                                                                                                                                unit: items.unit_name,
                                                                                                                                address: items.address,
                                                                                                                                recall: items.recallremarks,
                                                                                                                                compliance_task: items.compliance_name,
                                                                                                                                compliance_frequency: items.compliance_task_frequency,
                                                                                                                                document_issued_date: items.completion_date,
                                                                                                                                document_reference_number: items.document_reference_number,
                                                                                                                                next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                                next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                history_count: items.remarks_count,
                                                                                                                                uniqueId: items.uniqueId,
                                                                                                                                occurrence_remarks: items.occurrence_remarks,
                                                                                                                                status: items.compliance_status,
                                                                                                                                address: items.address

                                                                                                                            })
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span style={{ color: (moment(items.due_date).isSameOrBefore(today)) ? 'red' : (finalsplit.days && (items.compliance_status !== 'Inprogress')) ? 'red' : 'black' }}> {finalsplit.days ? finalsplit.days : 0}{" "} {finalsplit.timeline ? finalsplit.timeline : 'day(s)'}</span>
                                                                                                                        {/* {split && split.length == 6 ? split[2] :  split[3]} {split && split.length == 6 ? split[3] : split[4]} */}
                                                                                                                    </p>
                                                                                                                </center>
                                                                                                            </td>
                                                                                                            <td className='pointer'>
                                                                                                                <p
                                                                                                                    style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}
                                                                                                                    onClick={() => {
                                                                                                                        setShowValueModal(true)
                                                                                                                        setNextData(itemdata.child[l + 1])
                                                                                                                        setValueForFileUpload({
                                                                                                                            d_id: items.domain_id,
                                                                                                                            u_id: items.unit_id,
                                                                                                                            start_date: items.start_date,
                                                                                                                        })
                                                                                                                        setCompletionDate("")
                                                                                                                        SetRemarks("")
                                                                                                                        setEdit(false)
                                                                                                                        setFileUpload([])
                                                                                                                        setValueModal({
                                                                                                                            ...valueModal,
                                                                                                                            domain_id: items.domain_id,
                                                                                                                            start_date: items.start_date,
                                                                                                                            uploaded_document: items.uploaded_documents,
                                                                                                                            actiondata: items.action,
                                                                                                                            action: items.compliance_status,
                                                                                                                            approve_status: items.approve_status,
                                                                                                                            unit_id: items.unit_id,
                                                                                                                            due_date: items.due_date,
                                                                                                                            completion_date: items.completion_date,
                                                                                                                            remarks: items.remarks,
                                                                                                                            compliance_description: items.compliance_description,
                                                                                                                            description: items.compliance_description,
                                                                                                                            compliance_history_id: items.compliance_history_id,
                                                                                                                            unit: items.unit_name,
                                                                                                                            address: items.address,
                                                                                                                            recall: items.recallremarks,
                                                                                                                            compliance_task: items.compliance_name,
                                                                                                                            compliance_frequency: items.compliance_task_frequency,
                                                                                                                            document_issued_date: items.completion_date,
                                                                                                                            document_reference_number: items.document_reference_number,
                                                                                                                            next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                            next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                            history_count: items.remarks_count,
                                                                                                                            uniqueId: items.uniqueId,
                                                                                                                            occurrence_remarks: items.occurrence_remarks,
                                                                                                                            status: items.compliance_status,
                                                                                                                            address: items.address

                                                                                                                        })
                                                                                                                    }}
                                                                                                                >{(moment(items.due_date).isSameOrAfter(today)) ? "Left" : "Over Due"}</p>
                                                                                                            </td>
                                                                                                            <td className='pointer'>
                                                                                                                <p
                                                                                                                    style={{ cursor: 'pointer', color: items.compliance_status == 'Recall' ? 'brown' : items.compliance_status == 'Rectify' ? 'red' : 'black' }}
                                                                                                                    onClick={() => {
                                                                                                                        setShowValueModal(true)
                                                                                                                        setNextData(itemdata.child[l + 1])
                                                                                                                        setValueForFileUpload({
                                                                                                                            d_id: items.domain_id,
                                                                                                                            u_id: items.unit_id,
                                                                                                                            start_date: items.start_date,
                                                                                                                        })
                                                                                                                        setCompletionDate("")
                                                                                                                        SetRemarks("")
                                                                                                                        setEdit(false)
                                                                                                                        setFileUpload([])
                                                                                                                        setValueModal({
                                                                                                                            ...valueModal,
                                                                                                                            domain_id: items.domain_id,
                                                                                                                            start_date: items.start_date,
                                                                                                                            uploaded_document: items.uploaded_documents,
                                                                                                                            actiondata: items.action,
                                                                                                                            approve_status: items.approve_status,
                                                                                                                            action: items.compliance_status,
                                                                                                                            unit_id: items.unit_id,
                                                                                                                            due_date: items.due_date,
                                                                                                                            completion_date: items.completion_date,
                                                                                                                            remarks: items.remarks,
                                                                                                                            compliance_description: items.compliance_description,
                                                                                                                            description: items.compliance_description,
                                                                                                                            compliance_history_id: items.compliance_history_id,
                                                                                                                            unit: items.unit_name,
                                                                                                                            address: items.address,
                                                                                                                            recall: items.recallremarks,
                                                                                                                            compliance_task: items.compliance_name,
                                                                                                                            compliance_frequency: items.compliance_task_frequency,
                                                                                                                            document_issued_date: items.completion_date,
                                                                                                                            document_reference_number: items.document_reference_number,
                                                                                                                            next_due_date1: items.next_due_date != null ? items.next_due_date : null,
                                                                                                                            next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                            history_count: items.remarks_count,
                                                                                                                            uniqueId: items.uniqueId,
                                                                                                                            occurrence_remarks: items.occurrence_remarks,
                                                                                                                            status: items.compliance_status,
                                                                                                                            address: items.address

                                                                                                                        })
                                                                                                                    }}
                                                                                                                >{items.compliance_status}</p>
                                                                                                            </td>
                                                                                                            <td className='text-center'>
                                                                                                                {
                                                                                                                    items.compliance_file_name && items.compliance_file_name.length > 0 ?
                                                                                                                        <>
                                                                                                                            <Tooltip title='Download Format'>
                                                                                                                                <DownloadOutlined
                                                                                                                                    onClick={() => {
                                                                                                                                        fileListTemp({
                                                                                                                                            payload: [
                                                                                                                                                authtoken,
                                                                                                                                                {
                                                                                                                                                    "session_token": authtoken,
                                                                                                                                                    "request": [
                                                                                                                                                        "File_List_Temp",
                                                                                                                                                        {
                                                                                                                                                            "le_id": showdata && showdata.legal_entity,
                                                                                                                                                            "compliance_history_id": Number(items.compliance_history_id),
                                                                                                                                                            "u_id": Number(items.unit_id)
                                                                                                                                                        }
                                                                                                                                                    ]
                                                                                                                                                }
                                                                                                                                            ],
                                                                                                                                            paramid: paramid
                                                                                                                                        })
                                                                                                                                        window.open(urls + file)
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            </Tooltip>
                                                                                                                        </> : ''
                                                                                                                }
                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </Fragment>
                                                                                    );

                                                                                })
                                                                            ) : (
                                                                                <tr >
                                                                                    <td colSpan="100%" style={{ paddingTop: '15px', padding: '40px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                                </tr>
                                                                            )}

                                                                            {/* ) : (
                                                            <tr>
                                                                <td
                                                                    colSpan={7}
                                                                    style={{
                                                                        textAlign: "center",
                                                                        fontSize: "14px",
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    No Compliance Available
                                                                </td>
                                                            </tr>
                                                        )} */}
                                                                        </tbody>


                                                                    </table>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        {showValueModal == true ?
                                                            <div style={{ width: '40%' }}>
                                                                <Card className='approval-unit-details' style={{ borderRadius: "15px", height: '750px', overflowY: 'scroll', borderColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }}>
                                                                    <span style={{ marginLeft: '95%' }}>
                                                                        <CloseCircleOutlined onClick={Cancelmodal} style={{ fontSize: '20px', color: 'red' }} /></span>

                                                                    <div className="col-md-12 d-flex" >
                                                                        <div>
                                                                            <button
                                                                                type="button"
                                                                                class="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                                                                            >
                                                                                <i
                                                                                    class="fas fa-list-alt"
                                                                                ></i>
                                                                            </button>
                                                                        </div>
                                                                        <div>
                                                                            <p className='mb-1'><b>Unit Name</b></p>
                                                                            <h4 className='fw-bolder'> {valueModal.unit}&nbsp;<Tooltip title={valueModal.address} >
                                                                                <ExclamationCircleTwoTone style={{ fontSize: '14px' }} />
                                                                            </Tooltip></h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="container py-2">
                                                                        <div className="row">
                                                                            <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                                                                <p className="mb-1"><b>Compliance Task:</b></p>
                                                                                <h5>{valueModal.compliance_task}&nbsp;<Tooltip placement='bottom' title={valueModal.description} >
                                                                                    <ExclamationCircleTwoTone style={{ fontSize: '14px' }} />
                                                                                </Tooltip></h5>
                                                                            </div>
                                                                            <div className="col-12  ModalCard border p-2 bg-light mb-2">
                                                                                <p className="mb-1"><b>Compliance Frequency:</b></p>
                                                                                <h5>{valueModal.compliance_frequency}</h5>
                                                                            </div>
                                                                            {/* <div className="col-4 ModalCard  border p-2 bg-light mb-2">
                            <p className="mb-1"><b>Document Reference Number:</b></p>
                            <h5>{valueModal.document_reference_number}</h5>
                        </div> */}
                                                                            <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                                                                <p className="mb-1"><b>Upload Documents:</b></p>
                                                                                {/* {valueModal.uploaded_document != null ?
                                            valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                                                return ( */}
                                                                                <div className="d-flex align-items-end">
                                                                                    <div>
                                                                                        <label htmlFor="file-upload" className='btn btn-primary upload-btn p-1 d-flex justify-content-center align-items-center'><CloudUploadOutlined /></label>
                                                                                        <input
                                                                                            id="file-upload"
                                                                                            type="file"
                                                                                            // accept='.csv'
                                                                                            multiple
                                                                                            name="file"
                                                                                            className={'file-upload-ctcy'}
                                                                                            onChange={(e) => {

                                                                                                SavepastRecordOnChange(e)
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        <p>(Maximum 25MB per file/task)</p>
                                                                                        <p> Upload Date  :{moment(currentdate).format("DD-MMM-YYYY")} </p>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                                                                <p className="mb-1"><b>Uploaded File Size :</b></p>
                                                                                <h5>
                                                                                    {fileSize === 0 ? fileSize : `${(fileSize / 1048576).toFixed(3)} MB`}
                                                                                </h5>
                                                                            </div>
                                                                            <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                                                                <p className="mb-1"><b>Attached Documents:</b></p>
                                                                                <h5>
                                                                                    {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                                                                        return <p>{item.file_name}    <Button type="primary" class='addbutton'
                                                                                            icon={<DownloadOutlined />} size='small'
                                                                                            onClick={(e) => {
                                                                                                download(item.file_name)
                                                                                            }}
                                                                                            style={{ marginTop: '2%', borderRadius: '15px' }}  >
                                                                                        </Button> &nbsp;
                                                                                            <Button type="primary" style={{ borderRadius: '15px' }} class='addbutton'
                                                                                                icon={<CloseOutlined />} size='small'
                                                                                                onClick={(e) => {
                                                                                                    Remove(item.file_name)
                                                                                                    setFileSize(0)
                                                                                                }}
                                                                                            >
                                                                                            </Button></p>
                                                                                    })}
                                                                                </h5>
                                                                            </div>
                                                                            <div className="col-md-12 ModalCard border  p-2 bg-light mb-2">
                                                                                <p className="mb-1"><b>Task Completion Date / Document Issued Date: </b><span style={{ color: "red" }}>*</span></p>
                                                                                <h5>
                                                                                    <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder={'DD-MMM-YYYY'} className='form-control'
                                                                                        value={completionDate ? completionDate : ''}
                                                                                        style={{ width: '40%' }}
                                                                                        name="validity_date" id=""
                                                                                        disabledDate={(current) => {
                                                                                            return current > Date.now()
                                                                                        }}
                                                                                        onKeyDown={(event) => {
                                                                                            event.preventDefault()
                                                                                        }}
                                                                                        onChange={(dateString) => {
                                                                                            setCompletionDate(
                                                                                                dateString
                                                                                            )
                                                                                        }}
                                                                                        disabled={valueModal.status == 'Recall' ? true : false}
                                                                                    />

                                                                                </h5>
                                                                            </div>
                                                                            <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                <p className="mb-1 me-1"><b>Document Reference Number:</b></p>
                                                                                <Input class="form-control"
                                                                                    disabled={valueModal.status == 'Recall' ? true : false}
                                                                                    style={{ "width": "80%", display: 'block' }}
                                                                                    value={valueModal.document_reference_number}
                                                                                    onChange={onInputChange1}
                                                                                // onChange={(e) => {
                                                                                //     const value = e.target.value;
                                                                                //     const re = /^[A-Za-z0-9()-_.,/]{0,500}$/;
                                                                                //     if (value === "" || re.test(value)) {
                                                                                //         // SetRemarks(value)
                                                                                //         setDocumentNo(value)
                                                                                //     }
                                                                                // }}
                                                                                />
                                                                            </div>
                                                                            {valueModal.next_due_date1 !== null && valueModal.status == 'On Occurrence' ?
                                                                                <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                    <p className="mb-1 me-1"><b>Next Due Date: </b></p>
                                                                                    <p className="mb-1 me-2">
                                                                                        {valueModal.next_due_date}
                                                                                    </p>
                                                                                </div> : ''}

                                                                            <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                <p className="mb-1 me-1"><b>Status : </b></p>
                                                                                <p className="mb-1 me-1">
                                                                                    {valueModal.status}
                                                                                </p>
                                                                            </div>
                                                                            {valueModal.next_due_date1 !== null && valueModal.status == 'On Occurrence' ?
                                                                                <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                    <p className="mb-1 me-1"><b>Validity Date: </b></p>
                                                                                    {edit === false ?
                                                                                        <a href={() => false}>
                                                                                            <span
                                                                                                onClick={() => {
                                                                                                    setEdit(true)
                                                                                                }}
                                                                                                className="btn btn-sm btn-light-info text-info  d-flex align-items-center justify-content-center"
                                                                                            >
                                                                                                <i className="ri-edit-line"></i>Edit
                                                                                            </span>
                                                                                        </a> :
                                                                                        <h5>
                                                                                            <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder={'DD-MMM-YYYY'} className='form-control'
                                                                                                // value={valueModal ? completionDate : ''}
                                                                                                style={{ width: '100%' }}
                                                                                                name="validity_date" id=""
                                                                                                // disabledDate={(current) => {
                                                                                                //     var startdate = moment(valueModal.next_due_date);
                                                                                                //     startdate = startdate.add(180, "days");
                                                                                                //     startdate = startdate.format("DD-MM-YYYY");
                                                                                                //     var startdate1 = moment(valueModal.next_due_date);
                                                                                                //     startdate1 = startdate1.subtract(180, "days");
                                                                                                //     startdate1 = startdate1.format("DD-MM-YYYY");
                                                                                                //     console.log(startdate, 'newdate')
                                                                                                //     // console.log(moment(moment(valueModal.next_due_date).format('DD-MM-YYYY')).add(180, "days"), 'disabledatedisabledate')
                                                                                                //     return  current 
                                                                                                // }}
                                                                                                onKeyDown={(event) => {
                                                                                                    event.preventDefault()
                                                                                                }}
                                                                                                onChange={(dateString) => {
                                                                                                    setValueModal({
                                                                                                        ...valueModal,
                                                                                                        validity_date: dateString
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        </h5>
                                                                                    }







                                                                                </div> : false}
                                                                            {valueModal.compliance_frequency == 'On Occurrence' ?
                                                                                <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                    <p className="mb-1 me-1"><b>On Occurrence: </b></p>
                                                                                    <p className="mb-1 me-1">
                                                                                        {valueModal.occurrence_remarks}
                                                                                    </p>
                                                                                </div> : ''}
                                                                            {valueModal.status == 'Recall' ?
                                                                                <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                    <p className="mb-1 me-1"><b>Recall Remarks</b></p>
                                                                                    <p className="mb-1 me-1">{valueModal.recall}</p>
                                                                                    {valueModal.history_count != 0 ?
                                                                                        <>
                                                                                            <Tooltip title="click to view remark history" ><ExclamationCircleTwoTone style={{ marginLeft: '5px' }} onClick={(e) => {
                                                                                                showModalremark()
                                                                                                // setToolTip([valueModal])
                                                                                                const remarkapi = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "GetComplianceRemarksHistoryData",
                                                                                                            {
                                                                                                                "legal_entity_id": showdata.legal_entity,
                                                                                                                "unit_id": Number(valueModal.unit_id),
                                                                                                                "compliance_history_id": Number(valueModal.compliance_history_id)
                                                                                                            }
                                                                                                        ]
                                                                                                    }
                                                                                                ]
                                                                                                getremarkhistory({
                                                                                                    payload: remarkapi,
                                                                                                    paramid: paramid
                                                                                                })
                                                                                            }
                                                                                            } /></Tooltip></> : ''
                                                                                    }
                                                                                </div> : ''}
                                                                            {valueModal.remarks !== null ?
                                                                                <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                                    <p className="mb-1 me-1"><b>Remarks: </b></p>
                                                                                    <p className="mb-1 me-1">
                                                                                        {valueModal.remarks}
                                                                                        {valueModal.history_count != 0 ?
                                                                                            <>
                                                                                                <Tooltip title="click to view remark history" ><ExclamationCircleTwoTone style={{ marginLeft: '5px' }} onClick={(e) => {
                                                                                                    showModalremark()
                                                                                                    // setToolTip([valueModal])
                                                                                                    const remarkapi = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "GetComplianceRemarksHistoryData",
                                                                                                                {
                                                                                                                    "legal_entity_id": showdata.legal_entity,
                                                                                                                    "unit_id": Number(valueModal.unit_id),
                                                                                                                    "compliance_history_id": Number(valueModal.compliance_history_id)
                                                                                                                }
                                                                                                            ]
                                                                                                        }
                                                                                                    ]
                                                                                                    console.log(remarkapi, 'remarkapi2454555555555556');
                                                                                                    getremarkhistory({
                                                                                                        payload: remarkapi,
                                                                                                        paramid: paramid
                                                                                                    })
                                                                                                }
                                                                                                } /></Tooltip></> : ''
                                                                                        }
                                                                                    </p>
                                                                                </div> : ''}
                                                                            <div className="col-md-12 border ModalCard p-2 bg-light mb-2">
                                                                                <p className="mb-1"><b>Remarks:</b></p>
                                                                                <textarea class="form-control" placeholder='Enter remarks' maxLength={500} onChange={onInputChange} rows={1} style={{ width: '80%', display: 'inline' }}
                                                                                    value={remarks}
                                                                                // onChange={(e) => {
                                                                                //     const value = e.target.value;
                                                                                //     console.log(value,'valuevalue');
                                                                                //     const re = /^[A-Za-z0-9_ .,-]{0,500}$/;
                                                                                //     if (value === "" || re.test(value)) {
                                                                                //         console.log(value,'valuevaluevaluevalue');
                                                                                //         SetRemarks(value)
                                                                                //     }
                                                                                // }}
                                                                                />
                                                                                <span className='mx-1 text-info'
                                                                                    onClick={() => { showRemarksModal() }}>
                                                                                    <PlusCircleOutlined />
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-12 text-center p-2 mb-2">
                                                                                {
                                                                                    valueModal.status !== 'Recall' ? <div className='popupbtncolour'>
                                                                                        <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                                                                            // style={{ background: "#198754", borderColor: "#198754", marginLeft: '25%' }}
                                                                                            size='default'
                                                                                            onClick={() => {
                                                                                                setUpdate(true)
                                                                                                if (fileupload && fileupload.length > 0 || remarks !== '') {
                                                                                                    if (fileupload.length == 0) {
                                                                                                        const payload = [
                                                                                                            authtoken,
                                                                                                            {
                                                                                                                "session_token": authtoken,
                                                                                                                "request": [
                                                                                                                    "SaveInterimComplianceHistory",
                                                                                                                    {
                                                                                                                        "legal_entity_id": showdata && showdata.legal_entity,
                                                                                                                        "compliance_history_id": valueModal?.compliance_history_id,
                                                                                                                        "documents": fileupload.length > 0 ? fileupload : null,
                                                                                                                        "uploaded_documents": null,
                                                                                                                        "remarks": remarks,
                                                                                                                        "file_size": 0
                                                                                                                    }
                                                                                                                ]
                                                                                                                // "request": [
                                                                                                                //     "SaveInterimComplianceHistory",
                                                                                                                //     {
                                                                                                                //         "legal_entity_id": showdata && showdata.legal_entity,
                                                                                                                //         "compliance_history_id": valueModal.compliance_history_id,
                                                                                                                //         "documents": fileupload.length > 0 ? fileupload : null,
                                                                                                                //         "uploaded_documents": null,
                                                                                                                //         "remarks": remarks,
                                                                                                                //         "file_size": 0
                                                                                                                //     }
                                                                                                                // ]
                                                                                                            }
                                                                                                        ]
                                                                                                        const payload2 = [
                                                                                                            authtoken,
                                                                                                            {
                                                                                                                "session_token": authtoken,
                                                                                                                "request": [
                                                                                                                    "GetCurrentComplianceDetail",
                                                                                                                    {
                                                                                                                        "le_id": showdata.legal_entity,
                                                                                                                        "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                                                                        "current_start_count": 0,
                                                                                                                        "cal_view": null,
                                                                                                                        "cal_date": null,
                                                                                                                        "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                                                                        "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                                                                        "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                                                                        "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                                                                                    }
                                                                                                                ]
                                                                                                            }
                                                                                                        ]


                                                                                                        updateStatus({
                                                                                                            payload: payload,
                                                                                                            paramid: paramid,
                                                                                                            setShowValueModal: setShowValueModal,
                                                                                                            nextData: nextDatas,
                                                                                                            payload2: payload2
                                                                                                        })
                                                                                                    }

                                                                                                    if (fileupload.length > 0) {
                                                                                                        UploadFile1({
                                                                                                            payload: [
                                                                                                                authtoken,
                                                                                                                {
                                                                                                                    "session_token": authtoken,
                                                                                                                    "request": [
                                                                                                                        "UploadInterimTaskFile",
                                                                                                                        {
                                                                                                                            "le_id": showdata && showdata.legal_entity,
                                                                                                                            "c_id": 1,
                                                                                                                            "selected_task_info": [
                                                                                                                                {
                                                                                                                                    "d_id": Number(valueModal.domain_id),
                                                                                                                                    "u_id": Number(valueModal.unit_id),

                                                                                                                                    "file_info": fileupload,
                                                                                                                                    "start_date": valueModal.start_date,
                                                                                                                                    "compliance_history_id": Number(valueModal.compliance_history_id)

                                                                                                                                }
                                                                                                                            ],

                                                                                                                        }
                                                                                                                    ]
                                                                                                                }


                                                                                                            ],









                                                                                                            paramid: paramid
                                                                                                        })
                                                                                                    }

                                                                                                }
                                                                                                else {
                                                                                                    Toaster.error("Please give either a valid remarks Or upload a document to save")
                                                                                                }


                                                                                            }}
                                                                                        >
                                                                                            Update Interim Status
                                                                                        </Button>
                                                                                        <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                                                            onsubmit
                                                                                            icon={<PlayCircleOutlined />} size='default'
                                                                                            onClick={onsubmit

                                                                                            }
                                                                                        >
                                                                                            Submit
                                                                                        </Button>
                                                                                    </div> : false
                                                                                }
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </div> : false}
                                                    </div>
                                                </div>

                                                {
                                                    eventValue == 0 ?


                                                        ((currentCompliance && currentCompliance.overdue_count) + (currentCompliance && currentCompliance.inprogress_count) + (currentCompliance && currentCompliance.recall_count)) == (tempList.length) ?
                                                            false : <div style={{ marginTop: '2%' }}>
                                                                <div className='text-center'>
                                                                    <Button className="btn btn-success" shape='round' onClick={showmore} >Show More</Button>
                                                                </div>
                                                            </div>
                                                        : (eventValue == tempList.length ?
                                                            "" : <div className='text-center'>
                                                                <Button className="btn btn-success" shape='round' onClick={showmore} >Show More</Button>
                                                            </div>)
                                                }









                                                {/* <div className="row">

                                                    {
                                                        currentDetails && currentDetails.length > 0 && currentDetails.map((list) => {
                                                            return <>
                                                                <Table
                                                                    size={'small'}
                                                                    columns={columns2}
                                                                    dataSource={list.child}
                                                                    bordered
                                                                    title={() => {
                                                                        return list.status
                                                                    }}
                                                                    // {...tableProps}
                                                                    // scroll={{ x: 1500, y: 1000 }}
                                                                    pagination={false}
                                                                // rowSelection={{
                                                                //     columnTitle: <input type="checkbox" name="allchecked" className={'allChecked' + itemdata.index}
                                                                //         onClick={(e) => {
                                                                //             let checked = e.target.checked;
                                                                //             addAllSelectedDataValues(
                                                                //                 checked, itemdata.index
                                                                //             );
                                                                //         }}
                                                                //     />,
                                                                //     columnWidth: '10px',
                                                                //     ...rowSelection
                                                                // }}
                                                                // onHeaderRow={(columns, index) => {
                                                                //     return {
                                                                //         onClick: (e) => {
                                                                //             let checked = e.target.checked;
                                                                //             addAllSelectedDataValues(
                                                                //                 checked,
                                                                //                 itemdata.index
                                                                //             );
                                                                //         }

                                                                //     };
                                                                // }}
                                                                />





                                                            </>

                                                        })
                                                    }
                                                    {

                                                        ((currentCompliance && currentCompliance.overdue_count) + (currentCompliance && currentCompliance.inprogress_count) + (currentCompliance && currentCompliance.recall_count)) == (tempList.length) ?
                                                            false : <div style={{ marginTop: '2%' }}>
                                                                <div className='text-center'>
                                                                    <Button className="btn btn-success" shape='round' onClick={showmore} >Show More</Button>
                                                                </div>
                                                            </div>}

                                                  


                                                </div> */}
                                            </div>



                                            <div>


                                            </div>

                                        </TabPane>


                                        <TabPane
                                            // disabled={tabValue.compfie_vendor == false ? true : false}
                                            tab={
                                                <span style={{ padding: "0px 50px", fontSize: "16px" }}>


                                                    <b>Upcoming Compliance Tasks</b>
                                                </span>
                                            }
                                            key="3">

                                            <div className="col-md-12">
                                                <div className="card p-4 py-2 mb-0 rounded user-mapping2 bg-white">
                                                    <div className="d-flex justify-content-between">
                                                        <div></div>
                                                        <div>
                                                            <i style={{ color: "red" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>One Time</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "green" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>Periodical</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "orange" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>Review</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "pink" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>Flexi Review</label>
                                                        </div>
                                                        <div>
                                                            <i style={{ color: "yellow" }} className="bi bi-square-fill"></i>{" "}
                                                            <label>On Occurrence</label>
                                                        </div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        // upcomingTask.map((list) => {
                                                        // return <>
                                                        <Table
                                                            size={'small'}
                                                            columns={columns}
                                                            locale={{ emptyText: <b className='text-dark'>{'No compliance available'}</b> }}
                                                            dataSource={upcomingTask?.upcoming_compliances}
                                                            bordered
                                                            // title={() => {
                                                            //     return list.parent
                                                            // }}
                                                            // {...tableProps}
                                                            // scroll={{ x: 1500, y: 1000 }}
                                                            pagination={false}
                                                        // rowSelection={{
                                                        //     columnTitle: <input type="checkbox" name="allchecked" className={'allChecked' + itemdata.index}
                                                        //         onClick={(e) => {
                                                        //             let checked = e.target.checked;
                                                        //             addAllSelectedDataValues(
                                                        //                 checked, itemdata.index
                                                        //             );
                                                        //         }}
                                                        //     />,
                                                        //     columnWidth: '10px',
                                                        //     ...rowSelection
                                                        // }}
                                                        // onHeaderRow={(columns, index) => {
                                                        //     return {
                                                        //         onClick: (e) => {
                                                        //             let checked = e.target.checked;
                                                        //             addAllSelectedDataValues(
                                                        //                 checked,
                                                        //                 itemdata.index
                                                        //             );
                                                        //         }

                                                        //     };
                                                        // }}
                                                        />





                                                        //     </>

                                                        // })
                                                    }

                                                    {/* <p style={{ marginTop: '2%' }}>Total Upcoming Compliance : {upcomingTask && upcomingTask.total_count}</p> */}

                                                </div>
                                            </div>
                                            <div>
                                            </div>

                                        </TabPane>

                                    </Tabs>
                                </div>


                                : ''
                            }

                        </div>
                    </div>


                </div >
            </div >
            {/* <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <Calendar
                                    events={events}
                                   
                                     

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Modal title="" visible={false} footer={null} cancelText={false} onCancel={Cancelmodal} >
                {/* <div className="row"> */}
                <div className="col-md-12 d-flex" >
                    <div>
                        <button
                            type="button"
                            className="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                        >
                            <i
                                className="fas fa-list-alt"
                            ></i>
                        </button>
                    </div>
                    <div>
                        <p className='mb-1'><b>Unit Name</b></p>
                        <h4 className='fw-bolder'> {valueModal.unit}&nbsp;<Tooltip title={valueModal.address} >
                            <ExclamationCircleTwoTone style={{ fontSize: '14px' }} />
                        </Tooltip></h4>
                    </div>
                </div>
                <div className="container py-2">
                    <div className="row">
                        <div className="col-6 ModalCard border  p-2 bg-light mb-2">
                            <p className="mb-1"><b>Compliance Task:</b></p>
                            <h5>{valueModal.compliance_task}&nbsp;<Tooltip placement='bottom' title={valueModal.description} >
                                <ExclamationCircleTwoTone style={{ fontSize: '14px' }} />
                            </Tooltip></h5>
                        </div>
                        <div className="col-6  ModalCard border p-2 bg-light mb-2">
                            <p className="mb-1"><b>Compliance Frequency:</b></p>
                            <h5>{valueModal.compliance_frequency}</h5>
                        </div>
                        {/* <div className="col-4 ModalCard  border p-2 bg-light mb-2">
                            <p className="mb-1"><b>Document Reference Number:</b></p>
                            <h5>{valueModal.document_reference_number}</h5>
                        </div> */}
                        <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                            <p className="mb-1"><b>Uploaded Documents:</b></p>
                            {/* {valueModal.uploaded_document != null ?
                                            valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                                                return ( */}
                            <div className='d-flex justify-content-between pb-1'>
                                <input type="file" multiple name="file"
                                    // accept='.csv'
                                    disabled={valueModal.status == 'Recall' ? true : false}
                                    onChange={(e) => {
                                        SavepastRecordOnChange(e)
                                    }}
                                    id="" />
                            </div>
                        </div>

                        <div className="col-md-12 ModalCard border  p-2 bg-light mb-2">
                            <p className="mb-1"><b>Task Completion Date / Document Issued Date: </b><span style={{ color: "red" }}>*</span></p>
                            <h5> <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder={'DD-MMM-YYYY'} className='form-control'
                                value={completionDate ? completionDate : ''}
                                style={{ width: '40%' }}
                                name="validity_date" id=""
                                disabledDate={(current) => {
                                    return current > Date.now()
                                }}
                                onKeyDown={(event) => {
                                    event.preventDefault()
                                }}
                                onChange={(dateString) => {
                                    setCompletionDate(
                                        dateString
                                    )
                                }}
                                disabled={valueModal.status == 'Recall' ? true : false}
                            />

                            </h5>
                        </div>

                        <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                            <p className="mb-1 me-1"><b>Document Reference Number:</b></p>
                            <Input class="form-control"
                                disabled={valueModal.status == 'Recall' ? true : false}
                                style={{ "width": "80%", display: 'block' }}
                                value={valueModal.document_reference_number}
                                onChange={onInputChange1}
                            // onChange={(e) => {
                            //     const value = e.target.value;
                            //     const re = /^[A-Za-z0-9()-_.,/]{0,500}$/;
                            //     if (value === "" || re.test(value)) {
                            //         // SetRemarks(value)
                            //         setDocumentNo(value)
                            //     }
                            // }}
                            />
                        </div>
                        <div className="col-6 ModalCard border  p-2 bg-light mb-2">
                            <p className="mb-1"><b>Attached Documents:</b></p>
                            <h5>
                                {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                    return <p>{item.file_name}    <Button type="primary" class='fa fa-download text-primary c-pointer'
                                        icon={<DownloadOutlined />} size='small'
                                        onClick={(e) => {
                                            download(item.file_name)
                                        }}
                                        style={{ marginTop: '2%' }}  >
                                    </Button> &nbsp;
                                        <Button type="primary" class='fa fa-times text-primary removeicon'
                                            icon={< CloseOutlined />} size='small'
                                            onClick={(e) => { Remove(item.file_name) }}
                                        >
                                        </Button></p>
                                })}
                            </h5>        </div>

                        <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                            <p className="mb-1 me-1"><b>Validity Date : </b></p>
                            <p className="mb-1 me-1">{valueModal.completion_date}</p>
                        </div>

                        <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                            <p className="mb-1 me-1"><b>Status : </b></p>
                            <p className="mb-1 me-1">{valueModal.status}</p>
                        </div>
                        {valueModal.status == 'Recall' ?
                            <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                <p className="mb-1 me-1"><b>Recall Remarks</b></p>
                                <p className="mb-1 me-1">{valueModal.recall}</p>
                                {valueModal.recall_history_count != 0 ?
                                    <>
                                        <Tooltip title="click to view remark history" ><ExclamationCircleTwoTone style={{ marginLeft: '5px' }} onClick={(e) => {
                                            // showModalremark()
                                            // setToolTip([valueModal])
                                            // const remarkapi = [
                                            //     authtoken,
                                            //     {
                                            //         "session_token": authtoken,
                                            //         "request": [
                                            //             "GetComplianceRemarksHistoryData",
                                            //             {
                                            //                 "legal_entity_id": entityid,
                                            //                 "unit_id": Number(valueModal.unit_id),
                                            //                 "compliance_history_id": Number(valueModal.compliance_history_id)
                                            //             }
                                            //         ]
                                            //     }
                                            // ]
                                            // getremarkhistory({
                                            //     payload: remarkapi,
                                            //     paramid: paramid
                                            // })
                                        }
                                        } /></Tooltip></> : ''
                                }
                            </div> : ''}

                        {valueModal.next_due_date !== null ?
                            <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                <p className="mb-1 me-1"><b>Next Due Date : </b></p>
                                <p className="mb-1 me-1">{valueModal.next_due_date}</p>
                            </div> : ''}
                        <div className="col-md-6 border ModalCard p-2 bg-light mb-2">
                            <p className="mb-1"><b>Remarks:</b></p>
                            <textarea class="form-control" placeholder='Enter remarks' maxLength={500} onChange={onInputChange} rows={1} style={{ width: '80%', display: 'inline' }}
                                value={remarks}
                            // onChange={(e) => {
                            //     const value = e.target.value;
                            //     console.log(value,'valuevalue');
                            //     const re = /^[A-Za-z0-9_ .,-]{0,500}$/;
                            //     if (value === "" || re.test(value)) {
                            //         console.log(value,'valuevaluevaluevalue');
                            //         SetRemarks(value)
                            //     }
                            // }}
                            />
                            <span className='mx-1 text-info'
                                onClick={() => { showRemarksModal() }}>
                                <PlusCircleOutlined />
                            </span>
                        </div>
                        <div className="col-md-12 text-center p-2 mb-2">
                            {
                                valueModal.status !== 'Recall' ? <div className={'popupbtncolour ' + localStorage.getItem('currentTheme')}>
                                    <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                        // style={{ background: "#198754", borderColor: "#198754", marginLeft: '25%' }}
                                        size='default'
                                        onClick={() => {
                                            if (fileupload && fileupload.length > 0 || remarks !== '') {
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "SaveInterimComplianceHistory",
                                                            {
                                                                "legal_entity_id": showdata && showdata.legal_entity,
                                                                "compliance_history_id": valueModal.compliance_history_id,
                                                                "documents": fileupload.length > 0 ? fileupload : null,
                                                                "uploaded_documents": null,
                                                                "remarks": remarks,
                                                                "file_size": 0
                                                            }
                                                        ]
                                                    }
                                                ]
                                                const payload2 = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetCurrentComplianceDetail",
                                                            {
                                                                "le_id": showdata.legal_entity,
                                                                "unit_id": showdata.unit != '' ? Number(showdata.unit) : null,
                                                                "current_start_count": 0,
                                                                "cal_view": null,
                                                                "cal_date": null,
                                                                "d_id": showdata.domain != '' ? Number(showdata.domain) : null,
                                                                "division_id": showdata.division != '' ? Number(showdata.division) : null,
                                                                "category_id": showdata.category != '' ? Number(showdata.category) : null,
                                                                "frequency_id": showdata.frequency != '' ? Number(showdata.frequency) : null,
                                                            }
                                                        ]
                                                    }
                                                ]


                                                updateStatus({
                                                    payload: payload,
                                                    paramid: paramid,
                                                    setShowValueModal: setShowValueModal,
                                                    nextData: nextDatas,
                                                    payload2: payload2

                                                })
                                            }
                                            else {
                                                Toaster.error("Please give either a valid remarks Or upload document to save")
                                            }


                                        }}
                                    >
                                        Update Interim Status
                                    </Button>
                                    <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        onsubmit
                                        icon={<PlayCircleOutlined />} size='default'
                                        onClick={onsubmit

                                        }
                                    >
                                        Submit
                                    </Button>
                                </div> : false
                            }
                        </div>


                    </div>
                </div>

            </Modal>

            <Modal title="Remarks List" visible={remarksModal}
                onCancel={handleCancel}
                className={" add-service-prv remarksClass " + localStorage.getItem('currentTheme')} footer={false}>
                <Table
                    // className='userprivclass'
                    size={'small'}
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 ? remarksvalue : ''}
                    bordered
                    pagination={false}
                />
            </Modal>
            <Modal autoFocus={false} visible={passvisible} className="newStyleModalPassword" footer={null} onCancel={passwordcancel}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                            <input
                                autoFocus={true}
                                maxLength='20'
                                type="password"
                                id="password"
                                autoComplete='off'
                                name='passwordvalue'
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value

                                    })
                                }}
                                value={passwordata.passvalue}
                                style={{ "margin-top": "5px", width: '100%' }}
                                className="form-control"
                            />
                            {formValidator1.current.message(
                                'Password',
                                passwordata.passvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className={"form-actions popupbtncolour " + localStorage.getItem('currentTheme')}>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                        style={{ marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default'
                        onClick={submitpassword}
                    >Submit
                    </Button>

                    {/* <button
                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start"
                        onClick={passwordcancel}
                    >Cancel
                    </button> */}
                </div>
            </Modal>
            {valueModal.remarks != "" || null ?
                <Modal title="Remarks History" className={' remarksClass  add-service-prv ' + localStorage.getItem("currentTheme")} visible={isModalOpenremark} footer={null} onCancel={handleCancelremark}>

                    <Table
                        size={'small'}
                        className={localStorage.getItem("currentTheme")}
                        columns={columnsremark}
                        dataSource={remarklist.remarks_history}
                        bordered
                        pagination={false}
                    />
                </Modal> : ""}
        </div >

    )
}

export default connect(mapStateToProps, {
    compliancefilters,
    emailsave,
    GetGroupListData,
    FetchEmails,
    verifypassword,
    updateblockoption,
    GetOnOccurrenceCompliances,
    onoccurrencetransaction,
    startonoccurrencecompliance,
    GetRemarksData,
    getCalenderView,
    getCurrentTaskDetails,
    UploadFile1,
    getUpcomigTaskDetails,
    getFilterDataValues,
    UploadFile,
    Downloadfile,
    removedocument,
    updateStatus,
    saveStatus,
    getremarkhistory,
    fileListTemp
})(ComplianceTaskDetails)
