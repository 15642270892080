import React, { Fragment } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Collapse, Select, Input, DatePicker, Card, Modal, Button, Table, Tooltip, Pagination } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ExclamationCircleTwoTone, InfoCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, PlayCircleOutlined, DownloadOutlined, UpOutlined, FullscreenOutlined, ExportOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { LoginTrialReportFilters, LoginTraceReportData, ExportLoginTraceReportData } from '../../../Store/Action/Report/LoginTrace';
import { LOGIN_TRIAL_REPORT_EXPORT_RESET } from '../../../Store/types/index'
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import url, { getUrl } from './../../../Libs/URL'
import { HomeRedirect } from './../../../Libs/country'
const homepage = HomeRedirect()
const urls = getUrl()

const mapStateToProps = (state) => ({
    logintrialreports: state.LoginTrace.logintracereport,
})

const LoginTrace = ({
    LoginTrialReportFilters,
    LoginTraceReportData,
    ExportLoginTraceReportData,
    logintrialreports: { logintracefilter, logintraceshow, logintraceexport }
}) => {

    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid').replaceAll('"', ''));
    const paramid = location.pathname;
    const _ = require("lodash");
    const dispatch = useDispatch();
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    let [count, setcount] = useState(0)
    const [totalcount, setTotalcount] = useState("")
    const [key, setKey] = useState("1")
    const [legalstate, Setlegalstate] = useState(false)
    const [listData, setListData] = useState([])
    console.log(listData, 'listData');
    console.log(logintracefilter, 'logintracefilter');
    const [unitdata, setUnitData] = useState({
        user: 0,
        user_name: '',
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        from_date: "",
        to_date: "",
        time_zone_id: '',
        time_zone_name: '',
        min_date: ''
    })
    // const [disableButton, setDisableButton] = useState(false)
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;

    // const disablecondition = (e) => {
    //     var currentToDate = moment(unitdata.from_date).add(3, 'months')
    //     if (currentToDate < moment(e)) {
    //         setDisableButton(true)
    //     } else {
    //         setDisableButton(false)
    //     }
    // }

    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);
    useEffect(() => {
        if (logintraceshow && logintraceshow.log_trace_activities && logintraceshow.log_trace_activities.length > 0) {
            setListData(logintraceshow && logintraceshow.log_trace_activities)
        }
    }, [logintraceshow && logintraceshow.log_trace_activities])

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    useEffect(() => {
        if (isAuth) {
            // if (legalstate === true) {
            // if (entityid != "null") {
            LoginTrialReportFilters({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetLogintraceReportFilters",
                            {

                            }
                        ]
                    }
                ],
                paramid: paramid
            })
            // }
            // Setlegalstate(false)
            // }
        }
    }, [isAuth, entityid, legalstate])

    console.log(logintracefilter, 'logintracefilter');

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {

        if (logintraceshow && logintraceshow.total_count > 0) {

            setTotalcount(logintraceshow.total_count)
        }

    }, [logintraceshow])


    useEffect(() => {
        if (filteredArray.length) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        if (pageState === true) {
            console.log(pageSize, 'pageSize');
            console.log(current, 'current');
            setcount(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    useEffect(() => {
        if (entityid != null) {
            setUnitData({
                ...unitdata,
                country: sessionArr[0].c_id
            })
        }
    }, [entityid != null])
    useEffect(() => {

        if (logintraceexport && logintraceexport.link) {

            window.open(urls + '/' + logintraceexport.link);

            dispatch({

                type: LOGIN_TRIAL_REPORT_EXPORT_RESET
            });
        }
    }, [logintraceexport])
    // useEffect(() => {
    //     if (entityid === null) {
    //         setCurrentEntity(unitdata.legel_entity_id);
    //     }
    // }, [entityid]);
    useEffect(() => {
        if (unitdata.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [unitdata.legal_entity_name])

    useEffect(() => {
        if (logintraceexport && logintraceexport.link) {
            window.open(urls + '/' + logintraceexport.link);
        }

    }, [logintraceexport])

    const onshow = () => {
        setAddFormSubmit(true)
        setcount(0);
        setCurrent(1);
        setpageState(false)
        setPageSize(25)
        setTotalcount('')
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            LoginTraceReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetLoginTraceReportData",
                            {
                                "user_id": Number(unitdata.user),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": false,
                                "from_count": 0,
                                "page_count": 25,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'
                            }
                        ]
                    }
                ],
                paramid: paramid
            })

        }
    }
    useEffect(() => {
        if (pageState == true) {
            LoginTraceReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetLoginTraceReportData",
                            {
                                "user_id": Number(unitdata.user),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": false,
                                "from_count": Math.max(((current - 1) * pageSize), 0),
                                "page_count": pageSize,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])
    const onexport = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            setKey(0)
            ExportLoginTraceReportData({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetLoginTraceReportData",
                            {
                                "user_id": Number(unitdata.user),
                                "due_from_date": unitdata.from_date,
                                "due_to_date": unitdata.to_date,
                                "csv": true,
                                "from_count": 0,
                                "page_count": 0,
                                "tz_name": unitdata.time_zone_name ? unitdata.time_zone_name : 'UTC'
                            }
                        ]
                    }
                ],
                paramid: paramid
            })

        }
    }

    const onChange = (key) => {

    };

    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    console.log(fullscreen, 'fullscreen')

    return (
        <>
            <div id='page-wrapper' className="page-wrapper">
                {tableshow == true ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''}
                <div className="page-titles pb-2 pt-1 page-title-sticky">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>Log Reports</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Login Trace</span>
                                    </li>
                                </ol>
                            </nav>

                        </div>
                        <div className="col-lg-4 text-end">
                            <Tooltip title="Advance Filter"><Button
                                className="mt-1"
                                type="primary"
                                shape="round"
                                style={{
                                    display: filterTaskAcc ? "none" : "initial",
                                }}
                                onClick={filterHandle}
                            >
                                <FilterOutlined /> Filter
                            </Button></Tooltip>
                            <button style={{ float: 'right', marginTop: '5px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" ttitle={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className={"card-body userCard " + localStorage.getItem('currentTheme')} id='only-restricted' style={{ position: 'relative', padding: "10px 3px" }}>
                                <Collapse defaultActiveKey={["1"]} onChange={onChange} className='report-collapse' style={{ display: exitCollapse ? 'none' : 'block', marginLeft: '10%', marginRight: '10%' }}>
                                    <Panel header="Login Trace" key={"1"}>
                                        <div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>From Date : </b><span style={{ color: "red" }} > *</span></label>
                                                            <DatePicker
                                                                className='form-control'
                                                                format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                                value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                                allowClear={true}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                onKeyDown={(event) => {
                                                                    event.preventDefault()
                                                                }}
                                                                disabledDate={(current) => {
                                                                    return current && current.valueOf() > Date.now();
                                                                }}
                                                                onChange={(date, dateString) => {
                                                                    setListData([])
                                                                    console.log(date, 'dateString');
                                                                    if (date != null) {
                                                                        setUnitData({
                                                                            ...unitdata,
                                                                            from_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),
                                                                            min_date: dateString
                                                                        })
                                                                    }
                                                                    else {
                                                                        setUnitData({
                                                                            ...unitdata,
                                                                            from_date: '',
                                                                            min_date: '',
                                                                        })
                                                                    }
                                                                }
                                                                }

                                                            />

                                                            {validator.current.message(
                                                                'from_date',
                                                                unitdata.from_date,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'From Date Required',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor=""><b>To Date : </b><span style={{ color: "red" }} > *</span></label>
                                                        <div className="form-group">
                                                            <DatePicker
                                                                allowClear={true}
                                                                className='form-control'
                                                                format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                                onKeyDown={(event) => {
                                                                    event.preventDefault()
                                                                }}
                                                                disabledDate={(current) => {
                                                                    return moment(unitdata.from_date) >= current || current > Date.now();
                                                                }}
                                                                value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                onChange={(date, dateString) => {
                                                                    setListData([])
                                                                    if (date !== null) {
                                                                        // disablecondition(dateString)
                                                                        setUnitData({
                                                                            ...unitdata,
                                                                            to_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),

                                                                        })
                                                                    }
                                                                    else {

                                                                        setUnitData({
                                                                            ...unitdata,
                                                                            to_date: '',

                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                            {validator.current.message(
                                                                'to_date',
                                                                unitdata.to_date,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'To Date Required',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-1">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor=""><b>User : </b></label>
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    showSearch
                                                                    value={unitdata.user_name || undefined}
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                                    }}
                                                                    onChange={(value, data) => {
                                                                        setUnitData({
                                                                            ...unitdata,
                                                                            user: value != undefined ? value : '',
                                                                            user_name: data != undefined ? data.children : ''
                                                                        })
                                                                    }}
                                                                    placeholder="Enter User"
                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                >
                                                                    {logintracefilter && logintracefilter.audit_users_list && logintracefilter.audit_users_list.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.user_id}>
                                                                                {item.user_name}
                                                                            </Option>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor=""><b>Display in Time Zone : </b></label>
                                                            <Select
                                                                allowClear={true}
                                                                showSearch
                                                                placeholder='Enter Time Zone'
                                                                value={unitdata.time_zone_name || undefined}
                                                                // defaultValue={"All"}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                                }}
                                                                onChange={(value, data) => {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        time_zone_id: value != undefined ? value : '',
                                                                        time_zone_name: data != undefined ? data.children : ''
                                                                    })
                                                                }}
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                            >
                                                                {logintracefilter && logintracefilter.tz_list && logintracefilter.tz_list.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.tz_id}>
                                                                            {item.tz_name}
                                                                        </Option>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div >
                                            <div className='text-center popupbtncolour mb-2' style={{ marginTop: "10px" }}>
                                                <Button
                                                    // disabled={disableButton}
                                                    type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                                    onClick={onshow} icon={<EyeOutlined />}>
                                                    Show
                                                </Button>
                                                <Button title='' type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                    // style={{ background: "#32a852", borderColor: "#32a852" }}
                                                    onClick={onexport} icon={<ExportOutlined />}
                                                >
                                                    Export
                                                </Button>
                                                {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <Modal
                                    onCancel={setcancelFilter}
                                    footer={false}
                                    title="Login Trace"
                                    className={
                                        "add-service-prv modal-service-priv " +
                                        localStorage.getItem("currentTheme")
                                    }
                                    visible={filterModalVisible}
                                >
                                    <div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>From Date : </b><span style={{ color: "red" }} > *</span></label>
                                                        <DatePicker
                                                            className='form-control'
                                                            format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                            value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                            allowClear={true}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            onKeyDown={(event) => {
                                                                event.preventDefault()
                                                            }}
                                                            disabledDate={(current) => {
                                                                return current && current.valueOf() > Date.now();
                                                            }}
                                                            onChange={(date, dateString) => {
                                                                setListData([])
                                                                console.log(date, 'dateString');
                                                                if (date != null) {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        from_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),
                                                                        min_date: dateString
                                                                    })
                                                                }
                                                                else {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        from_date: '',
                                                                        min_date: '',
                                                                    })
                                                                }
                                                            }
                                                            }

                                                        />

                                                        {validator.current.message(
                                                            'from_date',
                                                            unitdata.from_date,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'From Date Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>To Date : </b><span style={{ color: "red" }} > *</span></label>
                                                    <div className="form-group">
                                                        <DatePicker
                                                            allowClear={true}
                                                            className='form-control'
                                                            format={'DD-MMM-YYYY'} placeholder='DD-MMM-YYYY'
                                                            onKeyDown={(event) => {
                                                                event.preventDefault()
                                                            }}
                                                            disabledDate={(current) => {
                                                                return moment(unitdata.from_date) >= current || current > Date.now();
                                                            }}
                                                            value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            onChange={(date, dateString) => {
                                                                setListData([])
                                                                if (date !== null) {
                                                                    // disablecondition(dateString)
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        to_date: moment(new Date(dateString)).format('DD-MMM-YYYY'),

                                                                    })
                                                                }
                                                                else {

                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        to_date: '',

                                                                    })
                                                                }
                                                            }}
                                getPopupContainer={trigger => trigger.parentNode}

                                                        />
                                                        {validator.current.message(
                                                            'to_date',
                                                            unitdata.to_date,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'To Date Required',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-1">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor=""><b>User : </b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                showSearch
                                                                value={unitdata.user_name || undefined}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                                }}
                                                                onChange={(value, data) => {
                                                                    setUnitData({
                                                                        ...unitdata,
                                                                        user: value != undefined ? value : '',
                                                                        user_name: data != undefined ? data.children : ''
                                                                    })
                                                                }}
                                                                placeholder="Enter User"
                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                            >
                                                                {logintracefilter && logintracefilter.audit_users_list && logintracefilter.audit_users_list.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.user_id}>
                                                                            {item.user_name}
                                                                        </Option>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor=""><b>Display in Time Zone : </b></label>
                                                        <Select
                                                            allowClear={true}
                                                            showSearch
                                                            placeholder='Enter Time Zone'
                                                            value={unitdata.time_zone_name || undefined}
                                                            // defaultValue={"All"}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                            }}
                                                            onChange={(value, data) => {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    time_zone_id: value != undefined ? value : '',
                                                                    time_zone_name: data != undefined ? data.children : ''
                                                                })
                                                            }}
                                                            style={{ width: '100%', marginTop: '5px' }}getPopupContainer={trigger => trigger.parentNode}
                                                        >
                                                            {logintracefilter && logintracefilter.tz_list && logintracefilter.tz_list.map((item, i) => {
                                                                return (
                                                                    <Option key={item.tz_id}>
                                                                        {item.tz_name}
                                                                    </Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='text-center popupbtncolour ' style={{ marginTop: "10px" }}>
                                        <Button
                                            // disabled={disableButton}
                                            type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                            onClick={onshow} icon={<EyeOutlined />}>
                                            Show
                                        </Button>
                                        <Button title='' type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                            // style={{ background: "#32a852", borderColor: "#32a852" }}
                                            onClick={onexport} icon={<ExportOutlined />}
                                        >
                                            Export
                                        </Button>
                                        {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                        <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                    </div>
                                </Modal>
                                {/* <br /> */}
                                {tableshow == true ?

                                    <Card style={{ marginTop: '0.2%', marginLeft: '10%', marginRight: '10%' }} className="servcardround">

                                        <div className='d-flex justify-content-evenly filter-detial-sticky' >
                                            {/* <div className='row'>
                                                <div className='col-md-6'> */}
                                            <label><b>From Date :</b> {unitdata.from_date}</label>
                                            {/* </div>

                                                <div className='col-md-6 text-end'> */}
                                            <label><b>To Date :</b> {unitdata.to_date}</label>
                                            {/* </div> */}

                                            {/* </div> */}
                                        </div>
                                        {/* <br /> */}
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content audittrailtable'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead antable-head table-head-stick">
                                                                <tr>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '10px' }}> # </th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}>Date & Time</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '220px' }}>Info</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody className='ant-table-tbody'>
                                                                {listData && listData.length > 0 ? listData.map((itemdata, i) => {
                                                                    return <Fragment key={i}>
                                                                        <tr>
                                                                            <td className='ant-table-cell'><center>{count + 1}</center></td>
                                                                            <td className='ant-table-cell'>{itemdata.created_on}</td>
                                                                            <td className='ant-table-cell border-less'>{itemdata.action}</td>
                                                                            <span hidden>{count = count + 1}</span>
                                                                        </tr>
                                                                    </Fragment>
                                                                }) :
                                                                    <tr>
                                                                        <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-3'>
                                            {count != 0 ?
                                                <>
                                                    <div className='col-md-3' >
                                                        <label>Showing {pageSize * (current - 1) + 1} to {count} of {Number(totalcount)} entries</label>
                                                    </div>
                                                    <div className='col-md-9 text-right' >

                                                        <Pagination
                                                            current={current}
                                                            showSizeChanger={true}
                                                            showQuickJumper={false}
                                                            onShowSizeChange={() => {
                                                                setCurrent(1)

                                                            }}
                                                            pageSizeOptions={[25, 50, 100]} hideOnSinglePage={Number(totalcount) > 25 ? false : true} defaultPageSize={25}
                                                            onChange={(page, pageSizes) => {
                                                                setpageState(true)
                                                                setCurrent(pageSize !== pageSizes ? 1 : page);
                                                                setPageSize(pageSizes)
                                                            }} total={Number(totalcount)}
                                                        // showTotal={(total) => `Showing 1 to ${count} of ${Number(totalcount)} entries`}
                                                        />


                                                    </div>
                                                </>
                                                : ''}
                                        </div>
                                    </Card> : ''
                                    // <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>

    )
}
export default connect(mapStateToProps, {
    LoginTrialReportFilters,
    LoginTraceReportData,
    ExportLoginTraceReportData,
})(LoginTrace);