import { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Select, Progress, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { Area, Line } from '@ant-design/plots';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useLocation, useHistory } from 'react-router-dom';
import { getWidgetData, getRiskChart, getOverDueChart, getComplianceStatusChart, getTaskDistributionChart, getCriticalityChart, getComplianceTrend, getCountryData } from '../../Store/Action/Widgets';
import { Toaster } from '../../Libs/Toaster';

const mapStateToProps = (state) => ({
    widgetInfo: state.Widgets.widInfo,
    riskWidget: state.Widgets.riskWid,
    overdueWidget: state.Widgets.overDueWid,
    complianceStatusWidget: state.Widgets.complianceStatusChart,
    taskDistributionWidget: state.Widgets.taskdistributionWid,
    criticalityWidget: state.Widgets.criticalityWid,
    complianceTrendWidget: state.Widgets.complianceTrendWid,
    countryWidgetData: state.Widgets.countryInfo
    // commonData: state.ComplianceStatusChart.complianceStatusChart.Data,

})
const LandingPage = ({
    getWidgetData,
    widgetInfo,
    getRiskChart,
    riskWidget,
    getCommonData,
    commonData,
    getOverDueChart,
    overdueWidget,
    getComplianceStatusChart,
    complianceStatusWidget,
    getTaskDistributionChart,
    taskDistributionWidget,
    getCriticalityChart,
    criticalityWidget,
    getComplianceTrend,
    complianceTrendWidget,
    getCountryData,
    countryWidgetData
}) => {
    const _ = require("lodash");
    const history = useHistory();
    const [dayStat, setDayStat] = useState(false)
    const [complianceApprovalUrl, setComplianceApprovalUrl] = useState('')
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    const isAuth = localStorage.getItem('isAuthenticated');
    const login = callername && callername.login_response.menu.Static && callername.login_response.menu.Static[0];
    const [entityData, setEntityData] = useState([])
    const [countryDomain, setCountryDomain] = useState([])
    const [overdueId, setOverdueId] = useState()
    const [cricId, setCricId] = useState()
    const [taskDisId, setTaskDisId] = useState()
    const [compTrendId, setcompTrendId] = useState()
    const [riskId, setRiskId] = useState()
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const sessionArr = sessionParsedValue.entity_info
    const location = useLocation();
    const { Option } = Select;
    const paramid = location.pathname;

    
    const data =
        [
            { "Year": "2022", "date": 'Jan', "value": 1390 },
            { "Year": "2022", "date": 'Feb', "value": 1469 },
            { "Year": "2022", "date": 'Mar', "value": 1521 },
            { "Year": "2022", "date": 'Apr', "value": 1615 },
            { "Year": "2022", "date": 'May', "value": 1703 },
            { "Year": "2022", "date": 'Jun', "value": 1767 },
            { "Year": "2022", "date": 'Jul', "value": 1806 },
            { "Year": "2022", "date": 'Aug', "value": 1903 },
            { "Year": "2022", "date": 'Sep', "value": 1986 },
            { "Year": "2022", "date": 'Oct', "value": 1952 },
            { "Year": "2022", "date": 'Nov', "value": 1910 },
            { "Year": "2022", "date": 'Dec', "value": 2015 },
            { "Year": "2023", "date": 'Jan', "value": 1000 },
            { "Year": "2023", "date": 'Feb', "value": 1100 },
            { "Year": "2023", "date": 'Mar', "value": 1200 },
            { "Year": "2023", "date": 'Apr', "value": 1300 },
            { "Year": "2023", "date": 'May', "value": 1541 },
            { "Year": "2023", "date": 'Jun', "value": 1364 },
            { "Year": "2023", "date": 'Jul', "value": 3541 },
            { "Year": "2023", "date": 'Aug', "value": 1955 },
            { "Year": "2023", "date": 'Sep', "value": 5410 },
            { "Year": "2023", "date": 'Oct', "value": 1345 },
            { "Year": "2023", "date": 'Nov', "value": 2052 },
            { "Year": "2023", "date": 'Dec', "value": 3546 },
        ]
    const dataline =
        [
            {
                "year": "Jan",
                "value": 30,
                "category": "Complied"
            },
            {
                "year": "Jan",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jan",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Feb",
                "value": 110,
                "category": "Complied"
            },
            {
                "year": "Feb",
                "value": 94,
                "category": "Delayed Compliance"
            },
            {
                "year": "Feb",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Mar",
                "value": 75,
                "category": "Complied"
            },
            {
                "year": "Mar",
                "value": 45,
                "category": "Delayed Compliance"
            },
            {
                "year": "Mar",
                "value": 56,
                "category": "Not Complied"
            },
            {
                "year": "Apr",
                "value": 40,
                "category": "Complied"
            },
            {
                "year": "Apr",
                "value": 44,
                "category": "Delayed Compliance"
            },
            {
                "year": "Apr",
                "value": 64,
                "category": "Not Complied"
            },
            {
                "year": "May",
                "value": 65,
                "category": "Complied"
            },
            {
                "year": "May",
                "value": 75,
                "category": "Delayed Compliance"
            },
            {
                "year": "May",
                "value": 65,
                "category": "Not Complied"
            },
            {
                "year": "Jun",
                "value": 74,
                "category": "Complied"
            },
            {
                "year": "Jun",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jun",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Jul",
                "value": 80,
                "category": "Complied"
            },
            {
                "year": "Jul",
                "value": 70,
                "category": "Delayed Compliance"
            },
            {
                "year": "Jul",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Aug",
                "value": 75,
                "category": "Complied"
            },
            {
                "year": "Aug",
                "value": 46,
                "category": "Delayed Compliance"
            },
            {
                "year": "Aug",
                "value": 90,
                "category": "Not Complied"
            },
            {
                "year": "Sep",
                "value": 65,
                "category": "Complied"
            },
            {
                "year": "Sep",
                "value": 54,
                "category": "Delayed Compliance"
            },
            {
                "year": "Sep",
                "value": 84,
                "category": "Not Complied"
            },
            {
                "year": "Oct",
                "value": 76,
                "category": "Complied"
            },
            {
                "year": "Oct",
                "value": 45,
                "category": "Delayed Compliance"
            },
            {
                "year": "Oct",
                "value": 96,
                "category": "Not Complied"
            },
            {
                "year": "Nov",
                "value": 60,
                "category": "Complied"
            },
            {
                "year": "Nov",
                "value": 74,
                "category": "Delayed Compliance"
            },
            {
                "year": "Nov",
                "value": 80,
                "category": "Not Complied"
            },
            {
                "year": "Dec",
                "value": 110,
                "category": "Complied"
            },
            {
                "year": "Dec",
                "value": 90,
                "category": "Delayed Compliance"
            },
            {
                "year": "Dec",
                "value": 70,
                "category": "Not Complied"
            },

        ]
    const configs = {
        data: data,
        xField: 'date',
        yField: 'value',
        seriesField: 'Year',
        legend: {
            position: 'bottom'
        }
    };

    const configline = {
        data: dataline,
        xField: 'year',
        yField: 'value',
        seriesField: 'category',
        yAxis: {
            label: {
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        color: ['#1979C9', '#D62A0D', '#FAA219'],
        legend: {
            position: 'bottom',
        },
        smooth: false,

    };

    useEffect(()=>{
     if(isAuth){
        Toaster.warning('Development Under Progress')
     }
    },[isAuth])

    useEffect(() => {
        if (countryWidgetData && countryWidgetData.d_info && countryWidgetData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            for (let i in countryWidgetData.d_info) {
                tempIds.push(countryWidgetData.d_info[i].d_id)
                tempArr.push({
                    'd_id': countryWidgetData.d_info[i].d_id,
                    'c_id': countryWidgetData.d_info[i].c_id,
                    "month_from": countryWidgetData.d_info[i].month_from,
                    "month_to": countryWidgetData.d_info[i].month_to
                })
            }
            console.log(tempIds, 'tempIds');
            // setDIds(tempIds)
            setCountryDomain(tempArr)
            console.log(tempArr, 'tempArr');
        }

    }, [countryWidgetData])

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
            return item.le_id
        })
        setEntityData(id)
        // }
    }, [])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCountryData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityData, entityid])


    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUserWidgetData",
                        {}
                    ]
                }
            ]
            if (paramid != "") {
                getWidgetData({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, entityid, entityData])

    useEffect(() => {
        if (widgetInfo && countryDomain.length > 0) {
            if (widgetInfo && widgetInfo.widget_info && widgetInfo.widget_info.length > 0) {
                let overId = _.find(widgetInfo.widget_info, { w_id: 3 })
                if (overId) {
                    setOverdueId(overId.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetNotCompliedChart",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getOverDueChart({
                        payload: payload,
                        caller: login.form_url
                    })
                }
                let critiId = _.find(widgetInfo.widget_info, { w_id: 12 })
                if (critiId) {
                    setCricId(critiId.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetTaskCriticality",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getCriticalityChart({
                        payload: payload,
                        caller: login.form_url
                    })
                }
                let taskId = _.find(widgetInfo.widget_info, { w_id: 11 })
                if (taskId) {
                    setTaskDisId(taskId.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetTaskDistributionWidget",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getTaskDistributionChart({
                        payload: payload,
                        caller: login.form_url
                    })
                }
                let complianceTrend = _.find(widgetInfo.widget_info, { w_id: 10 })
                if (complianceTrend) {
                    setcompTrendId(complianceTrend.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetComplianceTrend",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getComplianceTrend({
                        payload: payload,
                        caller: login.form_url
                    })
                }
                let riskWId = _.find(widgetInfo.widget_info, { w_id: 5 })
                if (riskWId) {
                    setRiskId(riskWId.w_id)
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetRiskChart",
                                {
                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                    "country_domains": countryDomain
                                }
                            ]
                        }
                    ]
                    getRiskChart({
                        payload: payload,
                        caller: login.form_url
                    })
                }
            }
        }

    }, [widgetInfo, countryDomain])



    // useEffect(() => {
    //     if (widgetInfo && countryDomain.length > 0) {
    //         if (widgetInfo && widgetInfo.widget_info && widgetInfo.widget_info.length > 0) {
    //             for (let i in widgetInfo.widget_info) {
    //                 if (widgetInfo.widget_info[i].w_id === 5) {
    //                     setOverdueId()
    //                     const payload = [
    //                         authtoken,
    //                         {
    //                             "session_token": authtoken,
    //                             "request": [
    //                                 "GetRiskChart",
    //                                 {
    //                                     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                                     "country_domains": countryDomain
    //                                 }
    //                             ]
    //                         }
    //                     ]
    //                     getRiskChart({
    //                         payload: payload,
    //                         caller: login.form_url
    //                     })
    //                 }
    //                 if (widgetInfo.widget_info[i].w_id === 3) {
    //                     const payload = [
    //                         authtoken,
    //                         {
    //                             "session_token": authtoken,
    //                             "request": [
    //                                 "GetNotCompliedChart",
    //                                 {
    //                                     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                                     "country_domains": countryDomain
    //                                 }
    //                             ]
    //                         }
    //                     ]
    //                     getOverDueChart({
    //                         payload: payload,
    //                         caller: login.form_url
    //                     })
    //                 }
    //                 if (widgetInfo.widget_info[i].w_id === 1) {
    //                     const payload = [
    //                         authtoken,
    //                         {
    //                             "session_token": authtoken,
    //                             "request": [
    //                                 "GetComplianceChart",
    //                                 {
    //                                     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                                     "country_domains": countryDomain
    //                                 }
    //                             ]
    //                         }
    //                     ]
    //                     getComplianceStatusChart({
    //                         payload: payload,
    //                         caller: login.form_url
    //                     })
    //                 }
    //                 if (widgetInfo.widget_info[i].w_id === 11) {
    //                     const payload = [
    //                         authtoken,
    //                         {
    //                             "session_token": authtoken,
    //                             "request": [
    //                                 "GetTaskDistributionWidget",
    //                                 {
    //                                     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                                     "country_domains": countryDomain
    //                                 }
    //                             ]
    //                         }
    //                     ]
    //                     getTaskDistributionChart({
    //                         payload: payload,
    //                         caller: login.form_url
    //                     })
    //                 }
    //                 if (widgetInfo.widget_info[i].w_id === 12) {
    //                     const payload = [
    //                         authtoken,
    //                         {
    //                             "session_token": authtoken,
    //                             "request": [
    //                                 "GetTaskCriticality",
    //                                 {
    //                                     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                                     "country_domains": countryDomain
    //                                 }
    //                             ]
    //                         }
    //                     ]
    //                     getCriticalityChart({
    //                         payload: payload,
    //                         caller: login.form_url
    //                     })
    //                 }
    //                 if (widgetInfo.widget_info[i].w_id === 10) {
    //                     const payload = [
    //                         authtoken,
    //                         {
    //                             "session_token": authtoken,
    //                             "request": [
    //                                 "GetComplianceTrend",
    //                                 {
    //                                     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
    //                                     "country_domains": countryDomain
    //                                 }
    //                             ]
    //                         }
    //                     ]
    //                     getComplianceTrend({
    //                         payload: payload,
    //                         caller: login.form_url
    //                     })
    //                 }
    //             }
    //         }
    //     }
    // }, [widgetInfo, countryDomain])

    //Over Due Widget useEffect

    useEffect(() => {
        if (overdueWidget) {
            am4core.useTheme(am4themes_animated);
            //overduechart
            var chartoverdue = am4core.create("chartdiv", am4charts.RadarChart);
            let total = (overdueWidget && overdueWidget.widget_data && overdueWidget && overdueWidget.widget_data[0].y) + (overdueWidget && overdueWidget.widget_data && overdueWidget && overdueWidget.widget_data[1].y) + (overdueWidget && overdueWidget.widget_data && overdueWidget && overdueWidget.widget_data[2].y) + (overdueWidget && overdueWidget.widget_data && overdueWidget && overdueWidget.widget_data[3].y)
            chartoverdue.height = '330'
            chartoverdue.width = '500'
            chartoverdue.data = [{
                "category": "0-30 days",
                "value": (Math.round(overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[0].y) / (total) * 100).toFixed(2),
                "full": 100,
                "custom": overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[0].y
            }, {
                "category": "31-60 days",
                "value": (Math.round(overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[1].y) / (total) * 100).toFixed(2),
                "full": 100,
                "custom": overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[1].y

            }, {
                "category": "61-90 days",
                "value": (Math.round(overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[2].y) / (total) * 100).toFixed(2),
                "full": 100,
                "custom": overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[2].y

            }, {
                "category": "Above 90 days",
                "value": (Math.round(overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[3].y) / (total) * 100).toFixed(2),
                "full": 100,
                "custom": overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 && overdueWidget.widget_data[3].y

            }];
            chartoverdue.startAngle = -90;
            chartoverdue.endAngle = 180;
            chartoverdue.innerRadius = am4core.percent(20);
            // chartoverdue.numberFormatter.numberFormat = "#.#'%'";
            chartoverdue.colors.list = [am4core.color("#F79B80"), am4core.color("#F2746B"), am4core.color("#F35D53"), am4core.color("#F24D64")];
            var categoryAxis = chartoverdue.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "category";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.fontWeight = 500;
            categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
                return (target.dataItem.index >= 0) ? chartoverdue.colors.getIndex(target.dataItem.index) : fill;
            });
            categoryAxis.renderer.minGridDistance = 10;

            var valueAxis = chartoverdue.xAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.min = 0;
            valueAxis.max = 100;
            valueAxis.strictMinMax = true;

            // Create series
            var series1 = chartoverdue.series.push(new am4charts.RadarColumnSeries());
            series1.dataFields.valueX = "full";
            series1.dataFields.categoryY = "category";
            series1.clustered = false;
            series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
            series1.columns.template.fillOpacity = 0.08;
            series1.columns.template.cornerRadiusTopLeft = 20;
            series1.columns.template.strokeWidth = 0;
            series1.columns.template.radarColumn.cornerRadius = 20;
            series1.columns.template.tooltipText = `{category}: [bold]{custom}[/] out of ${total}`;


            var series2 = chartoverdue.series.push(new am4charts.RadarColumnSeries());
            series2.dataFields.valueX = "value";
            series2.dataFields.categoryY = "category";
            series2.clustered = false;
            series2.columns.template.strokeWidth = 0;
            // series2.columns.template.tooltipText = "{category}: [bold]{value}({custom})[/]";
            series2.columns.template.tooltipText = `{category}: [bold]{custom}[/] out of ${total} ({value}%)`;
            series2.columns.template.radarColumn.cornerRadius = 20;

            series2.columns.template.adapter.add("fill", function (fill, target) {
                return chartoverdue.colors.getIndex(target.dataItem.index);
            });

            // Add cursor
            chartoverdue.cursor = new am4charts.RadarCursor();
        }
    }, [overdueWidget])

    //Criticality Widget useEffect 

    useEffect(() => {
        if (criticalityWidget) {
            am4core.useTheme(am4themes_animated);
            var criticalitychart = am4core.create("criticality", am4charts.XYChart);
            criticalitychart.hiddenState.properties.opacity = 0; // this creates initial fade-in         
            criticalitychart.paddingBottom = 20;
            criticalitychart.data = [{
                "name": "(A) High",
                "steps": criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[0].y,
                "href": "High.png",
                color: '#9e1b32'
            }, {
                "name": "(B) Medium",
                "steps": criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[1].y,
                "href": "Medium.png",
                color: '#E32636'
            }, {
                "name": "(C) Low",
                "steps": criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 && criticalityWidget.widget_data[2].y,
                "href": "Low.png",
                color: '#E52B50'
            }];
            var criticalitycategoryAxis = criticalitychart.xAxes.push(new am4charts.CategoryAxis());
            criticalitycategoryAxis.dataFields.category = "name";
            criticalitycategoryAxis.renderer.grid.template.strokeOpacity = 0;
            criticalitycategoryAxis.renderer.minGridDistance = 5;
            criticalitycategoryAxis.renderer.labels.template.dy = 30;
            criticalitycategoryAxis.renderer.tooltip.dy = 30; var criticalityvalueAxis = criticalitychart.yAxes.push(new am4charts.ValueAxis());
            criticalityvalueAxis.renderer.inside = true;
            criticalityvalueAxis.renderer.labels.template.fillOpacity = 0.5;
            criticalityvalueAxis.renderer.grid.template.strokeOpacity = 0;
            criticalityvalueAxis.min = 0;
            criticalityvalueAxis.cursorTooltipEnabled = false;
            criticalityvalueAxis.renderer.baseGrid.strokeOpacity = 0; var scriticalityseries = criticalitychart.series.push(new am4charts.ColumnSeries);
            scriticalityseries.dataFields.valueY = "steps";
            scriticalityseries.dataFields.categoryX = "name";
            scriticalityseries.tooltipText = "{valueY.value}";
            scriticalityseries.tooltip.pointerOrientation = "vertical";
            scriticalityseries.tooltip.dy = - 6;
            scriticalityseries.columnsContainer.zIndex = 100;
            scriticalityseries.columns.template.propertyFields.fill = "color"; var columnTemplate = scriticalityseries.columns.template;
            columnTemplate.width = am4core.percent(50);
            columnTemplate.maxWidth = 55;
            columnTemplate.column.cornerRadius(60, 60, 10, 10);
            columnTemplate.strokeOpacity = 0; scriticalityseries.heatRules.push({ target: columnTemplate, property: "fill", dataField: "valueY", min: am4core.color("#e5dc36"), max: am4core.color("#5faa46") });
            scriticalityseries.mainContainer.mask = undefined; var cursor = new am4charts.XYCursor();
            criticalitychart.cursor = cursor;
            // criticalitychart.lineX.disabled = true;
            // criticalitychart.lineY.disabled = true;
            criticalitychart.behavior = "none";
            var bullet = columnTemplate.createChild(am4charts.CircleBullet);
            bullet.circle.radius = 30;
            bullet.valign = "bottom";
            bullet.align = "center";
            bullet.isMeasured = true;
            bullet.mouseEnabled = false;
            bullet.verticalCenter = "bottom";
            bullet.interactionsEnabled = false;
            var hoverState = bullet.states.create("hover");
            var outlineCircle = bullet.createChild(am4core.Circle);
            outlineCircle.adapter.add("radius", function (radius, target) {
                var circleBullet = target.parent;
                return circleBullet.circle.pixelRadius + 10;
            })
            var image = bullet.createChild(am4core.Image);
            image.width = 60;
            image.height = 60;
            image.horizontalCenter = "middle";
            image.verticalCenter = "middle";
            image.propertyFields.href = "href"; image.adapter.add("mask", function (mask, target) {
                var circleBullet = target.parent;
                return circleBullet.circle;
            })
            var previousBullet;
            criticalitychart.cursor.events.on("cursorpositionchanged", function (event) {
                var dataItem = scriticalityseries.tooltipDataItem; if (dataItem.column) {
                    var bullet = dataItem.column.children.getIndex(1); if (previousBullet && previousBullet != bullet) {
                        previousBullet.isHover = false;
                    } if (previousBullet != bullet) {
                        var hs = bullet.states.getKey("hover");
                        hs.properties.dy = -bullet.parent.pixelHeight + 30;
                        bullet.isHover = true; previousBullet = bullet;
                    }
                }
            })
        }

    }, [criticalityWidget])

    //Task Distribution Widget

    useEffect(() => {
        if (taskDistributionWidget) {
            am4core.useTheme(am4themes_animated);
            var taskchart = am4core.create("taskchartdiv", am4charts.XYChart);
            let totalTask = taskDistributionWidget && taskDistributionWidget.Tot_count;
            let totalNumber;
            if(totalTask){
            let totalValue = totalTask.split(':')
             totalNumber = Number(totalValue[1])
            }
            console.log(totalNumber, 'totalNumber');
            taskchart.responsive.enabled = 'true'
            taskchart.data = [{
                "country": "Assigned",
                "visits": taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[0].y,
                "percent": (Math.round(taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[0].y) / totalNumber * 100).toFixed(2),
                color: taskchart.colors.getIndex(3),
            }, {
                "country": "Not Opted",
                "visits": taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[2].y,
                "percent": (Math.round(taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[2].y) / totalNumber * 100).toFixed(2),
                color: taskchart.colors.getIndex(5),

            }, {
                "country": "Unassigned",
                "visits": taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[1].y,
                "percent": (Math.round(taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[1].y) / totalNumber * 100).toFixed(2),
                color: taskchart.colors.getIndex(9),

            }, {
                "country": "Not Applicable",
                "visits": taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[3].y,
                "percent": (Math.round(taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 && taskDistributionWidget.widget_data[3].y) / totalNumber * 100).toFixed(2),
                color: taskchart.colors.getIndex(11),
            }
            ];
            taskchart.padding(30, 30, 30, 30);
            taskchart.colors.list = [am4core.color("#004D47"), am4core.color("#128277"), am4core.color("#52958B")];
            var categoryAxis = taskchart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.dataFields.category = "country";
            categoryAxis.renderer.minGridDistance = 60;
            categoryAxis.renderer.inversed = true;
            categoryAxis.renderer.grid.template.disabled = true;
            var valueAxis = taskchart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.extraMax = 0.1;
            categoryAxis.title.text = `[bold]${totalTask}[/]`;
            //valueAxis.rangeChangeEasing = am4core.ease.linear;
            //valueAxis.rangeChangeDuration = 1500;
            var taskseries = taskchart.series.push(new am4charts.ColumnSeries());
            taskseries.dataFields.categoryX = "country";
            taskseries.dataFields.valueY = "visits";
            // taskseries.tooltipText = "{valueY.value}"
            // taskseries.tooltipText = `[bold]{visits}[/]`;
            taskseries.columns.template.strokeOpacity = 0;
            taskseries.columns.template.column.cornerRadiusTopRight = 10;
            taskseries.columns.template.column.cornerRadiusTopLeft = 10;
            taskseries.columns.template.propertyFields.fill = 'color';
            // taskseries.columns.template.tooltipText = "Hiiiii"
            taskseries.columns.template.tooltipText = `{country}: {visits} out of ${totalTask} ({percent}%)`;

            //series.interpolationDuration = 1500;
            //series.interpolationEasing = am4core.ease.linear;
            var labelBullet = taskseries.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.verticalCenter = "bottom";
            labelBullet.label.dy = -10;
            labelBullet.label.text = "{values.valueY.workingValue.formatNumber('#.')}";
            taskchart.zoomOutButton.disabled = true;
            // as by default columns of the same series are of the same color, we add adapter which takes colors from chart.colors color set
            taskseries.columns.template.adapter.add("fill", function (fill, target) {
                return taskchart.colors.getIndex(target.dataItem.index);
            });
            // setInterval(function () {
            //     am4core.array.each(taskchart.data, function (item) {
            //         item.visits += Math.round(Math.random() * 200 - 100);
            //         item.visits = Math.abs(item.visits);
            //     })
            //     taskchart.invalidateRawData();
            // }, 2000)
            // categoryAxis.sortBySeries = taskseries;
        }

    }, [taskDistributionWidget])

    //Compliance Trend Widget

    useEffect(() => {
        if (complianceTrendWidget) {
            am4core.useTheme(am4themes_animated);
            var Compliancechart = am4core.create("Compliancechartdiv", am4charts.XYChart);
            Compliancechart.responsive.enabled = 'true';
            Compliancechart.data = [{
                "year": "2018",
                "Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[0].y).toFixed(2),
                "Delayed Compliance": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[0].y).toFixed(2),
                "Not Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[0].y).toFixed(2),
                "comData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[0].data,
                "comTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[0].total,
                "delData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[0].data,
                "delTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[0].total,
                "notData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[0].data,
                "notTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[0].total
            }, {
                "year": "2019",
                "Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[1].y).toFixed(2),
                "Delayed Compliance": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[1].y).toFixed(2),
                "Not Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[1].y).toFixed(2),
                "comData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[1].data,
                "comTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[1].total,
                "delData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[1].data,
                "delTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[1].total,
                "notData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[1].data,
                "notTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[1].total
            }, {
                "year": "2020",
                "Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[2].y).toFixed(2),
                "Delayed Compliance": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[2].y).toFixed(2),
                "Not Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[2].y).toFixed(2),
                "comData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[2].data,
                "comTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[2].total,
                "delData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[2].data,
                "delTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[2].total,
                "notData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[2].data,
                "notTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[2].total
            }, {
                "year": "2021",
                "Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[3].y).toFixed(2),
                "Delayed Compliance": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[3].y).toFixed(2),
                "Not Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[3].y).toFixed(2),
                "comData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[3].data,
                "comTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[3].total,
                "delData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[3].data,
                "delTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[3].total,
                "notData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[3].data,
                "notTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[3].total
            }, {
                "year": "2022",
                "Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[4].y).toFixed(2),
                "Delayed Compliance": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[4].y).toFixed(2),
                "Not Complied": Number(complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[4].y).toFixed(2),
                "comData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[4].data,
                "comTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[0].data && complianceTrendWidget.widget_data[0].data[4].total,
                "delData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[4].data,
                "delTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[1].data && complianceTrendWidget.widget_data[1].data[4].total,
                "notData": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[4].data,
                "notTotal": complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data[2].data && complianceTrendWidget.widget_data[2].data[4].total
            }];
            Compliancechart.colors.list = [am4core.color("#00FF7F"), am4core.color("#6A5ACD"), am4core.color("#FF0000")];
            var ComplianceAxis = Compliancechart.xAxes.push(new am4charts.CategoryAxis());
            ComplianceAxis.dataFields.category = "year";
            ComplianceAxis.renderer.opposite = true;

            var CompliancevalueAxis = Compliancechart.yAxes.push(new am4charts.ValueAxis());
            CompliancevalueAxis.title.text = "Total Value";
            CompliancevalueAxis.renderer.minLabelPosition = 0.01;

            var complianceseries1 = Compliancechart.series.push(new am4charts.LineSeries());
            complianceseries1.dataFields.valueY = "Complied";
            complianceseries1.dataFields.categoryX = "year";
            complianceseries1.name = "Complied";
            complianceseries1.bullets.push(new am4charts.CircleBullet());
            complianceseries1.tooltipText = "{name}: {valueY}% ([bold]{comData}[/] out of [bold]{comTotal}[/])";
            complianceseries1.legendSettings.valueText = "{valueY}";
            complianceseries1.visible = false;

            var complianceseries2 = Compliancechart.series.push(new am4charts.LineSeries());
            complianceseries2.dataFields.valueY = "Delayed Compliance";
            complianceseries2.dataFields.categoryX = "year";
            complianceseries2.name = 'Delayed Compliance';
            complianceseries2.bullets.push(new am4charts.CircleBullet());
            complianceseries2.tooltipText = "{name}: {valueY}% ([bold]{delData}[/] out of [bold]{delTotal}[/])";
            complianceseries2.legendSettings.valueText = "{valueY}";

            var complianceseries3 = Compliancechart.series.push(new am4charts.LineSeries());
            complianceseries3.dataFields.valueY = "Not Complied";
            complianceseries3.dataFields.categoryX = "year";
            complianceseries3.name = 'Not Complied';
            complianceseries3.bullets.push(new am4charts.CircleBullet());
            complianceseries3.tooltipText = "{name}: {valueY}% ([bold]{notData}[/] out of [bold]{notTotal}[/])";
            complianceseries3.legendSettings.valueText = "{valueY}";

            Compliancechart.cursor = new am4charts.XYCursor();
            Compliancechart.cursor.behavior = "zoomY";

            let hs1 = complianceseries1.segments.template.states.create("hover")
            hs1.properties.strokeWidth = 5;
            complianceseries1.segments.template.strokeWidth = 1;

            let hs2 = complianceseries2.segments.template.states.create("hover")
            hs2.properties.strokeWidth = 5;
            complianceseries2.segments.template.strokeWidth = 1;

            let hs3 = complianceseries3.segments.template.states.create("hover")
            hs3.properties.strokeWidth = 5;
            complianceseries3.segments.template.strokeWidth = 1;

            Compliancechart.legend = new am4charts.Legend();
            Compliancechart.legend.itemContainers.template.events.on("over", function (event) {
                var segments = event.target.dataItem.dataContext.segments;
                segments.each(function (segment) {
                    segment.isHover = true;
                })
            })
            Compliancechart.legend.itemContainers.template.events.on("out", function (event) {
                var segments = event.target.dataItem.dataContext.segments;
                segments.each(function (segment) {
                    segment.isHover = false;
                })
            })



        }

    }, [complianceTrendWidget])

    //Risk Widget

    useEffect(() => {
        if (riskWidget) {
            am4core.useTheme(am4themes_animated);

            //Hidden Risk

            var chart1 = am4core.create("Riskchartdiv", am4charts.PieChart3D);
            let total = (riskWidget && riskWidget.widget_data && riskWidget.widget_data[3].y) + (riskWidget && riskWidget.widget_data && riskWidget.widget_data[2].y) + (riskWidget && riskWidget.widget_data && riskWidget.widget_data[4].y);
            // Add data
            chart1.data = [{
                "country": "Not Opted",
                "litres": riskWidget && riskWidget.widget_data && riskWidget.widget_data[3].y,
                color: am4core.color('#DE3D1F')
            }, {
                "country": "Unassigned",
                "litres": riskWidget && riskWidget.widget_data && riskWidget.widget_data[2].y,
                color: am4core.color('#F79B80')
            }, {
                "country": "Not Applicable",
                "litres": riskWidget && riskWidget.widget_data && riskWidget.widget_data[4].y,
                color: am4core.color('#808080')
            }];
            // Set inner radius
            chart1.innerRadius = am4core.percent(35);
            // chart.height=200
            // chart.legend = new am4charts.Legend();
            // chart.current = x;
            // x.legend = new am4charts.Legend();

            // Add and configure Series
            var pieSeries = chart1.series.push(new am4charts.PieSeries3D());
            pieSeries.dataFields.value = "litres";
            pieSeries.dataFields.category = "country";
            pieSeries.slices.template.propertyFields.fill = "color";
            chart1.current = chart1;
            chart1.legend = new am4charts.Legend();
            pieSeries.slices.template.tooltipText = `{category}: [bold]{value}[/] out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;

            // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;

            chart1.numberFormatter.numberFormat = "#.";

            chart1.legend = new am4charts.Legend();
            chart1.legend.position = "bottom";
            chart1.legend.useDefaultMarker = true;
            chart1.legend.valueLabels.template.disabled = true;
            var marker = chart1.legend.markers.template.children.getIndex(0);
            marker.cornerRadius(12, 12, 12, 12);
            marker.strokeWidth = 2;
            marker.strokeOpacity = 1;
            marker.stroke = am4core.color("#ccc");
            // var label = chart.createChild(am4core.Label);
            // label.text = "Country-Domain";
            // label.fontSize = 14;
            // label.paddingTop = '10%';
            // label.align = "left";
            // label.paddingLeft = '10%';
            // label.marginBottom = '2%'
            // label.fontWeight = 'bold'

            // pieSeries.slices.template.stroke = am4core.color("#fff");
            // pieSeries.slices.template.strokeWidth = 2;
            // pieSeries.slices.template.strokeOpacity = 1;

            // This creates initial animation
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;

            //Exposed Risk

            var chart = am4core.create("ExposedRiskchartdiv", am4charts.PieChart3D);
            let total1 = (riskWidget && riskWidget.widget_data && riskWidget.widget_data[0].y) + (riskWidget && riskWidget.widget_data && riskWidget.widget_data[1].y);
            // Add data
            chart.data = [{
                "country": "Delayed Compliance",
                "litres": riskWidget && riskWidget.widget_data && riskWidget.widget_data[0].y,
                "color": am4core.color("#3c78d8")
            }, {
                "country": "Not Complied",
                "litres": riskWidget && riskWidget.widget_data && riskWidget.widget_data[1].y,
                "color": am4core.color("#fe4365")
            },
            ];
            // Set inner radius
            chart.innerRadius = am4core.percent(35);

            // Add and configure Series
            var pieSeries = chart.series.push(new am4charts.PieSeries3D());
            pieSeries.dataFields.value = "litres";
            pieSeries.dataFields.category = "country";
            pieSeries.slices.template.propertyFields.fill = "color";
            chart.current = chart;
            chart.legend = new am4charts.Legend();
            // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;
            // pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total}`;

            // pieSeries.slices.template.stroke = am4core.color("#fff");
            // pieSeries.slices.template.strokeWidth = 2;
            // pieSeries.slices.template.strokeOpacity = 1;

            chart.legend = new am4charts.Legend();
            chart.legend.useDefaultMarker = true;
            chart.legend.valueLabels.template.disabled = true;
            var marker = chart.legend.markers.template.children.getIndex(0);
            marker.cornerRadius(12, 12, 12, 12);
            marker.strokeWidth = 2;
            marker.strokeOpacity = 1;
            marker.stroke = am4core.color("#ccc");

            // pieSeries.slices.template.stroke = am4core.color("#fff");
            // pieSeries.slices.template.strokeWidth = 2;
            // pieSeries.slices.template.strokeOpacity = 1;

            // This creates initial animation
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;
        }

    }, [riskWidget])


    useEffect(() => {
        am4core.useTheme(am4themes_animated);
        //Compliance Status        
        // var compliancestatus = am4core.create("compliancestatusdiv", am4charts.XYChart);
        // compliancestatus.height = 510
        // // compliancestatus.width = '300'
        // // compliancestatus.width = '450'
        // compliancestatus.responsive.enabled = 'true'
        // compliancestatus.data = [
        //     {
        //         "year": "India",
        //         "Complied": 25,
        //         "Delayed_Compliance": 35,
        //         "In_Progress": 15,
        //         "Not_Complied": 25
        //     }]
        // compliancestatus.colors.list = [am4core.color("#0de580"), am4core.color("#3c78d8"), am4core.color("#f9c80e"), am4core.color("#fe4365"),];
        // var compliancestatuscategoryAxis = compliancestatus.yAxes.push(new am4charts.CategoryAxis());
        // compliancestatuscategoryAxis.dataFields.category = "year";
        // compliancestatuscategoryAxis.numberFormatter.numberFormat = "#";
        // compliancestatuscategoryAxis.renderer.inversed = true;
        // var compliancestatusvalueAxis = compliancestatus.xAxes.push(new am4charts.ValueAxis());
        // compliancestatus.legend = new am4charts.Legend();
        // // compliancestatus.legend.position = "top";
        // var legend = compliancestatus.children.push(new am4charts.Legend());
        // function makeSeries(name, fieldName, color) {
        //     var compliancestatusseries = compliancestatus.series.push(new am4charts.ColumnSeries3D());
        //     compliancestatusseries.dataFields.valueX = name;
        //     compliancestatusseries.dataFields.categoryY = "year";
        //     compliancestatusseries.dataFields.color = color;
        //     compliancestatusseries.name = fieldName;
        //     compliancestatusseries.stacked = true;
        //     compliancestatusseries.columns.template.propertyFields.fill = "color";
        //     compliancestatusseries.columns.template.tooltipText = "{name}, {valueX}";
        //     compliancestatusseries.columns.template.column3D.stroke = am4core.color("#fff");
        //     compliancestatusseries.columns.template.column3D.strokeOpacity = 0.2;
        //     compliancestatusseries.columns.template.column3D.height = '450'
        //     compliancestatusseries.columns.template.maxHeight = 40
        //     legend.data.push(compliancestatusseries);
        // }
        // makeSeries("Complied", "Complied", "#00FF00");
        // makeSeries("Delayed_Compliance", "Delayed Compliance", "#0000FF");
        // makeSeries("In_Progress", "In Progress", "#FFFF00");
        // makeSeries("Not_Complied", "Not Complied", "#FF0000");

        //Compliance Status Chart New
        // if (chartType == 'count') {
        am4core.useTheme(am4themes_animated);
        // console.log(dataValue && dataValue.length && dataValue[0].complied_count, 'dataValue && dataValue');
        //Compliance Status        
        var compliancestatus = am4core.create("compliancestatusdiv", am4charts.XYChart);
        compliancestatus.height = 510
        // compliancestatus.width = '300'
        // compliancestatus.width = '450'
        compliancestatus.responsive.enabled = 'true'
        compliancestatus.data = [{ "year": "India", "Complied": 25, "Delayed_Compliance": 35, "In_Progress": 15, "Not_Complied": 25 }, { "year": "Srilanka", "Complied": 10, "Delayed_Compliance": 15, "In_Progress": 36, "Not_Complied": 12 }, { "year": "Australia", "Complied": 32, "Delayed_Compliance": 51, "In_Progress": 16, "Not_Complied": 56 }]
        compliancestatus.colors.list = [am4core.color("#0de580"), am4core.color("#3c78d8"), am4core.color("#f9c80e"), am4core.color("#fe4365"),];
        var compliancestatuscategoryAxis = compliancestatus.yAxes.push(new am4charts.CategoryAxis());
        compliancestatuscategoryAxis.dataFields.category = "year";
        compliancestatuscategoryAxis.numberFormatter.numberFormat = "#";
        compliancestatuscategoryAxis.renderer.inversed = true;
        var compliancestatusvalueAxis = compliancestatus.xAxes.push(new am4charts.ValueAxis());
        compliancestatus.legend = new am4charts.Legend();
        // compliancestatus.legend.position = "top";
        var legend = compliancestatus.children.push(new am4charts.Legend());
        function makeSeries(name, fieldName, color) {
            var compliancestatusseries = compliancestatus.series.push(new am4charts.ColumnSeries3D());
            compliancestatusseries.dataFields.valueX = name;
            compliancestatusseries.dataFields.categoryY = "year";
            compliancestatusseries.dataFields.color = color;
            compliancestatusseries.name = fieldName;
            compliancestatusseries.stacked = true;
            compliancestatusseries.columns.template.propertyFields.fill = "color";
            compliancestatusseries.columns.template.tooltipText = "{name}: {valueX}";
            compliancestatusseries.columns.template.column3D.stroke = am4core.color("#fff");
            compliancestatusseries.columns.template.column3D.strokeOpacity = 0.2;
            compliancestatusseries.columns.template.column3D.height = '450'
            compliancestatusseries.columns.template.maxHeight = 40
            legend.data.push(compliancestatusseries);
        }
        makeSeries("Complied", "Complied", "#00FF00");
        makeSeries("Delayed_Compliance", "Delayed Compliance", "#0000FF");
        makeSeries("In_Progress", "In Progress", "#FFFF00");
        makeSeries("Not_Complied", "Not Complied", "#FF0000");


        // }


        //Comprative trend
        /*  var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart3D);
          Comprativechart.data = [{
              "country": "Jan",
              "year2022": 88,
              "year2021": 95
          }, {
              "country": "Feb",
              "year2022": 70,
              "year2021": 80
          }, {
              "country": "Mar",
              "year2022": 70,
              "year2021": 65
          }, {
              "country": "Apr",
              "year2022": 40,
              "year2021": 55
          }, {
              "country": "May",
              "year2022": 82,
              "year2021": 90
          }, {
              "country": "June",
              "year2022": 71,
              "year2021": 75
          }, {
              "country": "July",
              "year2022": 47,
              "year2021": 32
          }, {
              "country": "Aug",
              "year2022": 65,
              "year2021": 80
          }, {
              "country": "Sep",
              "year2022": 45,
              "year2021": 25
          },
          {
              "country": "Oct",
              "year2022": 74,
              "year2021": 62
          },
          {
              "country": "Nov",
              "year2022": 80,
              "year2021": 90
          },
          {
              "country": "Dec",
              "year2022": 81,
              "year2021": 85
          }];
          Comprativechart.colors.list = [am4core.color("#0de580"), am4core.color("#3c78d8")];
          Comprativechart.legend = new am4charts.Legend();
          var ComprativeAxis = Comprativechart.xAxes.push(new am4charts.CategoryAxis());
          ComprativeAxis.dataFields.category = "country";
          ComprativeAxis.renderer.grid.template.location = 0;
          ComprativeAxis.renderer.minGridDistance = 30;
  
          var ComprativevalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
          valueAxis.title.text = "Compliance %";
          valueAxis.renderer.labels.template.adapter.add("text", function (text) {
              return text;
          });
  
          var Comprativeseries = Comprativechart.series.push(new am4charts.ColumnSeries3D());
          Comprativeseries.dataFields.valueY = "year2022";
          Comprativeseries.dataFields.categoryX = "country";
          Comprativeseries.name = "Year 2022";
          Comprativeseries.clustered = false;
          Comprativeseries.columns.template.tooltipText = "India{category} (2022): [bold]{valueY}[/]";
          Comprativeseries.columns.template.fillOpacity = 0.9;
  
          var Comprativeseries2 = Comprativechart.series.push(new am4charts.ColumnSeries3D());
          Comprativeseries2.dataFields.valueY = "year2021";
          Comprativeseries2.dataFields.categoryX = "country";
          Comprativeseries2.name = "Year 2021";
          Comprativeseries2.clustered = false;
          Comprativeseries2.columns.template.tooltipText = "India{category} (2021): [bold]{valueY}[/]";*/

        var Comprativechart = am4core.create("Comprativechartdiv", am4charts.XYChart);
        // Comprativechart.height = '350'
        // Comprativechart.width = '450'
        Comprativechart.responsive.enabled = true;

        // Add data
        Comprativechart.data = [{
            "year": "2018",
            "Complied": 1587,
            "Delayed Compliance": 650,
            "Not Complied": 121
        }, {
            "year": "2019",
            "Complied": 1567,
            "Delayed Compliance": 683,
            "Not Complied": 146
        }, {
            "year": "2020",
            "Complied": 1617,
            "Delayed Compliance": 691,
            "Not Complied": 138
        }, {
            "year": "2021",
            "Complied": 1630,
            "Delayed Compliance": 642,
            "Not Complied": 127
        }, {
            "year": "2022",
            "Complied": 1660,
            "Delayed Compliance": 699,
            "Not Complied": 105
        }, {
            "year": "2023",
            "Complied": 1683,
            "Delayed Compliance": 721,
            "Not Complied": 109
        }];

        Comprativechart.dateFormatter.inputDateFormat = "yyyy";
        var dateAxis = Comprativechart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 60;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.5;
        dateAxis.baseInterval = {
            timeUnit: "year",
            count: 1
        }

        var comvalueAxis = Comprativechart.yAxes.push(new am4charts.ValueAxis());
        comvalueAxis.tooltip.disabled = true;

        var comseries = Comprativechart.series.push(new am4charts.LineSeries());
        comseries.dataFields.dateX = "year";
        comseries.name = "Complied";
        comseries.dataFields.valueY = "Complied";
        comseries.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>Complied: {valueY.value}</b></span>";
        comseries.tooltipText = "[#000]{valueY.value}[/]";
        comseries.tooltip.background.fill = am4core.color("#FFF");
        comseries.tooltip.getStrokeFromObject = true;
        comseries.tooltip.background.strokeWidth = 3;
        comseries.tooltip.getFillFromObject = false;
        comseries.fillOpacity = 0.6;
        comseries.strokeWidth = 2;
        comseries.stacked = false;

        var comseries2 = Comprativechart.series.push(new am4charts.LineSeries());
        comseries2.name = "Delayed Compliance";
        comseries2.dataFields.dateX = "year";
        comseries2.dataFields.valueY = "Delayed Compliance";
        comseries2.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>Delayed Compliance: {valueY.value}</b></span>";
        comseries2.tooltipText = "[#000]{valueY.value}[/]";
        comseries2.tooltip.background.fill = am4core.color("#FFF");
        comseries2.tooltip.getFillFromObject = false;
        comseries2.tooltip.getStrokeFromObject = true;
        comseries2.tooltip.background.strokeWidth = 3;
        comseries2.sequencedInterpolation = true;
        comseries2.fillOpacity = 0.6;
        comseries2.stacked = false;
        comseries2.strokeWidth = 2;

        var comseries3 = Comprativechart.series.push(new am4charts.LineSeries());
        comseries3.name = "Not Complied";
        comseries3.dataFields.dateX = "year";
        comseries3.dataFields.valueY = "Not Complied";
        comseries3.tooltipHTML = "<span style='font-size:14px; color:#000000;'><b>Not Complied: {valueY.value}</b></span>";
        comseries3.tooltipText = "[#000]{valueY.value}[/]";
        comseries3.tooltip.background.fill = am4core.color("#FFF");
        comseries3.tooltip.getFillFromObject = false;
        comseries3.tooltip.getStrokeFromObject = true;
        comseries3.tooltip.background.strokeWidth = 3;
        comseries3.sequencedInterpolation = true;
        comseries3.fillOpacity = 0.6;
        comseries3.defaultState.transitionDuration = 1000;
        comseries3.stacked = false;
        comseries3.strokeWidth = 2;

        Comprativechart.cursor = new am4charts.XYCursor();
        Comprativechart.cursor.xAxis = dateAxis;
        Comprativechart.scrollbarX = new am4core.Scrollbar();

        Comprativechart.legend = new am4charts.Legend();
        // Comprativechart.legend.position = "top";

        // axis ranges
        var range = dateAxis.axisRanges.create();
        range.date = new Date(2001, 0, 1);
        range.endDate = new Date(2003, 0, 1);
        range.axisFill.fill = Comprativechart.colors.getIndex(7);
        range.axisFill.fillOpacity = 0.2;

        range.label.text = "";
        range.label.inside = true;
        range.label.rotation = 90;
        range.label.horizontalCenter = "right";
        range.label.verticalCenter = "bottom";

        var range2 = dateAxis.axisRanges.create();
        range2.date = new Date(2007, 0, 1);
        range2.grid.stroke = Comprativechart.colors.getIndex(7);
        range2.grid.strokeOpacity = 0.6;
        range2.grid.strokeDasharray = "5,2";


        range2.label.text = "Motorcycle fee introduced";
        range2.label.inside = true;
        range2.label.rotation = 90;
        range2.label.horizontalCenter = "right";
        range2.label.verticalCenter = "bottom";

        //Compliance trend



    }, [complianceStatusWidget])

    useEffect(() => {
        if (callername && callername.login_response && callername.login_response.usr_cat_id === 1 || callername && callername.login_response && callername.login_response.usr_cat_id === 3 || callername && callername.login_response && callername.login_response.usr_cat_id === 4) {
            if (callername && callername.login_response && callername.login_response.menu && callername.login_response.menu.Transaction && callername.login_response.menu.Transaction.length > 0) {
                let complianceApproval = _.find(callername.login_response.menu.Transaction, { form_name: 'Compliance Approval' })
                console.log(complianceApproval, 'complianceApproval');
                setComplianceApprovalUrl(complianceApproval && complianceApproval.form_url)
            }
        }
    }, [])

    console.log(overdueWidget, 'overdueWidget');


    return (
        <div className="page-wrapper">
            <div className="pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                        </nav>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className='row'>
                        <div className='col-md-12'>
                            {callername && callername.login_response && callername.login_response.usr_cat_id != 5 ?
                                <div className='row'>
                                    {dayStat === false ?
                                        <>
                                            <div className='col px-1 ms-2'>
                                                <div className="card bg-success card-hover text-white card-flip h-80">
                                                    <div className="card-body p-2 card-front">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">40%</h3>
                                                                <span className="text-white h5"><b>Complied</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-2 card-back">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">235</h3>
                                                                <p style={{ lineHeight: '14px' }} className="text-white h6"><b>Total Records</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col px-1'>
                                                <div className="card bg-info card-hover text-white card-flip h-80">
                                                    <div className="card-body p-2 card-front">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">55%</h3>
                                                                <span className="text-white h5"><b style={{ fontSize: '0.9rem !important' }}>Delayed Compliance</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-2 card-back">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">156</h3>
                                                                <p style={{ lineHeight: '14px' }} className="text-white h6"><b>Total Records</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col px-1'>
                                                <div className="card bg-warning card-hover text-white card-flip h-80">
                                                    <div className="card-body p-2 card-front">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">23%</h3>
                                                                <span className="text-white h5"><b>In Progress</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-2 card-back">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">80</h3>
                                                                <p style={{ lineHeight: '14px' }} className="text-white h6"><b>Total Records</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col px-1'>
                                                <div className="card bg-danger card-hover text-white card-flip h-80">
                                                    <div className="card-body p-2 card-front">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">72%</h3>
                                                                <span className="text-white h5"><b>Not Complied</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body p-2 card-back">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">357</h3>
                                                                <p style={{ lineHeight: '14px' }} className="text-white h6"><b>Total Records</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </> :
                                        <>
                                            <div className='col px-1 pe-2'>
                                                <div className="card text-white card-flip h-80" style={{ backgroundColor: '#F79B80', cursor: 'pointer' }} onClick={() => {
                                                    history.push(`${complianceApprovalUrl}`)
                                                }}>
                                                    <div className="card-body p-2">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">125</h3>
                                                                <span className="text-white h5"><b>0 - 30 Days</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col px-1'>
                                                <div className="card text-white card-flip h-80" style={{ backgroundColor: '#F2746B', cursor: 'pointer' }} onClick={() => {
                                                    history.push(`${complianceApprovalUrl}`)
                                                }}>
                                                    <div className="card-body p-2">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">150</h3>
                                                                <span className="text-white h5"><b>31 - 60 Days</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col px-1'>
                                                <div className="card text-white card-flip h-80" style={{ backgroundColor: '#F35D53', cursor: 'pointer' }} onClick={() => {
                                                    history.push(`${complianceApprovalUrl}`)
                                                }}>
                                                    <div className="card-body p-2">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">250</h3>
                                                                <span className="text-white h5"><b></b>61 - 90 Days</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col px-1'>
                                                <div className="card text-white card-flip h-80" style={{ backgroundColor: '#F24D64', cursor: 'pointer' }} onClick={() => {
                                                    history.push(`${complianceApprovalUrl}`)
                                                }}>
                                                    <div className="card-body p-2">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <div className="ms-2 align-self-center">
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7">300</h3>
                                                                <span className="text-white h5"><b>Above 90 Days</b></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {callername && callername.login_response && callername.login_response.usr_cat_id === 1 || callername && callername.login_response && callername.login_response.usr_cat_id === 7 || callername && callername.login_response && callername.login_response.usr_cat_id === 4 || callername && callername.login_response && callername.login_response.usr_cat_id === 3 || callername && callername.login_response && callername.login_response.usr_cat_id === 2  ?
                                        <div className='col px-1 pe-2'>
                                            <div className="card bg-secondary text-white card-flip h-80">
                                                <div className="card-body p-2">
                                                    <div className="d-flex flex-row align-items-center">
                                                        <div className="ms-2 align-self-center">
                                                            {callername && callername.login_response && callername.login_response.usr_cat_id === 7 || callername && callername.login_response && callername.login_response.usr_cat_id === 2 ?
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7"><span style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setDayStat(true)
                                                                }}>75</span></h3>
                                                                :
                                                                <h3 className="mb-0 text-white fw-bold h1 fs-7"><span style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setDayStat(true)
                                                                }}>5</span>/<span style={{ cursor: 'pointer' }} onClick={() => {
                                                                    setDayStat(true)
                                                                }}>70</span></h3>
                                                            }

                                                            <span className="text-white h5"><b>Approval Pending</b></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="card-body p-2 card-back">
                                            <div className="d-flex flex-row align-items-center">
                                                <div className="round rounded-circle text-danger d-inline-block text-center bg-white d-flex justify-content-center align-items-center">
                                                    <i data-feather='minus-circle'></i>
                                                </div>
                                                <div className="ms-2 align-self-center">
                                                    <h3 className="mb-0 text-white fw-bold h1 fs-7">357</h3>
                                                    <p style={{ lineHeight: '14px' }} className="text-white h6"><b>Total Records</b></p>
                                                </div>
                                            </div>
                                        </div> */}
                                            </div>

                                        </div> : ''}

                                </div> :
                                <div className="row">
                                    <div className='col px-1 pe-2'>
                                        <div className="card text-white card-flip h-80" style={{ backgroundColor: 'rgb(149 46 154)', cursor: 'pointer' }}
                                        // onClick={() => {
                                        //     history.push(`${complianceApprovalUrl}`)
                                        // }}
                                        >
                                            <div className="card-body p-2">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ms-2 align-self-center">
                                                        <h3 className="mb-0 text-white fw-bold h1 fs-7">30</h3>
                                                        <span className="text-white h5"><b>Due Today</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className='col px-1 pe-2'>
                                        <div className="card text-white card-flip h-80" style={{ backgroundColor: 'rgb(255 174 44)', cursor: 'pointer' }}
                                        // onClick={() => {
                                        //     history.push(`${complianceApprovalUrl}`)
                                        // }}
                                        >
                                            <div className="card-body p-2">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ms-2 align-self-center">
                                                        <h3 className="mb-0 text-white fw-bold h1 fs-7">20</h3>
                                                        <span className="text-white h5"><b>Inprogress</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className='col px-1 pe-2'>
                                        <div className="card text-white card-flip h-80" style={{ backgroundColor: '#f32d2b', cursor: 'pointer' }}
                                        // onClick={() => {
                                        //     history.push(`${complianceApprovalUrl}`)
                                        // }}
                                        >
                                            <div className="card-body p-2">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ms-2 align-self-center">
                                                        <h3 className="mb-0 text-white fw-bold h1 fs-7">50</h3>
                                                        <span className="text-white h5"><b>Over Due</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className='col px-1 pe-2'>
                                        <div className="card text-white card-flip h-80" style={{ backgroundColor: 'rgb(42 125 153)', cursor: 'pointer' }}
                                        // onClick={() => {
                                        //     history.push(`${complianceApprovalUrl}`)
                                        // }}
                                        >
                                            <div className="card-body p-2">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ms-2 align-self-center">
                                                        <h3 className="mb-0 text-white fw-bold h1 fs-7">60</h3>
                                                        <span className="text-white h5"><b>Upcoming</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div><div className='col px-1 pe-2'>
                                        <div className="card text-white card-flip h-80" style={{ backgroundColor: '#da6d25', cursor: 'pointer' }}
                                        // onClick={() => {
                                        //     history.push(`${complianceApprovalUrl}`)
                                        // }}
                                        >
                                            <div className="card-body p-2">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="ms-2 align-self-center">
                                                        <h3 className="mb-0 text-white fw-bold h1 fs-7">25</h3>
                                                        <span className="text-white h5"><b>Task Recall</b></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="card border-danger">
                                        <div className="card-header">
                                            <h3 className="card-title">Compliance Status</h3>
                                        </div>
                                        <div className="card-body">
                                            <div id="compliancestatusdiv" style={{ height: '330px' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="card border-danger">  {/* c3-area.js */}
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                Comparative Trend
                                            </h3>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className="card-body">
                                                    <div id="Comprativechartdiv" style={{ height: '330px' }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Over Due Widget */}
                                {overdueId ?
                                    <div className='col-lg-6'>
                                        <div className="card border-danger">
                                            <div className='card-header'>
                                                <h3 className="card-title">
                                                    Over Due Chart
                                                </h3>
                                            </div>
                                            {overdueWidget && overdueWidget.widget_data && overdueWidget.widget_data.length > 0 ?
                                                <div className="card-body">
                                                    <div id="chartdiv" style={{ height: '330px' }}></div>
                                                </div> :
                                                <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                                        </div>
                                    </div> : null}
                                {cricId ?
                                    <div className='col-lg-6'>
                                        <div className="card border-danger">
                                            <div className='card-header'>
                                                <h5 className='card-title'>
                                                    Criticality Chart
                                                </h5>
                                            </div>
                                            {criticalityWidget && criticalityWidget.widget_data && criticalityWidget.widget_data.length > 0 ?
                                                <div className='card-body'>
                                                    <div id="criticality" style={{ height: '330px' }}></div>
                                                </div> :
                                                <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                                        </div>
                                    </div> : null}
                                {taskDisId ?
                                    <div className='col-md-6'>
                                        <div className="card-group">
                                            <div className="card border-danger">
                                                <div className="card-header">
                                                    <h3 className="card-title">
                                                        Task Distribution
                                                    </h3>
                                                </div>
                                                {taskDistributionWidget && taskDistributionWidget.widget_data && taskDistributionWidget.widget_data.length > 0 ?
                                                    <div className='card-body'>
                                                        <div id="taskchartdiv" style={{ height: '330px' }}></div>
                                                    </div> :
                                                    <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                                            </div>
                                        </div>
                                    </div> : null}
                                {compTrendId ?
                                    <div className='col-md-6'>
                                        <div className="card border-danger">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    Compliance Trend
                                                </h3>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    {complianceTrendWidget && complianceTrendWidget.widget_data && complianceTrendWidget.widget_data.length > 0 ?
                                                        <div className="card-body">
                                                            <div id="Compliancechartdiv" style={{ height: '330px' }}></div>
                                                        </div> :
                                                        <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}
                                {riskId ?
                                    <div className='col-md-12'>
                                        <div className="card border-danger">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    <span>Risk Chart</span>
                                                    {/* <span style={{ marginLeft: "380px" }}>Risk Chart- Exposed Risk</span> */}
                                                </h3>
                                            </div>
                                            <div className='card-body'>
                                                {/* <div className='row'>
                                                <div className='col-6'>
                                                    <div id='Riskchartdiv' style={{ height: '330px' }}></div>
                                                </div>
                                                <div className='col-6'>
                                                    <div id='ExposedRiskchartdiv' style={{ height: '330px' }}></div>
                                                </div>
                                            </div> */}
                                                <div className="row">

                                                    {riskWidget && riskWidget.widget_data && riskWidget.widget_data.length > 0 ?
                                                        <>
                                                            <div className="col-md-6">
                                                                <b style={{ marginLeft: '42%' }}>Hidden Risk</b>
                                                                <div id="Riskchartdiv" style={{ width: "100%", marginTop: '10px', height: "220px" }}></div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <b style={{ marginLeft: '42%' }}>Exposed Risk</b>
                                                                <div id="ExposedRiskchartdiv" style={{ width: "100%", marginTop: '10px', marginRight: '20%', height: "220px" }}></div>
                                                            </div></> :
                                                        <Spin style={{ marginTop: '10%', marginBottom: '10%' }} />}
                                                </div>

                                            </div>
                                        </div>
                                    </div> : null}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default connect(mapStateToProps, {
    getWidgetData,
    getRiskChart,
    getCountryData,
    getOverDueChart,
    getComplianceStatusChart,
    getTaskDistributionChart,
    getCriticalityChart,
    getComplianceTrend
})(LandingPage);