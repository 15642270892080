import { RECALLFILTERS, TASKRECALLCOMPLIANCE, REMARKSDATA, INVALID_PASSWORD_RESET, UPDATE_TASK_STATUS, INVALID_PASSWORD_STATUS, TASKRECALLCOMPLIANCESHOWMORE, RESET_REMARK_HISTORY, TASKRECALL_HISTORY, TASKRECALL_INTERIM, INVALIDMODALPSSWRD } from '../../types/index'

const initialState = {
    recallfilters: {
        statusrecall: '',
        userunitsrecall: '',
        // recallcompliance: '',
        recallcompliancestatus: '',
        recallremarks: '',
        recallremarksstatus: '',
        updatetaskstatus: '',
        count: '',
        password_reset: '',
        history_details: '',
    passwrdmodal:false,
        interim_details: ''
    },
    recallcompliance: '',
    recallcomplianceshowmore: '',
    recallfinalresult: '',

}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case RECALLFILTERS:
            {
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        statusrecall: payload[0],
                        userunitsrecall: payload[1]
                    },
                };
            }
        case INVALIDMODALPSSWRD : 
        {
            return{
                ...state,
                passwrdmodal:payload
            }
        }
        case TASKRECALLCOMPLIANCE:
            {
                console.log(payload[1].count, "222");
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        recallcompliancestatus: payload[0],
                        // recallcompliance: [...state.recallcompliance,payload[1].recall_compliances],
                        count: payload[1].count,

                    },
                    recallcompliance: payload[1].recall_compliances,//[...state.recallcompliance,payload[1].recall_compliances],
                    //  recallcomplianceshowmore: [...state.recallcompliance,payload[1].recall_compliances]
                };
            }
        case TASKRECALLCOMPLIANCESHOWMORE:
            {
                console.log(payload[1].count, "222");
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        recallcompliancestatus: payload[0],
                        // recallcompliance: [...state.recallcompliance,payload[1].recall_compliances],
                        count: payload[1].count,

                    },
                    //  recallcompliance: payload[1],//[...state.recallcompliance,payload[1].recall_compliances],
                    recallcomplianceshowmore: [...state.recallcomplianceshowmore, payload[1].recall_compliances],
                };
            }
        case REMARKSDATA:
            {
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        recallremarksstatus: payload[0],
                        recallremarks: payload[1]
                    },
                };
            }
        case RESET_REMARK_HISTORY:
            {
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        recallremarks: []
                    },
                };
            }
        case UPDATE_TASK_STATUS:
            {
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        updatetaskstatus: payload[0]
                    },
                };
            }
        case INVALID_PASSWORD_STATUS:
            {
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        password_reset: payload[0]
                    },
                };
            }

        case INVALID_PASSWORD_RESET:
            {
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        password_reset: ''
                    },
                };
            }
        case TASKRECALL_HISTORY: {
            return {
                ...state,
                recallfilters: {
                    ...state.recallfilters,
                    history_details: payload,
                },
            };
        }
        case TASKRECALL_INTERIM: {
            return {
                ...state,
                recallfilters: {
                    ...state.recallfilters,
                    interim_details: payload,
                },
            };
        }
        
        default:
            return state;
    }
}