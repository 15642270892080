const url = {
    Login: 'https://13.234.100.64',
    CommonURL: 'https://13.234.100.64',
    VendorURL: 'https://13.234.100.64/cv',
    encryptionKey: '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'
}
export const getUrl = () => {
    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    //  && sessionValue.login_response.cf_access == 'false' && sessionValue.login_response.ltmg_access == 'false' && sessionValue.login_response.vndr_access == 'true'
    if (localStorage.getItem('sessionValue')) {
        if (localStorage.getItem('isVendor') == "true") {
            return url.VendorURL
        } else {
            if (sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == true) {
                return url.CommonURL
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == true && sessionValue.login_response.vndr_access == false) {
                return url.CommonURL
            } else if (sessionValue.login_response.cf_access == false && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == true) {
                return url.VendorURL
            } else if(sessionValue.login_response.cf_access == true && sessionValue.login_response.ltmg_access == false && sessionValue.login_response.vndr_access == false) {
                return url.CommonURL
            } 
            else {
                return url.CommonURL
            }

        }
    }

}

export default url
