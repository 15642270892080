import { CATEGORY_DATA, DoWNLOAD_CONSOLIDATED_REPORT,HISTORY_REMARKS_DATA, CONSOLIDATED_REPORT, EXPORT_CONSOLIDATED_REPORT, MONTHWISEREPORT, TOGGLE_COMMON_LOADER } from './../../types/index'
const initialState = {
    ConsolidatedReports: {
        Data: [],
        ConsolidatedReportList: [],
        count:0,
        downloadPath: [],
        downloadReport: [],
        total_history_ids: [],
        RemarksData:[]

    }
}
export default function (state = initialState, action) {
   
    const { type, payload } = action;
    switch (type) {
        case CATEGORY_DATA: {
            console.log("HIIIIII");
            console.log(payload, "payload777");
            return {
                ...state,
                ConsolidatedReports: {
                    ...state.ConsolidatedReports,
                    Data: payload,
                },
            };
        }
        case CONSOLIDATED_REPORT: {
            console.log("HIIIIII");
            console.log(payload, "avvvvv");
            return {
                ...state,
                ConsolidatedReports: {
                    ...state.ConsolidatedReports,
                    ConsolidatedReportList: payload.status_report_consolidated_list,
                    count:payload.total_count,
                    total_history_ids: payload.total_history_ids
                },
            };
        }
         
         case HISTORY_REMARKS_DATA: {
            console.log("HIIIIII");
            console.log(payload, "avvvvv");
            return {
                ...state,
                ConsolidatedReports: {
                    ...state.ConsolidatedReports,
                    RemarksData: payload
                },
            };
        }
        case EXPORT_CONSOLIDATED_REPORT: {
            console.log("HIIIIII");
            console.log(payload, "avvvvv");
            return {
                ...state,
                ConsolidatedReports: {
                    ...state.ConsolidatedReports,
                    downloadPath: payload
                },
            };
        }
        case DoWNLOAD_CONSOLIDATED_REPORT: {
            console.log("HIIIIII");
            console.log(payload, "avvvvv");
            return {
                ...state,
                ConsolidatedReports: {
                    ...state.ConsolidatedReports,
                    downloadReport: payload
                },
            };
        }
        default:
            return state;
    }
}
