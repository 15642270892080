import React, { useEffect, useState, useRef, Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import {
  Collapse,
  Modal,
  Select,
  Tooltip,
  Card,
  DatePicker,
  Space,
  Descriptions,
  Button,
  Popover,
  Table,
  Typography,
  Input,
  Popconfirm,
  Drawer,
  Form,
  Checkbox,
} from "antd";
import {
  PlusOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  FilterFilled,
  UpOutlined,
  PlayCircleOutlined,
  EyeOutlined,
  DownOutlined,
  UpCircleOutlined,
  FullscreenOutlined,
  FilterOutlined,
  ExclamationCircleTwoTone,
  InfoOutlined,
  ArrowUpOutlined
} from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import {
  TaskRecallFilters,
  GetTaskRecallList,
  GetRemarksData,
  UpdateTaskRecallStatus,
  GetTaskRecallListShowmore,
  historyrecord, interimrecord
} from "./../../Store/Action/Transcations/Taskrecall";
import { toast } from "react-toastify";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { update } from "lodash";
import { INVALID_PASSWORD_RESET } from "./../../../src/Store/types/index";
import dayjs from "dayjs";
import { Toaster } from "../../Libs/Toaster";
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from "../Scrollbar";
import Stickicon from "./Stickyicon";
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
  filterrecall: state.recallfilters.recallfilters,
  filterdatas: state.Statutorysetting.statutorysettings.filterdata,
  recallcompliance: state.recallfilters.recallcompliance,
  recallcomplianceshowmore: state.recallfilters.recallcomplianceshowmore,
  historydata: state.recallfilters.recallfilters.history_details,
  interimdata: state.recallfilters.recallfilters.interim_details
});

const TaskRecall = ({
  TaskRecallFilters,
  GetTaskRecallList,
  GetRemarksData,
  UpdateTaskRecallStatus,
  filterrecall: {
    userunitsrecall,
    recallremarks,
    recallcompliancestatus,
    count,
    password_reset,
  },
  filterdatas,
  recallcompliance,
  recallcomplianceshowmore,
  GetTaskRecallListShowmore,
  historydata,
  historyrecord, interimrecord,
  interimdata
}) => {

  const [dataTableProperties, setDataTableProperties] = useState({
    fixedHeader: true,
    pagesize: 10,
    sizechanger: true,
    pageSizeOptions: ["10", "30", "50"],
  });
  const [page, setPage] = useState(1);
  const { TextArea } = Input;
  const [scroll, setScroll] = useState(false);
  const [showDropDown, setshowDropDown] = useState(false);
  let [counts, setcounts] = useState(0);
  const callername = JSON.parse(localStorage.getItem('sessionValue'));
  const formValidator2 = useRef(new SimpleReactValidator());
  const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
  const [showValueModal, setShowValueModal] = useState(false);
  const [acccordionTrue, setAccordionTrue] = useState(false);
  const [remarksModal, setRemarksModal] = useState(false);
  const [passwordmodal, Setpasswordmodal] = useState(false);
  const [FormShow, setFormShow] = useState(false);
  const [filterTaskAcc, setFilterTaskAcc] = useState(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [exitCollapse, setExitCollapse] = useState(false);
  const dispatch = useDispatch();
  const [due, setDue] = useState(false);
  const [checkedRow, setCheckedRow] = useState(false);
  const [mapFilter, setMapFilter] = useState([]);
  const [FormRecall, setFormRecall] = useState(false);
  const [filteredArray, setFilteredArray] = useState([]);
  const [legalstate, Setlegalstate] = useState(false);
  const location = useLocation();
  const paramid = location.pathname;
  const authtoken = localStorage.getItem("authToken");
  //  const entityid = localStorage.getItem('SelectedEntityid');
  const isAuth = localStorage.getItem("isAuthenticated");
  const sessionValue = localStorage.getItem("sessionValue");
  const sessionParsedValue = JSON.parse(sessionValue);
  const sessionArr = sessionParsedValue.entity_info;
  const { Option } = Select;
  const [totalRecord, settotalRecord] = useState([]);
  const [fullscreen, setfullscreen] = useState(false);
  const _ = require("lodash");
  const formValidator = useRef(new SimpleReactValidator());
  const recallSubmit = useRef(new SimpleReactValidator());
  const [entires, setEntires] = useState(0);
  const [filterValues, setFilterValues] = useState(
    {
      All: '',
      periodical: '',
      flexi_review: '',
      review: '',
      on_occurrence: '',
      one_time: ''
    }
  )

  const [checked, setChecked] = useState({
    All: false,
    periodical: false,
    flexi_review: false,
    review: false,
    on_occurrence: false,
    one_time: false

  })
  const filterLength = []
  const filterLen = Object.keys(filterValues)
  filterLen.forEach(key => {
    filterValues[key] === true && filterLength.push(
      key
    )
  })

  const [countvalue, setCountvalue] = useState(false);
  const [remarksvalue, Setremarksvalue] = useState();
  const [showdata, Setshowdata] = useState({
    domain: "",
    domainName: '',
    unit: "",
    frequency: 0,
    due_month: "",
    act: "",
    // legal_entity: '',
    legal_entity:
      localStorage.getItem("SelectedEntity") === "All Legal Entity"
        ? ""
        : localStorage.getItem("SelectedEntity"),
  });
  const [temp, setTemp] = useState([]);

  const [titleDynamic, setTitleDynamic] = useState([]);
  const [mergedArray, setMergedArray] = useState([]);
  const [temparr, setTemparr] = useState([]);
  const [finalchild, Setfinalchild] = useState([]);
  const [childlist, Setchildlist] = useState([]);
  const [recalldata, Setrecalldata] = useState({
    action: "",
    remarks: "",
  });

  const [password, Setpassword] = useState({
    passwordvalue: "",
  });
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [filteredArrayState, setfilteredArrayState] = useState([]);
  const [assigneename, setAssigneename] = useState([]);
  const [updatedArray, setUpdatedArray] = useState([]);
  const [updatedArrayshow, setUpdatedArrayShow] = useState([]);
  const [updatedArrayShowmore, setUpdatedArrayShowmore] = useState([]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const [tempFilterArray, setTempFilterArray] = useState([]);
  const [all, setAll] = useState("All");
  const [key, setKey] = useState("1");

  const setcancelFilter = () => {
    setFilterModalVisible(false);
    setFilterTaskAcc(false);
  };

  const [historymodal, sethistorymodal] = useState(false)
  const [interimmodal, setinterimmodal] = useState(false);

  useEffect(() => {
    if (isAuth) {
      Toaster.warning('Development Under Progress');
    }
  }, [isAuth])

  useEffect(() => {
    if (fullscreen == true) {
      document.addEventListener('fullscreenchange', handleKeyDown, false);
      document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
      return () => {
        document.addEventListener('fullscreenchange', handleKeyDown, false);
        document.removeEventListener('fullscreenchange', handleKeyDown, false);
        document.removeEventListener('webkitfullscreenchange', handleKeyDown);
      };
    }
  }, [fullscreen]);

  const fullscreenMode = () => {
    if (fullscreen == false) {
      fullscreenmode();
      setfullscreen(true);
    } else {
      fullscreenexit();
      setfullscreen(false);
    }
  }

  const filterHandle = () => {
    setFilterTaskAcc(true);
    setFilterModalVisible(true);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder="Search"
          autoFocus={'on'}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] != null
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  const [entityid, setCurrentEntity] = useState(localStorage.getItem(""));



  useEffect(() => {
    if (recallcompliance) {
      let arrayTemp = [];
      for (let i in recallcompliance) {
        arrayTemp.push(recallcompliance[i]);
      }
      var merged = [].concat.apply([], arrayTemp);
      setUpdatedArray([...merged]);
      setUpdatedArrayShow([...merged]);
    }
  }, [recallcompliance]);

  useEffect(() => {
    if (recallcomplianceshowmore) {
      let arrayTemp = [];
      for (let i in recallcomplianceshowmore) {
        arrayTemp.push(recallcomplianceshowmore[i]);
      }
      var merged = [].concat.apply([], arrayTemp);
      setUpdatedArrayShowmore([...merged]);
    }
  }, [recallcomplianceshowmore]);

  useEffect(() => {
    if (updatedArrayShowmore.length > 0) {
      setUpdatedArray([]);
      setUpdatedArray([...updatedArrayshow, ...updatedArrayShowmore]);
    }
  }, [updatedArrayShowmore]);

  useEffect(() => {
    if (localStorage.getItem("SelectedEntityid") != "null") {
      setCurrentEntity(
        localStorage.getItem("SelectedEntityid").replaceAll('"', "")
      );
    }
  }, [localStorage.getItem("SelectedEntityid")]);
  useEffect(() => {
    if (showdata.legal_entity == localStorage.getItem("SelectedEntity")) {
      Setlegalstate(true);
    }
  }, [showdata.legal_entity]);
  useEffect(() => {
    if (password_reset == "InvalidPassword") {
      Setpasswordmodal(false);
      setAddFormSubmit2(false);
      Setpassword({
        ...password,
        passwordvalue: "",
      });
      dispatch({
        type: INVALID_PASSWORD_RESET,
      });
    }
  }, [password_reset]);
  useEffect(() => {
    if (temp) {
      let tempARR = [];

      for (let i in temp) {
        tempARR.push(temp[i].child);
      }
      Setchildlist(tempARR);
    }
  }, [temp]);
  useEffect(() => {
    if (finalchild && finalchild.length > 0) {
      setEntires(finalchild.length);
    }
  }, [finalchild]);
  useEffect(() => {
    let tempArr = [];
    for (let k in childlist) {
      tempArr.push(...childlist[k]);
      Setfinalchild(tempArr);
    }
  }, [childlist]);
  const [valueModal, setValueModal] = useState({
    unit: "",
    address: "",
    compliance_history_id: "",
    compliance_task: "",
    compliance_frequency: "",
    uploaded_document: "",
    document_issued_date: "",
    document_reference_number: "",
    validity_date: "",
    next_due_date: "",
    status: "",
    remarks: "",
    action: "",
    compliance_description: "",
    interim_details: "",
    history_details: '',
    is_applicable : '',
    occurrence_remarks: null
  });

  console.log(valueModal,'valueModalvalueModal')

  useEffect(() => {
    if (recallremarks) {
      let temp = [];
      for (let i = 0; i < recallremarks.length; i++) {
        if (recallremarks[i].r_description != "") {
          temp.push(recallremarks[i]);
        }
        Setremarksvalue(temp);
      }
    }
  }, [recallremarks]);

  const column = [
    {
      title: "S.No.",
      dataIndex: false,
      width: "30px",
      align: "center",
      render: (text, record, index) => {
        return (page - 1) * 10 + index + 1;
      },
    },
    {
      title: "Description",
      dataIndex: "r_description",
      key: "r_description",
      width: "200px",
      ellipsis: true,
      render: (text, record) => (
        <a
          onClick={() => {
            Setrecalldata({ ...recalldata, remarks: record.r_description });
            setRemarksModal(false);
          }}
        >
          {record.r_description}
        </a>
      ),
    },
  ];

  const Active = () => {
    if (temparr.length > 0) {
      setFormRecall(true);
    } else {
      toast.error("Please Select Compliance to recall");
    }
    if (recallSubmit.current.allValid()) {
      if (temparr.length !== 0) {
        Swal.fire({
          title: "Are you sure?",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            Setpasswordmodal(true);
          }
        });
      }
    }
  };

  const addAllSelectedDataValues = (checked) => {
    if (mergedArray.length > 0) {
      let tempArray = temparr;
      var ele = document.getElementsByName("checked");
      if (checked === true) {
        setCheckedRow(true);
        for (let i in mergedArray) {
          tempArray.push(mergedArray[i]);
        }
        /*Remove Duplicate*/
        let compliance_id = tempArray.map((item) => {
          return item.compliance_id;
        });
        var difference = tempArray.filter(
          (x) => compliance_id.indexOf(x) === -1
        );
        let finaldata = [...new Set(difference)];
        /*Remove Duplicate*/

        setTemparr([...finaldata]);
        for (var i = 0; i < ele.length; i++) {
          if (ele[i].type === "checkbox") ele[i].checked = true;
        }
      } else {
        setCheckedRow(false);
        tempArray = [];
        setTemparr([...tempArray]);
        for (var j = 0; j < ele.length; j++) {
          if (ele[j].type === "checkbox") ele[j].checked = false;
        }
      }
    } else {
      var eleclass = document.getElementsByClassName("allChecked");
      eleclass[0].checked = false;
      Toaster.error("No Compliance Available");
    }
  };

  const scrollup = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= 213) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };



  const addSelectedDataValues = (checked, compliance_history_id, parentId) => {
    var ele = document.getElementsByName("allchecked");
    var eleclass = document.getElementsByClassName("allChecked");
    let array;
    let array1;
    let array2;
    let tempArray = temparr;
    if (checkedRow === true) {
      if (checked === true) {
        // array = _.find(recallcompliance, { compliance_history_id: compliance_history_id });
        // tempArray.push(array)
        array1 = _.filter(temp, { index: parentId });
        array = _.find(mergedArray, { index: parentId });
        tempArray.push(array);
        array2 = _.filter(tempArray, { index: parentId });
        if (array2.length === array1[0].child.length) {
          eleclass[0].checked = true;
        }
      } else {
        for (let i in tempArray) {
          if (tempArray[i].compliance_history_id === compliance_history_id) {
            tempArray.splice(i, 1);
          }
          if (updatedArray.length !== temparr.length) {
            setCheckedRow(false);
            eleclass[0].checked = false;
          }
        }
      }
      setTemparr([...tempArray]);
    } else {
      if (checked === true) {
        array = _.find(mergedArray, {
          compliance_history_id: compliance_history_id,
        });
        tempArray.push(array);
        if (tempArray.length === mergedArray.length) {
          eleclass[0].checked = true;
        }
      } else {
        for (let i in tempArray) {
          if (tempArray[i].compliance_history_id === compliance_history_id) {
            tempArray.splice(i, 1);
          }
        }
        eleclass[0].checked = false;
      }
      setTemparr([...tempArray]);
    }
  };

  const submitpassword = (e) => {
    setAddFormSubmit2(true);

    e.preventDefault();
    if (formValidator2.current.allValid()) {
      let temp = [];
      for (let i in temparr) {
        temp.push({
          compliance_id: temparr[i].compliance_id,
          compliance_history_id: temparr[i].compliance_history_id,
          unit_idl: temparr[i].unit_id,
          download_doc: temparr[i].download_url,
          reviewer_doc: temparr[i].reviwer_newdoc,
          interm_doc: temparr[i].interm_newdoc,
          interim_docs_size: Number(temparr[i].interim_docs_size),
          reviwer_docs_size: Number(temparr[i].reviwer_docs_size),
          document_size: temparr[i].document_size,
          recall_status: temparr[i].recall_status,
          recall_id: temparr[i].recall_id,
        });
      }
      UpdateTaskRecallStatus({
        payload: [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "UpdateTaskRecallStatus",
              {
                le_id: entityid,
                check_list: temp,
                action: recalldata.action != "" ? recalldata.action : "0",
                remarks: recalldata.remarks,
                password: password.passwordvalue,
                pwd_validate: true,
              },
            ],
          },
        ],
        paramid: paramid,
      });
    }
    //Setpasswordmodal(false);
  };

  const showValue = () => {
    setShowValueModal(true);
  };

  const passwordcancel = () => {
    Setpasswordmodal(false);
    setAddFormSubmit2(false);
    Setpassword({
      ...password,
      passwordvalue: "",
    });
  };

  const { Panel } = Collapse;

  const handleOk = () => {
    setRemarksModal(false);
  };

  const handleCancel = () => {
    setRemarksModal(false);
    setShowValueModal(false);
  };

  const gettaskrecalllist = () => {
    setFilteredArray([]);
    setAssigneename([]);
    setfilteredArrayState([]);
    setTemp([]);
    setUpdatedArray([]);
    setFormShow(true);
    if (formValidator.current.allValid()) {
      setExitCollapse(true);
      setFilterModalVisible(false);
      setFilterTaskAcc(false);
      setAccordionTrue(true);
      setKey("0");
      GetTaskRecallList({
        payload: [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "GetTaskRecallList",
              {
                le_id: entityid,
                unit_id: showdata.unit ? Number(showdata.unit) : null,
                d_id: Number(showdata.domain),
                frequency_id: showdata.frequency
                  ? Number(showdata.frequency)
                  : 0,
                compl_date: showdata.due_month,
                parent_id: Number(showdata.act),
                user_id: null,
                start_count: 0,
                task_status: "Task Recall",
              },
            ],
          },
        ],
        paramid: paramid,
      });
    }
  };

  const gettaskrecalllistshowmore = () => {
    setFormShow(true);
    setAll("All");
    if (formValidator.current.allValid()) {
      setAccordionTrue(true);
      GetTaskRecallListShowmore({
        payload: [
          authtoken,
          {
            session_token: authtoken,
            request: [
              "GetTaskRecallList",
              {
                le_id: entityid,
                unit_id: showdata.unit ? Number(showdata.unit) : null,
                d_id: Number(showdata.domain),
                frequency_id: showdata.frequency
                  ? Number(showdata.frequency)
                  : 0,
                compl_date: showdata.due_month,
                parent_id: Number(showdata.act),
                user_id: null,
                start_count: entires,
                task_status: "Task Recall",
              },
            ],
          },
        ],
        paramid: paramid,
      });
    }
  };



  const showRemarksModal = () => {
    GetRemarksData({
      payload: [
        authtoken,
        {
          session_token: authtoken,
          request: ["GetRemarksData", {}],
        },
      ],
      paramid: paramid,
    });
    setRemarksModal(true);
  };

  useEffect(() => {
    formValidator.current.showMessages();
    recallSubmit.current.showMessages();
    formValidator2.current.showMessages();
  }, []);

  useEffect(() => {
    if (filteredArray.length > 0) {
      {
        filteredArray.map((item) => {
          return setMapFilter(item);
        });
      }
    } else {
      setMapFilter([]);
    }
  }, [filteredArray]);

  useEffect(() => {
    if (updatedArray && updatedArray.length > 0) {
      let uniqueObjArray = [
        ...new Map(
          updatedArray &&
          updatedArray.length > 0 &&
          updatedArray.map((item) => [item["unit_id"], item])
        ).values(),
      ];
      const uniqueIds =
        uniqueObjArray &&
        uniqueObjArray.map((item) => {
          return item.unit_id;
        });
      let tempArr = [];
      for (let i in uniqueIds) {
        let filteredArr = _.filter(
          updatedArray && updatedArray.length > 0 && updatedArray,
          { unit_id: uniqueIds[i] }
        );
        tempArr.push(filteredArr);
      }
      setFilteredArray([tempArr]);
    } else {
      setFilteredArray([]);
    }
  }, [updatedArray]);

  const handlePasting = (e, regex) => {
    var regex = regex;
    var key = e.clipboardData.getData('text')
    if (!regex.test(key)) {
      e.preventDefault();
      Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
      return false;
    }
  }
  useEffect(() => {
    if (filteredArray.length > 0) {
      let temp = [];
      for (let i in filteredArray) {
        temp = filteredArray[i];
      }
      setfilteredArrayState([...temp]);
    }
  }, [filteredArray]);

  useEffect(() => {
    let tempArr = [];
    if (updatedArray && updatedArray.length > 0) {
      filteredArrayState &&
        filteredArrayState.length > 0 &&
        filteredArrayState.map((item, i) => {
          let uniqueObjArray = [
            ...new Map(
              item &&
              item.length > 0 &&
              item.map((items) => [items["assignee_name"], items])
            ).values(),
          ];
          const uniqueIds =
            uniqueObjArray.length > 0 &&
            uniqueObjArray.map((itemData) => {
              return itemData.assignee_name;
            });
          for (let i in uniqueIds) {
            let filteredArr = _.find(item && item.length > 0 && item, {
              assignee_name: uniqueIds[i],
            });
            tempArr.push(filteredArr);
          }
        });
      setAssigneename(tempArr);
    }
  }, [filteredArrayState]);

  useEffect(() => {
    if (updatedArray !== null) {
      settotalRecord(updatedArray.length);
      let totalList = [];
      let arr = [];
      let countrylisttempdata = [];
      let tempSetArray = [];
      let parId = [];
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });
      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          updatedArray &&
            updatedArray.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                  is_applicable:subMenuList.is_not_applicable
                };
                tempArray.push(entityArray);

                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        let checkarray = []
        let listArray_One_time = _.filter(tempArray, { compliance_frequency: "One Time" });
        if (listArray_One_time && listArray_One_time.length > 0) {
          for (let i in listArray_One_time) {
            checkarray.push(listArray_One_time[i])
          }
        }
        let listArray_periodical = _.filter(tempArray, { compliance_frequency: "Periodical" });
        if (listArray_periodical && listArray_periodical.length > 0) {
          for (let i in listArray_periodical) {
            checkarray.push(listArray_periodical[i])
          }
        }
        let listArray_Review = _.filter(tempArray, { compliance_frequency: "Review" });
        if (listArray_Review && listArray_Review.length > 0) {
          for (let i in listArray_Review) {
            checkarray.push(listArray_Review[i])
          }
        }
        let listArray_Flexi_Review = _.filter(tempArray, { compliance_frequency: "Flexi Review" });
        if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
          for (let i in listArray_Flexi_Review) {
            checkarray.push(listArray_Flexi_Review[i])
          }
        }
        let listArray_On_Occurrence = _.filter(tempArray, { compliance_frequency: "On Occurrence" });
        if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
          for (let i in listArray_On_Occurrence) {
            checkarray.push(listArray_On_Occurrence[i])
          }
        }

        totalList = {
          index: index,
          parentData: pmenu,
          child: checkarray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });
      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
    }
  }, [updatedArray, assigneename]);

  useEffect(() => {
    if (isAuth) {
      if (entityid !== "") {
        if (entityid != "null" && entityid !== null) {
          TaskRecallFilters({
            payload: [
              authtoken,
              {
                session_token: authtoken,
                request: [
                  "TaskRecallFilters",
                  {
                    le_id: entityid,
                  },
                ],
              },
            ],
            paramid: paramid,
          });
        }
      }
    }
  }, [isAuth, entityid]);

  const homescreen = () => {
    window.location.href = "/dashboard";
  };


  const onFinish = () => {
    const res = []
    const upres = []
    const keys = Object.keys(filterValues)
    keys.forEach(key => {
      if (filterValues[key] === true) {
        if (key == 'on_occurrence') {
          key = 'on occurrence'
        }
        if (key == 'one_time') {
          key = 'one time'
        }
        res.push(key)
        upres.push(
          key.toUpperCase().replace(/_/g, " ")
        )
      }
      // filterValues[key] === true && upres.push(
      //   key.toUpperCase().replace(/_/g, " ")
      // )
    })
    if (upres.length > 0 && upres.length != 5) {
      setAll(upres.join(', '))
    } else {
      setAll('All')
    }
    let filterTable;
    let temp = []
    let a
    if (res[0] == "All" || res.length == 0) {
      filterTable = updatedArray;
      settotalRecord(filterTable.length);
    } else {
      for (let i in res) {
        a = updatedArray.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(res[i].toLowerCase())
          )
        );


        Array.prototype.push.apply(temp, a);


      }
      filterTable = temp

      settotalRecord(filterTable.length);
    }

    let totalList = [];
    let arr = [];
    let countrylisttempdata = [];
    let tempSetArray = [];
    let parId = [];
    if (filterTable && filterTable.length > 0) {
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          filterTable &&
            filterTable.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: tempArray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });

      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
      // setChecked({
      //   All: false,
      //   periodical: false,
      //   flexi: false,
      //   review: false,
      //   on_occurrence: false,
      //   one_time: false

      // })
    } else {
      setMergedArray([]);
      setTemp([]);
      setTitleDynamic([]);
      setTempFilterArray([]);
    }
    setshowDropDown(false)
  }

  const onSubmit = (e) => {
    e.preventDefault()

  }
  const compliancetaskfilter = (e, type) => {
    let filterTable;
    if (type == "Compliance_Task") {
      filterTable = updatedArray.filter((o) => {
        if (all == "All") {
          if (o["statu"].toLowerCase().includes(e.toLowerCase()) || o["compliance_name"].toLowerCase().includes(e.toLowerCase()) || o["assigned_on"].toLowerCase().includes(e.toLowerCase())) {
            return true;
          }
        } else {
          if (
            (o["statu"].toLowerCase().includes(e.toLowerCase()) || o["compliance_name"].toLowerCase().includes(e.toLowerCase()) || o["assigned_on"].toLowerCase().includes(e.toLowerCase()) )&&
            o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
          ) {
            return true;
          }
        }
      });
    } else if (type == "Assignee") {
      filterTable = updatedArray.filter((o) => {
        if (all == "All") {
          if (o["assignee_name"].toLowerCase().includes(e.toLowerCase())) {
            return true;
          }
        } else {
          if (
            o["assignee_name"].toLowerCase().includes(e.toLowerCase()) &&
            o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
          ) {
            return true;
          }
        }
      });
    } else if (type == "Compliance_Status") {
      filterTable = updatedArray.filter((o) => {
        if (all == "All") {
          if (o["task_status"].toLowerCase().includes(e.toLowerCase())) {
            return true;
          }
        } else {
          if (
            o["task_status"].toLowerCase().includes(e.toLowerCase()) &&
            o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
          ) {
            return true;
          }
        }
      });
    } else if (type == "Due_Date") {
      filterTable = updatedArray.filter((o) => {
        if (all == "All") {
          if (o["due_date"].toLowerCase().includes(e.toLowerCase())) {
            return true;
          }
        } else {
          if (
            o["due_date"].toLowerCase().includes(e.toLowerCase()) &&
            o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
          ) {
            return true;
          }
        }
      });
    } else if (type == "Completion_Date") {
      filterTable = updatedArray.filter((o) => {
        if (all == "All") {
          if (o["completion_date"].toLowerCase().includes(e.toLowerCase())) {
            return true;
          }
        } else {
          if (
            o["completion_date"].toLowerCase().includes(e.toLowerCase()) &&
            o["compliance_frequency"].toLowerCase().includes(all.toLowerCase())
          ) {
            return true;
          }
        }
      });
    }

    let totalList = [];
    let arr = [];
    let countrylisttempdata = [];
    let tempSetArray = [];
    let parId = [];
    if (filterTable && filterTable.length > 0) {
      assigneename &&
        assigneename.length > 0 &&
        assigneename.map(function (user) {
          if (arr.indexOf(user.assignee_name) < 0)
            arr.push({
              assignee_name: user.assignee_name,
              unit_name: user.unit_name,
            });
        });

      arr.map((pmenu, index) => {
        // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
        let entityArray = [];
        let tempArray = [];
        let unit_name = [];
        if (pmenu) {
          filterTable &&
            filterTable.map((subMenuList, childIndex) => {
              if (
                pmenu.assignee_name === subMenuList.assignee_name &&
                pmenu.unit_name === subMenuList.unit_name
              ) {
                entityArray = {
                  address: subMenuList.address,
                  approve_status: subMenuList.approve_status,
                  approved_on: subMenuList.approved_on,
                  assigned_on: subMenuList.assigned_on,
                  assignee_name: subMenuList.assignee_name,
                  category_name: subMenuList.category_name,
                  compfielaw_url: subMenuList.compfielaw_url,
                  completion_date: subMenuList.completion_date,
                  compliance_description: subMenuList.compliance_description,
                  compliance_frequency: subMenuList.compliance_frequency,
                  compliance_history_id: subMenuList.compliance_history_id,
                  compliance_id: subMenuList.compliance_id,
                  compliance_name: subMenuList.compliance_name,
                  country_id: subMenuList.country_id,
                  division_name: subMenuList.division_name,
                  document_reference_number:
                    subMenuList.document_reference_number,
                  document_size: subMenuList.document_size,
                  domain_id: subMenuList.domain_id,
                  domain_name: subMenuList.domain_name,
                  download_url: subMenuList.download_url,
                  due_date: subMenuList.due_date,
                  duration_type: subMenuList.duration_type,
                  extn_due_date: subMenuList.extn_due_date,
                  file_names: subMenuList.file_names,
                  format_file_name: subMenuList.format_file_name,
                  history_count: subMenuList.history_count,
                  interim_count: subMenuList.interim_count,
                  interim_docs: subMenuList.interim_docs,
                  interim_docs_size: subMenuList.interim_docs_size,
                  interm_newdoc: subMenuList.interm_newdoc,
                  next_due_date: subMenuList.next_due_date,
                  occurrence_remarks: subMenuList.occurrence_remarks,
                  penal_consequences: subMenuList.penal_consequences,
                  recall_id: subMenuList.recall_id,
                  recall_status: subMenuList.recall_status,
                  remarks: subMenuList.remarks,
                  remarks_count: subMenuList.remarks_count,
                  reviwer_docs: subMenuList.reviwer_docs,
                  reviwer_docs_size: subMenuList.reviwer_docs_size,
                  reviwer_newdoc: subMenuList.reviwer_newdoc,
                  start_date: subMenuList.start_date,
                  statu: subMenuList.statu,
                  system_remarks: subMenuList.system_remarks,
                  task_status: subMenuList.task_status,
                  tz_date: subMenuList.tz_date,
                  tz_name: subMenuList.tz_name,
                  unit_id: subMenuList.unit_id,
                  unit_name: subMenuList.unit_name,
                  upload_docs: subMenuList.upload_docs,
                  validity_date: subMenuList.validity_date,
                  validity_settings_days: subMenuList.validity_settings_days,
                  parentId: index,
                  childId: childIndex,
                  indexNo: childIndex + 1,
                };
                tempArray.push(entityArray);
                unit_name.push(subMenuList.unit_name);
              }
            });
        }
        totalList = {
          index: index,
          parentData: pmenu,
          child: tempArray,
        };
        titleDynamic[index] = pmenu;
        countrylisttempdata.push(totalList);
        tempSetArray.push(tempArray);
        parId.push(unit_name);
      });

      setTitleDynamic([...titleDynamic]);
      var merged = [].concat.apply([], tempSetArray);
      setTemp(countrylisttempdata);
      setMergedArray(merged);
      setTempFilterArray([...new Set([].concat.apply([], parId))]);
    } else {
      setMergedArray([]);
      setTemp([]);
      setTitleDynamic([]);
      setTempFilterArray([]);
    }
  };

  const gettooltipfun = (record) => {
    const content = (
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #636262",
          tableLayout: "auto",
        }}
      >
        <tbody>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Vendor</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.unit_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Address</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.address}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Division</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.division_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Category</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.category_name}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Act/Rules</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.statu}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Compliance Description</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.compliance_description}</b>
            </td>
          </tr>
          <tr>
            <th style={{ border: "1px solid #636262" }}>
              <b>Penal Consequences</b>
            </th>
            <td style={{ border: "1px solid #636262" }}>
              <b>{record.penal_consequences}</b>
            </td>
          </tr>
        </tbody>
      </table>
    );
    return content;
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setshowDropDown(false)
    }
  };

  const viewhistory = (data) => {

    const payload = [
      authtoken,
      {
        "session_token": authtoken,
        "request": [
          "GetComplianceRemarksHistoryData",
          {
            "legal_entity_id": showdata.legal_entity,
            "unit_id": data.unit_id,
            "compliance_history_id": data.compliance_history_id
          }
        ]
      }
    ]
    historyrecord({
      payload: payload,
      paramid: paramid
    })
    sethistorymodal(true);
  }

  const viewinterim = (data) => {
    const payload = [
      authtoken,
      {
        "session_token": authtoken,
        "request": [
          "GetInterimComplianceDetails",
          {
            "legal_entity_id": showdata.legal_entity,
            "compliance_history_id": data.compliance_history_id
          }
        ]
      }
    ]
    interimrecord({
      payload: payload,
      paramid: paramid
    })
    setinterimmodal(true);
  }

  const historycancel = () => {
    sethistorymodal(false);
  }

  const history_columns = [
    {
      title: '#',
      key: 'index',
      align: "center",
      width: "30px",
      render: (text, record, index) => {
        return (
          index + 1
        )
      },
    },
    {
      title: 'Activity Date',
      key: 'activity_on',
      align: "center",
      width: "50px",
      dataIndex: 'activity_on',
    },
    {
      title: 'User Type',
      key: 'action',
      align: "center",
      width: "50px",
      dataIndex: 'action',
      render: (text, record) => {
        if (record.user_category_id == 5 || record.user_category_id == 6) {
          return 'Assignee'
        } else if ((record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Rectified' || record.action == 'Concurrence Rejected' || record.action == 'Concurred')) {
          return 'Concurrer'
        } else if (record.user_category_id == 1 && (record.action == 'RectifyApproval' || record.action == 'Approval Rejected' || record.action == 'Approved') ||
          (record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Approval Rejected' || record.action == 'RectifyApproval' || record.action == 'Approved')) {
          return 'Approver'
        }

      }
    },
    {
      title: 'Reviewer Report',
      key: 'documents',
      align: "center",
      width: "50px",
      dataIndex: 'documents',
    },
    {
      title: 'Remarks',
      key: 'remarks',
      align: "center",
      width: "50px",
      dataIndex: 'remarks',
    },
  ]

  const interim_columns = [
    {
      title: '#',
      key: 'index',
      align: "center",
      width: "30px",
      render: (text, record, index) => {
        return (
          index + 1
        )
      },
    },
    {
      title: 'Documents',
      key: 'documents',
      align: "center",
      width: "50px",
      dataIndex: 'documents',
    },
    {
      title: 'Remarks',
      key: 'remarks',
      align: "center",
      width: "50px",
      dataIndex: 'remarks',
    },
    {
      title: 'Updated On',
      key: 'activity_on',
      align: "center",
      width: "50px",
      dataIndex: 'activity_on',
    },
  ]

  const interimcancel = () => {
    setinterimmodal(false);
  }

  const resetfilter = () => {
    setFilterValues({
      ...filterValues,
      All: '',
      periodical: '',
      flexi_review: '',
      review: '',
      on_occurrence: '',
      one_time: ''
    })
    setChecked({
      ...checked,
      All: false,
      periodical: false,
      flexi_review: false,
      review: false,
      on_occurrence: false,
      one_time: false
    })
  }

  return (
    <div id="page-wrapper" className="page-wrapper">
      {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
      {document.body.scrollHeight > document.body.clientHeight ?
        <div className='back-to-top'>
          <ScrollButton />
        </div> : ''} */}
      <div className='back-to-top'>
        <ScrollButton />
      </div>
      <div className="page-titles pb-0 pt-1">
        <div className="row">
          <div className="col-lg-8 col-md-6 col-12 align-self-center">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 d-flex align-items-center">
                <li className="breadcrumb-item">
                  <span className="pointer" onClick={homescreen}>
                    <i className="ri-home-3-line fs-5"></i>
                  </span>
                </li>
                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                  <span style={{ fontSize: '16px' }}>Transaction</span>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ fontSize: "large", fontWeight: "bold" }}
                >
                  <span>Task Recall</span>
                </li>
              </ol>
            </nav>
            {/* <h2 className="mb-0 fw-bold">Task Recall</h2> */}
          </div>
          <div
            className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end"
            style={{ paddingRight: "14px" }}
          >
            {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                        icon={<ArrowLeftOutlined />} size='default' onClick={(e) => { previous(e) }}>
                        Back
                      </Button> */}

            <Tooltip title='Advanced Filter' placement="top">
              <Button
                type="primary"
                shape="round"
                style={{
                  marginRight: "10px",
                  display: filterTaskAcc ? "none" : "block",
                }}
                onClick={filterHandle}
              >
                <FilterOutlined /> Filter
              </Button>
            </Tooltip>
            <button
              onClick={(e) => {
                fullscreenMode();
              }}
              className="bg-transparent text-black ms-2 full-mode fullscreenradius"
            >
              <Tooltip
                placement="left"
                title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}
              >
                <FullscreenOutlined />
              </Tooltip>
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-1">
        <div className="row">
          <div className="col-lg-12">
            <div
              className={
                localStorage.getItem("currentTheme") +
                " card1 card-body1-statuatory "
              }
            >
              <Collapse
                accordion
                defaultActiveKey={["1"]}
                id="taskRecall"
                style={{ display: exitCollapse ? "none" : "block" }}
              >
                <Panel
                  className="report-collapse"
                  header="Task Recall"
                  key={key}
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        {/* <div className="col-md-8" > */}
                        {/* <div className="mb-3"> */}
                        {/* <label>Legal Entity:</label> */}
                        <label htmlFor="">
                          <b>Legal Entity: </b>
                        </label>
                        <span style={{ color: "red" }}> *</span>
                        <br />
                        {localStorage.getItem("SelectedEntity") ===
                          "All Legal Entity" ? (
                          <div className="form-group">
                            <Select
                              allowClear={true}
                              size="default"
                              placeholder="Select Legal Entity"
                              onPaste={(e) => {
                                handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                              }}
                              onChange={(data, value) => {
                                if (data !== undefined) {
                                  Setlegalstate(true);
                                  setCurrentEntity(data);
                                  Setshowdata({
                                    ...data,
                                    legal_entity: data,
                                    domain: "",
                                    unit: "",
                                    frequency: "",
                                    asignee: "",
                                    due_month: "",
                                  });
                                } else {
                                  Setlegalstate(false);
                                  setCurrentEntity("");
                                  Setshowdata({
                                    ...data,
                                    legal_entity: "",
                                    domain: "",
                                    unit: "",
                                    frequency: "",
                                    asignee: "",
                                    due_month: "",
                                  });
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.legal_entity || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              {sessionArr &&
                                sessionArr.length > 0 &&
                                sessionArr.map((item, i) => {
                                  return (
                                    <Option key={item.le_id}>
                                      {item.le_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {formValidator.current.message(
                              "legal_entity",
                              showdata.legal_entity,
                              "required",
                              {
                                className: `invalid-feedback ${FormShow ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Legal Entity Required",
                                },
                              }
                            )}
                          </div>
                        ) : (

                          <p title={localStorage.getItem('SelectedEntity')} className='cut-text-word' style={{ width: "100%", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                        )}
                        {/* </div> */}
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Domain: </b>
                          </label>
                          <span style={{ color: "red" }}> *</span>
                          <br />
                          <Select
                            allowClear={true}
                            size="default"
                            name="domain"
                            id="domain"
                            placeholder="Enter domain"
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9]+$/gi)
                            }}
                            onChange={(data, value) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  domain: data,
                                  domainName: value.children,
                                  unit: "",
                                  frequency: "",
                                  asignee: "",
                                  due_month: "",
                                  act: "",
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  domain: "",
                                  unit: "",
                                  frequency: "",
                                  asignee: "",
                                  due_month: "",
                                  act: "",
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.domain || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                            disabled={
                              showdata.legal_entity === "" ? true : false
                            }
                          >
                            {userunitsrecall &&
                              userunitsrecall.domains_list &&
                              userunitsrecall.domains_list.map((item, i) => {
                                return (
                                  <Option key={item.d_id}>{item.d_name}</Option>
                                );
                              })}
                          </Select>
                          {formValidator.current.message(
                            "domain",
                            showdata.domain,
                            ["required"],
                            {
                              className: `invalid-feedback ${FormShow ? "show" : "hide"
                                }`,
                              messages: {
                                required: "Domain Required",
                              },
                            }
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Vendor: </b>
                          </label>
                          <br />
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Enter vendor"
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9 , . - _]+$/gi)
                            }}
                            onChange={(data) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  unit: data,
                                  frequency: "",
                                  due_month: "",
                                  act: "",
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  unit: "",
                                  frequency: "",
                                  due_month: "",
                                  act: "",
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.unit || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                            disabled={
                              showdata.legal_entity === "" ? true : false
                            }
                          >
                            {userunitsrecall &&
                              userunitsrecall.user_units &&
                              userunitsrecall.user_units.map((item, i) => {
                                return (
                                  <Option key={item.unit_id}>
                                    {item.unit_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "10px" }}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Frequency: </b>
                          </label>

                          <Select defaultValue="All"
                            allowClear={true}
                            size="default"
                            // placeholder="Enter frequecy"
                            onChange={(data, value) => {
                              setAll(value.children === undefined ? 'All' : value.children)
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  frequency: data,
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  frequency: "",
                                });
                              }
                            }}
                            // showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.frequency || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            <Option key={0}>All</Option>
                            {userunitsrecall &&
                              userunitsrecall.frequency_list &&
                              userunitsrecall.frequency_list.map((item, i) => {
                                return (
                                  <Option key={item.frequency_id}>
                                    {item.frequency_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Due Month: </b>
                          </label>{" "}
                          <span style={{ color: "red" }}>*</span>
                          {due == false ? (
                            <DatePicker
                              popupStyle={{
                                height: "150px",
                                width: "280px",
                                marginTop: "5px",
                              }}
                              onKeyDown={(event) => {
                                event.preventDefault()
                              }}
                              format={"MMMM-YYYY"}
                              disabled={showdata.domain === "" ? true : false}
                              style={{ width: "100%", marginTop: "5px" }}
                              allowClear={false}
                              onChange={(date, dateString) => {
                                setDue(true);
                                Setshowdata({
                                  ...showdata,
                                  due_month:
                                    moment(dateString).format("MMMM-YYYY"),
                                });
                              }}
                              picker="month"
                              value={
                                showdata.due_month
                                  ? moment(showdata.due_month)
                                  : ""
                              }
                              monthCellRender={(current) => {
                                const style = {};
                                if (
                                  moment(current).format("MMM-YYYY") ===
                                  moment().format("MMM-YYYY")
                                ) {
                                  // style.border = '1px solid #1890ff';
                                  style.borderRadius = "2px";
                                  style.lineHeight = "24px";
                                  style.minWidth = "24px";
                                  style.position = "relative";
                                  style.display = "inlineBlock";
                                  style.backgroundColor = "#1890ff";
                                  style.color = "white";
                                  style.marginTop = "5px";
                                }
                                return (
                                  <div
                                    className="ant-picker-cell-inner"
                                    style={style}
                                  >
                                    {moment(current).format("MMM")}
                                  </div>
                                );
                              }}
                            />
                          ) : (
                            <DatePicker
                              popupStyle={{
                                height: "150px",
                                width: "280px",
                                marginTop: "5px",
                              }}
                              format={"MMMM-YYYY"}
                              disabled={showdata.domain === "" ? true : false}
                              style={{
                                width: "100%",
                                marginTop: "5px !important",
                              }}
                              allowClear={false}
                              onChange={(date, dateString) => {
                                setDue(true);
                                Setshowdata({
                                  ...showdata,
                                  due_month:
                                    moment(dateString).format("MMMM-YYYY"),
                                });
                              }}
                              picker="month"
                              onKeyDown={(event) => {
                                event.preventDefault()
                              }}
                              value={
                                showdata.due_month
                                  ? moment(showdata.due_month)
                                  : ""
                              }
                            />
                          )}
                          {formValidator.current.message(
                            "date",
                            showdata.due_month,
                            ["required"],
                            {
                              className: `invalid-feedback ${FormShow ? "show" : "hide"
                                }`,
                              messages: {
                                required: "Due Month Required",
                              },
                            }
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            <b>Act: </b>
                          </label>
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Enter act"
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9 () , . _ -]+$/gi)
                            }}
                            onChange={(data) => {
                              if (data !== undefined) {
                                Setshowdata({
                                  ...showdata,
                                  act: data,
                                });
                              } else {
                                Setshowdata({
                                  ...showdata,
                                  act: "",
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={showdata.act || undefined}
                            style={{ width: "100%", marginTop: "5px" }}
                            disabled={
                              showdata.due_month === "" ? true : false
                            }
                          >
                            {userunitsrecall &&
                              userunitsrecall.act_list &&
                              userunitsrecall.act_list.map((item, i) => {
                                return (
                                  <Option key={item.parent_id}>
                                    {item.statutory_mapping}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: '10px' }}>
                    <div className="row">
                      {callername && callername.login_response && callername.login_response.usr_cat_id === 1 || callername && callername.login_response && callername.login_response.usr_cat_id === 3 || callername && callername.login_response && callername.login_response.usr_cat_id === 4 ?
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Assignee: </b>
                            </label>
                            <br />
                            <Select
                              allowClear={true}
                              size="default"
                              name="domain"
                              id="domain"
                              placeholder="Enter Assignee"
                              showSearch
                              onPaste={(e) => {
                                handlePasting(e, /^[a-z 0-9 -]+$/gi)
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ width: "100%", marginTop: "5px" }}>
                              <Option>AS121-Assigne</Option>
                              <Option>4567-Thyaguu</Option>
                              <Option>3948-View Only</Option>
                            </Select>
                          </div>
                        </div> :
                        <div className="col-md-4">

                        </div>
                      }

                      <div className="col-md-4 text-center popupbtncolour mb-2">
                        <Button
                          type="primary"
                          shape="round"
                          className={"addbutton  " + localStorage.getItem('currentTheme')}
                          style={{ background: "#0096FF", borderColor: "#0096FF", 'margin-top': '27px' }}
                          size="default"
                          icon={<EyeOutlined />}
                          onClick={gettaskrecalllist}>
                          Show
                        </Button>
                      </div>
                    </div>
                  </div>

                </Panel>
              </Collapse>
              {acccordionTrue === true ? (
                <>
                  {/* <div className={scroll == true ? "d-block" : "d-none"}>
                                    <Button style={{ position: 'fixed', right: '30px', bottom: '50px', zIndex: 1, width: '34px', display: 'flex', justifyContent: 'center' }} onClick={() => { scrollup() }}><UpCircleOutlined style={{ fontSize: '1.5rem' }} /></Button>
                                </div> */}
                  <div className="mt-0 mx-2">
                    <Collapse defaultActiveKey={["0"]} accordion>
                      <Panel
                        className="report-collapse"
                        header="Compliance Table"
                        key="0"
                        extra={
                          <>
                            <span style={{ paddingRight: "80px" }}>
                              Selected Frequency : {all.toString()}
                            </span>
                            <span style={{ paddingRight: "80px" }}>
                              Total Compliance : {totalRecord}
                            </span>
                          </>
                        }
                      >
                        <div
                          className={
                            localStorage.getItem("currentTheme") +
                            " ant-table-wrapper"
                          }
                        >
                          <div className="ant-table ant-table-bordered ant-table-fixed-header">
                            <div className="ant-table-container">
                              <div className="ant-table-content taskrecalltable service-table">
                                <table className="table-fixed">
                                  <thead className="ant-table-thead">
                                    <tr>
                                      <th className="ant-table-cell" style={{ width: "5px" }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                          <span className="me-1"># </span>
                                          <div className="position-relative" ref={wrapperRef}>
                                            <button onClick={(e) => { setshowDropDown(current => !current) }}>
                                              <FilterFilled />
                                            </button>
                                            {showDropDown && (
                                              <Form onFinish={onFinish} className='bg-white position-absolute text-start shadow-lg' style={{ borderRadius: '15px', top: '32px', width: '165px' }}>

                                                <Form.Item className="mt-1" name={'periodical'} >
                                                  &nbsp;<Checkbox id={'periodical'} value={'periodical'} checked={checked.periodical} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      periodical: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      periodical: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      periodical: e.target.checked
                                                    })
                                                  }} />&nbsp; <label className="fw-normal user-select-none" htmlFor="periodical"><i style={{ color: 'green' }} className="bi bi-square-fill"></i> Periodical</label>
                                                </Form.Item>
                                                <Form.Item name={'flexi_review'}>
                                                  &nbsp;<Checkbox id="flexi_review" value={'flexi review'} checked={checked.flexi_review} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      flexi_review: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      flexi_review: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      flexi_review: e.target.checked
                                                    })
                                                  }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="flexi_review"><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</label>
                                                </Form.Item>
                                                <Form.Item name={'Review'}>
                                                  &nbsp;<Checkbox id="Review" value={'Review'} checked={checked.review} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      review: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      review: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      review: e.target.checked
                                                    })
                                                  }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="Review"><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</label>
                                                </Form.Item>
                                                <Form.Item name={'On Occurrence'}>
                                                  &nbsp;<Checkbox id="On-Occurrence" value={'On Occurrence'} checked={checked.on_occurrence} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      on_occurrence: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      on_occurrence: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      on_occurrence: e.target.checked
                                                    })
                                                  }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="On-Occurrence"><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> On Occurrence</label>
                                                </Form.Item>
                                                <Form.Item className="mb-1" name={' One Time'}>
                                                  &nbsp;<Checkbox id="One-Time" value={'One Time'} checked={checked.one_time} onChange={(e) => {
                                                    e.target.checked ? setChecked({
                                                      ...checked,
                                                      one_time: true
                                                    }) : setChecked({
                                                      ...checked,
                                                      one_time: false
                                                    })

                                                    setFilterValues({
                                                      ...filterValues,
                                                      one_time: e.target.checked
                                                    })
                                                  }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="One-Time"><i style={{ color: "red" }} className="bi bi-square-fill"></i> One Time</label>
                                                </Form.Item>
                                                <Form.Item className="submitButtonFrm" >
                                                  <Button style={{ borderRadius: '10px' }} className="statistic" type="primary" htmlType="submit">OK</Button>
                                                  <a className="link ms-2"  onClick={() => { resetfilter() }}>Reset</a>
                                                </Form.Item>
                                              </Form>
                                            )}
                                          </div>
                                        </div>
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "20px" }}
                                      >
                                        {/* Action {' '} */}
                                        <input
                                          type="checkbox"
                                          title="Click here to approve all"
                                          name="allchecked"
                                          className={"allChecked"}
                                          onClick={(e) => {
                                            let checked = e.target.checked;
                                            addAllSelectedDataValues(checked);
                                          }}
                                        />
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "610px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p
                                            className="p-0 mb-0"
                                            style={{
                                              marginLeft: "36%",
                                              display: "inline",
                                            }}
                                          >
                                            Compliance Task
                                          </p>{" "}
                                          <Popconfirm
                                            placement="bottom"
                                            title={
                                              <Input
                                                placeholder="Compliance Task"
                                                autoFocus={'on'}
                                                onKeyUp={(e) => {
                                                  compliancetaskfilter(
                                                    e.target.value,
                                                    "Compliance_Task"
                                                  );
                                                }}
                                              />
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>
                                        {/* <Input placeholder='Compliance Task' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Compliance_Task')
                                                                        }} /> */}
                                      </th>
                                      {/* <th className='ant-table-cell' style={{ width: '10%' }}>Assignee <Input placeholder='Assignee' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Assignee')
                                                                        }} /></th> */}
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "90px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p
                                            className="mb-0"
                                            style={{
                                              marginLeft: "2px",
                                              display: "inline",
                                            }}
                                          >
                                            Compliance Status
                                          </p>
                                          <Popconfirm
                                            placement="bottom"
                                            title={
                                              <Input
                                                placeholder="Compliance Status"
                                                autoFocus={'on'}
                                                onKeyUp={(e) => {
                                                  compliancetaskfilter(
                                                    e.target.value,
                                                    "Compliance_Status"
                                                  );
                                                }}
                                              />
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>
                                        {/* <Input placeholder='Compliance Status' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Compliance_Status')
                                                                        }} /> */}
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "80px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="mb-0"
                                            style={{
                                              marginLeft: "20%",
                                              display: "inline",
                                            }}
                                          >
                                            Due Date
                                          </p>{" "}
                                          <Popconfirm
                                            placement="bottom"
                                            title={
                                              <Input
                                                placeholder="Due Date"
                                                autoFocus={'on'}
                                                onKeyUp={(e) => {
                                                  compliancetaskfilter(
                                                    e.target.value,
                                                    "Due_Date"
                                                  );
                                                }}
                                              />
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>

                                        {/* <Input placeholder='Due Date' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Due_Date')
                                                                        }} /> */}
                                      </th>
                                      <th
                                        className="ant-table-cell"
                                        style={{ width: "80px" }}
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="mb-0"
                                            style={{
                                              marginLeft: "12%",
                                              display: "inline",
                                            }}
                                          >
                                            Completion Date
                                          </p>
                                          <Popconfirm
                                            placement="bottom"
                                            title={
                                              <Input
                                                placeholder="Completion Date"
                                                autoFocus={'on'}
                                                onKeyUp={(e) => {
                                                  compliancetaskfilter(
                                                    e.target.value,
                                                    "Completion_Date"
                                                  );
                                                }}
                                              />
                                            }
                                          >
                                            <SearchOutlined
                                              style={{ float: 'right', marginTop: '4px' }}
                                            />
                                          </Popconfirm>
                                        </div>

                                        {/* <Input placeholder='Completion Date' onKeyUp={(e) => {
                                                                            compliancetaskfilter(e.target.value, 'Completion_Date')
                                                                        }} /> */}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="ant-table-tbody">
                                    {filteredArrayState &&
                                      filteredArrayState.length > 0 ? (
                                      filteredArrayState.map((list, ii) => {
                                        return (
                                          <Fragment key={ii}>
                                            {tempFilterArray.includes(
                                              list[0].unit_name
                                            ) == true ? (
                                              <tr className="bg-lightdark">
                                                <td colSpan={7}>
                                                  <div
                                                    className="ant-table-title row"
                                                  >
                                                    <span className="col-md-6 text-start"> Unit : {list[0].unit_name}</span>
                                                    <span className="col-md-6 text-end"> Domain : {showdata.domainName}</span>
                                                  </div>
                                                </td>
                                              </tr>
                                            ) : (
                                              ""
                                            )}
                                            {temp && temp.length > 0 ? (
                                              temp.map((itemdata, i) => {
                                                let data = _.find(
                                                  itemdata.child,
                                                  {
                                                    unit_name:
                                                      list[0].unit_name,
                                                  }
                                                );
                                                if (data != undefined) {
                                                  if (
                                                    data.assignee_name ==
                                                    itemdata.parentData
                                                      .assignee_name
                                                  ) {
                                                    return (
                                                      <Fragment key={i}>
                                                        <tr className="ant-table-row ant-table-row-level-0">
                                                          <td
                                                            colSpan={12}
                                                            className="ant-table-cell"
                                                          >
                                                            <b>
                                                              {
                                                                itemdata
                                                                  .parentData
                                                                  .assignee_name
                                                              }
                                                            </b>
                                                          </td>
                                                        </tr>
                                                        {itemdata.child.map(
                                                          (items, l) => {

                                                            return (
                                                              <tr
                                                                className="ant-table-row ant-table-row-level-0"
                                                                key={l}
                                                              >
                                                                <td className="ant-table-cell">
                                                                  <center>
                                                                    {counts + 1}
                                                                    <span
                                                                      hidden
                                                                    >
                                                                      {
                                                                        (counts =
                                                                          counts +
                                                                          1)
                                                                      }
                                                                    </span>
                                                                    {items.compliance_frequency ==
                                                                      "Periodical" ? (
                                                                      <p>

                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "green",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : items.compliance_frequency ==
                                                                      "FlexiReview" ? (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "pink",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : items.compliance_frequency ==
                                                                      "Review" ? (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "orange",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : items.compliance_frequency ==
                                                                      "On Occurrence" ? (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "yellow",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    ) : (
                                                                      <p>
                                                                        <i
                                                                          style={{
                                                                            color:
                                                                              "red",
                                                                          }}
                                                                          title={
                                                                            items.compliance_frequency
                                                                          }
                                                                          className="bi bi-square-fill"
                                                                        ></i>
                                                                      </p>
                                                                    )}
                                                                  </center>
                                                                </td>
                                                                <td>
                                                                  <Fragment>
                                                                    <center>
                                                                      <input
                                                                        type="checkbox"
                                                                        title="Click here to Task Recall"
                                                                        name={
                                                                          "checked"
                                                                        }
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          let checked =
                                                                            e
                                                                              .target
                                                                              .checked;
                                                                          addSelectedDataValues(
                                                                            checked,
                                                                            items.compliance_history_id,
                                                                            items.parentId
                                                                          );
                                                                        }}
                                                                      />
                                                                    </center>
                                                                  </Fragment>
                                                                </td>
                                                                <td>
                                                                  <>
                                                                    <span
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() => {
                                                                        showValue();
                                                                        setValueModal(
                                                                          {
                                                                            compliance_history_id:
                                                                              items.compliance_history_id,
                                                                            unit: items.unit_name,
                                                                            compliance_task:
                                                                              items.compliance_name,
                                                                            compliance_frequency:
                                                                              items.compliance_frequency,
                                                                            document_issued_date:
                                                                              items.completion_date,
                                                                            document_reference_number:
                                                                              items.document_reference_number,
                                                                            next_due_date:
                                                                              items.next_due_date,
                                                                            address:
                                                                              items.address,
                                                                            compliance_description:
                                                                              items.compliance_description,
                                                                            uploaded_document:
                                                                              items.upload_docs,
                                                                            interim_details:
                                                                              items.interim_count,
                                                                            remarks:
                                                                              items.remarks,
                                                                            history_details: items.remarks_count,
                                                                            unit_id: items.unit_id,
                                                                            is_applicable:items.is_applicable,
                                                                            occurrence_remarks: items.occurrence_remarks
                                                                          }
                                                                        );
                                                                      }}
                                                                    >
                                                                      {/* {items.compliance_frequency == "Periodical" ? (
                                                                        <span style={{ color: "green" }}>{items.statu}</span>
                                                                      ) : items.compliance_frequency ==
                                                                        "FlexiReview" ? (
                                                                        <span style={{ color: "#F4BDD5" }}>{items.statu}</span>
                                                                      ) : items.compliance_frequency ==
                                                                        "Review" ? (
                                                                        <span style={{ color: "#F78453" }}>{items.statu}</span>
                                                                      ) : items.compliance_frequency ==
                                                                        "On Occurrence" ? (
                                                                        <span style={{ color: "#420075" }}>{items.statu}</span>
                                                                      ) : (
                                                                        <span style={{ color: "#F21625" }}>{items.statu}</span>
                                                                      )} */}
                                                                      {items.statu}
                                                                      <div className="approve-task-reacall-tooltip">
                                                                        <div className="mb-0">
                                                                          <Tooltip
                                                                            placement="topRight"
                                                                            overlayStyle={{
                                                                              maxWidth:
                                                                                "420px",
                                                                            }}
                                                                            title={gettooltipfun(
                                                                              items
                                                                            )}
                                                                          >
                                                                            {" "}
                                                                            <ExclamationCircleTwoTone />
                                                                            {/* gettooltipfun(items)
                                                                                                                            }> <ExclamationCircleTwoTone/> */}
                                                                          </Tooltip>
                                                                          &nbsp;
                                                                          {/* <Popover content={gettooltipfun(items)} title="">
                                                                                                                                <Button style={{ border: 0 }}><i className="bi-info-circle-fill"></i></Button>
                                                                                                                            </Popover> */}
                                                                          {/* {items.compliance_frequency == "Periodical" ? (
                                                                          <span style={{ color: "green" }}>{items.compliance_name}</span>
                                                                        ) : items.compliance_frequency ==
                                                                          "FlexiReview" ? (
                                                                          <span style={{ color: "#F4BDD5" }}>{items.compliance_name}</span>
                                                                        ) : items.compliance_frequency ==
                                                                          "Review" ? (
                                                                          <span style={{ color: "#F78453" }}>{items.compliance_name}</span>
                                                                        ) : items.compliance_frequency ==
                                                                          "On Occurrence" ? (
                                                                          <span style={{ color: "#420075" }}>{items.compliance_name}</span>
                                                                        ) : (
                                                                          <span style={{ color: "#F21625" }}>{items.compliance_name}</span>
                                                                        )} */}

                                                                          {
                                                                            items.compliance_name
                                                                          }<br />Assigned on : {items.assigned_on}
                                                                        </div>
                                                                      </div>
                                                                    </span>

                                                                  </>
                                                                </td>
                                                                {/* <td>
                                                                                                                {items.assignee_name}
                                                                                                            </td> */}
                                                                <td>
                                                                  <span
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      showValue();
                                                                      setValueModal(
                                                                        {
                                                                          compliance_history_id:
                                                                            items.compliance_history_id,
                                                                          unit: items.unit_name,
                                                                          compliance_task:
                                                                            items.compliance_name,
                                                                          compliance_frequency:
                                                                            items.compliance_frequency,
                                                                          document_issued_date:
                                                                            items.completion_date,
                                                                          document_reference_number:
                                                                            items.document_reference_number,
                                                                          next_due_date:
                                                                            items.next_due_date,
                                                                          address:
                                                                            items.address,
                                                                          compliance_description:
                                                                            items.compliance_description,
                                                                          uploaded_document:
                                                                            items.upload_docs,
                                                                          interim_details:
                                                                            items.interim_docs,
                                                                          remarks:
                                                                            items.remarks,
                                                                            is_applicable: items.is_applicable,
                                                                            occurrence_remarks: items.occurrence_remarks
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    {
                                                                      items.task_status
                                                                    }
                                                                  </span>
                                                                </td>
                                                                <td>

                                                                  <center>
                                                                    <span
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() => {
                                                                        showValue();
                                                                        setValueModal(
                                                                          {
                                                                            compliance_history_id:
                                                                              items.compliance_history_id,
                                                                            unit: items.unit_name,
                                                                            compliance_task:
                                                                              items.compliance_name,
                                                                            compliance_frequency:
                                                                              items.compliance_frequency,
                                                                            document_issued_date:
                                                                              items.completion_date,
                                                                            document_reference_number:
                                                                              items.document_reference_number,
                                                                            next_due_date:
                                                                              items.next_due_date,
                                                                            address:
                                                                              items.address,
                                                                            compliance_description:
                                                                              items.compliance_description,
                                                                            uploaded_document:
                                                                              items.upload_docs,
                                                                            interim_details:
                                                                              items.interim_docs,
                                                                            remarks:
                                                                              items.remarks,
                                                                              is_applicable:items.is_applicable,
                                                                              occurrence_remarks: items.occurrence_remarks
                                                                          }
                                                                        );
                                                                      }}
                                                                    >

                                                                      {items.compliance_frequency === "On Occurrence" ? 
                                                                      moment(
                                                                        items.due_date
                                                                      ).format(
                                                                        "DD-MMM-YYYY hh:mm"
                                                                      ) : 
                                                                      moment(
                                                                        items.due_date
                                                                      ).format(
                                                                        "DD-MMM-YYYY"
                                                                      )
                                                                      }

                                                                    </span>

                                                                  </center>
                                                                </td>
                                                                <td>
                                                                  <center>
                                                                    <span
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() => {
                                                                        showValue();
                                                                        setValueModal(
                                                                          {
                                                                            compliance_history_id:
                                                                              items.compliance_history_id,
                                                                            unit: items.unit_name,
                                                                            compliance_task:
                                                                              items.compliance_name,
                                                                            compliance_frequency:
                                                                              items.compliance_frequency,
                                                                            document_issued_date:
                                                                              items.completion_date,
                                                                            document_reference_number:
                                                                              items.document_reference_number,
                                                                            next_due_date:
                                                                              items.next_due_date,
                                                                            address:
                                                                              items.address,
                                                                            compliance_description:
                                                                              items.compliance_description,
                                                                            uploaded_document:
                                                                              items.upload_docs,
                                                                            interim_details:
                                                                              items.interim_docs,
                                                                            remarks:
                                                                              items.remarks,
                                                                              is_applicable:items.is_applicable,
                                                                              occurrence_remarks: items.occurrence_remarks
                                                                          }
                                                                        );
                                                                      }}
                                                                    >

                                                                      {moment(
                                                                        items.completion_date
                                                                      ).format(
                                                                        "DD-MMM-YYYY"
                                                                      )}

                                                                    </span>

                                                                  </center>
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )}
                                                      </Fragment>
                                                    );
                                                  }
                                                }
                                              })
                                            ) : ii == 0 ? (
                                              <tr>
                                                <td
                                                  colSpan={7}
                                                  style={{
                                                    textAlign: "center",
                                                    fontSize: "14px",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  No Compliance Available
                                                </td>
                                              </tr>
                                            ) : (
                                              false
                                            )}
                                          </Fragment>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan={7}
                                          style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          No Compliance Available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </Collapse>
                    <Modal
                      title="Task Recall"
                      className={
                        "add-service-prv " +
                        localStorage.getItem("currentTheme")
                      }
                      footer={false}
                      visible={filterModalVisible}
                      onCancel={setcancelFilter}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          {/* <div className="col-md-8" > */}
                          {/* <div className="mb-3"> */}
                          {/* <label>Legal Entity:</label> */}
                          <label htmlFor="">
                            <b>Legal Entity: </b>
                          </label>
                          <span style={{ color: "red" }}> *</span>
                          <br />
                          {localStorage.getItem("SelectedEntity") ===
                            "All Legal Entity" ? (
                            <div className="form-group">
                              <Select
                                getPopupContainer={trigger => trigger.parentNode}
                                allowClear={true}
                                size="default"
                                placeholder="Select Legal Entity"
                                onPaste={(e) => {
                                  handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                }}
                                onChange={(data, value) => {
                                  if (data !== undefined) {
                                    Setlegalstate(true);
                                    setCurrentEntity(data);
                                    Setshowdata({
                                      ...data,
                                      legal_entity: data,
                                      domain: "",
                                      unit: "",
                                      frequency: "",
                                      asignee: "",
                                      due_month: "",
                                    });
                                  } else {
                                    Setlegalstate(false);
                                    setCurrentEntity("");
                                    Setshowdata({
                                      ...data,
                                      legal_entity: "",
                                      domain: "",
                                      unit: "",
                                      frequency: "",
                                      asignee: "",
                                      due_month: "",
                                    });
                                  }
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                value={showdata.legal_entity || undefined}
                                style={{ width: "100%", marginTop: "5px" }}
                              >
                                {sessionArr &&
                                  sessionArr.length > 0 &&
                                  sessionArr.map((item, i) => {
                                    return (
                                      <Option key={item.le_id}>
                                        {item.le_name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              {formValidator.current.message(
                                "legal_entity",
                                showdata.legal_entity,
                                "required",
                                {
                                  className: `invalid-feedback ${FormShow ? "show" : "hide"
                                    }`,
                                  messages: {
                                    required: "Legal Entity Required",
                                  },
                                }
                              )}
                            </div>
                          ) : (
                            <p title={localStorage.getItem('SelectedEntity')} className='cut-text-word' style={{ width: "100%", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                          )}
                          {/* </div> */}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Domain: </b>
                            </label>
                            <span style={{ color: "red" }}> *</span>
                            <br />
                            <Select
                              getPopupContainer={trigger => trigger.parentNode}
                              allowClear={true}
                              size="default"
                              name="domain"
                              id="domain"
                              placeholder="Enter domain"
                              onPaste={(e) => {
                                handlePasting(e, /^[a-z 0-9]+$/gi)
                              }}
                              onChange={(data, value) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    domain: data,
                                    domainName: value.children,
                                    unit: "",
                                    frequency: "",
                                    asignee: "",
                                    due_month: "",
                                    act: "",
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    domain: "",
                                    unit: "",
                                    frequency: "",
                                    asignee: "",
                                    due_month: "",
                                    act: "",
                                  });
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.domain || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                              disabled={
                                showdata.legal_entity === "" ? true : false
                              }
                            >
                              {userunitsrecall &&
                                userunitsrecall.domains_list &&
                                userunitsrecall.domains_list.map((item, i) => {
                                  return (
                                    <Option key={item.d_id}>
                                      {item.d_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {formValidator.current.message(
                              "domain",
                              showdata.domain,
                              ["required"],
                              {
                                className: `invalid-feedback ${FormShow ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Domain Required",
                                },
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Vendor: </b>
                            </label>
                            <br />
                            <Select
                              getPopupContainer={trigger => trigger.parentNode}
                              allowClear={true}
                              size="default"
                              placeholder="Enter vendor"
                              onPaste={(e) => {
                                handlePasting(e, /^[a-z 0-9 , . - _]+$/gi)
                              }}
                              onChange={(data) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    unit: data,
                                    frequency: "",
                                    due_month: "",
                                    act: "",
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    unit: "",
                                    frequency: "",
                                    due_month: "",
                                    act: "",
                                  });
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.unit || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                              disabled={
                                showdata.legal_entity === "" ? true : false
                              }
                            >
                              {userunitsrecall &&
                                userunitsrecall.user_units &&
                                userunitsrecall.user_units.map((item, i) => {
                                  return (
                                    <Option key={item.unit_id}>
                                      {item.unit_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Frequency: </b>
                            </label>

                            <Select defaultValue="All"
                              getPopupContainer={trigger => trigger.parentNode}
                              allowClear={true}
                              size="default"
                              // placeholder="Enter frequecy"
                              onChange={(data, value) => {

                                setAll(value.children === undefined ? 'All' : value.children)
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    frequency: data,
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    frequency: "",
                                  });
                                }
                              }}
                              // showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.frequency || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              <Option key={0}>All</Option>
                              {userunitsrecall &&
                                userunitsrecall.frequency_list &&
                                userunitsrecall.frequency_list.map((item, i) => {
                                  return (
                                    <Option key={item.frequency_id}>
                                      {item.frequency_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-md-6" >
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Due Month: </b>
                            </label>{" "}
                            <span style={{ color: "red" }}>*</span>
                            {due == false ? (
                              <DatePicker
                                getPopupContainer={trigger => trigger.parentNode}
                                popupStyle={{
                                  height: "150px",
                                  marginTop: "5px",
                                }}
                                onKeyDown={(event) => {
                                  event.preventDefault()
                                }}
                                format={"MMMM-YYYY"}
                                disabled={showdata.domain === "" ? true : false}
                                style={{ width: "100%", marginTop: "5px" }}
                                allowClear={false}

                                onChange={(date, dateString) => {

                                  setDue(true);
                                  Setshowdata({
                                    ...showdata,
                                    due_month:
                                      moment(dateString).format("MMMM-YYYY"),
                                  });
                                }}
                                picker="month"
                                value={
                                  showdata.due_month
                                    ? moment(showdata.due_month)
                                    : ""
                                }
                                monthCellRender={(current) => {
                                  const style = {};
                                  if (
                                    moment(current).format("MMM-YYYY") ===
                                    moment().format("MMM-YYYY")
                                  ) {
                                    // style.border = '1px solid #1890ff';
                                    style.borderRadius = "2px";
                                    style.lineHeight = "24px";
                                    style.minWidth = "24px";
                                    style.position = "relative";
                                    style.display = "inlineBlock";
                                    style.backgroundColor = "#1890ff";
                                    style.color = "white";
                                    style.marginTop = "5px";
                                  }
                                  return (
                                    <div
                                      className="ant-picker-cell-inner"
                                      style={style}
                                    >
                                      {moment(current).format("MMM")}
                                    </div>
                                  );
                                }}
                              />
                            ) : (
                              <DatePicker
                                getPopupContainer={trigger => trigger.parentNode}
                                popupStyle={{
                                  height: "150px",
                                  width: "280px",
                                  marginTop: "5px",
                                }}
                                format={"MMMM-YYYY"}
                                disabled={showdata.domain === "" ? true : false}
                                style={{
                                  width: "100%",
                                  marginTop: "5px",
                                }}
                                allowClear={false}
                                onChange={(date, dateString) => {
                                  setDue(true);
                                  Setshowdata({
                                    ...showdata,
                                    due_month:
                                      moment(dateString).format("MMMM-YYYY"),
                                  });
                                }}
                                picker="month"
                                value={
                                  showdata.due_month
                                    ? moment(showdata.due_month)
                                    : ""
                                }
                              />
                            )}
                            {formValidator.current.message(
                              "date",
                              showdata.due_month,
                              ["required"],
                              {
                                className: `invalid-feedback ${FormShow ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Due Month Required",
                                },
                              }
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">
                              <b>Act: </b>
                            </label>
                            <Select
                              getPopupContainer={trigger => trigger.parentNode}
                              allowClear={true}
                              size="default"
                              placeholder="Enter act"
                              onPaste={(e) => {
                                handlePasting(e, /^[a-z 0-9 () - _ , .]+$/gi)
                              }}
                              onChange={(data) => {
                                if (data !== undefined) {
                                  Setshowdata({
                                    ...showdata,
                                    act: data,
                                  });
                                } else {
                                  Setshowdata({
                                    ...showdata,
                                    act: "",
                                  });
                                }
                              }}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              value={showdata.act || undefined}
                              style={{ width: "100%", marginTop: "5px" }}
                              disabled={
                                showdata.due_month === "" ? true : false
                              }
                            >
                              {userunitsrecall &&
                                userunitsrecall.act_list &&
                                userunitsrecall.act_list.map((item, i) => {
                                  return (
                                    <Option key={item.parent_id}>
                                      {item.statutory_mapping}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: '10px' }}>
                        {/* <div className="col-md-6">
                          <label htmlFor="">
                            <b>Assignee: </b>
                          </label>
                          <br />
                          <Select
                            getPopupContainer={trigger => trigger.parentNode}
                            allowClear={true}
                            size="default"
                            name="domain"
                            id="domain"
                            placeholder="Enter Assignee"
                            showSearch
                            optionFilterProp="children"
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9 -]+$/gi)
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: "100%", marginTop: "5px" }}>
                            <Option>AS121-Assigne</Option>
                            <Option>4567-Thyaguu</Option>
                            <Option>3948-View Only</Option>
                          </Select>
                        </div> */}
                        <div className="col-md-12 text-center popupbtncolour mt-2" style={{ marginTop: '27px' }}>
                          <Button
                            type="primary"
                            shape="round"
                            className={"addbutton  " + localStorage.getItem('currentTheme')}
                            style={{ 'margin-top': '27px' }}
                            size="default"
                            icon={<EyeOutlined />}
                            onClick={gettaskrecalllist}>
                            Show
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-12 mt-1 popupbtncolour text-center">

                      </div>
                    </Modal>
                    <div
                      className="row"
                      style={{ textAlign: "center", marginLeft: "38%" }}
                    >
                      <div className="col-md-4">
                        {updatedArray.length < count ? (
                          <Button
                            type="primary"
                            shape="round"
                            className="showbutton"
                            style={{
                              background: "#0096FF",
                              borderColor: "#0096FF",
                              marginLeft: "20%",
                              marginTop: "5%",
                            }}
                            size="default"
                            onClick={gettaskrecalllistshowmore}
                          >
                            Show More{" "}
                            <i>
                              {" "}
                              <DownOutlined />
                            </i>
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {temp && temp.length > 0 ? (
                      <>
                        <div>
                          <div
                            className="col-md-12"
                            style={{ marginTop: "10px" }}
                          >
                            <div className="row">
                              {/* <label htmlFor="" style={{ marginLeft: '3px' }}>Total Compliance : {count}</label> */}

                              <div
                                className="col-md-4"
                                style={{ marginLeft: "15px" }}
                              >
                                {/* <label>
                                  Total Compliance : <span>{count}</span>
                                </label> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            {callername && callername.login_response && callername.login_response.usr_cat_id === 1 || callername && callername.login_response && callername.login_response.usr_cat_id === 3 || callername.login_response && callername.login_response.usr_cat_id === 4 ?
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="">
                                    <b>Action : </b>
                                  </label>
                                  <span style={{ color: "red" }}> *</span>
                                  <Select
                                    name="action"
                                    className="select2 form-control custom-select bg-white"
                                    id="action"
                                    placeholder="Please Select"
                                    defaultValue={"Please Select"}
                                    onChange={(e) => {
                                      Setrecalldata({
                                        ...recalldata,
                                        action: e,
                                      });
                                    }}
                                  >
                                    <Option value=""> Please Select </Option>
                                    <Option value="1">
                                      {" "}
                                      Rectify & Retain Document{" "}
                                    </Option>
                                    <Option value="2">
                                      {" "}
                                      Rectify & Remove Document{" "}
                                    </Option>
                                  </Select>
                                  {recallSubmit.current.message(
                                    "action",
                                    recalldata.action,
                                    ["required"],
                                    {
                                      className: `invalid-feedback ${FormRecall ? "show" : "hide"
                                        }`,
                                      messages: {
                                        required:
                                          "Please select Action to Recall",
                                      },
                                    }
                                  )}
                                </div>
                              </div> : null}
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="">
                                  <b>Remarks : </b>
                                </label>
                                <span style={{ color: "red" }}> *</span>
                                <div class="d-flex align-items-end">
                                  <TextArea
                                    name="remarks"
                                    className="form-control"
                                    placeholder="Enter Remarks"
                                    value={recalldata.remarks || undefined}
                                    maxLength={500}
                                    id="remarks"
                                    cols="5"
                                    rows="1"
                                    onKeyPress={(event) => {
                                      if (! /^[A-Z0-9a-z _ .,-]+$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      Setrecalldata({
                                        ...recalldata,
                                        remarks: e.target.value,
                                      });
                                    }}
                                  ></TextArea>
                                  <span
                                    className="ms-1 text-info d-flex 
                                align-items-center justify-content-center"
                                    style={{ float: "right" }}
                                    onClick={() => {
                                      showRemarksModal();
                                    }}
                                  >
                                    <PlusCircleOutlined />
                                  </span>
                                </div>
                                {recallSubmit.current.message(
                                  "remarks",
                                  recalldata.remarks,
                                  ["required", { regex: /^[A-Z0-9a-z _ .,-]+$/ }],
                                  {
                                    className: `invalid-feedback ${FormRecall ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Remarks Required",
                                      regex: "Enter Valid Remarks"
                                    },
                                  }
                                )}
                              </div>
                            </div>
                            {/* <div className="col-md-1 ml25">
                                                <PlusCircleOutlined onClick={() => { showRemarksModal() }} />
                                            </div> */}

                            <Modal
                              title="Remarks List"
                              className={
                                "remark-header modelradius add-service-prv " +
                                localStorage.getItem("currentTheme")
                              }
                              visible={remarksModal}
                              footer={null}
                              onOk={handleOk}
                              onCancel={handleCancel}
                            >
                              <Table
                                // className='userprivclass'
                                className={localStorage.getItem("currentTheme")}
                                size={"small"}
                                columns={column}
                                dataSource={
                                  remarksvalue &&
                                  remarksvalue.length > 0 &&
                                  remarksvalue
                                }
                                bordered
                                pagination={false}
                              // pagination={{
                              //     defaultPageSize: dataTableProperties.pagesize,
                              //     showSizeChanger: dataTableProperties.sizechanger,
                              //     pageSizeOptions: dataTableProperties.pageSizeOptions
                              // }}
                              />
                            </Modal>
                            <div className="col-md-3">
                              <div className="form-group">
                                {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                {/* <a href={() => false} onClick={Active} style={{ marginLeft: '10%' }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Recall</a> */}
                                <Button
                                  type="primary"
                                  shape="round"
                                  className="addbutton"
                                  style={{
                                    background: "#0096FF",
                                    borderColor: "#0096FF",
                                    // marginLeft: "10%",
                                    marginTop: "8%",
                                    // float: "right",
                                  }}
                                  size="default"
                                  onClick={Active}
                                >
                                  Recall
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              {/* <Drawer width={480} visible={showValueModal} onClose={handleCancel} title="Domain" >
                            
                                    <div className="row" style={{ padding: 0 }}>
                                        <h2>Other Details</h2>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>Vendor : </b></label>
                                                </div>
                                                <div className="col-md-6 approvetask">
                                                    <Tooltip title={valueModal.address}>
                                                        <InfoCircleOutlined />
                                                    </Tooltip>{valueModal.unit}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>Compliance Task : </b></label>
                                                </div>
                                                <div className="col-md-6 approvetask">
                                                    <Tooltip title={valueModal.compliance_description}>
                                                        <InfoCircleOutlined />
                                                    </Tooltip>{valueModal.compliance_task}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>Compliance Frequency : </b></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <span style={{ float: 'left' }}>{valueModal.compliance_frequency}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>Uploaded File Size: </b></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <span style={{ float: 'left' }}>{valueModal.document_size}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>Attached Documents : </b></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <span style={{ float: 'left' }}>{valueModal.uploaded_document}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>Task Completion Date/Document Issued Date : </b></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <span style={{ float: 'left' }}>{valueModal.document_issued_date}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>Document Reference Number : </b></label>
                                                </div>
                                                <div className="col-md-6">
                                                    <span style={{ float: 'left' }}>{valueModal.document_reference_number}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>Remarks : </b></label>
                                                </div>
                                                <div className="col-md-4">
                                                    <span style={{ float: 'left' }}>{valueModal.remarks}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>interim details : </b></label>
                                                </div>
                                                <div className="col-md-4">
                                                    <span style={{ float: 'left' }}>{valueModal.interim_details}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor=""><b>Recall Remarks : </b></label>
                                                </div>
                                                <div className="col-md-4">
                                                  
                                                </div>
                                            </div>
                                        </div>
                                      
                                    </div>
                            </Drawer> */}
              {/* <Modal
                className={
                  "add-service-prv " + localStorage.getItem("currentTheme")
                }
                title="Domain"
                visible={showValueModal}
                footer={false}
                onCancel={handleCancel}
              >
                <Descriptions
                  title="Other Details"
                  layout="vertical"
                  className="labelclass"
                >
                  <Descriptions.Item label="Vendor">
                    <Tooltip title={valueModal.address}>
                      <InfoCircleOutlined />
                    </Tooltip>
                    {valueModal.unit}
                  </Descriptions.Item>
                  <Descriptions.Item label="Compliance Task">
                    <Tooltip title={valueModal.compliance_description}>
                      <InfoCircleOutlined />
                    </Tooltip>
                    &nbsp;
                    {valueModal.compliance_task}
                  </Descriptions.Item>
                  <Descriptions.Item label="Compliance Frequency">
                    {valueModal.compliance_frequency}
                  </Descriptions.Item>
                  <Descriptions.Item label="Uploaded File Size">
                    -
                  </Descriptions.Item>
                  <Descriptions.Item label="Attached Documents">
                    {valueModal.uploaded_document}
                  </Descriptions.Item>
                  <Descriptions.Item label="Task Completion Date/Document Issued Date">
                    {valueModal.document_issued_date}
                  </Descriptions.Item>
                  <Descriptions.Item label="Document Reference Number">
                    {valueModal.document_reference_number}
                  </Descriptions.Item>
                  <Descriptions.Item label="Remarks">
                    {valueModal.remarks}
                  </Descriptions.Item>
                  <Descriptions.Item label="Interim details">
                    {valueModal.interim_details}
                  </Descriptions.Item>
                </Descriptions>
              </Modal> */}
              <Modal title="" visible={showValueModal} footer={null} cancelText={false} onCancel={handleCancel} >
                {/* <div className="row"> */}
                <div className={valueModal.is_applicable == 1 ? 'not_applicable_modal' : 'd-none'}>Not Applicable</div>
                <div className="col-md-12 d-flex" >
                  <div>
                    <button
                      type="button"
                      className="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                    >
                      <i
                        className="fas fa-list-alt"
                      ></i>
                    </button>
                  </div>
                  <div>
                    <p className='mb-1'>Unit Name :</p>
                    <h4 className='fw-bolder'> {valueModal.unit}&nbsp;<Tooltip title={valueModal.address} >
                      <ExclamationCircleTwoTone style={{ fontSize: '14px' }} />
                    </Tooltip></h4>
                  </div>
                </div>
                <div className="container py-2">
                  <div className="row">
                    <div className="col-4 border rounded-start p-2 bg-light mb-2">
                      <p className="mb-1">Compliance Task :</p>
                      <h5>{valueModal.compliance_task}&nbsp;<Tooltip placement='bottom' title={valueModal.compliance_description} >
                        <ExclamationCircleTwoTone style={{ fontSize: '12px' }} />
                      </Tooltip></h5>
                    </div>
                    <div className="col-4 border p-2 bg-light mb-2">
                      <p className="mb-1">Compliance Frequency :</p>
                      <h5>{valueModal.compliance_frequency}</h5>
                    </div>
                    <div className="col-4 border rounded-end p-2 bg-light mb-2">
                      <p className="mb-1">Uploaded File Size :</p>
                      <h5>-</h5>
                    </div>

                    <div className="col-md-12 border rounded-end p-2 bg-light mb-2">
                      <p className="mb-1">Task Completion Date/Document Issued Date :</p>
                      <h5>{valueModal.document_issued_date}</h5>
                    </div>


                    <div className="col-md-6 border rounded-start p-2 bg-light mb-2">
                      <p className='mb-1'>Attached Documents :</p>
                      <h5> {valueModal.uploaded_document}</h5>
                    </div>
                    <div className="col-md-6 border rounded-end p-2 bg-light mb-2" >
                      <p className='mb-1'>Document Reference Number :</p>
                      <h5>{valueModal.document_reference_number}</h5>
                    </div>

                    <div className="col-4 border rounded-start p-2 bg-light mb-2">
                      <p className="mb-1">Remarks History :</p>

                      {valueModal.history_details != 0 ?
                        <span className="btn btn-sm btn-light-success
                        text-success btn-circle d-flex align-items-center justify-content-center" style={{ marginLeft: '100px' }}>
                          <Tooltip title='click here to View Remarks History' ><InfoOutlined style={{ fontSize: '25px' }} onClick={() => { viewhistory(valueModal) }} /></Tooltip></span> : ''}
                    </div>
                    <div className="col-4 border p-2 bg-light mb-2">
                      <p className="mb-1">Interim Details : </p>
                      <h5>  {valueModal.interim_details != 0 ?
                        <span className="btn btn-sm btn-light-danger
                        text-danger btn-circle d-flex align-items-center justify-content-center" style={{ marginLeft: '100px' }}>
                          <Tooltip title='click here to view Interim Details' >
                            <ArrowUpOutlined style={{ fontSize: '25px' }} onClick={() => { viewinterim(valueModal) }} /></Tooltip></span> : ''}</h5>
                    </div>
                    {valueModal.compliance_frequency == 'On Occurrence' || valueModal.occurrence_remarks != null || valueModal.occurrence_remarks != '' ?
                    <div className="col-4 border p-2 bg-light mb-2">
                      <p className="mb-1">OnOccurrence Remarks : </p>
                      <h5>{valueModal.occurrence_remarks}</h5>
                    </div>
                    : false}

                    {/* <div className="col-md-6 border rounded-end p-2 bg-light mb-2">
                      <p className="mb-1">Remarks History</p>
                      <h5>{valueModal.remarks}</h5>

                    </div> */}
                    {/* <div className="col-md-6 border rounded-start p-2 bg-light mb-2">
                      <p className="mb-1">Interim Details</p>
                      <h5> {valueModal.interim_details}</h5>
                    </div> */}

                  </div>
                </div>

              </Modal>
              <Modal
                autoFocus={false}
                visible={passwordmodal}
                footer={null}
                onCancel={passwordcancel}
                className="newStyleModalPassword"
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-6">
                      <label className="control-label">Password:</label>
                      <span style={{ color: "red" }}>*</span>
                      <input
                        autoFocus={true}
                        style={{ marginTop: "10px" }}
                        type="password"
                        id="password"
                        name="passwordvalue"
                        className="form-control"
                        placeholder="Enter password"
                        maxLength={20}
                        onChange={(e) => {
                          Setpassword({
                            ...password,
                            passwordvalue: e.target.value,
                          });
                        }}
                        value={password.passwordvalue}
                      />
                      {formValidator2.current.message(
                        "Password",
                        password.passwordvalue,
                        ["required"],
                        {
                          className: `invalid-feedback ${AddFormSubmit2 ? "show" : "hide"
                            }`,
                          messages: {
                            regex: "Password Required",
                          },
                        }
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <div className={"form-actions text-center popupbtncolour " + localStorage.getItem('currentTheme')}>
                  <Button
                    type="primary"
                    shape="round"
                    className={'addbutton ' + localStorage.getItem('currentTheme')}
                    icon={<PlayCircleOutlined />}
                    size="default"
                    onClick={submitpassword}
                  >
                    Submit
                  </Button>
                  {/* <div className="button2 mt-0" id={localStorage.getItem('currentTheme')} onClick={submitpassword}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        Submit
                                    </div> */}
                  {/* <button
                                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start" onClick={passwordcancel}>
                                        <div className="d-flex align-items-center">
                                        <i data-feather="save" className="feather-sm me-1 fill-icon"></i>
                                        Cancel
                                        </div>
                                    </button> */}
                </div>
              </Modal>

              <Modal visible={historymodal}
                title="Remarks History"
                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                footer={null}
                onCancel={historycancel}>
                <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                  columns={history_columns}
                  dataSource={historydata && historydata.remarks_history}
                  pagination={false}
                />
              </Modal>

              <Modal visible={interimmodal}
                title="Interim Details"
                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                footer={null}
                onCancel={interimcancel}>
                <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                  columns={interim_columns}
                  dataSource={interimdata && interimdata}
                  pagination={false}
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
      {acccordionTrue === true &&
        filteredArrayState &&
        filteredArrayState.length > 0 ? (
        <>
          <Stickicon />
        </>
      ) : (
        false
      )}
    </div>
  );
};

export default connect(mapStateToProps, {
  TaskRecallFilters,
  GetTaskRecallList,
  GetTaskRecallListShowmore,
  GetRemarksData,
  UpdateTaskRecallStatus,
  historyrecord, interimrecord
})(TaskRecall);
