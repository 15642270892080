import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { MONTHLYCOMPLIANCEFILTER, DETAILS_MODAL, MAPPING_DETAILS, SEARCH_COMPLIANCE, DOWNLOAD_COMPLIANCE, LABEL_LIST, COMPLIANCE_CERTIFICATE_FILTER, COMPLIANCE_STATUS_MODAL, COMPLIANCE_CERTIFICATE_TABLE, TOGGLE_COMMON_LOADER, INVALID_FILE } from './../../types/index'
import alertmessage from '../../../Libs/Altermessage';
import { toast } from 'react-toastify';
const urls = getUrl()

export const getComplianceCertificateFilter = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, 'payload5555');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_user`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: COMPLIANCE_CERTIFICATE_FILTER,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getComplianceCertificateTable = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, 'payload5555');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: COMPLIANCE_CERTIFICATE_TABLE,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const viewComplianceStatus = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, 'payload5555');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: COMPLIANCE_STATUS_MODAL,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const viewComplianceDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, 'payload5555');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: DETAILS_MODAL,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const mappingDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, 'payload5555');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: MAPPING_DETAILS,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getLabelList = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, 'payload5555');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: LABEL_LIST,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const searchCompliance = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, 'payload5555');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");

                dispatch({
                    type: SEARCH_COMPLIANCE,
                    payload: decryptData[1],
                });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const submitComplianceCertificate = ({ payload, executeCancel, paramid, payloadData, setComplianceSave, setTableShow }) => async dispatch => {
    console.log(payload, '9965555555');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Unable to create certificate")
            setComplianceSave(false)
            setTableShow(true)
            // Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                console.log(decryptData, "decryptData123");
                if (decryptData[0] == "Certification Saved") {
                    setComplianceSave(false)

                    dispatch(

                        getComplianceCertificateTable({
                            payload: payloadData,
                            paramid: paramid
                        })
                    )
                    setTableShow(true)

                    Toaster.success("Certificate Saved Successfully!!")
                } else if (decryptData[0] == "TaskDetailsFailure") {
                    setComplianceSave(false)
                    dispatch(

                        getComplianceCertificateTable({
                            payload: payloadData,
                            paramid: paramid
                        }))
                    setTableShow(true)
                    Toaster.error("Not all compliance are complied for the selected month")
                } else if (decryptData[0] == "CertTaskDetailsFailure") {
                    setComplianceSave(false)
                    dispatch(

                        getComplianceCertificateTable({
                            payload: payloadData,
                            paramid: paramid
                        }))
                    setTableShow(true)
                    Toaster.error("Not all compliance are complied for the selected month")
                }

                // dispatch({
                //     type: SEARCH_COMPLIANCE,
                //     payload: decryptData[1],
                // });
            }
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const UploadFile = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
            console.log(decryptData, 'decryptData');
            if (decryptData[0] == 'FileUploadSuccess') {
                // Toaster.success("File Upload Successfully")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else if (decryptData[0] == "UnSupportedFile") {
                dispatch({
                    type: INVALID_FILE,
                    payload: decryptData[0],
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Invalid file format")
            }

            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            }
            // dispatch({
            //     type: COMPLETED_TASK_LIST,
            //     payload: decryptData,
            // });
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Downloadfile = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload, 'payload522');

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/files`,
            headers: head,
            executeCancel,
            body: data1,

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();

        } else {
            const response = HTTP.parseResponse(executeRequest)
            console.log(response, "decryptDatadecryptData5");
            var a = document.createElement('a');
            var blob = dataURItoBlob(response, 'application/octet-stream');
            a.href = window.URL.createObjectURL(blob);
            console.log(payload, 'payloadpayload123');
            console.log(payload[1].request[1].file_name, 'payload[1].request[1].file_name');
            a.download = payload[1].request[1].file_value;
            a.click();
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // var blob = dataURItoBlob(executeRequest.data, 'application/octet-stream');
            // console.log(blob, 'blobs')
            // let fileReader = new FileReader();
            // fileReader.readAsDataURL(blob)
            // fileReader.onload = function (e) {
            //     console.log(e.target.result, 'e.target.result')
            //     var link = document.createElement('a');
            //     link.setAttribute(
            //         'download',
            //     );
            //     link.click();
            //     link.parentNode.removeChild(link);
            // };
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

function dataURItoBlob(dataURI, contentType) {
    console.log(contentType, 'blobs')
    var str = dataURI.replace(/\s/g, '');
    var binary = base64_decode(str);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: contentType });
}