import { combineReducers } from 'redux';
import Login from './Login';
import Header from './Dashboard/header'
import Statutorysetting from './Transactions/statutorysettings';
import Completedtaskyear from './Transactions/Completedtaskyear';
import ComplianceApproval from './Transactions/ComplianceApproval';
import bulkupload from './Transactions/Completedtaskcurrentbulkupload';
import recallfilters from './Transactions/Taskrecall';
import Reviewsetting from './Transactions/Reviewsettings';
import Assigncompliance from './Transactions/Assigncompliance';
import ReassignComplaince from './Transactions/ReassignComplaince';
import CriticalitySettings from './Transactions/Criticalitysettings'
import UnitClosure from './Master/UnitClosure';
import Remarksmaster from './Master/Remarksmaster';
import ViewOnlyRestictedList from './Master/ViewOnlyRestictedList';
import Serviceprovider from './Master/Serviceprovider'
import Userprivileges from './Master/Userprivileges';
import ApproveTaskRecallCompliance from './Transactions/ApproveTaskRecallCompliance';
import UserManagement from './Transactions/UserManagement';
import CriticalityReport from './Report/CriticalityReport'
import MonthWiseStatusReport from './Report/MonthWiseStatusReport'
import UnitReport from './Report/UnitReport'
import UserWiseReport from './Report/UserWiseReport'
import DomainReport from './Report/DomainReport';
import TaskCategoryReport from './Report/TaskCategoryReport';
import ConsolidatedReports from './Report/ConsolidatedReports';
import TaskWiseReport from './Report/TaskWiseReport';
import RiskReport from './Report/RiskReport';
import ReassignReport from './Report/ReassignReport';
import UnitlistReport from './Report/UnitlistReport';
import LegalEntityScoreCard from './Report/LegalEntityScoreCard';
import LegalentityReport from './Report/LegalentityReport';
import StatutoryUnitWiseReport from './Report/StatutoryUnitWiseReport';
import ServiceProviderComplianceReport from './Report/ServiceProviderComplianceReport';
import TaskHistoryReport from './Report/TaskHistoryReport';
import ServiceProviderDetails from './Report/Serviceproviderdetails';
import DomainCard from './Report/DomainCard';
import AuditTrail from './Report/AuditTrail';
import NotificationReport from './Report/NotificationReport';
import Assigneescorecard from './Report/Assigneescorecard';
import ApproverScoreCard from './Report/ApproverScoreCard'
import LoginTrace from './Report/LoginTrace';
import Settings from './Settings'
import Profile from './Profile';
import CriticalityChart from './Dashboard/CriticalityChart';
import ComplianceStatusChart from './Dashboard/ComplianceStatusChart';
import TaskDistributionCharts from './Dashboard/TaskDistributionCharts';
import ComplianceTrend from './Dashboard/ComplianceTrend';
import RiskChart from './Dashboard/RiskChart';
import Overdue from './Dashboard/Overdue';
import ComparativeTrend from './Dashboard/ComparativeTrend';
import AssigneeWiseCompliance from './Dashboard/Assigneewisecompliance';
import Vendorcompliancescore from './Report/CV_VendorComplianceScore';
import VendorTempClosureReport from './Report/CV_VendorTempClosureReport'
import CV_MonthWiseStatusReport from './Report/CV_MonthWiseStatusReport'
import MonthlyComplianceReport from './Report/CV_MonthlyComplianceReport'
import CV_ComplianceCertificate from './Transactions/CV_ComplianceCertificate';
import CV_ComplianceCertificateReport from './Report/CV_ComplianceCertificate';
import ComplianceCertificate from './Report/CV_ComplianceCertificate'
import CertificateLabel from './Master/CV_CertificateLabel'
import VendorClosure from './Master/CV_VendorClosure';
import CV_ApproveCompliance from './Transactions/CV_ApproveCompliance';
import CertificateLabelMap from './Master/CV_CertificateLevelMapping';
import CertificateMailMapping from './Master/CV_CertificateMailMapping';
import EXE_OnOccurrenceCompliance from './Transactions/EXE_OnOccurrenceCompliance';
import EXE_ComplianceTaskDetails from './Transactions/EXE_ComplianceTaskDetails';
import Widgetscharts from './Dashboard';
import Widgets from './Widgets';
import EXE_Easyupload from './Transactions/EXE_EasyUpload';
import Currentstatus from './Currentstatus';


const appReducer = combineReducers({
    Login,
    Header,
    Statutorysetting,
    Reviewsetting,
    Completedtaskyear,
    ComplianceApproval,
    Assigncompliance,
    bulkupload,
    recallfilters,
    ReassignComplaince,
    CriticalitySettings,
    UnitClosure,
    Remarksmaster,
    ViewOnlyRestictedList,
    Serviceprovider,
    Userprivileges,
    ApproveTaskRecallCompliance,
    UserManagement,
    MonthWiseStatusReport,
    DomainReport,
    TaskCategoryReport,
    ConsolidatedReports,
    TaskWiseReport,
    UserWiseReport,
    UnitlistReport,
    ServiceProviderComplianceReport,
    CriticalityReport,
    MonthWiseStatusReport,
    UnitReport,
    RiskReport,
    ReassignReport,
    StatutoryUnitWiseReport,
    TaskHistoryReport,
    LegalEntityScoreCard,
    LegalentityReport,
    StatutoryUnitWiseReport,
    ServiceProviderDetails,
    DomainCard,
    AuditTrail,
    NotificationReport,
    Assigneescorecard,
    LoginTrace,
    Profile,
    Settings,
    ApproverScoreCard,
    CriticalityChart,
    ComplianceStatusChart,
    TaskDistributionCharts,
    ComplianceTrend,
    RiskChart,
    Overdue,
    ComparativeTrend,
    AssigneeWiseCompliance,
    Vendorcompliancescore,
    VendorTempClosureReport,
    CV_MonthWiseStatusReport,
    MonthlyComplianceReport,
    CV_ComplianceCertificate,
    CV_ApproveCompliance,
    CV_ComplianceCertificateReport,
    ComplianceCertificate,
    CertificateLabel,
    VendorClosure,
    CertificateLabelMap,
    CertificateMailMapping,
    EXE_OnOccurrenceCompliance,
    EXE_ComplianceTaskDetails,
    Widgetscharts,
    Widgets,
    EXE_Easyupload,
    Currentstatus

});

export default (state, action) => {
    return appReducer(state, action);
};