import { Card, Tabs, Collapse, Select, Modal, Table, Button, Input, Tooltip, DatePicker, Statistic, Row, Col, Tag } from 'antd';
import React, { useDebugValue } from 'react'
import { useState, useEffect, useRef, Fragment } from 'react';
import DataTable from "react-data-table-component";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ArrowRightOutlined, ExclamationCircleTwoTone, CheckOutlined, BookTwoTone, ClockCircleOutlined, LayoutOutlined, CloseOutlined, ContainerOutlined, DeploymentUnitOutlined, ArrowLeftOutlined, PlayCircleOutlined, EyeOutlined, DownOutlined, ForwardFilled, FullscreenOutlined, UpOutlined } from '@ant-design/icons';
import { assign, selectunit, table, tabledata, selectassigneedata, showmoredata, saveassigncomliance, syncassigncomliance } from "../../../src/Store/Action/Transcations/Assigncompliance"
import moment from 'moment';
import { toast } from 'react-toastify';
// import ScrollToTop from "react-scroll-to-top";
import { entries } from 'lodash';
import FeatherIcon from 'feather-icons-react';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';
import Stickicon from "./Stickyicon";
import { Toaster } from '../../Libs/Toaster';
import { HomeRedirect } from './../../Libs/country'
import { UNITLIST } from '../../Store/types';
const homepage = HomeRedirect()

const _ = require("lodash");
const mapStateToProps = (state) => ({
    unitlist: state.Assigncompliance.assigncompliance
});
const Compliance = ({
    assign,
    selectunit,
    table,
    tabledata,
    showmoredata,
    selectassigneedata,
    saveassigncomliance,
    syncassigncomliance,
    unitlist
}) => {
    console.log(unitlist, "unitlist111")
    const [uniaddress, setUnitAddress] = useState([])
    const [unitnamemodal, setUnitnameModal] = useState([])
    const [tempshowmoredatalist, setTempShowmoredatalist] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [allselect, setAllSelect] = useState(false)
    const [childValue, setChildValue] = useState([])
    const { Option } = Select;
    const { Panel } = Collapse;

    const { TabPane } = Tabs;
    const [row, setRow] = useState(false)
    const currentdate = new Date();
    const [collapseAssignee, setCollapseAssignee] = useState(0)
    const [collapseAssignee1, setCollapseAssignee1] = useState(false)
    const location = useLocation();
    const [triggerDays, setTriggerDays] = useState([])
    console.log(triggerDays, 'triggerDays');
    const paramid = location.pathname;
    const [keys, setKeys] = useState("1")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const SelectedEntity = localStorage.getItem('SelectedEntity');
    const sessionValue = localStorage.getItem('sessionValue')
    const [editValue, setEditValue] = useState([])
    console.log(editValue, "editValueeditValue");
    const [updateValue, setUpdateValue] = useState([])
    const [updateDate, setUpdateDate] = useState([])
    console.log(updateDate, "updateDateupdateDate");
    const [EditDate, setEditDate] = useState([])
    console.log(EditDate, "EditDateEditDate");
    const [triggerValidation, setTriggerValidation] = useState([])
    const [dateValidation, setTriggerDateValidation] = useState([])
    console.log(dateValidation, 'triggerValidation');
    let [count, setCount] = useState(1)
    const [FormSubmit, setFormSubmit] = useState([])
    const [childId, setChildId] = useState([])
    console.log(childId, "childIdchildId");
    const [entityid, setCurrentEntity] = useState('');
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const FormValidator = useRef(new SimpleReactValidator());
    // const entityid = localStorage.getItem('SelectedEntityid');
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [frequencyid, setFrequencyId] = useState([])
    const [unitid, setUnitId] = useState([])
    const [complianceName, setComplianceName] = useState([])
    console.log(complianceName, 'complianceName');
    const [domain, setDomain] = useState([])
    console.log(domain, 'domain123456');
    const [select, setSelect] = useState([])
    const [pushdata, setPushData] = useState([])
    const [selectunit1, setSelectUnit1] = useState([])
    const [edit, setEdit] = useState([])
    const [temp, setTemp] = useState({
        tempData: [],
        parentIndexId: []
    });
    const [freqid, setFreqid] = useState([])
    const [uid, setUId] = useState([])
    const [collapseindex, setCollapseIndex] = useState([])
    const [set, SetSet] = useState([])
    const [triggervalue, SetTriggerValue] = useState([])
    const [key, setKey] = useState("1")
    const [header, setHeader] = useState([])
    console.log(header, "header1111")
    const [updatedateedit, SetUpdateDateEdit] = useState([])
    console.log(updatedateedit, "updatedateeditupdatedateedit");
    const [dueDate, setDueDate] = useState([])
    const [validityDate, setValitityDate] = useState([])
    console.log(validityDate, 'validityDate');
    const [updatedata, setUpdateData] = useState([])
    console.log(updatedata, "updatedataupdatedata");
    const [temparr, setTemparr] = useState([])
    console.log(temparr, 'temparr99999');
    const [totalrow, setTotalRow] = useState([])
    const [date, setDate] = useState([])
    console.log(date, "datedatedate");
    const [headerlist, setHeaderList] = useState([])
    console.log(headerlist, "headerlisttttt")
    const [category, setCategory] = useState([])
    const [compliancefrequency, setComplianceFrequency] = useState([])
    const [totaltabledata, setTotalTableData] = useState([])
    const [updatedays, setUpdatedays] = useState([])
    const [assign_statutory, setAssign_statutory] = useState([])
    const [showmoredatalist, setShowMoreDataList] = useState([])
    const [unitname, setUnitname] = useState([])
    console.log(unitname, "unitnameeee")
    const [approvaldrop, setApprovaldrop] = useState([])
    console.log(approvaldrop, "approvaldrop")
    const [assigneedrop, setAssigneedrop] = useState([])
    console.log(assigneedrop, 'assigneedrop');
    const [approvalsearch3, setApprovalsearch3] = useState([])
    const [assigneesearch3, setAssigneesearch3] = useState([])
    const [assignpayload, setAssignPayload] = useState([])
    console.log(assignpayload, "assignpayload")
    const [triggerindex, setTriggerIndex] = useState([])
    const [updatedaysindex, setupdatedaysIndex] = useState([])
    const [updateeditindex, setUpdateeditindex] = useState([])
    const [dateindex, setDateIndex] = useState([])
    const [rowcompid, setRowCompId] = useState([])
    const [boolean, setBoolean] = useState([])
    const [list, setList] = useState([])
    console.log(list, 'listlistlist');
    const [datacount, setdatacount] = useState(null)
    const [entires, setEntires] = useState(null)
    const [showmore1, setShowMore1] = useState(false)
    const [checkedRow, setCheckedRow] = useState(false);
    const [countvalue, setCountvalue] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [daysFormSubmit, setdaysFormSubmit] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [approvalonclick, setApprovalOnclick] = useState(false)
    const [tableclick, setTableclick] = useState(false)
    const [assigneeonclick, setAssigneeOnclick] = useState(false)
    const [editpayload, setEditPayload] = useState(false)
    const [rowpayload, setRowPayload] = useState(false)
    const [diffid, setDiffId] = useState([])
    const [statisticentity, setStatisticentity] = useState(localStorage.getItem('SelectedEntity') != "All Legal Entity" ? localStorage.getItem('SelectedEntity') : sessionArr)
    console.log(statisticentity, 'statisticentity');
    const [triggerstate, setTriggerstate] = useState(false)
    const [editclick, setEditClick] = useState({
        boolean: false,
        comp_id: []
    })
    const [monthState, setMonth] = useState()
    const [closeicon, setCloseIcon] = useState({
        comp_id: []
    })
    // const [editclick, setEditClick] = useState(false)
    const [editicon, setEditIcon] = useState([])
    // const [editclickdate, setEditClickDate] = useState(false)
    const [editclickdate, setEditClickDate] = useState({
        comp_id: [],
        boolean: false
    })
    console.log(editclickdate, "editclickdateeditclickdate");
    const [data, setData1] = useState();
    const [categoryname, setCategoryName] = useState("")
    const [divisionlist, setDivisionlist] = useState("")
    console.log(divisionlist, "divisionlist");
    const [clickassignee, setClickassginee] = useState({
        assignee: "",
        approval: "",
        approval_id: null,
        assignee_id: null
    })
    console.log(clickassignee, "clickassignee")
    const [assigneelist, setAssigneelist] = useState({
        assign_id: null,
        approval_id: null,
        assignee: "",
        approval: "",
        restricted: "",
        vor_id: ''
    })

    console.log(assigneelist, "assigneelist123")
    const [data1, setData] = useState({
        legalentity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        division: "",
        category: "",
        domain: "",
        unit1: "",
        compliance_frequency: "",
        timezone: "",
        vor_id: '',
        domainid: ''
    })
    console.log(data1,'ggggggg')


    const [assigneeid, setAssigneeId] = useState([])

    const [approvalid, setApprovalId] = useState([])



    const [fullscreen, setfullscreen] = useState(false);
    console.log(dueDate, 'dueDate');
    /* Selected Compliance State make false on 0 Checkbox selected */
    useEffect(() => {
        if (temparr && temparr.length == 0) {
            setCollapseAssignee1(false)
        }
    }, [temparr])
    /* Selected Compliance State make false on 0 Checkbox selected */
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    useEffect(() => {
        if (unitlist && unitlist.division && unitlist.division.length > 0) {
            let uniqueObjArray = [
                ...new Map(
                    unitlist.division &&
                    unitlist.division.length > 0 &&
                    unitlist.division.map((item) => [item["div_id"], item])
                ).values(),
            ];
            const uniqueIds =
                uniqueObjArray &&
                uniqueObjArray.map((item) => {
                    return item
                });
            setDivisionlist(uniqueIds)
        }
    }, [unitlist.division]);

    useEffect(() => {
        if (data1.legalentity) {
            let uniqueIds;
            if (unitlist && unitlist.division && unitlist.division.length > 0) {
                let uniqueObjArray = [
                    ...new Map(
                        unitlist.division &&
                        unitlist.division.length > 0 &&
                        unitlist.division.map((item) => [item["div_id"], item])
                    ).values(),
                ];
                uniqueIds =
                    uniqueObjArray &&
                    uniqueObjArray.map((item) => {
                        return item
                    });
            }
            const divisionvalue = _.filter(uniqueIds, { le_id: data1.legalentity })
            setDivisionlist(divisionvalue)
        }

    }, [data1.legalentity])

    useEffect(() => {
        if (assigneedrop) {
            let filterrr = assigneedrop.filter((item) => {
                return item.emp_code === assigneelist.assign_id
            })
            setAssigneeId(filterrr && filterrr[0] && filterrr[0].usr_id)
        }
        if (approvaldrop) {
            let filterrr1 = approvaldrop.filter((item) => {
                if (assigneelist.approval_id == null || assigneelist.approval_id == '' || assigneelist.approval_id == 'null') {
                    return item.emp_name === assigneelist.approval
                } else {
                    return item.emp_code === assigneelist.approval_id
                }

            })
            setApprovalId(filterrr1 && filterrr1[0] && filterrr1[0].usr_id)

        }

    }, [assigneedrop, assigneelist, approvaldrop])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            if (unitlist && unitlist.domain && unitlist.domain.length > 0) {
                let domainData = unitlist && unitlist.domain && unitlist.domain.length > 0 && unitlist.domain.filter((item) => {
                    return item.le_id == localStorage.getItem('SelectedEntityid')
                })
                setDomain(domainData)
            }
        }
    }, [localStorage.getItem('SelectedEntity') != "All Legal Entity", unitlist])

    useEffect(() => {
        let temp2 = []
        let temp3 = []
        let temp4 = []
        if (childId.length > 0) {
            for (let i in childId) {
                if (childId[i].frequencyName !== 'On Occurrence') {
                    temp2.push(
                        // comp_id: childId[i].comp_id,
                        triggerDays[childId[i].id]
                    )

                    if (triggerDays[childId[i].id] == 0 || triggerDays[childId[i].id] == '' || triggerDays[childId[i].id] == null || dueDate[childId[i].id] == '') {
                        temp3.push(childId[i].name)
                    }
                    if (dueDate[childId[i].id] == '') {
                        temp4.push(childId[i].name)
                    }
                    console.log(temp2, 'ttttttttttttttttt');
                    console.log(temp3, 'temp3');
                }
                setTriggerValidation([...temp2])
                setComplianceName([...temp3])
                setTriggerDateValidation([...temp4])
            }
        }
        else {
            setTriggerValidation([])
            setComplianceName([])
            setTriggerDateValidation([])
        }
    }, [childId.length, triggerDays, dueDate])
    const columns = [
        {
            title: 'Compliance Task',
            width: "220px",
            ellipsis: false,
            render: (record) => {

                if (record.compfielaw_url !== "") {
                    <span style={{ marginLeft: '3px' }}> <ExclamationCircleTwoTone /></span>
                    return <><Tooltip title={`${record.descp}`} >
                        <span style={{ marginLeft: '3px' }}> <ExclamationCircleTwoTone /></span>
                    </Tooltip>&nbsp;
                        <Tooltip title={"Compfie Law Knowledge Portal"}>
                            <a href={`${record.compfielaw_url}`}>
                                <BookTwoTone />
                            </a>
                        </Tooltip>&nbsp;

                        <span style={{ marginLeft: '5px' }}>{record.comp_name}</span></>
                }
                else {
                    <span style={{ marginLeft: '3px' }}> <ExclamationCircleTwoTone /></span>
                    return <><Tooltip title={`${record.descp}`} >
                        <span style={{ marginLeft: '3px' }}> <ExclamationCircleTwoTone /></span>
                    </Tooltip>
                        <span style={{ marginLeft: '5px' }}>{record.comp_name}</span></>
                }


            },
        },
        {
            title: <lable><span title='Applicable Vendors'> Vendors</span></lable>,
            dataIndex: false,
            ellipsis: true,
            width: '30px',
            align: "center",
            render: (row, record) => {
                return (
                    <Link style={{ padding: "0px 0px" }} onClick={(e) => {
                        showModal()
                        let temp2 = []
                        let temp3 = []
                        for (let i in record.applicable_units) {
                            let address = uid.filter((item) => {
                                return item.u_id === record.applicable_units[i]
                            })
                            for (let j in address) {
                                temp2.push(address[j].address)
                                temp3.push(address[j].u_name)
                            }
                        }
                        setUnitAddress([...temp2])
                        setUnitnameModal([...temp3])
                    }}>{`${record.applicable_units.length}/${data1.unit1.length}`}</Link>

                )
            }
        },
        // {
        //     title: 'Compliance Frequency',
        //     dataIndex: 'Compliance Frequency',
        //     key: 'freq',
        //     width: "110px",
        //     align: "center",
        //     render: (row, record, index) => {

        //         if(record.freq == 'Review') {
        //             return <Tooltip title={record.freq}><p><i style={{ color: "orange" }} className="bi bi-square-fill"></i></p></Tooltip>
        //         }else if(record.freq == 'Periodical'){
        //             return <Tooltip title={record.freq}><p><i style={{ color: "green" }} className="bi bi-square-fill"></i></p></Tooltip>
        //         }else if(record.freq == 'Flexi Review'){
        //             return <Tooltip title={record.freq}><p><i style={{ color: "#F78453" }} className="bi bi-square-fill"></i></p></Tooltip>
        //         }else if(record.freq == 'On Occurance'){
        //             return <Tooltip title={record.freq}><p><i style={{ color: "#FAF329" }} className="bi bi-square-fill"></i></p></Tooltip>
        //         }else{
        //             return <Tooltip title={record.freq}><p><i style={{ color: "#F21625" }} className="bi bi-square-fill"></i></p></Tooltip>
        //         }


        //     }
        //     // record && record.comp_name
        // },
        {
            title: 'Statutory Date/Schedule',
            ellipsis: false,
            width: "60px",
            align: 'center',
            render: (row, record, index) => {
                let today_date1 = new Date().toISOString().slice(0, 10)
                let today_date = moment(today_date1).format("MMM-DD")
                // return (
                //     record.r_every != null && record.repeat_by != null ?


                //         <>{record.summary}({record.due_date_list[0] != "" ? moment(record.due_date_list[0]).format("MMM-DD") : today_date})</> : <>{record.summary}</>




                // )
                return (

                    ((record.r_every != null) && (record.repeat_by != null) && (record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].statutory_date != null)) ?

                        <>{record.summary}({record.due_date_list[0] != "" ? moment(record.due_date_list[0]).format("MMM-DD")

                            : today_date})

                        </> : <>{record.summary}</>

                )
            }
            // record && record.comp_name
        },
        {
            title: <label><span>Trigger Before (Days)</span><span style={{ color: "red", marginLeft: "5px" }}>*</span></label>,
            dataIndex: 'Trigger Before (Days)',
            key: 'statu_dates[0].statutory_date',
            width: "50px",
            align: 'center',
            render: (row, record, index) => {
                return (
                    <>
                        {record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days == null || record && record.statu_dates && record.statu_dates == "" ?
                            <div>
                                <Input

                                    autocomplete="off"
                                    hidden={record.freq == "On Occurrence"}
                                    name='triggerInput'
                                    maxLength={3}

                                    style={{ width: "60px" }}
                                    // disabled={temparr && temparr.length && temparr.filter((e) => e && e.comp_id == record.comp_id).length > 0 ? false : true}
                                    className="form-control" placeholder='Days'
                                    onBlur={(e) => {

                                        let temp = []
                                        let temp2 = []
                                        let temp3 = editValue
                                        for (let i in temp3) {
                                            if (temp3[i].comp_id == record.comp_id) {
                                                temp3.splice(i, 1)
                                            }
                                        }
                                        temp2.push({
                                            comp_id: record.comp_id,
                                            value: e.target.value,
                                            index: index
                                        })
                                        temp.push(e.target.value)
                                        // if (e.target.value == '0') {
                                        //     toast.error("Trigger before days should be 1 to 100")
                                        // }
                                        if (Number(temp) < 101) {
                                            SetTriggerValue([...triggervalue, ...temp])
                                            setEditValue([...temp3, ...temp2])
                                            setEditPayload(true)
                                            setTriggerstate(false)
                                        } else {
                                            toast.error("Trigger before days should not exceed 100")
                                            setTriggerstate(true)
                                        }
                                        triggerDays[record.childId] = e.target.value
                                        setTriggerDays([...triggerDays])
                                    }}

                                ></Input>
                                {/* {FormValidator.current.message(
                                    'trigger_before',
                                    editValue[index],
                                    'required',

                                    {
                                        className: `invalid-feedback ${daysFormSubmit ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Trigger Before Days Required',


                                        }
                                    })} */}
                            </div>
                            :
                            <>
                                <label>{record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days}<span style={{ marginLeft: "5px" }}>Day(s)</span> <i className="ri-edit-line" style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        let temp = []
                                        temp.push(record.comp_id)
                                        setEditClick({
                                            boolean: true,
                                            comp_id: [...editclick.comp_id, ...temp]
                                        })
                                        // setEditClick(true)
                                    }}></i> </label>
                                {editclick && editclick.comp_id.filter((e) => e == record.comp_id).length > 0 ?

                                    // {/* {editclick.boolean == true && editclick && editclick.comp_id.filter((e) => e.comp_id === record.comp_id) && temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ? */ }
                                    <>
                                        <br />
                                        <Input
                                            // name='triggerInputUpdate' 
                                            className="form-control"
                                            maxLength={3}
                                            style={{ width: "50px" }} placeholder=''
                                            hidden={record.freq == "On Occurrence"}
                                            defaultValue={record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days}
                                            autocomplete="off"
                                            autoFocus={true}
                                            onBlur={(e) => {
                                                let text = record.summary;
                                                const myArray = text.split(" ");
                                                let tempupdate = []
                                                let temp2 = []
                                                tempupdate.push(e.target.value)
                                                temp2.push({
                                                    comp_id: record.comp_id,
                                                    value: e.target.value,
                                                    index: index
                                                })
                                                let temp = updateValue

                                                for (let i in temp) {
                                                    if (temp[i].comp_id == record.comp_id) {
                                                        temp.splice(i, 1)
                                                    }
                                                }
                                                // if (e.target.value == '0') {
                                                //     toast.error("Trigger before days should be 1 to 100")
                                                // }
                                                if (record.freq == 'One Time' || record.freq == 'On Occurrence') {
                                                    if (Number(tempupdate) < 101) {
                                                        setUpdatedays([...updatedays, ...tempupdate])
                                                        setUpdateValue([...temp, ...temp2])
                                                        setTriggerstate(false)
                                                    } else {
                                                        toast.error("Trigger before days should not exceed 100")
                                                        setTriggerstate(true)
                                                    }
                                                } else {
                                                    let k = record.summary
                                                    if (k.includes('Day') == true) {
                                                        if (Number(tempupdate) <= Number(myArray[2])) {
                                                            setUpdatedays([...updatedays, ...tempupdate])
                                                            setUpdateValue([...temp, ...temp2])
                                                            setTriggerstate(false)
                                                        } else {
                                                            toast.error("Trigger before days should not exceed repeats every value")
                                                            setTriggerstate(true)
                                                        }
                                                    } else if (k.includes('Month') == true) {
                                                        if (k.includes('1') == true) {
                                                            if (Number(tempupdate) <= 30) {
                                                                setUpdatedays([...updatedays, ...tempupdate])
                                                                setUpdateValue([...temp, ...temp2])
                                                                setTriggerstate(false)
                                                            } else {
                                                                toast.error("Trigger before days should not exceed repeats every value")
                                                                setTriggerstate(true)
                                                            }
                                                        } else if (k.includes('2') == true) {
                                                            if (Number(tempupdate) <= 60) {
                                                                setUpdatedays([...updatedays, ...tempupdate])
                                                                setUpdateValue([...temp, ...temp2])
                                                                setTriggerstate(false)
                                                            } else {
                                                                toast.error("Trigger before days should not exceed repeats every value")
                                                                setTriggerstate(true)
                                                            }
                                                        } else if (k.includes('3') == true) {
                                                            if (Number(tempupdate) <= 90) {
                                                                setUpdatedays([...updatedays, ...tempupdate])
                                                                setUpdateValue([...temp, ...temp2])
                                                                setTriggerstate(false)
                                                            } else {
                                                                toast.error("Trigger before days should not exceed repeats every value")
                                                                setTriggerstate(true)
                                                            }
                                                        } else {
                                                            if (Number(tempupdate) < 101) {
                                                                setUpdatedays([...updatedays, ...tempupdate])
                                                                setUpdateValue([...temp, ...temp2])
                                                                setTriggerstate(false)
                                                            } else {
                                                                toast.error("Trigger before days should not exceed 100")
                                                                setTriggerstate(true)
                                                            }
                                                        }
                                                    } else if (k.includes('Year') == true) {
                                                        if (Number(tempupdate) < 101) {
                                                            setUpdatedays([...updatedays, ...tempupdate])
                                                            setUpdateValue([...temp, ...temp2])
                                                            setTriggerstate(false)
                                                        } else {
                                                            toast.error("Trigger before days should not exceed 100")
                                                            setTriggerstate(true)
                                                        }
                                                    }

                                                }
                                                triggerDays[record.childId] = e.target.value
                                                setTriggerDays([...triggerDays])


                                            }}


                                        ></Input>
                                        {/* {FormValidator.current.message(
                                            'trigger_before',
                                            updateValue[index],
                                            'required',

                                            {
                                                className: `invalid-feedback ${daysFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Trigger Before Days Required',


                                                }
                                            })} */}
                                        <  CloseOutlined style={{ marginLeft: "5px" }}
                                            onClick={(e) => {
                                                let temp = editclick.comp_id
                                                for (let i in temp) {
                                                    if (temp[i] == record.comp_id) {
                                                        temp.splice(i, 1)
                                                    }
                                                }
                                                setEditClick({
                                                    comp_id: [...temp]
                                                })
                                            }} />
                                    </>
                                    : ""}
                            </>
                        }
                    </>

                )
                // }
                // else{
                //     return (
                //         <>
                //             {record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days == null || record && record.statu_dates && record.statu_dates == "" ?
                //                 <div>
                //                     <input type="number"
                //                         name='triggerInput' maxLength={3} style={{ width: "80px", height: "30px" }}
                //                         disabled={temparr && temparr.length && temparr.filter((e) => e && e.comp_id == record.comp_id).length > 0 ? false : true}
                //                         className="form-control" placeholder='Days'
                //                         onBlur={(e) => {

                //                             let temp = []
                //                             let temp2 = []
                //                             temp2.push({
                //                                 comp_id: record.comp_id,
                //                                 value: e.target.value,
                //                                 index: index
                //                             })
                //                             temp.push(e.target.value)

                //                             if (Number(temp) < 101) {
                //                                 SetTriggerValue([...triggervalue, ...temp])
                //                                 setEditValue([...editValue, ...temp2])
                //                                 setEditPayload(true)
                //                                 setTriggerstate(false)
                //                             } else {
                //                                 toast.error("Trigger before days should not exceed 100")
                //                                 setTriggerstate(true)
                //                             }
                //                         }}

                //                     ></input>
                //                 </div>
                //                 :
                //                 <>
                //                     <label>{record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days}  <i className="ri-edit-line" onClick={() => {
                //                         setEditClick(true)
                //                     }}></i> </label>
                //                     {editclick == true && temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ?
                //                         <input name='triggerInputUpdate' maxLength={3} style={{ width: "80px", height: "30px" }} className="form-control" placeholder='Days'
                //                             hidden={record.freq == "On Occurrence"}
                //                             onBlur={(e) => {

                //                                 let tempupdate = []
                //                                 let temp2 = []
                //                                 tempupdate.push(e.target.value)
                //                                 temp2.push({
                //                                     comp_id: record.comp_id,
                //                                     value: e.target.value,
                //                                     index: index
                //                                 })
                //                                 if (Number(tempupdate) < 101) {
                //                                     setUpdatedays([...updatedays, ...tempupdate])
                //                                     setUpdateValue([...updateValue, ...temp2])
                //                                     setTriggerstate(false)
                //                                 } else {
                //                                     toast.error("Trigger before days should not exceed 100")
                //                                     setTriggerstate(true)
                //                                 }

                //                             }}


                //                         ></input>
                //                         : ""}
                //                 </>
                //             }
                //         </>

                //     )
                // }
            }
            // record && record.comp_name
        },
        {
            title: <label><span>Due Date</span><span style={{ color: "red", marginLeft: "5px" }}>*</span></label>,
            dataIndex: 'Due Date *',
            key: 'due_date_list[0]',
            width: "70px",
            align: 'center',
            render: (row, record, index) => {
                let today_date1 = new Date().toISOString().slice(0, 10)
                let today_date = moment(today_date1).format("DD-MMM-YYYY")
                return (
                    <>
                        {record.due_date_list[0] != "" ?
                            <>
                                {editclickdate && editclickdate.comp_id.filter((e) => e == record.comp_id).length > 0 ?
                                    <label style={{ textAlign: "center", color: 'brown' }} hidden={record.freq == "On Occurrence"}>{moment(record.due_date_list[0]).format("DD-MMM-YYYY")}
                                        <i style={{ marginLeft: "5px", cursor: "pointer" }} className="ri-edit-line" onClick={() => {
                                            let temp = []
                                            temp.push(record.comp_id)
                                            // setEditClickDate(true)
                                            setEditClickDate({
                                                comp_id: [...editclickdate.comp_id, ...temp],
                                                boolean: true
                                            })
                                        }}></i>
                                    </label>
                                    :
                                    <label style={{ textAlign: "center" }} hidden={record.freq == "On Occurrence"}>{moment(record.due_date_list[0]).format("DD-MMM-YYYY")}
                                        <i style={{ marginLeft: "5px", cursor: "pointer" }} className="ri-edit-line" onClick={() => {
                                            console.log(record, 'recccc');
                                            console.log(record.due_date_list[0], 'record.due_date_list[0]');
                                            let temp = []
                                            temp.push(record.comp_id)
                                            // setEditClickDate(true)
                                            setEditClickDate({
                                                comp_id: [...editclickdate.comp_id, ...temp],
                                                boolean: true
                                            })
                                        }}></i>
                                    </label>
                                }

                                {editclickdate && editclickdate.comp_id.filter((e) => e == record.comp_id).length > 0 ?
                                    // <input style={{ width: "200px", height: "30px" }} className="form-control" type="date" min={moment(currentdate).format("YYYY-MM-DD")} max={moment(currentdate).format("YYYY-MM-DD") == moment(record.due_date_list[0]).format("DD-MM-YYYY") ? moment(currentdate).add(15,'days').format("YYYY-MM-DD"): moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                    <>
                                        {/* <Input format={'DD-MMM-YYYY'}
                                            style={{ width: "135px", height: "30px" }}
                                            defaultValue={moment(new Date(record.due_date_list[0])).format('YYYY-MM-DD')}
                                            // className="form-control"
                                            type="date"
                                            min={moment(currentdate).endOf('month')}
                                            // min={moment(month).endOf('month')}
                                            // min={moment(month).endOf('month')}
                                            // max={moment(currentdate).format("YYYY-MM-DD") == moment(record.due_date_list[0]).format("YYYY-MM-DD") ? moment(currentdate).add(15, 'days').format("YYYY-MM-DD") : moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                            hidden={record.freq == "On Occurrence"}

                                            onChange={(e) => {
                                                console.log(e.target.value,'e.target.value');
                                                let tempupdatedate = []
                                                tempupdatedate.push(e.target.value)
                                                SetUpdateDateEdit([...updatedateedit, ...tempupdatedate])
                                                let temp = updatedata
                                                for (let i in temp) {
                                                    if (temp[i].comp_id == record.comp_id) {
                                                        temp.splice(i, 1)
                                                    }
                                                }
                                                for (let i in updateValue) {
                                                    if (index == updateValue[i].index && record.comp_id == updateValue[i].comp_id) {
                                                        let temparr = []
                                                        temparr.push({
                                                            date: e.target.value,
                                                            comp_id: record.comp_id,
                                                            index: index
                                                        })
                                                        // if (record.r_every == null && record.repeat_by == null) {
                                                        let temparr1 = []
                                                        temparr1.push({
                                                            date: e.target.value,
                                                            comp_id: updateValue[i].comp_id,
                                                            value: updateValue[i].value,
                                                            frequency: record.freq
                                                        })
                                                        setUpdateData([...temp, ...temparr1])
                                                        // }
                                                        setUpdateDate([...updateDate, ...temparr])
                                                    }
                                                }
                                                dueDate[record.childId] = moment(e.target.value).format("DD-MMM-YYYY")
                                                setDueDate([...dueDate])
                                            }}
                                        /> */}
                                        <br />
                                        <DatePicker format={'DD-MMM-YYYY'}
                                            onKeyDown={(event) => {
                                                event.preventDefault()
                                            }}
                                            className="form-control"
                                            style={{ width: "125px", height: "25px" }}
                                            defaultValue={moment(record.due_date_list[0])}
                                            // key={moment(record.due_date_list[0])}
                                            allowClear={false}
                                            hidden={record.freq == "On Occurrence"}
                                            onChange={(e) => {
                                                console.log(e._d, 'sneha');
                                                let formatDate = moment(e._d).format('YYYY-MM-DD')
                                                let tempupdatedate = []
                                                tempupdatedate.push(formatDate)
                                                SetUpdateDateEdit([...updatedateedit, ...tempupdatedate])
                                                let temp = updatedata
                                                for (let i in temp) {
                                                    if (temp[i].comp_id == record.comp_id) {
                                                        temp.splice(i, 1)
                                                    }
                                                }
                                                for (let i in updateValue) {
                                                    if (index == updateValue[i].index && record.comp_id == updateValue[i].comp_id) {
                                                        let temparr = []
                                                        temparr.push({
                                                            date: formatDate,
                                                            comp_id: record.comp_id,
                                                            index: index
                                                        })
                                                        // if (record.r_every == null && record.repeat_by == null) {
                                                        let temparr1 = []
                                                        temparr1.push({
                                                            date: formatDate,
                                                            comp_id: updateValue[i].comp_id,
                                                            value: updateValue[i].value,
                                                            frequency: record.freq
                                                        })
                                                        setUpdateData([...temp, ...temparr1])
                                                        // }
                                                        setUpdateDate([...updateDate, ...temparr])
                                                    }
                                                }
                                                dueDate[record.childId] = moment(formatDate).format("DD-MMM-YYYY")
                                                setDueDate([...dueDate])
                                            }}
                                            disabledDate={(current) => {
                                                let dueMonth = record.due_date_list && record.due_date_list.length > 0 && record.due_date_list[0]
                                                let splittedMonth = dueMonth.split('-')
                                                if (record.freq === 'Review') {

                                                    return moment(record.due_date_list && record.due_date_list.length > 0 && record.due_date_list[0]) < current || moment().add(-1, 'days') >= current
                                                    // }
                                                    // else {
                                                    //     return moment().add(-1, 'days') >= current
                                                    // }
                                                }
                                                else {
                                                    return moment().add(-1, 'days') >= current
                                                }

                                            }}

                                        />


                                        {/* <Input type='date' style={{ width: '80%' }} allowClear={false}
                                            value={record.due_date_list[0] ? moment(record.due_date_list[0]).format("YYYY-MM-DD"): ''}
                                            min={moment(currentdate).format("YYYY-MM-DD")}
                                            max={moment(currentdate).format("YYYY-MM-DD") == moment(record.due_date_list[0]).format("YYYY-MM-DD") ? moment(currentdate).add(15,'days').format("YYYY-MM-DD"): moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                            hidden={record.freq == "On Occurrence"}
                                            onChange={(e) => {
                                                console.log(e.target.value,"iiiiiiiiii");
                                                let tempupdatedate = []
                                                tempupdatedate.push(e.target.value)
                                                SetUpdateDateEdit([...updatedateedit, ...tempupdatedate])
                                                let temp = updatedata
                                                for (let i in temp) {
                                                    if (temp[i].comp_id == record.comp_id) {
                                                        temp.splice(i, 1)
                                                    }
                                                }
                                                for (let i in updateValue) {
                                                    if (index == updateValue[i].index && record.comp_id == updateValue[i].comp_id) {
                                                        let temparr = []
                                                        temparr.push({
                                                            date: e.target.value,
                                                            comp_id: record.comp_id,
                                                            index: index
                                                        })
                                                        // if (record.r_every == null && record.repeat_by == null) {
                                                        let temparr1 = []
                                                        temparr1.push({
                                                            date: e.target.value,
                                                            comp_id: updateValue[i].comp_id,
                                                            value: updateValue[i].value,
                                                            frequency: record.freq
                                                        })
                                                        setUpdateData([...temp, ...temparr1])
                                                        // }
                                                        setUpdateDate([...updateDate, ...temparr])
                                                    }
                                                }
                                            }} */}

                                        <  CloseOutlined
                                            style={{ marginLeft: "5px" }}
                                            onClick={(e) => {
                                                let temp = editclickdate.comp_id
                                                for (let i in temp) {
                                                    if (temp[i] == record.comp_id) {
                                                        temp.splice(i, 1)
                                                    }
                                                }
                                                setEditClickDate({
                                                    comp_id: [...temp]
                                                })
                                            }} />
                                    </>

                                    : ""}
                            </>
                            :
                            <div>
                                {record.due_date_list[0] === "" && record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days != null ?
                                    <>
                                        {editclickdate && editclickdate.comp_id.filter((e) => e == record.comp_id).length > 0 ?
                                            <label style={{ textAlign: "center", color: 'brown' }} hidden={record.freq == "On Occurrence"}>{today_date}
                                                <i style={{ marginLeft: "5px" }} className="ri-edit-line" onClick={() => {
                                                    // setEditClickDate(true)
                                                    let temp = []
                                                    temp.push(record.comp_id)
                                                    // setEditClickDate(true)
                                                    setEditClickDate({
                                                        comp_id: [...editclickdate.comp_id, ...temp]
                                                    })
                                                }}></i>
                                            </label>
                                            :
                                            <label style={{ textAlign: "center" }} hidden={record.freq == "On Occurrence"}>{today_date}
                                                <i style={{ marginLeft: "5px" }} className="ri-edit-line" onClick={() => {
                                                    // setEditClickDate(true)
                                                    let temp = []
                                                    temp.push(record.comp_id)
                                                    // setEditClickDate(true)
                                                    setEditClickDate({
                                                        comp_id: [...editclickdate.comp_id, ...temp]
                                                    })
                                                }}></i>
                                            </label>
                                        }
                                        {editclickdate && editclickdate.comp_id.filter((e) => e == record.comp_id).length > 0 ?
                                            // <input style={{ width: "200px", height: "30px" }} className="form-control" type="date" min={moment(currentdate).format("YYYY-MM-DD")} max={moment(currentdate).format("YYYY-MM-DD") == moment(record.due_date_list[0]).format("DD-MM-YYYY") ? moment(currentdate).add(15,'days').format("YYYY-MM-DD"): moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                            <>
                                                {/* <Input
                                                    style={{ width: "130px", height: "30px" }}
                                                    className="form-control" type="date"
                                                    min={moment(currentdate).format("YYYY-MM-DD")}
                                                    max={moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                                    hidden={record.freq == "On Occurrence"}
                                                    onKeyDown={(event)=>{
                                                        event.preventDefault()
                                                       }}
                                                    defaultValue={moment(new Date(today_date)).format('YYYY-MM-DD')}
                                                    onChange={(e) => {
                                                        console.log(e.target.value, 'e.target.value');
                                                        let tempupdatedate = []
                                                        tempupdatedate.push(e.target.value)
                                                        SetUpdateDateEdit([...updatedateedit, ...tempupdatedate])
                                                        let temp = updatedata
                                                        for (let i in temp) {
                                                            if (temp[i].comp_id == record.comp_id) {
                                                                temp.splice(i, 1)
                                                            }
                                                        }
                                                        for (let i in updateValue) {
                                                            if (index == updateValue[i].index && record.comp_id == updateValue[i].comp_id) {
                                                                let temparr = []
                                                                temparr.push({
                                                                    date: e.target.value,
                                                                    comp_id: record.comp_id,
                                                                    index: index
                                                                })
                                                                // if (record.r_every == null && record.repeat_by == null) {
                                                                let temparr1 = []
                                                                temparr1.push({
                                                                    date: e.target.value,
                                                                    comp_id: updateValue[i].comp_id,
                                                                    value: updateValue[i].value,
                                                                    frequency: record.freq
                                                                })
                                                                setUpdateData([...temp, ...temparr1])
                                                                // }
                                                                setUpdateDate([...updateDate, ...temparr])
                                                            }
                                                        }
                                                        dueDate[record.childId] = moment(e.target.value).format("DD-MMM-YYYY")
                                                        setDueDate([...dueDate])

                                                    }}
                                                /> */}
                                                <DatePicker format={'DD-MMM-YYYY'}
                                                    onKeyDown={(event) => {
                                                        event.preventDefault()
                                                    }}
                                                    style={{ width: "125px", height: "25px" }}
                                                    className="form-control" type="date"
                                                    allowClear={false}
                                                    hidden={record.freq == "On Occurrence"}
                                                    defaultValue={moment(today_date)}
                                                    onChange={(e) => {
                                                        let formatDate = moment(e._d).format('YYYY-MM-DD')
                                                        let tempupdatedate = []
                                                        tempupdatedate.push(formatDate)
                                                        SetUpdateDateEdit([...updatedateedit, ...tempupdatedate])
                                                        let temp = updatedata
                                                        for (let i in temp) {
                                                            if (temp[i].comp_id == record.comp_id) {
                                                                temp.splice(i, 1)
                                                            }
                                                        }
                                                        for (let i in updateValue) {
                                                            if (index == updateValue[i].index && record.comp_id == updateValue[i].comp_id) {
                                                                let temparr = []
                                                                temparr.push({
                                                                    date: formatDate,
                                                                    comp_id: record.comp_id,
                                                                    index: index
                                                                })
                                                                // if (record.r_every == null && record.repeat_by == null) {
                                                                let temparr1 = []
                                                                temparr1.push({
                                                                    date: formatDate,
                                                                    comp_id: updateValue[i].comp_id,
                                                                    value: updateValue[i].value,
                                                                    frequency: record.freq
                                                                })
                                                                setUpdateData([...temp, ...temparr1])
                                                                // }
                                                                setUpdateDate([...updateDate, ...temparr])
                                                            }
                                                        }
                                                        dueDate[record.childId] = moment(formatDate).format("DD-MMM-YYYY")
                                                        setDueDate([...dueDate])
                                                    }}
                                                    disabledDate={(current) => {
                                                        let dueMonth = record.due_date_list && record.due_date_list.length > 0 && record.due_date_list[0]
                                                        let splittedMonth = dueMonth.split('-')
                                                        if (record.freq === 'Review') {
                                                            if (splittedMonth[1] != 'Jan') {
                                                                return moment(record.due_date_list && record.due_date_list.length > 0 && record.due_date_list[0]).endOf('month') <= current || moment().add(-1, 'days') >= current
                                                            }
                                                            else {
                                                                return moment().add(-1, 'days') >= current
                                                            }
                                                        }
                                                        else {
                                                            return moment().add(-1, 'days') >= current
                                                        }

                                                    }}
                                                />
                                                <  CloseOutlined
                                                    style={{ marginLeft: "5px" }}
                                                    onClick={(e) => {
                                                        let temp = editclickdate.comp_id
                                                        for (let i in temp) {
                                                            if (temp[i] == record.comp_id) {
                                                                temp.splice(i, 1)
                                                            }
                                                        }
                                                        setEditClickDate({
                                                            comp_id: [...temp]
                                                        })
                                                    }} />
                                            </>
                                            : ""}
                                    </>
                                    :
                                    // <Input
                                    //     className='form-control' type="date"
                                    //     min={moment(currentdate).format("YYYY-MM-DD")}
                                    //     max={moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                    //     hidden={record.freq == "On Occurrence"}
                                    //     style={{ width: "140px" }}
                                    //     autocomplete="off"
                                    //     // disabled={temparr && temparr.length && temparr.filter((e) => e && e.comp_id == record.comp_id).length > 0 ? false : true}
                                    //     onChange={(e) => {
                                    //         console.log(e.target.value, 'e.target.value');
                                    //         let temp = EditDate
                                    //         for (let i in temp) {
                                    //             if (temp[i].comp_id == record.comp_id) {
                                    //                 temp.splice(i, 1)
                                    //             }
                                    //         }
                                    //         // let date1 = moment(e.target.value).format("DD-MMM-YYYY")
                                    //         let tempdate = []
                                    //         tempdate.push(e.target.value)
                                    //         setDate([...date, ...tempdate])
                                    //         for (let i in editValue) {

                                    //             if (index == editValue[i].index) {
                                    //                 let temparr = []
                                    //                 temparr.push({
                                    //                     date: e.target.value,
                                    //                     comp_id: editValue[i].comp_id,
                                    //                     value: editValue[i].value,
                                    //                     frequency: record.freq
                                    //                 })
                                    //                 setEditDate([...temp, ...temparr])
                                    //             }
                                    //         }
                                    //         dueDate[record.childId] = moment(e.target.value).format("DD-MMM-YYYY")
                                    //         setDueDate([...dueDate])

                                    //     }}
                                    // ></Input >
                                    <DatePicker
                                        onKeyDown={(event) => {
                                            event.preventDefault()
                                        }}
                                        className='form-control' type="date"
                                        // min={moment(currentdate).format("YYYY-MM-DD")}
                                        // max={moment(record.due_date_list[0]).format("YYYY-MM-DD")}
                                        hidden={record.freq == "On Occurrence"}
                                        style={{ width: "140px" }}
                                        allowClear={false}
                                        // disabled={temparr && temparr.length && temparr.filter((e) => e && e.comp_id == record.comp_id).length > 0 ? false : true}
                                        onChange={(e) => {
                                            console.log(e._d, 'e.target.value');
                                            let formatDate = moment(e._d).format('DD-MMM-YYYY')
                                            console.log(formatDate, "formatDateformatDate");
                                            let temp = EditDate
                                            for (let i in temp) {
                                                if (temp[i].comp_id == record.comp_id) {
                                                    temp.splice(i, 1)
                                                }
                                            }
                                            // let date1 = moment(e.target.value).format("DD-MMM-YYYY")
                                            let tempdate = []
                                            tempdate.push(formatDate)
                                            setDate([...date, ...tempdate])
                                            for (let i in editValue) {
                                                if (index == editValue[i].index) {
                                                    console.log("hhhhhhhhhhhhh");
                                                    let temparr = []
                                                    temparr.push({
                                                        date: formatDate,
                                                        comp_id: editValue[i].comp_id,
                                                        value: editValue[i].value,
                                                        frequency: record.freq
                                                    })
                                                    setEditDate([...temp, ...temparr])
                                                }
                                            }
                                            dueDate[record.childId] = moment(formatDate).format("DD-MMM-YYYY")
                                            setDueDate([...dueDate])

                                        }}
                                        disabledDate={(current) => {
                                            return moment().add(-1, 'days') >= current
                                        }}
                                    />
                                }
                            </div>
                        }
                    </>
                )
            },
            sortable: true,
            // right: true,
            reorder: true,
            // width: '150px',

        },
        {
            title: 'Validity Date',
            dataIndex: 'Validity Date',
            key: 'due_date_list[0]',
            align: 'center',
            width: "70px",
            render: (row, record, index) => {
                return (
                    <>
                        {record && record.freq === "Review" || record && record.freq === "Periodical" ?
                            <DatePicker placeholder={undefined} className='form-control' type="date"
                                format={'DD-MMM-YYYY'}
                                disabledDate={(current) => {

                                    return moment().add(-1, 'days') >= current


                                }}
                                // hidden={record.freq == "On Occurrence"}
                                style={{ width: "140px" }}
                                min={moment(currentdate).format("DD-MMM-YYYY")}
                                // disabled={temparr.filter((e) => e.comp_id == row.comp_id).length > 0 ? false : true}
                                onChange={(e) => {
                                    let temp = updatedata
                                    for (let i in temp) {
                                        if (temp[i].comp_id == record.comp_id) {
                                            temp.splice(i, 1)
                                        }
                                    }
                                    for (let j in updateValue) {
                                        for (let k in updateDate) {
                                            if (index == updateDate[k].index && record.comp_id == updateDate[k].comp_id && record.comp_id == updateValue[j].comp_id) {
                                                let temparr = []
                                                temparr.push({
                                                    date: updateDate[k].date,
                                                    comp_id: updateDate[k].comp_id,
                                                    value: updateValue[j].value,
                                                    vdate: e.target.value,
                                                    frequency: record.freq
                                                })
                                                setUpdateData([...temp, ...temparr])
                                            }
                                        }
                                    }
                                    validityDate[record.childId] = moment(e._d).format("DD-MMM-YYYY")
                                    setValitityDate([...validityDate])

                                }}
                            // disabled={temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ? false : true}
                            ></DatePicker >
                            : ""}
                    </>
                )
            },
            reorder: true,
            // width: '90px',

        }

    ]




    const showmore = () => {
        setShowMore1(true)
        const payload10 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceForUnits",
                    {
                        "le_id": entityid && entityid,
                        "u_ids": unitid,
                        "d_id": Number(data1.domainid),
                        "r_count": entires,
                        "f_ids": frequencyid,
                        "tz_name": data1.timezone
                    }
                ]

            }
        ]
        showmoredata({
            payload: payload10,
            paramid: paramid
        })
    }
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const addAllSelectedDataValues = (checked, currentParId, parentData, childData) => {
        setChildValue(childData)
        let array
        let temparr1 = []
        let tempArray = temparr
        for (let j in childData) {

            temparr1.push(childData && childData[j].comp_id)

        }
        setRowCompId([...rowcompid, ...temparr1])
        var ele = document.getElementsByName('checked' + currentParId);

        if (checked === true) {
            setCheckedRow(true)
            array = _.filter(data, { parentId: currentParId });
            for (let i in array) {
                tempArray.push(array[i])
            }

            setTemparr([...tempArray])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            for (var x = tempArray.length - 1; x >= 0; x--) {
                if (tempArray[x].parentId === currentParId) {
                    tempArray.splice(x, 1)
                }
            }
            setTemparr([...tempArray])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }
    const addSelectedDataValues = (checked, comp_id, childId, parentId, record) => {

        var ele = document.getElementsByName('allchecked');
        var elechild = document.getElementsByClassName('checkclass' + parentId);
        var childcheckbox = document.getElementsByClassName('checkclasschild' + parentId);
        var checkbox = document.getElementById(parentId);
        let array
        let array1
        let array2
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(data, { comp_id: comp_id });
                tempArray.push(array)
                array = _.find(data, { comp_id: comp_id });
                tempArray.push(array)
                array1 = _.filter(temp.tempData, { index: parentId });

                array2 = _.filter(tempArray, { parentId: parentId });
                if (array2.length === array1[0].child.length) {
                    elechild[0].checked = true;
                }

            } else {

                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                    if (data.length !== temparr.length) {
                        setCheckedRow(false)
                        // for (var j = 0; j < ele.length; j++) {
                        //     if (ele[j].type === 'checkbox')
                        //         ele[j].checked = false;
                        // }
                        elechild[0].checked = false;
                    }
                }


            }
            setTemparr([...tempArray])

        } else {

            if (checked === true) {
                array1 = _.filter(temp.tempData, { index: parentId });
                array = _.find(data, { comp_id: comp_id });
                tempArray.push(array)
                array2 = _.filter(tempArray, { parentId: parentId });
                if (array2.length === array1[0].child.length) {
                    elechild[0].checked = true;
                }
                let temp2 = []

            } else {
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                }
                elechild[0].checked = false;
            }
            setTemparr([...tempArray])


        }



    }
    // const addSelectedDataValues = (checked, comp_id, act_name) => {
    //     console.log(checked, "checkedchecked");
    //     var ele = document.getElementsByName('allchecked');
    //     let array
    //     let tempArray = temparr
    //     if (checkedRow === true) {
    //         if (checked === true) {
    //             array = _.find(data, { comp_id: comp_id });
    //             tempArray.push(array)
    //         } else {
    //             console.log("test2");
    //             for (let i in tempArray) {
    //                 if (tempArray[i].comp_id === comp_id) {
    //                     tempArray.splice(i, 1)
    //                 }
    //                 if (data.length !== temparr.length) {
    //                     setCheckedRow(false)
    //                     for (var j = 0; j < ele.length; j++) {
    //                         if (ele[j].type === 'checkbox')
    //                             ele[j].checked = false;
    //                     }
    //                 }
    //             }

    //         }
    //         setTemparr([...tempArray])
    //     }
    //     else {
    //         if (checked === true) {
    //             array = _.find(data, { comp_id: comp_id });
    //             tempArray.push(array)
    //             if (data.length === temparr.length) {
    //                 setCheckedRow(true)
    //                 for (var i = 0; i < ele.length; i++) {
    //                     if (ele[i].type === 'checkbox')
    //                         ele[i].checked = true;
    //                 }
    //             }

    //         } else {
    //             console.log("test3");
    //             for (let i in tempArray) {
    //                 if (tempArray[i].comp_id === comp_id) {
    //                     tempArray.splice(i, 1)
    //                 }
    //             }
    //         }
    //         setTemparr([...tempArray])
    //     }

    // }

    const Next1 = () => {
        setAddFormSubmit(true)
        setCountvalue(true)
        setTemparr([])
        setTriggerValidation([])
        setTriggerDays([])
        let checked = false
        if (validator.current.allValid() && isAuth) {
            setAddFormSubmit(true)
            setKey("2")
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceTotalToAssign",
                        {
                            "le_id": entityid,
                            "u_ids": unitid,
                            "d_id": Number(data1.domainid),
                            "f_ids": frequencyid
                        }

                    ]
                }
            ]
            table({
                payload: payload2,
                paramid: paramid
            })
            const payload8 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceForUnits",
                        {
                            "le_id": entityid,
                            "u_ids": unitid,
                            "d_id": Number(data1.domainid),
                            "r_count": 0,
                            "f_ids": frequencyid,
                            "tz_name": data1.timezone
                        }
                    ]

                }
            ]
            tabledata({
                payload: payload8,
                paramid: paramid
            })
            // setTemparr([])
            // let temp = temparr
            // console.log(temp,"temp9999");
            // for (let i in temp) {
            //     for (let j in checkid) {
            //         if (temp[i].comp_id === checkid[j]) {
            //             temp.splice(i, 1)
            //         }
            //     }
            // }
            // setTemparr([...temp])
            setTemparr([])
        }
    }
    const Next2 = () => {
        // setTriggerstate(false)

        if (triggerstate != true) {
            setCollapseAssignee1(true)
            setTableclick(true)
            setdaysFormSubmit(true)
            if (temparr != "") {
                if (triggerValidation.length > 0) {
                    if (dateValidation && dateValidation.length == 0) {
                        if (triggerValidation.includes('0') == true) {
                            toast.error(`Trigger before days should be 1 to 100`)
                        }

                        else if ((triggerValidation.includes('') == false) && (triggerValidation.includes(null) == false)) {
                            console.log(editValue && editValue.length, 'EditDate.lengtheditValue');
                            console.log(EditDate && EditDate.length, 'EditDate.length');
                            console.log((editValue && editValue.length) == (EditDate && EditDate.length), "EditDate.lengthhh");

                            for (let i in temparr) {
                                if (temparr[i].freq != "On Occurrence") {
                                    if (temparr[i].due_date_list[0] != "" && temparr[i].statu_dates[0].trigger_before_days != null ? temparr[i].due_date_list[0] && temparr[i].statu_dates[0].trigger_before_days : editValue.filter((e) => e.comp_id == temparr[i].comp_id) && updatedata.filter((e) => e.comp_id == temparr[i].comp_id)) {
                                        const value123 = unitlist && unitlist.assign_user && unitlist.assign_user.filter((item) => {
                                            return item.is_approver == true
                                        })
                                        setApprovaldrop(value123)
                                        window.scrollTo(0, 0);
                                        setCollapseAssignee('1')
                                        // setCollapseAssignee1(true)
                                    }
                                    else {
                                        toast.warning("Trigger before days and Due date fields are required in - ".concat(header[i]))
                                        setKey("2")
                                    }
                                }
                                else {
                                    window.scrollTo(0, 0);
                                    setCollapseAssignee('1')
                                    // setCollapseAssignee1(true)

                                }
                            }
                        }

                        else {
                            toast.error(`Trigger before days Required-${complianceName && complianceName.length > 0 && complianceName[0]}`)
                        }
                    }
                    else {
                        toast.error(`Due Date fields are required in -${dateValidation && dateValidation.length > 0 && dateValidation[0]}`)
                    }
                }
                else {

                    for (let i in temparr) {
                        if (temparr[i].freq == "On Occurrence") {
                            window.scrollTo(0, 0);
                        }

                    }
                }

            }
            else {
                toast.warning("No Compliance selected for assign")
            }
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUserToAssignCompliance",
                        {
                            "d_id": Number(data1.domainid),
                            "u_ids": unitid,
                            "le_id": entityid
                        }
                    ]
                }
            ]
            selectassigneedata({
                payload: payload1,
                paramid: paramid
            })
            setCollapseAssignee1(true)
        }

    }
    console.log(assignpayload, 'assignpayload');
    console.log(unitlist.timezone[0], 'unitlist.timezone[0]');
    const Submit = () => {

        setAddFormSubmit1(true)

        if (validator1.current.allValid() && isAuth) {
            setAddFormSubmit1(true)

            const payload4 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "SaveAssignedCompliance",
                        {
                            "assignee": assigneelist.assign_id ? Number(assigneelist.assign_id) : Number(clickassignee.assignee_id),
                            // "assignee": 2,
                            "assignee_name": `${assigneelist.assign_id}- ${assigneelist.assignee}`,
                            "concurrence_person": null,
                            "concurrer_name": null,
                            "approval_person": assigneelist.approval_id ? Number(assigneelist.approval_id) : Number(clickassignee.approval_id),
                            // "approval_person": 1,
                            "approver_name": assigneelist.approval,
                            "assign_compliances": assignpayload,
                            "le_id": entityid,
                            "d_id": Number(data1.domainid),
                            "u_ids": unitid,
                            "tz_name": data1.timezone,
                            "vo_list": data1.vor_id ? [data1.vor_id] : []
                        }
                    ]

                }

            ]
            console.log(payload4, 'payload4');
            saveassigncomliance({
                payload: payload4,
                paramid: paramid
            })
            const payload5 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "SyncAssignedCompliance",
                        {
                            "assignee": assigneelist.assign_id ? Number(assigneelist.assign_id) : Number(clickassignee.assignee_id),
                            "assignee_name": `${assigneelist.assign_id}- ${assigneelist.assignee}`,
                            "concurrence_person": null,
                            "concurrer_name": null,
                            "approval_person": assigneelist.approval_id ? Number(assigneelist.approval_id) : Number(clickassignee.approval_id),
                            "approver_name": assigneelist.approval,
                            "assign_compliances": assignpayload,
                            "le_id": entityid,
                            "d_id": Number(data1.domainid),
                            "u_ids": unitid,
                            "tz_name": unitlist.timezone && unitlist.timezone[0] && unitlist.timezone[0].tz_name,

                        }
                    ]

                }

            ]
            console.log(payload5, 'payload5');
            syncassigncomliance({
                payload: payload5,
                paramid: paramid
            })
            const payload6 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssignCompliancesFormData",
                        {}
                    ]
                }
            ]
            selectunit({
                payload: payload6,
                paramid: paramid
            })

            // setKey("1")
            // setData({
            //     ...data,
            //     legalentity: "",
            //     division: "",
            //     category: "",
            //     domain: "",
            //     unit1: "",
            //     compliance_frequency: "",
            //     timezone: ""
            // })
            // setCheckedRow([])
            // setAssigneelist({
            //     ...assigneelist,
            //     assignee: "",
            //     approval: "",
            //     restricted: ""
            // })
            // setTrigger({
            //     ...trigger,
            //     triggerInput: ""
            // })
            // setDate([])
            // toast.success("Compliance Assigned Successfully")
            // setTimeout(() => {
            //     window.location.reload()
            // }, 2500);

        }
        // window.location.reload()

    }

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        validator.current.showMessages()
        FormValidator.current.showMessages()
        validator1.current.showMessages()
        Toaster.warning('Development Under Progress');
    }, [])

    useEffect(() => {
        if (unitlist.tablelist && unitlist.tablelist.length > 0 && unitlist.tablelist != null && unitlist.tablelist != undefined) {
            setAssign_statutory(unitlist && unitlist.tablelist && unitlist.tablelist[0])
        }
        if (showmore1 == true) {
            if (unitlist.showmorelist && unitlist.showmorelist.length > 0 && unitlist.showmorelist != null && unitlist.showmorelist != undefined) {
                // let temp=[]
                // temp.push(showmoredatalist)
                setShowMoreDataList(unitlist && unitlist.showmorelist && unitlist.showmorelist[0])
            }
        }
    }, [unitlist.tablelist, unitlist.showmorelist, showmore1])

    useEffect(() => {
        if (countvalue === true) {
            const count = datacount.map((item) => {
                return item.length
            })
            const total = count.reduce((a, b) => a + b, 0)
            setEntires(total)
        }
    }, [datacount, countvalue])

    useEffect(() => {
        if (showmore1 == true) {
            if (assign_statutory && showmoredatalist) {
                if (count == 1) {
                    let con = Object.entries(assign_statutory)
                    let data = Object.entries(showmoredatalist)
                    let concat = con.concat(data)
                    setList(concat)
                    let con1 = Object.values(assign_statutory)
                    let data1 = Object.values(showmoredatalist)
                    let concat1 = con1.concat(data1)
                    setdatacount(concat1)
                    let con2 = Object.keys(assign_statutory)
                    let data2 = Object.keys(showmoredatalist)
                    let concat2 = con2.concat(data2)
                    setHeaderList(concat2)
                    setCount(2)
                } else {
                    let data = Object.entries(showmoredatalist)
                    let concat = list.concat(data)
                    setList(concat)
                    let data1 = Object.values(showmoredatalist)
                    let concat1 = datacount.concat(data1)
                    setdatacount(concat1)
                    let data2 = Object.keys(showmoredatalist)
                    let concat2 = headerlist.concat(data2)
                    setHeaderList(concat2)
                }
            }
        }
        else {
            if (assign_statutory) {
                setList(Object.entries(assign_statutory))
                setdatacount(Object.values(assign_statutory))
                setHeaderList((Object.keys(assign_statutory)))
            }
        }

    }, [assign_statutory, showmore1, showmoredatalist])


    useEffect(() => {
        if (list.length) {
            let totalList = []
            let arr = [];
            let count = 0
            let countrylisttempdata = [];
            let tempSetArray = [];
            let parId = []
            list && list.map(function (user, i) {
                if ((arr.indexOf(user[0]) < 0))
                    arr.push(user[0]);
            });
            arr.map((pmenu, index) => {
                let entityArray = []
                let tempArray = []
                if (pmenu) {
                    list && list.map((subMenuList, childIndex) => {
                        if (pmenu === subMenuList[0]) {
                            subMenuList[1] && subMenuList[1].length && subMenuList[1].map((item) => {

                                entityArray = {
                                    "amendment_history_id": item.amendment_history_id,
                                    "applicable_units": item.applicable_units,
                                    "comp_id": item.comp_id,
                                    "comp_name": item.comp_name,
                                    "compfielaw_url": item.compfielaw_url,
                                    "descp": item.descp,
                                    "due_date_list": item.due_date_list,
                                    "freq": item.freq,
                                    "r_every": item.repeat_by,
                                    "repeat_by": item.repeat_by,
                                    "statu_dates": item.statu_dates,
                                    "summary": item.summary,
                                    "parentId": index,
                                    'childId': count,

                                }
                                if (item.statu_dates && item.statu_dates.length > 0) {
                                    triggerDays[count] = item.statu_dates[0].trigger_before_days
                                    validityDate[count] = null
                                    dueDate[count] = item.due_date_list.length > 0 && item.due_date_list[0]
                                }

                                count = count + 1
                                tempArray.push(entityArray)
                            })

                        }
                    })
                }


                let checkarray = []
                let listArray_One_time = _.filter(tempArray, { freq: "One Time" });
                if (listArray_One_time && listArray_One_time.length > 0) {
                    for (let i in listArray_One_time) {
                        checkarray.push(listArray_One_time[i])
                    }

                }

                let listArray_periodical = _.filter(tempArray, { freq: "Periodical" });
                if (listArray_periodical && listArray_periodical.length > 0) { for (let i in listArray_periodical) { checkarray.push(listArray_periodical[i]) } }
                let listArray_Review = _.filter(tempArray, { freq: "Review" });
                if (listArray_Review && listArray_Review.length > 0) { for (let i in listArray_Review) { checkarray.push(listArray_Review[i]) } }
                let listArray_Flexi_Review = _.filter(tempArray, { freq: "Flexi Review" });
                if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) { for (let i in listArray_Flexi_Review) { checkarray.push(listArray_Flexi_Review[i]) } }
                let listArray_On_Occurrence = _.filter(tempArray, { freq: "On Occurrence" });
                if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) { for (let i in listArray_On_Occurrence) { checkarray.push(listArray_On_Occurrence[i]) } }

                totalList = {
                    'index': index,
                    'parentData': pmenu,
                    'child': checkarray
                }
                countrylisttempdata.push(totalList)
                tempSetArray.push(checkarray)
                parId.push(index);

            })
            setTemp({
                ...temp,
                tempData: countrylisttempdata,
                parentIndexId: parId

            })
            setTriggerDays([...triggerDays])
            setDueDate([...dueDate])
            setValitityDate([...validityDate])
            // setData(tempSetArray)
            getData()
        }

    }, [list.length])


    const getData = () => {
        let countrylisttempdataNew = [];
        let arr = [];
        let arrNew = [];
        let count = 0
        list && list.map(function (user, i) {
            if ((arr.indexOf(user[0]) < 0))
                arr.push(user[0]);
        });

        arr.map((pmenu, index) => {
            let entityArray = []
            let tempArraryNew = [];

            if (pmenu) {

                list && list.map((subMenuList, childIndex) => {

                    if (pmenu === subMenuList[0]) {
                        subMenuList[1] && subMenuList[1].length && subMenuList[1].map((item) => {

                            entityArray = {
                                "amendment_history_id": item.amendment_history_id,
                                "applicable_units": item.applicable_units,
                                "comp_id": item.comp_id,
                                "comp_name": item.comp_name,
                                "compfielaw_url": item.compfielaw_url,
                                "descp": item.descp,
                                "due_date_list": item.due_date_list,
                                "freq": item.freq,
                                "r_every": item.r_every,
                                "repeat_by": item.repeat_by,
                                "statu_dates": item.statu_dates,
                                "summary": item.summary,
                                "parentId": childIndex,
                                "chilId": count
                            }
                            count = count + 1
                            tempArraryNew.push(entityArray)

                        })

                    }
                })

            }
            countrylisttempdataNew.push(tempArraryNew)

        })
        var merged = [].concat.apply([], countrylisttempdataNew);
        setData1(merged)
    }


    useEffect(() => {
        if (datacount) {
            var merged = [].concat.apply([], datacount);
            setTotalTableData(merged)
        }
    }, [datacount])

    useEffect(() => {
        let data = freqid.map((item) => {
            return item.frequency_id
        })
        setFrequencyId(data)
        let datadata = uid && uid.map((item) => {
            return item.u_id
        })
        setUnitId(datadata)
    }, [freqid, uid])
    useEffect(() => {
        if (data1.unit1) {
            for (const k in unitid) {
                let arr = unitlist && unitlist.unitdata && unitlist.unitdata.filter((item, i) => {
                    const uniqueCharsrow = [...new Set(item.u_ids)];
                    return item.u_ids.includes(parseInt(unitid[k]))
                })
                if (unitid.length >= 2) {
                    const remove = arr && arr.splice(2, 1)
                    setComplianceFrequency(arr)
                }
                else if(data1.unit1.length > 0) {
                    setComplianceFrequency(arr)
                }
            }
        }
    }, [unitid, data1])

    // useEffect(() => {
    //     if (unitlist && unitlist.domain && unitlist.domain.length > 0) {
    //         let data = unitlist && unitlist.domain && unitlist.domain.length > 0 && unitlist.domain.filter((item) => {
    //             return item.le_id == entityid
    //         })
    //         setDomain(data)
    //     }else{
    //         setDomain(0)
    //     }
    // }, [entityid, unitlist.domain, data1.legalentity])

    useEffect(() => {
        console.log('unitnameeee')
        if (data1.category) {
            let categoryvalue = unitlist.category.filter((item) => {
                return item.cat_id == data1.category
            })
            setCategoryName(categoryvalue[0].cat_name)
            console.log(unitlist.assignunit, "unitlist.assignunit567777");
            let unitbranch = _.filter(unitlist && unitlist.assignunit && unitlist.assignunit, { category_id: Number(data1.category) })
            setUnitname(unitbranch)
        }
        else if (data1.legalentity !== '') {
            setUnitname(unitlist.assignunit)
        }
        
    }, [data1, unitlist.assignunit])

    useEffect(() => {
        if (tableclick == true) {
            if (approvalonclick == true && unitlist.assign_user != "" && clickassignee.approval_id != "All") {
                let approvallist = approvaldrop && approvaldrop.filter((item) => {
                    return item.usr_id == clickassignee.approval_id
                })
                setApprovalsearch3(approvallist)
            }
            else {
                setApprovalsearch3(approvaldrop)

            }
        }

    }, [approvalonclick, clickassignee, approvaldrop, tableclick])

    useEffect(() => {
        if (tableclick == true) {
            if (assigneeonclick == true && unitlist.assign_user != "" && clickassignee.assignee_id !== "All") {
                let approvallist = assigneedrop.filter((item) => {
                    return item.usr_id == clickassignee.assignee_id
                })
                setAssigneesearch3(approvallist)
            }
            else {
                setAssigneesearch3(assigneedrop)
            }
        }
    }, [assigneeonclick, clickassignee, assigneedrop, tableclick])
    const rowSelection = {

        // columnTitle: <input type="checkbox" name="allchecked" className={'checkclass'} />,
        renderCell: (checked, record, index, originNode) => {
            console.log(record, "recorddddd")
            return (
                <Fragment>
                    <input type="checkbox"
                        name={'checked' + record.parentId}
                        className={'tempclass checkclasschild' + record.parentId}
                        // onClick={(e) => {
                        //     let checked = e.target.checked;
                        //     addSelectedDataValues(
                        //         checked,
                        //         record.comp_id,
                        //         record.childId,
                        //         record.parentId,
                        //         record
                        //     );
                        // }}
                        onClick={(e) => {
                            let checked = e.target.checked;
                            let temp = []
                            temp.push(record.comp_id)
                            setRowCompId([...rowcompid, ...temp])
                            if (e.target.checked == true) {
                                let temp2 = []
                                temp2.push({
                                    id: record.childId,
                                    name: record.comp_name,
                                    frequencyName: record.freq
                                })
                                setChildId([...childId, ...temp2])
                            }
                            else {

                                for (let i in childId) {
                                    if (childId[i].id == record.childId) {
                                        childId.splice(i, 1)
                                        setChildId([...childId])
                                    }

                                }
                                // for (let j in triggerValidation) {
                                //     if (triggerValidation[j].comp_id == record.comp_id) {
                                //         triggerValidation.splice(j, 1)
                                //     }
                                // }
                                // setTriggerValidation([...triggerValidation])
                            }
                            for (let i in collapseindex) {

                                let temp = []
                                temp.push(headerlist && headerlist[collapseindex[i]] && headerlist[collapseindex[i]])
                                setHeader([...header, ...temp])
                            }

                            addSelectedDataValues(
                                checked,
                                record.comp_id,
                                record.childId,
                                record.parentId,
                                record
                            );
                            setTriggerstate(false)
                        }}
                    />
                    <div style={{ marginTop: '8px' }}>
                        {record.freq == 'Review' ? <p><i title='Review' style={{ color: "orange" }} className="bi bi-square-fill"></i></p> :
                            record.freq == 'Periodical' ? <p><i title='Periodical' style={{ color: "green" }} className="bi bi-square-fill"></i></p> :
                                record.freq == 'Flexi Review' ? <p><i title='Flexi Review' style={{ color: "pink" }} className="bi bi-square-fill"></i></p> :
                                    record.freq == 'On Occurrence' ? <p><i title='On Occurrence' style={{ color: "yellow" }} className="bi bi-square-fill"></i></p> :
                                        <p><i style={{ color: "red" }} title={'One Time'} className="bi bi-square-fill"></i></p>
                        }</div>

                </Fragment>
            )

        },
    }
    console.log(triggerValidation, 'triggerValidation');
    console.log(unitlist, 'unitlist');

    useEffect(() => {
        if (tableclick == true) {
            const value123 = unitlist && unitlist.assign_user && unitlist.assign_user.filter((item) => {
                return item.is_approver == true
            })
            const data = value123 && value123.filter((item) => {
                return item.s_u_name != null
            })
            setApprovaldrop(value123)
            const data1 = data && data.map((item) => {
                return item.s_u_name
            })
            const uniqueCharsrow = [...new Set(data1)];
            setSelect(uniqueCharsrow)
            const assignee = unitlist && unitlist.assign_user && unitlist.assign_user.filter((item) => {
                return item.is_assignee == true
            })
            setAssigneedrop(assignee)

        }
    }, [tableclick, unitlist.assign_user, unitlist && unitlist.assign_user && unitlist.assign_user])
    useEffect(() => {
        if (isAuth && domain) {
            if (entityid != '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetAssignComplianceUnits",
                            {
                                "le_id": entityid,
                                "d_id": Number(data1.domainid),
                                "c_id": 1
                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    assign({
                        payload: payload,
                        paramid: paramid
                    })
                }

            }
        }
    }, [isAuth, entityid, data1.domainid])

    useEffect(() => {
        if (isAuth) {

            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssignCompliancesFormData",
                        {}
                    ]
                }
            ]
            selectunit({
                payload: payload1,
                paramid: paramid
            })
        }
    }, [isAuth, authtoken, paramid])
    useEffect(() => {
        for (let i in triggervalue) {
            let temp = []
            temp.push(i)
            setTriggerIndex([...triggerindex, ...temp])
        }

    }, [triggervalue])
    useEffect(() => {
        for (let i in updatedays) {
            let temp1 = []
            temp1.push(i)
            setupdatedaysIndex([...updatedaysindex, ...temp1])
        }
    }, [updatedays])
    useEffect(() => {
        for (let i in updatedateedit) {
            let temp = []
            temp.push(i)
            setUpdateeditindex([...updateeditindex, ...temp])
        }
    }, [updatedateedit])
    useEffect(() => {
        for (let i in date) {
            let temp = []
            temp.push(i)
            setDateIndex([...dateindex, ...temp])
        }
    }, [date])
    console.log(diffid, 'diffiddiffiddiffid');
    console.log(temparr, 'temparr');
    // useEffect(() => {
    //     if (temparr.length > 0) {

    //         let tempArray = []
    //         for (let i in temparr) {
    //             for (let j in EditDate) {
    //                 if (temparr[i].comp_id == EditDate[j].comp_id) {
    //                     tempArray.push({
    //                         "comp_id": temparr[i].comp_id,
    //                         "comp_name": temparr[i].comp_name,
    //                         "statu_dates": [
    //                             {
    //                                 "statutory_date": Number(moment(EditDate[j].date).format("D")),
    //                                 "statutory_month": Number(moment(EditDate[j].date).format("M")),
    //                                 "trigger_before_days": Number(EditDate[j].value),
    //                                 "repeat_by": temparr[i].statu_dates[0].repeat_by
    //                             }
    //                         ],
    //                         "d_date":
    //                             temparr[i].due_date_list[0] == "" ? moment(EditDate[j].date).format("DD-MMM-YYYY") : temparr[i].due_date_list[0],
    //                         "v_date": null,
    //                         "trigger_before_days": Number(EditDate[j].value),
    //                         "u_ids": unitid,
    //                         "repeat_by": temparr[i].repeat_by,
    //                         "r_every": temparr[i].r_every,
    //                         "frequency": EditDate[j].frequency,
    //                         "act": header[j],
    //                         "amendment_history_id": temparr[i].amendment_history_id


    //                     })
    //                 }

    //             }
    //             for (let k in updatedata) {
    //                 if (temparr[i].comp_id == updatedata[k].comp_id) {
    //                     tempArray.push({
    //                         "comp_id": temparr[i].comp_id,
    //                         "comp_name": temparr[i].comp_name,
    //                         "statu_dates": [
    //                             {
    //                                 "statutory_date": Number(moment(updatedata[k].date).format("D")),
    //                                 "statutory_month": Number(moment(updatedata[k].date).format("M")),
    //                                 "trigger_before_days": Number(updatedata[k].value),
    //                                 "repeat_by": temparr[i].statu_dates[0].repeat_by
    //                             }
    //                         ],
    //                         "d_date": moment(updatedata[k].date).format("DD-MMM-YYYY"),
    //                         "v_date": updatedata[k].vdate ? moment(updatedata[k].vdate).format("DD-MMM-YYYY") : null,
    //                         "trigger_before_days": Number(updatedata[k].value),
    //                         "u_ids": unitid,
    //                         "repeat_by": temparr[i].repeat_by,
    //                         "r_every": temparr[i].r_every,
    //                         "frequency": updatedata[k].frequency,
    //                         "act": header[k],
    //                         "amendment_history_id": temparr[i].amendment_history_id

    //                     })

    //                 }
    //             }
    //             for (let x in diffid) {
    //                 let today_date1 = new Date().toISOString().slice(0, 10)
    //                 let today_date = moment(today_date1).format("DD-MMM-YYYY")
    //                 var today = new Date();
    //                 let current_date = today.getDate()
    //                 let current_month = today.getMonth()
    //                 console.log(diffid, 'diffiddiffid');
    //                 console.log(temparr, 'temparr55555');
    //                 if (temparr && temparr.length && temparr[i].comp_id == diffid && diffid[x]) {
    //                     console.log('testtttttttt');
    //                     // tempArray.push({
    //                     //     "comp_id": temparr[i].comp_id,
    //                     //     "comp_name": temparr[i].comp_name,
    //                     //     "statu_dates": [
    //                     //         {
    //                     //             "statutory_date": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].statutory_date : current_date,
    //                     //             "statutory_month": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].statutory_month : current_month,
    //                     //             "trigger_before_days": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].trigger_before_days : null,
    //                     //             "repeat_by": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].repeat_by : null
    //                     //         }
    //                     //     ],
    //                     //     "d_date": temparr[i].due_date_list[0] == "" ? today_date : temparr[i].due_date_list[0],
    //                     //     "v_date": null,
    //                     //     "trigger_before_days": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].trigger_before_days : null,
    //                     //     "u_ids": unitid,
    //                     //     "repeat_by": temparr[i].repeat_by,
    //                     //     "r_every": temparr[i].r_every,
    //                     //     "frequency": temparr[i].freq,
    //                     //     "act": header[i],
    //                     //     "amendment_history_id": temparr[i].amendment_history_id


    //                     // })
    //                     tempArray.push({

    //                         "comp_id": temparr[i].comp_id,

    //                         "comp_name": temparr[i].comp_name,

    //                         "statu_dates":

    //                             temparr[i].statu_dates && temparr[i].statu_dates.length > 0 && temparr[i].statu_dates[0] && temparr[i].statu_dates[0].trigger_before_days ?

    //                                 [

    //                                     {

    //                                         "statutory_date": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].statutory_date : current_date,

    //                                         "statutory_month": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].statutory_month : current_month,

    //                                         "trigger_before_days": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].trigger_before_days : null,

    //                                         "repeat_by": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].repeat_by : null

    //                                     }

    //                                 ] : null,

    //                         "d_date": temparr[i].due_date_list[0] != undefined && temparr[i].due_date_list[0] == "" ? today_date : temparr[i].due_date_list[0] ? temparr[i].due_date_list[0] : null,

    //                         "v_date": null,

    //                         "trigger_before_days": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].trigger_before_days : null,

    //                         "u_ids": unitid,

    //                         "repeat_by": temparr[i].repeat_by,

    //                         "r_every": temparr[i].r_every,

    //                         "frequency": temparr[i].freq,

    //                         "act": header[i],

    //                         "amendment_history_id": temparr[i].amendment_history_id




    //                     })
    //                 }

    //             }
    //             console.log(tempArray, 'tempArray');

    //         }
    //         if (tempArray != "") {
    //             setAssignPayload([...assignpayload, ...tempArray])

    //         }

    //     }
    // }, [updatedata, EditDate, temparr, header, unitid, diffid])
    useEffect(() => {
        if (temparr.length > 0) {

            let tempArray = []
            for (let i in temparr) {
                // for(let j in headerlist){
                // for (let j in EditDate) {
                //     if (temparr[i].comp_id == EditDate[j].comp_id) {
                //         tempArray.push({
                //             "comp_id": temparr[i].comp_id,
                //             "comp_name": temparr[i].comp_name,
                //             "statu_dates": [
                //                 {
                //                     "statutory_date": Number(moment(EditDate[j].date).format("D")),
                //                     "statutory_month": Number(moment(EditDate[j].date).format("M")),
                //                     "trigger_before_days": Number(EditDate[j].value),
                //                     "repeat_by": temparr[i].statu_dates[0].repeat_by
                //                 }
                //             ],
                //             "d_date":
                //                 temparr[i].due_date_list[0] == "" ? moment(EditDate[j].date).format("DD-MMM-YYYY") : temparr[i].due_date_list[0],
                //             "v_date": null,
                //             "trigger_before_days": Number(EditDate[j].value),
                //             "u_ids": unitid,
                //             "repeat_by": temparr[i].repeat_by,
                //             "r_every": temparr[i].r_every,
                //             "frequency": EditDate[j].frequency,
                //             "act": header[j],
                //             "amendment_history_id": temparr[i].amendment_history_id


                //         })
                //     }

                // }
                // for (let k in updatedata) {
                //     if (temparr[i].comp_id == updatedata[k].comp_id) {
                //         tempArray.push({
                //             "comp_id": temparr[i].comp_id,
                //             "comp_name": temparr[i].comp_name,
                //             "statu_dates": [
                //                 {
                //                     "statutory_date": Number(moment(updatedata[k].date).format("D")),
                //                     "statutory_month": Number(moment(updatedata[k].date).format("M")),
                //                     "trigger_before_days": Number(updatedata[k].value),
                //                     "repeat_by": temparr[i].statu_dates[0].repeat_by
                //                 }
                //             ],
                //             "d_date": moment(updatedata[k].date).format("DD-MMM-YYYY"),
                //             "v_date": updatedata[k].vdate ? moment(updatedata[k].vdate).format("DD-MMM-YYYY") : null,
                //             "trigger_before_days": Number(updatedata[k].value),
                //             "u_ids": unitid,
                //             "repeat_by": temparr[i].repeat_by,
                //             "r_every": temparr[i].r_every,
                //             "frequency": updatedata[k].frequency,
                //             "act": header[k],
                //             "amendment_history_id": temparr[i].amendment_history_id

                //         })

                //     }
                // }
                // for (let x in diffid) {
                //     let today_date1 = new Date().toISOString().slice(0, 10)
                //     let today_date = moment(today_date1).format("DD-MMM-YYYY")
                //     var today = new Date();
                //     let current_date = today.getDate()
                //     let current_month = today.getMonth()
                //     console.log(diffid, 'diffiddiffid');
                //     console.log(temparr, 'temparr55555');
                //     if (temparr && temparr.length && temparr[i].comp_id == diffid && diffid[x]) {
                //         console.log('testtttttttt');
                //         // tempArray.push({
                //         //     "comp_id": temparr[i].comp_id,
                //         //     "comp_name": temparr[i].comp_name,
                //         //     "statu_dates": [
                //         //         {
                //         //             "statutory_date": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].statutory_date : current_date,
                //         //             "statutory_month": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].statutory_month : current_month,
                //         //             "trigger_before_days": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].trigger_before_days : null,
                //         //             "repeat_by": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].repeat_by : null
                //         //         }
                //         //     ],
                //         //     "d_date": temparr[i].due_date_list[0] == "" ? today_date : temparr[i].due_date_list[0],
                //         //     "v_date": null,
                //         //     "trigger_before_days": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].trigger_before_days : null,
                //         //     "u_ids": unitid,
                //         //     "repeat_by": temparr[i].repeat_by,
                //         //     "r_every": temparr[i].r_every,
                //         //     "frequency": temparr[i].freq,
                //         //     "act": header[i],
                //         //     "amendment_history_id": temparr[i].amendment_history_id


                //         // })
                //         tempArray.push({

                //             "comp_id": temparr[i].comp_id,

                //             "comp_name": temparr[i].comp_name,

                //             "statu_dates":

                //                 temparr[i].statu_dates && temparr[i].statu_dates.length > 0 && temparr[i].statu_dates[0] && temparr[i].statu_dates[0].trigger_before_days ?

                //                     [

                //                         {

                //                             "statutory_date": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].statutory_date : current_date,

                //                             "statutory_month": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].statutory_month : current_month,

                //                             "trigger_before_days": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].trigger_before_days : null,

                //                             "repeat_by": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].repeat_by : null

                //                         }

                //                     ] : null,

                //             "d_date": temparr[i].due_date_list[0] != undefined && temparr[i].due_date_list[0] == "" ? today_date : temparr[i].due_date_list[0] ? temparr[i].due_date_list[0] : null,

                //             "v_date": null,

                //             "trigger_before_days": temparr[i].statu_dates.length ? temparr[i].statu_dates[0].trigger_before_days : null,

                //             "u_ids": unitid,

                //             "repeat_by": temparr[i].repeat_by,

                //             "r_every": temparr[i].r_every,

                //             "frequency": temparr[i].freq,

                //             "act": header[i],

                //             "amendment_history_id": temparr[i].amendment_history_id




                //         })
                //     }

                // }

                let childId = temparr[i].chilId

                tempArray.push({
                    "comp_id": temparr[i].comp_id,
                    "comp_name": temparr[i].comp_name,
                    "statu_dates": [
                        {
                            "statutory_date": temparr[i].statu_dates.length > 0 ? Number(moment(dueDate[childId]).format("D")) : null,
                            "statutory_month": temparr[i].statu_dates.length > 0 ? Number(moment(dueDate[childId]).format("M")) : null,
                            "trigger_before_days": temparr[i].statu_dates.length > 0 ? Number(triggerDays[childId]) : null,
                            "repeat_by": temparr[i].statu_dates.length > 0 ? temparr[i].statu_dates[0].repeat_by : null
                        }
                    ],
                    "d_date": temparr[i].statu_dates.length > 0 ? dueDate[childId] : null,
                    // temparr[i].due_date_list[0] == "" ? moment(EditDate[j].date).format("DD-MMM-YYYY") : temparr[i].due_date_list[0],
                    "v_date": temparr[i].freq !== 'On Occurrence' ? validityDate[childId] : null,
                    "trigger_before_days": temparr[i].statu_dates.length > 0 ? Number(triggerDays[childId]) : null,
                    "u_ids": unitid,
                    "repeat_by": temparr[i].repeat_by,
                    "r_every": temparr[i].r_every,
                    "frequency": temparr[i].freq,
                    "amendment_history_id": temparr[i].amendment_history_id,
                    "act": "Testing Act",
                    // "act": headerlist[j],



                })
            }
            // }




            if (tempArray != "") {
                setAssignPayload(tempArray)

            }

        }
    }, [triggerDays, dueDate, validityDate, temparr, headerlist])
    console.log(rowcompid, 'rowcompid');

    useEffect(() => {
        let con = updatedata.concat(editValue)
        let ss = con.map((item) => {
            return item.comp_id
        })
        var difference = rowcompid && rowcompid.filter(x => ss.indexOf(x) === -1);
        setDiffId(difference)

    }, [rowcompid])

    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    return (
        <>
            <div id='page-wrapper' className="page-wrapper">

                <div className='back-to-top'>
                    <ScrollButton />
                </div>
                <div className="page-titles pb-0 pt-2">
                    <div className="row d-flex">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Transaction</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span>Assign Compliance</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>



                        <div className="col-lg-4 text-end">

                            {key == 2 && temparr.length > 0 ? <>
                                {triggerstate == false ?
                                    <Tooltip placement='top' title='Select Assignee'>
                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'black' }}
                                            size='default' onClick={Next2}>
                                            Select Assignee <i> </i>
                                        </Button> </Tooltip>
                                    : <Tooltip placement='top' title='Select Assignee'><Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right', color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'black' }}

                                        size='default' onClick={() => { toast.error("Trigger before days should not exceed 100") }}>
                                        Select Assignee <i></i>
                                    </Button></Tooltip>}
                            </> : ''}

                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>
                        </div>

                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card servcardround mb-2">
                                <div className="card-body" style={{ padding: "10px 15px" }}>
                                    <Tabs activeKey={key} type="card">
                                        <TabPane tab="Select Vendor" disabled key="1">
                                            {/* <div className="col-md-12 mt-0">
                                            <div className="row">
                                                <div className="col-md-2">

                                                </div>
                                                <div className="col-md-8" style={{ marginLeft: '35%' }}>
                                                    <Tag color="red">Legal Entity-4</Tag>
                                                    <Tag color="green">Domain-3</Tag>
                                                    <Tag color="purple">Unit-10</Tag>
                                                    <Tag color="blue">Frequency-5</Tag>
                                                </div>

                                                <div className="col-md-2">

                                                </div>

                                            </div>
                                        </div> */}
                                            <div className={localStorage.getItem("currentTheme")}>
                                                <Collapse defaultActiveKey={["1"]}>
                                                    {/* <Panel header="Overall Statistics" key={key} extra={`Selected Units : ${selectedRows.length} Total Compliances : 447 Locked count : 1 Unlocked count : 2`} > */}
                                                    <Panel className='report-collapse' header="Overall Statistics" key={keys}  >
                                                        <Row gutter={24}>
                                                            <Col span={5}>
                                                                <div className="card mb-0 assign-card">
                                                                    <div className="card-body p-3">
                                                                        <div className="layer"></div>
                                                                        <div className="d-flex flex-row align-items-center assign-card-content">
                                                                            <div className="icon-card text-success h1">
                                                                                <i className="fas fa-users"></i>
                                                                            </div>
                                                                            <div className="ps-3 ms-3 align-self-center border-start border-2">
                                                                                <h3 style={{ fontSize: '2rem', fontWeight: '800', lineHeight: '0.9' }} className="mb-0">{statisticentity && Array.isArray(statisticentity) ? statisticentity.length : 1}</h3>
                                                                                <span className="text-muted">Legal Entity</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col span={5}>
                                                                <div className="card mb-0 assign-card">
                                                                    <div className="card-body p-3">
                                                                        <div className="layer"></div>
                                                                        <div className="d-flex flex-row align-items-center assign-card-content">
                                                                            <div className="icon-card text-danger h1 ">
                                                                                <i className="fas fa-globe"></i>
                                                                            </div>
                                                                            <div className="ps-3 ms-3 align-self-center border-start border-2">
                                                                                <h3 style={{ fontSize: '2rem', fontWeight: '800', lineHeight: '0.9' }} className="mb-0">{domain ? domain.length : 0}</h3>
                                                                                <span className="text-muted">Domain</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col span={4}>
                                                                <div className="card mb-0 assign-card">
                                                                    <div className="card-body p-3">
                                                                        <div className="layer"></div>
                                                                        <div className="d-flex flex-row align-items-center assign-card-content">
                                                                            <div className="icon-card text-secondary h1 ">
                                                                                <i className="fas fa-th-list"></i>
                                                                            </div>
                                                                            <div className="ps-3 ms-3 align-self-center border-start border-2">
                                                                                <h3 style={{ fontSize: '2rem', fontWeight: '800', lineHeight: '0.9' }} className="mb-0">{unitname.length}</h3>
                                                                                <span className="text-muted">Vendor</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col span={5}>
                                                                <div className="card mb-0 assign-card">
                                                                    <div className="card-body p-3">
                                                                        <div className="layer"></div>
                                                                        <div className="d-flex flex-row align-items-center assign-card-content">
                                                                            <div className="icon-card text-warning h1 fw-bolder">
                                                                                <i className="fas fa-history"></i>
                                                                            </div>
                                                                            <div className="ps-3 ms-3 align-self-center border-start border-2">
                                                                                <h3 style={{ fontSize: '2rem', fontWeight: '800', lineHeight: '0.9' }} className="mb-0">{ data1.unit1?.length > 0 ?  compliancefrequency.length : 0}  </h3>
                                                                                <span className="text-muted">Frequency</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col span={5}>
                                                                <div className="card mb-0 assign-card">
                                                                    <div className="card-body p-3">
                                                                        <div className="layer"></div>
                                                                        <div className="d-flex flex-row align-items-center assign-card-content">
                                                                            <div className="icon-card text-primary h1 ">
                                                                                <i className="fas fa-user-times"></i>
                                                                            </div>
                                                                            <div className="ps-3 ms-3 align-self-center border-start border-2">
                                                                                <h3 style={{ fontSize: '2rem', fontWeight: '800', lineHeight: '0.9' }} className="mb-0">25</h3>
                                                                                <span className="text-muted">Un Assigned</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        {/* <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                                    <div style={{ height: '50%', paddingTop: '5px' }}>
                                                        <a>
                                                            <div className="card p-2 w-100 m-0 overflow-hidden card-hover" style={{ borderColor: 'brown' }}>
                                                                <div className="d-flex align-items-center">
                                                                    <div>
                                                                        <h3 className="pt-1 mb-0 h1">
                                                                            <b style={{ color: 'brown' }}>&nbsp;{5}</b>
                                                                        </h3>
                                                                        <h6 className="text-muted mb-0 fw-normal"><b style={{ color: 'brown' }}>Legal Entity</b></h6>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <span
                                                                            className=" btn btn-m btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center">
                                                                            <FeatherIcon icon="check-square" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div id="monthly-sales"></div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div style={{ height: '50%', paddingTop: '5px' }}>
                                                        <a><div className="card p-2 w-100 m-0 overflow-hidden card-hover" style={{ borderColor: 'orange' }}>
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <h3 className="pt-1 mb-0 h1">
                                                                        <b style={{ color: 'orange' }}> 447</b>
                                                                    </h3>
                                                                    <h6 className="text-muted mb-0 fw-normal"><b style={{ color: 'orange' }}>Domain</b></h6>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <span
                                                                        className=" btn btn-m btn-light-warning text-warning btn-circle d-flex align-items-center justify-content-center">
                                                                        <FeatherIcon icon="clipboard" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div id="monthly-sales1"></div>
                                                        </div></a>
                                                    </div>
                                                    <div style={{ height: '50%', paddingTop: '5px' }}>
                                                        <a><div className="card p-2 w-100 m-0 m-0 overflow-hidden card-hover" style={{ borderColor: 'red' }}>
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <h3 className="pt-1 mb-0 h1">
                                                                        <b style={{ color: 'red' }}>1</b>
                                                                    </h3>
                                                                    <h6 className="text-muted mb-0 fw-normal"><b style={{ color: 'red' }}>Unit</b></h6>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <span
                                                                        className=" btn btn-m btn-light-danger
                                        text-danger btn-circle d-flex
                                        align-items-center justify-content-center">
                                                                        <FeatherIcon icon="lock" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div id="monthly-sales4"></div>
                                                        </div></a>
                                                    </div>
                                                    <div style={{ height: '50%', paddingTop: '5px' }}>
                                                        <a><div className="card p-2 w-100 m-0 overflow-hidden card-hover" style={{ borderColor: 'green' }}>
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <h3 className="pt-1 mb-0 h1">
                                                                        <b style={{ color: 'green' }}>2</b>
                                                                    </h3>
                                                                    <h6 className="text-muted mb-0 fw-normal fs-2"><b style={{ color: 'green' }}>Frequency</b></h6>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <span
                                                                        className="btn btn-m btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                        <FeatherIcon icon="unlock" />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div id="monthly-sales3"></div>
                                                        </div></a>
                                                    </div>
                                                </div> */}

                                                        {/* <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <p><b>Selected Unit : </b>&nbsp;{selectedRows.length} </p>
                            <p><b>No.of Compliances: </b>&nbsp; 2055 </p>
                            <div style={{ display: 'flex' }}><span className="btn btn-m btn-light-danger
                              text-danger btn-circle d-flex
                              align-items-center justify-content-center"
                              style={{
                                width: "30px",
                                height: "30px"
                              }}><Tooltip title='Locked items count'><FeatherIcon icon="lock" /></Tooltip></span> 3</div>
                            <div style={{ display: 'flex' }}><span className="btn btn-m btn-light-success text-success btn-circle d-flex align-items-center justify-content-center" style={{
                              width: "30px",
                              height: "30px"
                            }}>
                              <Tooltip title='Unlocked items count'><FeatherIcon icon="unlock" /></Tooltip></span> 1</div>
                          </div> */}

                                                    </Panel>
                                                </Collapse>
                                            </div>
                                            <div className='col-md-12 mt-1'>
                                                <div className='row'>
                                                    {/* <div className='col-md-4'>
                                                    <div class="did-floating-label-content">
                                                        <select className='did-floating-input'

                                                            // defaultValue={localStorage.getItem('SelectedEntity') === "All Legal Entity" ? "" : localStorage.getItem('SelectedEntity')}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Legal Entity"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    setCurrentEntity(data)
                                                                    setData({
                                                                        ...data1,
                                                                        legalentity: data,
                                                                        division: '',
                                                                        domain: '',
                                                                        category: '',
                                                                        timezone: '',
                                                                        unit1: '',
                                                                        compliance_frequency: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setCurrentEntity("")
                                                                    setData({
                                                                        ...data1,
                                                                        legalentity: "",
                                                                        division: '',
                                                                        domain: '',
                                                                        category: '',
                                                                        timezone: '',
                                                                        unit1: '',
                                                                        compliance_frequency: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.legalentity ? data1.legalentity : undefined}
                                                            style={{ width: "100%", marginTop: "5px", title: '' }}
                                                        >
                                                            {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                return (
                                                                    <option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                        <label class="did-floating-label">Legal Entity</label>
                                                    </div>
                                                </div> */}
                                                    <div className='col-md-4'>
                                                        <label><b>Legal Entity : </b> <span style={{ color: "red" }}>*</span></label>
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select

                                                                    // defaultValue={localStorage.getItem('SelectedEntity') === "All Legal Entity" ? "" : localStorage.getItem('SelectedEntity')}
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Select Legal Entity"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                    }}
                                                                    onChange={(data, value) => {
                                                                        if (data !== undefined) {
                                                                            setCurrentEntity(data)
                                                                            let domainData = _.filter(unitlist && unitlist.domain, { le_id: data })
                                                                            setDomain(domainData)
                                                                            setData({
                                                                                ...data1,
                                                                                legalentity: data,
                                                                                division: '',
                                                                                domain: '',
                                                                                category: '',
                                                                                timezone: '',
                                                                                unit1: '',
                                                                                domainid: '',
                                                                                compliance_frequency: ''
                                                                            })
                                                                        }
                                                                        else {
                                                                            setComplianceFrequency([])
                                                                            setDomain([])
                                                                            setUnitname([])
                                                                            setCurrentEntity("")
                                                                            setComplianceFrequency([])
                                                                            setData({
                                                                                // ...data1,
                                                                                legalentity: "",
                                                                                division: '',
                                                                                domain: '',
                                                                                category: '',
                                                                                timezone: '',
                                                                                domainid: '',
                                                                                unit1: '',
                                                                                compliance_frequency: ''
                                                                            })
                                                                        }
                                                                        setAddFormSubmit(false)
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={data1.legalentity ? data1.legalentity : undefined}
                                                                    style={{ width: "100%", marginTop: "5px", title: '' }}
                                                                >
                                                                    {sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'legalentity',
                                                                    data1.legalentity,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Legal Entity Required',
                                                                        }
                                                                    })}
                                                            </div>
                                                            :
                                                            <p title={localStorage.getItem('SelectedEntity')} className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                        }
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Domain"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                }}
                                                                onChange={(value, data) => {


                                                                    if (value !== undefined) {
                                                                        setData({
                                                                            ...data1,
                                                                            domain: data.children,
                                                                            domainid: value,
                                                                            unit1: '',
                                                                            timezone: unitlist.timezone && unitlist.timezone[0] && unitlist.timezone[0].tz_name
                                                                        })
                                                                    }
                                                                    else {
                                                                        setData({
                                                                            ...data1,
                                                                            domain: '',
                                                                            domainid: '',
                                                                            unit1: '',
                                                                            timezone: ''
                                                                        })
                                                                    }
                                                                    setAddFormSubmit(false)
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={data1.domain || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                                disabled={data1.legalentity == '' ? true : false}
                                                            >
                                                                {domain && domain.length > 0 && domain.map((item, i) => {


                                                                    return (
                                                                        <Option key={item.d_id}>
                                                                            {item.d_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>

                                                            {validator.current.message(
                                                                'domain',
                                                                data1.domain,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Domain Required',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Time Zone : </b><span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                disabled={data1.domain != "" && data1.timezone == "" ? false : true}
                                                                size="default"
                                                                placeholder="Enter TimeZone"
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={data1.domain ? unitlist && unitlist.timezone && unitlist.timezone[0] && unitlist.timezone[0].tz_name : undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                            >

                                                            </Select>

                                                        </div>
                                                    </div>
                                                    {/* <div className='col-md-4'>
                                                    <div class="did-floating-label-content">
                                                        <select class="did-floating-input"
                                                            allowClear={true}
                                                            // disabled={data.legalentity == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Division"
                                                            onChange={(value) => {
                                                                if (value !== undefined) {
                                                                    let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    setCategory(CategoryData)
                                                                    setData({
                                                                        ...data1,
                                                                        division: value,
                                                                        category: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setCategory([])
                                                                    setData({
                                                                        ...data1,
                                                                        division: "",
                                                                        category: ''
                                                                    })
                                                                }

                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.division || undefined}
                                                            style={{ width: "100%" }}
                                                            disabled={data1.legalentity == '' ? true : false}
                                                        >
                                                            {unitlist.division && unitlist.division.length > 0 && unitlist.division.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </select>
                                                        <label class="did-floating-label">Division</label>
                                                    </div>
                                                </div> */}
                                                    {/* <div className='col-md-4'>
                                                    <div class="did-floating-label-content">
                                                        <select class="did-floating-input"
                                                            allowClear={true}
                                                            disabled={data1.division == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Category"
                                                            onChange={(value) => {
                                                                if (value !== undefined) {
                                                                    setData({
                                                                        ...data1,
                                                                        category: value
                                                                    })
                                                                }
                                                                else {
                                                                    setData({
                                                                        ...data1,
                                                                        category: ""
                                                                    })
                                                                }
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.category || undefined}
                                                            style={{ width: "100%" }}

                                                        >
                                                            {category.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <option key={item.cat_id}>
                                                                        {item.cat_name}
                                                                    </option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </select>

                                                        <label class="did-floating-label">Category</label>
                                                    </div>
                                                </div> */}
                                                </div>
                                            </div>

                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    {/* <div className='col-md-4'>
                                                    <div class="did-floating-label-content">
                                                        <select class="did-floating-input"
                                                            allowClear={true}
                                                            // disabled={data.legalentity == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Division"
                                                            onChange={(value) => {
                                                                if (value !== undefined) {
                                                                    let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    setCategory(CategoryData)
                                                                    setData({
                                                                        ...data1,
                                                                        division: value,
                                                                        category: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setCategory([])
                                                                    setData({
                                                                        ...data1,
                                                                        division: "",
                                                                        category: ''
                                                                    })
                                                                }

                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.division || undefined}
                                                            style={{ width: "100%" }}
                                                            disabled={data1.legalentity == '' ? true : false}
                                                        >
                                                            {unitlist.division && unitlist.division.length > 0 && unitlist.division.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </select>
                                                        <label class="did-floating-label">Domain</label>
                                                    </div>
                                                </div>  */}
                                                    {/* <div className='col-md-4'>
                                                    <div class="did-floating-label-content">
                                                        <select class="did-floating-input"
                                                            allowClear={true}
                                                            // disabled={data.legalentity == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Division"
                                                            onChange={(value) => {
                                                                if (value !== undefined) {
                                                                    let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    setCategory(CategoryData)
                                                                    setData({
                                                                        ...data1,
                                                                        division: value,
                                                                        category: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setCategory([])
                                                                    setData({
                                                                        ...data1,
                                                                        division: "",
                                                                        category: ''
                                                                    })
                                                                }

                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.division || undefined}
                                                            style={{ width: "100%" }}
                                                            disabled={data1.legalentity == '' ? true : false}
                                                        >
                                                            {unitlist.division && unitlist.division.length > 0 && unitlist.division.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </select>
                                                        <label class="did-floating-label">Time Zone</label>
                                                    </div>
                                                </div>   */}
                                                    {/* <div className='col-md-4'>
                                                    <div class="did-floating-label-content">
                                                        <select class="did-floating-input"
                                                            allowClear={true}
                                                            // disabled={data.legalentity == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Division"
                                                            onChange={(value) => {
                                                                if (value !== undefined) {
                                                                    let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                    setCategory(CategoryData)
                                                                    setData({
                                                                        ...data1,
                                                                        division: value,
                                                                        category: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setCategory([])
                                                                    setData({
                                                                        ...data1,
                                                                        division: "",
                                                                        category: ''
                                                                    })
                                                                }

                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.division || undefined}
                                                            style={{ width: "100%" }}
                                                            disabled={data1.legalentity == '' ? true : false}
                                                        >
                                                            {unitlist.division && unitlist.division.length > 0 && unitlist.division.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <option key={item.div_id}>
                                                                        {item.div_name}
                                                                    </option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </select>
                                                        <label class="did-floating-label">Unit</label>
                                                    </div>
                                                </div> */}
                                                    <div className='col-md-4'>
                                                        <label><b>Vendor : </b><span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                mode="multiple"
                                                                allowClear={false}
                                                                disabled={data1.domain == "" ? true : false}
                                                                size="default"
                                                                placeholder="Enter Vendor"
                                                                maxTagCount='responsive'
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                }}
                                                                onChange={(value) => {
                                                                    setAddFormSubmit(false)

                                                                    let fre = []
                                                                    for (const k in value) {
                                                                        let arr = unitname && unitname.filter((item, i) => {
                                                                            return item.u_name === value[k]
                                                                        })
                                                                        fre.push(...arr)
                                                                    }
                                                                    setUId(fre)

                                                                    let temp = []
                                                                    temp.push()
                                                                    setData({
                                                                        ...data1,
                                                                        unit1: value,
                                                                        compliance_frequency: ''
                                                                    })

                                                                    setSelectUnit1(value)
                                                                    setAddFormSubmit(false)

                                                                }}


                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={data1.unit1 || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                            >
                                                                {unitname && unitname.length > 0 && unitname.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.u_name}>
                                                                            {item.u_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'unit',
                                                                data1.unit1,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Vendor Required',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label><b>Compliance Frequency : </b><span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                mode="multiple"
                                                                allowClear={false}
                                                                size="default"
                                                                maxTagCount='responsive'
                                                                placeholder="Enter Compliance Frequency"
                                                                disabled={data1.unit1 == "" ? true : false}
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                }}
                                                                onChange={(value) => {
                                                                    let fre = []
                                                                    for (const k in value) {
                                                                        let arr = unitlist.unitdata.filter((item, i) => {
                                                                            return item.frequency === value[k]
                                                                        })
                                                                        fre.push(...arr)
                                                                    }

                                                                    setFreqid(fre)

                                                                    setData({
                                                                        ...data1,
                                                                        compliance_frequency: value
                                                                    })



                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={data1.compliance_frequency || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                            >
                                                                {compliancefrequency && compliancefrequency.length > 0 && compliancefrequency.map((item, i) => {

                                                                    return (
                                                                        <Option key={item.frequency}>
                                                                            {item.frequency}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'compliance',
                                                                data1.compliance_frequency,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Compliance Frequency Required',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <label><b>Division : </b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                // disabled={data.legalentity == "" ? true : false}
                                                                size="default"
                                                                placeholder="Enter Division"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                }}
                                                                onChange={(value) => {
                                                                    if (value !== undefined) {
                                                                        let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                                        setCategory(CategoryData)
                                                                        setData({
                                                                            ...data1,
                                                                            division: value,
                                                                            category: ''
                                                                        })
                                                                    }
                                                                    else {
                                                                        setCategory([])
                                                                        setData({
                                                                            ...data1,
                                                                            division: "",
                                                                            category: ''
                                                                        })
                                                                    }

                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={data1.division || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}
                                                                disabled={data1.legalentity == '' ? true : false}
                                                            >
                                                                {divisionlist && divisionlist.length > 0 && divisionlist.map((item, i) => {
                                                                    return (
                                                                        // data.unit ?
                                                                        <Option key={item.div_id}>
                                                                            {item.div_name}
                                                                        </Option>
                                                                        // :""
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>

                                                <div className='col-md-4'>
                                                        <label><b>Category : </b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                disabled={data1.division == "" ? true : false}
                                                                size="default"
                                                                placeholder="Enter Category"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                }}
                                                                onChange={(value) => {
                                                                    if (value !== undefined) {
                                                                        setData({
                                                                            ...data1,
                                                                            category: value
                                                                        })
                                                                    }
                                                                    else {
                                                                        setData({
                                                                            ...data1,
                                                                            category: ""
                                                                        })
                                                                    }
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={data1.category || undefined}
                                                                style={{ width: "100%", marginTop: "5px" }}

                                                            >
                                                                {category.map((item, i) => {
                                                                    return (
                                                                        // data.unit ?
                                                                        <Option key={item.cat_id}>
                                                                            {item.cat_name}
                                                                        </Option>
                                                                        // :""
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    {/* <div className='col-md-4'>
                                                    <div class="did-floating-label-content">
                                                        <select class="did-floating-input"
                                                            allowClear={true}
                                                            disabled={data1.division == "" ? true : false}
                                                            size="default"
                                                            placeholder="Enter Category"
                                                            onChange={(value) => {
                                                                if (value !== undefined) {
                                                                    setData({
                                                                        ...data1,
                                                                        category: value
                                                                    })
                                                                }
                                                                else {
                                                                    setData({
                                                                        ...data1,
                                                                        category: ""
                                                                    })
                                                                }
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={data1.category || undefined}
                                                            style={{ width: "100%" }}

                                                        >
                                                            {category.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <option key={item.cat_id}>
                                                                        {item.cat_name}
                                                                    </option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </select>

                                                        <label class="did-floating-label">Compliance Frequency</label>
                                                    </div>
                                                </div> */}
                                                    <div className='col-md-4'></div>


                                                </div>
                                            </div>
                                            <div className='text-end'>
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    size='default' onClick={Next1}> Next <i> <ArrowRightOutlined /></i>
                                                </Button>
                                                {/* <button className="nxt-button" style={{ verticalAlign: 'middle' }}
                                                onClick={Next1}><span>Next</span>
                                            </button> */}
                                            </div>

                                        </TabPane>

                                        <TabPane tab="Select Compliance Task" disabled key="2">
                                            {/* collapseAssignee1 == true &&  */}
                                            {collapseAssignee1 == true && temparr && temparr.length > 0 ?

                                                <div className="col-md-12" style={{ padding: "0px 20px" }}>
                                                    <div className={"row assign " + localStorage.getItem("currentTheme")}>
                                                        <Collapse key={0}
                                                            // className='report-collapse'
                                                            onChange={(e) => {
                                                                if (collapseAssignee == '0') {
                                                                    setCollapseAssignee('1')

                                                                }
                                                                else {
                                                                    setCollapseAssignee('0')
                                                                }
                                                            }}

                                                            defaultActiveKey={'0'}
                                                            activeKey={collapseAssignee}
                                                        >

                                                            <Panel header={`Selected Compliances : ${temparr.length}`} key="1">

                                                                {/* <Panel header={collapseAssignee1 == false ? 'Select Compliance Task' : `Selected Compliances : ${temparr.length}`} key='1' disabled={collapseAssignee1 == false ? true : false}
                                                            // extra={collapseAssignee1 == true ? <><span style={{ paddingRight: '50px' }}>Selected Tasks : {temparr.length}</span></> : false}
                                                            > */}
                                                                {unitlist.restricted && unitlist.restricted.length > 0 ?

                                                                    <div className="col-md-12" style={{ marginTop: '0px' }}>
                                                                        <div className="row">
                                                                            <div className="col-md-3">
                                                                                <label><b>Assignee </b><span style={{ color: "red" }}>*</span></label>

                                                                                <Select
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Select Assignee"
                                                                                    onChange={(value, data) => {

                                                                                        if (value !== undefined) {
                                                                                            // var merged = [].concat.apply([], data.children);
                                                                                            setAssigneelist({
                                                                                                ...assigneelist,
                                                                                                assign_id: value,
                                                                                                assignee: data.children[0] - data.children[1] - data.children[2]
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setAssigneelist({
                                                                                                ...assigneelist,
                                                                                                assign_id: '',
                                                                                                assignee: ''
                                                                                            })
                                                                                        }

                                                                                    }}
                                                                                    // onSearch={onSearch}
                                                                                    showSearch
                                                                                    optionFilterProp="children"
                                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    value={assigneelist.assignee || undefined}
                                                                                    style={{ width: "100%" }}
                                                                                >
                                                                                    {/* <Option key="All">All</Option> */}

                                                                                    {assigneedrop && assigneedrop.map((item, i) => {

                                                                                        if (item.emp_code !== null) {
                                                                                            return (


                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_code}-{item.emp_name}
                                                                                                </Option>
                                                                                                // :""
                                                                                            );
                                                                                        }
                                                                                        else {
                                                                                            return (


                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_name}
                                                                                                </Option>
                                                                                                // :""
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                                </Select>
                                                                                {validator1.current.message(
                                                                                    'Assignee',
                                                                                    assigneelist.assign_id,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Assignee Required',
                                                                                        }
                                                                                    })}

                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <label><b>Approval </b><span style={{ color: "red" }}>*</span></label>
                                                                                <Select
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Select Approver"
                                                                                    onChange={(value, data) => {
                                                                                        console.log(value, "valueeee11")
                                                                                        console.log(data, "dataaaaa11")
                                                                                        if (value !== undefined) {
                                                                                            setAssigneelist({
                                                                                                ...assigneelist,
                                                                                                approval_id: value,
                                                                                                approval: data.children[0] - data.children[1] - data.children[2]
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setAssigneelist({
                                                                                                ...assigneelist,
                                                                                                approval_id: '',
                                                                                                approval: ''
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                    // onSearch={onSearch}
                                                                                    showSearch
                                                                                    optionFilterProp="children"
                                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    value={assigneelist.approval || undefined}
                                                                                    style={{ width: "100%" }}
                                                                                >
                                                                                    {/* <Option key="All">All</Option> */}
                                                                                    {approvaldrop && approvaldrop.map((item, i) => {

                                                                                        // {/* {unitlist && unitlist.assign_user && unitlist.assign_user.map((item, i) => { */}
                                                                                        if (item.emp_code !== null) {
                                                                                            console.log(item, 'itemitemmmm22')

                                                                                            return (


                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_code}-{item.emp_name}
                                                                                                </Option>
                                                                                                // :""
                                                                                            );
                                                                                        }
                                                                                        else {
                                                                                            return (


                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_name}
                                                                                                </Option>
                                                                                                // :""
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                                </Select>
                                                                                {validator1.current.message(
                                                                                    'Approval',
                                                                                    assigneelist.approval,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Approval Required',
                                                                                        }
                                                                                    })}
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <label><b>View Only Restricted User </b></label>
                                                                                <Select
                                                                                    allowClear={false}
                                                                                    size="default"
                                                                                    placeholder="Enter View only restricted only"
                                                                                    onChange={(value) => {

                                                                                        setData({
                                                                                            ...data1,
                                                                                            vor_id: value,

                                                                                        })
                                                                                    }}
                                                                                    // onSearch={onSearch}
                                                                                    showSearch
                                                                                    optionFilterProp="children"
                                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    value={data1.vor_id || undefined}
                                                                                    style={{ width: "100%" }}
                                                                                >
                                                                                    {unitlist.restricted && unitlist.restricted.length && unitlist.restricted.map((item, i) => {
                                                                                        if (item.emp_code !== null) {
                                                                                            return (


                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_code}-{item.emp_name}
                                                                                                </Option>
                                                                                                // :""
                                                                                            );
                                                                                        }
                                                                                        else {
                                                                                            return (


                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_name}
                                                                                                </Option>
                                                                                                // :""
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                                </Select>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <Button type="primary" shape="round" className='addbutton'
                                                                                    style={{ marginTop: '6%', marginLeft: '31%' }}
                                                                                    icon={<PlayCircleOutlined />} size='default' onClick={Submit}>
                                                                                    Submit
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </div> :
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <label><b>Assignee </b><span style={{ color: "red" }}>*</span></label>
                                                                                <Select
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Select Assignee"
                                                                                    onChange={(value, data) => {

                                                                                        if (value !== undefined) {

                                                                                            setClickassginee({
                                                                                                ...clickassignee,
                                                                                                assignee_id: value,
                                                                                                assignee: data.children[0] - data.children[1] - data.children[2]
                                                                                            })
                                                                                            setAssigneeOnclick(true)
                                                                                        }
                                                                                        else {
                                                                                            setClickassginee({
                                                                                                ...clickassignee,
                                                                                                assignee_id: '',
                                                                                                assignee: ''
                                                                                            })
                                                                                            setAssigneeOnclick(false)
                                                                                        }

                                                                                    }}
                                                                                    // onSearch={onSearch}
                                                                                    showSearch
                                                                                    optionFilterProp="children"
                                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    value={clickassignee.assignee || undefined}
                                                                                    style={{ width: "100%" }}
                                                                                >
                                                                                    {/* <Option key="All">All</Option> */}

                                                                                    {assigneedrop && assigneedrop.map((item, i) => {
                                                                                        if (item.emp_code !== null) {
                                                                                            return (


                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_code}-{item.emp_name}
                                                                                                </Option>
                                                                                                // :""
                                                                                            );
                                                                                        }
                                                                                        else {
                                                                                            return (


                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_name}
                                                                                                </Option>
                                                                                                // :""
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                                </Select>
                                                                                {validator1.current.message(
                                                                                    'Assignee',
                                                                                    clickassignee.assignee,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Assignee Required',
                                                                                        }
                                                                                    })}

                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label><b>Approval </b><span style={{ color: "red" }}>*</span></label>
                                                                                <Select
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Select Approver"
                                                                                    onChange={(value, data) => {
                                                                                        console.log(value, "valueeee11")
                                                                                        console.log(data, "dataaaaa11")
                                                                                        if (data !== undefined) {
                                                                                            setClickassginee({
                                                                                                ...clickassignee,
                                                                                                approval_id: value,
                                                                                                approval: data.children[0] - data.children[1] - data.children[2]
                                                                                            })
                                                                                            setApprovalOnclick(true)
                                                                                        }
                                                                                        else {
                                                                                            setClickassginee({
                                                                                                ...clickassignee,
                                                                                                approval_id: '',
                                                                                                approval: ''
                                                                                            })
                                                                                            setAssigneeOnclick(false)

                                                                                        }
                                                                                    }}
                                                                                    // onSearch={onSearch}
                                                                                    showSearch
                                                                                    optionFilterProp="children"
                                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    value={clickassignee.approval || undefined}
                                                                                    style={{ width: "100%" }}
                                                                                >
                                                                                    {/* <Option key="All">All</Option> */}
                                                                                    {approvaldrop && approvaldrop.map((item, i) => {


                                                                                        // {/* {unitlist && unitlist.assign_user && unitlist.assign_user.map((item, i) => { */}
                                                                                        if (item.emp_code !== null) {
                                                                                            return (


                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_code}-{item.emp_name}
                                                                                                </Option>
                                                                                                // :""
                                                                                            );
                                                                                        }
                                                                                        else {
                                                                                            return (


                                                                                                <Option key={item.usr_id}>
                                                                                                    {item.emp_name}
                                                                                                </Option>
                                                                                                // :""
                                                                                            );
                                                                                        }



                                                                                    })}
                                                                                </Select>
                                                                                {validator1.current.message(
                                                                                    'Approval',
                                                                                    clickassignee.approval,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Approval Required',
                                                                                        }
                                                                                    })}
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <Button type="primary" shape="round" className='addbutton'
                                                                                    style={{ background: "#198754", borderColor: "#198754", marginTop: '6%', marginLeft: '50%' }}
                                                                                    icon={<PlayCircleOutlined />} size='default' onClick={Submit}>
                                                                                    Submit
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </div>}


                                                            </Panel>
                                                        </Collapse>
                                                    </div>
                                                </div> : false}

                                            {/* <div className='col-md-12' >
                                            <div className='row'>

                                                <div className='col-md-4'>
                                                    <button className="btn btn-secondary" style={{ marginLeft: "25px", width: "100px" }} onClick={() => { setKey("1") }}>Previous</button>
                                                </div>
                                                <div className='col-md-4'>
                                                </div>
                                                <div className='col-md-4'>
                                                    <button className="btn btn-info" style={{ float: "right", marginRight: "25px", width: "100px" }} onClick={Next2}>Next</button>
                                                </div>
                                            </div>
                                        </div> */}
                                            {/* <Card > */}
                                            <div className="col-md-12" style={{ padding: "0px 20px", marginTop: '1%' }}>
                                                {/* <p><b>Compliance Tasks</b></p> */}
                                                <div className="row assign">
                                                    {temp.tempData.length ? temp.tempData.length > 0 &&
                                                        temp.tempData.map((item, i) => {
                                                            return (
                                                                <Collapse key={i}
                                                                    // onChange={(e) => collapseindexvalue(e)}
                                                                    onChange={(e) => {
                                                                        console.log('eeeeee', e)
                                                                        let index = []
                                                                        index.push(...e)
                                                                        setCollapseIndex([...collapseindex, ...index])

                                                                    }}
                                                                    defaultActiveKey={'0'}
                                                                >
                                                                    <Panel header={item.parentData} key={i} extra={<div style={{ textAlign: 'left', width: '110px' }}>Total Tasks : {item.child.length}</div>}>
                                                                        <div className="container-fluid" style={{ padding: 0 }}>
                                                                            <div className="row">
                                                                                <div className="col-12" >
                                                                                    {/* <DataTable
                                                                                columns={columns}       
                                                                                data={item[1]}
                                                                                selectableRows={false}
                                                                                pagination
                                                                                onSelectedRowsChange={({
                                                                                    allSelected,
                                                                                    selectedCount,
                                                                                    selectedRows,

                                                                                }) => {
                                                                                    // if (selectedCount > 0) {
                                                                                    let array = []
                                                                                    array.push(item[0])


                                                                                    let checkarray = []
                                                                                    let tempArray = selectedRows && selectedRows.length && selectedRows.map((item) => {
                                                                                        return item
                                                                                    })

                                                                                    if (tempArray.length > 0) {
                                                                                        SetSet([...set, ...tempArray])
                                                                                        setTotalRow([...totalrow, ...tempArray])


                                                                                    }
                                                                                    if (tempArray.length == 0) {
                                                                                        for (const k in set) {
                                                                                            selectedRows.filter((item) => item.comp_id == set[k].comp_id)
                                                                                            SetSet([...set, ...selectedRows])

                                                                                        }
                                                                                        //   SetSet(checkarray)
                                                                                    }

                                                                                }}
                                                                            /> */}
                                                                                    {/* <Table columns={columns} pagination={false} dataSource={item[1]} scroll={{
                                                                                x: 1500,
                                                                            }}
                                                                                size="small"
                                                                                bordered


                                                                            // pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
                                                                            /> */}

                                                                                    <Table
                                                                                        size={'small'}
                                                                                        // className='userprivclass'
                                                                                        className={localStorage.getItem("currentTheme") + ' assign_table'}
                                                                                        id='review-setting-table'
                                                                                        columns={columns}
                                                                                        dataSource={item.child}
                                                                                        bordered
                                                                                        scroll={{ x: 900 }}
                                                                                        pagination={false}
                                                                                        rowSelection={{
                                                                                            columnWidth: '30px',
                                                                                            columnTitle: <input type="checkbox" name="allchecked" id={item.index} className={'tempclass checkclass' + item.index}
                                                                                                onClick={(e) => {
                                                                                                    if (e.target.checked == true) {
                                                                                                        let temp2 = []
                                                                                                        for (let i in item.child) {
                                                                                                            temp2.push({
                                                                                                                id: item.child[i].childId,
                                                                                                                name: item.child[i].comp_name,
                                                                                                                frequencyName: item.child[i].freq
                                                                                                            })
                                                                                                        }

                                                                                                        setChildId([...childId, ...temp2])
                                                                                                    }
                                                                                                    else {

                                                                                                        setChildId([])
                                                                                                        // for (let j in triggerValidation) {
                                                                                                        //     if (triggerValidation[j].comp_id == record.comp_id) {
                                                                                                        //         triggerValidation.splice(j, 1)
                                                                                                        //     }
                                                                                                        // }
                                                                                                        // setTriggerValidation([...triggerValidation])
                                                                                                    }
                                                                                                    let temp = []
                                                                                                    for (let j in item.child) {
                                                                                                        temp.push(headerlist && headerlist[item.child[j].parentId] && headerlist[item.child[j].parentId])

                                                                                                    }
                                                                                                    setHeader([...header, ...temp])
                                                                                                    let checked = e.target.checked;
                                                                                                    addAllSelectedDataValues(
                                                                                                        checked, item.index, item.parentData, item.child
                                                                                                    );
                                                                                                }
                                                                                                }
                                                                                            />,
                                                                                            ...rowSelection
                                                                                        }}
                                                                                    // onHeaderRow={(columns, index) => {

                                                                                    //     return {
                                                                                    //         onClick: (e) => {
                                                                                    //             let temp = []
                                                                                    //             for (let j in item.child) {
                                                                                    //                 temp.push(headerlist && headerlist[item.child[j].parentId] && headerlist[item.child[j].parentId])

                                                                                    //             }
                                                                                    //             setHeader([...header, ...temp])
                                                                                    //             let checked = e.target.checked;
                                                                                    //             addAllSelectedDataValues(
                                                                                    //                 checked, item.index, item.parentData, item.child
                                                                                    //             );
                                                                                    //         }

                                                                                    //     };
                                                                                    // }}


                                                                                    />
                                                                                </div>
                                                                                <br />
                                                                            </div>
                                                                        </div>

                                                                    </Panel>
                                                                </Collapse>
                                                            )

                                                        })
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                            {/* </Card> */}
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>

                                                    <div className='col-lg-6' >
                                                        <label style={{ marginLeft: "5px", float: 'left' }}>Showing 1 to <span>{entires}</span> of {unitlist.heading.r_count} entries</label>
                                                    </div>
                                                    <div className='col-lg-6' style={{ float: 'right', textAlign: 'right', marginLeft: '-6px' }}>
                                                        <span style={{ marginLeft: '27%' }}>Selected Compliance :<span style={{ marginLeft: "5px" }}>{temparr.length}</span></span>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='col-md-12' style={{ marginTop: '10px' }}>
                                                <div className='row'>

                                                    <div className='col-md-4'>
                                                        {/* <button className="pre-button" style={{ verticalAlign: 'middle', marginLeft: "5px", float: 'left' }}
                                                        onClick={() => {
                                                            var getchildcheck = document.getElementsByClassName('tempclass')
                                                            console.log(getchildcheck, 'getchildcheck');
                                                            for (var jk = 0; jk < getchildcheck.length; jk++) {
                                                                if (getchildcheck[jk].type === 'checkbox')
                                                                    getchildcheck[jk].checked = false;
                                                            }
                                                            setKey("1")
                                                            setTemparr([])
                                                            setEditClick({
                                                                boolean: false,
                                                                comp_id: []

                                                            })
                                                            setEditClickDate({
                                                                comp_id: [],
                                                                boolean: false

                                                            })
                                                            let checked = false
                                                            let comp_id
                                                            for (let i in temparr) {
                                                                comp_id = temparr[i].comp_id
                                                            }
                                                            addSelectedDataValues(
                                                                checked,
                                                                comp_id
                                                            )
                                                            // const [temp, setTemp] = useState({
                                                            //     tempData: [],
                                                            //     parentIndexId: []
                                                            // });
                                                            setTemp({})
                                                            // setTemp({
                                                            //     tempData: [],
                                                            //     parentIndexId: []
                                                            // })
                                                            // const [editclickdate, setEditClickDate] = useState({
                                                            //     comp_id: [],
                                                            //     boolean: false
                                                            // })

                                                        }}><span>Previous</span>
                                                    </button> */}
                                                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "5px", float: 'left' }}
                                                            icon={<ArrowLeftOutlined />} size='default'
                                                            onClick={() => {
                                                                var getchildcheck = document.getElementsByClassName('tempclass')
                                                                console.log(getchildcheck, 'getchildcheck');
                                                                for (var jk = 0; jk < getchildcheck.length; jk++) {
                                                                    if (getchildcheck[jk].type === 'checkbox')
                                                                        getchildcheck[jk].checked = false;
                                                                }
                                                                setKey("1")
                                                                setCollapseAssignee('0')
                                                                setCollapseAssignee1(false)
                                                                setTemparr([])
                                                                setEditClick({
                                                                    boolean: false,
                                                                    comp_id: []

                                                                })
                                                                setEditClickDate({
                                                                    comp_id: [],
                                                                    boolean: false

                                                                })
                                                                let checked = false
                                                                let comp_id
                                                                for (let i in temparr) {
                                                                    comp_id = temparr[i].comp_id
                                                                }
                                                                addSelectedDataValues(
                                                                    checked,
                                                                    comp_id
                                                                )
                                                                setTemp({})

                                                                setCollapseAssignee1(false)

                                                            }}>
                                                            Previous
                                                        </Button>
                                                    </div>
                                                    <div className='col-md-4' style={{ textAlign: 'center' }}>
                                                        {/* {(unitlist && unitlist.heading && unitlist.heading.r_count != entires && entires != 200) || (unitlist.tablelist.length > 0) ?
                                                        <button className="btn btn-success" style={{ float: "center", marginLeft: "90px" }} onClick={showmore} >Show More</button>
                                                        : ""} */}
                                                        {/* {(unitlist && unitlist.heading && unitlist.heading.r_count != entires && entires != 200) || (unitlist.tablelist.length > 0) ? */}
                                                        {(unitlist && unitlist.heading && unitlist.heading.r_count == entires) ?
                                                            ''
                                                            : <Button type="primary" shape="round" className='showbutton' style={{ background: "#0096FF", borderColor: "#0096FF", textAlign: 'center' }}
                                                                size='default' onClick={showmore}>
                                                                Show More <i> <DownOutlined /></i>
                                                            </Button>}
                                                    </div>
                                                    {/* <div className='col-md-4'>
                                                        {triggerstate == false ? <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right' }}
                                                            size='default' onClick={Next2}>
                                                            Select Assignee <i> </i>
                                                        </Button> : <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'right' }}

                                                            size='default' onClick={() => { toast.error("Trigger before days should not exceed 100") }}>
                                                            Select Assignee <i></i>
                                                        </Button>}
                                                     
                                                    </div> */}
                                                </div>
                                            </div>
                                            {/* {unitlist && unitlist.heading && unitlist.heading.r_count > 200 ? entires != 200 : unitlist && unitlist.heading && unitlist.heading.r_count != entires ?
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4'>
                                                        <button className="btn btn-success" style={{ float: "center", marginLeft: "50px" }} onClick={showmore} >Show More</button>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                            : ""} */}
                                            <Stickicon />

                                        </TabPane>

                                        {/* <TabPane tab="Select Assignee" disabled key="3">
                                        <Card>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    {unitlist.restricted && unitlist.restricted.length > 0 ? <>

                                                        <div className='col-md-4' style={{ textAlign: "center" }}>
                                                            <label><b>Assignee </b><span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-4' style={{ textAlign: "center" }}>
                                                            <label><b>Approval </b><span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                        <div className='col-md-4' style={{ textAlign: "center" }}>
                                                            <label><b>View Only Restricted User </b></label>
                                                        </div> </>
                                                        : <> <div className='col-md-6' style={{ textAlign: "center" }}>
                                                            <label><b>Assignee </b><span style={{ color: "red" }}>*</span></label>
                                                        </div>
                                                            <div className='col-md-6' style={{ textAlign: "center" }}>
                                                                <label><b>Approval </b><span style={{ color: "red" }}>*</span></label>
                                                            </div></>}
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    {unitlist.restricted && unitlist.restricted.length > 0 ? <>
                                                        <div className='col-md-4'>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Assignee"
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {

                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            assignee_id: value,
                                                                            assignee: data.children
                                                                        })
                                                                        setAssigneeOnclick(true)
                                                                    }
                                                                    else {
                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            assignee_id: '',
                                                                            assignee: ''
                                                                        })
                                                                        setAssigneeOnclick(false)
                                                                    }

                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={clickassignee.assignee || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                <Option key="All">All</Option>

                                                                {assigneedrop && assigneedrop.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <Option key={item.usr_id}>
                                                                                {item.emp_name}
                                                                            </Option>
                                                                        </>

                                                                    );
                                                                })}
                                                            </Select>

                                                        </div>
                                                        <div className='col-md-4'>

                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Approver"
                                                                onChange={(value, data) => {
                                                                    if (data !== undefined) {
                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            approval_id: value,
                                                                            approval: data.children
                                                                        })
                                                                        setApprovalOnclick(true)
                                                                    }
                                                                    else {
                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            approval_id: '',
                                                                            approval: ''
                                                                        })
                                                                        setAssigneeOnclick(false)

                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={clickassignee.approval || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                <Option key="All">All</Option>
                                                                {approvaldrop && approvaldrop.map((item, i) => {

                                                                    return (


                                                                        <Option key={item.usr_id}>
                                                                            {item.emp_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </> :
                                                        <><div className='col-md-6'>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Assignee"
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {
                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            assignee_id: value,
                                                                            assignee: data.children
                                                                        })
                                                                        setAssigneeOnclick(true)
                                                                    }
                                                                    else {
                                                                        setClickassginee({
                                                                            ...clickassignee,
                                                                            assignee_id: '',
                                                                            assignee: ''
                                                                        })
                                                                        setAssigneeOnclick(false)
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={clickassignee.assignee || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                <Option key="All">All</Option>

                                                                {assigneedrop && assigneedrop.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <Option key={item.usr_id}>
                                                                                {item.emp_name}
                                                                            </Option>
                                                                        </>

                                                                    );
                                                                })}
                                                            </Select>

                                                        </div>
                                                            <div className='col-md-6'>

                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Select Approver"
                                                                    onChange={(value, data) => {

                                                                        if (value !== undefined) {
                                                                            setClickassginee({
                                                                                ...clickassignee,
                                                                                approval_id: value,
                                                                                approval: data.children
                                                                            })
                                                                            setApprovalOnclick(true)
                                                                        }
                                                                        else {
                                                                            setClickassginee({
                                                                                ...clickassignee,
                                                                                approval_id: '',
                                                                                approval: ''
                                                                            })
                                                                            setApprovalOnclick(false)
                                                                        }
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={clickassignee.approval || undefined}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <Option key="All">All</Option>
                                                                    {approvaldrop && approvaldrop.map((item, i) => {
                                                                        return (


                                                                            <Option key={item.usr_id}>
                                                                                {item.emp_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </div></>}
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "20px", marginBottom: "20px" }}>
                                                <div className='row'>
                                                    {unitlist.restricted && unitlist.restricted.length > 0 ? <>

                                                        <div className='col-md-4'>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Assignee"
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {

                                                                        var merged = [].concat.apply([], data.children);
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            assign_id: value,
                                                                            assignee: data.children[2]
                                                                        })
                                                                    }
                                                                    else {
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            assign_id: '',
                                                                            assignee: ''
                                                                        })
                                                                    }

                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneelist.assignee || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                {assigneesearch3 && assigneesearch3.map((item, i) => {


                                                                    return (
                                                                        clickassignee.assignee ?
                                                                            <Option key={item.emp_code}>
                                                                                {item.emp_code} - {item.emp_name}
                                                                            </Option> : ""
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator1.current.message(
                                                                'Assignee',
                                                                assigneelist.assign_id,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Assignee Required',
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Approver"
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            approval_id: value,
                                                                            approval: data.children
                                                                        })
                                                                    }
                                                                    else {
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            approval_id: '',
                                                                            approval: ''
                                                                        })
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneelist.approval || undefined}
                                                                style={{ width: "100%" }}
                                                            >

                                                                {approvalsearch3 && approvalsearch3.map((item, i) => {
                                                                    return (
                                                                        clickassignee.approval ?
                                                                            item.emp_code != null ?
                                                                                <Option key={item.emp_code}>
                                                                                    {item.emp_code} - {item.emp_name}
                                                                                </Option>
                                                                                :
                                                                                <Option key={item.emp_code}>
                                                                                    {item.emp_name}
                                                                                </Option>
                                                                            : ""
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator1.current.message(
                                                                'Approval',
                                                                assigneelist.approval,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Approval Required',
                                                                    }
                                                                })}
                                                        </div>
                                                        <div style={{ width: '33.33%', position: 'absolute', top: 1, right: 1, marginTop: '55px' }}>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter View only restricted only"
                                                                onChange={(value) => {

                                                                    setData({
                                                                        ...data1,
                                                                        vor_id: value,

                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={data1.vor_id || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                {unitlist.restricted && unitlist.restricted.length && unitlist.restricted.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.usr_id}>
                                                                            {item.emp_code} - {item.emp_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>

                                                        </div> </> :
                                                        <><div className='col-md-6'>
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Assignee"
                                                                onChange={(value, data) => {

                                                                    if (value != undefined) {
                                                                        var merged = [].concat.apply([], data.children);
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            assign_id: value,
                                                                            assignee: data.children[2]
                                                                        })
                                                                    }
                                                                    else {
                                                                        setAssigneelist({
                                                                            ...assigneelist,
                                                                            assign_id: '',
                                                                            assignee: ''
                                                                        })
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                value={assigneelist.assignee || undefined}
                                                                style={{ width: "100%" }}
                                                            >
                                                                {assigneesearch3 && assigneesearch3.map((item, i) => {


                                                                    return (
                                                                        clickassignee.assignee ?
                                                                            <Option key={item.emp_code}>
                                                                                {item.emp_code} - {item.emp_name}
                                                                            </Option> : ""
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator1.current.message(
                                                                'Assignee',
                                                                assigneelist.assign_id,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Assignee Required',
                                                                    }
                                                                })}
                                                        </div>
                                                            <div className='col-md-6'>
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Enter Approver"
                                                                    onChange={(value, data) => {
                                                                        if (value !== undefined) {
                                                                            setAssigneelist({
                                                                                ...assigneelist,
                                                                                approval_id: value,
                                                                                approval: data.children
                                                                            })
                                                                        }
                                                                        else {
                                                                            setAssigneelist({
                                                                                ...assigneelist,
                                                                                assign_id: '',
                                                                                assignee: ''
                                                                            })
                                                                        }
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    value={assigneelist.assign_id || undefined}
                                                                    style={{ width: "100%" }}
                                                                >

                                                                    {approvalsearch3 && approvalsearch3.map((item, i) => {
                                                                        return (
                                                                            clickassignee.approval ?
                                                                                item.emp_code != null ?
                                                                                    <Option key={item.emp_code}>
                                                                                        {item.emp_code} - {item.emp_name}
                                                                                    </Option>
                                                                                    :
                                                                                    <Option key={item.emp_code}>
                                                                                        {item.emp_name}
                                                                                    </Option>
                                                                                : ""
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator1.current.message(
                                                                    'Approval',
                                                                    assigneelist.approval,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Approval Required',
                                                                        }
                                                                    })}
                                                            </div></>}
                                                </div>
                                            </div>
                                        </Card>
                                        <div className='col-md-12' style={{ marginTop: '10px' }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                              

                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'left', marginRight: "10px" }}
                                                        icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                                            setKey("2")
                                                            setAddFormSubmit1(false)
                                                            setData({
                                                                ...data1,
                                                                vor_id: '',

                                                            })
                                                            setAssigneelist({
                                                                assign_id: null,
                                                                approval_id: null,
                                                                assignee: "",
                                                                approval: "",
                                                                restricted: "",
                                                                vor_id: ''
                                                            })
                                                            setClickassginee({
                                                                assignee: "",
                                                                approval: "",
                                                                approval_id: null,
                                                                assignee_id: null
                                                            })
                                                        }}>
                                                        Previous
                                                    </Button>
                                                </div>
                                                <div className='col-md-4 text-center' >
                                                    <Button type="primary" shape="round" className='addbutton'
                                                        style={{ background: "#198754", borderColor: "#198754" }}
                                                        icon={<PlayCircleOutlined />} size='default' onClick={Submit}>
                                                        Submit
                                                    </Button>


                                                </div>
                                            </div>
                                        </div>
                                    </TabPane> */}
                                    </Tabs>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title="Applicable Vendors" className={"newStyleApplicableUnits add-service-prv modelradius " + localStorage.getItem('currentTheme')} style={{ width: '63%' }} visible={isModalVisible} onCancel={handleCancel} footer={null}>
                    {uniaddress && uniaddress.map((item, i) => {
                        return (
                            <>
                                <p>{unitnamemodal[i]}-{item}</p>
                            </>
                        )
                    })}
                </Modal>
            </div >
        </>

    )
}
export default connect(mapStateToProps, {
    assign,
    selectunit,
    table,
    tabledata,
    showmoredata,
    selectassigneedata,
    saveassigncomliance,
    syncassigncomliance,

})(Compliance);
