import { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import SimpleReactValidator from 'simple-react-validator';
import { Input, Card, Modal, Select } from 'antd';
import { getDomainaDetails } from './../../Store/Action/Transcations/Completedtaskcurrentbulkupload'
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    unit: state.bulkupload.bulkuploads
});
const Addcompletedtaskcurrentyearbulkupload = ({
    getDomainaDetails,
    unit: { unitlist }
}) => {
    const [downloadFile, setDownloadFile] = useState({
        legal_entity: '',
        domain: '',
        unit: ''
    })
    const [domain, setDomian] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUnitVisible, setIsUnitVisible] = useState(false);
    const formValidator = useRef(new SimpleReactValidator());
    const { Search } = Input;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    // const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const entityid = localStorage.getItem('SelectedEntityid');

    const fileDownload = (e) => {
        setDownloadFile({
            ...downloadFile,
            [e.target.name]: e.target.value
        })
    }

    const domainaDetails = (e) => {
        setDomian(e.target.value)
        getDomainaDetails({
            payload: [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnits",
                        {
                            "legal_entity_id": entityid,
                            "domain_id": parseInt(e.target.value)
                        }
                    ]
                }
            ],
            paramid: paramid
        })
    }

    const handleOk = () => {
        setIsUnitVisible(false);
        setIsModalVisible(false);
    };

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])

    const homescreen = ()=>{
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="page-titles pb-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Completed Task - Current Year - Bulk Upload
                                    </li>
                                </ol>
                            </nav>
                            <h1 className="mb-0 fw-bold">Completed Task - Current Year - Bulk Upload</h1>
                        </div>
                        <div className="
                col-lg-4 col-md-6
                d-none d-md-flex
                align-items-center
                justify-content-end
              "
                        >

                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <Card title="Download file" style={{ marginTop: "20px", boxShadow: "0px 0px 0px 1px grey" }}>
                        {/* <ToastContainer /> */}
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <label style={{ float: "right" }}>Legal Entity : <span style={{ color: "red" }}>*</span></label>
                                </div>
                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                    <div className='col-md-8'>
                                        <select name="option" className="form-control" onChange={fileDownload}
                                            value={domain} >
                                            <option value="">Select </option>
                                            <option value="LuluMallPrivateLimited" >Lulu Mall Private Limited</option>
                                            <option value="LuluRubberIndustry">Lulu Rubber Industry</option>
                                            <option value="LulusoftwareServicePrivateLimited">Lulu software Service Private Limited</option>
                                        </select>
                                    </div>
                                    : <div className='col-md-8'>
                                        {localStorage.getItem('SelectedEntity')}
                                    </div>}
                            </div>
                        </div><br />
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <label style={{ float: "right" }}>Domain :<span style={{ color: "red" }}>*</span></label>
                                </div>
                                <div className='col-md-8'>
                                    <select name="option" className="form-control"
                                        onChange={domainaDetails}
                                        value={domain}>
                                        <option value="">Select </option>
                                        <option value="1" >Labour-Employment-Industry</option>
                                        <option value="2">Lulu Rubber Industry</option>
                                        <option value="3">Lulu software Service Private Limited</option>
                                    </select>
                                </div>
                            </div>
                        </div><br />
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <label style={{ float: "right" }}>Unit :<span style={{ color: "red" }}>*</span></label>
                                </div>
                                <div className='col-md-8'>
                                    <Select
                                        size="default"
                                        placeholder="Please Select Units"
                                        // value={productForm.value.vendor_id}
                                        // onChange={vendorChange}
                                        style={{ width: '100%' }}

                                    >{unitlist && unitlist.length ?<>{
                                            unitlist.map((item, i) => {
                                            return (
                                                <Option key={item.unit_code}>
                                                    {item.unit_name}
                                                </Option>
                                            )
                                        })}
                                        </>:''}
                                    </Select>
                                </div>
                            </div>
                        </div><br />
                        <div className='col-md-12'>
                            <div className='row' style={{ marginTop: "20px" }}>
                                <div className='col-md-4'></div>
                                <div className='col-md-2' >
                                    <Link to="" style={{ textAlign: "center" }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Download</Link>
                                </div>
                                <div className='col-md-6'></div>
                            </div>
                        </div>
                    </Card>
                    <Card title="Upload file" style={{ marginTop: "20px", boxShadow: "0px 0px 0px 1px grey" }}>
                        {/* <ToastContainer /> */}
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <label style={{ float: "right" }}>Legal Entity : <span style={{ color: "red" }}>*</span></label>
                                </div>
                                <div className='col-md-8'>
                                    {/* <label >Lulu Mall Private Limited</label> */}
                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                        <div className='col-md-8'>
                                            <select name="option" className="form-control" >
                                                <option value="">Select </option>
                                                <option value="1" >Lulu Mall Private Limited</option>
                                                <option value="2">Lulu Rubber Industry</option>
                                                <option value="3">Lulu software Service Private Limited</option>
                                            </select>
                                        </div>
                                        : <div className='col-md-8'>
                                            {localStorage.getItem('SelectedEntity')}
                                        </div>}
                                </div>
                            </div>
                        </div><br />
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <label style={{ float: "right" }}>Select File :<span style={{ color: "red" }}>*</span></label>
                                </div>
                                <div className='col-md-8'>
                                    <input type="file" />
                                </div>
                            </div>
                        </div><br />
                        <div className='col-md-12'>
                            <div className='row' style={{ marginTop: "20px" }}>
                                <div className='col-md-4'></div>
                                <div className='col-md-2' >
                                    <Link to="" style={{ textAlign: "center" }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Upload</Link>
                                </div>
                                <div className='col-md-6'></div>
                            </div>
                        </div>
                    </Card>
                    <Modal title="Domain" visible={isModalVisible} onOk={handleOk} onCancel={handleOk}>
                        <Search
                            placeholder="Search"
                        // onSearch={onSearch}
                        // style={{
                        //     width: 200,
                        // }}
                        />
                    </Modal>

                    <Modal title="Unit" visible={isUnitVisible} onOk={handleOk} onCancel={handleOk}>
                        <Search
                            placeholder="Search"
                        // onSearch={onSearch}
                        // style={{
                        //     width: 200,
                        // }}
                        />
                    </Modal>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    getDomainaDetails
})(Addcompletedtaskcurrentyearbulkupload);
