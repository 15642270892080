import HTTP from './../../Libs//http'
import { Toaster } from './../../Libs//Toaster'
import url from './../../Libs/URL'
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { WIDGET_DATA, RISK_WIDGET, COMMON_DATA, OVERDUE_WIDGET, COMPLIANCE_STATUS_WIDGET, TASKDISTRIBUTION_WIDGET, CRITICALITY_WIDGET,COMPLIANCE_TREND_WIDGET,COUNTRY_DATA } from './../types/index';
import { getUrl } from './../../Libs/URL';
import alertmessage from '../../Libs/Altermessage';
const urls = getUrl()


export const getWidgetData = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, 'sneha');
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            // Toaster.error("Service Unavailable")
            // alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: WIDGET_DATA,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        Toaster.error(err);
    }
};

export const getRiskChart = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
        
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            console.log(decryptData, "decryptData104");

            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: RISK_WIDGET,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                alertmessage.sweetalert();

            } else {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
    }
};

export const getOverDueChart = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
  
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            console.log(decryptData, "decryptData104");

            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: OVERDUE_WIDGET,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                alertmessage.sweetalert();

            } else {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
    }
};

export const getComplianceStatusChart = ({ payload, caller, executeCancel }) => async dispatch => {
    try {
      
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            console.log(decryptData, "decryptData100");
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: COMPLIANCE_STATUS_WIDGET,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                alertmessage.sweetalert();

            } else {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
    }
};

export const getTaskDistributionChart = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
       
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            console.log(decryptData, "decryptData105");

            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: TASKDISTRIBUTION_WIDGET,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                alertmessage.sweetalert();

            } else {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
    }
};

export const getCriticalityChart = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
      
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            console.log(decryptData, "decryptData10777");

            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: CRITICALITY_WIDGET,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                alertmessage.sweetalert();

            } else {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
    }
};

export const getComplianceTrend = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
      
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, url.encryptionKey))
            console.log(decryptData, "decryptData101");
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: COMPLIANCE_TREND_WIDGET,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                alertmessage.sweetalert();

            } else {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
    }
};

export const getCountryData = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, 'sneha');
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            // Toaster.error("Service Unavailable")
            // alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: COUNTRY_DATA,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        Toaster.error(err);
    }
};

