import HTTP from '../../../src/Libs/http'
import { Toaster } from '../../../src/Libs/Toaster'
import url, { getUrl } from './../../../src/Libs/URL'
import EncryptDecrypt from './../../../src/Libs/Encrypt&Decrypt'
import { encode as base64_encode, decode as base64_decode } from 'base-64';
import alertmessage from '../../../src/Libs/Altermessage';
import { TOGGLE_COMMON_LOADER, COMMOM_WIDGET_DATA, COMMOM_WIDGET_DATA1, WIDGETS1, WIDGETS2, WIDGETS3, WIDGETS4, WIDGETS5, WIDGETS6, WIDGETS7, DOMAIN_SCORE_DATA, USER_SCORE_CARD, CALENDER_DATA, COMPFIELAW_DATA,SAVE_WIDGET_DATA } from './../../Store/types/index'

const urls = getUrl()

export const getCommonwidgetData = ({ payload, paramid, executeCancel }) => async dispatch => {

    try {
       
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': paramid
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "decryptData1111");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'GetChartFiltersSuccess') {
                dispatch({
                    type: COMMOM_WIDGET_DATA,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                // Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getCommonwidgetData1 = ({ payload, paramid, executeCancel }) => async dispatch => {

    try {
      
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': paramid
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "decryptData2222");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'GetUserWidgetDataSuccess') {
                dispatch({
                    type: COMMOM_WIDGET_DATA1,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                // Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getwidget1Data = ({ payload, caller, executeCancel }) => async dispatch => {
    try {
       
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "decryptData100");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: WIDGETS1,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getwidget2Data = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
      
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "decryptData101");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: WIDGETS2,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getwidget3Data = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
       
     
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "decryptData103");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: WIDGETS3,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getwidget4Data = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
     
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "decryptData104");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: WIDGETS4,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getwidget5Data = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
      
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "decryptData105");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: WIDGETS5,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getwidget6Data = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
      
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "decryyyyyyyyyyy");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: WIDGETS6,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getwidget7Data = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
        
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "decryptData10777");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: WIDGETS7,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getDomainScoreCard = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
       
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "65555555555575754");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: DOMAIN_SCORE_DATA,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getUserScoreCard = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
       
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "675767575754");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: USER_SCORE_CARD,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getCalenderdata = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
      
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "88888777777776666");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: CALENDER_DATA,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getCompfielaw = ({ payload, caller, executeCancel }) => async dispatch => {

    try {
      
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': caller
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/widgets`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "88888777777776666");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'ChartSuccess') {
                dispatch({
                    type: COMPFIELAW_DATA,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const savewidgetdata = ({ payload, paramid, executeCancel }) => async dispatch => {
    console.log(payload,"payloaddddddd");
    console.log(paramid,"paramiddddddd");
    try {
      
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_encode(url.encryptionKey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'caller-name': paramid
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload,url.encryptionKey)
        const createRequest = HTTP.post({
            url: urls + `/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,url.encryptionKey))
            console.log(decryptData, "decryptData44444444422");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'SaveWidgetDataSuccess') {
                dispatch({
                    type: SAVE_WIDGET_DATA,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                // Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};