import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Modal, Input, Table, Tooltip } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import DataTable from "react-data-table-component";
import { notificationshowall, updatenotification } from '../../Store/Action/Login';
import { Toaster } from '../../Libs/Toaster';
import { PlusSquareTwoTone, LikeOutlined, DislikeOutlined, PoweroffOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import url, { getUrl } from './../../Libs/URL'
import { CountrySplit, HomeRedirect } from './../../Libs/country'
const urls = getUrl()
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const finalURL = urls && urls != null ? urls.replace('/cv', '') : false;

const mapStateToProps = (state) => ({
    lists: state.Login.notificationshowall.messages,

})
const Messages = ({
    notificationshowall,
    lists,
    updatenotification
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [modalVisible, setModaVisible] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const header = paramid.split('/');
    var breadcrumb = '';
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    var type = '';
    if (header[1] == 'reminders') {
        breadcrumb = 'Remainders';
        type = 2;
    } else if (header[1] == 'notifications') {
        breadcrumb = 'Notifications';
    } else if (header[1] == 'messages') {
        breadcrumb = 'Messages';
        type = 4;
    } else if (header[1] == 'escalations') {
        breadcrumb = 'Escalations';
        type = 3;
    }

    const downloadreport = (e, record) => {
        window.open(finalURL + '/' + e);
        markread(record)

    }

    const markread = (record) => {
        let notifypayload;
        if (authtoken && legalid && legalid != "null") {
            notifypayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UpdateNotificationStatus",
                        {
                            "le_ids": [
                                legalid
                            ],
                            "notification_id": record.notification_id,
                            "has_read": true,
                            "extra_details": record.extra_details
                        }
                    ]
                }
            ]

        } else {
            let temp = [];
            for (let i = 0; i < callername.entity_info.length; i++) {
                temp.push(callername.entity_info[i].le_id);
            }
            notifypayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UpdateNotificationStatus",
                        {
                            "le_ids": temp,
                            "notification_id": record.notification_id,
                            "has_read": true,
                            "extra_details": record.extra_details
                        }
                    ]
                }
            ]
        }
        updatenotification({
            payload: notifypayload,
            paramid: paramid
        })

    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_on',
            key: 'created_on',
            width: '10px'

        },
        {
            title: 'Title',
            dataIndex: 'notification_text',
            key: 'notification_text',
            width: '300px',
            render: (text, record) => {
                console.log(record,"recordrecordrecord");
                if (record.extra_details == null) {
                
                    return <span className='pointer' onClick={(e) => { markread(record) }}>{record.notification_text}</span>
                } else {
                 
                    //return <span className='pointer' onClick={(e) => { downloadreport(record) }}>{record.notification_text}</span>
                     return <p className='pointer'><span>{record.notification_text}</span><Link onClick={(e) => { downloadreport(record.extra_details, record) }}>Your can download document here.</Link></p>
                }
            }

        },
    ];

    useEffect(() => {
            let payload;
            console.log('GetMessagesSuccess')
            if (legalid != 'null') {
                payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetNotifications",
                            {
                                "le_ids": [
                                    legalid
                                ],
                                "notification_type": 4,
                                "start_count": 0,
                                "end_count": 50
                            }
                        ]
                    }
                ]
            } else {
                let temp = [];
                for (let i = 0; i < callername.entity_info.length; i++) {
                    temp.push(callername.entity_info[i].le_id);
                }
                payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetNotifications",
                            {
                                "le_ids": temp,
                                "notification_type": 4,
                                "start_count": 0,
                                "end_count": 50
                            }
                        ]
                    }
                ]
            }



            notificationshowall({
                payload: payload,
                caller: paramid
            })
    }, [])
    
    const datalist = ""

    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    return (
        <Fragment>
            <div>

                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Notification</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span>Messages</span>
                                        </li>
                                    </ol>
                                </nav> 
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card servcardround">

                                    <div className="card-body" style={{ padding: '0px' }}>
                                        <div className={localStorage.getItem("currentTheme")}>
                                        <Table
                                           
                                           id='service-user-priv'
                                            columns={columns}
                                            dataSource={lists}
                                            bordered
                                            pagination={{
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            }}
                                        />
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>

        </Fragment>
    )
}
export default connect(mapStateToProps, {
    notificationshowall,
    updatenotification
})(Messages);