export const fullscreenmode = () => {
    sessionStorage.setItem('isFullScreen','true')
    let topBar = document.getElementById('top-nav')
    let pageWrapper = document.getElementById('page-wrapper')
    let leftbar = document.getElementsByClassName('left-sidebar');
    let pageTitles = document.getElementsByClassName('page-title-sticky');
    let filterDetails = document.getElementsByClassName('filter-detial-sticky');
    let theadSticks = document.getElementsByClassName('table-head-stick');
    let conditionClass = document.getElementsByClassName('rounded-3')
    let leftnone = leftbar[0];
    let pageTitle = pageTitles[0];
    let filterDetail = filterDetails[0];
    let theadStick = theadSticks[0];
    let Condition = conditionClass[0];
    document.documentElement.requestFullscreen();
    topBar.style.display = 'none'
    pageWrapper.style.paddingTop = '0'
    pageWrapper.style.marginLeft = '0'
    leftnone.style.display = 'none'
    let temp = filterDetail && filterDetail.getAttribute('class')
    let Conditiontemp = Condition && Condition.getAttribute('class')
    const myNewArr = Conditiontemp && Conditiontemp.match('conditionClass')
    const myArr = temp && temp.match("extraFilter");
    if (pageTitle != undefined) {
        pageTitle.style.top = '0'
    }
    if (filterDetail != undefined && theadStick != undefined) {
        filterDetail.style.top = '44px'
        theadStick.style.top = '82px'
    }
    if (filterDetail == undefined && myNewArr && myNewArr[0] == 'conditionClass') {
        console.log(filterDetail, 'filterDetail')
        theadStick.style.top = '44px'
    }
    if (myArr && myArr[0] === 'extraFilter') {
        theadStick.style.top = '104px'
    }

}

export const fullscreenexit = () => {
    sessionStorage.setItem('isFullScreen','false')
    // if (!document.webkitIsFullScreen && !document.fullscreenElement) {
    let topBar = document.getElementById('top-nav')
    let pageWrapper = document.getElementById('page-wrapper')
    let leftbar = document.getElementsByClassName('left-sidebar');
    let pageTitles = document.getElementsByClassName('page-title-sticky');
    let filterDetails = document.getElementsByClassName('filter-detial-sticky');
    let theadSticks = document.getElementsByClassName('table-head-stick');
    let conditionClass = document.getElementsByClassName('rounded-3')
    let leftnone = leftbar[0];
    let pageTitle = pageTitles[0];
    let filterDetail = filterDetails[0];
    let theadStick = theadSticks[0];
    let Condition = conditionClass[0];
    document.exitFullscreen();
    topBar.style.display = 'block'
    pageWrapper.style.paddingTop = '70px'
    pageWrapper.style.marginLeft = '65px'
    leftnone.style.display = 'block'
    let temp = filterDetail && filterDetail.getAttribute('class')
    let Conditiontemp = Condition && Condition.getAttribute('class')
    const myNewArr = Conditiontemp && Conditiontemp.match('conditionClass')
    const myArr = temp && temp.match("extraFilter");
    if (pageTitle != undefined) {
        pageTitle.style.top = '69px'
    }
    if (filterDetail != undefined && theadStick != undefined) {
        pageTitle.style.top = '69px'
        filterDetail.style.top = '113px'
        theadStick.style.top = '154px'
    }
    if (filterDetail == undefined && myNewArr && myNewArr[0] == 'conditionClass') {
        theadStick.style.top = '113px'
    }
    if (myArr && myArr[0] === 'extraFilter') {
        theadStick.style.top = '173px'
    }


    // }

}

export const handleKeyDown = (event) => {
    if (!document.webkitIsFullScreen && !document.fullscreenElement) {
        sessionStorage.setItem('isFullScreen','false')
        let topBar = document.getElementById('top-nav')
        let pageWrapper = document.getElementById('page-wrapper')
        let leftbar = document.getElementsByClassName('left-sidebar');
        let pageTitles = document.getElementsByClassName('page-title-sticky');
        let filterDetails = document.getElementsByClassName('filter-detial-sticky');
        let theadSticks = document.getElementsByClassName('table-head-stick');
        let conditionClass = document.getElementsByClassName('rounded-3')
        let leftnone = leftbar[0];
        let pageTitle = pageTitles[0];
        let filterDetail = filterDetails[0];
        let theadStick = theadSticks[0];
        let Condition = conditionClass[0];
        let temp = filterDetail && filterDetail.getAttribute('class')
        let Conditiontemp = Condition && Condition.getAttribute('class')
        const myNewArr = Conditiontemp && Conditiontemp.match('conditionClass')
        const myArr = temp && temp.match("extraFilter");
        document.exitFullscreen();
        topBar.style.display = 'block'
        pageWrapper.style.paddingTop = '70px'
        pageWrapper.style.marginLeft = '65px'
        leftnone.style.display = 'block'
        if (pageTitle != undefined) {
            pageTitle.style.top = '69px'
        }
        if (filterDetail != undefined && theadStick != undefined) {
            filterDetail.style.top = '113px'
            theadStick.style.top = '154px'
        }
        if (filterDetail == undefined && myNewArr && myNewArr[0] == 'conditionClass') {
            theadStick.style.top = '113px'
        }
        if (myArr && myArr[0] === 'extraFilter') {
            theadStick.style.top = '173px'
        }
    }

};