import { useState, useRef, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Modal, Card, Collapse, Tooltip, Input, Table, Button, Select, Space, Row, Col } from 'antd';
import DataTable from "react-data-table-component";
import { useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall';
import { statutorylist, editstatutory, filterdata, locksettings, SaveStatutorySettings, UpdateStatutorySettings } from './../../Store/Action/Transcations/Statutorysettings'
import FeatherIcon from 'feather-icons-react';
import { EditOutlined, CheckOutlined, CloseOutlined, FilterFilled, UserAddOutlined, PlayCircleOutlined, InfoCircleOutlined, PlusSquareTwoTone, ArrowLeftOutlined, MinusCircleOutlined, ExclamationCircleTwoTone, SearchOutlined, UpCircleOutlined, PlusCircleOutlined, EyeOutlined, DownOutlined, SaveOutlined, FullscreenOutlined, UpOutlined, FilterOutlined, BookTwoTone } from '@ant-design/icons';
import { indexOf, unset, update } from 'lodash';
import MultiUnitStatutorySettings from './MultiUnitStatutorySettings';
import { STATOTORYLIST, STATUTORYSEDIT_RESET, STATUTORY_SAVED_SUCCESS_RESET, STATUTORY_INVALID_PASSWORD_RESET } from '../../Store/types';
import TextArea from 'antd/lib/input/TextArea';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';
import { Toaster } from '../../Libs/Toaster';
// import { GiCheckMark } from "react-icons/gi";
import Stickicon from "./Stickyicon";
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
  list: state.Statutorysetting.statutorysettings.statutorylist,
  editdata: state.Statutorysetting.statutorysettings.editdatas,
  filterdatas: state.Statutorysetting.statutorysettings.filterdata,
  filterrecall: state.recallfilters.recallfilters,
  totaldatas: state.Statutorysetting.statutorysettings.totaldata,
  invalidpas: state.Statutorysetting.statutorysettings.invalidpass,
  statussaved: state.Statutorysetting.statutorysettings.savedstatutory,

})
const Statutorysetting = ({
  statutorylist,
  editstatutory,
  list,
  editdata,
  filterdata,
  locksettings,
  totaldatas,
  filterdatas,
  GetRemarksData,
  invalidpas,
  statussaved,
  filterrecall: { recallremarks },
  SaveStatutorySettings,
  UpdateStatutorySettings

}) => {
  console.log(list, 'list');
  console.log(filterdatas, 'filterdatas');
  console.log(statutorylist, 'statutorylist');
  console.log(editdata, 'editdata');
  console.log(totaldatas, 'totaldatas');
  console.log(statussaved, 'statussaved');
  const location = useLocation();
  const [page, setPage] = useState(1);
  const { Option } = Select;
  const formValidator = useRef(new SimpleReactValidator());
  const saveformValidator = useRef(new SimpleReactValidator());
  const overallsaveformValidator = useRef(new SimpleReactValidator());
  const paramid = location.pathname;
  const _ = require("lodash");
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false)
  const [overallcheck, setOverallcheck] = useState(false)
  const { Panel } = Collapse;
  const history = useHistory();
  const [data, setData] = useState();
  const [checkedRow, setCheckedRow] = useState(false);
  const isAuth = localStorage.getItem('isAuthenticated');
  const authtoken = localStorage.getItem('authToken');
  const validator = useRef(new SimpleReactValidator());
  const [AddFormSubmit, setAddFormSubmit] = useState(false);
  const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
  const [showbutton, setshowbutton] = useState(false)
  const [listData, setListData] = useState([])
  const sessionValue = localStorage.getItem('sessionValue')
  const sessionParsedValue = JSON.parse(sessionValue)
  const sessionArr = sessionParsedValue.entity_info
  const [EntityData, setEntityData] = useState([])
  const [bggroup, setBggroup] = useState([])
  const [TotalCompliance, setTotalCompliance] = useState(0)
  const [lockCount, setLockCount] = useState(0)
  const [unLockCount, setUnLockCount] = useState(0)
  // const entityid = localStorage.getItem('SelectedEntityid');
  const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
  const [childValue, setChildValue] = useState()
  const [isModalVisible, SetisModalVisible] = useState(false);
  const [passwordmodal, Setpasswordmodal] = useState(false);
  const [passworddisable, SetPassworddisable] = useState(false);
  const [submitpasswordmodal, Setsubmitpasswordmodal] = useState(false);
  const [editshow, seteditshow] = useState(false);
  const [listshow, setlistshow] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const [refcompid, setRefcompid] = useState([]);
  const [updateopt, setUpdateopt] = useState([]);
  // const [childremarks, setChildremarks] = useState([])
  const [CollapseStatus, setCollapseStatus] = useState([]);
  const [refCollapseStatus, setRefCollapseStatus] = useState([]);
  const [PayloadCollapseStatus, setPayloadCollapseStatus] = useState([]);
  const [viewstatus, setViewstatus] = useState([]);
  const [UpdatePannelRemarks, setUpdatePannelRemarks] = useState([]);
  const [PayloadUpdatePannelRemarks, setPayloadUpdatePannelRemarks] = useState([]);
  const [updateChildRemarks, setUpdateChildRemarks] = useState([])
  const [displayNoneChild, setDisplayNoneChild] = useState([])
  const [splitcollapsedisplay, setsplitcollapsedisplay] = useState([])
  console.log(splitcollapsedisplay, 'splitcollapsedisplay');
  const [displayNoneParent, setDisplayNoneParent] = useState([])
  console.log(displayNoneParent, 'displayNoneParent');
  console.log(updateChildRemarks, 'updateChildRemarks');
  console.log(CollapseStatus, 'CollapseStatus');
  console.log(UpdatePannelRemarks, 'UpdatePannelRemarks');
  console.log(updateopt, 'updateopt');
  console.log(refcompid, 'refcompid');
  console.log(viewstatus, 'viewstatus');
  console.log(displayNoneChild, 'displayNoneChild');
  console.log(PayloadCollapseStatus, 'PayloadCollapseStatus');
  console.log(splitcollapsedisplay, 'splitcollapsedisplay');
  console.log(refCollapseStatus, 'refCollapseStatus');

  const [uiddomian, setUiddomain] = useState([]);
  const [statutorypayload, setStatutorypayload] = useState([]);
  console.log(statutorypayload, 'statutorypayload');
  const [tempstatutorypayload, setTempstatutorypayload] = useState([]);
  const [d_idlength, setd_idlength] = useState();
  const [UpdataCheck, setUpdateCheck] = useState([]);
  const [dataTableProperties, setDataTableProperties] = useState({
    fixedHeader: true,
    pagesize: 10,
    sizechanger: true,
    pageSizeOptions: ['10', '30', '50']
  })
  // const [tempeditdata, setTempeditdata] = useState();
  const [remarksFormSubmit, setRemarksFormSubmit] = useState(false)
  const [ExtraCollapseId, setExtraCollapseId] = useState()
  // const [CollapseId, setCollapseId] = useState()
  const [updateRemarks, setUpdateRemarks] = useState([])
  console.log(updateRemarks, 'updateRemarks');
  // const [check, setCheck] = useState(false)
  const [RowRemarks, setRowRemark] = useState([])
  const [RowStatus, setRowStatus] = useState([])
  console.log(RowStatus, 'RowStatus');
  const [updateStatus, setUpdateStatus] = useState([])
  console.log(updateStatus, 'updateStatus');
  const [remarksModal, setRemarksModal] = useState(false);
  const [PannelremarksModal, setPannelRemarksModal] = useState(false)
  const [collapseremarksModal, setCollapseremarksModal] = useState(false);
  const [PanelRemarkChange, setpanelRemarkChange] = useState(false)
  // const [remarksPanel, setRemarksPanel] = useState(true)
  // const [minuscircleoutlined, setMinuscircleoutlined] = useState([]);
  // const [closeoutlined, setCloseoutlined] = useState([]);
  // const [checkoutlined, setCheckoutlined] = useState([]);
  const [categoryData, setCategoryData] = useState([])
  const [divisionData, setDivisionData] = useState([])

  const [CheckStatus, setCheckStatus] = useState([])
  const [tempshowmore, setTempshowmore] = useState([])
  const [showmoredataadded, setShowmoredataadded] = useState(false)

  const [key, setKey] = useState("1")
  // const [finalcollapse, setFinalcollapse] = useState([]);
  // const [payload, setPayload] = useState([])
  // const [remarkvalue, setRemarkvalue] = useState([]);
  // const [messages, setMessages] = useState([])
  const [childData, setChildData] = useState([])
  const [collapsemessages, setCollapseMessages] = useState([])
  // const [tempremarkvalue, setTempremarkvalue] = useState([])
  // const [editopt, setEditopt] = useState([])
  // const [panelremark, setPanelremark] = useState([]);
  // const [remarkmessage, setRemarkmessage] = useState();
  // const [collapseremarkmessage, setCollapseremarkmessage] = useState([]);
  // const [collapsename, setCollapsename] = useState([]);
  // const [tempcollapseid, setTempcollapseid] = useState();
  // const [checkpanelremark, setCheckpanelremark] = useState([]);
  // const [remarksindex, setRemarksindex] = useState({
  //   remarks: false,
  //   remarks_id: [],
  //   parent: [],
  //   collapseId: []
  // });
  var [count1, setCount1] = useState(1);
  // let [remarkscount, setRemarkscount] = useState(0)
  const [checkdata, setCheckdata] = useState([])
  // const [remarksdata, SetRemarksdata] = useState({
  //   remarks: ''
  // })
  console.log(checkdata, 'checkdata');
  const [password, Setpassword] = useState({
    passwordvalue: '',
    le_id: '',
    d_id: '',
    u_id: '',
    lock: '',
  })

  const [filterTaskAcc, setFilterTaskAcc] = useState(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [exitCollapse, setExitCollapse] = useState(false);
  const [titledata, Settitledata] = useState()
  console.log(titledata, 'titledata');
  const [rowRemarks, setRowRemarks] = useState(false)
  // const [collapseRemark, setCollapseRemark] = useState([])
  const [filtervalue, Setfiltervalue] = useState({
    entity: '',
    division: '',
    category: '',
    bg_id: '',
    bg_name: '',
    legal_entity_id: ''
  })
  console.log(filtervalue, 'filtervalue');
  const [remarksvalue, Setremarksvalue] = useState();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setSearchText(selectedKeys[0]);
  //   setSearchedColumn(dataIndex);
  // };
  const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
    setSelectedKeys(e.target.value ? [e.target.value] : [])
    confirm({ closeDropdown: false });
    // setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  useEffect(() => {
    if (list && list.length > 0) {
      setListData(list)
    }
  }, [list])

  useEffect(() => {
    if (displayNoneParent) {
      for (let i in displayNoneParent) {
        if (displayNoneParent[i] == 'displaynone') {
          UpdatePannelRemarks[i] = null
        } else {
          console.log(UpdatePannelRemarks[i], UpdatePannelRemarks[i] ? UpdatePannelRemarks[i] : '', 'tttttttttttt');
          UpdatePannelRemarks[i] = UpdatePannelRemarks[i] ? UpdatePannelRemarks[i] : ''
        }
      }
      setUpdatePannelRemarks([...UpdatePannelRemarks])
    }
  }, [displayNoneParent])

  useEffect(() => {
    if (UpdatePannelRemarks) {
      let temp = []
      for (let i in titledata) {
        for (let j in titledata[i].child) {
          // titledata[i].child[j]te.push
          temp.push(UpdatePannelRemarks[i])
          // PayloadUpdatePannelRemarks[titledata[i].child[j].childId] 
          // PayloadUpdatePannelRemarks[titledata.child] 
        }
      }
      setPayloadUpdatePannelRemarks([...temp])
    }
  }, [UpdatePannelRemarks])
  useEffect(() => {
    if (statussaved == 'UpdateStatutorySettingsSuccess') {
      const payload = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetStatutorySettings",
            {
              "le_id": entityid,
              "div_id": parseInt(filtervalue.division),
              "cat_id": parseInt(filtervalue.category),
            }
          ]
        }
      ]
      if (entityid != 'null') {
        statutorylist({
          payload: payload,
          paramid: paramid
        })
      }
      // previous()
      seteditshow(false);
      setlistshow(true);
      setCheckedRow(false)
      setSelectedRows([])
      var ele = document.getElementsByName('allchecked');
      ele[0].checked = false;
      var ele1 = document.getElementsByName('checked');
      for (var j = 0; j < ele1.length; j++) {
        if (ele1[j].type === 'checkbox')
          ele1[j].checked = false;
        let a = document.getElementsByClassName(ele1[j].className)
        // a[0].removeAttribute("hidden")
        for (let j = 0; j < a.length; j++) {
          a[j].removeAttribute("disabled")
        }
      }
      // showmoredataadded
      setShowmoredataadded(false)
      setCheckdata([])
      dispatch({
        type: STATUTORY_SAVED_SUCCESS_RESET
      });
    }
  }, [statussaved])


  useEffect(() => {
    if (invalidpas == 'InvalidPassword') {
      // Setpasswordmodal(true)
      Toaster.error('Invalid Password')
      Setpassword({
        ...password,
        passwordvalue: ''
      })
      SetPassworddisable(false)
      // Setpasswordmodal(true)
      dispatch({
        type: STATUTORY_INVALID_PASSWORD_RESET
      });
    } else if (invalidpas == 'ChangeStatutorySettingsLockSuccess') {
      Setpasswordmodal(false);
    }
  }, [invalidpas])

  const getColumnSearchProps = (dataIndex, placeholder) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
      >
        <Input
          ref={searchInput}
          autoFocus={'on'}
          placeholder={'Search ' + placeholder}
          value={selectedKeys[0]}
          onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text
  });

  //  Full screen mode //
  const [fullscreen, setfullscreen] = useState(false);
  useEffect(() => {
    if (fullscreen == true) {
      document.addEventListener('fullscreenchange', handleKeyDown, false);
      document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
      return () => {
        document.addEventListener('fullscreenchange', handleKeyDown, false);
        document.removeEventListener('fullscreenchange', handleKeyDown, false);
        document.removeEventListener('webkitfullscreenchange', handleKeyDown);
      };
    }
  }, [fullscreen]);

  const fullscreenMode = () => {
    if (fullscreen == false) {
      fullscreenmode();
      setfullscreen(true);
    } else {
      fullscreenexit();
      setfullscreen(false);
    }
  }
  const setcancelFilter = () => {
    setFilterModalVisible(false);
    setFilterTaskAcc(false);
  };

  const filterHandle = () => {
    setFilterTaskAcc(true);
    setFilterModalVisible(true);
  };

  useEffect(() => {
    if (sessionArr && sessionArr.length > 0) {
      setEntityData(sessionArr)
      let unique = _.uniqBy(sessionArr, function (e) {
        return e.bg_id;
      });
      setBggroup(unique)
    }
  }, [])

  useEffect(() => {
    if (list && list.length > 0) {
      let temp = []
      for (let i in list) {
        temp.push(Number(list[i].r_count))

      }
      let TotalCompliance = temp.reduce(function (accumulator, curValue) {
        return accumulator + curValue
      }, 0)
      setTotalCompliance(TotalCompliance)

      let lockData = _.filter(list, { is_locked: true })
      setLockCount(lockData.length)
      if (lockData.length == list.length) {
        setOverallcheck(true)
      } else {
        setOverallcheck(false)
      }
      let unlockData = _.filter(list, { is_locked: false })
      setUnLockCount(unlockData.length)
    } else {
      setOverallcheck(true)
    }
    // if (list.length == 0) {
    //   setOverallcheck(true)
    // }else{
    //   setOverallcheck(false)
    // }
  }, [list])
  useEffect(() => {
    if (filterdatas) {
      setCategoryData(filterdatas && filterdatas.cat_info)
      setDivisionData(filterdatas && filterdatas.div_infos)
    }
  }, [filterdatas])


  useEffect(() => {
    if (localStorage.getItem('SelectedEntityid') != null) {
      setCurrentEntity(localStorage.getItem('SelectedEntityid'))
    }
  }, [localStorage.getItem('SelectedEntityid')])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY >= 213) {
      setScroll(true)
    }
    else {
      setScroll(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch({
        type: STATOTORYLIST,
        payload: ['', { statutories: [] }]
      })
    }
  }, [])

  const column = [
    {
      title: '#',
      dataIndex: 'indexNo',
      key: 'indexNo',
      width: "20px",
      align: "center",
      render: (row, record, index) => {
        return (
          <>
            <span>{record.indexNo}</span>
            {record.frequency_name == 'Periodical' ?
              <Tooltip title={record && record.frequency_name}><p><i style={{ color: "green" }} className="bi bi-square-fill"></i></p></Tooltip> :
              record.frequency_name == 'Flexi Review' ?
                <Tooltip title={record && record.frequency_name}><p><i style={{ color: "pink" }} className="bi bi-square-fill"></i></p></Tooltip> :
                record.frequency_name == 'Review' ?
                  <Tooltip title={record && record.frequency_name}><p><i style={{ color: "orange" }} className="bi bi-square-fill"></i></p></Tooltip> :
                  record.frequency_name == 'On Occurrence' ?
                    <Tooltip title={record && record.frequency_name}><p><i style={{ color: "yellow" }} className="bi bi-square-fill"></i></p></Tooltip> :
                    <Tooltip title={record && record.frequency_name}><p><i style={{ color: "red" }} className="bi bi-square-fill"></i></p></Tooltip>
            }
          </>
        )
      }, filters: [
        {
          text: <span><i style={{ color: "green" }} class="bi bi-square-fill"></i> Periodical</span>,
          value: 'Periodical',
        },
        {
          text: <span><i style={{ color: "pink" }} class="bi bi-square-fill"></i> Flexi Review</span>,
          value: 'Flexi Review',
        },
        {
          text: <span><i style={{ color: "orange" }} class="bi bi-square-fill"></i> Review</span>,
          value: 'Review',
        },
        {
          text: <span><i style={{ color: "yellow" }} class="bi bi-square-fill"></i> On Occurrence</span>,
          value: 'On Occurrence',
        },
        {
          text: <span><i style={{ color: "red" }} class="bi bi-square-fill"></i> One Time</span>,
          value: 'One Time',
        },
      ],
      onFilter: (value, record) => record.frequency_name == value,
    },

    {
      title: "Statutory Provision",
      render: (record) => record.s_prov,
      sortable: true,
      width: '210px',
      reorder: true,
      ellipsis: false
    },
    {
      title: "Compliance Task",
      render: (record) => record.comp_name,
      sortable: true,
      width: '170px',
      reorder: true,
      // ellipsis: true
    },
    {
      title: "Compliance Description",
      render: (record) => record.descp,
      sortable: true,
      reorder: true,
      width: '170px',
      // ellipsis: true
    },
    {
      title: "Criticality",
      // render: (record) => record.criticality_know,
      sortable: true,
      reorder: true,
      width: '35px',
      // ellipsis: true,
      align: 'center',
      render: (record) => {
        return (record.criticality_client === null ? record.criticality_know :
          (record.criticality_client !== record.criticality_know ?
            <span style={{ color: 'red', cursor: 'pointer' }} title={record.criticality_know} >
              {record.criticality_client}</span> : <span title={''}>{record.criticality_client}</span>))
      }
    },
    {
      title:
        <span title='Applicable'>
          <EditOutlined />
        </span>,
      render: (record) => record.app_status === true ? <FeatherIcon icon="check-circle" size={20} className={'pointer'} style={{ color: '#318CE7' }} /> : <FeatherIcon icon="x-circle" className={'pointer'} size={20} />,
      // sortable: true,
      // right: true,
      reorder: true,
      width: '20px',
      // ellipsis: true,
      align: 'center'
    },
    {
      title: <span title='Opted'>
        <UserAddOutlined />
      </span>,
      render: (record) => {
        // let temp;
        // if (record.unit_wise_status[0].comp_app_status === true) {
        //   temp = 1
        // } else {
        //   temp = 0
        // }
        // if (record.unit_wise_status[0].comp_opt_status === 1) {
        //   // if (remarksindex.remarks_id.length === 0) {
        //   return (record.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) < 0) ? <CheckOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]}onClick={(e) => {
        //     changeremarks(e, record)
        //   }} /> :
        //     (record.unit_wise_status[0].comp_opt_status === 1 && (closeoutlined.indexOf(record.comp_id) >= 0) && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (checkoutlined.indexOf(record.comp_id) < 0) ? <CloseOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //       changeremarks(e, record)
        //     }} /> :
        //       (record.unit_wise_status[0].comp_opt_status === 1 && (checkoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) < 0) && (minuscircleoutlined.indexOf(record.comp_id) >= 0) ? <MinusCircleOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //         changeremarks(e, record)
        //       }} /> : '')))
        //   // (row.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) >= 0) ? <CloseOutlined onClick={(e) => {
        //   //   changeremarks(e, row)
        //   // }} /> :
        //   //   (row.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(row.comp_id) >= 0) && (checkoutlined.indexOf(row.comp_id) < 0) ? <MinusCircleOutlined onClick={(e) => {
        //   //     changeremarks(e, row)
        //   //   }} /> : '')))
        //   // } else {
        //   // return (row.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) ? <CheckOutlined onClick={(e) => {
        //   //   changeremarks(e, row)
        //   // }} /> :
        //   //   (row.unit_wise_status[0].comp_opt_status === 1 && (closeoutlined.indexOf(row.comp_id) >= 0) && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (checkoutlined.indexOf(row.comp_id) < 0) ? <CloseOutlined onClick={(e) => {
        //   //     changeremarks(e, row)
        //   //   }} /> :
        //   //     (row.unit_wise_status[0].comp_opt_status === 1 && (checkoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) && (minuscircleoutlined.indexOf(row.comp_id) >= 0) ? <MinusCircleOutlined onClick={(e) => {
        //   //       changeremarks(e, row)
        //   //     }} /> : '')))
        //   // }
        // } else if (record.unit_wise_status[0].comp_opt_status === 0) {
        //   return (record.unit_wise_status[0].comp_opt_status === 0 && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (checkoutlined.indexOf(record.comp_id) < 0) ? <CloseOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //     changeremarks(e, record)
        //   }} /> :
        //     (record.unit_wise_status[0].comp_opt_status === 0 && (minuscircleoutlined.indexOf(record.comp_id) >= 0) && (closeoutlined.indexOf(record.comp_id) < 0) && (checkoutlined.indexOf(record.comp_id) < 0) ? <MinusCircleOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //       changeremarks(e, record)
        //     }} /> :
        //       (record.unit_wise_status[0].comp_opt_status === 0 && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) < 0) && (checkoutlined.indexOf(record.comp_id) >= 0) ? <CheckOutlined   defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //         changeremarks(e, record)
        //       }} /> : '')))
        // } else {
        //   return (record.unit_wise_status[0].comp_opt_status === 2 && (checkoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) < 0) ? <MinusCircleOutlined onClick={(e) => {
        //     changeremarks(e, record)
        //   }} /> :
        //     (record.unit_wise_status[0].comp_opt_status === 2 && (checkoutlined.indexOf(record.comp_id) >= 0) && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) < 0) ? <CheckOutlined defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //       changeremarks(e, record)
        //     }} /> :
        //       (record.unit_wise_status[0].comp_opt_status === 2 && (checkoutlined.indexOf(record.comp_id) < 0) && (minuscircleoutlined.indexOf(record.comp_id) < 0) && (closeoutlined.indexOf(record.comp_id) >= 0) ? <CloseOutlined  defaultValue={updateStatus[record.childId]}  key={updateStatus[record.childId]} onClick={(e) => {
        //         changeremarks(e, record)
        //       }} /> : '')))
        // }<Checkmark /> BsCheckLg

        if (updateStatus[record.childId] === 1) {
          return <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'orange' }} size="20" defaultValue={updateStatus[record.childId]} key={updateStatus[record.childId]} onClick={(e) => {
            setpanelRemarkChange(false)
            // setCheck(false)
            // tick to close
            // changeremarks(e, record)
            CheckStatus.map((item, i) => {
              if (item == record.comp_id) {
                CheckStatus.splice(i, 1)
              }
            })
            setRemarksFormSubmit(false)
            let temp = []
            temp.push(record.comp_id)
            setCheckStatus([...CheckStatus, ...temp])
            if (record.childId in updateStatus) {
              // let index = updateStatus.indexOf(record.childId)
              // updateStatus[record.childId] = [];
              // if (index == -1) {
              updateStatus[record.childId] = 0
              setUpdateStatus([...updateStatus])
              // } else {
              // updateStatus[record.childId] = [];
              // updateStatus[record.childId] = 0
              // }
            }
            // if (record.childId in RowStatus) {
            //   let index = RowStatus.indexOf(record)
            //   RowStatus[record.childId] = [];
            //   if (index == -1) {
            //     RowStatus[record.childId] = 0
            //   }
            //   else {
            //     RowStatus[record.childId] = [];
            //     RowStatus[record.childId] = 0
            //   }
            // }
            // setRowStatus([...RowStatus])
            // UpdataCheck.map((item, i) => {
            //   if (item == record.parentId) {
            //     UpdataCheck.splice(i, 1)
            //   }
            // })
            // setUpdateCheck([...UpdataCheck])
            if (CheckStatus.includes(record.comp_id)) {
              if (record.parentId in CollapseStatus) {
                let index = CollapseStatus.indexOf(record.parentId)
                RowStatus[record.childId] = [];
                if (index == -1) {
                  CollapseStatus[record.parentId] = 1
                } else {
                  CollapseStatus[record.parentId] = [];
                  CollapseStatus[record.parentId] = 1
                }
              }
              setCollapseStatus([...CollapseStatus])
            } else {
              // if (record.parentId in CollapseStatus) {
              // let index = CollapseStatus.indexOf(record.parentId)
              // RowStatus[record.childId] = 0;
              // if (index == -1) {
              // CollapseStatus[record.parentId] = 0
              // } else {
              // CollapseStatus[record.parentId] = [];
              let temp = []
              for (let i in tempstatutorypayload) {
                if (tempstatutorypayload[i].collapseId == record.parentId) {
                  if (tempstatutorypayload[i].comp_id == record.comp_id) {
                    temp.push(0)
                  } else {
                    temp.push(tempstatutorypayload[i].c_o_status)
                  }
                }
              }
              if (temp.includes(1) != true) {
                CollapseStatus[record.parentId] = 0
              }
              // else{

              // }
              // }
              // }
              // }
              setCollapseStatus([...CollapseStatus])
            }

            // if (record.childId in updateRemarks) {
            // let index = updateRemarks.indexOf(record)
            // updateRemarks[record.childId] = [];
            // if (index == -1) {
            if (record.app_status ? 1 : 0 == updateStatus[record.childId]) {
              updateRemarks[record.childId] = null;
              console.log('kaiii');
            } else {
              updateRemarks[record.childId] = '';
              console.log(updateRemarks[record.childId], record.childId, 'kaal')
            }
            // }
            // }
            // else {
            // updateRemarks[record.childId] = [];
            // updateRemarks[record.childId] = null
            // }
            setUpdateRemarks([...updateRemarks])
            // CheckStatus.map((item, i) => {
            //   if (item == record.c_comp_id) {
            //     CheckStatus.splice(i, 1)
            //   }
            // })

            // let tempArray = []
            // payload && payload.length > 0 && payload.map((item, i) => {
            //   if (item.comp_id === record.comp_id) {
            //     payload.splice(i, 1)
            //     setPayload([...payload, ...tempArray])
            //   }
            // })
            // tempArray.push({
            //   "c_c_id": record.unit_wise_status[0].c_comp_id,
            //   "a_status": record.app_status,
            //   "n_a_remarks": record.not_app_remarks,
            //   "comp_id": record.comp_id,
            //   "c_o_status": updateStatus[record.childId],
            //   "c_remarks": null,
            //   "u_name": selectedRows[0].u_name,
            //   "u_id": record.unit_wise_status[0].unit_id,
            //   "c_a_status": record.app_status,
            //   "h_id": record.unit_wise_status[0].h_id
            // })
            // setPayload([...payload, ...tempArray])
          }}
          />
        }
        else if (updateStatus[record.childId] === 2) {
          return <FeatherIcon icon="minus-circle" className={'pointer'} size={20} style={{ color: '#FF0000' }} onClick={(e) => {
            setpanelRemarkChange(false)
            // setCheck(true)
            // minus to tick
            if (record.app_status ? 1 : 0 == updateStatus[record.childId]) {
              updateRemarks[record.childId] = null;
              console.log('kaiii');
            } else {
              updateRemarks[record.childId] = '';
              console.log(updateRemarks[record.childId], record.childId, 'kaal')
            }
            let tempArray = []
            // changeremarks(e, record)
            if (record.childId in updateStatus) {
              // let index = updateStatus.indexOf(record)
              // updateStatus[record.childId] = [];
              // if (index == -1) {
              updateStatus[record.childId] = 1
              // }
              // else {
              // updateStatus[record.childId] = [];
              // updateStatus[record.childId] = 1
              // }
            }
            setUpdateStatus([...updateStatus])
            setRemarksFormSubmit(false)
            // if (record.parentId in CollapseStatus) {
            // let index = CollapseStatus.indexOf(CollapseId)
            // RowStatus[record.childId] = [];
            // if (index == -1) {
            CollapseStatus[record.parentId] = 1
            // }
            // else {
            // CollapseStatus[record.parentId] = [];
            // CollapseStatus[record.parentId] = 1
            // }
            // }
            setCollapseStatus([...CollapseStatus])
            let temp1 = []
            UpdataCheck.map((item, i) => {
              if (item == record.parentId) {
                UpdataCheck.splice(i, 1)
              }
            })
            temp1.push(record.parentId)
            setUpdateCheck([...UpdataCheck, ...temp1])
            // if (record.childId in RowStatus) {
            //   let index = RowStatus.indexOf(record)
            //   RowStatus[record.childId] = [];
            //   if (index == -1) {
            //     RowStatus[record.childId] = 1
            //   }
            //   else {
            //     RowStatus[record.childId] = [];
            //     RowStatus[record.childId] = 1
            //   }
            // }
            // setRowStatus([...RowStatus])
            let temp = []

            temp.push(record.comp_id)
            setCheckStatus([...CheckStatus, ...temp])
            // payload && payload.length > 0 && payload.map((item, i) => {
            //   if (item.comp_id === record.comp_id) {
            //     payload.splice(i, 1)
            //     setPayload([...payload, ...tempArray])
            //   }
            // })
            // tempArray.push({
            //   "c_c_id": record.unit_wise_status[0].c_comp_id,
            //   "a_status": record.app_status,
            //   "n_a_remarks": record.not_app_remarks,
            //   "comp_id": record.comp_id,
            //   "c_o_status": updateStatus[record.childId],
            //   "c_remarks": updateRemarks[record.childId],
            //   "u_name": selectedRows[0].u_name,
            //   "u_id": record.unit_wise_status[0].unit_id,
            //   "c_a_status": record.app_status,
            //   "h_id": record.unit_wise_status[0].h_id
            // })
            // setPayload([...payload, ...tempArray])

          }} defaultValue={updateStatus[record.childId]} key={updateStatus[record.childId]} />
        }
        else {
          return <FeatherIcon icon="x-circle" size="20" className={'pointer'} defaultValue={updateStatus[record.childId]} key={updateStatus[record.childId]} onClick={(e) => {
            // changeremarks(e, record)
            setpanelRemarkChange(false)
            // setCheck(false)
            let tempArray = []
            if (record.childId in updateStatus) {
              updateStatus[record.childId] = 2

            }
            if (record.app_status ? 1 : 0 == updateStatus[record.childId]) {
              updateRemarks[record.childId] = null;
              console.log('kaiii');
            } else {
              updateRemarks[record.childId] = '';
              console.log(updateRemarks[record.childId], record.childId, 'kaal')
            }
            setUpdateStatus([...updateStatus])
            CheckStatus.map((item, i) => {
              if (item == record.comp_id) {
                CheckStatus.splice(i, 1)
              }
            })
            setCheckStatus([...CheckStatus])
            setRemarksFormSubmit(false)
          }} />
        }

      },
      reorder: true,
      width: '20px',
      // ellipsis: true,
      align: 'center'

    },
    {
      title: "Remarks",
      render: (record) => {
        if (CheckStatus.includes(record.comp_id) == false) {
          if ((updateRemarks[record.childId] === null) && (CollapseStatus[record.parentId] === 1) && (updateStatus[record.childId] != 2) && (updateStatus[record.childId] != 1)) {
            return <p>{record.not_app_remarks}</p>
          }
        }
        if (titledata && titledata.length > 0 && titledata[record.parentId].child.length == 1) {
          return false
        } else {
          if (Number(record.unit_wise_status[0].comp_app_status) != updateStatus[record.childId]) {
            if (PanelRemarkChange == true || updateRemarks[record.childId] != null ? updateRemarks[record.childId] : '' === RowRemarks[record.childId]) {
              return <>
                <div className='d-flex align-items-end'>
                  <div className="d-flex flex-column" style={{ "width": "100%" }}>
                    <textarea class="form-control" cols="5" rows="1" placeholder='Enter remarks' style={{ "width": "100%" }}
                      // value={row.unit_wise_status[0].comp_remarks}
                      defaultValue={updateRemarks[record.childId]}
                      // value={updateRemarks[record.childId]}
                      key={updateRemarks[record.childId]}
                      onFocusCapture={(e) => {
                        console.log(e, 'on-fouce-capture')
                      }}

                      onBlur={(e) => {
                        let tempArray = updateRemarks
                        let temp = []
                        temp.push(e.target.value)
                        if (record.childId in tempArray) {
                          // let index = updateRemarks.indexOf(record)
                          // updateRemarks[record.childId] = [];
                          // if (index == -1) {
                          tempArray[record.childId] = e.target.value;
                          // }
                          // } else {
                          // updateRemarks[record.childId] = [];
                          // updateRemarks[record.childId] = e.target.value
                        }
                        // document.getElementById(`remarks_${record.comp_id}`).focus();
                        setUpdateRemarks([...tempArray])

                      }}
                      id={`remarks_${record.comp_id}`} name="remarks"
                      hidden={PanelRemarkChange == true || updateRemarks[record.childId] === RowRemarks[record.childId] ? true : false}
                    />
                    {saveformValidator.current.message(
                      'remarks',
                      updateRemarks[record.childId],
                      'required',
                      {
                        className: `invalid-feedback ${remarksFormSubmit && (PanelRemarkChange == true || updateRemarks[record.childId] === RowRemarks[record.childId]) ? 'show' : 'hide'}`,
                        messages: {
                          required: 'Remarks Required',

                        }
                      })
                    }
                  </div>
                  <PlusCircleOutlined className='text-info ms-1' onClick={() => { showRemarksModal(record.comp_id, record.collapseId, record.childId) }} hidden={PanelRemarkChange == true || updateRemarks[record.childId] === RowRemarks[record.childId] ? true : false} style={{ marginTop: '10px' }} />
                </div>
              </>
            } else {
              return <>
                <div className='d-flex align-items-end'>
                  <div className="d-flex flex-column" style={{ "width": "100%" }}>
                    <textarea class="form-control" cols="5" rows="1" placeholder='Enter remarks' style={{ "width": "100%" }}
                      // value={row.unit_wise_status[0].comp_remarks}
                      // value={updateRemarks[record.childId]}
                      defaultValue={updateRemarks[record.childId]}
                      key={updateRemarks[record.childId]}
                      onFocusCapture={(e) => {
                        console.log(e, 'on-fouce-capture')
                      }}
                      onBlur={(e) => {
                        let tempArray = updateRemarks
                        let temp = []
                        temp.push(e.target.value)

                        if (record.childId in tempArray) {
                          // let index = updateRemarks.indexOf(record)
                          // updateRemarks[record.childId] = [];
                          // if (index == -1) {
                          tempArray[record.childId] = e.target.value;
                          // }
                          // } else {
                          // updateRemarks[record.childId] = [];
                          // updateRemarks[record.childId] = e.target.value
                        }
                        // document.getElementById(`remarks_${record.comp_id}`).focus();
                        setUpdateRemarks([...tempArray])


                      }}
                      id={`remarks_${record.comp_id}`} name="remarks"
                      hidden={PanelRemarkChange == true}
                    />
                    {saveformValidator.current.message(
                      'remarks',
                      updateRemarks[record.childId],
                      'required',
                      {
                        className: `invalid-feedback ${remarksFormSubmit && (PanelRemarkChange == true || updateRemarks[record.childId] === RowRemarks[record.childId]) ? 'show' : 'hide'}`,
                        messages: {
                          required: 'Remarks Required',

                        }
                      })
                    }
                  </div>
                  <PlusCircleOutlined className='text-info ms-1' onClick={() => { showRemarksModal(record.comp_id, record.collapseId, record.childId) }} hidden={PanelRemarkChange == true} style={{ marginTop: '10px' }} />
                </div>

              </>
            }
          }
        }
      },
      sortable: true,
      reorder: true,
      width: '140px',
      // ellipsis: true
    },
    {
      title: "Viewed",
      render: (record) => {
        return (
          <>
            {record && record.unit_wise_status && record.unit_wise_status[0].is_new ?
              // <Tooltip title={record.compliance_frequency}><p><i style={{ color: "white" }} class="bi bi-square-fill"></i></p></Tooltip> :
              <></> :
              <Tooltip title={record.compliance_frequency}><p><i style={{ color: "orange" }} class="bi bi-square-fill"></i></p></Tooltip>
            }
          </>
        )
      },
      sortable: true,
      reorder: true,
      width: '50px',
      // ellipsis: true,
      align: 'center'
    }
  ];

  // useEffect(()=>{

  // },[])

  const parentStatusChange = (parentId, childId, changeto, childData) => {

    console.log(parentId, childId, changeto, childData, 'childData')
    /*Before set into updateStatus state while onload */
    // updateChildRemarks[childId] = undefined
    // setUpdateChildRemarks([...updateChildRemarks])
    if (childData.length != 1) {

      //     /* remove parent once child input enables*/
      //     Object.keys(updateParentRemarks).forEach(key => {
      //         if (key == parentId) {
      //             delete updateParentRemarks[parentId];
      //         }
      //     });
      let temp = [];
      let tempParent = [];
      for (let i in childData) {
        tempParent.push(childData[i].parentId)
      }

      let finalArrayParent = [...new Set(tempParent)]
      for (let j in childData) {
        if (finalArrayParent[0] == parentId) {
          temp.push(updateopt[childData[j].childId])
        }
      }
      console.log(temp, temp.includes(1), temp.includes(0), 'temp5555555555555555555');
      let unqiueArrayValueCheck = temp.every((val, i, arr) => val === arr[0])

      if (childData.length == temp.length) {
        console.log(unqiueArrayValueCheck, changeto, 'unqiueArrayValueCheckkkk')
        if (unqiueArrayValueCheck == true) {
          console.log("111111111111");
          for(let i in childData){
            updateChildRemarks[childData[i].childId]= null
          }
          setUpdateChildRemarks([...updateChildRemarks])
          CollapseStatus[parentId] = changeto
          setCollapseStatus([...CollapseStatus])
          for (let k in childData) {
            console.log(childData.childId, "2222");
            PayloadCollapseStatus[childData[k].childId] = changeto
            setPayloadCollapseStatus([...PayloadCollapseStatus])
          }
          console.log(CollapseStatus[parentId] == temp[0], 'mmmmmmm');
          if (CollapseStatus[parentId] == temp[0]) {
            for (let m in childData) {
              displayNoneChild[childData[m].childId] = 'displaynone'
              splitcollapsedisplay[parentId] = 'displaynone'

            }
            setDisplayNoneChild([...displayNoneChild])
            setsplitcollapsedisplay([...splitcollapsedisplay])
            // displayNoneParent[parentId] = undefined
            // setDisplayNoneParent([...displayNoneParent])
            if (refCollapseStatus[parentId] == temp[0]) {
              if (refCollapseStatus[parentId] == changeto) {
                if (refCollapseStatus[parentId] == 1) {
                  displayNoneParent[parentId] = 'displaynone'
                  setDisplayNoneParent([...displayNoneParent])
                } else {
                  displayNoneParent[parentId] = undefined
                  setDisplayNoneParent([...displayNoneParent])
                }

              } else {
                displayNoneParent[parentId] = undefined
                setDisplayNoneParent([...displayNoneParent])
              }
            } else {
              displayNoneParent[parentId] = undefined
              setDisplayNoneParent([...displayNoneParent])
            }
            let sample = []
            for (let l in childData) {
              sample.push(displayNoneChild[childData[l].childId])
            }
            console.log(sample, "sampleeeee");
          } else {
            console.log(displayNoneParent[parentId], "sampleeeee22222");
            displayNoneParent[parentId] = undefined
            setDisplayNoneParent([...displayNoneParent])
          }
        } else {
          // console.log(parentId, childId, changeto, childData, 'childData'),temp5555555555555555555
          console.log(displayNoneParent[parentId], parentId, 'second_else')
          if (temp.includes(1)) {
            console.log('vibin1')
            // setAddFormSubmit2(false)
            CollapseStatus[parentId] = 1
            setCollapseStatus([...CollapseStatus])
            for (let k in childData) {
              PayloadCollapseStatus[childData[k].childId] = 1
              setPayloadCollapseStatus([...PayloadCollapseStatus])
            }
            if (refCollapseStatus[parentId] == changeto) {
              if(changeto == 1){
                displayNoneParent[parentId] = 'displaynone'
              }else{
                displayNoneParent[parentId] = undefined
              }
              setDisplayNoneParent([...displayNoneParent])
            } else {
              displayNoneParent[parentId] = 'displaynone'
              setDisplayNoneParent([...displayNoneParent])
            }
            console.log(UpdatePannelRemarks[parentId], 'ooooo');
            // for(let k in UpdatePannelRemarks){
            // if(UpdatePannelRemarks[parentId]){
              for (let i in refcompid) {
                if(refcompid[i] == updateopt[i]){
                  console.log("haiiiii");
                  updateChildRemarks[i] = null
                }else{
                  console.log("haiiiii22222");
                  updateChildRemarks[i] = ''
                }
                // console.log(displayNoneChild[i] == undefined,displayNoneChild[i],'hai1111');
                // if (displayNoneChild[i] == undefined) {
                //   console.log("haiiiii");
                //   updateChildRemarks[i] = ''
                // }else{
                //   console.log("haiiiii22222");
                //   updateChildRemarks[i] = null
                // }
              }
              setUpdateChildRemarks([...updateChildRemarks])   
            UpdatePannelRemarks[parentId] = UpdatePannelRemarks[parentId] ? UpdatePannelRemarks[parentId] : null
            setUpdatePannelRemarks([...UpdatePannelRemarks])
            
            // }
            // }
            // for(let i in displayNoneChild){
            //   if(displayNoneChild[i] == 'displaynone'){
            //     updateChildRemarks[i]  = null
            //   }
            // else{

            // }
            // }
            // for (let l in childData) {
            //   if (finalArrayParent[0] == parentId) {
            //     displayNoneChild[childData[l].id] = 'displaynone'
            //     setDisplayNoneChild([...displayNoneChild])
            //   }
            // }
            // console.log(parentId, childId, changeto, childData, 'childData')temp5555555555555555555

          } else if (temp.includes(0)) {
            console.log('vibin12')
            CollapseStatus[parentId] = 0
            setCollapseStatus([...CollapseStatus])
            setUpdatePannelRemarks([...UpdatePannelRemarks])
            for (let k in childData) {
              PayloadCollapseStatus[childData[k].childId] = 0
              setPayloadCollapseStatus([...PayloadCollapseStatus])
            }
            console.log('1234567891');
            if (refCollapseStatus[parentId] == changeto) {
              console.log('1234567892');
              if (refCollapseStatus[parentId] == 1) {
                console.log('1234567893');
                displayNoneParent[parentId] = 'displaynone'
                setDisplayNoneParent([...displayNoneParent])
              } else {
                if(refCollapseStatus[parentId] == 0){
                  displayNoneParent[parentId] = 'displaynone'
                  setDisplayNoneParent([...displayNoneParent])
                }else{
                  displayNoneParent[parentId] = undefined
                  setDisplayNoneParent([...displayNoneParent])
                }
              }
            } else {
              displayNoneParent[parentId] = 'displaynone'
              setDisplayNoneParent([...displayNoneParent])
              for (let i in refcompid) {
                if(refcompid[i] == updateopt[i]){
                  console.log("haiiiii");
                  updateChildRemarks[i] = null
                }else{
                  console.log("haiiiii22222");
                  updateChildRemarks[i] = ''
                }
                // console.log(displayNoneChild[i] == undefined,displayNoneChild[i],'hai1111');
                // if (displayNoneChild[i] == undefined) {
                //   console.log("haiiiii");
                //   updateChildRemarks[i] = ''
                // }else{
                //   console.log("haiiiii22222");
                //   updateChildRemarks[i] = null
                // }
              }
              setUpdateChildRemarks([...updateChildRemarks])         
            }
          } else {
            console.log('vibin')
            if (refCollapseStatus[parentId] == changeto) {
              displayNoneParent[parentId] = undefined
              setDisplayNoneParent([...displayNoneParent])
            } else {
              displayNoneParent[parentId] = 'displaynone'
              setDisplayNoneParent([...displayNoneParent])
            }
          }
          for (let m in childData) {
            displayNoneChild[childData[m].childId] = undefined
            splitcollapsedisplay[parentId] = undefined
          }
          setDisplayNoneChild([...displayNoneChild])
          setsplitcollapsedisplay([...splitcollapsedisplay])
        }

      } else {
        // for (let m in childData) {
        // displayNoneChild[childId] = undefined
        // }
        // setDisplayNoneChild([...displayNoneChild])
        // updateStatusParent[parentId] = 0
        // setUpdateStatusParent([...updateStatusParent])
        // console.log(updateStatusParent[parentId], 'updateStatusParent3')
        // for (let l in childData) {
        //     if (finalArrayParent[0] == parentId) {
        //         displayNoneChild[childData[l].id] = ''
        //         setDisplayNoneChild([...displayNoneChild])
        //     }
        // }
      }
    } else {
      CollapseStatus[parentId] = changeto
      setCollapseStatus([...CollapseStatus])
      for (let k in childData) {
        PayloadCollapseStatus[childData[k].childId] = changeto
        setPayloadCollapseStatus([...PayloadCollapseStatus])
      }
      displayNoneParent[parentId] = undefined
      setDisplayNoneParent([...displayNoneParent])
      console.log(CollapseStatus[parentId], childData, 'CollapseStatus11111111')
      // if (changeto == 2) {
      for (let m in childData) {
        displayNoneChild[childData[m].childId] = 'displaynone'
      }
      if (CollapseStatus[parentId] == 1) {
        displayNoneParent[parentId] = 'displaynone'
        setDisplayNoneParent([...displayNoneParent])
      }
      // displayNoneParent[parentId] = undefined
      setDisplayNoneChild([...displayNoneChild])
      // let k = []
      // let tempdisplay = []
      // console.log(itemdata.child, 'llllllllllllllll');
      // for (let y in itemdata.child) {
      //   k.push(itemdata.child[y].childId)
      // }
      // console.log(k, 'kkkkkkkkkkkkkkkkkkk');
      // for (let l in k) {
      //   tempdisplay.push(displayNoneChild[l])
      // }
      // console.log(tempdisplay, 'tempdisplay');
      // setsplitcollapsedisplay(tempdisplay)
      // }
    }


  }

  const childStatusChange = (parentId, changeto, data) => {
    console.log(parentId, changeto, data, 'dataaaaaaa');
    if (changeto == 2) {
      // setDisplayNone('')
      // setDisplayNoneChild('displaynone')
    }
    // updateStatusParent[parentId] = changeto
    // setUpdateStatusParent([...updateStatusParent])
    for (let i in data) {
      updateopt[data[i].childId] = changeto
      setUpdateopt([...updateopt])
      // displayNoneChild[data[i].id] = 'displaynone'
      // setDisplayNoneChild([...displayNoneChild])
    }
  }

  const genExtra = (val, collapseId, parent, child) => {
    return panel_data(val, collapseId, parent, child);
  };

  const panel_data = (val, collapseId, parent, child) => {
    let temp = child[0].unit_wise_status[0].comp_app_status ? 1 : 0
    let sample = []
    for (let k in child) {
      sample.push(child[k].app_status ? 1 : 0)
    }
    let newval = sample.every((val, i, arr) => val === temp)
    console.log(newval, temp, collapseId, parent, child, 'temptemptemptemptemptemptemp');
    let sampletemp = []
    let checknewval = viewstatus.every((val, i, arr) => val === true)
    // if(checknewval){
    //   displayNoneParent[collapseId] = 'displaynone'
    // }
    // if (checknewval ? (newval && temp == 0) : false) {
    if (checknewval) {
      if (checknewval ? (newval && temp == 0) : false) {
        return <div className='d-flex align-items-end'
          onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
          }}>
          <label style={{ "paddingRight": "15px" }}>Remarks :</label>
          <div className='d-flex flex-column'>
            <Input cols="5" rows="1" style={{ width: "120px" }} type={"text"}
              placeholder="Enter remarks"
              // id={'Parent_' + collapseId} className={'form-control ' + displayNoneParent[collapseId]}
              defaultValue={UpdatePannelRemarks[collapseId]} key={UpdatePannelRemarks[collapseId]}
              onBlur={(e) => {
                // if (collapseId in UpdatePannelRemarks) {
                //   // let index = updateRemarks.indexOf(rowId)
                //   UpdatePannelRemarks[collapseId] = [];
                //   if (UpdatePannelRemarks.indexOf(collapseId) == -1) {
                //     UpdatePannelRemarks[collapseId] = e.target.value
                //   }
                // } else {
                //   UpdatePannelRemarks[collapseId] = [];
                //   UpdatePannelRemarks[collapseId] = e.target.value
                // }
                UpdatePannelRemarks[collapseId] = e.target.value;
                setUpdatePannelRemarks([...UpdatePannelRemarks])
                // setRemarksModal(false)
                setPannelRemarksModal(false)
                if (PanelRemarkChange == true) {
                  for (let i in child) {
                    if (child[i].childId in updateRemarks) {
                      let index = updateRemarks.indexOf(child[i].childId)
                      updateRemarks[child[i].childId] = [];
                      if (index == -1) {
                        updateRemarks[child[i].childId] = e.target.value
                      }
                      else {
                        updateRemarks[child[i].childId] = [];
                        updateRemarks[child[i].childId] = e.target.value
                      }
                    }
                  }
                  setUpdateRemarks([...updateRemarks])
                }
              }
              }
            />

          </div>
          <PlusCircleOutlined className='text-info' style={{ "paddingLeft": "5px" }} onClick={() => { showRemarksModall(collapseId, parent, child) }} />
        </div>
      } else {
        return null
      }
    } else {
      return <div className='d-flex align-items-end'
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
        }}>
        <label style={{ "paddingRight": "15px" }} className={displayNoneParent[collapseId]}>Remarks :</label>
        <div className='d-flex flex-column'>
          <Input cols="5" rows="1" style={{ width: "120px" }} type={"text"}
            placeholder="Enter remarks"
            id={'Parent_' + collapseId} className={'form-control ' + displayNoneParent[collapseId]}
            defaultValue={UpdatePannelRemarks[collapseId]} key={UpdatePannelRemarks[collapseId]}
            onBlur={(e) => {
              // if (collapseId in UpdatePannelRemarks) {
              //   // let index = updateRemarks.indexOf(rowId)
              //   UpdatePannelRemarks[collapseId] = [];
              //   if (UpdatePannelRemarks.indexOf(collapseId) == -1) {
              //     UpdatePannelRemarks[collapseId] = e.target.value
              //   }
              // } else {
              //   UpdatePannelRemarks[collapseId] = [];
              //   UpdatePannelRemarks[collapseId] = e.target.value
              // }
              UpdatePannelRemarks[collapseId] = e.target.value;
              setUpdatePannelRemarks([...UpdatePannelRemarks])
              // setRemarksModal(false)
              setPannelRemarksModal(false)
              if (PanelRemarkChange == true) {
                for (let i in child) {
                  if (child[i].childId in updateRemarks) {
                    let index = updateRemarks.indexOf(child[i].childId)
                    updateRemarks[child[i].childId] = [];
                    if (index == -1) {
                      updateRemarks[child[i].childId] = e.target.value
                    }
                    else {
                      updateRemarks[child[i].childId] = [];
                      updateRemarks[child[i].childId] = e.target.value
                    }
                  }
                }
                setUpdateRemarks([...updateRemarks])
              }
            }
            }
          />

        </div>
        <PlusCircleOutlined className={'text-info ' + displayNoneParent[collapseId]} style={{ "paddingLeft": "5px" }} onClick={() => { showRemarksModall(collapseId, parent, child) }} />
      </div>
    }
  }

  const rowSelection = {
    renderCell: (checked, record, index, originNode) => {
      return (
        <Fragment style={{ width: '10px' }}>
          <input type="checkbox"
            // style={{ width: '10px' }}
            name="checked"
            className={record.d_id}
            hidden={record.is_locked === false ? false : true}
            onClick={(e) => {
              let checked = e.target.checked;
              addSelectedDataValues(
                checked,
                record.u_id,
                record.d_id
                // record.childId
              );
            }}
          />
        </Fragment>
      )
    }

  }

  const columns = [
    {
      title: "Unit",
      ...getColumnSearchProps('u_name', 'Unit'),
      render: (record) => {
        if (record.is_new) {
          return <><Tooltip placement='bottomLeft' title={`${record.address}`} >{" "}<InfoCircleOutlined style={{ color: '#5DAAE3' }} /></Tooltip><span style={{ color: '#0000FF' }}>{" "}{record.u_name}</span></>
        } else {
          return <><Tooltip placement='bottomLeft' title={`${record.address}`} >{" "}<InfoCircleOutlined style={{ color: '#5DAAE3' }} /></Tooltip>{" "}{record.u_name}</>
        }
      },
      sortable: true,
      width: '210px',
      // ellipsis: true,
      dataIndex: false,
      key: false,

    },
    {
      title: "Location",
      ...getColumnSearchProps('location', 'Location'),
      render: (record) => {
        if (record.is_new) {
          return <span style={{ color: '#0000FF' }}>{record.location}</span>
        } else {
          return record.location
        }
      },
      sortable: true,
      width: '210px',
      // ellipsis: true,
      dataIndex: false,
      key: false,

    },
    {
      title: "Domain",
      ...getColumnSearchProps('d_name', 'Domain'),
      render: (record) => {
        if (record.is_new) {
          return <span style={{ color: '#0000FF' }}>{record.d_name}</span>
        } else {
          return record.d_name
        }
      },
      sortable: true,
      width: '190px',
      // ellipsis: true,
      dataIndex: false,
      key: false,

    },
    {
      title: <label><span title='No.of Compliance'>Compliance</span></label>,
      ...getColumnSearchProps('r_count', 'No.of Compliance'),
      render: (record) => {
        if (record.is_new) {
          return <span style={{ color: '#0000FF' }}>{record.r_count}</span>
        } else {
          return record.r_count
        }
      },
      sortable: true,
      width: '40px',
      ellipsis: false,
      dataIndex: false,
      key: false,
      align: 'center'

    },
    {
      title: "Updated By",
      ...getColumnSearchProps('usr_by', 'Updated By'),
      render: (record) => {
        if (record.is_new) {
          return <span style={{ color: '#0000FF' }}>{record.usr_by}</span>
        } else {
          return record.usr_by
        }
      },
      sortable: true,
      width: '100px',
      // ellipsis: true,
      dataIndex: false,
      key: false,
      align: 'center'


    },
    {
      title: "Updated On",
      ...getColumnSearchProps('usr_on', 'Updated On'),
      render: (record) => {
        if (record.is_new) {
          return <span style={{ color: '#0000FF' }}>{record.usr_on}</span>
        } else {
          return record.usr_on
        }
      },
      sortable: true,
      width: '100px',
      dataIndex: false,
      key: false,
      align: 'center'

    },

    {
      title: "Lock",
      sortable: true,
      width: '50px',
      render: (record) => {
        return (
          <center>
            {
              record.is_locked === true ?
                <div onClick={(e) => {
                  unlock(record)
                }} >
                  <div >
                    <span className="btn btn-m btn-light-danger text-danger btn-circle d-flex
                              align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>
                      <Tooltip placement='left' title='Click here to Unlock'><FeatherIcon icon="lock" /></Tooltip>
                    </span>
                  </div></div> : <div>
                  <span className="btn btn-m btn-light-success
                text-success btn-circle d-flex align-items-center
                justify-content-center" style={{
                      width: "30px",
                      height: "30px"
                    }}>
                    <FeatherIcon icon="unlock" /></span>
                </div>
            }
          </center>
        )
      },

    }
  ];

  const remarkscolumn = [

    {
      title: 'S.No',
      dataIndex: false,
      width: '30px',
      align: 'center',
      // ellipsis: true,
      render: (text, record, index) => {
        return (page - 1) * 10 + index + 1
      }
    },
    {
      title: "Description",
      render: (text, row) => <a href={() => false} onClick={(e) => {
        setRowRemarks(true)
        let rowId = childValue
        updateChildRemarks[rowId] = row.r_description;
        setUpdateChildRemarks([...updateChildRemarks])
        setRemarksModal(false)
        setPannelRemarksModal(false)
      }}>{row.r_description}</a>,
      sortable: true,
      width: '250px',
      ellipsis: true,
      reorder: true,
    }
  ]

  const Pannelremarkscolumn = [
    {
      title: 'S.No',
      dataIndex: false,
      width: '30px',
      align: 'center',
      // ellipsis: true,
      render: (text, record, index) => {
        return (page - 1) * 10 + index + 1
      }
    },
    {
      title: 'Description',
      dataIndex: 'r_description',
      key: 'r_description',
      width: '200px',
      ellipsis: true,
      render: (text, row) => <a href={() => false} onClick={(e) => {
        setRowRemarks(true)
        let rowId = ExtraCollapseId
        if (rowId in UpdatePannelRemarks) {
          // let index = updateRemarks.indexOf(rowId)
          UpdatePannelRemarks[rowId] = [];
          if (UpdatePannelRemarks.indexOf(rowId) == -1) {
            UpdatePannelRemarks[rowId] = row.r_description;
          }

        } else {
          UpdatePannelRemarks[rowId] = [];
          UpdatePannelRemarks[rowId] = row.r_description
        }
        setUpdatePannelRemarks([...UpdatePannelRemarks])
        // setRemarksModal(false)
        setPannelRemarksModal(false)
        for (let i in childData) {
          if (childData[i].childId in RowRemarks) {
            let index = RowRemarks.indexOf(childData[i].childId)
            RowRemarks[childData[i].childId] = [];
            if (index == -1) {
              RowRemarks[childData[i].childId] = row.r_description
            }
            else {
              RowRemarks[childData[i].childId] = [];
              RowRemarks[childData[i].childId] = row.r_description
            }
          }
        }
        setRowRemark([...RowRemarks])


        if (collapseremarksModal === true) {
          //  setCollapseremarkmessage(temp)
          let temp = []
          // setCollapseMessages(temp)
          setCollapseremarksModal(false)
        }
      }}>{row.r_description}</a>,
    },
  ]

  useEffect(() => {
    if (recallremarks) {
      let temp = [];
      for (let i = 0; i < recallremarks.length; i++) {
        if (recallremarks[i].r_description != '') {
          temp.push(recallremarks[i])
        }
        Setremarksvalue(temp)
      }
    }

  }, [recallremarks])

  useEffect(() => {
    if (editdata.length > 0) {
      if (showmoredataadded == true) {
        let temp = tempshowmore
        for (let i in editdata) {
          temp.push(editdata[i])
        }
        setCheckdata([...temp])
      } else {
        let temp = checkdata
        for (let i in editdata) {
          temp.push(editdata[i])
        }
        setCheckdata([...temp])
      }
    }
  }, [editdata])

  useEffect(() => {
    saveformValidator.current.showMessages()
    overallsaveformValidator.current.showMessages()
    formValidator.current.showMessages()
    validator.current.showMessages()
  }, [])

  useEffect(() => {
    if (isAuth) {

      const payload = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetStatutorySettings",
            {
              "le_id": entityid,
              "div_id": parseInt(filtervalue.division),
              "cat_id": parseInt(filtervalue.category),
            }
          ]
        }
      ]

      const filterAPI = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetStatutorySettingsFilters",
            {}
          ]
        }
      ]
      if (entityid != 'null') {
        statutorylist({
          payload: payload,
          paramid: paramid
        })
      }

      filterdata({
        payload: filterAPI,
        paramid: paramid
      })
    }
  }, [isAuth])

  useEffect(() => {
    // setTempeditdata(editdata)
    let array = [];
    let finalarray = [];
    let datavalue = [];
    if (checkdata) {
      const ab = checkdata.map(function (item) {
        if ((array.indexOf(item.lone_statu_name) < 0))
          array.push(item.lone_statu_name)
      })
      array.map((data, i) => {
        // let array2 = [];
        let temp = [];
        let entityArray = [];
        if (data) {
          checkdata.map((child, childIndex) => {
            if (data === child.lone_statu_name) {
              entityArray = {
                'app_status': child.app_status,
                'comp_id': child.comp_id,
                'comp_name': child.comp_name,
                'compfielaw_url': child.compfielaw_url,
                'criticality_client': child.criticality_client,
                'criticality_know': child.criticality_know,
                'descp': child.descp,
                'frequency_name': child.frequency_name,
                'h_id': child.h_id,
                'is_assigned': child.is_assigned,
                'lone_statu_name': child.lone_statu_name,
                'mapping_provision': child.mapping_provision,
                'not_app_remarks': child.not_app_remarks,
                'opt_status': child.opt_status,
                's_prov': child.s_prov,
                'unit_wise_status': child.unit_wise_status,
                'collapseId': i,
                'childId': childIndex,
                'parentId': i,
                'indexNo': count1
              }
              temp.push(entityArray)
              { <span hidden>{count1 = count1 + 1}</span> }
              {
                refcompid[childIndex] = child.app_status ? 1 : 0
                setRefcompid([...refcompid])
                updateopt[childIndex] = child.unit_wise_status[0].comp_opt_status
                setUpdateopt([...updateopt])
                updateChildRemarks[childIndex] = child.unit_wise_status[0].comp_remarks
                setUpdateChildRemarks([...updateChildRemarks])
                viewstatus[childIndex] = child.unit_wise_status[0].is_new
                setViewstatus([...viewstatus])
                PayloadCollapseStatus[childIndex] = child.opt_status
                setPayloadCollapseStatus([...PayloadCollapseStatus])
              }
            }
          })
        }
        datavalue = {
          index: i,
          parent: data,
          child: temp
        }

        displayNoneParent[i] = undefined
        // displayNoneParent
        finalarray.push(datavalue)
      })
      setDisplayNoneParent([...displayNoneParent])
      Settitledata(finalarray)
      getData()
    }

  }, [checkdata])

  useEffect(() => {
    titledata && titledata.length && titledata.map((item, i) => {
      let temp = [];
      UpdatePannelRemarks[i] = item.child[0].not_app_remarks
      item && item.child.length > 0 && item.child.map((itemdata, j) => {
        temp.push(itemdata.unit_wise_status[0].comp_opt_status)
      })
      if (temp.includes(1) === true) {
        CollapseStatus[i] = 1
      } else {
        let count = 0
        for (let k in temp) {
          if (temp[k] === 2) {
            count = count + 1
            if (count === temp.length) {
              CollapseStatus[i] = 2
            }
          }
        }
        if (count !== temp.length) {
          CollapseStatus[i] = 0
        }
      }
      // CollapseStatus[i] = item.child[0].app_status == true ? 1 : 0
    })
    setUpdatePannelRemarks([...UpdatePannelRemarks])
    for (let i in UpdatePannelRemarks) {
      if (UpdatePannelRemarks[i] != null) {
        displayNoneParent[i] = undefined
        setDisplayNoneParent([...displayNoneParent])
      } else {
        displayNoneParent[i] = 'displaynone'
        setDisplayNoneParent([...displayNoneParent])
      }
    }
    // displayNoneParent
    setCollapseStatus([...CollapseStatus])
    setRefCollapseStatus([...CollapseStatus])

    // displayNoneParentif()

  }, [titledata])

  useEffect(() => {
    let temp = []
    let tempArr = []
    if (statutorypayload.length > 0) {
      for (let i in statutorypayload) {
        temp.push({
          "c_c_id": statutorypayload && statutorypayload[i].c_c_id,
          "a_status": PayloadCollapseStatus[i],
          "n_a_remarks": PayloadUpdatePannelRemarks[i],
          "comp_id": statutorypayload && statutorypayload[i].comp_id,
          "c_o_status": updateopt[i],
          "c_remarks": updateChildRemarks[i],
          "u_name": statutorypayload && statutorypayload[i].u_name,
          "u_id": statutorypayload && statutorypayload[i].u_id,
          "c_a_status": statutorypayload && statutorypayload[i].c_a_status,
          "h_id": statutorypayload && statutorypayload[i].h_id
        })
        setStatutorypayload(temp)
      }
    }
    if (tempstatutorypayload.length > 0) {
      for (let i in tempstatutorypayload) {
        tempArr.push({
          "c_c_id": tempstatutorypayload && tempstatutorypayload[i].c_c_id,
          "a_status": PayloadCollapseStatus[i],
          "n_a_remarks": PayloadUpdatePannelRemarks[i],
          "comp_id": tempstatutorypayload && tempstatutorypayload[i].comp_id,
          "c_o_status": updateopt[i],
          "c_remarks": updateChildRemarks[i],
          "u_name": tempstatutorypayload && tempstatutorypayload[i].u_name,
          "u_id": tempstatutorypayload && tempstatutorypayload[i].u_id,
          "c_a_status": tempstatutorypayload && tempstatutorypayload[i].c_a_status,
          "h_id": tempstatutorypayload && tempstatutorypayload[i].h_id,
          "collapseId": tempstatutorypayload && tempstatutorypayload[i].collapseId,
        })
        setTempstatutorypayload(tempArr)
      }
    }
  }, [updateChildRemarks, updateopt, PayloadCollapseStatus, UpdatePannelRemarks, PayloadUpdatePannelRemarks])

  useEffect(() => {
    let tempArray = []
    // let tempArr = []
    titledata && titledata.length && titledata.map((itemdata, i) => {
      itemdata && itemdata.child.length > 0 && itemdata.child.map((item, j) => {
        tempArray.push({
          "c_c_id": item.unit_wise_status[0].c_comp_id,
          "a_status": item.unit_wise_status[0].act_status,
          "n_a_remarks": item.not_app_remarks,
          "comp_id": item.comp_id,
          "c_o_status": item.unit_wise_status[0].comp_opt_status,
          "c_remarks": item.unit_wise_status[0].comp_remarks,
          "u_name": selectedRows && selectedRows.length > 0 && selectedRows[0].u_name,
          "u_id": item.unit_wise_status[0].unit_id,
          "c_a_status": item.unit_wise_status[0].comp_app_status,
          "h_id": item.unit_wise_status[0].h_id,
          "collapseId": item.collapseId
        })
        // tempArr.push({
        //   "c_c_id": item.unit_wise_status[0].c_comp_id,
        //   "a_status": item.unit_wise_status[0].act_status,
        //   "n_a_remarks": item.not_app_remarks,
        //   "comp_id": item.comp_id,
        //   "c_o_status": item.unit_wise_status[0].comp_opt_status,
        //   "c_remarks": item.unit_wise_status[0].comp_remarks,
        //   "u_name": selectedRows && selectedRows.length > 0 && selectedRows[0].u_name,
        //   "u_id": item.unit_wise_status[0].unit_id,
        //   "c_a_status": item.unit_wise_status[0].comp_app_status,
        //   "h_id": item.unit_wise_status[0].h_id,
        //   "collapseId":item.collapseId
        // })
      })
    })
    setTempstatutorypayload([...tempArray])
    setStatutorypayload(tempArray)
  }, [titledata])

  const handleCancel = () => {
    setRemarksModal(false)
    SetisModalVisible(false);
    setPannelRemarksModal(false)
  }

  const passwordcancel = () => {
    setAddFormSubmit1(false)
    Setpasswordmodal(false);
    Setpassword({
      ...password,
      passwordvalue: ''
    })
  }

  const showmore = () => {
    editscreen(checkdata.length)

    let temp = []
    for (let i in statutorypayload) {
      let listArray = _.filter(checkdata, { comp_id: statutorypayload[i].comp_id });
      let entityArrayNew = {
        'app_status': listArray[0].app_status,
        'comp_id': listArray[0].comp_id,
        'comp_name': listArray[0].comp_name,
        'compfielaw_url': listArray[0].compfielaw_url,
        'criticality_client': listArray[0].criticality_client,
        'criticality_know': listArray[0].criticality_know,
        'descp': listArray[0].descp,
        'frequency_name': listArray[0].frequency_name,
        'h_id': listArray[0].h_id,
        'is_assigned': listArray[0].is_assigned,
        'lone_statu_name': listArray[0].lone_statu_name,
        'mapping_provision': listArray[0].mapping_provision,
        'not_app_remarks': listArray[0].not_app_remarks,
        'opt_status': listArray[0].opt_status,
        's_prov': listArray[0].s_prov,
        'unit_wise_status': [{
          'act_status': statutorypayload[i].a_status,
          'comp_app_status': statutorypayload[i].c_a_status,
          'c_comp_id': statutorypayload[i].c_c_id,
          'comp_opt_status': statutorypayload[i].c_o_status,
          'comp_remarks': statutorypayload[i].c_remarks,
          'compfielaw_url': listArray[0].compfielaw_url,
          'h_id': listArray[0].unit_wise_status[0].h_id,
          'is_new': listArray[0].unit_wise_status[0].is_new,
          'is_saved': listArray[0].unit_wise_status[0].is_saved,
          'is_submitted': listArray[0].unit_wise_status[0].is_submitted,
          'unit_id': listArray[0].unit_wise_status[0].unit_id
        }],
      }
      temp.push(entityArrayNew)
    }
    setTempshowmore(temp)
    setShowmoredataadded(true)
  }

  const unlock = (e_data) => {
    Setpasswordmodal(true)
    Setsubmitpasswordmodal(true)
    Setpassword({
      ...password,
      le_id: entityid,
      d_id: e_data.d_id,
      u_id: e_data.u_id,
      lock: e_data.is_new
    })
  }

  const handleOk = () => {
    setRemarksModal(false)
    setPannelRemarksModal(false)
  };

  const getData = () => {
    let array = [];
    let countrylisttempdataNew = [];
    let arrNew = [];
    const ab = checkdata.map(function (item) {
      if ((array.indexOf(item.lone_statu_name) < 0))
        arrNew.push(item.lone_statu_name)
    })

    arrNew.map((data, i) => {
      let entityArrayNew = [];
      let tempArraryNew = [];
      if (data) {
        checkdata.map((child, childIndex) => {
          if (data === child.lone_statu_name) {
            entityArrayNew = {
              'app_status': child.app_status,
              'comp_id': child.comp_id,
              'comp_name': child.comp_name,
              'compfielaw_url': child.compfielaw_url,
              'criticality_client': child.criticality_client,
              'criticality_know': child.criticality_know,
              'descp': child.descp,
              'frequency_name': child.frequency_name,
              'h_id': child.h_id,
              'is_assigned': child.is_assigned,
              'lone_statu_name': child.lone_statu_name,
              'mapping_provision': child.mapping_provision,
              'not_app_remarks': child.not_app_remarks,
              'opt_status': child.opt_status,
              's_prov': child.s_prov,
              'unit_wise_status': child.unit_wise_status,
              'collapseId': i,
              'childId': childIndex,
              'parentId': i
            }
            tempArraryNew.push(entityArrayNew)
            updateRemarks[childIndex] = child.unit_wise_status[0].comp_remarks
            updateStatus[childIndex] = child.unit_wise_status[0].comp_opt_status
            RowRemarks[childIndex] = child.not_app_remarks
            RowStatus[childIndex] = child.opt_status
          }
        })


      }
      countrylisttempdataNew.push(tempArraryNew)
    })
    var merged = [].concat.apply([], countrylisttempdataNew);
    setData(merged)
    setUpdateStatus([...updateStatus])
    setUpdateRemarks([...updateRemarks])
    setRowRemark([...RowRemarks])
    setRowStatus([...RowStatus])
  }

  const updatestatutorySettings = () => {
    setRemarksFormSubmit(true)
    let temp = []
    let temp2 = []
    let newval1 = viewstatus.every((val, i, arr) => val === false)
    if (newval1) {
      Toaster.error('All compliance should select while submit')
    } else {

      if (UpdatePannelRemarks.includes('') || updateChildRemarks.includes('')) {
        Toaster.error('Remarks Requireds')
      } else {
        Setsubmitpasswordmodal(false)
        Setpasswordmodal(true)
        // if (temp2[0] == 1) {
        //   Toaster.error('Remarks required for opt in act')
        // }
        // else if (temp2[0] == 2) {
        //   Toaster.error('Remarks required for Not applicable act')
        // }
        // else if (temp2[0] == 0) {
        //   Toaster.error('Remarks required for Not opted act')
        // }
      }
    }
  }

  const savestatutorysettings = () => {
    setRemarksFormSubmit(true)
    let newval = viewstatus.every((val, i, arr) => val === true)
    let temp = []
    let temp2 = []
    if (newval) {
      Toaster.error('Atleast one compliance should be select')
    } else {
      for (let i in statutorypayload) {
        // if (statutorypayload[i].a_status != statutorypayload[i].c_o_status) {
        if (statutorypayload[i].c_remarks != null) {
          // temp.push(true)
        } else {
          // temp.push(false)
          temp2.push(statutorypayload[i].c_o_status)
        }
      }
      // }
      console.log(temp, 'temptemp');

      setRemarksFormSubmit(true)
      if (updateChildRemarks.includes('') || UpdatePannelRemarks.includes('')) {
        Toaster.error('Remarks Required')
      } else {
        // Toaster.error('saved successfully Required')
        SaveStatutorySettings({
          payload:
            [
              authtoken,
              {
                "session_token": authtoken,
                "request": [
                  "SaveStatutorySettings",
                  {
                    "update_statutories": statutorypayload,
                    "le_id": entityid,
                    "s_s": 1,
                    "d_id": selectedRows[0].d_id,
                    "u_ids": uiddomian
                  },

                ]
              }
            ],
          paramid: paramid
        })
        // if (temp2[0] == 1) {
        //   Toaster.error('Remarks required for opt in act')
        // }
        // else if (temp2[0] == 2) {
        //   Toaster.error('Remarks required for Not applicable act')
        // }
        // else if (temp2[0] == 0) {
        //   Toaster.error('Remarks required for Not opted act')
        // }
      }
    }
  }

  const editscreen = (len) => {
    let uid = []
    seteditshow(true);
    setlistshow(false);
    for (let i = 0; i < selectedRows.length; i++) {
      uid.push(selectedRows[i].u_id)
    }
    setUiddomain(uid)
    const editvalue = [
      authtoken,
      {
        "session_token": authtoken,
        "request": [
          "GetSettingsCompliances",
          {
            "le_id": entityid,
            "u_ids": uid,
            "r_count": len,
            "d_id": selectedRows[0].d_id
          }
        ]
      }
    ]
    editstatutory({
      payload: editvalue,
      paramid: paramid
    })
  }

  const previous = () => {
    seteditshow(false);
    setlistshow(true);
    dispatch({
      type: STATUTORYSEDIT_RESET,
    })
    setCheckedRow(false)
    setSelectedRows([])
    var ele = document.getElementsByName('allchecked');
    ele[0].checked = false;
    var ele1 = document.getElementsByName('checked');
    for (var j = 0; j < ele1.length; j++) {
      if (ele1[j].type === 'checkbox')
        ele1[j].checked = false;
      let a = document.getElementsByClassName(ele1[j].className)
      // a[0].removeAttribute("hidden")
      for (let j = 0; j < a.length; j++) {
        a[j].removeAttribute("disabled")
      }
    }
    // showmoredataadded
    setShowmoredataadded(false)
    setCheckdata([])
  }

  useEffect(() => {
    if (list && list.length > 0) {
      let unique = _.uniqBy(list, function (e) {
        return e.d_id;
      });
      let check = unique?.length ? unique.map((item) => {
        return item.d_id
      }) : null

      setd_idlength(check.length)
    }
  }, [list])

  const showRemarksModal = (comp_id, collapseId, childId) => {
    setChildValue(childId)
    GetRemarksData({
      payload:
        [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "GetRemarksData",
              {}
            ]
          }
        ],
      paramid: paramid
    })
    setRemarksModal(true);
  };

  const showRemarksModall = (collapseId, parent, child) => {
    setExtraCollapseId(collapseId)
    setChildData(child)
    // setTempcollapseid(collapseId)
    let temp = []
    temp.push(collapseId)
    setCollapseMessages([...collapsemessages, ...temp])
    GetRemarksData({
      payload:
        [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "GetRemarksData",
              {}
            ]
          }
        ],
      paramid: paramid
    })
    // setRemarksModal(true);
    setPannelRemarksModal(true)
    setCollapseremarksModal(true);
  };

  const filterapply = (e) => {
    setListData([])
    setTotalCompliance(0)
    setLockCount(0)
    setUnLockCount(0)
    setAddFormSubmit(true)
    setshowbutton(true)
    let payloaddata;

    dispatch({
      type: STATUTORYSEDIT_RESET,
    })
    setCheckdata([])
    if (validator.current.allValid()) {

      setFilterModalVisible(false);
      setExitCollapse(true);
      setFilterTaskAcc(false);
      setKey(0)
      if (filtervalue.entity === '') {
        payloaddata = [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "GetStatutorySettings",
              {
                "le_id": entityid,
                "div_id": parseInt(filtervalue.division),
                "cat_id": parseInt(filtervalue.category),
              }
            ]
          }
        ]
      } else {

        payloaddata = [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "GetStatutorySettings",
              {
                "le_id": filtervalue.entity,
                "div_id": parseInt(filtervalue.division),
                "cat_id": parseInt(filtervalue.category),
              }
            ]
          }
        ]
      }
      statutorylist({
        payload: payloaddata,
        paramid: paramid
      })
      SetisModalVisible(false);
    }
  }

  const submitpassword = (e) => {
    setAddFormSubmit1(true)
    e.preventDefault();
    if (formValidator.current.allValid() && submitpasswordmodal === true) {
      // Toaster.error('Toast1 submit Required')
      const payload = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "ChangeStatutorySettingsLock",
            {
              "le_id": password.le_id,
              "d_id": password.d_id,
              "u_id": password.u_id,
              "lock": password.lock,
              "password": password.passwordvalue
            }
          ]
        }
      ]
      let payloaddata = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetStatutorySettings",
            {
              "le_id": entityid,
              "div_id": parseInt(filtervalue.division),
              "cat_id": parseInt(filtervalue.category),
            }
          ]
        }
      ]
      locksettings({
        payload: payload,
        paramid: paramid,
        history: history,
        payloaddata: payloaddata
      })
      Setpasswordmodal(false);
    } else {
      let temp = []
      for (let i in statutorypayload) {
        if (statutorypayload[i].a_status != statutorypayload[i].c_o_status) {
          if (statutorypayload[i].c_remarks != null) {
            temp.push(true)
          } else {
            temp.push(false)
          }
        }
      }
      if ( updateChildRemarks.includes('') && UpdatePannelRemarks.includes('')) {
        // Toaster.error('Toast2 submit Required')
        Toaster.error('Password Required')
      } else {
         UpdateStatutorySettings({
          payload:
            [
              authtoken,
              {
                "session_token": authtoken,
                "request": [
                  "UpdateStatutorySettings",
                  {
                    "password": password.passwordvalue,
                    "update_statutories": statutorypayload,
                    "le_id": entityid,
                    "s_s": 2,
                    "d_id": selectedRows[0].d_id,
                    "u_ids": uiddomian
                  },

                ]
              }
            ],
          paramid: paramid
        })
        SetPassworddisable(true)
        Setpasswordmodal(false)
      }
    }
  }

  const addAllSelectedDataValues = (checked) => {
    var ele = document.getElementsByName('checked');
    let listArray = _.filter(list, { is_locked: false });
    let temp = listArray.filter((item) => {
      return item.is_locked == false
    })
    if (checked === true) {
      setCheckedRow(true)
      if (listArray && listArray.length > 0) {
        let temp1 = []
        let a1
        let a
        if (d_idlength > 1) {
          for (let i in temp) {
            if (temp[0].d_id == temp[i].d_id) {
              temp1.push(temp[i])
              a1 = document.getElementsByClassName(ele[i].className)
              a1[i].removeAttribute("disabled")
            }
          }
        } else {
          for (let i in temp) {
            temp1.push(temp[i])
          }
        }
        if (d_idlength > 1) {
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].className == temp[0].d_id) {
              ele[i].checked = true;
              ele[i].removeAttribute("disabled")
            } else {
              a = document.getElementsByClassName(ele[i].className)
              ele[i].checked = false;
            }
          }
          for (let j = 0; j < a.length; j++) {
            a[j].setAttribute('disabled', true)
          }
        } else {
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].type === 'checkbox')
              ele[i].checked = true;
          }

        }
        setSelectedRows(temp1)
      }
    } else {
      setCheckedRow(false)
      setSelectedRows([])
      let a
      if (d_idlength > 1) {
        for (var j = 0; j < ele.length; j++) {
          a = document.getElementsByClassName(ele[j].className)
          ele[j].checked = false;
          for (let j = 0; j < a.length; j++) {
            a[j].removeAttribute("disabled")
          }
        }
      } else {
        for (var j = 0; j < ele.length; j++) {
          if (ele[j].type === 'checkbox')
            ele[j].checked = false;
        }
      }

    }
  };

  const addSelectedDataValues = (checked, u_id, d_id) => {
    var ele = document.getElementsByName('allchecked');
    var ele1 = document.getElementsByName('checked');
    let array
    let listArray
    let total_domain_id
    let tempArray = selectedRows
    if (checkedRow === true) {
      if (checked === true) {
        array = _.find(list, { u_id: u_id });
        tempArray.push(array)
      } else {
        listArray = _.filter(list, { is_locked: false });
        // for (let i in listArray) {
        //   if (listArray[i].d_id != tempArray[0].d_id) {
        //     let a = document.getElementsByClassName(listArray[i].d_id)
        //   } 
        //   // else {
        //   // }
        // }
        for (let i in tempArray) {
          if (tempArray[i].u_id === u_id) {
            tempArray.splice(i, 1)
          }
          if (list.length !== selectedRows.length) {
            setCheckedRow(false)
            for (var j = 0; j < ele.length; j++) {
              if (ele[j].type === 'checkbox')
                ele[j].checked = false;
            }
          }
        }
      }
      setSelectedRows([...tempArray])
    }
    else {
      let a
      listArray = _.filter(list, { is_locked: false });
      total_domain_id = _.filter(listArray, { d_id: d_id });
      if (checked === true) {
        array = _.find(total_domain_id, { u_id: u_id });
        tempArray.push(array)
        if (total_domain_id.length === selectedRows.length) {
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].type === 'checkbox') {
              ele[i].checked = true;
            } else {
              ele[0].checked = false;
            }
          }
        }
        if (d_idlength > 1) {
          for (let i in listArray) {
            if (listArray[i].d_id != tempArray[0].d_id) {
              a = document.getElementsByClassName(listArray[i].d_id)
            }
          }
          for (let j = 0; j < a.length; j++) {
            a[j].setAttribute('disabled', true)
          }
          // }
        } else {
          if (listArray.length === selectedRows.length) {
            for (var i = 0; i < ele.length; i++) {
              if (ele[i].type === 'checkbox')
                ele[i].checked = true;
            }
          }
        }
      } else {
        if (d_idlength > 1) {
          for (let i in listArray) {
            if (listArray[i].d_id != tempArray[0].d_id) {
              a = document.getElementsByClassName(listArray[i].d_id)
            }
            // else {
            // }
          }
        }
        for (let i in tempArray) {
          if (tempArray[i].u_id === u_id) {
            tempArray.splice(i, 1)
          }
        }
        if (d_idlength > 1) {
          if (tempArray.length == 0) {
            for (let j = 0; j < a.length; j++) {
              a[j].removeAttribute("disabled")
            }
            for (var j = 0; j < ele1.length; j++) {
              ele1[j].checked = false;
            }
          }
          for (var i = 0; i < ele.length; i++) {
            if (ele[i].type === 'checkbox') {
              ele[i].checked = false;
            }
          }
        } else {
          ele[0].checked = false;
        }
      }
      setSelectedRows([...tempArray])
    }
  }

  const homescreen = () => {
    window.location.href = homepage
    document.getElementById('homehover').style.color = "blue"
  }

  const handlePasting = (e, regex) => {

    var regex = regex;
    var key = e.clipboardData.getData('text')
    if (!regex.test(key)) {
      e.preventDefault();
      Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
      return false;
    }
    // e.clipboardData.getData('text')
    // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
    // e.preventDefault();
    // return false;
  }

  return (
    <div id='page-wrapper' className="page-wrapper">
      {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
      {document.body.scrollHeight > document.body.clientHeight ?
        <div className='back-to-top'>
          <ScrollButton />
        </div> : ''} */}
      <div className='back-to-top'>
        <ScrollButton />
      </div>
      <div style={{ display: (!listshow ? 'none' : '') }}>
        <div className="page-titles pb-1 pt-1" style={{ maxWidth: "unset" }}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 align-self-center" style={{ padding: '0' }}>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 d-flex align-items-center">
                  <li className="breadcrumb-item">
                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                  </li>
                  <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                    <span style={{ fontSize: '16px' }}>Transaction</span>
                  </li>
                  <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                    <span>Statutory Settings</span>
                  </li>
                </ol>
              </nav>

            </div>

            <div style={{ paddingRight: '2px' }} className=' col-lg-6 col-md-6 col-12 d-flex justify-content-end align-items-center' >
              {showbutton == true ?
                <>
                  <div className='p-2 rounded' style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='me-2 border-end pe-2'>
                      <i style={{ color: "black", }} className="bi bi-square-fill"></i>&nbsp;
                      <label style={{ color: '#808080' }}> Statutes assigned</label>
                    </div>
                    <div><i style={{ color: "blue" }} className="bi bi-square-fill"></i>&nbsp;
                      <label style={{ color: '#808080' }}>Units to be assigned</label>
                    </div>
                  </div>
                </>
                : ''}
              <Tooltip title='Advanced Filter' placement='top'>
                <Button
                  className="mx-1"
                  type="primary"
                  shape="round"
                  style={{
                    display: filterTaskAcc ? "none" : "initial",
                  }}
                  onClick={filterHandle}
                >
                  <FilterOutlined /> Filter
                </Button>
              </Tooltip>
              {selectedRows.length > 0 ? <>
                <Tooltip placement='top' title='Edit'>< Button type="primary" shape="round" className='addbutton mx-3'
                  size='default'
                  icon={<EditOutlined />}
                  onClick={(e) => {
                    editscreen(editdata.length)
                  }}>
                  Edit
                </Button></Tooltip>
              </>
                : ''
              }
              <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'}>
            <Collapse className='report-collapse' defaultActiveKey={["1"]}
              style={{ display: exitCollapse ? "none" : "block" }}>
              <Panel header="Statutory Settings" key={key}>
                <div className='col-md-12'>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor=""><b>Business Group :</b></label>
                      </div>
                      {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                        <div className="form-group">
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Select Business Group"
                            onDrop={(e) => {
                              e.preventDefault();
                              Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9]+$/gi)
                            }}
                            onChange={(data, value) => {
                              if (data !== undefined) {
                                Setfiltervalue({
                                  ...filtervalue,
                                  bg_id: data,
                                  bg_name: value.children
                                })
                                let datas = _.filter(sessionArr, { bg_id: Number(data) })
                                setEntityData(datas)
                              }
                              else {
                                Setfiltervalue({
                                  ...filtervalue,
                                  bg_id: "",
                                  bg_name: "",
                                  entity: '',
                                  division: '',
                                  category: '',
                                  legal_entity_id: ''
                                })
                                setEntityData(sessionArr)
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            value={filtervalue.bg_id ? filtervalue.bg_id : undefined}
                            style={{ width: '100%', marginTop: '5px' }}

                          >
                            {bggroup && bggroup.length > 0 && bggroup.map((item, i) => {

                              if (item.bg_name !== null) {
                                return (
                                  <Option key={item.bg_id}>
                                    {item.bg_name}
                                  </Option>
                                );
                              }
                            })}
                          </Select>

                        </div> :
                        <p> - </p>
                      }
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}> *</span>
                      </div>
                      {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                        <>
                          <Select
                            allowClear={true}
                            size="default"
                            placeholder="Select Legal Entity"
                            onDrop={(e) => {
                              e.preventDefault();
                              Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                            }}
                            onChange={(data, value) => {
                              if (data !== undefined) {
                                setCurrentEntity(data)
                                Setfiltervalue({
                                  ...filtervalue,
                                  legal_entity_id: data,
                                  legal_entity_name: value.children
                                })
                                let DivisionData = _.filter(filterdatas.div_infos, { le_id: data })
                                setDivisionData(DivisionData)
                                let CategoryData = _.filter(filterdatas.cat_info, { le_id: data })
                                setCategoryData(CategoryData)
                              }
                              else {
                                setCurrentEntity(data)
                                Setfiltervalue({
                                  ...filtervalue,
                                  legal_entity_id: "",
                                  legal_entity_name: ""
                                })
                                setDivisionData([])
                                setCategoryData([])
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            //  value={userDetails.legal_entity_id}
                            value={filtervalue.legal_entity_id ? filtervalue.legal_entity_id : undefined}
                            style={{ "width": "100%", marginTop: '5px' }}
                          >
                            {EntityData && EntityData.length > 0 && EntityData.map((item, i) => {
                              return (
                                <Option key={item.le_id}>
                                  {item.le_name}
                                </Option>
                              );
                            })}
                          </Select>
                          {validator.current.message(
                            'legal_entity_id',
                            filtervalue.legal_entity_id,
                            'required',
                            {
                              className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                              messages: {
                                required: 'Legal Entity Required',
                              }
                            })}

                        </> : <p title={localStorage.getItem('SelectedEntity')} className='cut-text-word' style={{ "color": "#6666ff", "fontWeight": "bold", width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>}

                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label ><b>Division :</b></label>
                      </div>

                      <Select
                        allowClear={true}
                        size="default"
                        placeholder="Select Division"
                        className=""
                        disabled={filtervalue.legal_entity_id == '' ? true : false}
                        style={{ "width": "100%", marginTop: '5px' }}
                        showSearch
                        optionFilterProp="children"
                        value={filtervalue.division ? filtervalue.division : undefined}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                        onDrop={(e) => {
                          e.preventDefault();
                          Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                          return false;
                        }}
                        onPaste={(e) => {
                          handlePasting(e, /^[a-z 0-9]+$/gi)
                        }} onChange={(e) => {
                          if (e !== undefined) {
                            let CategoryData = _.filter(filterdatas.cat_info, { div_id: e })
                            setCategoryData(CategoryData)
                            Setfiltervalue({
                              ...filtervalue,
                              division: e
                            })
                          }
                          else {
                            setCategoryData([])
                            Setfiltervalue({
                              ...filtervalue,
                              division: ""
                            })
                          }
                        }}>

                        {divisionData && divisionData.length > 0 && divisionData.map((item) => {
                          return <Option value={item.div_id}>{item.div_name}</Option>
                        })}
                      </Select>

                    </div>
                  </div>
                  <div className='col-md-12' style={{ marginTop: "5px" }}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label ><b>Category :</b></label>
                        </div>
                        <Select
                          className=""
                          style={{ "width": "100%", marginTop: '3px' }}
                          allowClear={true}
                          disabled={filtervalue.legal_entity_id == '' ? true : false}
                          value={filtervalue.category ? filtervalue.category : undefined}
                          size="default"
                          placeholder="Select Category"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          onDrop={(e) => {
                            e.preventDefault();
                            Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9]+$/gi)
                          }}
                          onChange={(e) => {
                            if (e !== undefined) {
                              Setfiltervalue({
                                ...filtervalue,
                                category: e
                              })
                            }
                            else {
                              Setfiltervalue({
                                ...filtervalue,
                                category: ""
                              })
                            }
                          }}>

                          {categoryData && categoryData.length > 0 && categoryData.map((item) => {
                            return <Option value={item.cat_id}>{item.cat_name}</Option>
                          })}
                        </Select>

                      </div>
                    </div>

                    <div style={{ textAlign: 'center' }} >
                      <div className="form-group">
                        <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", align: 'center' }}
                          size='default' icon={<EyeOutlined />} onClick={filterapply}> Show
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
            </Collapse>
            <Modal
              onCancel={setcancelFilter}
              footer={false}
              title="Statutory Settings"
              className={
                "add-service-prv cons-report w-50 " +
                localStorage.getItem("currentTheme")
              }
              visible={filterModalVisible}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor=""><b>Business Group :</b></label>
                  </div>
                  {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                    <div className="form-group">
                      <Select
                        allowClear={true}
                        size="default"
                        getPopupContainer={trigger => trigger.parentNode}
                        placeholder="Select Business Group"
                        onDrop={(e) => {
                          e.preventDefault();
                          Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                          return false;
                        }}
                        onPaste={(e) => {
                          handlePasting(e, /^[a-z 0-9]+$/gi)
                        }}
                        onChange={(data, value) => {
                          if (data !== undefined) {
                            Setfiltervalue({
                              ...filtervalue,
                              bg_id: data,
                              bg_name: value.children
                            })
                            let datas = _.filter(sessionArr, { bg_id: Number(data) })
                            setEntityData(datas)
                          }
                          else {
                            Setfiltervalue({
                              ...filtervalue,
                              bg_id: "",
                              bg_name: "",
                              entity: '',
                              division: '',
                              category: '',
                              legal_entity_id: ''
                            })
                            setEntityData(sessionArr)
                          }
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        value={filtervalue.bg_id ? filtervalue.bg_id : undefined}
                        style={{ width: '100%', marginTop: '5px' }}

                      >
                        {bggroup && bggroup.length > 0 && bggroup.map((item, i) => {

                          if (item.bg_name !== null) {
                            return (
                              <Option key={item.bg_id}>
                                {item.bg_name}
                              </Option>
                            );
                          }
                        })}
                      </Select>

                    </div> :
                    <p> - </p>
                  }
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}> *</span>
                  </div>
                  {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                    <>
                      <Select
                        allowClear={true}
                        size="default"
                        placeholder="Select Legal Entity"
                        getPopupContainer={trigger => trigger.parentNode}
                        onDrop={(e) => {
                          e.preventDefault();
                          Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                          return false;
                        }}
                        onPaste={(e) => {
                          handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                        }}
                        onChange={(data, value) => {
                          if (data !== undefined) {
                            setCurrentEntity(data)
                            Setfiltervalue({
                              ...filtervalue,
                              legal_entity_id: data,
                              legal_entity_name: value.children
                            })
                            let DivisionData = _.filter(filterdatas.div_infos, { le_id: data })
                            setDivisionData(DivisionData)
                            let CategoryData = _.filter(filterdatas.cat_info, { le_id: data })
                            setCategoryData(CategoryData)
                          }
                          else {
                            setCurrentEntity(data)
                            Setfiltervalue({
                              ...filtervalue,
                              legal_entity_id: "",
                              legal_entity_name: ""
                            })
                            setDivisionData([])
                            setCategoryData([])
                            setListData([])
                          }
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        //  value={userDetails.legal_entity_id}
                        value={filtervalue.legal_entity_id ? filtervalue.legal_entity_id : undefined}
                        style={{ "width": "100%", marginTop: '5px' }}
                      >
                        {EntityData && EntityData.length > 0 && EntityData.map((item, i) => {
                          return (
                            <Option key={item.le_id}>
                              {item.le_name}
                            </Option>
                          );
                        })}
                      </Select>
                      {validator.current.message(
                        'legal_entity_id',
                        filtervalue.legal_entity_id,
                        'required',
                        {
                          className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                          messages: {
                            required: 'Legal Entity Required',
                          }
                        })}

                    </> : <p title={localStorage.getItem('SelectedEntity')} className='cut-text-word' style={{ "color": "#6666ff", "fontWeight": "bold", width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>}

                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label ><b>Division :</b></label>
                  </div>

                  <Select
                    allowClear={true}
                    size="default"
                    placeholder="Select Division"
                    getPopupContainer={trigger => trigger.parentNode}
                    style={{ "width": "100%", marginTop: '5px' }}
                    disabled={filtervalue.legal_entity_id == '' ? true : false}
                    showSearch
                    optionFilterProp="children"
                    value={filtervalue.division ? filtervalue.division : undefined}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    onDrop={(e) => {
                      e.preventDefault();
                      Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                      return false;
                    }}
                    onPaste={(e) => {
                      handlePasting(e, /^[a-z 0-9]+$/gi)
                    }}
                    onChange={(e) => {
                      if (e !== undefined) {
                        let CategoryData = _.filter(filterdatas.cat_info, { div_id: e })
                        setCategoryData(CategoryData)
                        Setfiltervalue({
                          ...filtervalue,
                          division: e
                        })
                      }
                      else {
                        setCategoryData([])
                        Setfiltervalue({
                          ...filtervalue,
                          division: ""
                        })
                      }
                    }}>

                    {divisionData && divisionData.length > 0 && divisionData.map((item) => {
                      return <Option value={item.div_id}>{item.div_name}</Option>
                    })}
                  </Select>

                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label ><b>Category :</b></label>
                  </div>
                  <Select
                    className=""
                    style={{ "width": "100%", marginTop: '5px' }}
                    allowClear={true}
                    getPopupContainer={trigger => trigger.parentNode}
                    disabled={filtervalue.legal_entity_id == '' ? true : false}
                    value={filtervalue.category ? filtervalue.category : undefined}
                    size="default"
                    placeholder="Select Category"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    onDrop={(e) => {
                      e.preventDefault();
                      Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                      return false;
                    }}
                    onPaste={(e) => {
                      handlePasting(e, /^[a-z 0-9]+$/gi)
                    }}
                    onChange={(e) => {
                      if (e !== undefined) {
                        Setfiltervalue({
                          ...filtervalue,
                          category: e
                        })
                      }
                      else {
                        Setfiltervalue({
                          ...filtervalue,
                          category: ""
                        })
                      }
                    }}>

                    {categoryData && categoryData.length > 0 && categoryData.map((item) => {
                      return <Option value={item.cat_id}>{item.cat_name}</Option>
                    })}
                  </Select>

                </div>

                <div style={{ textAlign: 'center' }} >
                  <div className={"form-group mt-2 popupbtncolour " + localStorage.getItem('currentTheme')}>
                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", align: 'center' }}
                      size='default' icon={<EyeOutlined />} onClick={filterapply}> Show
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          {/* </div> */}
        </div>

        {showbutton == true ?
          <div>
            <div className="container-fluid pt-1" style={{ display: list?.length == 0 ? 'none' : 'block' }}>
              <div className='card-body1-statuatory rounded'>
                <div>
                  <div className={"edit-user-priv " + localStorage.getItem('currentTheme')}>
                    <Collapse defaultActiveKey={["1"]}>
                      <Panel header={<b>Overall Statistics</b>} key={key} extra={<><span style={{ paddingRight: '80px' }}><b>Selected Units : </b>{selectedRows.length}</span><span style={{ paddingRight: '80px' }}><b>Total Compliances : </b>{TotalCompliance}</span><span style={{ paddingRight: '80px' }}><b>Locked count : </b>{lockCount}</span><span style={{ paddingRight: '50px' }}><b>Unlocked count : </b>{unLockCount}</span></>} >
                        <Row gutter={16}>
                          <Col span={6}>
                            <div className="card mb-0 assign-card">
                              <div className="card-body p-3">
                                <div className="layer"></div>

                                <div className="d-flex flex-row align-items-center assign-card-content">
                                  <div className="icon-card text-success h1">
                                    <FeatherIcon icon="check-square" />
                                  </div>
                                  <div className="ps-3 ms-3 align-self-center border-start border-2">
                                    <h3 style={{ fontSize: '2rem', fontWeight: '800', lineHeight: '0.9' }} className="mb-0">{selectedRows.length}</h3>
                                    <span className="text-muted">Selected Units</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </Col>
                          <Col span={6}>
                            <div className="card mb-0 assign-card">
                              <div className="card-body p-3">
                                <div className="layer"></div>
                                <div className="d-flex flex-row align-items-center assign-card-content">
                                  <div className="icon-card text-danger h1 ">
                                    <FeatherIcon icon="clipboard" />
                                  </div>
                                  <div className="ps-3 ms-3 align-self-center border-start border-2">
                                    <h3 style={{ fontSize: '2rem', fontWeight: '800', lineHeight: '0.9' }} className="mb-0">{TotalCompliance}</h3>
                                    <span className="text-muted">Total Compliances</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className="card mb-0 assign-card">
                              <div className="card-body p-3">
                                <div className="layer"></div>
                                <div className="d-flex flex-row align-items-center assign-card-content">
                                  <div className="icon-card text-secondary h1 ">
                                    <FeatherIcon icon="lock" />
                                  </div>
                                  <div className="ps-3 ms-3 align-self-center border-start border-2">
                                    <h3 style={{ fontSize: '2rem', fontWeight: '800', lineHeight: '0.9' }} className="mb-0">{lockCount}</h3>
                                    <span className="text-muted">Locked count</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className="card mb-0 assign-card">
                              <div className="card-body p-3">
                                <div className="layer"></div>
                                <div className="d-flex flex-row align-items-center assign-card-content">
                                  <div className="icon-card text-warning h1 fw-bolder">
                                    <FeatherIcon icon="unlock" />
                                  </div>
                                  <div className="ps-3 ms-3 align-self-center border-start border-2">
                                    <h3 style={{ fontSize: '2rem', fontWeight: '800', lineHeight: '0.9' }} className="mb-0">{unLockCount}</h3>
                                    <span className="text-muted">Unlocked count</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid pt-1">
              <div className="row">
                <div className="col-12">
                  <div className='card servcardround mb-2 pt-1' >
                    <div className="table-responsive">
                      <div className="edit-user-priv">
                        <Table style={{ padding: "0px 8px 5px 8px" }}
                          className={localStorage.getItem("currentTheme") + ' edit-user-priv'}
                          size={'small'}
                          columns={columns}
                          locale={{ emptyText: <b>{'No Records Found'}</b> }}
                          dataSource={listData}
                          bordered
                          scroll={{ x: 1000 }}
                          pagination={false}
                          rowSelection={{
                            columnWidth: '30px',
                            columnTitle: <input type="checkbox"
                              // style={{ width: '10px' }}
                              hidden={overallcheck}
                              name="allchecked" id="all" className='checkclass'
                              onClick={(e) => {
                                let checked = e.target.checked;
                                addAllSelectedDataValues(
                                  checked
                                );
                              }}
                            />,
                            ...rowSelection
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
          // <Table />
          // <h1>No Records Found !</h1>
        }
        {/* </div> */}
        {/* {selectedRows.length > 0 ?
                      <div className="btn btn-primary btn-md" onClick={(e) => {
                        editscreen()
                      }}>Edit</div> : ''
                    } */}
        {/* <br /> */}
        {/* <div style={{ paddingLeft: "20px" }}>
                    <p>Selected Unit : &nbsp;{selectedRows.length} </p>
                    <p>Statutes assigned to those units are marked in Black Color</p>
                    <p>Select atleast one unit</p>
                    <p style={{ color: '#0000FF' }}>Units to be assigned are marked in Blue Color</p>
                  </div> */}
        {/* </div> */}



      </div>
      {editshow == true && selectedRows.length > 1 ? <MultiUnitStatutorySettings selectedRows={selectedRows} filtervalue1={filtervalue} entityid={entityid} setSelectedRows={setSelectedRows} seteditshow={seteditshow} setlistshow={setlistshow} /> :
        <div style={{ display: (!editshow ? 'none' : '') }}>
          <div>
            <div>
              <div className="page-titles pt-2 pb-0">
                <div className="row">
                  <div className="col-lg-8 col-md-6 col-12 align-self-center">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 d-flex align-items-center">
                        <li className="breadcrumb-item">
                          <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                        </li>
                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                          <span style={{ fontSize: '16px' }}>Transaction</span>
                        </li>
                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                          <span>Statutory Settings</span>
                        </li>
                      </ol>
                    </nav>
                  </div>
                  <div

                    className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end" style={{ paddingRight: 0 }}>
                    <Tooltip title='Back' placement='tpo'><Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                      icon={<ArrowLeftOutlined />} size='default' onClick={(e) => { previous(e) }}>
                      Back
                    </Button></Tooltip>

                    <button style={{ marginLeft: '3px' }} onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                      <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"} ><FullscreenOutlined /></Tooltip>
                    </button>
                    {/* <button style={{ float: "right" }} className="usrbutton button--anthe prev" onClick={(e) => { previous(e) }}><span class="text-white fs-3 fw-bold">Back</span></button> */}
                    {/* <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginTop: '7px' }}
                        size='default'
                        onClick={(e) => {
                          savestatutorysettings(e)
                        }}
                      >Save
                      </Button> */}
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 stat-set-card">
                    <div className="card-body">
                      <div className="col-md-12 mt-2">
                        <div className="row">
                          {/* {titledata && titledata.map((itemdata, i) => { defaultActiveKey={['0']} */}
                          {titledata && titledata.map((itemdata, i) => {
                            return (
                              <Collapse key={i} defaultActiveKey={['0']}
                                expandIcon={() =>
                                  CollapseStatus[itemdata.index] == 0 ? <FeatherIcon className={'pointer'} icon="x-circle" size="20" onClick={(event) => {
                                    event.stopPropagation();
                                    setpanelRemarkChange(true)
                                    childStatusChange(itemdata.index, 2, itemdata.child)
                                    CollapseStatus[itemdata.index] = 2
                                    for (let j in itemdata.child) {
                                      viewstatus[itemdata.child[j].childId] = false
                                      displayNoneChild[itemdata.child[j].childId] = 'displaynone'
                                      updateChildRemarks[itemdata.child[j].childId] = updateChildRemarks[itemdata.child[j].childId] ? updateChildRemarks[itemdata.child[j].childId] : null
                                    }
                                    for (let k in displayNoneChild) {
                                      if (displayNoneChild[k] == 'displaynone') {
                                        updateChildRemarks[k] = updateChildRemarks[k] ? updateChildRemarks[k] : null
                                      } else {
                                        updateChildRemarks[k] = updateChildRemarks[k] ? updateChildRemarks[k] : ''
                                      }
                                    }
                                    // for(let k in itemdata.child){
                                    //   if(updateopt[k] == refcompid[k]){
                                    //     updateChildRemarks[itemdata.child[k].childId] = null
                                    //   }else{
                                    //     updateChildRemarks[itemdata.child[k].childId] = ''
                                    //   }
                                    // }
                                    setViewstatus([...viewstatus])
                                    setDisplayNoneChild([...displayNoneChild])
                                    setCollapseStatus([...CollapseStatus])
                                    setUpdateChildRemarks([...updateChildRemarks])
                                    // if(0 == refCollapseStatus[i]){
                                    // displayNoneParent[i] = 'displaynone'
                                    // if(refCollapseStatus[i]== 1){
                                    //   displayNoneParent[i] 
                                    // }
                                    // }else{
                                    displayNoneParent[i] = undefined
                                    UpdatePannelRemarks[i] = UpdatePannelRemarks[i] ? UpdatePannelRemarks[i] : ''
                                    setUpdatePannelRemarks([...UpdatePannelRemarks])
                                    // }
                                    for (let k in itemdata.child) {
                                      PayloadCollapseStatus[itemdata.child[k].childId] = 2
                                      setPayloadCollapseStatus([...PayloadCollapseStatus])
                                    }
                                  }} /> :
                                    CollapseStatus[i] == 1 ? <FeatherIcon icon="check-circle" className={'pointer'} size={20} style={{ color: '#318CE7' }} onClick={(event) => {
                                      setpanelRemarkChange(true)
                                      event.stopPropagation();
                                      childStatusChange(itemdata.index, 0, itemdata.child)
                                      CollapseStatus[itemdata.index] = 0
                                      setCollapseStatus([...CollapseStatus])
                                      // viewstatus[itemdata.child] = false
                                      // setViewstatus([...viewstatus])
                                      for (let j in itemdata.child) {
                                        viewstatus[itemdata.child[j].childId] = false
                                        displayNoneChild[itemdata.child[j].childId] = 'displaynone'
                                      }
                                      setViewstatus([...viewstatus])
                                      setDisplayNoneChild([...displayNoneChild])
                                      if (0 == refCollapseStatus[i]) {
                                        displayNoneParent[i] = undefined
                                        UpdatePannelRemarks[i] = UpdatePannelRemarks[i] ? UpdatePannelRemarks[i] : ''
                                        setUpdatePannelRemarks([...UpdatePannelRemarks])
                                        for (let j in itemdata.child) {
                                          if (displayNoneChild[j] == 'displaynone') {
                                            updateChildRemarks[j] = updateChildRemarks[i] ? updateChildRemarks[i] : null
                                          }
                                        }
                                        // UpdatePannelRemarks[i] = null
                                        // setUpdatePannelRemarks([...UpdatePannelRemarks])
                                      } else {
                                        displayNoneParent[i] = undefined
                                        UpdatePannelRemarks[i] = UpdatePannelRemarks[i] ? UpdatePannelRemarks[i] : ''
                                        setUpdatePannelRemarks([...UpdatePannelRemarks])
                                        for (let i in displayNoneChild) {
                                          if (displayNoneChild[i] == 'displaynone') {
                                            updateChildRemarks[i] = updateChildRemarks[i] ? updateChildRemarks[i] : null
                                          }
                                        }
                                      } 

                                      for (let k in itemdata.child) {
                                        PayloadCollapseStatus[itemdata.child[k].childId] = 0
                                        setPayloadCollapseStatus([...PayloadCollapseStatus])
                                      }
                                    }} /> : <FeatherIcon icon="minus-circle" className={'pointer'} size={20} style={{ color: '	#FF0000' }} onClick={(event) => {
                                      setpanelRemarkChange(true)
                                      event.stopPropagation();
                                      childStatusChange(itemdata.index, 1, itemdata.child)
                                      CollapseStatus[itemdata.index] = 1
                                      setCollapseStatus([...CollapseStatus])
                                      for (let j in itemdata.child) {
                                        viewstatus[itemdata.child[j].childId] = false
                                        displayNoneChild[itemdata.child[j].childId] = 'displaynone'
                                      }
                                      // if (1 == refCollapseStatus[i]) {
                                      //   // displayNoneParent[i] = 'displaynone'
                                      //   // UpdatePannelRemarks[i] = null
                                      //   // setUpdatePannelRemarks([...UpdatePannelRemarks])
                                      // } else {
                                      //   displayNoneParent[i] = undefined
                                      //   UpdatePannelRemarks[i] = ''
                                      //   setUpdatePannelRemarks([...UpdatePannelRemarks])
                                      //   for (let i in displayNoneChild) {
                                      //     if (displayNoneChild[i] == 'displaynone') {
                                      //       updateChildRemarks[i] = null
                                      //     }
                                      //   }
                                      // } 
                                      setViewstatus([...viewstatus])
                                      setDisplayNoneChild([...displayNoneChild])
                                      if (1 == refCollapseStatus[i]) {
                                        displayNoneParent[i] = 'displaynone'
                                        UpdatePannelRemarks[i] = UpdatePannelRemarks[i] ? UpdatePannelRemarks[i] : null
                                        setUpdatePannelRemarks([...UpdatePannelRemarks])
                                        // if(refCollapseStatus[i]== 1){
                                        //   displayNoneParent[i] 
                                        // }
                                      } else {
                                        displayNoneParent[i] = undefined
                                        UpdatePannelRemarks[i] = UpdatePannelRemarks[i] ? UpdatePannelRemarks[i] : ''
                                        setUpdatePannelRemarks([...UpdatePannelRemarks])
                                      }
                                      // viewstatus[itemdata.child] = false
                                      // setViewstatus([...viewstatus])
                                      for (let k in itemdata.child) {
                                        PayloadCollapseStatus[itemdata.child[k].childId] = 1
                                        setPayloadCollapseStatus([...PayloadCollapseStatus])
                                      }
                                    }} />}
                                className="site-collapse-custom-collapse">
                                <Panel header={itemdata.parent} showArrow={true} key={itemdata.index}
                                  extra={genExtra(itemdata.child[0].not_app_remarks, itemdata.index, itemdata.parent, itemdata.child)}>
                                  {/* <Table
                                    size={'small'}
                                    className={localStorage.getItem("currentTheme") + ' edit-user-priv'}
                                    columns={column}
                                    dataSource={itemdata.child}
                                    bordered
                                    scroll={{ x: 1000 }}
                                    pagination={false} /> */}
                                  <div className={localStorage.getItem("currentTheme") + " ant-table-wrapper"}>
                                    <div className="ant-table ant-table-bordered ant-table-fixed-header">
                                      <div className="ant-table-container">
                                        <div className="ant-table-content taskrecalltable service-table">
                                          <table className="table-fixed">
                                            <thead className='ant-table-thead'>
                                              <tr>
                                                <th className='ant-table-cell' style={{ width: '10px' }}>#</th>
                                                <th className='ant-table-cell' style={{ width: '170px' }}>Statutory Provision</th>
                                                <th className='ant-table-cell' style={{ width: '170px' }}><center>Compliance Task</center></th>
                                                <th className='ant-table-cell' style={{ width: '200px' }}><center>Compliance Description</center></th>
                                                <th className='ant-table-cell' style={{ width: '50px' }}><center>Criticality</center></th>
                                                <th className='ant-table-cell' style={{ width: '30px' }}><center><span title='Applicable'><EditOutlined /></span></center></th>
                                                <th className='ant-table-cell' style={{ width: '30px' }}><center><span title='Opted'><UserAddOutlined /></span></center></th>
                                                <th className='ant-table-cell' style={{ width: '130px' }}><center>Remarks</center></th>
                                                <th className='ant-table-cell' style={{ width: '50px' }}><center>Viewed</center></th>
                                              </tr>
                                            </thead>
                                            <tbody className='ant-table-tbody'>
                                              {itemdata && itemdata.child.length > 0 && itemdata.child.map((item, i) => {
                                                let temp = item.app_status ? 1 : 0
                                                return <>
                                                  <tr>
                                                    <td className='ant-table-cell'><center>{item.indexNo}
                                                      {item.frequency_name == 'Periodical' ?
                                                        <Tooltip title={item && item.frequency_name}><p><i style={{ color: "green" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                                        item.frequency_name == 'Flexi Review' ?
                                                          <Tooltip title={item && item.frequency_name}><p><i style={{ color: "pink" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                                          item.frequency_name == 'Review' ?
                                                            <Tooltip title={item && item.frequency_name}><p><i style={{ color: "orange" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                                            item.frequency_name == 'On Occurrence' ?
                                                              <Tooltip title={item && item.frequency_name}><p><i style={{ color: "yellow" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                                              <Tooltip title={item && item.frequency_name}><p><i style={{ color: "red" }} className="bi bi-square-fill"></i></p></Tooltip>
                                                      }
                                                    </center></td>
                                                    <td className='ant-table-cell'>{item.s_prov}</td>
                                                    <td className='ant-table-cell'>{item.compfielaw_url ? <BookTwoTone /> : ''}{" "}{item.comp_name}</td>
                                                    <td className='ant-table-cell'>{item.descp}</td>
                                                    <td className='ant-table-cell'><center>{(item.criticality_client === null ? item.criticality_know :
                                                      (item.criticality_client !== item.criticality_know ? <span style={{ color: 'red', cursor: 'pointer' }} title={item.criticality_know} >
                                                        {item.criticality_client}</span> : <span title={''}>{item.criticality_client}</span>))
                                                    }</center></td>
                                                    <td className='ant-table-cell'><center>{item.app_status === true ? <FeatherIcon icon="check-circle" size={20} className={'pointer'} style={{ color: '#318CE7' }} /> : <FeatherIcon icon="x-circle" className={'pointer'} size={20} />}</center></td>
                                                    <td className='ant-table-cell'><center>{updateopt[item.childId] == 1 ? <FeatherIcon icon="check-circle" size={20} className={'pointer'} style={{ color: '#318CE7' }} onClick={(e) => {
                                                      updateopt[item.childId] = 0
                                                      setUpdateopt([...updateopt])
                                                      viewstatus[item.childId] = false
                                                      setViewstatus([...viewstatus])
                                                      if (refcompid[item.childId] == updateopt[item.childId]) {
                                                        updateChildRemarks[item.childId] = null
                                                        setUpdateChildRemarks([...updateChildRemarks])
                                                      } else {
                                                        updateChildRemarks[item.childId] = ''
                                                        setUpdateChildRemarks([...updateChildRemarks])
                                                      }
                                                      parentStatusChange(item.collapseId, item.childId, 0, itemdata.child)
                                                    }} /> : updateopt[item.childId] == 0 ? <FeatherIcon icon="x-circle" className={'pointer'} size={20} onClick={(e) => {
                                                      updateopt[item.childId] = 2
                                                      setUpdateopt([...updateopt])
                                                      viewstatus[item.childId] = false
                                                      setViewstatus([...viewstatus])
                                                      if (refcompid[item.childId] == updateopt[item.childId]) {
                                                        updateChildRemarks[item.childId] = updateChildRemarks[item.childId] ? updateChildRemarks[item.childId] : null
                                                        setUpdateChildRemarks([...updateChildRemarks])
                                                      } else {
                                                        updateChildRemarks[item.childId] = updateChildRemarks[item.childId] ? updateChildRemarks[item.childId] : ''
                                                        setUpdateChildRemarks([...updateChildRemarks])
                                                      }
                                                      parentStatusChange(item.collapseId, item.childId, 2, itemdata.child)
                                                    }} /> : updateopt[item.childId] == 2 ? < FeatherIcon icon="minus-circle" className={'pointer'} size={20} style={{ color: '	#FF0000' }} onClick={(e) => {
                                                      updateopt[item.childId] = 1
                                                      setUpdateopt([...updateopt])
                                                      viewstatus[item.childId] = false
                                                      setViewstatus([...viewstatus])
                                                      if (refcompid[item.childId] == updateopt[item.childId]) {
                                                        updateChildRemarks[item.childId] = null
                                                        setUpdateChildRemarks([...updateChildRemarks])
                                                      } else {
                                                        updateChildRemarks[item.childId] = ''
                                                        setUpdateChildRemarks([...updateChildRemarks])
                                                      }
                                                      parentStatusChange(item.collapseId, item.childId, 1, itemdata.child)
                                                    }} /> : ''}</center></td>
                                                    <td className='ant-table-cell'><center>
                                                      {temp != updateopt[item.childId] ? <>
                                                        <div className='d-flex align-items-end' id={'childId_' + item.childId} >
                                                          <div className="d-flex flex-column" style={{ "width": "100%" }}>
                                                            <textarea cols="5" rows="1"
                                                              id={'childId_' + item.childId} className={'form-control ' + displayNoneChild[item.childId]}
                                                              value={updateChildRemarks[item.childId]}
                                                              onChange={(e) => {
                                                                if (e.target.value != null) {
                                                                  updateChildRemarks[item.childId] = e.target.value;
                                                                  setUpdateChildRemarks([...updateChildRemarks])
                                                                  // triggerValidation[item.childId] = undefined
                                                                  // setTriggerValidation([...triggerValidation])
                                                                } else {
                                                                  // triggerValidation[item.childId] = 'Remarks Required'
                                                                  // setTriggerValidation([...triggerValidation])
                                                                }
                                                              }}
                                                              placeholder='Enter remarks' style={{ "width": "100%" }} />
                                                          </div>
                                                          <PlusCircleOutlined className={'text-info ms-1 ' + displayNoneChild[item.childId]}
                                                            id={'childId_' + item.childId}
                                                            onClick={() => { showRemarksModal(item.comp_id, item.collapseId, item.childId) }}
                                                            style={{ marginTop: '10px' }} />
                                                        </div>
                                                      </>
                                                        :
                                                        temp != updateopt[item.childId] ? <>
                                                          <div className={'d-flex align-items-end'} id={'childId_' + item.childId} >
                                                            <div className="d-flex flex-column" style={{ "width": "100%" }}>
                                                              <textarea class="form-control" cols="5" rows="1"
                                                                id={'childId_' + item.childId} className={'form-control ' + displayNoneChild[item.childId]}
                                                                placeholder='Enter remarks'
                                                                onChange={(e) => {
                                                                  if (e.target.value != null) {
                                                                    updateChildRemarks[item.childId] = e.target.value;
                                                                    setUpdateChildRemarks([...updateChildRemarks])
                                                                    // triggerValidation[item.childId] = undefined
                                                                    // setTriggerValidation([...triggerValidation])
                                                                  } else {
                                                                    // triggerValidation[item.childId] = 'Remarks Required'
                                                                    // setTriggerValidation([...triggerValidation])
                                                                  }
                                                                }}
                                                                value={updateChildRemarks[item.childId]}
                                                                style={{ "width": "100%" }} />
                                                            </div>
                                                            <PlusCircleOutlined className={'text-info ms-1 ' + displayNoneChild[item.childId]}
                                                              id={'childId_' + item.childId}
                                                              onClick={() => { showRemarksModal(item.comp_id, item.collapseId, item.childId) }}
                                                              style={{ marginTop: '10px' }} />
                                                          </div>
                                                        </> : ''
                                                        // <span id={'childId_' + item.childId} className={displayNoneChild[item.childId]}>{UpdatePannelRemarks[item.collapseId]}</span>
                                                      }
                                                    </center></td>
                                                    <td className='ant-table-cell'><center>{viewstatus[item.childId] ? '' : <Tooltip><p><i style={{ color: "orange" }} class="bi bi-square-fill"></i></p></Tooltip>}</center></td>
                                                  </tr>
                                                </>
                                              })
                                              }
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Panel>
                              </Collapse>
                            )
                          })}


                        </div>
                      </div>
                      {/* <div className="col-md-12 mt-2" >
                          <div className="row">
                            <label style={{ marginLeft: '-10px' }}>Showing 1 to {checkdata.length} of  {totaldatas}</label>
                            <label style={{ marginLeft: '-10px' }}>Note : Maximum 500 character allowed for each remarks</label>
                          </div>
                        </div> */}

                      <div className="col-md-12 mt-3">
                        <div className="row">
                          <div className='col-md-4' style={{ paddingLeft: '0px' }} >
                            <label>Showing 1 to {checkdata.length} of  {totaldatas} entries</label>
                            <label><b>Note : Maximum 500 character allowed for each remarks</b></label>
                          </div>
                          <div className='col-md-4 text-center'  >
                            {checkdata.length === totaldatas ?
                              <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                onClick={(e) => {
                                  updatestatutorySettings(e)
                                }} icon={<PlayCircleOutlined />} size='default'>
                                Submit
                              </Button>
                              :
                              <Button type="primary" shape="round" className='showbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                size='default'
                                onClick={(e) => { showmore(e) }}
                              >Show More <DownOutlined />
                              </Button>
                            }
                          </div>
                          <div className='col-md-4' style={{ textAlign: 'right', paddingRight: 0 }} >
                            {/* {checkdata.length === totaldatas ? */}
                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                              size='default'
                              onClick={(e) => {
                                savestatutorysettings(e)
                              }}
                            >Save <SaveOutlined />
                            </Button>
                            {/* // : '' */}
                            {/* } */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Stickicon />
                  </div>

                </div>

              </div>

            </div>
          </div>

          <Modal title="Remarks List" className={"remark-header add-service-prv modelradius " + localStorage.getItem('currentTheme')} visible={remarksModal} footer={null} onOk={handleOk} onCancel={handleCancel}>
            <Table
              // style={{ width: '420px !important' }}
              className={localStorage.getItem("currentTheme")}
              size={'small'}
              columns={remarkscolumn}
              dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
              bordered
              // scroll={{ y: 400 }}
              pagination={false}
            // pagination={{
            //   defaultPageSize: dataTableProperties.pagesize,
            //   showSizeChanger: dataTableProperties.sizechanger,
            //   pageSizeOptions: dataTableProperties.pageSizeOptions
            // }}
            />
          </Modal>
          <Modal title="Remarks List" className={"remark-header add-service-prv modelradius " + localStorage.getItem('currentTheme')} visible={PannelremarksModal} footer={null} onOk={handleOk} onCancel={handleCancel}>
            <Table size={'small'}
              className={localStorage.getItem("currentTheme")}
              columns={Pannelremarkscolumn}
              dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
              bordered
              // scroll={{ y: 400 }}
              pagination={false}
            // pagination={{
            //   defaultPageSize: dataTableProperties.pagesize,
            //   showSizeChanger: dataTableProperties.sizechanger,
            //   pageSizeOptions: dataTableProperties.pageSizeOptions
            // }}
            />
          </Modal>
          {/* Password Modal popup */}
          <Modal autoFocus={false} title="Enter Password" visible={passwordmodal} footer={null} onCancel={passwordcancel} className="newStyleModalPassword">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-6">
                  <label className="control-label">Password:</label>
                  <span style={{ "color": "red" }}>*</span>
                  <input
                    autoFocus={true}
                    style={{ marginTop: '5px' }}
                    type="password"
                    id="password"
                    disabled={passworddisable}
                    name='passwordvalue'
                    className="form-control"
                    placeholder="Enter password"
                    autoComplete='off'
                    onChange={(e) => {
                      Setpassword({
                        ...password,
                        passwordvalue: e.target.value
                      })
                    }}
                    value={password.passwordvalue}
                  />
                  {formValidator.current.message(
                    'Password',
                    password.passwordvalue,
                    ['required'],
                    {
                      className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                      messages: {
                        required: 'Password Required'
                      }
                    })}
                </div>
              </div>
            </div>
            <div className={"form-actions text-center popupbtncolour " + localStorage.getItem('currentTheme')}>
              <Button type="primary" shape="round" disabled={passworddisable} className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginTop: '10px' }}
                onClick={(e) => {
                  submitpassword(e)
                }} icon={<PlayCircleOutlined />} size='default'>
                Submit
              </Button>
              {/* <button
                  className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start" onClick={passwordcancel}>

                  Cancel
                </button> */}
            </div>
          </Modal>
        </div >
      }
    </div >
  )
}

export default connect(mapStateToProps, {
  statutorylist,
  editstatutory,
  filterdata,
  locksettings,
  GetRemarksData,
  SaveStatutorySettings,
  UpdateStatutorySettings
})(Statutorysetting);