import React from 'react'
import { FilterOutlined, InfoCircleOutlined, PlayCircleOutlined, InfoOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Collapse, Select, Input, DatePicker, Card, Modal, Table, Tooltip, Progress, Pagination, Button, Drawer } from 'antd';
import { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, EyeOutlined, ExportOutlined, FullscreenOutlined, UpOutlined } from '@ant-design/icons';
import {
    getTaskCategoryReport, getTaskCategoryData, getExportData, getdownloaddocument, historyrecord,
    interimrecord, getcollapsedata
} from '../../../Store/Action/Report/TaskCategoryReport';
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    allData: state.TaskCategoryReport.taskCategoryReport.data,
    tableData: state.TaskCategoryReport.taskCategoryReport.getTableData,
    history_record: state.TaskCategoryReport.taskCategoryReport.history_data,
    interim_record: state.TaskCategoryReport.taskCategoryReport.interim_data,
    collapse_record: state.TaskCategoryReport.taskCategoryReport.collapse_data
})

const TaskCategoryReport = ({
    getTaskCategoryReport,
    allData,
    tableData,
    getTaskCategoryData,
    getExportData,
    getdownloaddocument,
    historyrecord,
    interimrecord,
    history_record,
    interim_record,
    getcollapsedata,
    collapse_record
}) => {
    const _ = require("lodash");
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [getreportlist, setGetReportList] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const location = useLocation();
    const paramid = location.pathname;
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    let [index, setIndex] = useState(0)
    const [entity, setEntity] = useState([])
    const [historymodal, sethistorymodal] = useState(false)
    const [interimmodal, setinterimmodal] = useState(false);
    const [collapsemodal, setcollapsemodal] = useState(false);
    const [consolidateddata, setConsolidatedDate] = useState({
        country: "",
        category: "",
        user_type: "",
        legel_entity: "",
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: 0,
        compliance_task_status: "",
        taskCategory: "",
        domainName: '',
        countryName: '',
        unit_name: '',
        user_name: '',
        actName: '',
        division_name: '',
        compliance_frequency_name: 'All',
        compliance_task_status_name: '',
        category_name: '',
        user_type_name: '',
        le_id: '',
        task_category_name: '',
        task_sub_categroy_name: 'All'


    })

    console.log(consolidateddata, "consolidateddata4444");
    const validator = useRef(new SimpleReactValidator());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageState, setpageState] = useState(false);
    const [domainList, setDomainList] = useState([])
    const [divisionList, setDivisionList] = useState([])
    const [unitCodeLabelList, setUnitCodeLabelList] = useState([])
    const [unitLegalEntity, setUnitLegalEntity] = useState([])
    console.log(unitLegalEntity, "unitLegalEntity");
    const [complianceUser, setComplianceUser] = useState([])
    const [taskSubCategoryList, setTaskSubCategoryList] = useState([])
    const [taskCategoryList, setTaskCategoryList] = useState([])
    const [complianceFrequencyList, setComplianceFrequencyList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [actLegalEntity, setActLegalEntity] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [titledata, Settitledata] = useState()
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 3, 4, 5, 6, 9, 10, 29, 32])
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const [statkey, setStatKey] = useState("0")
    const { Panel } = Collapse;
    const { Option } = Select;

    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    useEffect(() => {
        if (localStorage.getItem("SelectedEntity") !== "All Legal Entity") {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setConsolidatedDate({
                ...consolidateddata,
                countryName: tempcountry[0].c_name,
                country: tempcountry[0].c_id,
                le_id: localStorage.getItem('SelectedEntityid')
            })
        }
    }, [localStorage.getItem("SelectedEntity") !== "All Legal Entity"])

    const onshow = () => {
        setFilteredArrayState([])
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            setIndex(0);
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            const payload =
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetTaskCategoryWiseReport",
                            {
                                "country_id": Number(consolidateddata.country),
                                "legal_entity_id": consolidateddata.le_id,
                                "domain_id": Number(consolidateddata.domain),
                                "division_id": Number(consolidateddata.division) ? Number(consolidateddata.division) : 0,
                                "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                                "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                                "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                                "compliance_task": consolidateddata.compliance_task ? Number(consolidateddata.compliance_task) : null,
                                "frequency_id": Number(consolidateddata.compliance_frequency) ? Number(consolidateddata.compliance_frequency) : 0,
                                "task_category_id": Number(consolidateddata.taskCategory) ? Number(consolidateddata.taskCategory) : 0,
                                "task_sub_category_id": Number(consolidateddata.task_sub_category) ? Number(consolidateddata.task_sub_category) : 0,
                                "csv": false,
                                "from_count": 0,
                                "page_count": 25,
                                "list_check": null
                            }
                        ]
                    }
                ]

            getTaskCategoryData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setCheckBoxValue([1, 2, 3, 4, 5, 6, 9, 10, 29, 32])
    };

    const columns = [
        {
            title: "Compliance Task",
            ellipsis: true,
            // selector: (row) => row.compliance_task,
            width: '60px',
            dataIndex: false,
            key: false,
            render: (record) => {
                return (
                    <>
                        <div className="mb-3">
                            <Tooltip title={record.compliance_description}>
                                <p style={{ whiteSpace: 'unset' }}><InfoCircleOutlined />&nbsp;{record.compliance_task}</p>
                            </Tooltip>
                        </div>
                    </>
                )
            }
        },
        {
            title: "Criticality",
            ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: "criticality_name",
            key: "criticality_name",
            // render: (record) => record.frequency_name
        },
        {
            title: "Frequency",
            ellipsis: true,
            width: '50px',
            align: 'center',
            dataIndex: "frequency_name",
            key: "frequency_name",
            // render: (record) => record.frequency_name
        },
        {
            title: "User Name",
            width: '50px',
            ellipsis: true,
            dataIndex: "assignee_name",
            key: false,
            // render: (record) => record.assignee_name
        },
        {
            title: "Activity Status",
            width: '50px',
            ellipsis: true,
            dataIndex: "activity_status",
            key: "activity_status",
            // render: (record) => record.activity_status
        },

        {
            title: "Compliance Status",
            width: '50px',
            ellipsis: true,
            dataIndex: "task_status",
            key: "task_status",
            // render: (record) => record.task_status
        },
        {
            title: "Due Date",
            width: '50px',
            ellipsis: true,
            dataIndex: "due_date",
            key: "due_date",
            // render: (record) => record.due_date
        },
        {
            title: "Completion Date",
            width: '50px',
            ellipsis: true,
            dataIndex: "start_date",
            key: "start_date",
            // render: (record) => record.activity_date
        },
        {
            title: "Document",
            width: '50px',
            ellipsis: true,
            dataIndex: "frequency_name",
            key: "frequency_name",
            // render: (record) => record.frequency_name
        },
    ];


    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const handlePasting = (e, regex) => {
        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
    }

    const handleOk = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskCategoryWiseReport",
                        {
                            "country_id": Number(consolidateddata.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidateddata.domain),
                            "division_id": Number(consolidateddata.division) ? Number(consolidateddata.division) : 0,
                            "category_id": Number(consolidateddata.category) ? Number(consolidateddata.category) : 0,
                            "unit_id": Number(consolidateddata.unit) ? Number(consolidateddata.unit) : 0,
                            "parent_id": Number(consolidateddata.act) ? Number(consolidateddata.act) : 0,
                            "compliance_task": consolidateddata.compliance_task ? Number(consolidateddata.compliance_task) : null,
                            "frequency_id": Number(consolidateddata.compliance_frequency) ? Number(consolidateddata.compliance_frequency) : 0,
                            "task_category_id": Number(consolidateddata.taskCategory) ? Number(consolidateddata.taskCategory) : 0,
                            "task_sub_category_id": Number(consolidateddata.task_sub_category) ? Number(consolidateddata.task_sub_category) : 0,
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            getExportData({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false)
        setCheckBoxValue([1, 2, 3, 4, 5, 6, 9, 10, 29, 32])
    };

    const onChange = (key) => {
        console.log(key);
    };

    const onStatChange = (key) => {
        console.log(key);
    }

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 3, 4, 5, 6, 9, 10, 29, 32])
        }
    }

    useEffect(() => {
        if (tableData && tableData.task_compliances && tableData.task_compliances.length > 0) {
            var array = tableData.task_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }
    }, [tableData.task_compliances, pageSize, current])


    useEffect(() => {
        validator.current.showMessages()
    }, [])
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };



    useEffect(() => {
        if (isAuth) {
            if (entityid != 'null') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetTaskCategoryWiseReportFilters",
                            {
                                "country_id": Number(consolidateddata.country),
                                "legal_entity_id": entityid
                            }
                        ]
                    }
                ]
                getTaskCategoryReport({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid])

    useEffect(() => {
        if (allData) {
            setActLegalEntity(allData && allData.tsk_act_legal_entity)
            setCategoryList(allData && allData.category_list)
            setComplianceFrequencyList(allData && allData.compliance_frequency_list)
            setDivisionList(allData && allData.division_list)
            setDomainList(allData && allData.domains)
            setTaskCategoryList(allData && allData.task_category_list)
            setTaskSubCategoryList(allData && allData.task_sub_category_list)
            setUnitLegalEntity(allData && allData.unit_list)
        }

    }, [allData])

    useEffect(() => {
        if (paginationArray && paginationArray.length > 0) {
            let uniqueObjArray = [...new Map(paginationArray && paginationArray.length && paginationArray.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(paginationArray, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [paginationArray])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (paginationArray && paginationArray.length) {
            const ab = paginationArray && paginationArray.length && paginationArray.map(function (item) {
                if ((array.indexOf(item.act_name) < 0))
                    array.push(item.act_name)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    paginationArray && paginationArray.length && paginationArray.map((child, childIndex) => {
                        if (data === child.act_name) {
                            entityArray = {
                                "act_name": child.act_name,
                                "activity_date": child.activity_date,
                                "activity_status": child.activity_status,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "completion_date": child.completion_date,
                                "compliance_description": child.compliance_description,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "country_id": child.country_id,
                                "criticality_name": child.criticality_name,
                                "division_name": child.division_name,
                                "doc_ref_num": child.doc_ref_num,
                                "document_name": child.document_name,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extend_due_date": child.extend_due_date,
                                "frequency_name": child.frequency_name,
                                "history_count": child.history_count,
                                "interim_count": child.interim_count,
                                "legal_entity_id": child.legal_entity_id,
                                "logo_url": child.logo_url,
                                "parent_id": child.parent_id,
                                "remarks_count": child.remarks_count,
                                "start_date": child.start_date,
                                "task_category_id": child.task_category_id,
                                "task_status": child.task_status,
                                "task_sub_category_id": child.task_sub_category_id,
                                "task_sub_category_name": child.task_sub_category_name,
                                "tree_count": child.tree_count,
                                "tz_name": child.tz_name,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                "url": child.url
                            }
                            temp.push(entityArray)
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [paginationArray && paginationArray.length])

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const downloaddocument = (item) => {
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "DownloadFile",
                    {
                        "le_id": consolidateddata.le_id,
                        "c_id": parseInt(consolidateddata.country),
                        "d_id": parseInt(consolidateddata.domain),
                        "u_id": item.unit_id,
                        "start_date": item.start_date,
                        "file_name": item.document_name
                    }
                ]
            }
        ]
        getdownloaddocument({
            payload: pay,
            paramid: paramid
        })
    }

    const viewactivity_histroy = (his_data) => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceRemarksActionHistoryData",
                    {
                        "legal_entity_id": consolidateddata.le_id,
                        "unit_id": his_data.unit_id,
                        "compliance_history_id": his_data.compliance_history_id
                    }
                ]
            }
        ]
        historyrecord({
            payload: payload,
            paramid: paramid
        })
        sethistorymodal(true);
    }
    const view_interim = (int_details) => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetInterimComplianceDetails",
                    {
                        "legal_entity_id": consolidateddata.le_id,
                        "compliance_history_id": int_details.compliance_history_id
                    }
                ]
            }
        ]
        interimrecord({
            payload: payload,
            paramid: paramid
        })
        setinterimmodal(true)
    }

    const historycancel = () => {
        sethistorymodal(false);
    }

    const interimcancel = () => {
        setinterimmodal(false);
    }

    const collapsecancel = () => {
        setcollapsemodal(false);
    }

    const history_columns = [
        {
            title: '#',
            key: 'index',
            align: "center",
            width: "30px",
            render: (text, record, index) => {
                return (
                    index + 1
                )
            },
        },
        {
            title: 'Activity Date',
            key: 'activity_on',
            align: "center",
            width: "50px",
            dataIndex: 'activity_on',
        },
        {
            title: 'Action',
            key: 'action',
            align: "center",
            width: "50px",
            dataIndex: 'action',
        },
        {
            title: 'User Name',
            key: 'employee_name',
            align: "center",
            width: "50px",
            dataIndex: 'employee_name',
        },
        {
            title: 'User Type',
            key: 'action',
            align: "center",
            width: "50px",
            dataIndex: 'action',
            render: (text, record) => {
                if (record.user_category_id == 5 || record.user_category_id == 6) {
                    return 'Assignee'
                } else if ((record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Rectified' || record.action == 'Concurrence Rejected' || record.action == 'Concurred')) {
                    return 'Concurrer'
                } else if (record.user_category_id == 1 && (record.action == 'RectifyApproval' || record.action == 'Approval Rejected' || record.action == 'Approved') ||
                    (record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Approval Rejected' || record.action == 'RectifyApproval' || record.action == 'Approved')) {
                    return 'Approver'
                }

            }
        },
        {
            title: 'Reviewer Report',
            key: 'documents',
            align: "center",
            width: "50px",
            dataIndex: 'documents',
        },
        {
            title: 'Remarks',
            key: 'remarks',
            align: "center",
            width: "50px",
            dataIndex: 'remarks',
        },
    ]

    const interim_columns = [
        {
            title: '#',
            key: 'index',
            align: "center",
            width: "30px",
            render: (text, record, index) => {
                return (
                    index + 1
                )
            },
        },
        {
            title: 'Documents',
            key: 'documents',
            align: "center",
            width: "50px",
            dataIndex: 'documents',
            render: (data, record) => {
                let file_name = data.split('||')
                return file_name && file_name.map((item) => {
                    return <><span onClick={() => { downloaddocument(record) }}>{item}<hr /></span></>
                })
            }
        },
        {
            title: 'Remarks',
            key: 'remarks',
            align: "center",
            width: "50px",
            dataIndex: 'remarks',
        },
        {
            title: 'Updated On',
            key: 'activity_on',
            align: "center",
            width: "50px",
            dataIndex: 'activity_on',
        },
    ]

    const collapse_columns = [
        {
            title: 'Compliance Task',
            key: 'compliance_task',
            align: "center",
            width: "50px",
            dataIndex: 'compliance_task',
        },
        {
            title: 'Frequency',
            key: 'frequency_name',
            align: "center",
            width: "50px",
            dataIndex: 'frequency_name',
        },
        {
            title: 'User Name',
            key: 'assignee_name',
            align: "center",
            width: "50px",
            dataIndex: 'assignee_name',
        },
        {
            title: 'Activity Status',
            key: 'activity_status',
            align: "center",
            width: "50px",
            dataIndex: 'activity_status',
            render: (data, record) => {
                if (record.history_count != 0) {
                    return <>
                        {data}<span className="mx-2 bg-primary rounded-circle text-white task-category-table-btn">
                            <Tooltip title='click here to View Activity History'>
                                <InfoOutlined onClick={() => { viewactivity_histroy(record) }} />
                            </Tooltip></span>
                    </>
                }
                if (record.interim_count != 0) {
                    return <>
                        {data}<span className="bg-danger rounded-circle text-white task-category-table-btn">
                            <Tooltip title='click here to view Interim Details'>
                                <ArrowUpOutlined onClick={() => { view_interim(record) }} /></Tooltip></span>
                    </>
                }
            }
        },
        {
            title: 'Compliance Status',
            key: 'task_status',
            align: "center",
            width: "50px",
            dataIndex: 'task_status',
        },
        {
            title: 'Due Date',
            key: 'due_date',
            align: "center",
            width: "50px",
            dataIndex: 'due_date',
        },
        {
            title: 'Completion Date',
            key: 'completion_date',
            align: "center",
            width: "50px",
            dataIndex: 'completion_date',
        },
    ]

    const collapse_data = (data) => {
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetTaskCategoryWiseReportTreeData",
                    {
                        "country_id": parseInt(consolidateddata.country),
                        "legal_entity_id": consolidateddata.le_id,
                        "domain_id": parseInt(consolidateddata.domain),
                        "compliance_history_id": data[0].compliance_history_id,
                        "unit_id": data[0].unit_id,
                        "task_category_id": data[0].task_category_id,
                        "task_sub_category_id": data[0].task_sub_category_id,
                        "parent_id": data[0].parent_id,
                        "c_task": null,
                        "frequency_id": consolidateddata.compliance_frequency ? parseInt(consolidateddata.compliance_frequency) : 0
                    }
                ]
            }
        ]
        getcollapsedata({
            payload: pay,
            paramid: paramid
        })
        setcollapsemodal(true);
    }

    return (
        <div id='page-wrapper' className="page-wrapper">
            {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
                {document.body.scrollHeight > document.body.clientHeight ?
                    <div className='back-to-top'>
                        <ScrollButton />
                    </div> : ''} */}
            {tableshow == true ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''}
            <div className="page-titles pb-2 pt-1 page-title-sticky">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Task Category Report</span>
                                </li>
                            </ol>
                        </nav>

                    </div>
                    <div className="col-lg-4 col-md-6 text-end">
                        <Tooltip title={'Advance Filter'}><Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button></Tooltip>
                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>

            <Drawer title="Select Fields" width={500} className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onClose={handleCancel} onCancel={handleCancel}>
                <div className='d-flex flex-wrap'>
                    <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 32 ? true : false}
                        onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} /> <label for='select_all'>Select All</label>

                    <input id="column_1" name='chk' type="checkbox" disabled="disabled" checked value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /><label for='column_1'>  Unit Code</label>
                    <input id="column_2" name='chk' type="checkbox" disabled="disabled" value='2' checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'>  Unit Name</label>
                    <input id="column_7" name='chk' type="checkbox" value='3' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'> Act/Rules</label>
                    <input id="column_31" name='chk' type="checkbox" value='4' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_31'> Location </label>
                    <input id="column_8" name='chk' type="checkbox" value='5' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_8'>  Compliance Task </label>
                    <input id="column_9" name='chk' type="checkbox" value='6' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_9'>  Frequency </label>
                    <input id="column_10" name='chk' type="checkbox" value='7' checked={checkboxValue.includes(7)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_10'>  Periodicity </label>
                    <input id="column_29" name='chk' type="checkbox" value='8' checked={checkboxValue.includes(8)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_29'>  Criticality</label>
                    <input id="column_32" name='chk' type="checkbox" value='9' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_32'>  Task Category </label>
                    <input id="column_3" name='chk' type="checkbox" value='10' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>  Task Sub Category</label>
                    <input id="column_4" name='chk' type="checkbox" value='11' checked={checkboxValue.includes(11)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'> Division</label>
                    <input id="column_5" name='chk' type="checkbox" value='12' checked={checkboxValue.includes(12)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'>  Category</label>
                    <input id="column_6" name='chk' type="checkbox" value='13' checked={checkboxValue.includes(13)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>   Assigned by</label>
                    <input id="column_11" name='chk' type="checkbox" value='14' checked={checkboxValue.includes(14)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_11'>  Assigned To</label>
                    <input id="column_12" name='chk' type="checkbox" value='15' checked={checkboxValue.includes(15)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_12'> Assigned Date </label>
                    <input id="column_13" name='chk' type="checkbox" value='16' checked={checkboxValue.includes(16)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_13'>  Assignee </label>
                    <input id="column_14" name='chk' type="checkbox" value='17' checked={checkboxValue.includes(17)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_14'>  Completed on </label>
                    <input id="column_15" name='chk' type="checkbox" value='18' checked={checkboxValue.includes(18)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_15'>  Concurrer</label>
                    <input id="column_16" name='chk' type="checkbox" value='19' checked={checkboxValue.includes(19)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_16'>  Concurred on  </label>
                    <input id="column_17" name='chk' type="checkbox" value='20' checked={checkboxValue.includes(20)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'>  Approver </label>
                    <input id="column_18" name='chk' type="checkbox" value='21' checked={checkboxValue.includes(21)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_18'>  Approved on </label>
                    <input id="column_19" name='chk' type="checkbox" value='22' checked={checkboxValue.includes(22)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_19'> Activity Status</label>
                    <input id="column_20" name='chk' type="checkbox" value='23' checked={checkboxValue.includes(23)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_20'>  Start Date  </label>
                    <input id="column_21" name='chk' type="checkbox" value='24' checked={checkboxValue.includes(24)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_21'> Due Date</label>
                    <input id="column_22" name='chk' type="checkbox" value='25' checked={checkboxValue.includes(25)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_22'>  Extend Due Date </label>
                    <input id="column_23" name='chk' type="checkbox" value='26' checked={checkboxValue.includes(26)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_23'>   Task Completion Date / Document Issued Date</label>
                    <input id="column_24" name='chk' type="checkbox" value='27' checked={checkboxValue.includes(27)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_24'> Month</label>
                    <input id="column_25" name='chk' type="checkbox" value='28' checked={checkboxValue.includes(28)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_25'>  Validity Date </label>
                    <input id="column_26" disabled="disabled" checked name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /><label for='column_26'> Statutory Status</label>
                    <input id="column_27" name='chk' type="checkbox" value='30' checked={checkboxValue.includes(30)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_27'>   Duration</label>
                    <input id="column_28" name='chk' type="checkbox" value='31' checked={checkboxValue.includes(31)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_28'>  Document Reference Number</label>
                    <input id="column_30" checked disabled="disabled" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /><label for='column_30'>  Remarks </label>

                </div>
                <div className="form-actions popupbtncolour text-center">
                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>

                </div>
            </Drawer>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'} style={{ position: 'relative', padding: "10px 3px" }}>

                            <Collapse className='report-collapse' style={{ display: exitCollapse ? "none" : "block" }} accordion defaultActiveKey={["1"]} onChange={onChange} >
                                <Panel header=" Task Category Report" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Country"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                                }}
                                                                onChange={(value, data) => {
                                                                    if (value !== undefined) {
                                                                        let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                        setEntity(le)
                                                                        setConsolidatedDate({
                                                                            ...consolidateddata,
                                                                            country: value,
                                                                            countryName: data.children,
                                                                            category: "",
                                                                            user_type: "",
                                                                            legel_entity: "",
                                                                            unit: '',
                                                                            user: "",
                                                                            domain: "",
                                                                            act: "",
                                                                            from_date: "",
                                                                            task_category: "",
                                                                            compliance_task: "",
                                                                            to_date: "",
                                                                            task_sub_category: "",
                                                                            compliance_frequency: "",
                                                                            compliance_task_status: "",
                                                                            taskCategory: "",
                                                                            domainName: '',
                                                                            unit_name: '',
                                                                            user_name: '',
                                                                            actName: '',
                                                                            division_name: '',
                                                                            compliance_frequency_name: 'All',
                                                                            compliance_task_status_name: '',
                                                                            category_name: '',
                                                                            user_type_name: '',
                                                                            le_id: '',
                                                                            task_category_name: '',
                                                                            task_sub_categroy_name: 'All'
                                                                        })
                                                                    }
                                                                    else {
                                                                        setEntity([])
                                                                        setConsolidatedDate({
                                                                            ...consolidateddata,
                                                                            country: '',
                                                                            countryName: '',
                                                                            category: "",
                                                                            user_type: "",
                                                                            legel_entity: "",
                                                                            unit: '',
                                                                            user: "",
                                                                            domain: "",
                                                                            act: "",
                                                                            from_date: "",
                                                                            task_category: "",
                                                                            compliance_task: "",
                                                                            to_date: "",
                                                                            task_sub_category: "",
                                                                            compliance_frequency: "",
                                                                            compliance_task_status: "",
                                                                            taskCategory: "",
                                                                            domainName: '',
                                                                            unit_name: '',
                                                                            user_name: '',
                                                                            actName: '',
                                                                            division_name: '',
                                                                            compliance_frequency_name: 'All',
                                                                            compliance_task_status_name: '',
                                                                            category_name: '',
                                                                            user_type_name: '',
                                                                            le_id: '',
                                                                            task_category_name: '',
                                                                            task_sub_categroy_name: 'All'
                                                                        })
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={Settings.le_id}
                                                                style={{ width: '100%', marginTop: '2px' }}

                                                                value={consolidateddata.countryName || undefined}
                                                            >
                                                                {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {/* {validator.current.message(
                                                                'legalentity',
                                                                consolidateddata.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                    }
                                                                })} */}
                                                        </div> :
                                                        <p>{consolidateddata.countryName}</p>
                                                    }
                                                </div>
                                                {validator.current.message(
                                                    'country',
                                                    consolidateddata.country,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Country Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                            }}
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    setCurrentEntity(data)
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        le_id: data,
                                                                        legel_entity: value.children,
                                                                        category: "",
                                                                        user_type: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        taskCategory: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        actName: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: 'All',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        task_category_name: '',
                                                                        task_sub_categroy_name: 'All'
                                                                    })
                                                                }
                                                                else {
                                                                    setCurrentEntity('')
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        le_id: '',
                                                                        legel_entity: '',
                                                                        category: "",
                                                                        user_type: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        taskCategory: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        actName: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: 'All',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        task_category_name: '',
                                                                        task_sub_categroy_name: 'All'
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={consolidateddata.legel_entity || undefined}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            disabled={consolidateddata.country == '' ? true : false}

                                                        >
                                                            {entity && entity.length && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            consolidateddata.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b>  <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        }}
                                                        onChange={(value, data) => {
                                                            if (value !== undefined) {
                                                                let filteredTaskCategory = _.filter(allData && allData.task_category_list, { task_domain_id: Number(value) })
                                                                setTaskCategoryList(filteredTaskCategory)
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    domain: value,
                                                                    domainName: data.children,
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    taskCategory: "",
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    actName: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: 'All',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_category_name: '',
                                                                    task_sub_categroy_name: 'All'
                                                                })
                                                            }
                                                            else {
                                                                setTaskCategoryList([])
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    domain: '',
                                                                    domainName: '',
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    taskCategory: "",
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    actName: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: 'All',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_category_name: '',
                                                                    task_sub_categroy_name: 'All'
                                                                })
                                                            }
                                                        }}
                                                        placeholder="Enter Domain"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={consolidateddata.domainName || undefined}
                                                        disabled={consolidateddata.le_id == '' ? true : false}

                                                    >
                                                        {domainList && domainList.length && domainList.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidateddata.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>



                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Task Category : </b> <span style={{ color: "red" }}>*</span></label>
                                                <Select allowClear={true}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(value, data) => {
                                                        if (value !== undefined) {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                taskCategory: value,
                                                                task_category_name: data.children
                                                            })

                                                            let filteredCategory = _.filter(allData && allData.task_sub_category_list, { task_category_id: Number(value) })
                                                            setTaskSubCategoryList(filteredCategory)
                                                        }
                                                        else {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                taskCategory: '',
                                                                task_category_name: ''
                                                            })


                                                            setTaskSubCategoryList([])
                                                        }
                                                        // let filteredUnit = _.filter(unitLegalEntity, { division_id: Number(value) })
                                                        // setUnitLegalEntity(filteredUnit)
                                                    }}
                                                    placeholder="Enter Task Category"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={consolidateddata.task_category_name ? consolidateddata.task_category_name : '--Select--'}
                                                    disabled={consolidateddata.domain == '' ? true : false}

                                                >
                                                    {taskCategoryList && taskCategoryList.length && taskCategoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_category_id}>
                                                                {item.task_category_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'taskCategory',
                                                    consolidateddata.taskCategory,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Task Category Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Task Sub Category : </b> </label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        }}
                                                        onChange={(value, data) => {
                                                            if (value !== undefined) {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    task_sub_category: value,
                                                                    task_sub_categroy_name: data.children
                                                                })
                                                            }
                                                            else {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    task_sub_category: '',
                                                                    task_sub_categroy_name: ''
                                                                })
                                                            }
                                                        }}
                                                        placeholder="Task Sub Category"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        disabled={consolidateddata.taskCategory == '' ? true : false}
                                                        value={consolidateddata.task_sub_categroy_name || undefined}
                                                    >
                                                        <Option key='All'>All</Option>
                                                        {taskSubCategoryList && taskSubCategoryList.length && taskSubCategoryList.map((item, i) => {
                                                            return (
                                                                <Option key={item.task_sub_category_id}>
                                                                    {item.task_sub_category_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Category : </b> </label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        }}
                                                        onChange={(value, data) => {
                                                            if (value !== undefined) {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    category: value,
                                                                    category_name: data.children
                                                                })
                                                                let filteredUnit = _.filter(unitLegalEntity, { category_id: Number(value) })
                                                                setUnitLegalEntity(filteredUnit)

                                                            }
                                                            else {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    category: '',
                                                                    category_name: ''
                                                                })
                                                            }
                                                        }}
                                                        placeholder="Enter Category"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        value={consolidateddata.category_name || undefined}
                                                        disabled={consolidateddata.le_id == '' ? true : false}

                                                    >
                                                        {categoryList && categoryList.length && categoryList.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>

                                            <div className='col-md-4'>
                                                <label><b>Unit : </b> </label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        }}
                                                        onChange={(value, data) => {
                                                            if (value !== undefined) {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    unit: value,
                                                                    unit_name: data.children
                                                                })
                                                            }
                                                            else {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    unit: '',
                                                                    unit_name: ''
                                                                })
                                                            }
                                                        }}
                                                        placeholder="Enter Unit"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={consolidateddata.unit_name || undefined}
                                                        disabled={consolidateddata.le_id == '' ? true : false}

                                                    >
                                                        {unitLegalEntity && unitLegalEntity.length && unitLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {`${item.unit_code}-${item.unit_name}`}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Frequency : </b> </label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        }}
                                                        onChange={(value, data) => {
                                                            if (value !== undefined) {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    compliance_frequency: value,
                                                                    compliance_frequency_name: data.children
                                                                })
                                                            }
                                                            else {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    compliance_frequency: '',
                                                                    compliance_frequency_name: ''
                                                                })
                                                            }
                                                        }}
                                                        placeholder="Enter Compliance Frequency"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        value={consolidateddata.compliance_frequency_name || undefined}
                                                        disabled={consolidateddata.le_id == '' ? true : false}

                                                    >
                                                        <Option key='All'>All</Option>
                                                        {complianceFrequencyList && complianceFrequencyList.length && complianceFrequencyList.map((item, i) => {

                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task : </b> </label>
                                                <div className="form-group">
                                                    <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}
                                                        // allowClear={true}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        }}
                                                        onChange={(e) => {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_task: e.target.value
                                                            })
                                                        }}
                                                        value={consolidateddata.compliance_task || undefined}
                                                        disabled={consolidateddata.le_id == '' ? true : false}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Division : </b> </label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        }}
                                                        onChange={(value, data) => {
                                                            if (value !== undefined) {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    division: value,
                                                                    division_name: data.children
                                                                })
                                                                let filteredCategory = _.filter(categoryList, { div_id: Number(value) })
                                                                setCategoryList(filteredCategory)

                                                            }
                                                            else {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    division: '',
                                                                    division_name: ''
                                                                })

                                                                setCategoryList([])
                                                                setUnitLegalEntity([])
                                                            }
                                                        }}
                                                        placeholder="Select Division"
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        disabled={consolidateddata.le_id == '' ? true : false}
                                                        value={consolidateddata.division_name || undefined}
                                                    >
                                                        {divisionList && divisionList.length && divisionList.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Act/Rules : </b> </label>
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        }}
                                                        onChange={(value, data) => {
                                                            if (value !== undefined) {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    act: value,
                                                                    actName: data.children
                                                                })
                                                            }
                                                            else {
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    act: '',
                                                                    actName: ''
                                                                })
                                                            }
                                                        }}
                                                        placeholder="Enter Act/Rules"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        disabled={consolidateddata.le_id == '' ? true : false}
                                                        value={consolidateddata.actName || undefined}
                                                    >
                                                        {actLegalEntity && actLegalEntity.length && actLegalEntity.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.statutory_mapping}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='text-center mt-2 popupbtncolour'>
                                        <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                            icon={<EyeOutlined />} onClick={onshow}
                                        >
                                            Show
                                        </Button>
                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                            icon={<ExportOutlined />} onClick={showModal}
                                        >
                                            Export
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                onCancel={setcancelFilter}
                                footer={false}
                                title="Task Category Report"
                                className={
                                    "add-service-prv cons-report " +
                                    localStorage.getItem("currentTheme")
                                }
                                visible={filterModalVisible}
                            >
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                            }}
                                                            onChange={(value, data) => {
                                                                if (value !== undefined) {
                                                                    let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                    setEntity(le)
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        country: value,
                                                                        countryName: data.children,
                                                                        category: "",
                                                                        user_type: "",
                                                                        legel_entity: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        taskCategory: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        actName: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: 'All',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        le_id: '',
                                                                        task_category_name: '',
                                                                        task_sub_categroy_name: 'All'
                                                                    })
                                                                }
                                                                else {
                                                                    setEntity([])
                                                                    setConsolidatedDate({
                                                                        ...consolidateddata,
                                                                        country: '',
                                                                        countryName: '',
                                                                        category: "",
                                                                        user_type: "",
                                                                        legel_entity: "",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "",
                                                                        taskCategory: "",
                                                                        domainName: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        actName: '',
                                                                        division_name: '',
                                                                        compliance_frequency_name: 'All',
                                                                        compliance_task_status_name: '',
                                                                        category_name: '',
                                                                        user_type_name: '',
                                                                        le_id: '',
                                                                        task_category_name: '',
                                                                        task_sub_categroy_name: 'All'
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}

                                                            value={consolidateddata.countryName || undefined}
                                                        >
                                                            {uniq_country_data && uniq_country_data.length && uniq_country_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {/* {validator.current.message(
                                                                'legalentity',
                                                                consolidateddata.legel_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                    }
                                                                })} */}
                                                    </div> :
                                                    <p>{consolidateddata.countryName}</p>
                                                }
                                            </div>
                                            {validator.current.message(
                                                'country',
                                                consolidateddata.country,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Country Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Legal Entity : </b>  <span style={{ color: "red" }}>*</span></label>
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="form-group">
                                                    <Select allowClear={true}
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="default"
                                                        placeholder="Enter Legal Entity"
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        }}
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                setCurrentEntity(data)
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    le_id: data,
                                                                    legel_entity: value.children,
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    taskCategory: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    actName: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: 'All',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_category_name: '',
                                                                    task_sub_categroy_name: 'All'
                                                                })
                                                            }
                                                            else {
                                                                setCurrentEntity('')
                                                                setConsolidatedDate({
                                                                    ...consolidateddata,
                                                                    le_id: '',
                                                                    legel_entity: '',
                                                                    category: "",
                                                                    user_type: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "",
                                                                    taskCategory: "",
                                                                    domainName: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    actName: '',
                                                                    division_name: '',
                                                                    compliance_frequency_name: 'All',
                                                                    compliance_task_status_name: '',
                                                                    category_name: '',
                                                                    user_type_name: '',
                                                                    task_category_name: '',
                                                                    task_sub_categroy_name: 'All'
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={consolidateddata.legel_entity || undefined}
                                                        style={{ width: '100%', marginTop: '2px' }}

                                                        disabled={consolidateddata.country == '' ? true : false}

                                                    >
                                                        {entity && entity.length && entity.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'legalentity',
                                                        consolidateddata.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div> :
                                                <p>{localStorage.getItem('SelectedEntity')}</p>
                                            }
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Domain : </b>  <span style={{ color: "red" }}>*</span></label>
                                            <div className="form-group">
                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(value, data) => {
                                                        if (value !== undefined) {
                                                            let filteredTaskCategory = _.filter(allData && allData.task_category_list, { task_domain_id: Number(value) })
                                                            setTaskCategoryList(filteredTaskCategory)
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                domain: value,
                                                                domainName: data.children,
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                taskCategory: "",
                                                                unit_name: '',
                                                                user_name: '',
                                                                actName: '',
                                                                division_name: '',
                                                                compliance_frequency_name: 'All',
                                                                compliance_task_status_name: '',
                                                                category_name: '',
                                                                user_type_name: '',
                                                                task_category_name: '',
                                                                task_sub_categroy_name: 'All'
                                                            })
                                                        }
                                                        else {
                                                            setTaskCategoryList([])
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                domain: '',
                                                                domainName: '',
                                                                category: "",
                                                                user_type: "",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "",
                                                                taskCategory: "",
                                                                unit_name: '',
                                                                user_name: '',
                                                                actName: '',
                                                                division_name: '',
                                                                compliance_frequency_name: 'All',
                                                                compliance_task_status_name: '',
                                                                category_name: '',
                                                                user_type_name: '',
                                                                task_category_name: '',
                                                                task_sub_categroy_name: 'All'
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Enter Domain"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={consolidateddata.domainName || undefined}
                                                    disabled={consolidateddata.le_id == '' ? true : false}

                                                >
                                                    {domainList && domainList.length && domainList.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            {validator.current.message(
                                                'domain',
                                                consolidateddata.domain,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Domain Required',
                                                    }
                                                })}
                                        </div>



                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Task Category : </b> <span style={{ color: "red" }}>*</span></label>
                                            <Select allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                onChange={(value, data) => {
                                                    if (value !== undefined) {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            taskCategory: value,
                                                            task_category_name: data.children
                                                        })

                                                        let filteredCategory = _.filter(allData && allData.task_sub_category_list, { task_category_id: Number(value) })
                                                        setTaskSubCategoryList(filteredCategory)
                                                    }
                                                    else {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            taskCategory: '',
                                                            task_category_name: ''
                                                        })


                                                        setTaskSubCategoryList([])
                                                    }
                                                    // let filteredUnit = _.filter(unitLegalEntity, { division_id: Number(value) })
                                                    // setUnitLegalEntity(filteredUnit)
                                                }}
                                                placeholder="Enter Task Category"
                                                style={{ width: '100%', marginTop: '2px' }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                value={consolidateddata.task_category_name ? consolidateddata.task_category_name : '--Select--'}
                                                disabled={consolidateddata.domain == '' ? true : false}

                                            >
                                                {taskCategoryList && taskCategoryList.length && taskCategoryList.map((item, i) => {
                                                    return (
                                                        <Option key={item.task_category_id}>
                                                            {item.task_category_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                            {validator.current.message(
                                                'taskCategory',
                                                consolidateddata.taskCategory,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Task Category Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Task Sub Category : </b> </label>
                                            <div className="form-group">
                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(value, data) => {
                                                        if (value !== undefined) {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                task_sub_category: value,
                                                                task_sub_categroy_name: data.children
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                task_sub_category: '',
                                                                task_sub_categroy_name: ''
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Task Sub Category"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    disabled={consolidateddata.taskCategory == '' ? true : false}
                                                    value={consolidateddata.task_sub_categroy_name || undefined}
                                                >
                                                    <Option key='All'>All</Option>
                                                    {taskSubCategoryList && taskSubCategoryList.length && taskSubCategoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_sub_category_id}>
                                                                {item.task_sub_category_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Category : </b> </label>
                                            <div className="form-group">
                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(value, data) => {
                                                        if (value !== undefined) {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                category: value,
                                                                category_name: data.children
                                                            })
                                                            let filteredUnit = _.filter(unitLegalEntity, { category_id: Number(value) })
                                                            setUnitLegalEntity(filteredUnit)
                                                        }
                                                        else {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                category: '',
                                                                category_name: ''
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Enter Category"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    value={consolidateddata.category_name || undefined}
                                                    disabled={consolidateddata.le_id == '' ? true : false}

                                                >
                                                    {categoryList && categoryList.length && categoryList.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>

                                        <div className='col-md-4'>
                                            <label><b>Unit : </b> </label>
                                            <div className="form-group">
                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(value, data) => {
                                                        if (value !== undefined) {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                unit: value,
                                                                unit_name: data.children
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                unit: '',
                                                                unit_name: ''
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Enter Unit"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={consolidateddata.unit_name || undefined}
                                                    disabled={consolidateddata.le_id == '' ? true : false}

                                                >
                                                    {unitLegalEntity && unitLegalEntity.length && unitLegalEntity.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Compliance Frequency : </b> </label>
                                            <div className="form-group">
                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(value, data) => {
                                                        if (value !== undefined) {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_frequency: value,
                                                                compliance_frequency_name: data.children
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                compliance_frequency: '',
                                                                compliance_frequency_name: ''
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Enter Compliance Frequency"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    value={consolidateddata.compliance_frequency_name || undefined}
                                                    disabled={consolidateddata.le_id == '' ? true : false}

                                                >
                                                    <Option key='All'>All</Option>
                                                    {complianceFrequencyList && complianceFrequencyList.length && complianceFrequencyList.map((item, i) => {
                                                        return (
                                                            <Option key={item.frequency_id}>
                                                                {item.frequency}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Compliance Task : </b> </label>
                                            <div className="form-group">
                                                <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '2px' }}
                                                    // allowClear={true}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(e) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            compliance_task: e.target.value
                                                        })
                                                    }}
                                                    value={consolidateddata.compliance_task || undefined}
                                                    disabled={consolidateddata.le_id == '' ? true : false}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Division : </b> </label>
                                            <div className="form-group">
                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(value, data) => {
                                                        if (value !== undefined) {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                division: value,
                                                                division_name: data.children
                                                            })
                                                            let filteredCategory = _.filter(categoryList, { div_id: Number(value) })
                                                            setCategoryList(filteredCategory)
                                                            let filteredUnit = _.filter(unitLegalEntity, { division_id: Number(value) })
                                                            setUnitLegalEntity(filteredUnit)
                                                        }
                                                        else {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                division: '',
                                                                division_name: ''
                                                            })

                                                            setCategoryList([])
                                                            setUnitLegalEntity([])
                                                        }
                                                    }}
                                                    placeholder="Select Division"
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    disabled={consolidateddata.le_id == '' ? true : false}
                                                    value={consolidateddata.division_name || undefined}
                                                >
                                                    {divisionList && divisionList.length && divisionList.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Act/Rules : </b> </label>
                                            <div className="form-group">
                                                <Select allowClear={true}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(value, data) => {
                                                        if (value !== undefined) {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                act: value,
                                                                actName: data.children
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedDate({
                                                                ...consolidateddata,
                                                                act: '',
                                                                actName: ''
                                                            })
                                                        }
                                                    }}
                                                    placeholder="Enter Act/Rules"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%', marginTop: '2px' }}
                                                    disabled={consolidateddata.le_id == '' ? true : false}
                                                    value={consolidateddata.actName || undefined}
                                                >
                                                    {actLegalEntity && actLegalEntity.length && actLegalEntity.map((item, i) => {
                                                        return (
                                                            <Option key={item.parent_id}>
                                                                {item.statutory_mapping}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='text-center mt-2 popupbtncolour'>
                                    <Button type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')}
                                        icon={<EyeOutlined />} onClick={onshow}
                                    >
                                        Show
                                    </Button>
                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        icon={<ExportOutlined />} onClick={showModal}
                                    >
                                        Export
                                    </Button>
                                </div>

                            </Modal>

                            {tableshow == true ?
                                <Card className='rounded-3'>
                                    <div className='col-md-12 mt-0 mb-2 filter-detial-sticky' >
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :</b> {localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidateddata.legel_entity : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-2'>
                                                <label><b>Country :</b> {consolidateddata.countryName}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label><b>Domain :</b> {consolidateddata.domainName}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label><b>Task Category :</b> {consolidateddata.task_category_name}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content userprivclass1'>
                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className="ant-table-thead table-head-stick" >
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '10px' }}># </th>
                                                                <th className='ant-table-cell' style={{ width: '250px' }}>Compliance Task</th>
                                                                <th className='ant-table-cell' style={{ width: '50px' }}>Criticality</th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}>Frequency</th>
                                                                <th className='ant-table-cell' style={{ width: '140px' }}>User Name</th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>Activity Status</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}>Compliance Task Status</th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>Due Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}><center>Completion Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '150px' }}><center>Document</center></th>

                                                            </tr>
                                                        </thead>
                                                        {filteredArrayState && filteredArrayState.length ?
                                                            filteredArrayState.map((item, i) => {
                                                                return (
                                                                    <tbody className='ant-table-tbody' key={i}>
                                                                        <tr className="bg-lightdark">
                                                                            <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                <div className='bg-white shadow-sm border-radius-reports'>
                                                                                    <div className='col-md-12'>
                                                                                        <div className='row m-1'>
                                                                                            <label><b>{item[0].task_sub_category_name}</b></label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12">
                                                                                        <div className='row m-2'>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Unit :</b> {item[0].unit_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Division :</b> {item[0].division_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Category :</b> {item[0].category_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {titledata && titledata.length > 0 ?
                                                                            titledata.map((itemdata, i) => {
                                                                                let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                                if (data != undefined) {
                                                                                    if (data.act_name == itemdata.parent) {
                                                                                        let list = itemdata.child
                                                                                        let size = 25
                                                                                        const items = list.slice(0, size)
                                                                                        return <Fragment>

                                                                                            <tr className='ant-table-row ant-table-row-level-0'>

                                                                                                <td colSpan={12} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                            </tr>

                                                                                            {itemdata.child.map((items) => {
                                                                                                if (item[0].unit_id == items.unit_id) {
                                                                                                    return (
                                                                                                        // <Card className='rounded-3'>
                                                                                                        <>
                                                                                                            <tr>
                                                                                                                <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                                <td title={items.compliance_description} className='ant-table-cell pointer' onClick={() => { collapse_data(item) }}><Tooltip title={items.compliance_description}>
                                                                                                                    <ExclamationCircleTwoTone />
                                                                                                                </Tooltip> &nbsp;{items.compliance_task}</td>
                                                                                                                <td className='ant-table-cell'>{items.criticality_name}</td>
                                                                                                                <td className='ant-table-cell'>{items.frequency_name}</td>
                                                                                                                <td className='ant-table-cell'>{items.assignee_name}</td>
                                                                                                                <td className='ant-table-cell d-flex align-items-center'>{items.activity_status}
                                                                                                                    {items.history_count != 0 ? <span className="mx-2 bg-primary rounded-circle text-white task-category-table-btn">
                                                                                                                        <Tooltip title='click here to View Activity History'><InfoOutlined onClick={() => { viewactivity_histroy(items) }} />
                                                                                                                        </Tooltip></span> : ''}
                                                                                                                    {items.interim_count != 0 ?
                                                                                                                        <span className="bg-danger rounded-circle text-white task-category-table-btn">
                                                                                                                            <Tooltip title='click here to view Interim Details'>
                                                                                                                                <ArrowUpOutlined onClick={() => { view_interim(items) }} /></Tooltip></span> : ''}
                                                                                                                </td>
                                                                                                                <td className='ant-table-cell'>{items.task_status}</td>
                                                                                                                <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                                <td className='ant-table-cell'><center>{items.completion_date}</center></td>
                                                                                                                <td className='ant-table-cell pointer' onClick={() => { downloaddocument(items) }}><span style={{ color: 'blue' }}>{items.doc_ref_num}</span></td>
                                                                                                                {/* </tr> */}

                                                                                                            </tr>
                                                                                                            <span hidden>{index = index + 1}</span>
                                                                                                        </>
                                                                                                        // </Card>
                                                                                                    )
                                                                                                }

                                                                                            })

                                                                                            }
                                                                                        </Fragment>
                                                                                    }
                                                                                }
                                                                            }) :
                                                                            <h4 style={{ textAlign: 'center', marginTop: '5%' }}>No Records Found</h4>
                                                                        }
                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr>
                                                                <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                            </tr>
                                                        }
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        {index != 0 ?
                                            <div className='col-md-6' >
                                                <label>Showing {pageSize * (current - 1) + 1} to {index} of {tableData && tableData.total_count} entries</label>
                                            </div> : ''}

                                        <div className='col-md-6 text-right'>
                                            <Pagination
                                                current={current}
                                                showSizeChanger={true}
                                                onShowSizeChange={() => {
                                                    setCurrent(1)
                                                }}
                                                pageSizeOptions={[25, 50, 100]} hideOnSinglePage={tableData && tableData.total_count > 25 ? false : true} defaultPageSize={25}
                                                onChange={(page, pageSizes) => {
                                                    setpageState(true)
                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                    setPageSize(pageSizes)
                                                }} total={tableData && tableData.total_count}
                                            />
                                        </div>
                                    </div>

                                </Card>
                                : false
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={historymodal}
                title="Remarks History"
                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                footer={null}
                onCancel={historycancel}>
                <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                    columns={history_columns}
                    dataSource={history_record && history_record.remarks_history}
                    pagination={false}
                />
            </Modal>

            <Modal visible={interimmodal}
                title="Interim Details"
                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                footer={null}
                onCancel={interimcancel}>
                <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                    columns={interim_columns}
                    dataSource={interim_record && interim_record}
                    pagination={false}
                />
            </Modal>

            <Modal visible={collapsemodal}
                title="Task Category Report"
                className={" add-service-prv " + localStorage.getItem('currentTheme')}
                footer={null}
                onCancel={collapsecancel}>
                <Table className={`stats ${localStorage.getItem("currentTheme")}`}
                    columns={collapse_columns}
                    dataSource={collapse_record && collapse_record.tree_data}
                    pagination={false}
                />
            </Modal>
        </div>

    )
}

export default connect(mapStateToProps, {
    getTaskCategoryReport,
    getTaskCategoryData,
    getExportData,
    getdownloaddocument,
    historyrecord,
    interimrecord,
    getcollapsedata
})(TaskCategoryReport);