import React from 'react'
import { Collapse, Select, Input, Card, Drawer, Button, Table, Tooltip, Popover, Modal, Pagination, Progress } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useRef, useEffect, Fragment } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom'
import SimpleReactValidator from "simple-react-validator";
import { connect } from 'react-redux';
import { criticalityfilterlist, criticalityshowlist, criticalityexportlist, showmoredata } from './../../Store/Action/Report/CriticalityReport';
import { ExclamationCircleTwoTone, ExclamationCircleOutlined, PlayCircleOutlined, FullscreenOutlined, UpOutlined, ExportOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../Libs/fullscreenmode';
import ScrollButton from '../Scrollbar';
import { Toaster } from '../../Libs/Toaster';
import url, { getUrl } from './../../Libs/URL'
import { CountrySplit,HomeRedirect } from './../../Libs/country'
const urls = getUrl()
const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    filterdatas: state.CriticalityReport.criticalityreports.filterdata,
    tabledatas: state.CriticalityReport.criticalityreports.tabledata,
    showmoredatas: state.CriticalityReport.criticalityreports.showmorelist
});
const CriticalityReport = ({
    criticalityfilterlist,
    criticalityshowlist,
    criticalityexportlist,
    filterdatas,
    tabledatas,
    showmoredatas

}) => {
    const _ = require("lodash");
    const location = useLocation();
    const paramid = location.pathname;
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [tableshow, setTableShow] = useState(false)
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [legalstate, Setlegalstate] = useState(false)
    const [tabledata, setTableData] = useState([])
    const [titledata, Settitledata] = useState()
    const [entity, setEntity] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [paginationdata, setPaginationdata] = useState([])
    const [finaldata, Setfinaldata] = useState([])
    const [tablecount, setTablecount] = useState([])

    const [show, Setshow] = useState(0)
    const [count, setCount] = useState([])
    const [fcount, setfcount] = useState(0)
    let [index, setindex] = useState(0)
    const [current, setCurrent] = useState(1);

    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [checkboxValue, setCheckBoxValue] = useState([0, 1, 2, 3, 4, 5, 20])
    const [criticalitydata, setCriticalitydDate] = useState({
        country: "",
        category: "",
        legal_entity_id: "",
        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        unit: "",
        domain: "",
        act: "",
        compliance_task: "",
        compliance_frequency: "",
        task_status: "All",
        division: "",
        business_group: "",
        criticality: "",
        country_id: '',
        parent_id: '',
        domain_id: '',
        division_id: '',
        category_id: '',
        unit_id: '',
        criticality_id: '',
        compliance_frequency_id: '',
        business_group_id: '',
        bg_id: '',
        bg_nanme: ''
    })
    const [totaldata, setTotalData] = useState({
        domainlist: [],
        divisionlist: [],
        categorylist: [],
        unitlist: []
    })

    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid').replaceAll('"', ''));
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [getreportlist, setGetReportList] = useState([])
    const [bggroup, setBggroup] = useState([])

    useEffect(() => {
        if (sessionArr && sessionArr.length > 0) {
            let unique = _.uniqBy(sessionArr, function (e) {
                return e.bg_id;
            });
            setBggroup(unique)
        }
    }, [])

    const { Panel } = Collapse;
    const { Option } = Select;
    useEffect(() => {
        if (filterdatas) {
            setGetReportList(filterdatas && filterdatas)
            let Domaindata = _.filter(filterdatas && filterdatas.domains, { le_id: entityid })
            setTotalData({
                ...totaldata,
                domainlist: Domaindata,
                unitlist: filterdatas && filterdatas.units,
                divisionlist: filterdatas && filterdatas.div_infos,
                categorylist: filterdatas && filterdatas.cat_infos
            })
        }
    }, [filterdatas && filterdatas.domains, entityid])
    // useEffect(() => {
    //     if (entityid != "null") {
    //         setCriticalitydDate({
    //             ...criticalitydata,
    //             country_id: sessionArr[0].c_id,
    //             country: sessionArr[0].c_name
    //         })
    //     }
    // }, [entityid != "null"])

    useEffect(() => {
        if (localStorage.getItem("SelectedEntity") !== "All Legal Entity") {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setCriticalitydDate({
                ...criticalitydata,
                country_id: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }
    }, [localStorage.getItem("SelectedEntity") !== "All Legal Entity"])
    useEffect(() => {
        if (criticalitydata.legal_entity_id == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [criticalitydata.legal_entity_id])
    useEffect(() => {
        if (criticalitydata.legal_entity_name == localStorage.getItem('SelectedEntity')) {
            Setlegalstate(true)
        }
    }, [criticalitydata.legal_entity_name])

    const contents = (items) => {
        return <table style={{ width: "80px", height: '100px' }} class="table table-striped" >
            <tbody>
                <tc>
                    <th>Concurrer</th>
                    <td><b>{items.concurrence_person}</b></td>
                </tc>
                <tc>
                    <th>Approver</th>
                    <td><b>{items.approval_person}</b></td>
                </tc>
            </tbody>
        </table>
    };

    useEffect(() => {
        if (tabledata && tabledata && tabledata.length > 0) {
            let uniqueObjArray = [...new Map(tabledata && tabledata.length && tabledata.map((item) => [item["unit_id"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(tabledata && tabledata && tabledata.length && tabledata, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])
        }
    }, [tabledata])

    // useEffect(() => {
    //     if (filteredArray.length) {
    //         let temp = []
    //         for (let i in filteredArray) {

    //             temp = filteredArray[i]
    //         }
    //         setfilteredArrayState([...filteredArrayState, ...temp])
    //     }

    // }, [filteredArray])
    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (tablecount && tablecount.length > 0) {

            setfcount(tablecount && tablecount.length)
        }
    }, [tablecount, fcount])
    useEffect(() => {
        if (tabledatas) {
            setTableData(tabledatas && tabledatas.criticality_data_list)
        }
        if (tabledatas.total_count) {
            setCount(tabledata && tabledatas.total_count)
        }
        if (showmoredatas) {
            setPaginationdata(showmoredatas && showmoredatas.criticality_data_list)
        }
    }, [tabledatas, tabledatas.criticality_data_list, tabledatas.total_count, count, showmoredatas])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != "null") {
            setCurrentEntity(localStorage.getItem('SelectedEntityid').replaceAll('"', ''))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    useEffect(() => {
        let temp = []
        if (paginationdata && paginationdata.length > 0) {
            for (let i in paginationdata) {
                temp.push(paginationdata[i])
            }
            Setfinaldata([...temp]);
        }
    }, [paginationdata])
    useEffect(() => {
        let temp = [];
        if (tabledata && tabledata.length > 0) {
            for (let i in tabledata) {
                temp.push(tabledata[i])
            }
            setTablecount([...temp]);
        }
    }, [tabledata])

    useEffect(() => {
        if (isAuth) {
            if (legalstate === true) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            'GetCriticalityFilters', {
                                'le_id': entityid
                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    criticalityfilterlist({
                        payload: payload,
                        paramid: paramid
                    })
                }
                Setlegalstate(false)
            }
        }
    }, [isAuth, entityid, legalstate])

    const onshow = () => {
        setfilteredArrayState([])
        setAddFormSubmit(true)
        setCurrent(1);
        setpageState(false)
        setPageSize(25)
        setindex(0)
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setindex(0);
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        'GetCriticalityData', {
                            'c_id': Number(criticalitydata.country_id),
                            'bg_id': criticalitydata.bg_id ? Number(criticalitydata.bg_id) : null,
                            'le_id': entityid,
                            'd_id': Number(criticalitydata.domain_id),
                            'unit_id': criticalitydata.unit_id ? Number(criticalitydata.unit_id) : null,
                            'div_id': criticalitydata.division_id ? Number(criticalitydata.division_id) : null,
                            'cat_id': criticalitydata.category_id ? Number(criticalitydata.category_id) : null,
                            'parent_id': criticalitydata.parent_id ? Number(criticalitydata.parent_id) : 0,
                            'compliance_task': criticalitydata.compliance_task ? criticalitydata.compliance_task : null,
                            'frequency_id': criticalitydata.compliance_frequency_id ? Number(criticalitydata.compliance_frequency_id) : 0,
                            'status_name': criticalitydata.task_status ? criticalitydata.task_status : "ALL",
                            'criticality_id': criticalitydata.criticality_id ? Number(criticalitydata.criticality_id) : 0,
                            'csv': false,
                            'f_count': 1,
                            't_count': 25,
                            'count_qry': true,
                            'list_check': null
                        }
                    ]
                }
            ]
            criticalityshowlist({
                payload: payload1,
                paramid: paramid
            })
            Setshow(fcount + 1)
        }
    }

    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    const handleOk = () => {
        setAddFormSubmit(true)
        setIsModalOpen(true);
        setCheckBoxValue([0, 1, 2, 3, 4, 5, 20])
        const payload2 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    'GetCriticalityData', {
                        'c_id': Number(criticalitydata.country_id),
                        'bg_id': criticalitydata.bg_id ? Number(criticalitydata.bg_id) : null,
                        'le_id': entityid,
                        'd_id': Number(criticalitydata.domain_id),
                        'unit_id': criticalitydata.unit_id ? Number(criticalitydata.unit_id) : null,
                        'div_id': criticalitydata.division_id ? Number(criticalitydata.division_id) : null,
                        'cat_id': criticalitydata.category_id ? Number(criticalitydata.category_id) : null,
                        'parent_id': criticalitydata.parent_id ? Number(criticalitydata.parent_id) : 0,
                        'compliance_task': criticalitydata.compliance_task ? criticalitydata.compliance_task : null,
                        'frequency_id': criticalitydata.compliance_frequency_id ? Number(criticalitydata.compliance_frequency_id) : 0,
                        'status_name': criticalitydata.task_status ? criticalitydata.task_status : "ALL",
                        'criticality_id': criticalitydata.criticality_id ? Number(criticalitydata.criticality_id) : 0,
                        'csv': true,
                        'f_count': 1,
                        't_count': 25,
                        'count_qry': true,
                        'list_check': checkboxValue
                    }
                ]

            }

        ]
        criticalityexportlist({
            payload: payload2,
            paramid: paramid
        })
        setIsModalOpen(false);
    };
    useEffect(() => {
        if (pageState == true) {
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        'GetCriticalityData', {
                            'c_id': Number(criticalitydata.country_id),
                            'bg_id': criticalitydata.bg_id ? Number(criticalitydata.bg_id) : null,
                            'le_id': entityid,
                            'd_id': Number(criticalitydata.domain_id),
                            'unit_id': criticalitydata.unit_id ? Number(criticalitydata.unit_id) : null,
                            'div_id': criticalitydata.division_id ? Number(criticalitydata.division_id) : null,
                            'cat_id': criticalitydata.category_id ? Number(criticalitydata.category_id) : null,
                            'parent_id': criticalitydata.parent_id ? Number(criticalitydata.parent_id) : 0,
                            'compliance_task': criticalitydata.compliance_task ? criticalitydata.compliance_task : null,
                            'frequency_id': criticalitydata.compliance_frequency_id ? Number(criticalitydata.compliance_frequency_id) : 0,
                            'status_name': criticalitydata.task_status ? criticalitydata.task_status : "ALL",
                            'criticality_id': criticalitydata.criticality_id ? Number(criticalitydata.criticality_id) : 0,
                            'csv': false,
                            'f_count': Math.max(((current - 1) * pageSize), 0) + 1,
                            't_count': current != 0 ? current * pageSize : pageSize,
                            'count_qry': true,
                            'list_check': null
                        }
                    ]
                }
            ]
            criticalityshowlist({
                payload: payload1,
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])


    useEffect(() => {

        let array = [];
        let finalarray = [];

        let datavalue = [];
        if (tabledata && tabledata.length && tabledata) {
            const ab = tabledata && tabledata.length && tabledata.map(function (item) {
                if ((array.indexOf(item.act_name) < 0))
                    array.push(item.act_name)
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    tabledata && tabledata.length && tabledata.map((child, childIndex) => {
                        if (data === child.act_name) {
                            entityArray = {
                                "act_name": child.act_name,
                                "approval_person": child.approval_person,
                                "assignee_name": child.assignee_name,
                                "category_name": child.category_name,
                                "client_criticality": child.client_criticality,
                                "compliance_description": child.compliance_description,
                                "compliance_id": child.compliance_id,
                                "compliance_task": child.compliance_task,
                                "division_name": child.division_name,
                                "document_name": child.document_name,
                                "download_url": child.download_url,
                                "due_date": child.due_date,
                                "frequency": child.frequency,
                                "know_criticality": child.know_criticality,
                                "penal_consequences": child.penal_consequences,
                                "task_status": child.task_status,
                                "tz_name": child.tz_name,
                                "unit": child.unit,
                                "unit_address": child.unit_address,
                                "unit_id": child.unit_id,
                                'childId': childIndex,
                                'parentId': i
                            }
                            temp.push(entityArray)
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)

        }

    }, [tabledata && tabledata.length && tabledata])

    useEffect(() => {
        if (pageState === true) {
            setindex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const onChange = (key) => {

    };
    const onStatChange = (key) => {
        console.log(key);
    }
    const handleCancel = () => {
        setIsModalOpen(false);
        setCheckBoxValue([0, 1, 2, 3, 4, 5, 20])
    };
    const checkBoxOnChange = (e) => {
        let tempIds = []
        let temp = checkboxValue
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([0, 1, 2, 3, 4, 5, 20])
        }
    }

    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };

    //  Full screen mode //
    const [fullscreen, setfullscreen] = useState(false);

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    return (
        <div id='page-wrapper' className="page-wrapper">
            {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} /> */}
            {document.body.scrollHeight > document.body.clientHeight ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''}
            <div className="page-titles pb-2 pt-1 page-title-sticky">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Report</span>
                                </li>

                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Criticality Reports</span>
                                </li>
                            </ol>
                        </nav>

                    </div>
                    <div className="col-lg-4 text-end">

                        <Tooltip title="Advance Filter"><Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button></Tooltip>

                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <Drawer title="Select Fields" className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel} width={600}>
                <div className='d-flex flex-wrap'>
                    <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                        checked={checkboxValue.length === 20 ? true : false}
                        onClick={(e) => {
                            let checked = e.target.checked
                            selectAllCheckBox(checked)
                        }} /><label for='select_all'> Select All</label>

                    <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /><label for='column_1'> Business Group</label>
                    <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'>Legal Entity</label>
                    <input id="column_6" name='chk' type="checkbox" value='3' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'> Unit Code</label>
                    <input id="column_7" name='chk' type="checkbox" value='4' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'> Unit Name</label>
                    <input id="column_8" name='chk' type="checkbox" value='5' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_8'>Location</label>
                    <input id="column_9" name='chk' type="checkbox" checked={checkboxValue.includes(6)} value='6' onChange={(e) => checkBoxOnChange(e)} /><label for='column_9'> Division</label>
                    <input id="column_10" name='chk' type="checkbox" checked={checkboxValue.includes(7)} value='7' onChange={(e) => checkBoxOnChange(e)} /><label for='column_10'>Category</label>
                    <input id="column_29" name='chk' type="checkbox" checked={checkboxValue.includes(8)} value='8' onChange={(e) => checkBoxOnChange(e)} /><label for='column_29'> Act</label>
                    <input id="column_32" name='chk' type="checkbox" checked={checkboxValue.includes(9)} value='9' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_32'>Status</label>
                    <input id="column_3" name='chk' type="checkbox" checked={checkboxValue.includes(10)} value='10' onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'> Compliance Task</label>
                    <input id="column_4" name='chk' type="checkbox" checked={checkboxValue.includes(11)} value='11' onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'> Penal Consequences</label>
                    <input id="column_5" name='chk' type="checkbox" checked={checkboxValue.includes(12)} value='12' onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'> Frequency</label>
                    <input id="column_14" name='chk' type="checkbox" checked={checkboxValue.includes(13)} value='13' onChange={(e) => checkBoxOnChange(e)} /><label for='column_14'> Criticality</label>
                    <input id="column_15" name='chk' type="checkbox" checked={checkboxValue.includes(14)} value='14' onChange={(e) => checkBoxOnChange(e)} /><label for='column_15'> Periodicaly</label>
                    <input id="column_16" name='chk' type="checkbox" checked={checkboxValue.includes(15)} value='15' onChange={(e) => checkBoxOnChange(e)} /><label for='column_16'> Assignee</label>
                    <input id="column_17" name='chk' type="checkbox" checked={checkboxValue.includes(16)} value='16' onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'> Concurrer</label>
                    <input id="column_18" name='chk' type="checkbox" checked={checkboxValue.includes(17)} value='17' onChange={(e) => checkBoxOnChange(e)} /><label for='column_18'> Approver</label>
                    <input id="column_19" name='chk' type="checkbox" checked={checkboxValue.includes(18)} value='18' onChange={(e) => checkBoxOnChange(e)} /><label for='column_19'> Due Date</label>
                    <input id="column_20" name='chk' type="checkbox" checked={checkboxValue.includes(19)} value='19' onChange={(e) => checkBoxOnChange(e)} /><label for='column_20'> Task Completion Date / Document Issued Date</label>
                    <input id="column_21" name='chk' type="checkbox" value='20' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /><label for='column_21'> Remarks</label>
                </div>
                <div className="form-actions popupbtncolour text-center">
                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                </div>

            </Drawer>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" id='only-restricted' style={{ position: 'relative', padding: "10px 3px" }}>
                            <div className={localStorage.getItem('currentTheme')}>
                                <Collapse className='report-collapse '
                                    style={{ display: exitCollapse ? "none" : "block" }} defaultActiveKey={["1"]} onChange={onChange} >
                                    <Panel header=" Criticality Report" key={key} extra={tableshow == true ? '' : false}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Country :</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    placeholder='Enter Country'
                                                                    allowClear={true}
                                                                    size="default"
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                        return false;
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        handlePasting(e, /^[a-z]+$/gi)
                                                                    }}
                                                                    onChange={(value, data) => {
                                                                        if (data !== undefined) {
                                                                            let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                            setEntity(le)
                                                                        }
                                                                        setCriticalitydDate({
                                                                            ...criticalitydata,
                                                                            country_id: value ? value : '',
                                                                            country: data ? data.children : '',
                                                                            category: "",
                                                                            legal_entity_id: "",
                                                                            legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                            unit: "",
                                                                            domain: "",
                                                                            act: "",
                                                                            compliance_task: "",
                                                                            compliance_frequency: "",
                                                                            task_status: "",
                                                                            division: "",
                                                                            business_group: "",
                                                                            criticality: "",
                                                                            parent_id: '',
                                                                            domain_id: '',
                                                                            division_id: '',
                                                                            category_id: '',
                                                                            unit_id: '',
                                                                            criticality_id: '',
                                                                            compliance_frequency_id: '',
                                                                            business_group_id: '',
                                                                            bg_id: '',
                                                                            bg_nanme: ''
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%', marginTop: '3px' }}
                                                                    value={criticalitydata.country || undefined}
                                                                >
                                                                    {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.c_id}>
                                                                                {item.c_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'country',
                                                                    criticalitydata.country,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Country Required',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{criticalitydata.country}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity :</b> <span style={{ color: "red" }}>*</span></label>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Legal Entity"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                                }}
                                                                onChange={(data, value) => {
                                                                    Setlegalstate(true)
                                                                    data && setCurrentEntity(data)
                                                                    setCriticalitydDate({
                                                                        ...criticalitydata,
                                                                        legal_entity_id: data ? data : '',
                                                                        legal_entity_name: value ? value.children : '',
                                                                        category: "",
                                                                        unit: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        compliance_task: "",
                                                                        compliance_frequency: "",
                                                                        task_status: "",
                                                                        division: "",
                                                                        business_group: "",
                                                                        criticality: "",
                                                                        parent_id: '',
                                                                        domain_id: '',
                                                                        division_id: '',
                                                                        category_id: '',
                                                                        unit_id: '',
                                                                        criticality_id: '',
                                                                        compliance_frequency_id: '',
                                                                        business_group_id: '',
                                                                        bg_id: '',
                                                                        bg_nanme: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%', marginTop: '5px' }}

                                                                value={criticalitydata.legal_entity_name || undefined}
                                                                disabled={criticalitydata.country == '' ? true : false}

                                                            >
                                                                {entity && entity.length && entity.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legal_entity_id',
                                                                criticalitydata.legal_entity_name,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Legal Entity Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Domain"
                                                        value={criticalitydata.domain || undefined}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z - .]+$/gi)
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        disabled={criticalitydata.legal_entity_name == '' ? true : false}


                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onChange={(data, value) => {

                                                            let division = _.filter(filterdatas && filterdatas.div_infos, { le_id: entityid })
                                                            setTotalData({
                                                                ...totaldata,
                                                                divisionlist: division
                                                            })

                                                            setCriticalitydDate({
                                                                ...criticalitydata,
                                                                domain_id: data ? data : '',
                                                                domain: value ? value.children : '',
                                                                category: "",
                                                                unit: "",
                                                                act: "",
                                                                compliance_task: "",
                                                                compliance_frequency: "",
                                                                task_status: "",
                                                                division: "",
                                                                business_group: "",
                                                                criticality: "",
                                                                parent_id: '',
                                                                division_id: '',
                                                                category_id: '',
                                                                unit_id: '',
                                                                criticality_id: '',
                                                                compliance_frequency_id: '',
                                                                business_group_id: '',
                                                                bg_id: '',
                                                                bg_nanme: ''
                                                            })
                                                        }}>
                                                        {totaldata.domainlist && totaldata.domainlist.length && totaldata.domainlist.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                        {/* {filterdatas.domains && filterdatas.domains.length > 0 &&
                                                            filterdatas.domains.map((item) => (

                                                                <Option key={item.d_id}>{item.d_name}</Option>
                                                            ))} */}
                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        criticalitydata.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Domain Required',
                                                            }
                                                        })}

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Business Group :</b></label>

                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Entry Business Group"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z 0-9]+$/gi)
                                                                }} onChange={(data, value) => {

                                                                    setCriticalitydDate({
                                                                        ...criticalitydata,
                                                                        bg_id: data ? data : '',
                                                                        bg_name: value ? value.children : ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //  value={userDetails.legal_entity_id}
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                            >
                                                                {bggroup && bggroup.length && bggroup.map((item, i) => {

                                                                    if (item.bg_name !== null) {
                                                                        return (
                                                                            <Option key={item.bg_id}>
                                                                                {item.bg_name}
                                                                            </Option>
                                                                        );
                                                                    }
                                                                })}
                                                            </Select>

                                                        </div> :
                                                        <p> - </p>
                                                    }


                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division :</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Division"
                                                        value={criticalitydata.division || undefined}
                                                        disabled={criticalitydata.domain == '' ? true : false}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9]+$/gi)
                                                        }}
                                                        // onClick={() => {
                                                        //     if (criticalitydata.domain_id == null) {

                                                        //         toast.warning("Domain Required")
                                                        //     }
                                                        // }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onChange={(data, value) => {

                                                            let category = _.filter(filterdatas && filterdatas.cat_infos, { div_id: Number(data) })

                                                            setTotalData({
                                                                ...totaldata,
                                                                categorylist: category
                                                            })
                                                            setCriticalitydDate({
                                                                ...criticalitydata,
                                                                division_id: data ? data : '',
                                                                division: value ? value.children : ''

                                                            })
                                                        }}

                                                        style={{ width: '100%', marginTop: '3px' }}
                                                    >
                                                        {totaldata.divisionlist && totaldata.divisionlist.length && totaldata.divisionlist.map((item, i) => {
                                                            return (

                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>

                                                            );
                                                        })}


                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Category :</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Category"
                                                        value={criticalitydata.category || undefined}
                                                        disabled={criticalitydata.domain == '' ? true : false}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9]+$/gi)
                                                        }}
                                                        // onClick={() => {
                                                        //     if (criticalitydata.domain_id == null) {

                                                        //         toast.warning("Domain Required")
                                                        //     }
                                                        // }}
                                                        onChange={(data, value) => {
                                                            let unit = _.filter(filterdatas && filterdatas.units, { category_id: Number(data) })
                                                            setTotalData({
                                                                ...totaldata,
                                                                unitlist: unit
                                                            })
                                                            setCriticalitydDate({
                                                                ...criticalitydata,
                                                                category_id: data ? data : '',
                                                                category: value ? value.children : ''

                                                            })
                                                        }}

                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '3px' }}

                                                    >
                                                        {totaldata.categorylist && totaldata.categorylist.length && totaldata.categorylist.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                        {/* {filterdatas.cat_infos && filterdatas.cat_infos.length > 0 &&
                                                            filterdatas.cat_infos.map((item) => (

                                                        <Option key={item.cat_id}>{item.cat_name}</Option>
                                                            ))} */}


                                                    </Select>

                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Frequency :</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Compliance Frequency"
                                                        defaultValue="All"
                                                        onChange={(data, value) => {

                                                            setCriticalitydDate({
                                                                ...criticalitydata,
                                                                compliance_frequency_id: data ? data : '',
                                                                compliance_frequency: value ? value.children : ''

                                                            })
                                                        }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}

                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '3px' }}

                                                    >
                                                        <Option key={0}>All</Option>
                                                        {filterdatas.compliance_frequency && filterdatas.compliance_frequency.length > 0 &&
                                                            filterdatas.compliance_frequency.map((item) => (

                                                                <Option key={item.frequency_id}>{item.frequency}</Option>
                                                            ))}

                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Unit :</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Unit"
                                                        value={criticalitydata.unit || undefined}
                                                        disabled={criticalitydata.domain_id == "" ? true : false}
                                                        // onClick={() => {
                                                        //     if (criticalitydata.domain_id == null) {

                                                        //         toast.warning("Domain Required")
                                                        //     }
                                                        // }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        }}
                                                        onChange={(data, value) => {

                                                            setCriticalitydDate({
                                                                ...criticalitydata,
                                                                unit_id: data ? data : '',
                                                                unit: value ? value.children : ''


                                                            })
                                                        }}

                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                    >
                                                        {totaldata.unitlist && totaldata.unitlist.length && totaldata.unitlist.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                        {/* {filterdatas.units && filterdatas.units.length > 0 &&
                                                            filterdatas.units.map((item) => (

                                                                <Option key={item.unit_id}>{item.unit_name}</Option>
                                                            ))} */}
                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label> <b>Task Status :</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Task Status"
                                                        defaultValue="All"
                                                        value={criticalitydata.task_status || undefined}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onChange={(value) => {
                                                            setCriticalitydDate({
                                                                ...criticalitydata,
                                                                task_status: value ? value : ''
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                    >
                                                        <Option key="All">All</Option>
                                                        <Option key="Assigned">Assigned</Option>
                                                        <Option key="Un-Assigned">Un-Assigned</Option>
                                                        <Option key="Not Opted">Not Opted</Option>
                                                        <Option key="Not Applicable">Not Applicable</Option>

                                                    </Select>

                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "5px" }}>
                                            <div className='row'>

                                                <div className='col-md-4'>
                                                    <label><b>Act :</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Act"
                                                        value={criticalitydata.act || undefined}
                                                        disabled={criticalitydata.domain == '' ? true : false}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9 - . ()]+$/gi)
                                                        }}
                                                        onChange={(data, value) => {

                                                            setCriticalitydDate({
                                                                ...criticalitydata,
                                                                parent_id: data ? data : '',
                                                                act: value ? value.children : ''
                                                            })
                                                        }}

                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '5px' }}

                                                    >
                                                        {filterdatas.acts && filterdatas.acts.length > 0 &&
                                                            filterdatas.acts.map((item) => (

                                                                <Option key={item.parent_id}>{item.act}</Option>
                                                            ))}

                                                    </Select>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Criticality :</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Criticality"
                                                        defaultValue="All"
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onChange={(data, value) => {

                                                            setCriticalitydDate({
                                                                ...criticalitydata,
                                                                criticality_id: data ? data : '',
                                                                criticality: value ? value.children : ''

                                                            })
                                                        }}


                                                    >
                                                        <Option key={0}>All</Option>
                                                        {filterdatas.compliance_criticality && filterdatas.compliance_criticality.length > 0 &&
                                                            filterdatas.compliance_criticality.map((item) => (

                                                                <Option key={item.criticality_id}>{item.criticality_name}</Option>
                                                            ))}

                                                    </Select>

                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Task :</b></label>
                                                    <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '5px' }}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9 - . ()]+$/gi)
                                                        }}
                                                        onChange={(e) => {
                                                            setCriticalitydDate({
                                                                ...criticalitydata,
                                                                compliance_task: e.target.value
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center mt-1 popupbtncolour'>
                                            <Button type="primary" icon={<EyeOutlined />} shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                            >
                                                Show
                                            </Button>
                                            <Button type="primary" shape="round" className={'mx-1 addbutton ' + localStorage.getItem('currentTheme')} icon={<ExportOutlined />} onClick={showModal}
                                            >
                                                Export
                                            </Button>

                                        </div>



                                    </Panel>
                                </Collapse>
                                <Modal
                                    onCancel={setcancelFilter}
                                    footer={false}
                                    title="Criticality Reports"
                                    className={
                                        "add-service-prv cons-report " +
                                        localStorage.getItem("currentTheme")
                                    }
                                    visible={filterModalVisible}
                                >

                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country :</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                placeholder='Enter Country'
                                                                allowClear={true}
                                                                size="default"
                                                                onDrop={(e) => {
                                                                    e.preventDefault();
                                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                    return false;
                                                                }}
                                                                onPaste={(e) => {
                                                                    handlePasting(e, /^[a-z]+$/gi)
                                                                }}
                                                                onChange={(value, data) => {
                                                                    if (data !== undefined) {
                                                                        let le = _.filter(sessionArr, { c_id: Number(value) })
                                                                        setEntity(le)
                                                                    }
                                                                    setCriticalitydDate({
                                                                        ...criticalitydata,
                                                                        country_id: value ? value : '',
                                                                        country: data ? data.children : '',
                                                                        category: "",
                                                                        legal_entity_id: "",
                                                                        legal_entity_name: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
                                                                        unit: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        compliance_task: "",
                                                                        compliance_frequency: "",
                                                                        task_status: "",
                                                                        division: "",
                                                                        business_group: "",
                                                                        criticality: "",
                                                                        parent_id: '',
                                                                        domain_id: '',
                                                                        division_id: '',
                                                                        category_id: '',
                                                                        unit_id: '',
                                                                        criticality_id: '',
                                                                        compliance_frequency_id: '',
                                                                        business_group_id: '',
                                                                        bg_id: '',
                                                                        bg_nanme: ''
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                style={{ width: '100%', marginTop: '3px' }}
                                                                value={criticalitydata.country || undefined}
                                                            >
                                                                {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.c_id}>
                                                                            {item.c_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'country',
                                                                criticalitydata.country,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{criticalitydata.country}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :</b> <span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Legal Entity"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                            }}
                                                            onChange={(data, value) => {
                                                                Setlegalstate(true)
                                                                data && setCurrentEntity(data)
                                                                setCriticalitydDate({
                                                                    ...criticalitydata,
                                                                    legal_entity_id: data ? data : '',
                                                                    legal_entity_name: value ? value.children : '',
                                                                    category: "",
                                                                    unit: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    compliance_task: "",
                                                                    compliance_frequency: "",
                                                                    task_status: "",
                                                                    division: "",
                                                                    business_group: "",
                                                                    criticality: "",
                                                                    parent_id: '',
                                                                    domain_id: '',
                                                                    division_id: '',
                                                                    category_id: '',
                                                                    unit_id: '',
                                                                    criticality_id: '',
                                                                    compliance_frequency_id: '',
                                                                    business_group_id: '',
                                                                    bg_id: '',
                                                                    bg_nanme: ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            value={criticalitydata.legal_entity_name || undefined}
                                                            disabled={criticalitydata.country == '' ? true : false}

                                                        >
                                                            {entity && entity.length && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legal_entity_id',
                                                            criticalitydata.legal_entity_name,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b><span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Domain"
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z - .]+$/gi)
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }} value={criticalitydata.domain || undefined}
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                    disabled={criticalitydata.legal_entity_name == '' ? true : false}
                                                    getPopupContainer={trigger => trigger.parentNode}

                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(data, value) => {

                                                        let division = _.filter(filterdatas && filterdatas.div_infos, { le_id: entityid })
                                                        setTotalData({
                                                            ...totaldata,
                                                            divisionlist: division
                                                        })

                                                        setCriticalitydDate({
                                                            ...criticalitydata,
                                                            domain_id: data ? data : '',
                                                            domain: value ? value.children : '',
                                                            category: "",
                                                            unit: "",
                                                            act: "",
                                                            compliance_task: "",
                                                            compliance_frequency: "",
                                                            task_status: "",
                                                            division: "",
                                                            business_group: "",
                                                            criticality: "",
                                                            parent_id: '',
                                                            division_id: '',
                                                            category_id: '',
                                                            unit_id: '',
                                                            criticality_id: '',
                                                            compliance_frequency_id: '',
                                                            business_group_id: '',
                                                            bg_id: '',
                                                            bg_nanme: ''
                                                        })
                                                    }}>
                                                    {totaldata.domainlist && totaldata.domainlist.length && totaldata.domainlist.map((item, i) => {
                                                        return (
                                                            // data.unit ?
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                            // :""
                                                        );
                                                    })}
                                                    {/* {filterdatas.domains && filterdatas.domains.length > 0 &&
                                                            filterdatas.domains.map((item) => (

                                                                <Option key={item.d_id}>{item.d_name}</Option>
                                                            ))} */}
                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    criticalitydata.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Business Group :</b></label>

                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Entry Business Group"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9]+$/gi)
                                                            }} onChange={(data, value) => {

                                                                setCriticalitydDate({
                                                                    ...criticalitydata,
                                                                    bg_id: data ? data : '',
                                                                    bg_name: value ? value.children : ''
                                                                })
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            style={{ width: '100%', marginTop: '3px' }}
                                                        >
                                                            {bggroup && bggroup.length && bggroup.map((item, i) => {

                                                                if (item.bg_name !== null) {
                                                                    return (
                                                                        <Option key={item.bg_id}>
                                                                            {item.bg_name}
                                                                        </Option>
                                                                    );
                                                                }
                                                            })}
                                                        </Select>

                                                    </div> :
                                                    <p> - </p>
                                                }


                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Division :</b></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Division"
                                                    value={criticalitydata.division || undefined}
                                                    disabled={criticalitydata.domain == '' ? true : false}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9]+$/gi)
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onChange={(data, value) => {

                                                        let category = _.filter(filterdatas && filterdatas.cat_infos, { div_id: Number(data) })

                                                        setTotalData({
                                                            ...totaldata,
                                                            categorylist: category
                                                        })
                                                        setCriticalitydDate({
                                                            ...criticalitydata,
                                                            division_id: data ? data : '',
                                                            division: value ? value.children : ''

                                                        })
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}

                                                    style={{ width: '100%', marginTop: '3px' }}
                                                >
                                                    {totaldata.divisionlist && totaldata.divisionlist.length && totaldata.divisionlist.map((item, i) => {
                                                        return (

                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>

                                                        );
                                                    })}


                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Category :</b></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Category"
                                                    value={criticalitydata.category || undefined}
                                                    disabled={criticalitydata.domain == '' ? true : false}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9]+$/gi)
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(data, value) => {
                                                        let unit = _.filter(filterdatas && filterdatas.units, { category_id: Number(data) })
                                                        setTotalData({
                                                            ...totaldata,
                                                            unitlist: unit
                                                        })
                                                        setCriticalitydDate({
                                                            ...criticalitydata,
                                                            category_id: data ? data : '',
                                                            category: value ? value.children : ''

                                                        })
                                                    }}

                                                    style={{ width: '100%', marginTop: '3px' }}

                                                >
                                                    {totaldata.categorylist && totaldata.categorylist.length && totaldata.categorylist.map((item, i) => {
                                                        return (
                                                            // data.unit ?
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                            // :""
                                                        );
                                                    })}
                                                    {/* {filterdatas.cat_infos && filterdatas.cat_infos.length > 0 &&
                                                            filterdatas.cat_infos.map((item) => (

                                                        <Option key={item.cat_id}>{item.cat_name}</Option>
                                                            ))} */}


                                                </Select>

                                            </div>


                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Frequency :</b></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Compliance Frequency"
                                                    defaultValue="All"
                                                    onChange={(data, value) => {

                                                        setCriticalitydDate({
                                                            ...criticalitydata,
                                                            compliance_frequency_id: data ? data : '',
                                                            compliance_frequency: value ? value.children : ''

                                                        })
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    style={{ width: '100%', marginTop: '3px' }}

                                                >
                                                    <Option key={0}>All</Option>
                                                    {filterdatas.compliance_frequency && filterdatas.compliance_frequency.length > 0 &&
                                                        filterdatas.compliance_frequency.map((item) => (

                                                            <Option key={item.frequency_id}>{item.frequency}</Option>
                                                        ))}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Unit :</b></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Unit"
                                                    value={criticalitydata.unit || undefined}
                                                    disabled={criticalitydata.domain_id == "" ? true : false}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                    }}
                                                    onChange={(data, value) => {

                                                        setCriticalitydDate({
                                                            ...criticalitydata,
                                                            unit_id: data ? data : '',
                                                            unit: value ? value.children : ''


                                                        })
                                                    }}

                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    {totaldata.unitlist && totaldata.unitlist.length && totaldata.unitlist.map((item, i) => {
                                                        return (
                                                            // data.unit ?
                                                            <Option key={item.unit_id}>
                                                                {item.unit_name}
                                                            </Option>
                                                            // :""
                                                        );
                                                    })}
                                                    {/* {filterdatas.units && filterdatas.units.length > 0 &&
                                                            filterdatas.units.map((item) => (

                                                                <Option key={item.unit_id}>{item.unit_name}</Option>
                                                            ))} */}
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label> <b>Task Status :</b></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Task Status"
                                                    defaultValue="All"
                                                    value={criticalitydata.task_status || undefined}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onChange={(value) => {
                                                        setCriticalitydDate({
                                                            ...criticalitydata,
                                                            task_status: value ? value : ''
                                                        })
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    <Option key="All">All</Option>
                                                    <Option key="Assigned">Assigned</Option>
                                                    <Option key="Un-Assigned">Un-Assigned</Option>
                                                    <Option key="Not Opted">Not Opted</Option>
                                                    <Option key="Not Applicable">Not Applicable</Option>

                                                </Select>

                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>

                                            <div className='col-md-4'>
                                                <label><b>Act :</b></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Act"
                                                    value={criticalitydata.act || undefined}
                                                    disabled={criticalitydata.domain == '' ? true : false}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9 - . ()]+$/gi)
                                                    }}
                                                    onChange={(data, value) => {

                                                        setCriticalitydDate({
                                                            ...criticalitydata,
                                                            parent_id: data ? data : '',
                                                            act: value ? value.children : ''
                                                        })
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    style={{ width: '100%', marginTop: '5px' }}

                                                >
                                                    {filterdatas.acts && filterdatas.acts.length > 0 &&
                                                        filterdatas.acts.map((item) => (

                                                            <Option key={item.parent_id}>{item.act}</Option>
                                                        ))}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Criticality :</b></label>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Criticality"
                                                    defaultValue="All"
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onChange={(data, value) => {

                                                        setCriticalitydDate({
                                                            ...criticalitydata,
                                                            criticality_id: data ? data : '',
                                                            criticality: value ? value.children : ''

                                                        })
                                                    }}

                                                    getPopupContainer={trigger => trigger.parentNode}
                                                >
                                                    <Option key={0}>All</Option>
                                                    {filterdatas.compliance_criticality && filterdatas.compliance_criticality.length > 0 &&
                                                        filterdatas.compliance_criticality.map((item) => (

                                                            <Option key={item.criticality_id}>{item.criticality_name}</Option>
                                                        ))}

                                                </Select>

                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task :</b></label>
                                                <Input placeholder="Enter Compliance Task" style={{ width: '100%', marginTop: '5px' }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9 - . ()]+$/gi)
                                                    }}
                                                    onChange={(e) => {
                                                        setCriticalitydDate({
                                                            ...criticalitydata,
                                                            compliance_task: e.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center mt-1 popupbtncolour'>
                                        <Button type="primary" icon={<EyeOutlined />} shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                        >
                                            Show
                                        </Button>
                                        <Button type="primary" shape="round" className={'mx-1 addbutton ' + localStorage.getItem('currentTheme')} icon={<ExportOutlined />} onClick={showModal}
                                        >
                                            Export
                                        </Button>

                                    </div>



                                </Modal>
                            </div>
                            {/* <div className={localStorage.getItem('currentTheme')}>
                                {tableshow == true ?
                                    <Collapse defaultActiveKey={["1"]} className='report-collapse' onChange={onStatChange} style={{ marginTop: '1%', backgroundColor: '#5daae3' }}>
                                        <Panel header="Overall Statistics" key={statkey}>
                                            <div className="col-md-12" style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                <div className="row m-2">
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={100} width={90} format={(percent) => `${210}`} />
                                                        <h4 style={{ marginLeft: '5%' }}>Assigned</h4>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={10} width={90} status="exception" format={(percent) => `${90}`} />
                                                        <h4 style={{ marginLeft: '5%' }}>Un Assigned</h4>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={70} width={90} strokeColor={{ '0%': '#f5b342', '100%': '#f5b342' }} format={(percent) => `${175}`} />
                                                        <h4 style={{ marginLeft: '5%' }}>Opted</h4>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Progress type="circle" percent={40} width={90} strokeColor={{ '0%': 'blue', '100%': 'blue' }} format={(percent) => `${115}`} />
                                                        <h4 style={{ marginLeft: '5%' }}>Not Opted</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse> : ''}
                            </div> */}
                            {tableshow == true ?

                                <Card className='rounded-3 '>

                                    <div className='col-md-12 mt-0 mb-2 filter-detial-sticky' >
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :</b> {criticalitydata.legal_entity_name}</label>
                                            </div>
                                            <div className='col-md-4 text-center'>
                                                <label><b>Country :</b> {criticalitydata.country}</label>
                                            </div>
                                            <div className='col-md-4 text-end'>
                                                <label><b>Domain :</b> {criticalitydata.domain}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className={localStorage.getItem('currentTheme') + ' px-2'}>
                                        <div className='ant-table-wrapper'>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content audittrailtable '>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead table-head-stick">
                                                                <tr>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'}> <centre>#</centre> </th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '270px' }} >Compliance Task</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }}  ><centre>Compliance Frequency</centre></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '80px' }} ><centre>Criticality</centre></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }} ><center>Due Date</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '140px' }} >Task Status</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '150px' }}  ><center>Assignee</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '130px' }} ><center>Format</center></th>

                                                                </tr>
                                                            </thead>
                                                            {filteredArrayState && filteredArrayState.length > 0 ?
                                                                filteredArrayState.map((item, i) => {
                                                                    console.log(item, 'itemitemmmmm')

                                                                    return (
                                                                        <tbody className='ant-table-tbody' key={i}>
                                                                            <tr className="bg-lightdark">
                                                                                <td colSpan="100%">

                                                                                    <div className='bg-white shadow-sm border-radius-reports'>
                                                                                        <div className="col-md-12">
                                                                                            <div className='row m-2'>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Unit :</b> {item[0].unit}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Division :</b> {item[0].division_name}</label>
                                                                                                </div>
                                                                                                <div className='col-md-3' >
                                                                                                    <label><b>Category :</b> {item[0].category_name}</label>
                                                                                                </div>
                                                                                                <div className="col-md-3">
                                                                                                    <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </td>
                                                                            </tr>


                                                                            {titledata && titledata.map((itemdata, i) => {

                                                                                let data = _.find(itemdata.child, { unit_id: item[0].unit_id })
                                                                                if (data != undefined) {
                                                                                    if (data.act_name == itemdata.parent) {
                                                                                        return <Fragment>

                                                                                            <tr className='ant-table-row ant-table-row-level-0'>

                                                                                                <td colSpan={12} className='ant-table-cell' ><b style={{ backgroundColor: "lightyellow" }}>{itemdata.parent}</b></td>
                                                                                            </tr>

                                                                                            {itemdata.child.map((items) => {
                                                                                                if (item[0].unit_id == items.unit_id) {
                                                                                                    return <tr>
                                                                                                        <td className='ant-table-cell'><center>{index + 1}</center></td>
                                                                                                        <td className='ant-table-cell'>
                                                                                                            <>
                                                                                                                <Tooltip title={items.compliance_description} >
                                                                                                                    <ExclamationCircleTwoTone />
                                                                                                                </Tooltip> &nbsp;
                                                                                                                {items.penal_consequences == '-' ? '' : <Tooltip title={items.penal_consequences} >
                                                                                                                    <span>  <ExclamationCircleOutlined /></span>
                                                                                                                </Tooltip>}
                                                                                                                &nbsp; {items.compliance_task}
                                                                                                            </></td>
                                                                                                        <td className='ant-table-cell'>{items.frequency}</td>
                                                                                                        <td className='ant-table-cell'><center>{
                                                                                                            <div>

                                                                                                                {(items.client_criticality === null ? items.know_criticality :
                                                                                                                    (items.client_criticality !== items.know_criticality ? <span style={{ color: 'red', cursor: 'pointer' }} title={items.know_criticality} >{items.client_criticality}</span> : <span>{items.client_criticality}</span>))}
                                                                                                            </div>}</center></td>

                                                                                                        <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                        <td className='ant-table-cell'>{items.task_status}</td>
                                                                                                        <td className='ant-table-cell'>{
                                                                                                            <div>
                                                                                                                {items.assignee_name ?                        
                                                                                                                    <div>
                                                                                                                        <div className="mb-0">
                                                                                                                            <Tooltip title={
                                                                                                                                <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                                                                                                    <tbody>
                                                                                                                                        <tr>
                                                                                                                                            <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Concurrer</center></th>
                                                                                                                                            <th style={{ border: '1px solid #636262' }}><center>Approver</center></th>

                                                                                                                                        </tr>
                                                                                                                                        <tr>
                                                                                                                                            <td style={{ border: '1px solid #636262' }}><center>{items.concurrence_person ? items.concurrence_person : '-'}</center></td>
                                                                                                                                            <td style={{ border: '1px solid #636262' }}><center>{items.approval_person ? items.approval_person : '-'}</center></td>
                                                                                                                                        </tr>


                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                            }> <ExclamationCircleTwoTone />&nbsp;&nbsp;{items.assignee_name}</Tooltip>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    : ''}
                                                                                                            </div>}</td>
                                                                                                        <td className='ant-table-cell'>
                                                                                                            {
                                                                                                                <a href={`${urls + '/compliance_format/' + items.download_url}`} target="_blank" >
                                                                                                                    {items.document_name}
                                                                                                                </a>
                                                                                                            }
                                                                                                        </td>
                                                                                                        <span hidden>{index = index + 1}</span>

                                                                                                    </tr>
                                                                                                }

                                                                                            })

                                                                                            }

                                                                                        </Fragment>

                                                                                    }
                                                                                }
                                                                            })
                                                                            }

                                                                        </tbody>
                                                                    )
                                                                }) :
                                                                <tr>
                                                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>
                                                            }
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        {index != 0 ?
                                            <><div className='col-md-6'>
                                                <label>Showing {pageSize * (current - 1) + 1} to {index} of {count} entries</label>
                                            </div>
                                                <div className='col-md-6 text-right'>
                                                    <Pagination
                                                        current={current}
                                                        showSizeChanger={true}
                                                        showQuickJumper={false}
                                                        onShowSizeChange={() => {
                                                            setCurrent(1)
                                                            // setpageState(true)
                                                        }}
                                                        pageSizeOptions={[25, 50, 100]} hideOnSinglePage={count > 25 ? false : true} defaultPageSize={25}
                                                        onChange={(page, pageSizes) => {
                                                            setpageState(true)
                                                            setCurrent(pageSize !== pageSizes ? 1 : page);
                                                            setPageSize(pageSizes)
                                                        }} total={count} />
                                                </div>
                                            </> : ''}
                                    </div>
                                </Card>
                                : ''}

                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default connect(mapStateToProps, {
    criticalityfilterlist,
    criticalityshowlist,
    criticalityexportlist,


})(CriticalityReport);