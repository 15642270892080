import React from 'react'
import { Tooltip, Collapse, Select, Input, DatePicker, Card, Drawer, Button, Table, Progress, Modal, Pagination } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, Fragment } from 'react';
import { DoubleLeftOutlined, PlayCircleOutlined, EyeOutlined, ExportOutlined, FullscreenOutlined, UpOutlined, FilterOutlined, ExclamationCircleTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import { getFilterValues, getUnitData, getTaskwiseReportData, getTaskWiseExportData, getRemarksFilter } from '../../../Store/Action/Report/TaskWiseReport';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import moment from 'moment';
import { fullscreenmode, fullscreenexit, handleKeyDown } from './../../../Libs/fullscreenmode';
import ScrollToTop from "react-scroll-to-top";
import ScrollButton from '../../Scrollbar';
import { Toaster } from '../../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    filterValue: state.TaskWiseReport.TaskWiseReports
})
const TaskWiseReport = (({
    getFilterValues,
    filterValue,
    getTaskwiseReportData,
    getTaskWiseExportData,
    getUnitData,
    getRemarksFilter
}) => {
    console.log(getTaskwiseReportData, "filterValue");
    const _ = require("lodash");
    let [index, setIndex] = useState(0)
    const [totalCount, setTotal] = useState(50)
    const validator = useRef(new SimpleReactValidator());
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [pageSize, setPageSize] = useState(25)
    const [paginationArray, setPaginationArray] = useState([])
    const [countrydata, setCountryData] = useState(null)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [statkey, setStatKey] = useState("0")
    const [key, setKey] = useState("1")
    const [titledata, Settitledata] = useState([])
    console.log(paginationArray, 'titledata')
    const [filteredArray, setFilteredArray] = useState([])
    const [entity, setEntity] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [exitCollapse, setExitCollapse] = useState(false);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [exportButton, setExportButton] = useState(false)
    const [consolidatedData, setConsolidatedData] = useState({
        country: "",
        country_history: '',
        category: "",
        user_type: "All",
        legel_entity: "",
        entity_name: '',
        legalentity_history: '',
        unit: '',
        user: "",
        domain: "",
        doamin_history: '',
        act: "",
        act_history: '',
        from_date: "",
        task_category: "",
        compliance_task: "",
        task_history: '',
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "All",
        division: "",
        domainName: "",
        countryName: '',
        complianceName: ""

    })

    const [open, setOpen] = useState(false);
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const sessionArr = sessionParsedValue.entity_info
    const country_info = sessionParsedValue.country_info
    const [domain, setDomain] = useState([])
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const [division, setDivision] = useState([])
    const [checkboxValue, setCheckBoxValue] = useState([1, 2, 7, 8, 9, 10, 31, 34])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ComplianceTask, setComplianceTask] = useState([])
    const [complianceData, setComplianceData] = useState("")
    console.log(filteredArrayState, 'filteredArrayState');
    const [Act, setAct] = useState([])
    const [Acts, setActs] = useState([])
    const [complianceTaskStatus, setcomplianceTaskStatus] = useState([])
    const [user, setuser] = useState([])
    const [userType, setUserType] = useState([])
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [disableButton, setDisableButton] = useState(false)
    const [unitcode, setUnitcode] = useState([])
    const [units, setUnits] = useState([])
    const { Panel } = Collapse;
    const { Option } = Select;
    const [fullscreen, setfullscreen] = useState(false);
    const [isModalOpenChild, setModalOpenChild] = useState(false)

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("SelectedEntity") !== "All Legal Entity") {
            let temp = localStorage.getItem("SelectedEntity")
            let tempcountry = _.filter(sessionArr, { le_name: temp })
            setConsolidatedData({
                ...consolidatedData,
                country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
                countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
            })
        }
    }, [localStorage.getItem("SelectedEntity") !== "All Legal Entity"])

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    useEffect(() => {

        if (filterValue && filterValue.TaskWiseReportList && filterValue.TaskWiseReportList.task_compliances && filterValue.TaskWiseReportList.task_compliances.length > 0) {

            var array = filterValue && filterValue.TaskWiseReportList.task_compliances.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }
        else {
            setPaginationArray([])
        }

    }, [filterValue && filterValue.TaskWiseReportList.task_compliances, pageSize, current])


    const showModal = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setIsModalOpen(true);
        }
    };

    const handleOk = () => {
        setAddFormSubmit(true)
        setExportButton(true)
        if (validator.current.allValid()) {
            // setTableShow(true)
            // setKey(0)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskWiseReport",
                        {
                            "country_id": Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            // "statutory_mapping": consolidatedData.act,
                            // "comp_tsk_id": Number(consolidatedData.compliance_task),
                            "statutory_mapping": consolidatedData.act,
                            "comp_tsk_id": consolidatedData.compliance_task ? Number(consolidatedData.compliance_task) : 0,
                            "user_type": consolidatedData.user_type ? consolidatedData.user_type : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": consolidatedData.from_date ? moment(consolidatedData.from_date).format('DD-MMM-YYYY') : "",
                            "due_to_date": consolidatedData.to_date ? moment(consolidatedData.to_date._d).format('DD-MMM-YYYY') : "",
                            "task_status": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                            "csv": true,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": checkboxValue
                        }
                    ]
                }
            ]
            getTaskWiseExportData({
                payload: payload,
                paramid: paramid
            })
        }
        setIsModalOpen(false);
        setCheckBoxValue([1, 2, 7, 8, 9, 10, 31, 34])
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setCheckBoxValue([1, 2, 7, 8, 9, 10, 31, 34])
    };
    // const disablecondition = (e) => {
    //     var currentToDate = moment(consolidatedData.from_date).add(3, 'months')
    //     if (currentToDate < moment(e)) {
    //         setDisableButton(true)
    //     } else {
    //         setDisableButton(false)
    //     }
    // }

    const onshow = () => {
        setAddFormSubmit(true)
        // Settitledata([])
        setfilteredArrayState([])
        if (validator.current.allValid()) {
            setExitCollapse(true);
            setFilterTaskAcc(false);
            setFilterModalVisible(false);
            setIndex(0);
            setCurrent(1);
            setpageState(false)
            setPageSize(25)
            setTableShow(true)
            setKey(0)
            setStatKey(1)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskWiseReport",
                        {
                            "country_id": Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidatedData.domain),
                            "division_id": consolidatedData.division ? Number(consolidatedData.division) : 0,
                            "category_id": consolidatedData.category ? Number(consolidatedData.category) : 0,
                            "unit_id": consolidatedData.unit ? Number(consolidatedData.unit) : 0,
                            // "statutory_mapping": "The Payment of Gratuity Act, 1973  >>  The Kerala Payment of Gratuity Rules, 1973",
                            // "comp_tsk_id": 184,
                            "statutory_mapping": consolidatedData.act,
                            "comp_tsk_id": consolidatedData.compliance_task ? Number(consolidatedData.compliance_task) : 0,
                            "user_type": consolidatedData.user_type ? consolidatedData.user_type : null,
                            "user_id": consolidatedData.user ? Number(consolidatedData.user) : 0,
                            "due_from_date": consolidatedData.from_date ? moment(consolidatedData.from_date).format('DD-MMM-YYYY') : "",
                            "due_to_date": consolidatedData.to_date ? moment(consolidatedData.to_date._d).format('DD-MMM-YYYY') : "",
                            "task_status": consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
                            "csv": false,
                            "from_count": 0,
                            "page_count": 0,
                            "list_check": 0
                        }
                    ]
                }
            ]
            getTaskwiseReportData({
                payload: payload,
                paramid: paramid
            })
        }
    }

    useEffect(() => {
        if (filterValue.TaskWiseReportList.task_compliances && filterValue.TaskWiseReportList.task_compliances.length > 0) {
            let uniqueObjArray = [...new Map(filterValue && filterValue.TaskWiseReportList && filterValue.TaskWiseReportList.task_compliances && filterValue.TaskWiseReportList.task_compliances.length > 0 && filterValue.TaskWiseReportList.task_compliances.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(filterValue && filterValue.TaskWiseReportList && filterValue.TaskWiseReportList.task_compliances.length && filterValue.TaskWiseReportList.task_compliances, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])

        }

    }, [filterValue && filterValue.TaskWiseReportList.task_compliances])

    const onClose = () => {
        setOpen(false);
    };
    const ComplianceTaskOnChange = (data, value) => {
        let taskData = _.find(ComplianceTask, { compliance_id: Number(data) })
        setComplianceData(taskData && taskData.compliance_description)
        if (data !== undefined) {
            setConsolidatedData({
                ...consolidatedData,
                compliance_task: data,
                complianceName: value.children,
                task_history: value.children
            })
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTaskWiseActReportFilters",
                        {
                            "country_id": Number(consolidatedData.country),
                            "legal_entity_id": entityid,
                            "domain_id": Number(consolidatedData.domain),
                            "comp_tsk_id": Number(data)
                        }
                    ]
                }
            ]
            getUnitData({
                payload: payload,
                paramid: paramid
            })
        }
        else {
            setConsolidatedData({
                ...consolidatedData,
                compliance_task: '',
                complianceName: '',
            })
        }
    }

    useEffect(() => {
        if (filteredArray.length) {
            let temp = []
            for (let i in filteredArray) {
                temp = filteredArray[i]
            }
            setfilteredArrayState([...filteredArrayState, ...temp])
        }

    }, [filteredArray])
    const genextra = () => {
        return <div onClick={(event) => {
            // If you don't want click extra trigger collapse, you can prevent this:
            event.stopPropagation();
        }}>

            <Button type='primary' onClick={() => {
                setOpen(true)
            }}>
                <i class="bi bi-chevron-double-left"></i>
            </Button>


        </div>
    }


    useEffect(() => {
        if (entityid === null) {

            setCurrentEntity(consolidatedData.legel_entity)
        }
    }, [entityid])

    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            const country = sessionParsedValue.country_info
            setCountryData(country)
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (isAuth) {
            if (entityid !== '') {
                if (entityid != 'null') {
                    let temp = localStorage.getItem("SelectedEntity")
                    let tempcountry = _.filter(sessionArr, { le_name: temp })
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetTaskWiseReportFilters",
                                {
                                    "country_id": consolidatedData.country ? Number(consolidatedData.country) : tempcountry && tempcountry[0].c_id,
                                    "legal_entity_id": entityid
                                }
                            ]
                        }
                    ]
                    getFilterValues({
                        payload: payload,
                        paramid: paramid
                    })
                }
            }
        }
    }, [isAuth, authtoken, paramid, entityid])
    useEffect(() => {
        validator.current.showMessages()
    }, [])

    const handlePasting = (e, regex) => {
        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
    }


    useEffect(() => {
        // setTempeditdata(editdata)
        let array = [];
        let finalarray = [];
        if (paginationArray && paginationArray.length > 0) {
            let temp = [];
            let entityArray = [];
            let datavalue = [];
            let childAray = []
            let filteredPeople = [];
            paginationArray && paginationArray.length > 0 && paginationArray.map((child, childIndex) => {
                //if(same Compliance_history_id repeats then push into separate array and map with final array as child)
                let checkArray = temp.some(function (el) { return el.compliance_history_id === child.compliance_history_id; });
                if (checkArray) {
                    datavalue = {
                        "act_name": child.act_name,
                        "activity_on": child.activity_on,
                        "activity_status": child.activity_status,
                        "category_name": child.category_name,
                        "completion_date": child.completion_date,
                        "compliance_activity_id": child.compliance_activity_id,
                        "compliance_description": child.compliance_description,
                        "compliance_history_id": child.compliance_history_id,
                        "compliance_id": child.compliance_id,
                        "compliance_name": child.compliance_name,
                        "criticality_name": child.criticality_name,
                        "division_name": child.division_name,
                        "doc_ref_num": child.doc_ref_num,
                        "due_date": child.due_date,
                        "extend_due_date": child.extend_due_date,
                        "frequency_name": child.frequency_name,
                        "history_count": child.history_count,
                        "interim_count": child.interim_count,
                        "legal_entity_id": child.legal_entity_id,
                        "start_date": child.start_date,
                        "task_group_name": child.task_group_name,
                        "task_status": child.task_status,
                        "task_sub_group_name": child.task_sub_group_name,
                        "tz_name": child.tz_name,
                        "unit": child.unit,
                        "unit_address": child.unit_address,
                        "unit_id": child.unit_id,
                        "uploaded_document": child.uploaded_document,
                        "user_name": child.user_name,
                        'childId': childIndex,
                        'assignee_name': child.assignee_name,
                        'activity_date': child.activity_date
                    }
                    childAray.push(datavalue)
                }
                //final array
                entityArray = {
                    "act_name": child.act_name,
                    "activity_on": child.activity_on,
                    "activity_status": child.activity_status,
                    "category_name": child.category_name,
                    "completion_date": child.completion_date,
                    "compliance_activity_id": child.compliance_activity_id,
                    "compliance_description": child.compliance_description,
                    "compliance_history_id": child.compliance_history_id,
                    "compliance_id": child.compliance_id,
                    "compliance_name": child.compliance_name,
                    "criticality_name": child.criticality_name,
                    "division_name": child.division_name,
                    "doc_ref_num": child.doc_ref_num,
                    "due_date": child.due_date,
                    "extend_due_date": child.extend_due_date,
                    "frequency_name": child.frequency_name,
                    "history_count": child.history_count,
                    "interim_count": child.interim_count,
                    "legal_entity_id": child.legal_entity_id,
                    "start_date": child.start_date,
                    "task_group_name": child.task_group_name,
                    "task_status": child.task_status,
                    "task_sub_group_name": child.task_sub_group_name,
                    "tz_name": child.tz_name,
                    "unit": child.unit,
                    "unit_address": child.unit_address,
                    "unit_id": child.unit_id,
                    "uploaded_document": child.uploaded_document,
                    "user_name": child.user_name,
                    'childId': childIndex,
                    'assignee_name': child.assignee_name,
                    'activity_date': child.activity_date,
                    'childExists': childAray
                }

                temp.push(entityArray)
                //remove if above condition
                if (checkArray) {
                    for (let i in temp) {
                        if (temp[i].childId === childIndex) {
                            temp.splice(i, 1)
                        }
                    }
                }

            })
            Settitledata(temp)

        }

    }, [paginationArray])

    const checkBoxOnChange = (e) => {
        let temp = checkboxValue
        let tempIds = []
        if (temp.includes(Number(e.target.value)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(e.target.value)) {
                    temp.splice(i, 1)
                }
            }
            setCheckBoxValue([...temp])
        } else {
            tempIds.push(Number(e.target.value))
            setCheckBoxValue([...checkboxValue, ...tempIds])
        }
    }

    const selectAllCheckBox = (checked) => {
        var ele = document.getElementsByName('chk');
        let tempAllIds = []
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox') {
                    ele[i].checked = true;
                    tempAllIds.push(Number(ele[i].value))
                }
            }
            setCheckBoxValue(tempAllIds)
        }
        else {
            var ele = document.getElsetActsementsByName('chk');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;

            }
            setCheckBoxValue([1, 2, 7, 8, 9, 10, 31, 34])
        }
    }
    useEffect(() => {
        if (filterValue) {
            setUserType(filterValue && filterValue.unitData && filterValue.unitData.compliance_user_type)
            setComplianceTask(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list)
            setDomain(filterValue && filterValue.Data && filterValue.Data.domains)
            if (filterValue && filterValue.Data && filterValue.Data.tsk_act_legal_entity && filterValue.Data.tsk_act_legal_entity.length > 0) {
                let uniqueObjArray = [...new Map(filterValue && filterValue.Data && filterValue.Data.tsk_act_legal_entity && filterValue.Data.tsk_act_legal_entity.length > 0 && filterValue.Data.tsk_act_legal_entity.map((item) => [item["statutory_mapping"], item])).values()];
                setAct(uniqueObjArray)
            } else {
                setAct([])
            }
            setfrequency(filterValue && filterValue.Data && filterValue.Data.compliance_frequency)
            setCategoryList(filterValue && filterValue.Data && filterValue.Data.categories_list)
            setcomplianceTaskStatus(filterValue && filterValue.unitData && filterValue.unitData.compliance_task_status)
            setUnitcode(filterValue && filterValue.Data && filterValue.Data.unit_code_label_list)
            setUnits(filterValue && filterValue.unitData && filterValue.unitData.tsk_unit_legal_entity)
            setDivision(filterValue && filterValue.Data && filterValue.Data.divisions_list)
            setuser(filterValue && filterValue.unitData && filterValue.unitData.tsk_compliance_users)
        }


    }, [filterValue])

    useEffect(() => {
        if (pageState === true) {
            setIndex(pageSize * (current - 1))
        }
    }, [pageState, pageSize, current])

    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const [modalDataState, setModalDataState] = useState([])

    const getChildDetails = (getrecords) => {
        setModalOpenChild(true)
        setModalDataState(getrecords)
        console.log(getrecords, 'getrecords')
    }

    const handleOkChild = () => {
        setModalOpenChild(false)
        setModalDataState([])
    };

    const handleCancelChild = () => {
        setModalOpenChild(false)
        setModalDataState([])
    };

    const [isRemarksModal, setRemarksModal] = useState(false)
    const getRemarksList = (getrecord) => {
        setRemarksModal(true)
        const remarkspayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceRemarksHistoryData",
                    {
                        "legal_entity_id": consolidatedData.legel_entity,
                        "unit_id": parseInt(getrecord.unit_id),
                        "compliance_history_id": parseInt(getrecord.compliance_history_id)
                    }
                ]
            }
        ]
        getRemarksFilter({
            payload: remarkspayload,
            paramid: paramid
        })
    }

    const columnData = [
        {
            title: "User Name",
            dataIndex: "assignee_name",
            key: "assignee_name",
        },
        {
            title: "Activity Status",
            dataIndex: "activity_status",
            key: "activity_status",
        },
        {
            title: "Activity Date",
            dataIndex: "activity_date",
            key: "activity_date",
        },
        {
            title: "Uploaded Document",
            dataIndex: "uploaded_document",
            key: "uploaded_document",
        },
        {
            title: "Date",
            dataIndex: "completion_date",
            key: "completion_date",
        }
    ];

    const remarksColumn = [
        {
            title: "Activity Date",
            dataIndex: "activity_on",
            key: "activity_on",
        },
        {
            title: "Activity Date",
            dataIndex: "user_category_id",
            key: "user_category_id",
            render: (text, record) => {
                if (record.user_category_id == 5 || record.user_category_id == 6) {
                    return 'Assignee'
                } else if ((record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Rectified' || record.action == 'Concurrence Rejected' || record.action == 'Concurred')) {
                    return 'Concurrer'
                } else if (record.user_category_id == 1 && (record.action == 'RectifyApproval' || record.action == 'Approval Rejected' || record.action == 'Approved') ||
                    (record.user_category_id == 3 || record.user_category_id == 4) && (record.action == 'Approval Rejected' || record.action == 'RectifyApproval' || record.action == 'Approved')) {
                    return 'Approver'
                }

            }
        },
        {
            title: "Reviewer Report",
            dataIndex: "documents",
            key: "documents",
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
        },
    ]

    const handleOkRemarks = () => {
        setRemarksModal(false)
    };

    const handleCancelRemarks = () => {
        setRemarksModal(false)
    };

    return (
        <div id='page-wrapper' className="page-wrapper">
            {tableshow == true ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''}
            <div className="page-titles pb-2 pt-1 page-title-sticky">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                    <span style={{ fontSize: "15px", fontWeight: "bold" }}>Compliance Reports</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Task Wise Report</span>
                                </li>
                            </ol>
                        </nav>

                    </div>
                    <div className="col-lg-6 text-end">
                        <Tooltip title={'Advance Filter'}><Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button></Tooltip>
                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                            <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className={localStorage.getItem("currentTheme") + ' card-body1-statuatory'} style={{ position: 'relative' }}>

                            <Collapse className='report-collapse' style={{ display: exitCollapse ? "none" : "block" }} accordion defaultActiveKey={["1"]} onChange={onChange}>
                                <Panel header="Task Wise Report" key={key} >
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>


                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">

                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            value={consolidatedData.country || undefined}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z ]+$/gi)
                                                            }}
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    let le = _.filter(sessionArr, { c_id: Number(data) })
                                                                    setEntity(le)
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        country: data,
                                                                        country_history: value.children,
                                                                        countryName: value.children,
                                                                        category: "",
                                                                        user_type: "All",
                                                                        legel_entity: "",
                                                                        legalentity_history: '',
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        doamin_history: '',
                                                                        act: "",
                                                                        act_history: '',
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        task_history: '',
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: ""
                                                                    })
                                                                }
                                                                else {
                                                                    setConsolidatedData({
                                                                        ...consolidatedData,
                                                                        country: '',
                                                                        countryName: '',
                                                                        country_history: '',
                                                                        category: "",
                                                                        user_type: "All",
                                                                        legel_entity: "",
                                                                        legalentity_history: '',
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        doamin_history: '',
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: "",
                                                                        doamin_history: '',
                                                                        act_history: '',
                                                                        task_history: ''
                                                                    })
                                                                }
                                                            }
                                                            }
                                                            style={{ width: '100%', marginTop: '2px' }}>
                                                            {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                                return (
                                                                    <Option key={item.c_id}>
                                                                        {item.c_name}
                                                                    </Option>
                                                                );

                                                            })}
                                                        </Select>

                                                        {validator.current.message(
                                                            'Country',
                                                            consolidatedData.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Country Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{consolidatedData.countryName}</p>
                                                }
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :  </b><span style={{ color: "red" }}>*</span></label>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                    <div className="form-group">

                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            value={consolidatedData.legel_entity || undefined}
                                                            placeholder="Enter Legal Entity"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                            }}
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    setConsolidatedData({

                                                                        ...consolidatedData,
                                                                        legel_entity: data,
                                                                        entity_name: value.children,
                                                                        legalentity_history: value.children,
                                                                        category: "",
                                                                        user_type: "All",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: "",
                                                                        doamin_history: '',
                                                                        act_history: '',
                                                                        task_history: ''
                                                                    })
                                                                    setCurrentEntity(data)
                                                                }
                                                                else {
                                                                    setConsolidatedData({

                                                                        ...consolidatedData,
                                                                        legel_entity: '',
                                                                        entity_name: '',
                                                                        legalentity_history: '',
                                                                        category: "",
                                                                        user_type: "All",
                                                                        unit: '',
                                                                        user: "",
                                                                        domain: "",
                                                                        act: "",
                                                                        from_date: "",
                                                                        task_category: "",
                                                                        compliance_task: "",
                                                                        to_date: "",
                                                                        task_sub_category: "",
                                                                        compliance_frequency: "",
                                                                        compliance_task_status: "All",
                                                                        division: "",
                                                                        domainName: "",
                                                                        complianceName: "",
                                                                        doamin_history: '',
                                                                        act_history: '',
                                                                        task_history: ''
                                                                    })
                                                                    setCurrentEntity('')
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //value={Settings.le_id}
                                                            style={{ width: '100%', marginTop: '2px' }}
                                                            disabled={consolidatedData.country == '' ? true : false}

                                                        >

                                                            {entity && entity.length > 0 && entity.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}


                                                        </Select>

                                                        {validator.current.message(
                                                            'legalEntity',
                                                            consolidatedData.legel_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}

                                                    </div> :
                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                }

                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    allowClear={true}
                                                    disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidatedData.legel_entity == '') ? true : false}
                                                    size="default"
                                                    value={consolidatedData.domain || undefined}
                                                    placeholder="Enter Domain"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(data, value) => {
                                                        if (data !== undefined) {
                                                            let filteredAct = _.filter(Act, { domain_id: Number(data) })
                                                            setActs(filteredAct)
                                                            let filteredComplianceStatus = _.filter(filterValue && filterValue.unitData && filterValue.unitData.compliance_task_status, { domain_id: Number(data) })
                                                            setComplianceTask(filteredComplianceStatus)
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                domain: data,
                                                                domainName: value.children,
                                                                doamin_history: value.children,
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                complianceName: "",
                                                                act_history: '',
                                                                task_history: ''

                                                            })
                                                        }
                                                        else {

                                                            setActs([])
                                                            setComplianceTask([])
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                domain: '',
                                                                domainName: '',
                                                                doamin_history: '',
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: "",
                                                                act: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                complianceName: "",
                                                                act_history: '',
                                                                task_history: ''
                                                            })
                                                        }
                                                    }

                                                    }
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {domain && domain.length && domain.map((item, i) => {
                                                        return (
                                                            <Option key={item.d_id}>
                                                                {item.d_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidatedData.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Domain Required',
                                                        }
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>

                                                <label><b>Act/Rules : </b><span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    value={consolidatedData.act || undefined}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Act"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(data, value) => {
                                                        if (data !== undefined) {
                                                            // let filteredActObj = _.find(filterValue && filterValue.Data && filterValue.Data.tsk_act_legal_entity, { compliance_id: Number(data) })
                                                            let filteredComplianceStatus = _.filter(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list, { statutory_mapping: data })
                                                            setComplianceTask(filteredComplianceStatus)
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                act: value.children,
                                                                act_history: value.children,
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                complianceName: "",
                                                                task_history: ''
                                                            })
                                                        }
                                                        else {
                                                            setComplianceTask([])
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                act: '',
                                                                category: "",
                                                                user_type: "All",
                                                                unit: '',
                                                                user: "",
                                                                from_date: "",
                                                                task_category: "",
                                                                compliance_task: "",
                                                                to_date: "",
                                                                task_sub_category: "",
                                                                compliance_frequency: "",
                                                                compliance_task_status: "All",
                                                                division: "",
                                                                complianceName: "",
                                                                task_history: '',
                                                                act_history: ''
                                                            })
                                                            setComplianceTask([])
                                                        }
                                                    }
                                                    }
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                                        return (
                                                            <Option key={item.statutory_mapping}>
                                                                {item.statutory_mapping}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>

                                                {validator.current.message(
                                                    'Act',
                                                    consolidatedData.act,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Act Required',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task : </b><span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    allowClear={true}
                                                    disabled={consolidatedData.act == '' ? true : false}
                                                    value={consolidatedData.compliance_task || undefined}
                                                    size="default"
                                                    placeholder="Enter Compliance Task"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(data, value) => {
                                                        ComplianceTaskOnChange(data, value)
                                                    }}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {ComplianceTask && ComplianceTask.length > 0 && ComplianceTask.map((item, i) => {
                                                        return (
                                                            <Option key={item.compliance_id}>
                                                                {item.compliance_task}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidatedData.compliance_task,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Compliance Task Required',
                                                        }
                                                    })}



                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>User : </b></label>
                                                <Select
                                                    value={consolidatedData.user || undefined}

                                                    allowClear={true}
                                                    disabled={consolidatedData.act == '' ? true : false}
                                                    size="default"
                                                    placeholder="Enter User"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {user && user.length && user.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {item.employee_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Division : </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    size="default"
                                                    disabled={consolidatedData.compliance_task == '' ? true : false}
                                                    value={consolidatedData.division || undefined}
                                                    placeholder="Enter Division"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                division: data
                                                            })
                                                            let categorydata = _.filter(filterValue.Data.categories_list, { div_id: Number(data) })
                                                            setCategoryList(categorydata)
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                division: ''
                                                            })

                                                            setCategoryList([])
                                                        }
                                                    }
                                                    }
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>From Date : </b> </label>
                                                <DatePicker format={'DD-MMM-YYYY'} allowClear={true} placement={'bottomLeft'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z ]+$/gi)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                        return false
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                                    onChange={(date, dateString) => {
                                                        if (dateString == '') {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                from_date: '',
                                                                to_date: ''
                                                            })
                                                        } else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                from_date: dateString,
                                                                to_date: moment(dateString).add(3, 'months')
                                                            })
                                                        }

                                                    }}

                                                />

                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>To Date : </b> </label>
                                                <DatePicker format={'DD-MMM-YYYY'} allowClear={true} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z ]+$/gi)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                        return false
                                                    }}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    onChange={(date, dateString) => {
                                                        // disablecondition(dateString)
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            to_date: dateString
                                                        })
                                                    }}
                                                    value={consolidatedData.to_date || undefined}
                                                    disabledDate={(current) => {
                                                        return moment(consolidatedData.from_date).add(1, 'days') >= current ||
                                                            moment(consolidatedData.from_date).add(1, 'year') < current;
                                                    }}
                                                />

                                                {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Category : </b></label>
                                                <Select
                                                    value={consolidatedData.category || undefined}
                                                    disabled={consolidatedData.compliance_task == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter category"
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(data) => {
                                                        if (data !== undefined) {

                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                category: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                category: ''
                                                            })
                                                        }
                                                        // let unitData = _.filter(units && units.length > 0 && units, { category_id: Number(data) })
                                                        // setUnits(unitData)
                                                    }
                                                    }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {category && category.length > 0 && category.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>

                                            <div className='col-md-4'>
                                                <label><b>User Type : </b></label>
                                                <Select
                                                    value={consolidatedData.user_type || undefined}
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter UserType"
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user_type: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                user_type: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    // value={compfie.user_Group}
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {userType && userType.length && userType.map((item, i) => {
                                                        return (
                                                            <Option key={item.user_type}>
                                                                {item.user_type}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Compliance Task Status : </b></label>
                                                <Select
                                                    disabled={consolidatedData.domain == '' ? true : false}
                                                    value={consolidatedData.compliance_task_status || undefined}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(value) => {
                                                        if (value !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                compliance_task_status: value
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                compliance_task_status: ''
                                                            })
                                                        }
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    <Option key="All">All</Option>
                                                    {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                                        return (
                                                            <Option key={item.task_status}>
                                                                {item.task_status}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "5px" }}>
                                        <div className='row'>

                                            <div className='col-md-4'>
                                                <label><b>Unit : </b></label>
                                                <Select
                                                    value={consolidatedData.unit || undefined}
                                                    disabled={consolidatedData.compliance_task == '' ? true : false}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Unit"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                    }}
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                unit: data
                                                            })
                                                        }
                                                        else {
                                                            setConsolidatedData({
                                                                ...consolidatedData,
                                                                unit: ''
                                                            })
                                                        }
                                                    }
                                                    }
                                                    style={{ width: '100%', marginTop: '2px' }}

                                                >
                                                    {units && units.length > 0 && units.map((item, i) => {
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {`${item.unit_code}-${item.unit_name}`}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>


                                        </div>
                                    </div>
                                    <div className='text-center mt-2 popupbtncolour'>
                                        {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                        <Button disabled={disableButton} type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                            icon={<EyeOutlined />}       >
                                            Show
                                        </Button>
                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                            icon={<ExportOutlined />} onClick={showModal}>
                                            Export
                                        </Button>

                                    </div>

                                </Panel>
                            </Collapse>

                            <Modal
                                onCancel={setcancelFilter}
                                footer={false}
                                title="Task Wise Report"
                                className={
                                    "add-service-prv cons-report " +
                                    localStorage.getItem("currentTheme")
                                }
                                visible={filterModalVisible}
                            >
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Country : </b> <span style={{ color: "red" }}>*</span></label>


                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                <div className="form-group">

                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Country"
                                                        value={consolidatedData.country || undefined}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z ]+$/gi)
                                                        }}
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                let le = _.filter(sessionArr, { c_id: Number(data) })
                                                                setEntity(le)
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    country: data,
                                                                    country_history: data,
                                                                    countryName: value.children,
                                                                    category: "",
                                                                    user_type: "All",
                                                                    legel_entity: "",
                                                                    legalentity_history: '',
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    doamin_history: '',
                                                                    act: "",
                                                                    act_history: '',
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    task_history: '',
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: ""
                                                                })
                                                            }
                                                            else {
                                                                setConsolidatedData({
                                                                    ...consolidatedData,
                                                                    country: '',
                                                                    countryName: '',
                                                                    category: "",
                                                                    user_type: "All",
                                                                    legel_entity: "",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: "",
                                                                })
                                                            }
                                                        }
                                                        }
                                                        style={{ width: '100%', marginTop: '2px' }}>
                                                        {uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map((item, i) => {
                                                            return (
                                                                <Option key={item.c_id}>
                                                                    {item.c_name}
                                                                </Option>
                                                            );

                                                        })}
                                                    </Select>

                                                    {validator.current.message(
                                                        'Country',
                                                        consolidatedData.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Country Required',
                                                            }
                                                        })}

                                                </div> :
                                                <p>{consolidatedData.countryName}</p>
                                            }
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Legal Entity :  </b><span style={{ color: "red" }}>*</span></label>
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?

                                                <div className="form-group">

                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        size="default"
                                                        value={consolidatedData.legel_entity || undefined}
                                                        placeholder="Enter Legal Entity"
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                        }}
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                setConsolidatedData({

                                                                    ...consolidatedData,
                                                                    legel_entity: data,
                                                                    entity_name: value.children,
                                                                    legalentity_history: '',
                                                                    category: "",
                                                                    user_type: "All",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: "",
                                                                    doamin_history: '',
                                                                    act_history: '',
                                                                    task_history: ''
                                                                })
                                                                setCurrentEntity(data)
                                                            }
                                                            else {
                                                                setConsolidatedData({

                                                                    ...consolidatedData,
                                                                    legel_entity: '',
                                                                    entity_name: '',
                                                                    category: "",
                                                                    user_type: "All",
                                                                    unit: '',
                                                                    user: "",
                                                                    domain: "",
                                                                    act: "",
                                                                    from_date: "",
                                                                    task_category: "",
                                                                    compliance_task: "",
                                                                    to_date: "",
                                                                    task_sub_category: "",
                                                                    compliance_frequency: "",
                                                                    compliance_task_status: "All",
                                                                    division: "",
                                                                    domainName: "",
                                                                    complianceName: "",
                                                                })
                                                                setCurrentEntity('')
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        //value={Settings.le_id}
                                                        style={{ width: '100%', marginTop: '2px' }}
                                                        disabled={consolidatedData.country == '' ? true : false}

                                                    >

                                                        {entity && entity.length > 0 && entity.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}


                                                    </Select>

                                                    {validator.current.message(
                                                        'legalEntity',
                                                        consolidatedData.legel_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}

                                                </div> :
                                                <p>{localStorage.getItem('SelectedEntity')}</p>
                                            }

                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Domain : </b> <span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                allowClear={true}
                                                disabled={((localStorage.getItem('SelectedEntity') === "All Legal Entity") && consolidatedData.legel_entity == '') ? true : false}
                                                size="default"
                                                value={consolidatedData.domain || undefined}
                                                placeholder="Enter Domain"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                onChange={(data, value) => {
                                                    if (data !== undefined) {
                                                        let filteredAct = _.filter(Act, { domain_id: Number(data) })
                                                        setActs(filteredAct)
                                                        let filteredComplianceStatus = _.filter(filterValue && filterValue.unitData && filterValue.unitData.compliance_task_status, { domain_id: Number(data) })
                                                        setComplianceTask(filteredComplianceStatus)
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            domain: data,
                                                            domainName: value.children,
                                                            doamin_history: value.children,
                                                            category: "",
                                                            user_type: "All",
                                                            unit: '',
                                                            user: "",
                                                            act: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "All",
                                                            division: "",
                                                            complianceName: "",

                                                        })
                                                    }
                                                    else {

                                                        setActs([])
                                                        setComplianceTask([])
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            domain: '',
                                                            domainName: '',
                                                            doamin_history: '',
                                                            category: "",
                                                            user_type: "All",
                                                            unit: '',
                                                            user: "",
                                                            act: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "All",
                                                            division: "",
                                                            complianceName: "",
                                                        })
                                                    }
                                                }

                                                }
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {domain && domain.length && domain.map((item, i) => {
                                                    return (
                                                        <Option key={item.d_id}>
                                                            {item.d_name}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                            {validator.current.message(
                                                'domain',
                                                consolidatedData.domain,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Domain Required',
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>

                                            <label><b>Act/Rules : </b><span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.act || undefined}
                                                disabled={consolidatedData.domain == '' ? true : false}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Enter Act"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                onChange={(data, value) => {
                                                    if (data !== undefined) {
                                                        // let filteredActObj = _.find(filterValue && filterValue.Data && filterValue.Data.tsk_act_legal_entity, { compliance_id: Number(data) })
                                                        let filteredComplianceStatus = _.filter(filterValue && filterValue.Data && filterValue.Data.compliance_task_wise_list, { statutory_mapping: data })
                                                        setComplianceTask(filteredComplianceStatus)
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            act: value.children,
                                                            act_history: value.children,
                                                            category: "",
                                                            user_type: "All",
                                                            unit: '',
                                                            user: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "All",
                                                            division: "",
                                                            complianceName: "",
                                                        })
                                                    }
                                                    else {
                                                        setComplianceTask([])
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            act: '',
                                                            category: "",
                                                            user_type: "All",
                                                            unit: '',
                                                            user: "",
                                                            from_date: "",
                                                            task_category: "",
                                                            compliance_task: "",
                                                            to_date: "",
                                                            task_sub_category: "",
                                                            compliance_frequency: "",
                                                            compliance_task_status: "All",
                                                            division: "",
                                                            complianceName: "",
                                                            task_history: '',
                                                        })
                                                        setComplianceTask([])
                                                    }
                                                }
                                                }
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {Acts && Acts.length > 0 && Acts.map((item, i) => {
                                                    return (
                                                        <Option key={item.statutory_mapping}>
                                                            {item.statutory_mapping}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>

                                            {validator.current.message(
                                                'Act',
                                                consolidatedData.act,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Act Required',
                                                    }
                                                })}
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Compliance Task : </b><span style={{ color: "red" }}>*</span></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                allowClear={true}
                                                disabled={consolidatedData.act == '' ? true : false}
                                                value={consolidatedData.compliance_task || undefined}
                                                size="default"
                                                placeholder="Enter Compliance Task"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                onChange={(data, value) => {
                                                    ComplianceTaskOnChange(data, value)
                                                }}
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {ComplianceTask && ComplianceTask.length > 0 && ComplianceTask.map((item, i) => {
                                                    return (
                                                        <Option key={item.compliance_id}>
                                                            {item.compliance_task}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                            {validator.current.message(
                                                'domain',
                                                consolidatedData.compliance_task,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Compliance Task Required',
                                                    }
                                                })}



                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>User : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.user || undefined}

                                                allowClear={true}
                                                disabled={consolidatedData.act == '' ? true : false}
                                                size="default"
                                                placeholder="Enter User"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                onChange={(data) => {
                                                    if (data !== undefined) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            user: data
                                                        })
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            user: ''
                                                        })
                                                    }
                                                }
                                                }
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {user && user.length && user.map((item, i) => {
                                                    return (
                                                        <Option key={item.user_id}>
                                                            {item.employee_name}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Division : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                allowClear={true}
                                                size="default"
                                                disabled={consolidatedData.compliance_task == '' ? true : false}
                                                value={consolidatedData.division || undefined}
                                                placeholder="Enter Division"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                onChange={(data) => {
                                                    if (data !== undefined) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            division: data
                                                        })
                                                        let categorydata = _.filter(filterValue.Data.categories_list, { div_id: Number(data) })
                                                        setCategoryList(categorydata)
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            division: ''
                                                        })

                                                        setCategoryList([])
                                                    }
                                                }
                                                }
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {division && division.length > 0 && division.map((item, i) => {
                                                    return (
                                                        <Option key={item.div_id}>
                                                            {item.div_name}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>From Date : </b> </label>
                                            <DatePicker
                                                format={'DD-MMM-YYYY'} allowClear={true} placement={'bottomLeft'} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z ]+$/gi)
                                                }}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                    return false
                                                }}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                                onChange={(date, dateString) => {
                                                    if (dateString == '') {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            from_date: '',
                                                            to_date: ''
                                                        })
                                                    } else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            from_date: dateString,
                                                            to_date: moment(dateString).add(3, 'months')
                                                        })
                                                    }

                                                }}

                                            />

                                            {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>To Date : </b> </label>
                                            <DatePicker format={'DD-MMM-YYYY'} allowClear={true} placeholder='DD-MMM-YYYY' style={{ width: '100%', marginTop: '2px' }}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z ]+$/gi)
                                                }}
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                    return false
                                                }}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                onChange={(date, dateString) => {
                                                    // disablecondition(dateString)
                                                    setConsolidatedData({
                                                        ...consolidatedData,
                                                        to_date: dateString
                                                    })
                                                }}
                                                value={consolidatedData.to_date || undefined}
                                                disabledDate={(current) => {
                                                    return moment(consolidatedData.from_date).add(1, 'days') >= current ||
                                                        moment(consolidatedData.from_date).add(1, 'year') < current;
                                                }}
                                            />

                                            {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label><b>Category : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.category || undefined}
                                                disabled={consolidatedData.compliance_task == '' ? true : false}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Enter category"
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onChange={(data) => {
                                                    if (data !== undefined) {

                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            category: data
                                                        })
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            category: ''
                                                        })
                                                    }
                                                    // let unitData = _.filter(units && units.length > 0 && units, { category_id: Number(data) })
                                                    // setUnits(unitData)
                                                }
                                                }
                                                // value={compfie.user_Group}
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {category && category.length > 0 && category.map((item, i) => {
                                                    return (
                                                        <Option key={item.cat_id}>
                                                            {item.cat_name}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                        </div>

                                        <div className='col-md-4'>
                                            <label><b>User Type : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.user_type || undefined}
                                                disabled={consolidatedData.domain == '' ? true : false}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Enter UserType"
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onChange={(data) => {
                                                    if (data !== undefined) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            user_type: data
                                                        })
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            user_type: ''
                                                        })
                                                    }
                                                }
                                                }
                                                // value={compfie.user_Group}
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {userType && userType.length && userType.map((item, i) => {
                                                    return (
                                                        <Option key={item.user_type}>
                                                            {item.user_type}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label><b>Compliance Task Status : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                disabled={consolidatedData.domain == '' ? true : false}
                                                value={consolidatedData.compliance_task_status || undefined}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                onChange={(value) => {
                                                    if (value !== undefined) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            compliance_task_status: value
                                                        })
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            compliance_task_status: ''
                                                        })
                                                    }
                                                }}
                                                // placeholder="Enter Domain Name"
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                <Option key="All">All</Option>
                                                {complianceTaskStatus && complianceTaskStatus.length && complianceTaskStatus.map((item, i) => {
                                                    return (
                                                        <Option key={item.task_status}>
                                                            {item.task_status}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12' style={{ marginTop: "5px" }}>
                                    <div className='row'>

                                        <div className='col-md-4'>
                                            <label><b>Unit : </b></label>
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                value={consolidatedData.unit || undefined}
                                                disabled={consolidatedData.compliance_task == '' ? true : false}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Enter Unit"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                                                }}
                                                onChange={(data) => {
                                                    if (data !== undefined) {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            unit: data
                                                        })
                                                    }
                                                    else {
                                                        setConsolidatedData({
                                                            ...consolidatedData,
                                                            unit: ''
                                                        })
                                                    }
                                                }
                                                }
                                                style={{ width: '100%', marginTop: '2px' }}

                                            >
                                                {units && units.length > 0 && units.map((item, i) => {
                                                    return (
                                                        <Option key={item.unit_id}>
                                                            {`${item.unit_code}-${item.unit_name}`}
                                                        </Option>
                                                    );
                                                })}

                                            </Select>
                                        </div>


                                    </div>
                                </div>
                                <div className='text-center mt-2 popupbtncolour'>
                                    {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                                    <Button disabled={disableButton} type="primary" shape="round" className={'addbutton me-1 ' + localStorage.getItem('currentTheme')} onClick={onshow}
                                        icon={<EyeOutlined />}       >
                                        Show
                                    </Button>
                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        icon={<ExportOutlined />} onClick={showModal}>
                                        Export
                                    </Button>

                                </div>
                            </Modal>



                            {tableshow == true ?
                                <Card className='rounded-3'>
                                    <div className='col-md-12 mt-0 mb-2 filter-detial-sticky' >
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Legal Entity :</b>{localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? consolidatedData.legalentity_history : localStorage.getItem('SelectedEntity')}</label>
                                            </div>
                                            <div className='col-md-4 text-center'>
                                                <label><b>Country :</b>{consolidatedData.country_history}</label>
                                            </div>
                                            <div className='col-md-4 text-end'>
                                                <label><b>Domain :</b>{consolidatedData.doamin_history}</label>
                                            </div>


                                        </div>
                                        <div className="col-md-12 mt-1">
                                            <div className="row">
                                                <div className='col-md-7 mt-1'>
                                                    <label><b>Act :</b>{consolidatedData.act_history}</label>
                                                </div>
                                                <div className='col-md-5 mt-1'>
                                                    <label><b>Compliance Task </b>:{consolidatedData.task_history ? consolidatedData.task_history : ''}<Tooltip title={complianceData}>
                                                        <ExclamationCircleTwoTone className='ms-1' />
                                                    </Tooltip>{ComplianceTask && ComplianceTask.length > 0 && ComplianceTask[0].criticality_name != null ?
                                                        <span className='badgecolour' style={{ lineHeight: '18px' }}>{ComplianceTask && ComplianceTask[0].criticality_name}</span> : false}</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div >
                                        <div className='ant-table ant-table-bordered  ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content userprivclass1'>

                                                    <table>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        {/* antable-head */}
                                                        <thead className="ant-table-thead table-head-stick" >
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '20px' }}>#</th>

                                                                <th className='ant-table-cell' style={{ width: '100px' }}>Frequency</th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}><center>Due Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}>Compliance Task Status</th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>User Name</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>Activity Status</center></th>
                                                                <th className='ant-table-cell' style={{ width: '120px' }}><center>Activity Date</center></th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}><center>Uploaded Document</center></th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }} title='Task Completion Date/Document issued Date'>Date</th>
                                                            </tr>
                                                        </thead>
                                                        {filteredArrayState && filteredArrayState.length > 0 ?
                                                            filteredArrayState.map((item, i) => {
                                                                return (
                                                                    <tbody className='ant-table-tbody' key={i}>
                                                                        <tr className="bg-lightdark">
                                                                            <td style={{ paddingLeft: '0 !important', paddingRight: '0 !important' }} colSpan="100%">
                                                                                <div className='bg-white shadow-sm border-radius-reports'>
                                                                                    <div className="col-md-12">
                                                                                        <div className='row m-2'>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Unit :</b> {item[0].unit_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Division :</b> {item[0].division_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Category :</b> {item[0].category_name}</label>
                                                                                            </div>
                                                                                            <div className='col-md-3' >
                                                                                                <label><b>Time Zone :</b> {item[0].tz_name}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        {titledata && titledata.length > 0 && titledata.map((itemdata, i) => {
                                                                            console.log(itemdata, 'itemdata')
                                                                            return <Fragment key={i}>

                                                                                <tr>
                                                                                    <td className='ant-table-cell'>{index + 1}</td>
                                                                                    <td className='ant-table-cell'>{itemdata.frequency_name}</td>
                                                                                    <td className='ant-table-cell'><center>{itemdata.due_date}</center></td>
                                                                                    <td className='ant-table-cell'><center>{itemdata.task_status}</center></td>
                                                                                    {itemdata.childExists && itemdata.childExists.length > 0 ?
                                                                                        <td className='ant-table-cell'><center><Link onClick={(e) => { getChildDetails(itemdata.childExists) }}>{itemdata.assignee_name}</Link></center></td> :
                                                                                        <td className='ant-table-cell'><center>{itemdata.assignee_name}</center></td>
                                                                                    }
                                                                                    <td className='ant-table-cell'><center>{itemdata.activity_status} {itemdata.history_count > 0 ? <InfoCircleOutlined onClick={(e) => getRemarksList(itemdata)} /> : false}</center></td>
                                                                                    <td className='ant-table-cell'><center>{itemdata.activity_date}</center></td>
                                                                                    <td ><center><Link>{itemdata.doc_ref_num}</Link></center></td>
                                                                                    <td className='ant-table-cell'><center>{itemdata.completion_date != null ? itemdata.completion_date : '-'}</center></td>
                                                                                    <span hidden>{index = index + 1}</span>

                                                                                </tr>
                                                                            </Fragment>
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                )
                                                            }) :
                                                            <tr>
                                                                <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                            </tr>
                                                        }
                                                    </table>
                                                    {/* <br /> */}
                                                    <Modal
                                                        title="Mapping Lists"
                                                        className={"remark-header modelradius add-service-prv " + localStorage.getItem("currentTheme")}
                                                        visible={isModalOpenChild}
                                                        footer={null}
                                                        onOk={handleOkChild}
                                                        onCancel={handleCancelChild}
                                                    >
                                                        <Table
                                                            className={localStorage.getItem("currentTheme")}
                                                            size={"small"}
                                                            columns={columnData}
                                                            dataSource={modalDataState}
                                                            bordered
                                                            pagination={false}
                                                        />
                                                    </Modal>

                                                    <Modal
                                                        title="Remarks History"
                                                        className={"remark-header modelradius add-service-prv " + localStorage.getItem("currentTheme")}
                                                        visible={isRemarksModal}
                                                        footer={null}
                                                        onOk={handleOkRemarks}
                                                        onCancel={handleCancelRemarks}
                                                    >
                                                        <Table
                                                            className={localStorage.getItem("currentTheme")}
                                                            size={"small"}
                                                            columns={remarksColumn}
                                                            dataSource={filterValue.remarks && filterValue.remarks.remarks_history && filterValue.remarks.remarks_history.length > 0 && filterValue.remarks.remarks_history}
                                                            bordered
                                                            pagination={false}
                                                        />
                                                    </Modal>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row mt-3">
                                        {index != 0 ?
                                            <div className='col-md-6' >
                                                <label>Showing {pageSize * (current - 1) + 1} to {index} of {filterValue && filterValue.TaskWiseReportList?.task_compliances?.length} entries</label>
                                            </div> : ''}

                                        <div className='col-md-6 text-right'>
                                            <Pagination
                                                current={current}
                                                showSizeChanger={true}
                                                // showQuickJumper={true}
                                                onShowSizeChange={() => {
                                                    setCurrent(1)
                                                    // setpageState(true)
                                                }}
                                                pageSizeOptions={[25, 50, 100]} hideOnSinglePage={filterValue && filterValue.count > 25 ? false : true} defaultPageSize={25}
                                                onChange={(page, pageSizes) => {
                                                    setpageState(true)
                                                    setCurrent(pageSize !== pageSizes ? 1 : page);
                                                    setPageSize(pageSizes)
                                                }} total={filterValue && filterValue.count}
                                            // showTotal={(total) => `Showing 1 to ${index} of ${tableData.total_count} entries`}
                                            />
                                        </div>
                                    </div>
                                </Card>
                                : false}
                            {/* <Modal title="Select Fields" className={'add-service-prv ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel} width={100}>
                                <div className='row'>
                                    <div className='col-lg-3'><input id="select_all" width="100%" type="checkbox" autocomplete="off"
                                        checked={checkboxValue.length === 34 ? true : false}
                                        onClick={(e) => {
                                            let checked = e.target.checked
                                            selectAllCheckBox(checked)
                                        }} /> Select All</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> Unit Code</div>
                                    <div className='col-lg-3'><input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> Unit Name</div>
                                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='3' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 1</div>
                                    <div className='col-lg-3'><input id="column_7" name='chk' type="checkbox" value='4' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 2</div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_8" name='chk' type="checkbox" value='5' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 3 </div>
                                    <div className='col-lg-3'><input id="column_9" name='chk' type="checkbox" value='6' onChange={(e) => checkBoxOnChange(e)} /> Unit Code 4 </div>
                                    <div className='col-lg-3'><input id="column_10" name='chk' type="checkbox" value='7' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} />Act / Rules</div>
                                    <div className='col-lg-3'><input id="column_29" name='chk' type="checkbox" value='8' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Frequency</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_32" name='chk' type="checkbox" value='9' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Task Category</div>
                                    <div className='col-lg-3'><input id="column_3" name='chk' type="checkbox" value='10' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Task sub Category </div>
                                    <div className='col-lg-3'><input id="column_4" name='chk' type="checkbox" value='11' onChange={(e) => checkBoxOnChange(e)} /> Periodicity </div>
                                    <div className='col-lg-3'><input id="column_5" name='chk' type="checkbox" value='12' onChange={(e) => checkBoxOnChange(e)} /> Criticality</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_14" name='chk' type="checkbox" value='13' onChange={(e) => checkBoxOnChange(e)} /> Division</div>
                                    <div className='col-lg-3'><input id="column_15" name='chk' type="checkbox" value='14' onChange={(e) => checkBoxOnChange(e)} /> Category</div>
                                    <div className='col-lg-3'><input id="column_16" name='chk' type="checkbox" value='15' onChange={(e) => checkBoxOnChange(e)} /> Assigned By</div>
                                    <div className='col-lg-3'><input id="column_17" name='chk' type="checkbox" value='16' onChange={(e) => checkBoxOnChange(e)} /> Assignee To</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_18" name='chk' type="checkbox" value='17' onChange={(e) => checkBoxOnChange(e)} /> Assigned Date</div>
                                    <div className='col-lg-3'><input id="column_19" name='chk' type="checkbox" value='18' onChange={(e) => checkBoxOnChange(e)} /> Assignee</div>
                                    <div className='col-lg-3'><input id="column_20" name='chk' type="checkbox" value='19' onChange={(e) => checkBoxOnChange(e)} /> Completed On</div>
                                    <div className='col-lg-3'><input id="column_21" name='chk' type="checkbox" value='20' onChange={(e) => checkBoxOnChange(e)} /> Concurrer </div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_22" name='chk' type="checkbox" value='21' onChange={(e) => checkBoxOnChange(e)} /> Concurrer On </div>
                                    <div className='col-lg-3'><input id="column_23" name='chk' type="checkbox" value='22' onChange={(e) => checkBoxOnChange(e)} /> Approver </div>
                                    <div className='col-lg-3'><input id="column_24" name='chk' type="checkbox" value='23' onChange={(e) => checkBoxOnChange(e)} /> Approver On </div>
                                    <div className='col-lg-3'><input id="column_25" name='chk' type="checkbox" value='24' onChange={(e) => checkBoxOnChange(e)} /> Activity Status</div>
                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_26" name='chk' type="checkbox" value='25' onChange={(e) => checkBoxOnChange(e)} /> Start Date</div>
                                    <div className='col-lg-3'><input id="column_27" name='chk' type="checkbox" value='26' onChange={(e) => checkBoxOnChange(e)} /> Due Date</div>
                                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='27' onChange={(e) => checkBoxOnChange(e)} />Extend Due Date </div>
                                    <div className='col-lg-3'><input id="column_28" name='chk' type="checkbox" value='28' onChange={(e) => checkBoxOnChange(e)} /> Task Completion Date / Document Issued Date </div>

                                </div>
                                <br />
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='29' onChange={(e) => checkBoxOnChange(e)} /> Month</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='30' onChange={(e) => checkBoxOnChange(e)} /> Validity Date</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='31' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Statutory Status</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='32' onChange={(e) => checkBoxOnChange(e)} /> Duration</div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='33' onChange={(e) => checkBoxOnChange(e)} /> Document Reference Number</div>
                                    <div className='col-lg-3'><input id="column_31" name='chk' type="checkbox" value='34' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> Remarks</div>

                                </div>
                                <div className="form-actions text-center popupbtncolour">
                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>
                                </div>

                            </Modal> */}
                            <Drawer title="Select Fields" width={500} className={'add-service-prv export-checkbox resize-drawer ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onClose={handleCancel} onCancel={handleCancel}>
                                <div className='d-flex flex-wrap'>
                                    <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                                        checked={checkboxValue.length === 34 ? true : false}
                                        onClick={(e) => {
                                            let checked = e.target.checked
                                            selectAllCheckBox(checked)
                                        }} /> <label for='select_all'>Select All</label>

                                    <input id="column_1" name='chk' type="checkbox" disabled="disabled" value='1' checked onChange={(e) => (e) => checkBoxOnChange(e)} /><label for='column_1'>  Unit Code</label>
                                    <input id="column_2" name='chk' type="checkbox" disabled="disabled" value='2' checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_2'>  Unit Name</label>
                                    <input id="column_7" name='chk' type="checkbox" value='3' checked={checkboxValue.includes(3)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_7'>  Unit Code 1</label>
                                    <input id="column_31" name='chk' type="checkbox" value='4' checked={checkboxValue.includes(4)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_31'>  Unit Code 2 </label>
                                    <input id="column_8" name='chk' type="checkbox" value='5' checked={checkboxValue.includes(5)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_8'>  Unit Code 3 </label>
                                    <input id="column_9" name='chk' type="checkbox" value='6' checked={checkboxValue.includes(6)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_9'>  Unit Code 4</label>
                                    <input id="column_10" name='chk' type="checkbox" disabled value='7' checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_10'>  Act / Rules </label>
                                    <input id="column_29" name='chk' type="checkbox" disabled="disabled" value='8' checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_29'>  Frequency</label>
                                    <input id="column_32" name='chk' type="checkbox" value='9' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_32'>Location </label>
                                    <input id="column_3" name='chk' type="checkbox" value='10' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_3'>  Compliance Task</label>
                                    <input id="column_4" name='chk' type="checkbox" value='11' checked={checkboxValue.includes(11)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_4'> Periodicity</label>
                                    <input id="column_5" name='chk' type="checkbox" value='12' checked={checkboxValue.includes(12)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_5'>  Criticality</label>
                                    <input id="column_6" name='chk' type="checkbox" value='13' checked={checkboxValue.includes(13)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_6'>  Division</label>
                                    <input id="column_11" name='chk' type="checkbox" value='14' checked={checkboxValue.includes(14)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_11'>  Category</label>
                                    <input id="column_12" name='chk' type="checkbox" value='15' checked={checkboxValue.includes(15)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_12'>  Assigned by </label>
                                    <input id="column_13" name='chk' type="checkbox" value='16' checked={checkboxValue.includes(16)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_13'>  Assigned To </label>
                                    <input id="column_14" name='chk' type="checkbox" value='17' checked={checkboxValue.includes(17)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_14'>  Assigned Date </label>
                                    <input id="column_15" name='chk' type="checkbox" value='18' checked={checkboxValue.includes(18)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_15'>  Assignee </label>
                                    <input id="column_16" name='chk' type="checkbox" value='19' checked={checkboxValue.includes(19)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_16'>  Completed on </label>
                                    <input id="column_17" name='chk' type="checkbox" value='20' checked={checkboxValue.includes(20)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_17'>  Concurrer </label>
                                    <input id="column_18" name='chk' type="checkbox" value='21' checked={checkboxValue.includes(21)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_18'>  Concurred on </label>
                                    <input id="column_19" name='chk' type="checkbox" value='22' checked={checkboxValue.includes(22)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_19'> Approver</label>
                                    <input id="column_20" name='chk' type="checkbox" value='23' checked={checkboxValue.includes(23)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_20'>   Approved on  </label>
                                    <input id="column_21" name='chk' type="checkbox" value='24' checked={checkboxValue.includes(24)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_21'> Activity Status</label>
                                    <input id="column_22" name='chk' type="checkbox" value='25' checked={checkboxValue.includes(25)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_22'>  Start Date </label>
                                    <input id="column_23" name='chk' type="checkbox" value='26' checked={checkboxValue.includes(26)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_23'>  Due Date</label>
                                    <input id="column_24" name='chk' type="checkbox" value='27' checked={checkboxValue.includes(27)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_24'> Extend Due Date</label>
                                    <input id="column_25" name='chk' type="checkbox" value='28' checked={checkboxValue.includes(28)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_25'>   Task Completion Date / Document Issued Date</label>
                                    <input id="column_26" name='chk' type="checkbox" value='29' checked={checkboxValue.includes(29)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_26'> Month</label>
                                    <input id="column_27" name='chk' type="checkbox" value='30' checked={checkboxValue.includes(30)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_27'>   Validity Date</label>
                                    <input id="column_28" name='chk' type="checkbox" value='31' disabled="disabled" checked onChange={(e) => checkBoxOnChange(e)} /><label for='column_28'>  Statutory Status</label>
                                    <input id="column_30" name='chk' type="checkbox" value='32' checked={checkboxValue.includes(25)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_30'>  Duration </label>
                                    <input id="column_38" name='chk' type="checkbox" value='33' checked={checkboxValue.includes(33)} onChange={(e) => checkBoxOnChange(e)} /><label for='column_38'> Document Reference Number </label>
                                    <input id="column_34" name='chk' checked={checkboxValue.includes(34)} type="checkbox" value='34' onChange={(e) => checkBoxOnChange(e)} /><label for='column_32'>  Remarks </label>

                                </div>
                                <div className="form-actions popupbtncolour text-center">
                                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                                        icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                                    </Button>

                                </div>
                            </Drawer>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
})
export default connect(mapStateToProps, {
    getFilterValues,
    getUnitData,
    getTaskwiseReportData,
    getTaskWiseExportData,
    getRemarksFilter
})(TaskWiseReport);