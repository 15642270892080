import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { EASY_UPLOAD_FILTERDATA, COMPLIANCE_UPCOMING_TASK, COMPLIANCE_TASK_CALENDER_VIEW, COMPLIANCE_CURRENT_TASK, COMPLIANCE_FILTER_SHOW, COMPLIANCE_VIEW,EASY_UPLOAD_SHOWDATA,EASY_UPLOAD_TABLEDATA } from '../../types/index'


const initialState = {
    complianceTaskDetails: {
        compliancefilter: [],
        compliancefiltershow: [],
        currentCompliance: [],
        upcomingTask: [],
        complianceshowdata:[],
        compliancetabledata:[]


    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case EASY_UPLOAD_FILTERDATA:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        compliancefilter: payload[1],
                    },
                };
            }
            case EASY_UPLOAD_SHOWDATA:
                {
                    return {
                        ...state,
                        complianceTaskDetails: {
                            ...state.complianceTaskDetails,
                            complianceshowdata: payload[1],
                        },
                    };
                }
                case EASY_UPLOAD_TABLEDATA:
                    {
                        return {
                            ...state,
                            complianceTaskDetails: {
                                ...state.complianceTaskDetails,
                                compliancetabledata: payload[1],
                            },
                        };
                    }
        case COMPLIANCE_TASK_CALENDER_VIEW:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        compliancefiltershow: payload[1],
                    },
                };
            }
        case COMPLIANCE_CURRENT_TASK:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        currentCompliance: payload[1],
                    },
                };
            }
        case COMPLIANCE_UPCOMING_TASK:
            {
                return {
                    ...state,
                    complianceTaskDetails: {
                        ...state.complianceTaskDetails,
                        upcomingTask: payload[1],
                    },
                };
            }
        default:
            return state;
    }
}