import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { Card, Divider, Tabs, Steps, Radio, Input, Row, Col, Table, Select, Modal, Button, Tooltip, DividerProps, Collapse } from 'antd';
import { ArrowRightOutlined, CheckOutlined, CloseOutlined, ArrowLeftOutlined, PlusCircleTwoTone, PlusOutlined, PlayCircleOutlined, PlusCircleOutlined, FullscreenOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { getUsermanagementDetails, getById, addUserDetails, selectHaveCompliance, addUserManagementDetails, EdithaveCompliance, edituserManagementDetails, getUsermanagementList } from './../../Store/Action/Master/UserManagement'
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import { Toaster } from '../../Libs/Toaster'
import FeatherIcon from 'feather-icons-react';
// import Usermanagement from "../Master/Usermanagement"
import Usermanagement from './Usermanagement';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import { blockInvalidChar, allowAlpha } from '../../Libs/Blockinvalidcharacter';
import { fullscreenexit, fullscreenmode } from './../../Libs/fullscreenmode';
import moment from 'moment';
import { uniq } from 'lodash';
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()
const _ = require("lodash");
const mapStateToProps = (state) => ({
    usermanagementList: state.UserManagement.userManagement,
    filterrecall: state.recallfilters.recallfilters

})
const AddUserManagement = ({
    getUsermanagementDetails,
    getById,
    addUserDetails,
    selectHaveCompliance,
    AddType,
    fullscreen,
    setfullscreen,
    setAddType,
    EdithaveCompliance,
    addUserManagementDetails,
    // setShowList,
    details,
    GetRemarksData,
    showList,
    edituserManagementDetails,
    filterrecall: { recallremarks },
    usermanagementList: { userManagementList, UserLegalDetails, EntityDetails, userManagementDetails, GetUserDetails, haveCompliance, count }
}) => {
    const [userfullscreen, setuserfullscreen] = useState('');
    const previous = () => {
        // setfullscreen(false);
        setTabValue({
            ...tabValue,
            compfie: false,
            compfie_litigation: false,
            compfie_vendor: false
        })
        setEntityValue([])
        setLitigationEntityValue([])
        setVendoreEntityValue([])
        setdomainValue([])
        setlitigationdomainValue([])
        setvendordomainValues([])
        setCategoryValues([])
        setVendorCategoryValues([])
        setDivisionValue([])
        setvendorDivisionValue([])
        setShowList(true)
    }
    const { Panel } = Collapse;
    const [categoryList, setcategoryList] = useState([]);
    const [errorField, setErrorField] = useState([])
    const [errorField1, setErrorField1] = useState([])
    const [errorField2, setErrorField2] = useState([])
    const [errorField3, setErrorField3] = useState([])
    const [errorField4, setErrorField4] = useState([])
    const currentdate = new Date();
    console.log(errorField4, 'errorField4');
    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    const entity_info = sessionValue.entity_info
    const [remarksModal, setRemarksModal] = useState(false);
    const [addChange, setAddChange] = useState(false)
    const [collapseAssignee, setCollapseAssignee] = useState(0)
    const [page, setPage] = useState(1);
    const [vendorcategoryList, setvendorcategoryList] = useState([]);
    const [unitListData, setUnitListData] = useState([]);
    const [vendorListData, setvendorListData] = useState([]);
    const [payloadEntityEdit, setpayloadEntityEdit] = useState([])
    const [payloadEditDomain, setPayloaEditdDomain] = useState([])
    const [payloadEditlitigationDomain, setpayloadEditlitigationDomain] = useState([])
    console.log(payloadEditlitigationDomain, 'payloadEditlitigationDomain');
    const [date, setDate] = useState(moment(currentdate).format('DD-MMM-YYYY'))
    const [payloadEditVendorDomain, setPayloaEditVendordDomain] = useState([])
    const [payloadEditunit, setPayloadEditunit] = useState([])
    console.log(UserLegalDetails, 'UserLegalDetails');
    console.log(EntityDetails, 'EntityDetails');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    // const isAuth = localStorage.getItem('isAuthenticated');
    const login = callername && callername.login_response;
    console.log(login.usr_cat_id, 'login');
    const [legalBg, setLegalBg] = useState([])
    const [litigationlegalBg, setLitigationLegalBg] = useState([])
    const [vendorlegalBg, setVendorLegalBg] = useState([])
    const [payloadEditClosedunit, setPayloadClosedEditunit] = useState([])
    console.log(payloadEditClosedunit, 'payloadEditClosedunit');
    console.log(payloadEditDomain, 'payloadEditDomain');
    console.log(details, 'details');
    console.log(payloadEntityEdit, 'payloadEntityEdit');
    const [bgTooltip, setBgToolTip] = useState([])
    const [entityTooltip, setEntityToolTip] = useState([])
    const [selectProduct, setSelectProduct] = useState([])
    const [vendorBgTooltip, setVendorBgToolTip] = useState([])
    const [litigationBgTooltip, setLitigationBgTooltip] = useState([])

    const [vendorEntityTooltip, setVendorEntityToolTip] = useState([])
    const [litigationEntityTooltip, setLitigationEntityTooltip] = useState([])
    const [domainTooltip, setDomainToolTip] = useState([])
    const [vendorDomainTooltip, setVendorDomainToolTip] = useState([])
    const [litigationDomainTooltip, setLitigationDomainTooltip] = useState([])
    const [divisionTooltip, setDivisionToolTip] = useState([])
    const [vendordivisionTooltip, setVendorDivisionToolTip] = useState([])
    const [catTooltip, setCatTooltip] = useState([])
    const [vendorcatTooltip, setVedorCatTooltip] = useState([])
    const [unitTooltip, setUnitTooltip] = useState([])
    const [unitClosedTooltip, setunitClosedTooltip] = useState([])
    const [vendorTooltip, setVedorTooltip] = useState([])
    const [vendorclosedTooltip, setVedorClosedTooltip] = useState([])
    console.log(catTooltip, 'catTooltip');
    const [payloadLitigationEntityEdit, setpayloadLitigationEntityEdit] = useState([])
    console.log(payloadLitigationEntityEdit, 'payloadLitigationEntityEdit');
    const [payloadVendorEntityEdit, setpayloadVendorEntityEdit] = useState([])
    console.log(payloadVendorEntityEdit, 'payloadVendorEntityEdit');
    const [unitAccess, setunitAccess] = useState(false);
    const [vendorAccess, setVendorAccess] = useState(false);
    console.log(vendorAccess, 'vendorAccess');
    console.log(unitAccess, 'unitAccess');
    const [LegalEntityList, setLegalEntityList] = useState([]);
    const [LitigationLegalEntityList, setLitigationLegalEntityList] = useState([]);
    const [DomainListData, setDomainListData] = useState([]);
    const [editEntity, setEditEntity] = useState([])
    const [editEntityValue, setEditEntityValue] = useState([])
    const [allUnitData, setAllUnitData] = useState([])
    const [allvendorData, setAllVendorData] = useState([])
    console.log(allvendorData, 'allvendorData');
    const [vendorAllUnitData, setvendorAllUnitData] = useState([])
    const [LitigationDomainList, setLitigationDomainList] = useState([]);
    const [VendorDomainList, setVendorDomainList] = useState([]);
    const [vendorEntityList, setVendorLegalEntityList] = useState([]);
    console.log(unitListData, 'unitListData');
    const [usergroupcompfie, setUserGroupCompfie] = useState([])
    const [vendorgroupcompfie, setVendorgroupcompfie] = useState([])
    const [showList1, setShowList] = useState(false)
    const { Option, OptGroup } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const [all, setAll] = useState([]);
    const [vendorAll, setVendorAll] = useState([])
    console.log(vendorAll, 'vendorAll');
    const [entityValue, setEntityValue] = useState([])
    const [businessGroupData, setBusinessGroupData] = useState([])
    const [businessGroupValue, setbusinessGroupValue] = useState([])
    const [unitDatas, setunitDatas] = useState([])
    const [CloseUnitDAta, setCloseUnitDAta] = useState([])
    const [vendorunitDatas, setvendorunitDatas] = useState([])
    const [vendorCloseUnitDAta, setvendorCloseUnitDAta] = useState([])
    const [litigationbusinessGroupData, setlitigationbusinessGroupData] = useState([])
    const [lititigationbusinessGroupValue, setlititigationbusinessGroupValue] = useState([])
    console.log(lititigationbusinessGroupValue, 'lititigationbusinessGroupValue');
    const [vendorbusinessGroupData, setVendorBusinessGroupData] = useState([])
    const [vendorbusinessGroupValue, setVendorbusinessGroupValue] = useState([])
    const [litigationEntityValue, setLitigationEntityValue] = useState([])
    console.log(litigationEntityValue, 'litigationEntityValue');
    const [vendoreEntityValue, setVendoreEntityValue] = useState([])
    console.log(vendoreEntityValue, 'vendoreEntityValue');
    const [vendorClosedAll, setVendorClosedAll] = useState([])
    const [domainValue, setdomainValue] = useState([])
    console.log(domainValue, 'domainValuedomainValuedomainValuedomainValue');
    const [litigationdomainValue, setlitigationdomainValue] = useState([])
    const [vendordivisionData, setvendordivisionData] = useState([])
    const [divisionData, setdivisionData] = useState([])
    const [vendordomainValue, setvendordomainValues] = useState([])

    const [vendorDivisionValue, setvendorDivisionValue] = useState([])
    console.log(vendorDivisionValue, 'vendorDivisionValue');
    console.log(entity_info, 'entity_info');
    const [DivisionValue, setDivisionValue] = useState([])
    const [categoryValues, setCategoryValues] = useState([])
    const [VendorcategoryValues, setVendorCategoryValues] = useState([])
    const [closedAll, setclosedAll] = useState([]);
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');
    const [domainEntity, setdomainEntity] = useState([])
    const [litigationdomainEntity, setlitigationdomainEntity] = useState([])
    const [vendordomainEntity, setvendordomainEntity] = useState([])
    console.log(domainEntity, 'domainEntitydomainEntity');
    const [LitigationEntityselectcheckbox, SetLitigationEntityselectcheckbox] = useState('block');
    const [LitigationEntitysunselectcheckbox, SetLitigationEntityunselectcheckbox] = useState('none');
    const [VendorEntityselectcheckbox, SetVendorEntityselectcheckbox] = useState('block');
    const [VendorEntityunselectcheckbox, SetVendorEntityunselectcheckbox] = useState('none');
    const [domainselectcheckbox, SetDomainselectcheckbox] = useState('block');
    const [domainunselectcheckbox, SetDomainunselectcheckbox] = useState('none');
    const [litigationdomainselectcheckbox, Setlitigationdomainselectcheckbox] = useState('block');
    const [litigationdomainunselectcheckbox, SetlitigationDomainunselectcheckbox] = useState('none');
    const [vendordomianselectcheckbox, SetvendorDomainselectcheckbox] = useState('block');
    const [Vendordomainunselectcheckbox, Setvendorunselectcheckbox] = useState('none');
    const [categoryselectcheckbox, Setcategoryselectcheckbox] = useState('block');
    const [categoryunselectcheckbox, Setcategoryunselectcheckbox] = useState('none');
    const [divisionselectcheckbox, Setdivisionselectcheckbox] = useState('block');
    const [divisionunselectcheckbox, Setdivisionunselectcheckbox] = useState('none');
    const [vendorbusinessGroupselectcheckbox, SetvendorbusinessGroupselectcheckbox] = useState('block');
    const [vendorbusinessGroupUnselectcheckbox, SetvendorbusinessGroupUnselectcheckbox] = useState('none');
    const [businessGroupselectcheckbox, SetbusinessGroupselectcheckbox] = useState('block');
    const [businessGroupUnselectcheckbox, SetbusinessGroupUnselectcheckbox] = useState('none');
    const [litigationbusinessGroupselectcheckbox, SetlitigationbusinessGroupselectcheckbox] = useState('block');
    const [litigationbusinessGroupunselectcheckbox, SetlitigationbusinessGroupunselectcheckbox] = useState('none');
    const [vendordivisionselectcheckbox, Setvendordivisionselectcheckbox] = useState('block');
    const [vendordivisionunselectcheckbox, Setvendordivisionunselectcheckbox] = useState('none');
    const [vendorcategoryselectcheckbox, Setvendorcategoryselectcheckbox] = useState('block');
    const [vendorcategoryunselectcheckbox, Setvendoruncategoryselectcheckbox] = useState('none');
    const [unitselectcheckbox, Setunitselectcheckbox] = useState('block');
    const [unitunselectcheckbox, Setunitunselectcheckbox] = useState('none');
    const [closeunitselectcheckbox, Setclosedunitselectcheckbox] = useState('block');
    const [closedunitunselectcheckbox, Setclosedunitunselectcheckbox] = useState('none');
    const [vendorunitselectcheckbox, Setvendorunitselectcheckbox] = useState('block');
    const [vendorunitunselectcheckbox, Setvendorunitunselectcheckbox] = useState('none');
    const [vendorcloseunitselectcheckbox, Setvendorclosedunitselectcheckbox] = useState('block');
    const [vendorclosedunitunselectcheckbox, SetVendorclosedunitunselectcheckbox] = useState('none');
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const entityid = localStorage.getItem('SelectedEntityid');
    const { TabPane } = Tabs;
    const { Search } = Input;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [categoryFormSubmit, setCategoryFormSubmit] = useState(false);
    const [LitigationFormSubmit, setLitigationFormSubmit] = useState(false);
    const [active, setActive] = useState('1')
    const [activeUnit, setActiveUnit] = useState(false)
    const [selectUnit, setSelectUnit] = useState(false);
    const [closedUnitData, setClosedtUnitData] = useState([]);
    const [selectUnitData, setSelectUnitData] = useState([]);
    const [selectUnitList, setselectUnitList] = useState([]);
    const [closedselectUnitList, setclosedselectUnitList] = useState([]);
    console.log(closedselectUnitList, 'closedselectUnitList');
    const [vendorUnitList, setvendorUnitList] = useState([]);
    const [vendorClosedUnitList, setcvendorClosedUnitList] = useState([]);
    console.log(vendorClosedUnitList, 'vendorClosedUnitList');
    const [closedOption, setClosedOption] = useState([])
    const [categoryDatas, setCategoryDatas] = useState([])
    console.log(categoryDatas, 'categoryDatas');
    const [litigationcategoryDatas, setlitigationcategoryDatas] = useState([])
    const [vendorcategoryDatas, setvendorcategoryDatas] = useState([])

    const [EntityData, setEntityData] = useState([])
    const [litigationEntityData, setlitigationEntityData] = useState([])
    const [vendorEntityData, setvendorEntityData] = useState([])
    console.log(vendorEntityData, 'EntityDataEntityDataEntityData');
    const [domainData, setDomainData] = useState([])
    const [litigationDomainData, setLitigationDomainData] = useState([])
    const [VendorDomainData, setVendorDomainData] = useState([])
    const [vendorcategory, setVendorCategory] = useState([
    ])

    const [unitEntity, setUnitEntity] = useState([])
    const [closedEntityUnit, setclosedEntityUnit] = useState([])
    const [vendorunitEntity, setvendorunitEntity] = useState([])
    const [vendorClosedunitEntity, setvendorClosedunitEntity] = useState([])
    const [domainname, setDomainName] = useState([])
    const [domainlist, setDomainList] = useState([])
    const [options, setOptions] = useState([{
        label: '',
        value: '',

    }])
    const [VendorEntityOption, setVendorEntityOption] = useState([{
        label: '',
        value: '',

    }])
    const [Entityoptions, setEntityOptions] = useState([{
        label: '',
        value: '',
    }])
    console.log(domainEntity, 'domainEntitydomainEntity');
    console.log(DivisionValue, 'DivisionValue');
    const [Domainoptions, setDomainOptions] = useState([{
        label: '',
        value: '',
        legal: "",
        u_dm_id: "",
        legal_id: ""
    }])
    console.log(GetUserDetails, 'GetUserDetails');
    const [VendorDomainoptions, setVendorDomainOptions] = useState([{
        label: '',
        value: '',
    }])
    const [LitigationDomainoptions, setLitigationDomainOptions] = useState([{
        label: '',
        value: '',
    }])
    const [categoryoptions, setCategoryOptions] = useState([{
        label: '',
        value: '',
    }])
    const [LitigationEntityoptions, setLitigationEntityOptions] = useState([{
        label: '',
        value: '',
    }])
    const [Vendoroptions, setVendorOptions] = useState([{
        label: '',
        value: '',

    }])
    const [vendorselected, setvendorSelected] = useState([]);
    const [vendorClosedOption, setvendorClosedOption] = useState([{
        label: '',
        value: '',

    }])
    const [DivisionOption, setDivisionOption] = useState([{
        label: '',
        value: '',

    }])
    const [divisions, setDivisions] = useState([])

    const [vendorcloseselected, setVendorCloseSelected] = useState([]);
    const [VendorEntity, setVendorEntity] = useState([])
    const [selected, setSelected] = useState([]);
    const [closedSelected, setClosedSelected] = useState([])
    const [DomainValue, setDomainValue] = useState([]);
    const [VendorDomainValue, setvendorDomainValue] = useState([]);
    const [LitigationDomainValue, setLitigationDomainValue] = useState([]);
    const [domainPayload, setDomainPayload] = useState([])
    const [litigationdomainPayload, setLitigationDomainPayload] = useState([])
    const [vendordomainPayload, setVendorDomainPayload] = useState([])
    console.log(LitigationDomainValue, "VendorDomainValue");
    console.log(usergroupcompfie, "usergroupcompfie");
    const [compfieCategory, setcompfieCategory] = useState([]);
    const [closeselected, setCloseSelected] = useState([]);
    const [vendorcategories, setVendorCategories] = useState([
    ])
    const [legalEntity, setLegalEntity] = useState([])
    const [vendorlegalEntity, setvendorLegalEntity] = useState([])
    console.log(vendorlegalEntity, 'vendorlegalEntity');
    console.log(legalEntity, 'legalEntity');
    const [litigationlegalEntity, setlitigationLegalEntity] = useState([])
    const [tab, setTab] = useState()
    const [tabboolean, setTabBoolean] = useState(false)
    const [domain, setDomain] = useState([])
    const [userDetails, setUserDetails] = useState({
        emp_name: '',
        emp_contact_country_code: '',
        emp_contact_code: '',
        emp_contact_no: '',
        emp_id: '',
        mobile_no_country_code: '',
        mobile_no: '',
        email: '',
        remarks: '',
        emp_code: '',
        otp: 0

    })
    const [tabValue, setTabValue] = useState({
        compfie: false,
        compfie_litigation: false,
        compfie_vendor: false
    })
    const [mainTab, setMainTab] = useState(false)
    const [category, setCategory] = useState({
        compfie: '',
        compie_litigation: '',
        compfie_vendor: ''
    })

    const [compfie, setCompfie] = useState({
        category: null,
        seating_Unit: null,
        user_Group: '',
        user_level: '1',
        entity: '',
        serviceProvider: '',
        checked_value: '',
        legal_Entity: [],
        Domain: [],
        domain_name: "",
        Division: '',
        businessGroup: ''

        // domain:'',

    })
    console.log(compfie, 'compfiecompfiecompfie');
    const handleCancel = () => {
        setRemarksModal(false)
    };
    const [compfieVendorValue, setCompfieVendorValue] = useState({
        category: null,
        seating_Unit: null,
        user_Group: null,
        user_level: '1',
        entity: '',
        serviceProvider: null,
        checked_value: '',
        legal_Entity: [],
        Domain: [],
        Division: '',

        // domain:'',

    })
    const [compfieLitigation, setCompfieLitigation] = useState({
        category: null,
        user: AddType == 'edit' ? 'Inhouse User' : '',
        legal_Entity: [],
        Domain: [],
        businessGroup: '',
        serviceProvider: ''
    })
    const [compfieDomain, setCompfieDomain] = useState([])
    const [vendorDomain, setVendorDomain] = useState([])
    const [categoryValue, setCategoryValue] = useState({
        category: [],
        seatingUnit: [],
        serviceProvider: [],
        legalEnity: [],
        userGroup: [],
        domain: []
    })
    const [compfieLitigationCategory, setcompfieLitigationCategory] = useState({
        // category: [],
        // seatingUnit: [],
        // serviceProvider: [],
        legalEnity: [],
        // userGroup: [],
        domain: []
    })
    const [EditCompfieVendor, setEditCompfieVendor] = useState({
        category: [],
        seatingUnit: [],
        legalEnity: [],
        userGroup: [],
        domain: []
    })
    const [vendorUnitDetails, setVendorUnitDetails] = useState([])
    console.log(vendorUnitDetails, 'vendorUnitDetails');
    const [vendorClosedUnitDetails, setVendorClosedUnitDetails] = useState([])
    const [vendorUnit, setVendorUnit] = useState([])
    const [vendorClosedUnit, setVendorClosedUnit] = useState([])
    const [value, setValue] = useState({
        userGroup: '',
        seatingUnit: '',
        userLevel: '',
        businessGroup: '',
        legalEnity: "",
        category: '',
        domain: '',
        division: ''
    })

    const [userUnits, setUserUnits] = useState([

    ])
    console.log(userUnits, 'userUnits');
    const [userClosedUnits, setClosedUserUnits] = useState([

    ])
    const [remarksvalue, Setremarksvalue] = useState();
    console.log(userClosedUnits, 'userClosedUnits');
    console.log(remarksvalue, 'remarksvalue');
    const validator = useRef(new SimpleReactValidator());
    const Formvalidator = useRef(new SimpleReactValidator());
    const LitigationFormvalidator = useRef(new SimpleReactValidator());
    console.log(legalEntity, 'legalEntitylegalEntity');
    const onChange = (e) => {
        setUserDetails({
            ...userDetails,
            otp: e.target.value
        })
    };
    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])
    const RemarksColumn = [
        {
            title: '#',
            dataIndex: false,
            width: '18px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            },

        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '100px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                setUserDetails({
                    ...userDetails,
                    remarks: record.r_description
                })
                setRemarksModal(false)
            }}>{record.r_description}</a>,

        },
    ];
    const onUserDetailOnChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value
        })
        setActiveUnit(false)
        setAddFormSubmit(false)
    }
    console.log(domainEntity, 'domainEntitydomainEntitydomainEntity');

    console.log(litigationdomainValue, 'litigationdomainValue1233444');
    console.log(compfieLitigation, 'compfieLitigation12333');
    console.log(divisionData, 'divisionData');
    console.log(domainValue, 'domainValue');
    console.log(GetUserDetails && GetUserDetails.ul_user_units.length, 'GetUserDetails && GetUserDetails.ul_user_units.length');
    useEffect(() => {
        if (compfie.category == '2' || compfie.category == '7' || compfie.category == "View Only" || compfie.category == "View OnlyResticted") {
            if (allUnitData && allUnitData.length > 0) {
                if (((allUnitData.length) == (userManagementDetails && userManagementDetails.um_legal_units.length))) {
                    setunitAccess(true)
                }

            }
            if (GetUserDetails && GetUserDetails.ul_user_units && GetUserDetails.ul_user_units.length == 0) {
                setunitAccess(true)
            }
        }
    }, [allUnitData, GetUserDetails, compfie.category])


    const fullscreenMode = () => {
        if (fullscreen == true) {
            fullscreenmode();
            setfullscreen(false);
            setAddChange(true)
            setuserfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(true);
            setAddChange(true);
            setuserfullscreen(false);
        }
    }

    useEffect(() => {
        if (fullscreen == true) {
            fullscreenMode()

        }
    }, [])


    //Tool tip for select field


    useEffect(() => {
        let temp = []
        if (entityValue.length > 0) {
            for (let i in entityValue) {
                let data = _.filter(EntityData, { le_id: entityValue[i] })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].le_name)
                }

            }
            if (temp.length > 0) {
                setEntityToolTip(temp.join(' , '))
            }

        }
    }, [entityValue, EntityData])
    useEffect(() => {
        let temp = []
        if (businessGroupValue.length > 0) {
            for (let i in businessGroupValue) {
                let data = _.filter(businessGroupData, { bg_id: Number(businessGroupValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].bg_name)
                }

            }
            if (temp.length > 0) {
                setBgToolTip(temp.join(', '))
            }

        }
    }, [businessGroupValue, businessGroupData])
    useEffect(() => {
        let temp = []
        if (lititigationbusinessGroupValue.length > 0) {
            for (let i in lititigationbusinessGroupValue) {
                let data = _.filter(litigationbusinessGroupData, { bg_id: Number(lititigationbusinessGroupValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].bg_name)
                }

            }
            if (temp.length > 0) {
                setLitigationBgTooltip(temp.join(' , '))
            }

        }
    }, [lititigationbusinessGroupValue, litigationbusinessGroupData])
    useEffect(() => {
        let temp = []
        if (vendorbusinessGroupValue.length > 0) {
            for (let i in vendorbusinessGroupValue) {
                let data = _.filter(vendorbusinessGroupData, { bg_id: Number(vendorbusinessGroupValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].bg_name)
                }

            }
            if (temp.length > 0) {
                setVendorBgToolTip(temp.join(' , '))
            }

        }
    }, [vendorbusinessGroupValue, vendorbusinessGroupData])
    useEffect(() => {
        let temp = []
        if (litigationEntityValue.length > 0) {
            for (let i in litigationEntityValue) {
                let data = _.filter(litigationEntityData, { le_id: litigationEntityValue[i] })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].le_name)
                }

            }
            if (temp.length > 0) {
                setLitigationEntityTooltip(temp.join(',  '))
            }

        }
    }, [litigationEntityValue, litigationEntityData])
    useEffect(() => {
        let temp = []
        if (vendoreEntityValue.length > 0) {
            for (let i in vendoreEntityValue) {
                let data = _.filter(vendorEntityData, { le_id: vendoreEntityValue[i] })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].le_name)
                }

            }
            if (temp.length > 0) {
                setVendorEntityToolTip(temp.join(',  '))
            }

        }
    }, [vendoreEntityValue, vendorEntityData])
    useEffect(() => {
        let temp = []
        if (domainPayload.length > 0) {
            for (let i in domainPayload) {
                let data = _.filter(domainData, { u_dm_id: Number(domainPayload[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_dm_name)
                }

            }
            if (temp.length > 0) {
                setDomainToolTip(temp.join(',  '))
            }

        }
    }, [domainPayload, domainData])
    useEffect(() => {
        let temp = []
        if (litigationdomainPayload.length > 0) {
            for (let i in litigationdomainPayload) {
                let data = _.filter(litigationDomainData, { u_dm_id: Number(litigationdomainPayload[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_dm_name)
                }

            }
            if (temp.length > 0) {
                setLitigationDomainTooltip(temp.join(',  '))
            }

        }
    }, [litigationdomainPayload, litigationDomainData])
    useEffect(() => {
        let temp = []
        if (vendordomainPayload.length > 0) {
            for (let i in vendordomainPayload) {
                let data = _.filter(VendorDomainData, { u_dm_id: Number(vendordomainPayload[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_dm_name)
                }

            }
            if (temp.length > 0) {
                setVendorDomainToolTip(temp.join(',  '))
            }

        }
    }, [vendordomainPayload, VendorDomainData])
    useEffect(() => {
        let temp = []
        if (DivisionValue.length > 0) {
            for (let i in DivisionValue) {
                let data = _.filter(divisionData, { d_id: Number(DivisionValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].d_name)
                }

            }
            if (temp.length > 0) {
                setDivisionToolTip(temp.join(',  '))
            }

        }
    }, [DivisionValue, divisionData])
    useEffect(() => {
        let temp = []
        if (vendorDivisionValue.length > 0) {
            for (let i in vendorDivisionValue) {
                let data = _.filter(vendordivisionData, { d_id: Number(vendorDivisionValue[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].d_name)
                }

            }
            if (temp.length > 0) {
                setVendorDivisionToolTip(temp.join(',  '))
            }

        }
    }, [vendorDivisionValue, vendordivisionData])
    useEffect(() => {
        let temp = []
        if (categoryValues.length > 0) {
            for (let i in categoryValues) {
                let data = _.filter(categoryDatas, { cat_id: Number(categoryValues[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].cat_name)
                }

            }
            if (temp.length > 0) {
                setCatTooltip(temp.join(',  '))
            }

        }
    }, [categoryValues, categoryDatas])
    useEffect(() => {
        let temp = []
        if (VendorcategoryValues.length > 0) {
            for (let i in VendorcategoryValues) {
                let data = _.filter(vendorcategoryDatas, { cat_id: Number(VendorcategoryValues[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].cat_name)
                }

            }
            if (temp.length > 0) {
                setVedorCatTooltip(temp.join(',  '))
            }

        }
    }, [VendorcategoryValues, vendorcategoryDatas])

    useEffect(() => {
        let temp = []
        if (all.length > 0) {
            for (let i in all) {
                let data = _.filter(selectUnitData, { u_unt_id: Number(all[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_unt_code + ' - ' + data[0].u_unt_name + ' - ' + data[0].u_unt_address)
                }

            }
            if (temp.length > 0) {
                setUnitTooltip(temp.join(',  '))
            }

        }
    }, [all, selectUnitData])

    useEffect(() => {
        let temp = []
        if (closedAll.length > 0) {
            for (let i in all) {
                let data = _.filter(closedUnitData, { u_unt_id: Number(closedAll[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_unt_code + ' - ' + data[0].u_unt_name + ' - ' + data[0].u_unt_address)
                }

            }
            if (temp.length > 0) {
                setunitClosedTooltip(temp.join(',  '))
            }

        }
    }, [closedAll, closedUnitData])
    useEffect(() => {
        let temp = []
        if (vendorAll.length > 0) {
            for (let i in vendorAll) {
                let data = _.filter(vendorUnit, { u_unt_id: Number(vendorAll[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_unt_code + ' - ' + data[0].u_unt_name + ' - ' + data[0].u_unt_address)
                }

            }
            if (temp.length > 0) {
                setVedorTooltip(temp.join(',  '))
            }

        }
    }, [vendorAll, vendorUnit])
    useEffect(() => {
        let temp = []
        if (vendorClosedAll.length > 0) {
            for (let i in vendorClosedAll) {
                let data = _.filter(vendorClosedUnit, { u_unt_id: Number(vendorClosedAll[i]) })
                console.log(data, 'data9999999');
                if (data && data.length > 0) {
                    temp.push(data[0].u_unt_code + ' - ' + data[0].u_unt_name + ' - ' + data[0].u_unt_address)
                }

            }
            if (temp.length > 0) {
                setVedorClosedTooltip(temp.join(',  '))
            }

        }
    }, [vendorClosedAll, vendorClosedUnit])

    console.log(allvendorData, 'allvendorData');
    console.log(userManagementDetails && userManagementDetails.um_vndr_legal_units, 'userManagementDetails && userManagementDetails.um_vndr_legal_units');

    //Vor user Condition
    useEffect(() => {
        if (compfieVendorValue.category || compfieVendorValue.category == '7' || compfieVendorValue.category == '2' || compfieVendorValue.category == "View Only" || compfieVendorValue.category == "View OnlyResticted") {
            if (allvendorData && allvendorData.length > 0) {
                if (((allvendorData.length) == (userManagementDetails && userManagementDetails.um_vndr_legal_units.length))) {
                    setVendorAccess(true)
                }

            }
            if (GetUserDetails && GetUserDetails.vend_unitlist && GetUserDetails.vend_unitlist.length == 0) {
                setVendorAccess(true)
            }
        }
    }, [allvendorData, GetUserDetails])
    useEffect(() => {
        let temp = []
        let temp2 = []
        if (AddType == 'edit') {
            if (GetUserDetails && GetUserDetails.ul_user_units && GetUserDetails.ul_user_units.length > 0) {
                for (let i in GetUserDetails && GetUserDetails.ul_user_units) {
                    temp.push({
                        le_id: GetUserDetails && GetUserDetails.ul_user_units[i].le_id,
                        u_id: GetUserDetails && GetUserDetails.ul_user_units[i].u_unt_id
                    })

                }

                setAllUnitData(temp)
            }
            if (GetUserDetails && GetUserDetails.vend_unitlist && GetUserDetails.vend_unitlist.length > 0) {
                console.log(GetUserDetails && GetUserDetails.vend_unitlist, 'GetUserDetails && GetUserDetails.vend_unitlist');
                // if (category.compfie_vendor == '7') {
                for (let i in GetUserDetails && GetUserDetails.vend_unitlist) {
                    temp2.push({
                        le_id: GetUserDetails && GetUserDetails.vend_unitlist[i].le_id,
                        u_id: GetUserDetails && GetUserDetails.vend_unitlist[i].u_unt_id
                    })
                }
                console.log(temp2, 'temptemptemptemp');
                // }

                setAllVendorData(temp2)
            }



        }

        else {
            if (compfie.category == "View Only Restricted" || compfie.category == "View Only" || compfie.category == 2 || compfie.category == '7') {
                if (userManagementDetails.um_legal_units && userManagementDetails.um_legal_units && userManagementDetails.um_legal_units.length > 0) {
                    for (let i in userManagementDetails.um_legal_units) {
                        temp.push({
                            le_id: userManagementDetails.um_legal_units[i].le_id,
                            u_id: userManagementDetails.um_legal_units[i].u_unt_id
                        })
                    }
                }

                setAllUnitData(temp)
            }
            if (userManagementDetails && userManagementDetails.um_vndr_legal_units.length > 0) {
                if (category.compfie_vendor == '7' || category.compfie_vendor == '2') {
                    for (let i in userManagementDetails && userManagementDetails.um_vndr_legal_units) {
                        temp2.push({
                            le_id: userManagementDetails && userManagementDetails.um_vndr_legal_units[i].le_id,
                            u_id: userManagementDetails && userManagementDetails.um_vndr_legal_units[i].u_unt_id
                        })
                    }
                }

                setAllVendorData(temp2)
            }

        }
    }, [userManagementDetails && userManagementDetails.um_legal_units, category.compfie_vendor, compfie.category, GetUserDetails && GetUserDetails.vend_unitlist
    ])
    console.log(allUnitData, 'allUnitData');

    //select field option(title name)

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];

        for (let i in entityValue) {
            let entityData = _.filter(EntityData && EntityData.length > 0 && EntityData, { le_id: entityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            divisionData && divisionData.length > 0 && divisionData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setUnitListData(countrylisttempdataunit)


    }, [divisionData, EntityData, entityValue])
    console.log(selectUnitData, 'selectUnitData');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];

        for (let i in entityValue) {
            let entityData = _.filter(EntityData && EntityData.length > 0 && EntityData, { le_id: entityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            selectUnitData && selectUnitData.length > 0 && selectUnitData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_unt_id': subunitlist.u_unt_id,
                        'u_unt_code': subunitlist.u_unt_code,
                        'u_unt_name': subunitlist.u_unt_name,
                        'u_unt_address': subunitlist.u_unt_address,
                        'le_id': subunitlist.le_id,
                        "d_id": subunitlist.d_id,
                        "cat_id": subunitlist.cat_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_unt_name,
                        'values': subunitlist.u_unt_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })


            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setselectUnitList(countrylisttempdataunit)


    }, [selectUnitData, EntityData, entityValue])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];

        for (let i in entityValue) {
            let entityData = _.filter(EntityData && EntityData.length > 0 && EntityData, { le_id: entityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            closedUnitData && closedUnitData.length > 0 && closedUnitData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_unt_id': subunitlist.u_unt_id,
                        'u_unt_code': subunitlist.u_unt_code,
                        'u_unt_address': subunitlist.u_unt_address,
                        'u_unt_name': subunitlist.u_unt_name,
                        'le_id': subunitlist.le_id,
                        "d_id": subunitlist.d_id,
                        "cat_id": subunitlist.cat_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_unt_name,
                        'values': subunitlist.u_unt_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(tempArrayunit, 'tempArrayunit');
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setclosedselectUnitList(countrylisttempdataunit)


    }, [closedUnitData, EntityData, entityValue])



    console.log(vendoreEntityValue, 'vendorUnit99999999999999999999');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        console.log(vendorUnit, 'vendorUnit');
        for (let i in vendoreEntityValue) {
            let entityData = _.filter(vendorEntityData && vendorEntityData.length > 0 && vendorEntityData, { le_id: vendoreEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            vendorUnit && vendorUnit.length > 0 && vendorUnit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_unt_id': subunitlist.u_unt_id,
                        'u_unt_code': subunitlist.u_unt_code,
                        'u_unt_name': subunitlist.u_unt_name,
                        'u_unt_address': subunitlist.u_unt_address,
                        'le_id': subunitlist.le_id,
                        "d_id": subunitlist.d_id,
                        "cat_id": subunitlist.cat_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_unt_name,
                        'values': subunitlist.u_unt_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })
            console.log(pmenu, 'pmenu12345678888888888');
            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwaryavvvvvvvvvvvvv');
            console.log(totalListunit, 'unitListData55555555')
            countrylisttempdataunit.push(totalListunit)
        })
        setvendorUnitList(countrylisttempdataunit)


    }, [vendorUnit, vendorEntityData, vendoreEntityValue])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];

        for (let i in vendoreEntityValue) {
            let entityData = _.filter(vendorEntityData && vendorEntityData.length > 0 && vendorEntityData, { le_id: vendoreEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            vendorClosedUnit && vendorClosedUnit.length > 0 && vendorClosedUnit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_unt_id': subunitlist.u_unt_id,
                        'u_unt_code': subunitlist.u_unt_code,
                        'u_unt_name': subunitlist.u_unt_name,
                        'u_unt_address': subunitlist.u_unt_address,
                        'le_id': subunitlist.le_id,
                        "d_id": subunitlist.d_id,
                        "cat_id": subunitlist.cat_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_unt_name,
                        'values': subunitlist.u_unt_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData55555555')
            countrylisttempdataunit.push(totalListunit)
        })
        setcvendorClosedUnitList(countrylisttempdataunit)


    }, [vendorClosedUnit, vendorEntityData, vendoreEntityValue])
    console.log(selectUnitList, 'selectUnitList');
    console.log(vendorUnitList, 'vendorUnitList');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let divisionDatas = []

        console.log(divisionDatas, 'divisionDatassssss');
        for (let i in entityValue) {
            let entityData = _.filter(EntityData && EntityData.length > 0 && EntityData, { le_id: entityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])

            console.log(arrunit, 'arrunit');
        }
        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            categoryDatas && categoryDatas.length > 0 && categoryDatas.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setcategoryList(countrylisttempdataunit)


    }, [categoryDatas, EntityData, entityValue])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let divisionDatas = []
        console.log(divisionDatas, 'divisionDatassssss');
        for (let i in vendoreEntityValue) {
            let entityData = _.filter(vendorEntityData && vendorEntityData.length > 0 && vendorEntityData, { le_id: vendoreEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])

            console.log(arrunit, 'arrunit');
        }
        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            vendorcategoryDatas && vendorcategoryDatas.length > 0 && vendorcategoryDatas.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setvendorcategoryList(countrylisttempdataunit)


    }, [vendorcategoryDatas, vendorEntityData, vendoreEntityValue])
    console.log(categoryList, 'categoryList');
    console.log(vendorcategoryList, 'vendorcategoryList');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let entityArrayunit = []
        let tempArrayunit = []
        console.log(legalBg, 'legalBg');

        if (businessGroupData.length > 0) {
            if (legalBg && legalBg.length > 0 && legalBg.includes(null) == false) {
                if (businessGroupValue && businessGroupValue.length > 0) {


                    for (let i in businessGroupValue) {
                        let entityData = _.filter(businessGroupData && businessGroupData.length > 0 && businessGroupData, { bg_id: Number(businessGroupValue[i]) })
                        console.log(entityData, 'entityData');
                        arrunit.push(entityData[0])
                    }
                    console.log(arrunit, 'arrunit');

                    arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
                        let entityArrayunit = []
                        let tempArrayunit = []

                        EntityData && EntityData.length > 0 && EntityData.map((subunitlist) => {
                            console.log(subunitlist, 'subunitlist');
                            if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                                entityArrayunit = {
                                    'bg_id': subunitlist.bg_id,
                                    'le_name': subunitlist.le_name,
                                    'le_id': subunitlist.le_id,

                                    'label': subunitlist.le_name,
                                    'values': subunitlist.le_id
                                }
                                tempArrayunit.push(entityArrayunit)
                            }
                        })

                        totalListunit = {
                            'label': pmenu && pmenu.bg_name == null ? 'Others' : pmenu && pmenu.bg_name,
                            'options': tempArrayunit
                        }
                        console.log(totalListunit, 'aishwarya');
                        console.log(totalListunit, 'unitListData')
                        countrylisttempdataunit.push(totalListunit)
                    })
                    setLegalEntityList(countrylisttempdataunit)

                }
            }

            else {

                console.log('sneha');
                let data = []


                EntityData && EntityData.length > 0 && EntityData.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');
                    // if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                    entityArrayunit = [{
                        'bg_id': subunitlist.bg_id,
                        'le_name': subunitlist.le_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.le_name,
                        'values': subunitlist.le_id
                    }]
                    // tempArrayunit.push(entityArrayunit)
                    // }
                    data = _.filter(businessGroupData, { bg_id: subunitlist.bg_id })
                    console.log(data, '12395559');
                    console.log(subunitlist.bg_id, 'subunitlist.bg_id');
                    totalListunit = {
                        'label': subunitlist.bg_id != null ? data && data[0].bg_name : 'Others',
                        'options': entityArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                })



                console.log(countrylisttempdataunit, 'aishwarya87535333');
                console.log(totalListunit, 'unitListData')
                // let uniqueObjArray = [...new Map(countrylisttempdataunit.length > 0 && countrylisttempdataunit.map((item) => [item["bg_id"], item])).values()];

                setLegalEntityList(countrylisttempdataunit)

            }

        }
    }, [businessGroupValue, EntityData])

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let entityArrayunit = []
        let tempArrayunit = []
        if (LitigationLegalEntityList.length == 0) {
            if (litigationbusinessGroupData.length > 0) {
                if (litigationlegalBg && litigationlegalBg.length > 0 && litigationlegalBg.includes(null) == false) {
                    if (lititigationbusinessGroupValue && lititigationbusinessGroupValue.length > 0) {


                        for (let i in lititigationbusinessGroupValue) {
                            let entityData = _.filter(litigationbusinessGroupData && litigationbusinessGroupData.length > 0 && litigationbusinessGroupData, { bg_id: Number(lititigationbusinessGroupValue[i]) })
                            console.log(entityData, 'entityData');
                            arrunit.push(entityData[0])
                        }
                        console.log(arrunit, 'arrunit');

                        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
                            let entityArrayunit = []
                            let tempArrayunit = []

                            litigationEntityData && litigationEntityData.length > 0 && litigationEntityData.map((subunitlist) => {
                                console.log(subunitlist, 'subunitlist');
                                if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                                    entityArrayunit = {
                                        'bg_id': subunitlist.bg_id,
                                        'le_name': subunitlist.le_name,
                                        'le_id': subunitlist.le_id,

                                        'label': subunitlist.le_name,
                                        'values': subunitlist.le_id
                                    }
                                    tempArrayunit.push(entityArrayunit)
                                }
                            })

                            totalListunit = {
                                'label': pmenu && pmenu.bg_name == null ? 'Others' : pmenu && pmenu.bg_name,
                                'options': tempArrayunit
                            }
                            console.log(totalListunit, 'aishwarya');
                            console.log(totalListunit, 'unitListData')
                            countrylisttempdataunit.push(totalListunit)
                        })
                        setLitigationLegalEntityList(countrylisttempdataunit)

                    }
                }

                else {

                    console.log('sneha');
                    let data = []


                    litigationEntityData && litigationEntityData.length > 0 && litigationEntityData.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');
                        // if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                        entityArrayunit = [{
                            'bg_id': subunitlist.bg_id,
                            'le_name': subunitlist.le_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.le_name,
                            'values': subunitlist.le_id
                        }]
                        // tempArrayunit.push(entityArrayunit)
                        // }
                        data = _.filter(litigationbusinessGroupData, { bg_id: subunitlist.bg_id })
                        console.log(data, '12395559');
                        console.log(subunitlist.bg_id, 'subunitlist.bg_id');
                        totalListunit = {
                            'label': subunitlist.bg_id != null ? data && data.length > 0 && data[0].bg_name : 'Others',
                            'options': entityArrayunit
                        }
                        countrylisttempdataunit.push(totalListunit)
                    })



                    console.log(countrylisttempdataunit, 'aishwarya87535333');
                    console.log(totalListunit, 'unitListData')
                    // let uniqueObjArray = [...new Map(countrylisttempdataunit.length > 0 && countrylisttempdataunit.map((item) => [item["bg_id"], item])).values()];

                    setLitigationLegalEntityList(countrylisttempdataunit)

                }
            }
        }
    }, [lititigationbusinessGroupValue, litigationbusinessGroupData, litigationEntityData])



    useEffect(() => {
        let temp = []
        if (vendorEntityList.length > 0) {
            console.log(vendorEntityList, 'LitigationLegalEntityList');
            for (let i in vendorEntityList) {
                if (vendorEntityList[i].options.length > 0) {
                    for (let j in vendorEntityList[i].options) {
                        temp.push(vendorEntityList[i].options[j])
                    }
                }
            }
            console.log(temp, 'temp123459455');
            setvendorEntityData(temp)
        }


    }, [vendorEntityList])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        let entityArrayunit = []
        let tempArrayunit = []
        if (vendorEntityList.length == 0) {
            if (vendorbusinessGroupData.length > 0) {
                if (vendorlegalBg && vendorlegalBg.length > 0 && vendorlegalBg.includes(null) == false) {
                    if (vendorbusinessGroupValue && vendorbusinessGroupValue.length > 0) {


                        for (let i in vendorbusinessGroupValue) {
                            let entityData = _.filter(vendorbusinessGroupData && vendorbusinessGroupData.length > 0 && vendorbusinessGroupData, { bg_id: Number(vendorbusinessGroupValue[i]) })
                            console.log(entityData, 'entityData');
                            arrunit.push(entityData[0])
                        }
                        console.log(arrunit, 'arrunit');

                        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
                            let entityArrayunit = []
                            let tempArrayunit = []

                            vendorEntityData && vendorEntityData.length > 0 && vendorEntityData.map((subunitlist) => {
                                console.log(subunitlist, 'subunitlist');
                                if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                                    entityArrayunit = {
                                        'bg_id': subunitlist.bg_id,
                                        'le_name': subunitlist.le_name,
                                        'le_id': subunitlist.le_id,

                                        'label': subunitlist.le_name,
                                        'values': subunitlist.le_id
                                    }
                                    tempArrayunit.push(entityArrayunit)
                                }
                            })

                            totalListunit = {
                                'label': pmenu && pmenu.bg_name == null ? 'Others' : pmenu && pmenu.bg_name,
                                'options': tempArrayunit
                            }
                            console.log(totalListunit, 'aishwarya');
                            console.log(totalListunit, 'unitListData')
                            countrylisttempdataunit.push(totalListunit)
                        })
                        setVendorLegalEntityList(countrylisttempdataunit)

                    }
                }

                else {

                    console.log('sneha');
                    let data = []


                    vendorEntityData && vendorEntityData.length > 0 && vendorEntityData.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');
                        // if (pmenu && pmenu.bg_id == subunitlist.bg_id) {
                        entityArrayunit = [{
                            'bg_id': subunitlist.bg_id,
                            'le_name': subunitlist.le_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.le_name,
                            'values': subunitlist.le_id
                        }]
                        // tempArrayunit.push(entityArrayunit)
                        // }
                        data = _.filter(vendorbusinessGroupData, { bg_id: subunitlist.bg_id })
                        console.log(data, '12395559');
                        console.log(subunitlist.bg_id, 'subunitlist.bg_id');
                        console.log(data && data.length, 'data && data.length');
                        if (data && data.length > 0) {
                            totalListunit = {
                                'label': subunitlist.bg_id != null ? data && data.length > 0 && data[0].bg_name : 'Others',
                                'options': entityArrayunit
                            }
                            countrylisttempdataunit.push(totalListunit)
                        }
                    })



                    console.log(countrylisttempdataunit, 'aishwarya87535333');
                    console.log(totalListunit, 'unitListData')
                    // let uniqueObjArray = [...new Map(countrylisttempdataunit.length > 0 && countrylisttempdataunit.map((item) => [item["bg_id"], item])).values()];

                    setVendorLegalEntityList(countrylisttempdataunit)

                }
            }
        }
    }, [vendorbusinessGroupValue, vendorbusinessGroupData, vendorEntityData])


    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        for (let i in entityValue) {
            let entityData = _.filter(EntityData && EntityData.length > 0 && EntityData, { le_id: entityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            domainData && domainData.length > 0 && domainData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_dm_id': subunitlist.u_dm_id,
                        'u_dm_name': subunitlist.u_dm_name,
                        'le_id': subunitlist.le_id,
                        // 'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_dm_name,
                        'values': subunitlist.u_dm_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setDomainListData(countrylisttempdataunit)


    }, [domainData, EntityData, entityValue])
    console.log(vendordomainValue, 'vendordomainValue');
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        for (let i in litigationEntityValue) {
            let entityData = _.filter(litigationEntityData && litigationEntityData.length > 0 && litigationEntityData, { le_id: litigationEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            litigationDomainData && litigationDomainData.length > 0 && litigationDomainData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_dm_id': subunitlist.u_dm_id,
                        'u_dm_name': subunitlist.u_dm_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.u_dm_name,
                        'values': subunitlist.u_dm_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setLitigationDomainList(countrylisttempdataunit)


    }, [litigationDomainData, litigationEntityData, litigationEntityValue])
    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        for (let i in vendoreEntityValue) {
            let entityData = _.filter(vendorEntityData && vendorEntityData.length > 0 && vendorEntityData, { le_id: vendoreEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit77777');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            VendorDomainData && VendorDomainData.length > 0 && VendorDomainData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist6666666');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'u_dm_id': subunitlist.u_dm_id,
                        'u_dm_name': subunitlist.u_dm_name,
                        'le_id': subunitlist.le_id,
                        // 'bg_id': subunitlist.bg_id,
                        'label': subunitlist.u_dm_name,
                        'values': subunitlist.u_dm_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })

            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya8145222');
            console.log(totalListunit, 'unitListData666666')
            countrylisttempdataunit.push(totalListunit)
        })
        setVendorDomainList(countrylisttempdataunit)


    }, [VendorDomainData, vendorEntityData, vendoreEntityValue])


    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        for (let i in vendoreEntityValue) {
            let entityData = _.filter(vendorEntityData && vendorEntityData.length > 0 && vendorEntityData, { le_id: vendoreEntityValue[i] })
            console.log(entityData, 'entityData');
            arrunit.push(entityData[0])
        }
        console.log(arrunit, 'arrunit');

        arrunit && arrunit.length > 0 && arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []

            vendordivisionData && vendordivisionData.length > 0 && vendordivisionData.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist89999');
                if (pmenu && pmenu.le_id == subunitlist.le_id) {
                    entityArrayunit = {
                        'd_id': subunitlist.d_id,
                        'd_name': subunitlist.d_name,
                        'le_id': subunitlist.le_id,
                        'bg_id': subunitlist.bg_id,
                        'label': subunitlist.d_name,
                        'values': subunitlist.d_id
                    }
                    tempArrayunit.push(entityArrayunit)
                }
            })
            // console.log(tempArrayunit, 'sneha');
            totalListunit = {
                'label': pmenu && pmenu.le_name == null ? 'Others' : pmenu && pmenu.le_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'aishwarya');
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setvendorListData(countrylisttempdataunit)


    }, [vendoreEntityValue, vendorEntityData, vendordivisionData])
    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    //select Onchange
    const compfieDomainHandelChange = (obj, data) => {
        setActiveUnit(false)
        let tem = []
        console.log(data, 'data');
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (domainData && domainData && domainData.length > 0) {
                let temp = []
                for (let i in domainData) {
                    temp.push(`${domainData[i].le_id}-${domainData[i].u_dm_id}`)
                    allArray.push(domainData[i].u_dm_id)

                }
                setdomainValue(temp)
                setdomainEntity(domainData)
            }
            SetDomainselectcheckbox('none');
            SetDomainunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setdomainValue([]);
            SetDomainselectcheckbox('block');
            SetDomainunselectcheckbox('none');
            setdomainEntity([])
        } else {
            setdomainValue(obj)
            if (obj.length == domainData.length) {
                SetDomainselectcheckbox('none');
                SetDomainunselectcheckbox('block');
            } else {
                SetDomainselectcheckbox('block');
                SetDomainunselectcheckbox('none');
            }
            if (obj.length > 0) {
                console.log(obj, 'obj9995922');
                let temp = []
                for (let i in obj) {
                    let splitValue = obj[i].split("-")
                    console.log(splitValue, 'splitValuesplitValue');
                    let data = _.filter(domainData && domainData, { u_dm_id: Number(splitValue[1]), le_id: splitValue[0] })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(data, 'tempppp');
                }
                setdomainEntity(temp)
            }

            console.log(obj, 'obj');

        }
        return obj;

    };
    const LitigationDomainHandelChange = (obj) => {
        setActiveUnit(false)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (litigationDomainData && litigationDomainData.length > 0) {
                let temp = []
                for (let i in litigationDomainData) {
                    temp.push(`${litigationDomainData[i].le_id}-${litigationDomainData[i].u_dm_id}`)
                    allArray.push(litigationDomainData[i].u_dm_id)

                }
                setlitigationdomainValue(temp)
                setlitigationdomainEntity(litigationDomainData)
            }
            Setlitigationdomainselectcheckbox('none');
            SetlitigationDomainunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setlitigationdomainValue([]);
            setlitigationdomainEntity([])
            Setlitigationdomainselectcheckbox('block');
            SetlitigationDomainunselectcheckbox('none');
        } else {
            console.log('testinggggggggggg');
            setlitigationdomainValue(obj)
            if (obj.length == litigationDomainData.length) {
                Setlitigationdomainselectcheckbox('none');
                SetlitigationDomainunselectcheckbox('block');
            } else {
                Setlitigationdomainselectcheckbox('block');
                SetlitigationDomainunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let splitValue = obj[i].split("-")
                    let data = _.filter(litigationDomainData && litigationDomainData, { u_dm_id: Number(splitValue[1]), le_id: splitValue[0] })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setlitigationdomainEntity(temp)
            }
        }
        return obj;

    };
    const vendorDoaminHandelChange = (obj) => {
        setActiveUnit(false)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (VendorDomainData && VendorDomainData.length > 0) {
                let temp = []
                for (let i in VendorDomainData) {
                    temp.push(`${VendorDomainData[i].le_id}-${VendorDomainData[i].u_dm_id}`)
                    allArray.push(VendorDomainData[i].u_dm_id)

                }
                setvendordomainValues(temp)
                setvendordomainEntity(litigationDomainData)
            }
            SetvendorDomainselectcheckbox('none');
            Setvendorunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setvendordomainValues([]);
            setvendordomainEntity([])
            SetvendorDomainselectcheckbox('block');
            Setvendorunselectcheckbox('none');
        } else {
            if (obj.length == VendorDomainData.length) {
                SetvendorDomainselectcheckbox('none');
                Setvendorunselectcheckbox('block');
            } else {
                SetvendorDomainselectcheckbox('block');
                Setvendorunselectcheckbox('none');
            }
            setvendordomainValues(obj)
            if (obj.length > 0) {

                let temp = []
                for (let i in obj) {
                    let splitValue = obj[i].split("-")
                    let data = _.filter(VendorDomainData && VendorDomainData, { u_dm_id: Number(splitValue[1]), le_id: splitValue[0] })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setlitigationdomainEntity(temp)
            }
        }
        return obj;

    };

    const compfieCategoryHandelChange = (obj, value) => {
        console.log(obj, 'objjjj');
        console.log(value, 'aishuuuuuuuuuuuuuu');
        let allArray = [];
        if (obj.includes('all')) {
            let cat = []

            if (categoryDatas && categoryDatas.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in categoryDatas) {
                    temp.push(categoryDatas[i].cat_id)
                    cat.push(categoryDatas[i].cat_name)
                    allArray.push(categoryDatas[i].cat_id)

                }
                setCatTooltip(cat.join(' , '))
                setCategoryValues(temp)
                // setDomainData(temp2)
            }
            Setcategoryselectcheckbox('none');
            Setcategoryunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setCatTooltip(temp)
            setCategoryValues([]);
            Setcategoryselectcheckbox('block');
            Setcategoryunselectcheckbox('none');
        } else {
            let temp = value && value.length > 0 && value.map((item, i) => {
                return item.children
            })
            if (temp.length > 0) {
                setCatTooltip(temp.join(' , '))
            }

            if (obj.length == categoryDatas.length) {
                Setcategoryselectcheckbox('none');
                Setcategoryunselectcheckbox('block');
            } else {
                Setcategoryselectcheckbox('block');
                Setcategoryunselectcheckbox('none');
            }
            setCategoryValues(obj)
        }
        return obj;

    };
    const vendorCategoryHandelChange = (obj) => {
        setActiveUnit(false)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorcategoryDatas && vendorcategoryDatas.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in vendorcategoryDatas) {
                    temp.push(vendorcategoryDatas[i].cat_id)
                    allArray.push(vendorcategoryDatas[i].cat_id)
                }
                setVendorCategoryValues(temp)
                // setDomainData(temp2)
            }
            Setvendorcategoryselectcheckbox('none');
            Setvendoruncategoryselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendorCategoryValues([]);
            Setvendorcategoryselectcheckbox('block');
            Setvendoruncategoryselectcheckbox('none');
        } else {
            if (obj.length == vendorcategoryDatas.length) {
                Setvendorcategoryselectcheckbox('none');
                Setvendoruncategoryselectcheckbox('block');
            } else {
                Setvendorcategoryselectcheckbox('block');
                Setvendoruncategoryselectcheckbox('none');
            }
            setVendorCategoryValues(obj)
        }
        return obj;

    };
    const divisionHandelChange = (obj) => {
        setCategoryValues([])
        setActiveUnit(false)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (divisionData && divisionData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in divisionData) {
                    temp.push(`${divisionData[i].d_id}`)
                    allArray.push(divisionData[i].d_id)


                }
                setDivisionValue(allArray)
                setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            }
            Setdivisionselectcheckbox('none');
            Setdivisionunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setDivisionValue([]);
            setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            Setdivisionselectcheckbox('block');
            Setdivisionunselectcheckbox('none');
        } else {
            if (obj.length == divisionData.length) {
                Setdivisionselectcheckbox('none');
                Setdivisionunselectcheckbox('block');
            } else {
                Setdivisionselectcheckbox('block');
                Setdivisionunselectcheckbox('none');
            }
            let temp = []
            setDivisionValue(obj)



            console.log(obj, 'obj');
            // setCategoryDatas(data)
        }
        return obj;

    };
    console.log(DivisionValue, 'DivisionValue');
    const VendordivisionHandelChange = (obj) => {
        setVendorCategoryValues([])
        setActiveUnit(false)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendordivisionData && vendordivisionData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in vendordivisionData) {
                    temp.push(vendordivisionData[i].d_id)
                    allArray.push(vendordivisionData[i].d_id)


                }
                setvendorDivisionValue(temp)
                setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            }
            Setvendordivisionselectcheckbox('none');
            Setvendordivisionunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setvendorDivisionValue([]);
            setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            Setvendordivisionselectcheckbox('block');
            Setvendordivisionunselectcheckbox('none');
        } else {
            if (obj.length == vendordivisionData.length) {
                Setvendordivisionselectcheckbox('none');
                Setvendordivisionunselectcheckbox('block');
            } else {
                Setvendordivisionselectcheckbox('block');
                Setvendordivisionunselectcheckbox('none');
            }
            // let data = _.filter(userManagementDetails && userManagementDetails.um_group_category, { d_id: Number(obj[0]) })
            setvendorDivisionValue(obj)
            // setCategoryDatas(data)
        }
        return obj;

    };

    const compfieEntityHandelChange = (obj, value) => {
        // if (AddType == 'add') {
        //     setCategoryValues([])
        //     setdomainValue([])
        //     setDivisionValue([])
        // }
        setActiveUnit(false)
        console.log(value, 'datadatadatadatadatadata');
        let allArray = [];
        if (obj.includes('all')) {
            if (EntityData && EntityData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in EntityData) {
                    temp.push(EntityData[i].le_id)
                    allArray.push(EntityData[i].le_id)
                }
                setLegalEntity(EntityData)
                setEntityValue(temp)
                setDomainData(userManagementDetails && userManagementDetails.um_legal_domain)
            }
            Setselectcheckbox('none');
            Setunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setEntityValue([]);
            Setselectcheckbox('block');
            Setunselectcheckbox('none');

            setCategoryValues([])
            setdomainValue([])
            setDivisionValue([])

        } else {
            console.log(obj, 'objobjobjobjobj');



            if (obj.length == EntityData.length) {
                Setselectcheckbox('none');
                Setunselectcheckbox('block');
            } else {
                Setselectcheckbox('block');
                Setunselectcheckbox('none');
            }
            let data = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: obj[0] })
            let datas = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: obj[0] })

            console.log(datas, 'datadata');
            setLegalEntity(datas)
            // setDomainData(data)

            setEntityValue(obj)



        }
        return obj;

    };
    const litigationEntityHandelChange = (obj) => {
        setlitigationdomainValue([])
        setActiveUnit(false)
        console.log(EntityData, 'EntityData');
        let allArray = [];
        if (obj.includes('all')) {
            if (litigationEntityData && litigationEntityData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in litigationEntityData) {
                    temp.push(litigationEntityData[i].le_id)
                    allArray.push(litigationEntityData[i].le_id)

                }
                setLitigationEntityValue(temp)
                setLitigationDomainData(userManagementDetails && userManagementDetails.ltmg_um_legal_domain)
            }
            SetLitigationEntityselectcheckbox('none');
            SetLitigationEntityunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setLitigationEntityValue([]);
            SetLitigationEntityselectcheckbox('block');
            SetLitigationEntityunselectcheckbox('none');


        } else {

            if (obj.length == litigationEntityData.length) {
                SetLitigationEntityselectcheckbox('none');
                SetLitigationEntityunselectcheckbox('block');
            } else {
                SetLitigationEntityselectcheckbox('block');
                SetLitigationEntityunselectcheckbox('none');
            }
            setLitigationEntityValue(obj)
            let data = _.filter(userManagementDetails && userManagementDetails.ltmg_um_legal_domain, { le_id: obj[0] })
            console.log(data, 'datadata');
            // setLitigationDomainData(data)
            let datas = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: obj[0] })

            console.log(datas, 'datadata');
            setlitigationLegalEntity(datas)
        }
        return obj;

    };
    const vendorEntityHandelChange = (obj) => {
        setActiveUnit(false)
        console.log(obj, 'objjjj');
        setvendordomainValues([])
        setVendorCategoryValues([])
        setvendorDivisionValue([])
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorEntityData && vendorEntityData.length > 0) {
                let temp = []
                let temp2 = []
                for (let i in vendorEntityData) {
                    temp.push(vendorEntityData[i].le_id)
                    allArray.push(vendorEntityData[i].le_id)
                }
                setVendoreEntityValue(temp)
                setvendorLegalEntity(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
                // setVendorDomainData(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
            }
            SetVendorEntityselectcheckbox('none');
            SetVendorEntityunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendoreEntityValue([]);
            SetVendorEntityselectcheckbox('block');
            SetVendorEntityunselectcheckbox('none');
            setvendordomainValues([])
            setVendorCategoryValues([])
            setvendorDivisionValue([])
        } else {

            if (obj.length == vendorEntityData.length) {
                SetVendorEntityselectcheckbox('none');
                SetVendorEntityunselectcheckbox('block');
            } else {
                SetVendorEntityselectcheckbox('block');
                SetVendorEntityunselectcheckbox('none');
            }
            setVendoreEntityValue(obj)
            let temp = []
            let tempData = []
            let tempData2 = []
            let temp2 = []
            for (let i in obj) {

                let data = _.filter(userManagementDetails && userManagementDetails.vndr_um_legal_domain, { le_id: obj[i] })
                let datas = _.filter(EntityData, { le_id: entityValue[i] })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                for (let j in datas) {
                    temp2.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            setvendorLegalEntity(temp)
        }
        return obj;

    };
    const HandelChange = (obj, data) => {

        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (selectUnitData && selectUnitData && selectUnitData.length > 0) {
                let temp = []
                for (let i in selectUnitData) {
                    temp.push(selectUnitData[i].u_unt_id)
                    allArray.push(selectUnitData[i].u_unt_id)

                }
                setUnitEntity(selectUnitData)
                setAll(temp)
            }
            Setunitselectcheckbox('none');
            Setunitunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            let tempArr = []
            setAll([]);
            setUnitEntity([])
            if (AddType == 'edit') {
                let uniqueObjArray = [...new Map(selectUnitData && selectUnitData.length > 0 && selectUnitData.map((item) => [item["le_id"], item])).values()];

                const uniqueIds = uniqueObjArray && uniqueObjArray.length > 0 && uniqueObjArray.map((item) => {
                    return item.le_id
                })
                for (let i in uniqueIds) {
                    let filteredArr = _.filter(selectUnitData, { le_id: uniqueIds[i] })

                    tempArr.push(filteredArr)
                }
                for (let j in tempArr) {
                    if (tempArr[j].length > 0) {
                        let data = tempArr[j] && tempArr[j].map((item) => {
                            return item.u_unt_id
                        })

                        temp.push({
                            "le_id": tempArr[j][0].le_id,
                            "unit_ids_str": data.toString()
                        })
                    }
                }
                console.log(temp, 'tempAish');

                console.log(tempArr, 'tempArr555544444444');
                const Editpayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "HaveCompliancesUnit",
                            {
                                "le_ids": uniqueIds,
                                "user_id": details.user_id,
                                "le_unit_ids": temp,
                                "product_id": "1"
                            }
                        ]

                    }
                ]
                console.log(Editpayload, 'Editpayloaddddd');
                selectHaveCompliance({
                    payload: Editpayload,
                    paramid: paramid,

                })
            }
            Setunitselectcheckbox('block');
            Setunitunselectcheckbox('none');
        } else {
            setAll(obj)
            if (obj.length == selectUnitData.length) {
                Setunitselectcheckbox('none');
                Setunitunselectcheckbox('block');
            } else {
                Setunitselectcheckbox('block');
                Setunitunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(selectUnitData && selectUnitData, { u_unt_id: Number(obj[i]) })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setUnitEntity(temp)
            }
            // else {
            //     setUnitEntity(selectUnitData)
            // }



        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };

    const businessGroupHandelChange = (obj, data) => {
        if (AddType == 'add') {
            setEntityValue([])
        }
        setActiveUnit(false)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (businessGroupData && businessGroupData && businessGroupData.length > 0) {
                let temp = []
                for (let i in businessGroupData) {
                    temp.push(`${businessGroupData[i].bg_id}`)
                    allArray.push(businessGroupData[i].bg_id)

                }
                setbusinessGroupValue(temp)
            }
            SetbusinessGroupselectcheckbox('none');
            SetbusinessGroupUnselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setbusinessGroupValue([]);
            SetbusinessGroupselectcheckbox('block');
            SetbusinessGroupUnselectcheckbox('none');
        } else {
            setbusinessGroupValue(obj)
            if (obj.length == businessGroupData.length) {
                SetbusinessGroupselectcheckbox('none');
                SetbusinessGroupUnselectcheckbox('block');
            } else {
                SetbusinessGroupselectcheckbox('block');
                SetbusinessGroupUnselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { bg_id: Number(obj[i]) })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setEntityData(temp)
            }
            else {
                setEntityData(userManagementDetails && userManagementDetails.um_legal_entity)
            }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };

    const litigationbusinessGroupHandelChange = (obj, data) => {
        if (AddType == 'add') {
            setLitigationEntityValue([])
        }
        setActiveUnit(false)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (litigationbusinessGroupData && litigationbusinessGroupData.length > 0) {
                let temp = []
                for (let i in litigationbusinessGroupData) {
                    temp.push(`${litigationbusinessGroupData[i].bg_id}`)
                    allArray.push(litigationbusinessGroupData[i].bg_id)

                }
                setlititigationbusinessGroupValue(temp)
            }
            SetlitigationbusinessGroupselectcheckbox('none');
            SetlitigationbusinessGroupunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setlititigationbusinessGroupValue([]);
            SetlitigationbusinessGroupselectcheckbox('block');
            SetlitigationbusinessGroupunselectcheckbox('none');
        } else {
            setlititigationbusinessGroupValue(obj)
            if (obj.length == litigationbusinessGroupData.length) {
                SetlitigationbusinessGroupselectcheckbox('none');
                SetlitigationbusinessGroupunselectcheckbox('block');
            } else {
                SetlitigationbusinessGroupselectcheckbox('block');
                SetlitigationbusinessGroupunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                let arr = []
                for (let i in obj) {
                    if (obj[i] != null) {

                        let arr = _.reject(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { le_admin: null })
                        if (arr && arr.length > 0) {
                            let data = _.filter(arr, { bg_id: Number(obj[i]) })

                            for (let j in data) {
                                temp.push(data[j])
                            }
                            // temp.push(data)
                            console.log(temp, 'tempppp');
                            setlitigationEntityData(temp)
                        }

                    }
                }

            }
            else {
                setlitigationEntityData(userManagementDetails && userManagementDetails.um_ltmg_legal_entity)
            }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    const vendorbusinessGroupHandelChange = (obj, data) => {
        if (AddType == 'add') {
            setVendoreEntityValue([])
        }
        setActiveUnit(false)
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorbusinessGroupData && vendorbusinessGroupData && vendorbusinessGroupData.length > 0) {
                let temp = []
                for (let i in vendorbusinessGroupData) {
                    temp.push(`${vendorbusinessGroupData[i].bg_id}`)
                    allArray.push(vendorbusinessGroupData[i].bg_id)

                }
                setVendorbusinessGroupValue(temp)
            }
            SetvendorbusinessGroupselectcheckbox('none');
            SetvendorbusinessGroupUnselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setVendorbusinessGroupValue([]);
            SetvendorbusinessGroupselectcheckbox('block');
            SetvendorbusinessGroupUnselectcheckbox('none');
        } else {

            setVendorbusinessGroupValue(obj)
            if (obj.length == vendorbusinessGroupData.length) {
                SetvendorbusinessGroupselectcheckbox('none');
                SetvendorbusinessGroupUnselectcheckbox('block');
            } else {
                SetvendorbusinessGroupselectcheckbox('block');
                SetvendorbusinessGroupUnselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let arr = _.reject(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { le_admin: null })
                    console.log(arr, 'arr89888898989');
                    if (arr && arr.length > 0) {
                        let data = _.filter(arr, { bg_id: Number(obj[i]) })
                        console.log(data, 'aaaaaaa');
                        for (let j in data) {
                            temp.push(data[j])
                        }
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setvendorEntityData(temp)
            }
            else {
                setvendorEntityData(userManagementDetails && userManagementDetails.um_vndr_legal_entity)
            }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    const CloseUnitHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (closedUnitData && closedUnitData && closedUnitData.length > 0) {
                let temp = []
                for (let i in closedUnitData) {
                    temp.push(closedUnitData[i].u_unt_id)
                    allArray.push(closedUnitData[i].u_unt_id)

                }
                setclosedAll(temp)
            }
            Setclosedunitselectcheckbox('none');
            Setclosedunitunselectcheckbox('block');
            setclosedEntityUnit(closedUnitData)
        } else if (obj.includes('unselect')) {
            let temp = [];
            let tempArr = []
            setclosedAll([]);
            if (AddType == 'edit') {
                let uniqueObjArray = [...new Map(closedUnitData && closedUnitData.length > 0 && closedUnitData.map((item) => [item["le_id"], item])).values()];

                const uniqueIds = uniqueObjArray && uniqueObjArray.length > 0 && uniqueObjArray.map((item) => {
                    return item.le_id
                })
                for (let i in uniqueIds) {
                    let filteredArr = _.filter(closedUnitData, { le_id: uniqueIds[i] })

                    tempArr.push(filteredArr)
                }
                for (let j in tempArr) {
                    if (tempArr[j].length > 0) {
                        let data = tempArr[j] && tempArr[j].map((item) => {
                            return item.u_unt_id
                        })

                        temp.push({
                            "le_id": tempArr[j][0].le_id,
                            "unit_ids_str": data.toString()
                        })
                    }
                }
                console.log(temp, 'tempAish');

                console.log(tempArr, 'tempArr555544444444');
                const Editpayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "HaveCompliancesUnit",
                            {
                                "le_ids": uniqueIds,
                                "user_id": details.user_id,
                                "le_unit_ids": temp,
                                "product_id": "1"
                            }
                        ]

                    }
                ]
                console.log(Editpayload, 'Editpayloaddddd');
                selectHaveCompliance({
                    payload: Editpayload,
                    paramid: paramid,

                })
            }
            Setclosedunitselectcheckbox('block');
            Setclosedunitunselectcheckbox('none');
            setclosedEntityUnit([])
        } else {

            setclosedAll(obj)
            if (obj.length == closedUnitData.length) {
                Setclosedunitselectcheckbox('none');
                Setclosedunitunselectcheckbox('block');
            } else {
                Setclosedunitselectcheckbox('block');
                Setclosedunitunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(closedUnitData && closedUnitData, { u_unt_id: Number(obj[i]) })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setclosedEntityUnit(temp)
            }
            // else {
            //     setclosedEntityUnit(closedUnitData)
            // }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    const VendorUnitHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorUnit && vendorUnit && vendorUnit.length > 0) {
                let temp = []
                for (let i in vendorUnit) {
                    temp.push(vendorUnit[i].u_unt_id)
                    allArray.push(vendorUnit[i].u_unt_id)

                }
                setVendorAll(temp)
                setvendorunitEntity(vendorUnit)
            }
            Setvendorunitselectcheckbox('none');
            Setvendorunitunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let tempArr = [];
            let temp = []
            setVendorAll([]);
            setvendorunitEntity([])
            if (AddType == 'edit') {
                let uniqueObjArray = [...new Map(vendorUnit && vendorUnit.length > 0 && vendorUnit.map((item) => [item["le_id"], item])).values()];

                const uniqueIds = uniqueObjArray && uniqueObjArray.length > 0 && uniqueObjArray.map((item) => {
                    return item.le_id
                })
                for (let i in uniqueIds) {
                    let filteredArr = _.filter(vendorUnit, { le_id: uniqueIds[i] })

                    tempArr.push(filteredArr)
                }
                for (let j in tempArr) {
                    if (tempArr[j].length > 0) {
                        let data = tempArr[j] && tempArr[j].map((item) => {
                            return item.u_unt_id
                        })

                        temp.push({
                            "le_id": tempArr[j][0].le_id,
                            "unit_ids_str": data.toString()
                        })
                    }
                }
                console.log(temp, 'tempAish');

                console.log(tempArr, 'tempArr555544444444');
                const Editpayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "HaveCompliancesUnit",
                            {
                                "le_ids": uniqueIds,
                                "user_id": details.user_id,
                                "le_unit_ids": temp,
                                "product_id": "3"
                            }
                        ]

                    }
                ]
                console.log(Editpayload, 'Editpayloaddddd');
                selectHaveCompliance({
                    payload: Editpayload,
                    paramid: paramid,

                })
            }

            Setvendorunitselectcheckbox('block');
            Setvendorunitunselectcheckbox('none');
        } else {
            setVendorAll(obj)
            if (obj.length == vendorUnit.length) {
                Setvendorunitselectcheckbox('none');
                Setvendorunitunselectcheckbox('block');
            } else {
                Setvendorunitselectcheckbox('block');
                Setvendorunitunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(vendorUnit && vendorUnit, { u_unt_id: Number(obj[i]) })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setvendorunitEntity(temp)
            }
            // else {
            //     setvendorunitEntity(vendorUnit)
            // }
        }
        return obj;

    };
    const VendorClosedUnitHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (vendorClosedUnit && vendorClosedUnit.length > 0) {
                let temp = []
                for (let i in vendorClosedUnit) {
                    temp.push(vendorClosedUnit[i].u_unt_id)
                    allArray.push(vendorClosedUnit[i].u_unt_id)

                }
                setVendorClosedAll(temp)
                setvendorClosedunitEntity(vendorClosedUnit)
            }
            Setvendorclosedunitselectcheckbox('none');
            SetVendorclosedunitunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            let tempArr = []
            setVendorClosedAll([]);
            if (AddType == 'edit') {
                let uniqueObjArray = [...new Map(vendorClosedUnit && vendorClosedUnit.length > 0 && vendorClosedUnit.map((item) => [item["le_id"], item])).values()];

                const uniqueIds = uniqueObjArray && uniqueObjArray.length > 0 && uniqueObjArray.map((item) => {
                    return item.le_id
                })
                for (let i in uniqueIds) {
                    let filteredArr = _.filter(vendorClosedUnit, { le_id: uniqueIds[i] })

                    tempArr.push(filteredArr)
                }
                for (let j in tempArr) {
                    if (tempArr[j].length > 0) {
                        let data = tempArr[j] && tempArr[j].map((item) => {
                            return item.u_unt_id
                        })

                        temp.push({
                            "le_id": tempArr[j][0].le_id,
                            "unit_ids_str": data.toString()
                        })
                    }
                }
                console.log(temp, 'tempAish');

                console.log(tempArr, 'tempArr555544444444');
                const Editpayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "HaveCompliancesUnit",
                            {
                                "le_ids": uniqueIds,
                                "user_id": details.user_id,
                                "le_unit_ids": temp,
                                "product_id": "3"
                            }
                        ]

                    }
                ]
                console.log(Editpayload, 'Editpayloaddddd');
                selectHaveCompliance({
                    payload: Editpayload,
                    paramid: paramid,

                })
            }
            Setvendorclosedunitselectcheckbox('block');
            SetVendorclosedunitunselectcheckbox('none');
        } else {
            setVendorClosedAll(obj)
            if (obj.length == vendorClosedUnit.length) {
                Setvendorclosedunitselectcheckbox('none');
                SetVendorclosedunitunselectcheckbox('block');
            } else {
                Setvendorclosedunitselectcheckbox('block');
                SetVendorclosedunitunselectcheckbox('none');
            }
            if (obj.length > 0) {
                let temp = []
                for (let i in obj) {
                    let data = _.filter(vendorClosedUnit && vendorClosedUnit, { u_unt_id: Number(obj[i]) })

                    for (let j in data) {
                        temp.push(data[j])
                    }
                    // temp.push(data)
                    console.log(temp, 'tempppp');
                }
                setvendorClosedunitEntity(temp)
            }
            // else {
            //     setvendorClosedunitEntity(vendorUnit)
            // }
        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };
    const countryOnChange = e => {
        const { value } = e.target;
        const re = /^\+(\d{1}\-)?(\d{1,4})$/;
        console.log(re.test(value), 're.test(value)');

        if (re.test(value) === true) {
            // setErrorField3(true)
            setUserDetails({
                ...userDetails,
                mobile_no_country_code: value
            })
        }
        else {
            // setErrorField3(false)
            setUserDetails({
                ...userDetails,
                mobile_no_country_code: value
            })
        }
    }
    const mobileOnChange = e => {
        const { value } = e.target;
        const re = /^\d{10}$/;
        console.log(re.test(value), 're.test(value)');
        if (re.test(value) === true) {
            // setErrorField4(true)
            setUserDetails({
                ...userDetails,
                mobile_no: value
            })
        }
        else {
            // setErrorField4(false)
            setUserDetails({
                ...userDetails,
                mobile_no: value
            })
        }
    }
    const EmailOnChange = e => {
        const { value } = e.target;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log(re.test(value), 're.test(value)');

        if (re.test(value) === true) {
            // setErrorField(true)
            setUserDetails({
                ...userDetails,
                email: value
            })
        }
        else {
            // setErrorField(false)
            setUserDetails({
                ...userDetails,
                email: value
            })
        }
    }

    const EmpIdOnChange = e => {
        const { value } = e.target;
        const re = /^[A-za-z 0-9]*$/;
        console.log(re.test(value), 're.test(value)');
        if (re.test(value) === true) {
            // setErrorField2(true)
            setUserDetails({
                ...userDetails,
                emp_id: value
            })
        }
        else {
            e.preventDefault();
            // setErrorField2(false)
            // setUserDetails({
            //     ...userDetails,
            //     emp_id: value
            // })
        }
    }
    const EmployeeOnChange = e => {
        const { value } = e.target;
        const re = /^[a-zA-Z ]*$/;
        console.log(re.test(value), 're.test(value)');
        if (re.test(value) === true) {
            // setErrorField1(true)
            setUserDetails({
                ...userDetails,
                emp_name: value
            })
        }
        else {
            // setErrorField1(false)
            setUserDetails({
                ...userDetails,
                emp_name: value
            })
        }
    }
    const categoryOnChange = (e) => {

        if (e == undefined) {
            setCategory({
                ...category,
                compfie: "",
                user_Group: ''



            })
            setCompfie({
                ...compfie,
                category: null,
                user_Group: ''
            })
            setEntityValue([])
            setbusinessGroupValue([])
            setActiveUnit(false)
        }
        else {
            let CategoryData = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_c_id: Number(e) })
            setUserGroupCompfie(CategoryData)
            setActiveUnit(false)
            setCategory({
                ...category,
                compfie: e,
                user_Group: ''
            })
            setCompfie({
                ...compfie,
                category: e,
                user_Group: ''
            })
        }
    }


    useEffect(() => {
        if (DivisionValue.length > 0) {
            let temp = []
            for (let i in DivisionValue) {
                let data = _.filter(userManagementDetails && userManagementDetails.um_group_category, { d_id: Number(DivisionValue[i]) })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            setCategoryDatas(temp)
        }
        else {
            setCategoryDatas([])
        }
    }, [DivisionValue])
    useEffect(() => {
        if (vendorDivisionValue.length > 0) {
            let temp = []
            for (let i in vendorDivisionValue) {
                let data = _.filter(userManagementDetails && userManagementDetails.um_group_category, { d_id: Number(vendorDivisionValue[i]) })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            setvendorcategoryDatas(temp)
        }
        else {
            setvendorcategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
        }
    }, [vendorDivisionValue])

    useEffect(() => {
        if (haveCompliance == "HaveComplianceFailed") {
            console.log('aishuuu8888');
            const Editpayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UserManagementEditView",
                        {
                            "user_id": details.user_id
                        }
                    ]

                }
            ]
            getById({
                payload: Editpayload,
                paramid: paramid,

            })
        }

    }, [haveCompliance, count])
    console.log(entityValue, 'entityValueentityValueentityValue');
    console.log(editEntityValue, 'editEntityValue');
    useEffect(() => {
        if (entityValue.length > 0) {
            let temp = []
            let tempData = []
            let tempData2 = []
            let temp2 = []
            for (let i in entityValue) {
                let data = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: entityValue[i] })
                let datas = _.filter(EntityData, { le_id: entityValue[i] })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                for (let j in datas) {
                    temp2.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            // if (AddType == 'add') {
            setDomainData(temp)
            setLegalEntity(temp2)
            let tempDivision = []
            let catDiv = []

            for (let i in entityValue) {


                let data = _.filter(unitDatas, { le_id: entityValue[i] })
                let datas = _.filter(CloseUnitDAta, { le_id: entityValue[i] })
                let division = _.filter(userManagementDetails && userManagementDetails.um_cmpf_group_division, { le_id: entityValue[i] })
                let categoryArray = _.filter(userManagementDetails && userManagementDetails.um_group_category
                    , { le_id: entityValue[i] })

                // console.log(datas, 'datadata');
                console.log(division, 'division999999');

                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    tempData.push(data[j])
                }
                for (let j in datas) {
                    tempData2.push(datas[j])
                }
                for (let k in division) {
                    tempDivision.push(division[k])
                }
                for (let v in categoryArray) {
                    catDiv.push(categoryArray[v])
                }
                console.log(tempData, 'tempppp');
            }
            console.log(tempDivision, 'tempDivision');

            // if (AddType == 'add') {
            setSelectUnitData(tempData)
            setClosedtUnitData(tempData2)
            setdivisionData(tempDivision)
            setCategoryDatas(catDiv)
        }
    }, [entityValue])

    useEffect(() => {
        if (vendoreEntityValue.length > 0) {
            let temp = []
            let tempData = []
            let tempData2 = []
            let temp2 = []
            for (let i in vendoreEntityValue) {
                let data = _.filter(userManagementDetails && userManagementDetails.vndr_um_legal_domain, { le_id: vendoreEntityValue[i] })
                let datas = _.filter(vendorEntityData, { le_id: vendoreEntityValue[i] })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                for (let j in datas) {
                    temp2.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            // if (AddType == 'add') {
            setVendorDomainData(temp)
            setvendorLegalEntity(temp2)
            let tempDivision = []
            let catDiv = []

            for (let i in vendoreEntityValue) {


                let data = _.filter(vendorunitDatas, { le_id: vendoreEntityValue[i] })
                let datas = _.filter(vendorCloseUnitDAta, { le_id: vendoreEntityValue[i] })
                let division = _.filter(userManagementDetails && userManagementDetails.um_vndr_group_division, { le_id: vendoreEntityValue[i] })
                let categoryArray = _.filter(userManagementDetails && userManagementDetails.um_group_category
                    , { le_id: vendoreEntityValue[i] })

                // console.log(datas, 'datadata');
                console.log(division, 'division999999');

                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    tempData.push(data[j])
                }
                for (let j in datas) {
                    tempData2.push(datas[j])
                }
                for (let k in division) {
                    tempDivision.push(division[k])
                }
                for (let v in categoryArray) {
                    catDiv.push(categoryArray[v])
                }
                console.log(tempData, 'tempppp');
            }
            console.log(tempDivision, 'tempDivision');

            // if (AddType == 'add') {
            setVendorUnit(tempData)
            setVendorClosedUnit(tempData2)
            setvendordivisionData(tempDivision)
            setvendorcategoryDatas(catDiv)
        }
    }, [vendoreEntityValue])














    // useEffect(() => {
    //     if (vendoreEntityValue.length > 0) {
    //         let temp = []
    //         let temp2 = []
    //         let tempData2 = []
    //         let tempData = []
    //         let tempDivision = []
    //         let catDiv = []
    //         for (let i in vendoreEntityValue) {
    //             let data = _.filter(userManagementDetails && userManagementDetails.vndr_um_legal_domain, { le_id: vendoreEntityValue[i] })
    //             let datas = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { le_id: vendoreEntityValue[i] })
    //             let division = _.filter(userManagementDetails && userManagementDetails.um_vndr_group_division
    //                 , { le_id: vendoreEntityValue[i] })
    //             let categoryArray = _.filter(userManagementDetails && userManagementDetails.um_group_category
    //                 , { le_id: vendoreEntityValue[i] })
    //             console.log(data, 'aaaaaaa');
    //             for (let j in data) {
    //                 temp.push(data[j])
    //             }
    //             for (let j in datas) {
    //                 temp2.push(data[j])
    //             }
    //             for (let k in division) {
    //                 tempDivision.push(division[k])
    //             }
    //             for (let v in categoryArray) {
    //                 catDiv.push(categoryArray[v])
    //             }
    //             // temp.push(data)
    //             console.log(temp, 'tempppp88888888');
    //         }
    //         // if (AddType == 'add') {
    //         setVendorDomainData(temp)
    //         setvendorcategoryDatas(catDiv)
    //         // }
    //         setvendorLegalEntity(temp2)
    //         for (let i in entityValue) {


    //             let data = _.filter(vendorunitDatas, { le_id: vendoreEntityValue[i] })
    //             let datas = _.filter(CloseUnitDAta, { le_id: vendoreEntityValue[i] })
    //             console.log(data, 'aaaaaaa');
    //             for (let j in data) {
    //                 tempData.push(data[j])
    //             }
    //             for (let j in datas) {
    //                 tempData2.push(datas[j])
    //             }
    //             console.log(tempData, 'tempppp');
    //         }
    //         setVendorUnit(tempData)
    //         setVendorClosedUnit(tempData2)
    //         setvendordivisionData(tempDivision)
    //     }
    //     else {
    //         setVendorDomainData(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
    //     }
    // }, [vendoreEntityValue])
    console.log(compfie, 'compfie55555');
    useEffect(() => {
        if (litigationEntityValue.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in litigationEntityValue) {
                console.log(litigationEntityValue, 'litigationEntityValue');
                let data = _.filter(userManagementDetails && userManagementDetails.ltmg_um_legal_domain, { le_id: litigationEntityValue[i] })
                let datas = _.filter(litigationEntityData, { le_id: litigationEntityValue[i] })
                console.log(datas, 'snehaaa');
                for (let j in data) {
                    temp.push(data[j])
                }
                for (let j in datas) {
                    temp2.push(data[j])
                }
                // temp.push(data)
                console.log(temp, 'tempppp999999999');
            }
            // if (AddType == 'add') {
            setLitigationDomainData(temp)
            // }
            setlitigationLegalEntity(temp2)
        }
        else {
            setLitigationDomainData(userManagementDetails && userManagementDetails.ltmg_um_legal_domain)
        }
    }, [litigationEntityValue])
    useEffect(() => {
        if (EntityData.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in EntityData) {
                let data = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, {
                    le_id: EntityData
                    [i].le_id
                })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }

                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            if (AddType == 'edit') {
                setDomainData(temp)
            }

        }
        else {
            setDomainData(userManagementDetails && userManagementDetails.um_legal_domain)
            // setLegalEntity()
        }
    }, [EntityData])

    useEffect(() => {
        if (litigationEntityData.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in litigationEntityData) {
                let data = _.filter(userManagementDetails && userManagementDetails.ltmg_um_legal_domain, {
                    le_id: litigationEntityData

                    [i].le_id
                })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }

                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            if (AddType == 'edit') {
                setLitigationDomainData(temp)
            }

        }
        else {
            setLitigationDomainData(userManagementDetails && userManagementDetails.ltmg_um_legal_domain)
            // setLegalEntity()
        }
    }, [litigationEntityData])


    useEffect(() => {
        if (vendorEntityData.length > 0) {
            let temp = []
            let temp2 = []
            for (let i in vendorEntityData) {
                let data = _.filter(userManagementDetails && userManagementDetails.vndr_um_legal_domain, {
                    le_id: vendorEntityData

                    [i].le_id
                })
                console.log(data, 'aaaaaaa');
                for (let j in data) {
                    temp.push(data[j])
                }

                // temp.push(data)
                console.log(temp, 'tempppp');
            }
            if (AddType == 'edit') {
                setVendorDomainData(temp)
            }

        }
        else {
            setVendorDomainData(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
            // setLegalEntity()
        }
    }, [vendorEntityData])



    const userDetailOnSubmit = () => {

        //   setActive("2")
        setTabBoolean(true)
        setAddFormSubmit(true);
        setCategoryFormSubmit(true)
        setLitigationFormSubmit(true)
        if (tabValue.compfie == true || tabValue.compfie_litigation == true || tabValue.compfie_vendor == true) {
            if (validator.current.allValid()) {
                console.log(validator.current.allValid(), 'validator.current.allValid()');
                console.log(Formvalidator.current.allValid(), 'Formvalidator.current.allValid()');
                if (Formvalidator.current.allValid()) {
                    // setSelectUnit(true)
                    // setActive("2")

                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "EmployeeCodeExists",
                                {
                                    "mode": "SAVE",
                                    "user_id_optional": null,
                                    "employee_code": userDetails.emp_id,
                                    "mobile_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`
                                }
                            ]
                            // "request": [
                            //     "EmployeeCodeExists",
                            //     {
                            //         "mode": "SAVE",
                            //         "user_id_optional": null,
                            //         "employee_code": "a5344",
                            //         "mobile_no": "+91-9853322282"
                            //     }
                            // ]
                            // "request": [
                            //     "EmployeeCodeExists",
                            //     {
                            //      "mode": "SAVE",
                            //      "user_id_optional": null,
                            //      "employee_code": "a12345",
                            //      "mobile_no": "+91-9853322222"
                            //     }
                            //    ]
                        }
                    ]
                    const Editpayload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "EmployeeCodeExists",
                                {
                                    "mode": "UPDATE",
                                    "user_id_optional": GetUserDetails && GetUserDetails.ul_userDetails[0].user_id,
                                    "employee_code": userDetails.emp_id,
                                    "mobile_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`
                                }
                            ]
                            // "request": [
                            //     "EmployeeCodeExists",
                            //     {
                            //         "mode": "SAVE",
                            //         "user_id_optional": null,
                            //         "employee_code": "a5344",
                            //         "mobile_no": "+91-9853322282"
                            //     }
                            // ]
                            // "request": [
                            //     "EmployeeCodeExists",
                            //     {
                            //      "mode": "SAVE",
                            //      "user_id_optional": null,
                            //      "employee_code": "a12345",
                            //      "mobile_no": "+91-9853322222"
                            //     }
                            //    ]
                        }
                    ]
                    addUserDetails({
                        payload: AddType == 'add' ? payload : Editpayload,
                        paramid: paramid,
                        setActive: setActive,
                        setSelectUnit: setSelectUnit,
                        setActiveUnit: setActiveUnit,
                        setCollapseAssignee: setCollapseAssignee
                    })


                }
                else {
                    Toaster.warning("Please  Enter All Values")
                }

            }
        }
        else {
            if (validator.current.allValid()) {
                Toaster.error('Select atleast one product')
            }
        }

        // else {
        //     Toaster.warning("Please  Enter All Values")
        // }
        // setSelectUnit(true)




    }
    //set the edit value
    useEffect(() => {
        if (AddType == 'add') {
            setUserDetails({
                ...userDetails,
                email: ''
            })
        }

    }, [])
    useEffect(() => {
        if (AddType == 'edit') {
            if (GetUserDetails && GetUserDetails.ul_userDetails && GetUserDetails.ul_userDetails.length > 0) {
                let mobile = GetUserDetails && GetUserDetails.ul_userDetails[0].mob_no.split("-")
                setUserDetails({
                    ...userDetails,
                    emp_name: GetUserDetails && GetUserDetails.ul_userDetails[0].emp_name,
                    emp_id: GetUserDetails && GetUserDetails.ul_userDetails[0].emp_code,
                    email: GetUserDetails && GetUserDetails.ul_userDetails[0].email_id,
                    mobile_no_country_code: mobile && mobile[0],
                    mobile_no: mobile && mobile[1],
                    otp: GetUserDetails && GetUserDetails.ul_userDetails[0].otp_required,
                    remarks: GetUserDetails && GetUserDetails.ul_userDetails[0].otp_remarks

                })
                let userCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category, { u_c_id: GetUserDetails && GetUserDetails.ul_userDetails[0].u_cat_id })
                let SeatingUnit = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { u_unt_id: GetUserDetails && GetUserDetails.ul_userDetails[0].seating_unit_id })
                let UserGroup = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_g_id: GetUserDetails && GetUserDetails.ul_userDetails[0].u_g_id })
                let ServiceProvider = _.filter(userManagementDetails && userManagementDetails.um_service_providers, { u_sp_id: GetUserDetails && GetUserDetails.ul_userDetails[0].sp_id })
                console.log(ServiceProvider, 'ServiceProvider');
                let entityData = []
                let EntityData2 = []
                // if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                let LegalEntity = GetUserDetails && GetUserDetails.ul_legal_entities && GetUserDetails && GetUserDetails.ul_legal_entities.length && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                    return item.le_id
                })

                let LegalEntityArr = []
                let legalEntityId = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                    return item.le_id
                })
                if (LegalEntity && LegalEntity.length > 0) {
                    for (let i in LegalEntity) {
                        if (legalEntityId && legalEntityId.length > 0 && legalEntityId.includes(LegalEntity[i])) {
                            LegalEntityArr.push(LegalEntity[i])
                        }
                    }
                }

                setEditEntity(LegalEntityArr)
                setEntityValue(LegalEntityArr)
                // }
                // else {
                //     let data = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: entityid })
                //     console.log(data, 'dataaaaaaaaa')
                //     if (data && data.length > 0) {
                //         for (let l in data) {

                //             entityData.push(`${data[l].le_id}`)

                //         }
                //         setEditEntity(entityData)
                //         setEntityValue(entityData)
                //     }
                //     let LegalEntity = GetUserDetails && GetUserDetails.ul_legal_entities && GetUserDetails && GetUserDetails.ul_legal_entities.length && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                //         return item.le_id
                //     })
                //     setpayloadEntityEdit(LegalEntity)
                // }
                let tempp = []
                let tempp2 = []


                let LitigationLegalEntityArr = []

                let LitigationLegalEntity = GetUserDetails && GetUserDetails.ul_ltg_legal_entities && GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length > 0 && GetUserDetails && GetUserDetails.ul_ltg_legal_entities.map((item) => {
                    return item.le_id
                })

                let litigationEntityDataId = litigationEntityData && litigationEntityData.length > 0 && litigationEntityData.map((item) => {
                    return item.le_id
                })
                if (LitigationLegalEntity && LitigationLegalEntity.length > 0) {
                    for (let i in LitigationLegalEntity) {
                        if (litigationEntityDataId && litigationEntityDataId.length > 0 && litigationEntityDataId.includes(LitigationLegalEntity[i])) {
                            LitigationLegalEntityArr.push(LitigationLegalEntity[i])
                        }
                    }
                }
                console.log(litigationEntityDataId, 'litigationEntityDataId123454545');
                if (((LitigationLegalEntityArr.length == 0) || (LitigationLegalEntityArr == false) || (LitigationLegalEntityArr == 0))) {
                    setLitigationEntityValue([])
                    setpayloadLitigationEntityEdit([])
                }
                else {
                    setLitigationEntityValue(LitigationLegalEntityArr)
                    setpayloadLitigationEntityEdit(LitigationLegalEntityArr)

                }

                // if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                console.log('aishuuu');
                let value = GetUserDetails && GetUserDetails.ul_legal_entities.length > 0 && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                    // if (item.bg_id != null) {
                    return item.bg_id
                    // }


                })
                setLegalBg(value)
                GetUserDetails && GetUserDetails.ul_legal_entities.length > 0 && GetUserDetails && GetUserDetails.ul_legal_entities.map((item) => {
                    // if (item.bg_id != null) {
                    //     return `${item.bg_id}`
                    // }
                    let data = _.filter(businessGroupData, { bg_id: item.bg_id })
                    console.log(data, 'dataaaaaaa');
                    if (data && data.length > 0) {
                        tempp.push(data[0])
                    }

                })
                console.log(tempp, 'tempp777777');
                if (tempp && tempp.length > 0) {
                    for (let l in tempp) {
                        if (tempp[l].bg_id != null) {
                            tempp2.push(`${tempp[l].bg_id}`)
                        }
                    }
                }

                setbusinessGroupValue(tempp2)
                // } else {
                //     let data = _.filter(GetUserDetails && GetUserDetails.ul_legal_entities, { le_id: entityid })
                //     console.log(data, 'dataaaaaaaaa')
                //     if (data && data.length > 0) {
                //         for (let l in data) {
                //             if (data[l].bg_id != null) {
                //                 tempp2.push(`${data[l].bg_id}`)
                //             }
                //         }
                //     }
                //     setbusinessGroupValue(tempp2)
                // }


                let litigationbg = GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length && GetUserDetails.ul_ltg_legal_entities.map((item) => {
                    if (item.bg_id != null) {
                        return `${item.bg_id}`
                    }

                })

                let litigationbg1 = GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length && GetUserDetails.ul_ltg_legal_entities.map((item) => {

                    return item.bg_id


                })
                setLitigationLegalBg(litigationbg1)
                let templ = []
                for (let l in litigationbg) {
                    if ((litigationbg[l] != undefined) && (litigationbg[l] != null)) {
                        templ.push(litigationbg[l])
                    }
                }
                console.log(vendorEntityData, 'vendorEntityData');
                setlititigationbusinessGroupValue(templ)
                // setLitigationEntityValue(LitigationLegalEntity)
                let VendorLegalEntity = GetUserDetails && GetUserDetails.ul_vend_legal_entities && GetUserDetails && GetUserDetails.ul_vend_legal_entities.length && GetUserDetails && GetUserDetails.ul_vend_legal_entities.map((item) => {
                    return item.le_id
                })
                let VendorLegalEntityArr = []
                let VendorEntityDataId = vendorEntityData && vendorEntityData.length > 0 && vendorEntityData.map((item) => {
                    return item.le_id
                })
                if (VendorLegalEntity && VendorLegalEntity.length > 0) {
                    for (let i in VendorLegalEntity) {
                        if (VendorEntityDataId && VendorEntityDataId.length > 0 && VendorEntityDataId.includes(VendorLegalEntity[i])) {
                            VendorLegalEntityArr.push(VendorLegalEntity[i])
                        }
                    }
                }

                console.log(VendorLegalEntity, 'VendorLegalEntity8888888');

                if (((VendorLegalEntityArr && VendorLegalEntityArr.length == 0) || (VendorLegalEntityArr == false) || (VendorLegalEntityArr == 0))) {
                    setpayloadVendorEntityEdit([])
                    setVendoreEntityValue([])
                }
                else {
                    setVendoreEntityValue(VendorLegalEntityArr)
                    setpayloadVendorEntityEdit(VendorLegalEntityArr)
                }
                // if (VendorLegalEntity != 0) {
                //     setpayloadVendorEntityEdit(VendorLegalEntity)
                // }
                // else {
                //     setpayloadVendorEntityEdit([])
                // }
                let vendorBg = GetUserDetails && GetUserDetails.ul_vend_legal_entities.length && GetUserDetails.ul_vend_legal_entities.map((item) => {
                    if (item.bg_id != null) {
                        return `${item.bg_id}`
                    }
                })
                let vendorBg1 = GetUserDetails && GetUserDetails.ul_vend_legal_entities.length && GetUserDetails.ul_vend_legal_entities.map((item) => {

                    return item.bg_id

                })
                setVendorLegalBg(vendorBg1)
                let temp5 = []
                for (let l in vendorBg) {
                    if ((vendorBg[l] != undefined) && (vendorBg[l] != null)) {
                        temp5.push(vendorBg[l])
                    }
                }

                setVendorbusinessGroupValue(temp5)

                if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                    let division = GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units.map((item) => {
                        if (item.div_id != null) {
                            console.log(item.div_id, 'item.div_id');
                            return item.div_id
                        }

                    })


                    let temp = []
                    for (let l in division) {
                        if (division[l] != undefined) {
                            temp.push(division[l])
                        }
                    }
                    setDivisionValue([...new Set(temp)])
                }
                else {
                    let EntityData = _.filter(GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units, { le_id: entityid })
                    if (EntityData && EntityData.length > 0) {
                        let division = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                            if (item.div_id != null) {
                                console.log(item.div_id, 'item.div_id');
                                return item.div_id
                            }

                        })


                        let temp = []
                        for (let l in division) {
                            if (division[l] != undefined) {
                                temp.push(division[l])
                            }
                        }
                        setDivisionValue([...new Set(temp)])
                    }
                }

                // setDivisionValue(temp)
                if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                    let temp2 = []
                    let vendordivision = GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist.map((item) => {
                        if (item.div_id != null) {
                            console.log(item.div_id, 'item.div_id');
                            return item.div_id
                        }

                    })
                    for (let k in vendordivision) {
                        if (vendordivision[k] != undefined) {
                            temp2.push(vendordivision[k])
                        }
                    }
                    setvendorDivisionValue([...new Set(temp2)])
                }

                else {
                    let EntityData = _.filter(GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist, { le_id: entityid })
                    let temp2 = []
                    let vendordivision = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                        if (item.div_id != null) {
                            console.log(item.div_id, 'item.div_id');
                            return item.div_id
                        }

                    })
                    for (let k in vendordivision) {
                        if (vendordivision[k] != undefined) {
                            temp2.push(vendordivision[k])
                        }
                    }
                    setvendorDivisionValue([...new Set(temp2)])

                }
                if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                    let categorydata = GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units.map((item) => {
                        if (item.cat_id != null) {
                            console.log(item.cat_id, 'item.cat_id');
                            return item.cat_id
                        }

                    })
                    let temparr1 = []
                    for (let l in categorydata) {
                        if (categorydata[l] != undefined) {
                            temparr1.push(categorydata[l])
                        }
                    }
                    setCategoryValues([...new Set(temparr1)])
                }
                else {
                    let EntityData = _.filter(GetUserDetails && GetUserDetails.ul_user_units.length > 0 && GetUserDetails.ul_user_units, { le_id: entityid })
                    let categorydata = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                        if (item.cat_id != null) {
                            console.log(item.cat_id, 'item.cat_id');
                            return item.cat_id
                        }

                    })
                    let temparr1 = []
                    for (let l in categorydata) {
                        if (categorydata[l] != undefined) {
                            temparr1.push(categorydata[l])
                        }
                    }
                    setCategoryValues([...new Set(temparr1)])
                }
                if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                    let vendorcategorydata = GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist.map((item) => {
                        if (item.cat_id != null) {
                            console.log(item.cat_id, 'item.cat_id');
                            return item.cat_id
                        }

                    })
                    let temparr2 = []
                    for (let l in vendorcategorydata) {
                        if (vendorcategorydata[l] != undefined) {
                            temparr2.push(vendorcategorydata[l])
                        }
                    }
                    setVendorCategoryValues([...new Set(temparr2)])
                }
                else {
                    let EntityData = _.filter(GetUserDetails && GetUserDetails.vend_unitlist.length > 0 && GetUserDetails.vend_unitlist, { le_id: entityid })
                    if (EntityData && EntityData.length > 0) {
                        let vendorcategorydata = EntityData && EntityData.length > 0 && EntityData.map((item) => {
                            if (item.cat_id != null) {
                                console.log(item.cat_id, 'item.cat_id');
                                return item.cat_id
                            }

                        })
                        let temparr2 = []
                        for (let l in vendorcategorydata) {
                            if (vendorcategorydata[l] != undefined) {
                                temparr2.push(vendorcategorydata[l])
                            }
                        }
                        setVendorCategoryValues([...new Set(temparr2)])
                    }
                }
                let VendoruserCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category, { u_c_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category })
                let VendorSeatingUnit = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { u_unt_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_seating_unit })
                let VendorUserGroup = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_g_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_group_id })
                setCategory({
                    ...category,
                    compfie: userCategory && userCategory.length > 0 && userCategory[0].u_c_id,
                    compfie_vendor: VendoruserCategory && VendoruserCategory.length > 0 && VendoruserCategory[0].u_c_id

                })

                setCategoryValue({
                    ...categoryValue,
                    category: userCategory,
                    // domain: Domain,
                    serviceProvider: ServiceProvider,
                    // legalEnity: LegalEntity,
                    userGroup: UserGroup,
                    seatingUnit: SeatingUnit
                })
                setEditCompfieVendor({
                    ...EditCompfieVendor,
                    category: VendoruserCategory,
                    // domain: VendorDomains,
                    seatingUnit: VendorSeatingUnit,
                    legalEnity: VendorLegalEntity,
                    userGroup: VendorUserGroup
                })
                setCompfie({
                    ...compfie,
                    category: GetUserDetails && GetUserDetails.ul_userDetails.length > 0 && GetUserDetails.ul_userDetails[0].u_cat_id == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails.length > 0 && GetUserDetails.ul_userDetails[0].u_cat_id}`,
                    seating_Unit: SeatingUnit.length ? `${SeatingUnit[0].u_unt_code}-${SeatingUnit[0].u_unt_name}` : null,
                    user_level: GetUserDetails && GetUserDetails.ul_userDetails[0].user_level == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].user_level}`,
                    user_Group: GetUserDetails && GetUserDetails.ul_userDetails[0].u_g_id == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].u_g_id}`,
                    serviceProvider: ServiceProvider && ServiceProvider.length ? ServiceProvider && ServiceProvider.length && ServiceProvider[0].u_sp_name : null,
                    // legal_Entity: LegalEntity && LegalEntity.length ? LegalEntity[0].le_name : [],
                    // Domain: Domain && Domain.length ? Domain && Domain.length && Domain[0].u_dm_name : []
                })
                setCompfieLitigation({
                    ...compfieLitigation,
                    category: GetUserDetails && GetUserDetails.ul_userDetails[0].usr_type_id,
                    user: GetUserDetails && GetUserDetails.ul_userDetails[0].usr_type_id,
                    // legal_Entity: LitigationLegalEntity && LitigationLegalEntity.length ? LitigationLegalEntity[0].le_name : [],
                    // Domain: LitigationDomain.length ? LitigationDomain && LitigationDomain[0].u_dm_name : []
                })
                console.log(GetUserDetails && GetUserDetails.ul_userDetails[0].u_cat_id, 'GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category');
                setCompfieVendorValue({
                    ...compfieVendorValue,
                    category: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category}`,
                    seating_Unit: VendorSeatingUnit && VendorSeatingUnit.length ? `${VendorSeatingUnit[0].u_unt_code}-${VendorSeatingUnit[0].u_unt_name}` : null,
                    user_level: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_level == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_level}`,
                    user_Group: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_group_id == null ? null : `${GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_group_id}`,
                    // legal_Entity: VendorLegalEntity && VendorLegalEntity.length && VendorLegalEntity[0].le_name,
                    serviceProvider: ServiceProvider && ServiceProvider.length ? ServiceProvider && ServiceProvider.length && ServiceProvider[0].u_sp_name : null,

                    // Domain: VendorDomain && VendorDomain.length ? VendorDomain && VendorDomain.length && VendorDomain[0].u_dm_name : []
                })



            }
        }

    }, [GetUserDetails.ul_userDetails, GetUserDetails && GetUserDetails.ul_ltg_legal_entities && GetUserDetails.ul_ltg_legal_entities.length && GetUserDetails.ul_ltg_legal_entities, litigationEntityData, vendorEntityData, EntityData])

    useEffect(() => {
        if (AddType == 'edit') {
            if ((GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].is_access_ct == 1) && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1) && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1)) {
                setTabValue({
                    ...tabValue,
                    compfie: true,
                    compfie_litigation: true,
                    compfie_vendor: GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].vndr_user_category != null ? true : false
                })
            }
            else if ((GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].is_access_ct == 1) || (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1) || (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1)) {
                setTabValue({
                    ...tabValue,
                    compfie: GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ct == 1 ? true : false,
                    compfie_litigation: GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1 ? true : false,
                    compfie_vendor: ((GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1) && (GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].vndr_user_category != null)) ? true : false
                })
            }
        }

    }, [GetUserDetails && GetUserDetails.ul_userDetails, AddType])

    useEffect(() => {
        if (domainValue && domainValue.length > 0) {
            let temp = []
            for (let i in domainValue) {
                let split = domainValue[i].split("-")
                console.log(split, 'jjjjjjjjj');
                temp.push(Number(split[1]))

            }
            setDomainPayload(temp)
        }
        if (litigationdomainValue && litigationdomainValue.length > 0) {
            let temp = []
            for (let i in litigationdomainValue) {
                let split = litigationdomainValue[i] && litigationdomainValue[i].split("-")
                console.log(split, 'jjjjjjjjj');
                if (split && split.length > 0) {
                    temp.push(Number(split[1]))
                }


            }
            setLitigationDomainPayload(temp)
        }
        if (vendordomainValue && vendordomainValue.length > 0) {
            let temp = []
            for (let i in vendordomainValue) {
                let split = vendordomainValue[i] && vendordomainValue[i].split("-")
                console.log(split, 'jjjjjjjjj');
                if (split && split.length > 0) {
                    temp.push(Number(split[1]))
                }

            }
            setVendorDomainPayload(temp)
        }
    }, [domainValue, litigationdomainValue, vendordomainValue])


    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_user_units.length && AddType == 'edit') {
            console.log(vendoreEntityValue, 'vendoreEntityValue');
            let temparr = []

            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []

            for (let s in entityValue) {

                if (GetUserDetails && GetUserDetails.ul_user_units.length > 0) {
                    let data = _.filter(GetUserDetails.ul_user_units, { le_id: entityValue[s] })

                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }
            for (let t in vendoreEntityValue) {

                if (GetUserDetails && GetUserDetails.vend_unitlist.length > 0) {
                    let data = _.filter(GetUserDetails.vend_unitlist, { le_id: vendoreEntityValue[t] })

                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem2.push(data[j])
                        }
                    }
                }
            }

            console.log(tem, 'temtem');
            if (tem && tem.length > 0) {
                for (let i in tem) {
                    if (selectUnitData.length) {
                        let data = _.find(selectUnitData, { u_unt_id: Number(tem[i].u_unt_id) })
                        temparr.push(data)

                    }
                    if (closedUnitData.length) {
                        let closedData = _.find(closedUnitData, { u_unt_id: Number(tem[i].u_unt_id) })
                        closedTemp.push(closedData)
                    }

                    if (userManagementDetails && userManagementDetails.um_group_category.length) {
                        let Data = _.find(userManagementDetails && userManagementDetails.um_group_category, { cat_id: Number(GetUserDetails && GetUserDetails.ul_user_units.length && GetUserDetails.ul_user_units[i].cat_id) })
                        categoryTemp.push(Data)
                    }

                    if (userManagementDetails && userManagementDetails.um_cmpf_group_division.length) {
                        let Data = _.find(userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division, { d_id: Number(GetUserDetails && GetUserDetails.ul_user_units.length && GetUserDetails.ul_user_units[i].div_id) })
                        divisionarr.push(Data)
                    }
                }
            }
            console.log(vendorUnit, 'vendorUnitvendorUnit');
            if (tem2 && tem2.length > 0) {
                for (let i in tem2) {
                    if (vendorUnit.length > 0) {
                        console.log('ice');
                        let data = _.find(vendorUnit, { u_unt_id: Number(tem2 && tem2.length > 0 && tem2[i].u_unt_id) })
                        vendor.push(data)
                        console.log(data, 'snehaaaaaaaaaaa');
                    }
                    if (vendorClosedUnit.length > 0) {
                        let data = _.find(vendorClosedUnit, { u_unt_id: Number(tem2 && tem2.length > 0 && tem2[i].u_unt_id) })
                        vendorClosed.push(data)
                    }

                }
            }
            console.log(vendor, 'vendorsssss');
            if (temparr && temparr.length) {
                let data = []
                let a = []
                console.log(temparr, 'temparrtemparr');
                for (let i in temparr) {
                    if (temparr && temparr.length && temparr[i] != undefined) {
                        // if (entityid === null || entityid === "null") {
                        //     console.log(all[i], 'all[i]');
                        //     if (unitEntity&&unitEntity.length) {
                        //         temparr2.push(

                        //             {
                        //                 le_id: legalEntity[k].le_id,
                        //                 u_id: temparr && temparr.length && temparr[i].u_unt_id
                        //             }
                        //         )
                        //     }

                        // }
                        // else {
                        //     temparr2.push(

                        //         {
                        //             le_id: entityid,
                        //             u_id: temparr && temparr.length && temparr[i].u_unt_id
                        //         }
                        //     )
                        // }
                        a.push(temparr && temparr.length && temparr[i])
                        data.push(
                            temparr && temparr.length && temparr[i].u_unt_id
                        )


                    }

                }
                console.log(a, 'SMMMM');
                console.log(temparr2, 'temparr2');
                console.log(data, 'data');
                setUnitEntity(a)
                // setUserUnits(temparr2)
                setAll(data)
            }
            if (closedTemp && closedTemp.length) {
                let data = []
                let a = []
                // for (let i in temparr) {
                //     if (temparr && temparr.length && temparr[i] != undefined) {
                //         temparr2.push({

                //             label: `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                //             value: temparr && temparr.length && temparr[i].u_unt_id
                //         })
                //         data.push(
                //             `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                //         )

                //     }
                // }
                for (let i in closedTemp) {
                    if (closedTemp && closedTemp.length && closedTemp[i] != undefined) {
                        // if (entityid === null || entityid === "null") {
                        //     console.log(all[i], 'all[i]');
                        //     for (let k in legalEntity) {
                        //         ClosedTemparr.push(

                        //             {
                        //                 le_id: legalEntity[k].le_id,
                        //                 u_id: closedTemp && closedTemp.length && closedTemp[i].u_unt_id
                        //             }
                        //         )
                        //     }

                        // }
                        // else {
                        //     ClosedTemparr.push(

                        //         {
                        //             le_id: entityid,
                        //             u_id: closedTemp && closedTemp.length && closedTemp[i].u_unt_id
                        //         }
                        //     )
                        // }
                        a.push(closedTemp && closedTemp.length && closedTemp[i])
                        data.push(
                            closedTemp && closedTemp.length && closedTemp[i].u_unt_id
                        )

                    }

                }
                console.log(a, 'temparr2');
                console.log(data, 'data');
                setclosedEntityUnit(a)
                // setClosedUserUnits(ClosedTemparr)
                // setClosedSelected(data)
                // setCloseSelected(ClosedTemparr)
                setclosedAll(data)
            }
            if (vendor && vendor.length) {
                let vendorData = []
                let data = []
                let a = []
                // for (let i in temparr) {
                //     if (temparr && temparr.length && temparr[i] != undefined) {
                //         temparr2.push({

                //             label: `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                //             value: temparr && temparr.length && temparr[i].u_unt_id
                //         })
                //         data.push(
                //             `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                //         )

                //     }
                // }
                console.log(vendor, 'vendorvendorvendorvendor');
                for (let i in vendor) {
                    if (vendor && vendor.length && vendor[i] != undefined) {
                        // if (entityid === null || entityid === "null") {
                        //     console.log(all[i], 'all[i]');
                        //     for (let k in legalEntity) {
                        //         vendorData.push(

                        //             {
                        //                 le_id: legalEntity[k].le_id,
                        //                 u_id: vendor && vendor.length && vendor[i].u_unt_id
                        //             }
                        //         )
                        //     }

                        // }
                        // else {
                        //     vendorData.push(

                        //         {
                        //             le_id: entityid,
                        //             u_id: vendor && vendor.length && vendor[i].u_unt_id
                        //         }
                        //     )
                        // }
                        a.push(vendor && vendor.length && vendor[i])
                        data.push(
                            vendor && vendor.length && vendor[i].u_unt_id
                        )

                    }

                }
                console.log(temparr2, 'temparr2');
                console.log(data, 'data');
                setVendorUnit(a)
                // setVendorUnitDetails(vendorData)
                setVendorAll(data)
            }
            if (vendorClosed && vendorClosed.length) {
                let vendorData = []
                let data = []
                let a = []
                console.log(vendorClosed, 'vendorvendorvendorvendor');
                for (let i in vendorClosed) {
                    if (vendorClosed && vendorClosed.length && vendorClosed[i] != undefined) {
                        // if (entityid === null || entityid === "null") {
                        //     console.log(all[i], 'all[i]');
                        //     console.log(vendorlegalEntity, 'legalEntity123');
                        //     for (let k in vendorlegalEntity) {
                        //         vendorData.push(

                        //             {
                        //                 le_id: vendorlegalEntity[k].le_id,
                        //                 u_id: vendorClosed && vendorClosed.length && vendorClosed[i].u_unt_id
                        //             }
                        //         )
                        //     }

                        // }
                        // else {
                        //     vendorData.push(

                        //         {
                        //             le_id: entityid,
                        //             u_id: vendorClosed && vendorClosed.length && vendorClosed[i].u_unt_id
                        //         }
                        //     )
                        // }
                        data.push(
                            vendorClosed && vendorClosed.length && vendorClosed[i].u_unt_id
                        )
                        a.push(vendorClosed && vendorClosed.length && vendorClosed[i])

                    }

                }
                console.log(temparr2, 'temparr2');
                console.log(data, 'data');
                setvendorClosedunitEntity(a)
                // setVendorClosedUnitDetails(vendorData)
                setVendorClosedAll(data)
            }

            if (categoryTemp && categoryTemp.length) {
                for (let i in categoryTemp) {
                    if (categoryTemp && categoryTemp.length && categoryTemp[i] != undefined) {
                        categoryTemparr.push({

                            label: categoryTemp && categoryTemp.length && categoryTemp[i].cat_name,
                            value: categoryTemp && categoryTemp.length && categoryTemp[i].cat_id
                        })

                    }
                }

                setcompfieCategory(categoryTemparr)
            }
            if (divisionarr && divisionarr.length) {
                for (let i in divisionarr) {
                    if (divisionarr && divisionarr.length && divisionarr[i] != undefined) {
                        divisionArr.push({

                            label: divisionarr && divisionarr.length && divisionarr[i].d_name,
                            value: divisionarr && divisionarr.length && divisionarr[i].d_id
                        })

                    }
                }

                setDivisions(divisionArr)
            }

        }
        if (GetUserDetails && GetUserDetails.vend_unitlist.length && AddType == 'edit') {
            let temparr = []
            let temparr2 = []
            for (let i in GetUserDetails && GetUserDetails.vend_unitlist.length && GetUserDetails && GetUserDetails.vend_unitlist) {
                let data = _.find(vendorUnit, { u_unt_id: GetUserDetails && GetUserDetails.vend_unitlist.length && GetUserDetails.vend_unitlist[i].u_unt_id })
                temparr.push(data)
            }
            if (temparr && temparr.length && temparr[0] != undefined) {
                for (let i in temparr) {
                    if (temparr && temparr.length && temparr[i] != undefined) {
                        temparr2.push({

                            label: `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                            value: temparr && temparr.length && temparr[i].u_unt_id
                        })

                    }
                }

                setvendorSelected(temparr2)
            }
        }
    }, [GetUserDetails && GetUserDetails.ul_user_units, entityValue, vendoreEntityValue, GetUserDetails && GetUserDetails.vend_unitlist, legalEntity])
    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_user_units.length && AddType == 'edit') {
            if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
                let temparr = []
                let temparr2 = []
                let closedTemp = []
                let ClosedTemparr = []
                let vendor = []
                let vendorClosed = []
                let categoryTemp = []
                let categoryTemparr = []
                let divisionarr = []
                let divisionArr = []
                let tem = []
                let tem2 = []



                if (GetUserDetails && GetUserDetails.ul_user_units.length > 0) {
                    for (let i in GetUserDetails && GetUserDetails.ul_user_units) {
                        tem.push({
                            le_id: GetUserDetails && GetUserDetails.ul_user_units[i].le_id,
                            u_id: GetUserDetails && GetUserDetails.ul_user_units[i].u_unt_id
                        })
                    }
                }
                setPayloadEditunit(tem)
                if (GetUserDetails && GetUserDetails.vend_unitlist.length > 0) {
                    let temp = []
                    for (let i in GetUserDetails && GetUserDetails.vend_unitlist) {
                        temp.push({
                            le_id: GetUserDetails && GetUserDetails.vend_unitlist[i].le_id,
                            u_id: GetUserDetails && GetUserDetails.vend_unitlist[i].u_unt_id
                        })
                    }
                    setPayloadClosedEditunit(temp)
                }




            }

        }
    }, [GetUserDetails && GetUserDetails.ul_user_units, GetUserDetails && GetUserDetails.vend_unitlist])
    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_user_domains.length > 0 && AddType == 'edit') {
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []

            for (let s in entityValue) {

                if (GetUserDetails && GetUserDetails.ul_user_domains
                    .length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_user_domains.length && GetUserDetails.ul_user_domains, { le_id: entityValue[s] })
                    console.log(data, 'datadatadata');
                    console.log(GetUserDetails && GetUserDetails.ul_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }


            setdomainEntity(tem)

            if (tem && tem.length > 0) {
                let data = tem && tem.length > 0 && tem.map((item) => {
                    return `${item.le_id}-${item.u_dm_id}`
                })
                setdomainValue(data)
            }

            if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
                let temarray = []
                for (let s in payloadEntityEdit) {

                    if (GetUserDetails && GetUserDetails.ul_user_domains.length > 0) {
                        let data = _.filter(GetUserDetails && GetUserDetails.ul_user_domains.length && GetUserDetails.ul_user_domains, { le_id: payloadEntityEdit[s] })
                        console.log(data, 'datadatadata');
                        console.log(GetUserDetails && GetUserDetails.ul_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                        if (data && data.length > 0) {
                            for (let j in data) {
                                temarray.push(data[j])
                            }
                        }
                    }

                }


                // setdomainEntity(tem)
                console.log(temarray, 'tem555555');
                if (temarray && temarray.length > 0) {
                    let data = temarray && temarray.length > 0 && temarray.map((item) => {

                        temparr.push({
                            le_id: item.le_id,
                            d_id: item.u_dm_id
                        })
                    })
                    console.log(temparr, 'mukilan');
                    setPayloaEditdDomain(temparr)
                }
            }


        }
        if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length && AddType == 'edit') {
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []



            for (let s in litigationEntityValue) {

                if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_ltg_user_domains.length && GetUserDetails.ul_ltg_user_domains, { le_id: litigationEntityValue[s] })

                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }

            setlitigationdomainEntity(tem)

            let datas = litigationDomainData && litigationDomainData.length > 0 && litigationDomainData.map((item) => {
                return item.u_dm_id
            })

            if (tem && tem.length > 0) {
                let data = tem && tem.length > 0 && tem.map((item) => {
                    if (datas.includes(item.u_dm_id)) {
                        return `${item.le_id}-${item.u_dm_id}`
                    }
                })
                setlitigationdomainValue(data)
            }
            if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
                let temarray = []
                for (let s in payloadLitigationEntityEdit) {

                    if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length > 0) {
                        let data = _.filter(GetUserDetails && GetUserDetails.ul_ltg_user_domains.length && GetUserDetails.ul_ltg_user_domains, { le_id: payloadLitigationEntityEdit[s] })
                        console.log(data, 'datadatadata');
                        console.log(GetUserDetails && GetUserDetails.ul_ltg_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                        if (data && data.length > 0) {
                            for (let j in data) {
                                temarray.push(data[j])
                            }
                        }
                    }

                }


                // setdomainEntity(tem)

                if (temarray && temarray.length > 0) {
                    let data = temarray && temarray.length > 0 && temarray.map((item) => {

                        temparr.push({
                            le_id: item.le_id,
                            d_id: item.u_dm_id
                        })
                    })
                    setpayloadEditlitigationDomain(temparr)
                }
            }

        }
        if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length && AddType == 'edit') {
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let vendor = []
            let vendorClosed = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            let tem = []
            let tem2 = []
            for (let s in vendoreEntityValue) {

                if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length > 0) {
                    let data = _.filter(GetUserDetails && GetUserDetails.ul_vend_user_domains.length && GetUserDetails.ul_vend_user_domains, { le_id: vendoreEntityValue[s] })

                    if (data && data.length > 0) {
                        for (let j in data) {
                            tem.push(data[j])
                        }
                    }
                }

            }

            setvendordomainEntity(tem)
            let datas = VendorDomainData && VendorDomainData.length > 0 && VendorDomainData.map((item) => {
                return item.u_dm_id
            })
            if (tem && tem.length > 0) {
                let data = tem && tem.length > 0 && tem.map((item) => {
                    if (datas.includes(item.u_dm_id)) {
                        return `${item.le_id}-${item.u_dm_id}`
                    }
                    // else {
                    //     return null
                    // }
                })
                setvendordomainValues(data)
            }

            if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
                let temarray = []
                for (let s in payloadVendorEntityEdit) {

                    if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length > 0) {
                        let data = _.filter(GetUserDetails && GetUserDetails.ul_vend_user_domains.length && GetUserDetails.ul_vend_user_domains, { le_id: payloadEntityEdit[s] })
                        console.log(data, 'datadatadata');
                        console.log(GetUserDetails && GetUserDetails.ul_vend_user_domains, 'GetUserDetails && GetUserDetails.ul_user_domains');
                        if (data && data.length > 0) {
                            for (let j in data) {
                                temarray.push(data[j])
                            }
                        }
                    }

                }


                // setdomainEntity(tem)

                if (temarray && temarray.length > 0) {
                    let data = temarray && temarray.length > 0 && temarray.map((item) => {

                        temparr.push({
                            le_id: item.le_id,
                            d_id: item.u_dm_id
                        })
                    })
                    setPayloaEditVendordDomain(temparr)
                }
            }



        }

    }, [GetUserDetails && GetUserDetails.ul_user_domains, entityValue, litigationEntityValue, vendoreEntityValue])



    const userDetailsOnSubmit = () => {
        setAddFormSubmit(true);
        setCategoryFormSubmit(true)
        console.log('aishuuuu');
        if (AddType == 'add') {


            if (validator.current.allValid()) {
                if (Formvalidator.current.allValid()) {

                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveClientUser",
                                {
                                    "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                                    "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                    "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                                    "u_g_id": ((compfie.user_Group != null) && tabValue.compfie == true) ? Number(compfie.user_Group) : null,
                                    "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                                    "email_id": userDetails.email,
                                    "emp_name": userDetails.emp_name,
                                    "emp_code": userDetails.emp_id,
                                    "cont_no": "--",
                                    "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                    "u_level": (((compfie.user_level != "null") || (compfie.user_level != null)) && (tabValue.compfie == true)) ? Number(compfie.user_level) : null,
                                    "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                    // "s_unit": null,
                                    // "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
                                    "is_sp": compfie.serviceProvider != '' ? true : false,
                                    "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
                                    "user_entity_ids": ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],
                                    "l_user_entity_ids": ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                    "vend_user_entity_ids": ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                    "user_domain_ids": compfie.Domain,
                                    "l_user_domain_ids": compfieLitigation.Domain,
                                    "vend_user_domain_ids": compfieVendorValue.Domain,
                                    "user_unit_ids": unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                    "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                                    "checked_value": "0",
                                    "otp_remarks": "OTP feature has been switched off",
                                    "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                    "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
                                    "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
                                }
                            ]


                        }
                    ]
                    addUserManagementDetails({
                        payload: payload,
                        paramid: paramid
                    })
                }
                else {
                    Toaster.warning("Please  Enter All Values")
                }
            }

            // else {
            //     Toaster.warning("Please  Enter All Values")
            // }

        }

        if (AddType == 'edit') {

            if (validator.current.allValid()) {
                if (Formvalidator.current.allValid()) {
                    const payloadEdit = [
                        authtoken,
                        {
                            "session_token": authtoken,

                            "request": [
                                "UpdateClientUser",
                                {
                                    "u_id": Number(GetUserDetails && GetUserDetails.ul_userDetails[0].user_id),
                                    "u_cat_id": compfie && compfie.category == (categoryValue && categoryValue.category.length && categoryValue.category[0].u_c_name) ? Number(categoryValue && categoryValue.category[0].u_c_id) : compfie.category == null ? null : Number(compfie.category),
                                    "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                    "vend_u_cat_id": compfieVendorValue.category === (EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_name) ? Number(EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_id) : compfieVendorValue.category == null ? null : Number(compfieVendorValue.category),
                                    "u_g_id": compfie && compfie.user_Group === (categoryValue && categoryValue.userGroup.length > 0 && categoryValue.userGroup[0].u_g_name) ? Number(categoryValue && categoryValue.userGroup && categoryValue.userGroup.length && categoryValue.userGroup[0].u_g_id) : compfie && compfie.user_Group == null ? null : Number(compfie && compfie.user_Group),
                                    "vend_u_g_id": compfieVendorValue.user_Group === (EditCompfieVendor && EditCompfieVendor.userGroup.length && EditCompfieVendor.userGroup[0].u_g_name) ? Number(EditCompfieVendor && EditCompfieVendor.userGroup[0].u_g_id) : compfieVendorValue.user_Group == null ? null : Number(compfieVendorValue.user_Group),
                                    "email_id": userDetails.email,
                                    "emp_name": userDetails.emp_name,
                                    "emp_code": userDetails.emp_id,
                                    "cont_no": "--",
                                    "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                    "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                                    "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                    "is_sp": compfie.serviceProvider == null ? false : true,
                                    "sp_id": compfie.serviceProvider === (categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_name) ? Number(categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_id) : compfie.serviceProvider == null ? null : Number(compfie.serviceProvider),

                                    "l_user_entity_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadLitigationEntityEdit : ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                    "vend_user_entity_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadVendorEntityEdit :
                                            ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                    "l_user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditlitigationDomain :
                                            ((tabValue.compfie_litigation == true) && (compfieLitigation.Domain.length)) ? compfieLitigation.Domain : [],
                                    "vend_user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditVendorDomain :
                                            ((tabValue.compfie_vendor == true) && (compfieVendorValue.Domain.length > 0)) ? compfieVendorValue.Domain : [],
                                    "user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditDomain :
                                            ((tabValue.compfie == true) && (compfie.Domain.length)) ? compfie.Domain : [],
                                    "user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditunit : unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                    "vndr_user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditClosedunit : vendorAccess == true ? vendorUnitDetails : vendorUnitDetails.concat(vendorClosedUnitDetails),
                                    "user_entity_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEntityEdit : ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],

                                    "c_user": compfie.category != null ? true : false,
                                    "v_user": compfieVendorValue.category != null ? true : false,
                                    "l_user": compfieLitigation.category != null ? true : false,
                                    "checked_value": "0",
                                    "otp_remarks": "OTP feature has been switched off",
                                    "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                    "ltmg_prdt_access": compfieLitigation.category != null == true ? 1 : 0,
                                    "vndr_prdt_access": compfieVendorValue.category != null ? 1 : 0,
                                }
                            ]

                        }
                    ]
                    console.log(payloadEdit, 'payloadEdit');
                    console.log(compfieVendorValue, 'compfieVendorValue');
                    edituserManagementDetails({
                        payload: payloadEdit,
                        paramid: paramid
                    })

                }
                else {
                    Toaster.warning("Please  Enter All Values")
                }
            }

            else {
                Toaster.warning("Please  Enter All Values")
            }

        }

    }

    const userDetailsSaved = () => {
        setAddFormSubmit(true);
        if (AddType == 'add') {
            if (tabValue.compfie_litigation == true && tabValue.compfie == false && tabValue.compfie_vendor == false) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveClientUser",
                            {
                                "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                                "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                                "u_g_id": ((compfie.user_Group != null) && tabValue.compfie == true) ? Number(compfie.user_Group) : null,
                                "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                                "email_id": userDetails.email,
                                "emp_name": userDetails.emp_name,
                                "emp_code": userDetails.emp_id,
                                "cont_no": "--",
                                "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                "u_level": (((compfie.user_level != "null") || (compfie.user_level != null)) && (tabValue.compfie == true)) ? Number(compfie.user_level) : null,
                                "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                // "s_unit": null,
                                // "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
                                "is_sp": compfie.serviceProvider != '' ? true : false,
                                "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
                                "user_entity_ids": ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],
                                "l_user_entity_ids": ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                "vend_user_entity_ids": ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                "user_domain_ids": compfie.Domain,
                                "l_user_domain_ids": compfieLitigation.Domain,
                                "vend_user_domain_ids": compfieVendorValue.Domain,
                                "user_unit_ids": unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                                "checked_value": "0",
                                "otp_remarks": "OTP feature has been switched off",
                                "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
                                "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
                            }
                        ]


                    }
                ]
                addUserManagementDetails({
                    payload: payload,
                    paramid: paramid
                })
            } else {
                if (compfie.category == 3 || compfie.category == 2 || compfie.category == 7) {
                    console.log('ifcase');
                    if (validator.current.allValid()) {
                        if (Formvalidator.current.allValid()) {
                            if (userUnits.length > 0 || vendorUnitDetails.length > 0 || userClosedUnits.length > 0 || vendorClosedUnitDetails.length > 0) {
                                const payload = [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "SaveClientUser",
                                            {
                                                "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                                                "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                                "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                                                "u_g_id": ((compfie.user_Group != null) && tabValue.compfie == true) ? Number(compfie.user_Group) : null,
                                                "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                                                "email_id": userDetails.email,
                                                "emp_name": userDetails.emp_name,
                                                "emp_code": userDetails.emp_id,
                                                "cont_no": "--",
                                                "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                                "u_level": (((compfie.user_level != "null") || (compfie.user_level != null)) && (tabValue.compfie == true)) ? Number(compfie.user_level) : null,
                                                "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                                // "s_unit": null,
                                                // "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
                                                "is_sp": compfie.serviceProvider != '' ? true : false,
                                                "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
                                                "user_entity_ids": ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],
                                                "l_user_entity_ids": ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                                "vend_user_entity_ids": ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                                "user_domain_ids": compfie.Domain,
                                                "l_user_domain_ids": compfieLitigation.Domain,
                                                "vend_user_domain_ids": compfieVendorValue.Domain,
                                                "user_unit_ids": unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                                "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                                                "checked_value": "0",
                                                "otp_remarks": "OTP feature has been switched off",
                                                "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                                "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
                                                "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
                                            }
                                        ]


                                    }
                                ]
                                addUserManagementDetails({
                                    payload: payload,
                                    paramid: paramid
                                })
                            }
                            else {
                                Toaster.error("Atleast one unit required for each Legal Entity for compfie")
                            }
                        }
                    }
                }

                else {
                    if (userUnits.length > 0 || vendorUnitDetails.length > 0 || userClosedUnits.length > 0 || vendorClosedUnitDetails.length > 0) {
                        if (validator.current.allValid()) {
                            if (Formvalidator.current.allValid()) {
                                const payload = [
                                    authtoken,
                                    {
                                        "session_token": authtoken,
                                        "request": [
                                            "SaveClientUser",
                                            {
                                                "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                                                "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                                "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                                                "u_g_id": ((compfie.user_Group != null) && tabValue.compfie == true) ? Number(compfie.user_Group) : null,
                                                "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                                                "email_id": userDetails.email,
                                                "emp_name": userDetails.emp_name,
                                                "emp_code": userDetails.emp_id,
                                                "cont_no": "--",
                                                "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                                "u_level": (((compfie.user_level != "null") || (compfie.user_level != null)) && (tabValue.compfie == true)) ? Number(compfie.user_level) : null,
                                                "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                                // "s_unit": null,
                                                // "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
                                                "is_sp": compfie.serviceProvider != '' ? true : false,
                                                "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
                                                "user_entity_ids": ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],
                                                "l_user_entity_ids": ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                                "vend_user_entity_ids": ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                                "user_domain_ids": compfie.Domain,
                                                "l_user_domain_ids": compfieLitigation.Domain,
                                                "vend_user_domain_ids": compfieVendorValue.Domain,
                                                "user_unit_ids": unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                                "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                                                "checked_value": "0",
                                                "otp_remarks": "OTP feature has been switched off",
                                                "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                                "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
                                                "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
                                            }

                                        ]


                                    }
                                ]
                                addUserManagementDetails({
                                    payload: payload,
                                    paramid: paramid
                                })
                            }
                        }

                    }
                    else {
                        Toaster.error("Atleast one unit required for each Legal Entity for compfie")
                    }
                }
            }
        }

        if (AddType == 'edit') {
            if (compfie.category == 3 || compfie.category == 2 || compfie.category == 7 ||
                compfie.category == "View Only Restricted" || compfie.category == "Legal Entity Admin" || compfie.category == "View Only") {
                if (validator.current.allValid()) {
                    if (Formvalidator.current.allValid()) {
                        if (userUnits.length > 0 || vendorUnitDetails.length > 0 || userClosedUnits.length > 0 || vendorClosedUnitDetails.length > 0) {
                            const payloadEdit = [
                                authtoken,
                                {
                                    "session_token": authtoken,

                                    "request": [
                                        "UpdateClientUser",
                                        {
                                            "u_id": Number(GetUserDetails && GetUserDetails.ul_userDetails[0].user_id),
                                            "u_cat_id": compfie && compfie.category == (categoryValue && categoryValue.category.length && categoryValue.category[0].u_c_name) ? Number(categoryValue && categoryValue.category[0].u_c_id) : compfie.category == null ? null : Number(compfie.category),
                                            "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                            "vend_u_cat_id": compfieVendorValue.category === (EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_name) ? Number(EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_id) : compfieVendorValue.category == null ? null : Number(compfieVendorValue.category),
                                            "u_g_id": compfie && compfie.user_Group === (categoryValue && categoryValue.userGroup.length > 0 && categoryValue.userGroup[0].u_g_name) ? Number(categoryValue && categoryValue.userGroup && categoryValue.userGroup.length && categoryValue.userGroup[0].u_g_id) : compfie && compfie.user_Group == null ? null : Number(compfie && compfie.user_Group),
                                            "vend_u_g_id": compfieVendorValue.user_Group === (EditCompfieVendor && EditCompfieVendor.userGroup.length && EditCompfieVendor.userGroup[0].u_g_name) ? Number(EditCompfieVendor && EditCompfieVendor.userGroup[0].u_g_id) : compfieVendorValue.user_Group == null ? null : Number(compfieVendorValue.user_Group),
                                            "email_id": userDetails.email,
                                            "emp_name": userDetails.emp_name,
                                            "emp_code": userDetails.emp_id,
                                            "cont_no": "--",
                                            "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                            "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                                            "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                            "is_sp": compfie.serviceProvider == null ? false : true,
                                            "sp_id": compfie.serviceProvider === (categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_name) ? Number(categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_id) : compfie.serviceProvider == null ? null : Number(compfie.serviceProvider),

                                            "l_user_entity_ids":
                                                localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadLitigationEntityEdit : ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                            "vend_user_entity_ids":
                                                localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadVendorEntityEdit :
                                                    ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                            "l_user_domain_ids":
                                                localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditlitigationDomain :
                                                    ((tabValue.compfie_litigation == true) && (compfieLitigation.Domain.length)) ? compfieLitigation.Domain : [],
                                            "vend_user_domain_ids":
                                                localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditVendorDomain :
                                                    ((tabValue.compfie_vendor == true) && (compfieVendorValue.Domain.length > 0)) ? compfieVendorValue.Domain : [],
                                            "user_domain_ids":
                                                localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditDomain :
                                                    ((tabValue.compfie == true) && (compfie.Domain.length)) ? compfie.Domain : [],
                                            "user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditunit : unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                            "vndr_user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditClosedunit : vendorAccess == true ? vendorUnitDetails : vendorUnitDetails.concat(vendorClosedUnitDetails),
                                            "user_entity_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEntityEdit : ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],

                                            "c_user": compfie.category != null ? true : false,
                                            "v_user": compfieVendorValue.category != null ? true : false,
                                            "l_user": compfieLitigation.category != null ? true : false,
                                            "checked_value": "0",
                                            "otp_remarks": "OTP feature has been switched off",
                                            "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                            "ltmg_prdt_access": compfieLitigation.category != null == true ? 1 : 0,
                                            "vndr_prdt_access": compfieVendorValue.category != null ? 1 : 0,
                                        }
                                    ]

                                }
                            ]
                            console.log(payloadEdit, 'payloadEdit');
                            edituserManagementDetails({
                                payload: payloadEdit,
                                paramid: paramid
                            })
                        }
                        else {
                            Toaster.error("Atleast one unit required for each Legal Entity for compfie")
                        }
                    }
                }
            }
            else {
                if (userUnits.length > 0 || vendorUnitDetails.length > 0 || userClosedUnits.length > 0 || vendorClosedUnitDetails.length > 0) {
                    const payloadEdit = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "UpdateClientUser",
                                {
                                    "u_id": Number(GetUserDetails && GetUserDetails.ul_userDetails[0].user_id),
                                    "u_cat_id": compfie && compfie.category == (categoryValue && categoryValue.category.length && categoryValue.category[0].u_c_name) ? Number(categoryValue && categoryValue.category[0].u_c_id) : compfie.category == null ? null : Number(compfie.category),
                                    "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                    "vend_u_cat_id": compfieVendorValue.category === (EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_name) ? Number(EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_id) : compfieVendorValue.category == null ? null : Number(compfieVendorValue.category),
                                    "u_g_id": compfie && compfie.user_Group === (categoryValue && categoryValue.userGroup.length > 0 && categoryValue.userGroup[0].u_g_name) ? Number(categoryValue && categoryValue.userGroup && categoryValue.userGroup.length && categoryValue.userGroup[0].u_g_id) : compfie && compfie.user_Group == null ? null : Number(compfie && compfie.user_Group),
                                    "vend_u_g_id": compfieVendorValue.user_Group === (EditCompfieVendor && EditCompfieVendor.userGroup.length && EditCompfieVendor.userGroup[0].u_g_name) ? Number(EditCompfieVendor && EditCompfieVendor.userGroup[0].u_g_id) : compfieVendorValue.user_Group == null ? null : Number(compfieVendorValue.user_Group),
                                    "email_id": userDetails.email,
                                    "emp_name": userDetails.emp_name,
                                    "emp_code": userDetails.emp_id,
                                    "cont_no": "--",
                                    "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                    "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                                    "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                    "is_sp": compfie.serviceProvider == null ? false : true,
                                    "sp_id": compfie.serviceProvider === (categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_name) ? Number(categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_id) : compfie.serviceProvider == null ? null : Number(compfie.serviceProvider),

                                    "l_user_entity_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadLitigationEntityEdit : ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                    "vend_user_entity_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadVendorEntityEdit :
                                            ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                    "l_user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditlitigationDomain :
                                            ((tabValue.compfie_litigation == true) && (compfieLitigation.Domain.length)) ? compfieLitigation.Domain : [],
                                    "vend_user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditVendorDomain :
                                            ((tabValue.compfie_vendor == true) && (compfieVendorValue.Domain.length > 0)) ? compfieVendorValue.Domain : [],
                                    "user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditDomain :
                                            ((tabValue.compfie == true) && (compfie.Domain.length)) ? compfie.Domain : [],
                                    "user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditunit : unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                    "vndr_user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditClosedunit : vendorAccess == true ? vendorUnitDetails : vendorUnitDetails.concat(vendorClosedUnitDetails),
                                    "user_entity_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEntityEdit : ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],

                                    "c_user": compfie.category != null ? true : false,
                                    "v_user": compfieVendorValue.category != null ? true : false,
                                    "l_user": compfieLitigation.category != null ? true : false,
                                    "checked_value": "0",
                                    "otp_remarks": "OTP feature has been switched off",
                                    "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                    "ltmg_prdt_access": compfieLitigation.category != null == true ? 1 : 0,
                                    "vndr_prdt_access": compfieVendorValue.category != null ? 1 : 0,
                                }
                            ]

                        }
                    ]
                    console.log(payloadEdit, 'payloadEdit');
                    edituserManagementDetails({
                        payload: payloadEdit,
                        paramid: paramid
                    })
                }
                else {
                    Toaster.error("Atleast one unit required for each Legal Entity for compfie")
                }
            }

        }



    }
    const userDetailsSaved1 = () => {
        setAddFormSubmit(true);
        if (AddType == 'add') {
            if (tabValue.compfie_litigation == true && tabValue.compfie == false && tabValue.compfie_vendor == false) {
                if (validator.current.allValid()) {
                    if (Formvalidator.current.allValid()) {
                        const payload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "SaveClientUser",
                                    {
                                        "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                                        "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                        "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                                        "u_g_id": ((compfie.user_Group != null) && tabValue.compfie == true) ? Number(compfie.user_Group) : null,
                                        "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                                        "email_id": userDetails.email,
                                        "emp_name": userDetails.emp_name,
                                        "emp_code": userDetails.emp_id,
                                        "cont_no": "--",
                                        "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                        "u_level": (((compfie.user_level != "null") || (compfie.user_level != null)) && (tabValue.compfie == true)) ? Number(compfie.user_level) : null,
                                        "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                        // "s_unit": null,
                                        // "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
                                        "is_sp": compfie.serviceProvider != '' ? true : false,
                                        "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
                                        "user_entity_ids": ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],
                                        "l_user_entity_ids": ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                        "vend_user_entity_ids": ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                        "user_domain_ids": compfie.Domain,
                                        "l_user_domain_ids": compfieLitigation.Domain,
                                        "vend_user_domain_ids": compfieVendorValue.Domain,
                                        "user_unit_ids": unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                        "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                                        "checked_value": "0",
                                        "otp_remarks": "OTP feature has been switched off",
                                        "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                        "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
                                        "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
                                    }
                                ]


                            }
                        ]
                        addUserManagementDetails({
                            payload: payload,
                            paramid: paramid
                        })
                    }
                    else {
                        Toaster.warning('Please Enter All the Fields')
                    }
                }
                else {
                    Toaster.warning('Please Enter All the Fields')
                }
            }
        }

        if (AddType == 'edit') {



            if (validator.current.allValid()) {
                if (Formvalidator.current.allValid()) {

                    const payloadEdit = [
                        authtoken,
                        {
                            "session_token": authtoken,

                            "request": [
                                "UpdateClientUser",
                                {
                                    "u_id": Number(GetUserDetails && GetUserDetails.ul_userDetails[0].user_id),
                                    "u_cat_id": compfie && compfie.category == (categoryValue && categoryValue.category.length && categoryValue.category[0].u_c_name) ? Number(categoryValue && categoryValue.category[0].u_c_id) : compfie.category == null ? null : Number(compfie.category),
                                    "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                    "vend_u_cat_id": compfieVendorValue.category === (EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_name) ? Number(EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_id) : compfieVendorValue.category == null ? null : Number(compfieVendorValue.category),
                                    "u_g_id": compfie && compfie.user_Group === (categoryValue && categoryValue.userGroup.length > 0 && categoryValue.userGroup[0].u_g_name) ? Number(categoryValue && categoryValue.userGroup && categoryValue.userGroup.length && categoryValue.userGroup[0].u_g_id) : compfie && compfie.user_Group == null ? null : Number(compfie && compfie.user_Group),
                                    "vend_u_g_id": compfieVendorValue.user_Group === (EditCompfieVendor && EditCompfieVendor.userGroup.length && EditCompfieVendor.userGroup[0].u_g_name) ? Number(EditCompfieVendor && EditCompfieVendor.userGroup[0].u_g_id) : compfieVendorValue.user_Group == null ? null : Number(compfieVendorValue.user_Group),
                                    "email_id": userDetails.email,
                                    "emp_name": userDetails.emp_name,
                                    "emp_code": userDetails.emp_id,
                                    "cont_no": "--",
                                    "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                    "u_level": ((compfie.user_level != "null") || (compfie.user_level != null)) ? Number(compfie.user_level) : null,
                                    "vend_u_level": ((compfieVendorValue.user_level != null) && (tabValue.compfie_vendor == true)) ? Number(compfieVendorValue.user_level) : null,
                                    "is_sp": compfie.serviceProvider == null ? false : true,
                                    "sp_id": compfie.serviceProvider === (categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_name) ? Number(categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_id) : compfie.serviceProvider == null ? null : Number(compfie.serviceProvider),

                                    "l_user_entity_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadLitigationEntityEdit : ((tabValue.compfie_litigation == true) && (litigationEntityValue.length > 0)) ? litigationEntityValue : [],
                                    "vend_user_entity_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadVendorEntityEdit :
                                            ((tabValue.compfie_vendor == true) && (vendoreEntityValue && vendoreEntityValue.length > 0)) ? vendoreEntityValue : [],
                                    "l_user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditlitigationDomain :
                                            ((tabValue.compfie_litigation == true) && (compfieLitigation.Domain.length)) ? compfieLitigation.Domain : [],
                                    "vend_user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditVendorDomain :
                                            ((tabValue.compfie_vendor == true) && (compfieVendorValue.Domain.length > 0)) ? compfieVendorValue.Domain : [],
                                    "user_domain_ids":
                                        localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditDomain :
                                            ((tabValue.compfie == true) && (compfie.Domain.length)) ? compfie.Domain : [],
                                    "user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditunit : unitAccess == true ? userUnits : userUnits.concat(userClosedUnits),
                                    "vndr_user_unit_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEditClosedunit : vendorAccess == true ? vendorUnitDetails : vendorUnitDetails.concat(vendorClosedUnitDetails),
                                    "user_entity_ids": localStorage.getItem("SelectedEntity") !== 'All Legal Entity' ? payloadEntityEdit : ((tabValue.compfie == true) && (entityValue.length > 0)) ? entityValue : [],

                                    "c_user": compfie.category != null ? true : false,
                                    "v_user": compfieVendorValue.category != null ? true : false,
                                    "l_user": compfieLitigation.category != null ? true : false,
                                    "checked_value": "0",
                                    "otp_remarks": "OTP feature has been switched off",
                                    "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                    "ltmg_prdt_access": compfieLitigation.category != null == true ? 1 : 0,
                                    "vndr_prdt_access": compfieVendorValue.category != null ? 1 : 0,
                                }
                            ]

                        }
                    ]
                    console.log(payloadEdit, 'payloadEdit');
                    edituserManagementDetails({
                        payload: payloadEdit,
                        paramid: paramid
                    })
                }
                else {
                    Toaster.warning('Please Enter All the Fields')
                }
            }
            else {
                Toaster.warning('Please Enter All the Fields')
            }



        }



    }
    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_userDetails.length) {
            let CategoryData = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_c_id: Number(category.compfie) })
            setUserGroupCompfie(CategoryData)
            let CategoryDatas = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_c_id: Number(category.compfie_vendor) })
            setVendorgroupcompfie(CategoryDatas)
        }
    }, [category.compfie, category.compfie_vendor])


    useEffect(() => {
        validator.current.showMessages()
        Formvalidator.current.showMessages()
        LitigationFormvalidator.current.showMessages()
    }, [])
    console.log(litigationEntityData, 'litigationEntityData');
    useEffect(() => {

        if (AddType == 'add') {
            let temp1 = []
            let temp2 = []
            let temp3 = []
            if (userManagementDetails && userManagementDetails.um_legal_entity && userManagementDetails.um_legal_entity.length > 0) {
                if (entityid === null || entityid === "null" || entity_info && entity_info.length == 1) {
                    let uniqueObjArray = [...new Map(userManagementDetails.um_legal_entity && userManagementDetails.um_legal_entity.length > 0 && userManagementDetails.um_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    })

                    if (uniqueIds && uniqueIds.length > 0) {
                        for (let i in uniqueIds) {
                            let contract = _.find(UserLegalDetails, { le_id: uniqueIds[i].le_id })
                            if (contract.cont_to != undefined && contract.cont_to != null) {
                                if (moment(contract.cont_to).isSameOrAfter(date)) {
                                    temp1.push(uniqueIds[i])
                                }
                            }

                        }
                    }
                    setEntityData(temp1)
                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: entityid })
                    if (entityData && entityData.length > 0) {
                        for (let i in entityData) {
                            let contract = _.find(UserLegalDetails, { le_id: entityData[i].le_id })
                            if (contract.cont_to != undefined && contract.cont_to != null) {
                                if (moment(contract.cont_to).isSameOrAfter(date)) {
                                    temp1.push(entityData[i])
                                }
                            }
                        }
                    }

                    setEntityData(temp1)
                }
            }
            if (userManagementDetails && userManagementDetails.um_ltmg_legal_entity.length > 0) {
                if (entityid === null || entityid === "null" || entity_info && entity_info.length == 1) {
                    let uniqueObjArray = [...new Map(userManagementDetails.um_ltmg_legal_entity && userManagementDetails.um_ltmg_legal_entity.length > 0 && userManagementDetails.um_ltmg_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    })
                    if (uniqueIds && uniqueIds.length > 0) {
                        for (let i in uniqueIds) {
                            let contract = _.find(UserLegalDetails, { le_id: uniqueIds[i].le_id })
                            if (contract.l_cont_to != undefined && contract.l_cont_to != null) {
                                if (moment(contract.l_cont_to).isSameOrAfter(date)) {
                                    temp2.push(uniqueIds[i])
                                }
                            }
                        }
                    }
                    setlitigationEntityData(temp2)
                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_ltmg_legal_entity, { le_id: entityid })
                    if (entityData && entityData.length > 0) {
                        for (let i in entityData) {
                            let contract = _.find(UserLegalDetails, { le_id: entityData[i].le_id })
                            if (contract.l_cont_to != undefined && contract.l_cont_to != null) {
                                if (moment(contract.l_cont_to).isSameOrAfter(date)) {
                                    temp2.push(entityData[i])
                                }
                            }

                        }
                    }
                    setlitigationEntityData(temp2)
                }
            }
            let vendorEntity = []
            if (userManagementDetails && userManagementDetails.um_vndr_legal_entity.length > 0) {
                if (entityid === null || entityid === "null" || entity_info && entity_info.length == 1) {
                    let uniqueObjArray = [...new Map(userManagementDetails && userManagementDetails.um_vndr_legal_entity && userManagementDetails.um_vndr_legal_entity.length > 0 && userManagementDetails.um_vndr_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    });
                    console.log(userManagementDetails && userManagementDetails.um_vndr_business_group, 'userManagementDetails && userManagementDetails.um_vndr_business_group');
                    if (userManagementDetails && userManagementDetails.um_vndr_business_group && userManagementDetails.um_vndr_business_group.length > 0) {
                        if (uniqueIds && uniqueIds.length > 0) {
                            for (let i in uniqueIds) {
                                for (let j in userManagementDetails.um_vndr_business_group) {
                                    let contract = _.find(UserLegalDetails, { le_id: uniqueIds[i].le_id })
                                    console.log(uniqueIds[i].bg_id == userManagementDetails.um_vndr_business_group[j].bg_id, 'uniqueIds[i].bg_id == userManagementDetails.um_vndr_business_group[j].bg_id');
                                    if (uniqueIds[i].bg_id == userManagementDetails.um_vndr_business_group[j].bg_id) {
                                        if ((uniqueIds[i].le_admin !== null) && (moment(contract.v_cont_to).isSameOrAfter(date))) {
                                            vendorEntity.push(uniqueIds[i])
                                        }
                                    }
                                }
                            }
                        }
                        setvendorEntityData(vendorEntity)
                    }
                    else {
                        if (uniqueIds && uniqueIds.length > 0) {
                            for (let i in uniqueIds) {
                                let contract = _.find(UserLegalDetails, { le_id: uniqueIds[i].le_id })
                                if (contract.v_cont_to != undefined && contract.v_cont_to != null) {
                                    if (moment(contract.v_cont_to).isSameOrAfter(date)) {
                                        temp3.push(uniqueIds[i])
                                    }
                                }
                            }
                        }
                        // setlitigationEntityData(temp3)
                        setvendorEntityData(temp3)
                    }
                    console.log(userManagementDetails.um_vndr_business_group, 'vendorbusinessGroupData');

                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { le_id: entityid })
                    if (entityData && entityData.length > 0) {
                        for (let i in entityData) {
                            let contract = _.find(UserLegalDetails, { le_id: entityData[i].le_id })
                            if (contract.v_cont_to != undefined && contract.v_cont_to != null) {
                                if (moment(contract.v_cont_to).isSameOrAfter(date)) {
                                    temp3.push(entityData[i])
                                }
                            }
                        }
                    }

                    setvendorEntityData(temp3)
                }
            }





        }


        if (AddType == "edit") {
            let temp1 = []
            let temp2 = []
            let temp3 = []
            if (userManagementDetails && userManagementDetails.um_legal_entity && userManagementDetails.um_legal_entity.length > 0) {
                if (entityid === null || entityid === "null" || entity_info && entity_info.length == 1) {
                    let uniqueObjArray = [...new Map(userManagementDetails.um_legal_entity && userManagementDetails.um_legal_entity.length > 0 && userManagementDetails.um_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    })

                    if (uniqueIds && uniqueIds.length > 0) {
                        for (let i in uniqueIds) {
                            let contract = _.find(UserLegalDetails, { le_id: uniqueIds[i].le_id })
                            if (contract.cont_to != undefined && contract.cont_to != null) {
                                if (moment(contract.cont_to).isSameOrAfter(date)) {
                                    temp1.push(uniqueIds[i])
                                }
                            }
                        }
                    }
                    setEntityData(temp1)
                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: entityid })
                    if (entityData && entityData.length > 0) {
                        for (let i in entityData) {
                            let contract = _.find(UserLegalDetails, { le_id: entityData[i].le_id })
                            if (contract.cont_to != undefined && contract.cont_to != null) {
                                if (moment(contract.cont_to).isSameOrAfter(date)) {
                                    temp1.push(entityData[i])
                                }
                            }
                        }
                    }

                    setEntityData(temp1)
                }
            }
            if (userManagementDetails && userManagementDetails.um_ltmg_legal_entity.length > 0) {
                if (entityid === null || entityid === "null" || entity_info && entity_info.length == 1) {
                    let uniqueObjArray = [...new Map(userManagementDetails.um_ltmg_legal_entity && userManagementDetails.um_ltmg_legal_entity.length > 0 && userManagementDetails.um_ltmg_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    })
                    if (uniqueIds && uniqueIds.length > 0) {
                        for (let i in uniqueIds) {
                            let contract = _.find(UserLegalDetails, { le_id: uniqueIds[i].le_id })
                            if (contract.l_cont_to != undefined && contract.l_cont_to != null) {
                                if (moment(contract.l_cont_to).isSameOrAfter(date)) {
                                    temp2.push(uniqueIds[i])
                                }
                            }
                        }
                    }
                    setlitigationEntityData(temp2)
                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_ltmg_legal_entity, { le_id: entityid })
                    if (entityData && entityData.length > 0) {
                        for (let i in entityData) {
                            let contract = _.find(UserLegalDetails, { le_id: entityData[i].le_id })
                            if (contract.l_cont_to != undefined && contract.l_cont_to != null) {
                                if (moment(contract.l_cont_to).isSameOrAfter(date)) {
                                    temp2.push(entityData[i])
                                }
                            }
                        }
                    }
                    setlitigationEntityData(temp2)
                }
            }
            let vendorEntity = []
            if (userManagementDetails && userManagementDetails.um_vndr_legal_entity.length > 0) {
                if (entityid === null || entityid === "null" || entity_info && entity_info.length == 1) {
                    let uniqueObjArray = [...new Map(userManagementDetails && userManagementDetails.um_vndr_legal_entity && userManagementDetails.um_vndr_legal_entity.length > 0 && userManagementDetails.um_vndr_legal_entity.map((item) => [item["le_id"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item
                    });
                    console.log(userManagementDetails && userManagementDetails.um_vndr_business_group, 'userManagementDetails && userManagementDetails.um_vndr_business_group');
                    if (userManagementDetails && userManagementDetails.um_vndr_business_group && userManagementDetails.um_vndr_business_group.length > 0) {
                        if (uniqueIds && uniqueIds.length > 0) {
                            for (let i in uniqueIds) {
                                for (let j in userManagementDetails.um_vndr_business_group) {
                                    let contract = _.find(UserLegalDetails, { le_id: uniqueIds[i].le_id })
                                    console.log(uniqueIds[i].bg_id == userManagementDetails.um_vndr_business_group[j].bg_id, 'uniqueIds[i].bg_id == userManagementDetails.um_vndr_business_group[j].bg_id');
                                    if (uniqueIds[i].bg_id == userManagementDetails.um_vndr_business_group[j].bg_id) {
                                        if ((uniqueIds[i].le_admin !== null) && (moment(contract.v_cont_to).isSameOrAfter(date))) {
                                            vendorEntity.push(uniqueIds[i])
                                        }
                                    }
                                }
                            }
                        }
                        setvendorEntityData(vendorEntity)
                    }
                    else {
                        if (uniqueIds && uniqueIds.length > 0) {
                            for (let i in uniqueIds) {
                                let contract = _.find(UserLegalDetails, { le_id: uniqueIds[i].le_id })
                                if (contract.v_cont_to != undefined && contract.v_cont_to != null) {
                                    if (moment(contract.v_cont_to).isSameOrAfter(date)) {
                                        temp3.push(uniqueIds[i])
                                    }
                                }
                            }
                        }
                        // setlitigationEntityData(temp3)
                        setvendorEntityData(temp3)
                    }
                    console.log(userManagementDetails.um_vndr_business_group, 'vendorbusinessGroupData');

                }
                else {
                    let entityData = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { le_id: entityid })
                    if (entityData && entityData.length > 0) {
                        for (let i in entityData) {
                            let contract = _.find(UserLegalDetails, { le_id: entityData[i].le_id })
                            if (contract.v_cont_to != undefined && contract.v_cont_to != null) {
                                if (moment(contract.v_cont_to).isSameOrAfter(date)) {
                                    temp3.push(entityData[i])
                                }
                            }
                        }
                    }

                    setvendorEntityData(temp3)
                }
            }
            if (userManagementDetails && userManagementDetails.um_legal_domain) {
                setDomainData(userManagementDetails && userManagementDetails.um_legal_domain)
            }
            if (userManagementDetails && userManagementDetails.ltmg_um_legal_domain) {
                setLitigationDomainData(userManagementDetails && userManagementDetails.ltmg_um_legal_domain)
            }
            if (userManagementDetails && userManagementDetails.vndr_um_legal_domain.length > 0) {
                setVendorDomainData(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
            }
        }
        else {
            if (localStorage.getItem("SelectedEntity") == 'All Legal Entity') {
                if (userManagementDetails && userManagementDetails.um_legal_domain) {
                    setDomainData(userManagementDetails && userManagementDetails.um_legal_domain)
                }
                if (userManagementDetails && userManagementDetails.ltmg_um_legal_domain) {
                    setLitigationDomainData(userManagementDetails && userManagementDetails.ltmg_um_legal_domain)
                }
                if (userManagementDetails && userManagementDetails.vndr_um_legal_domain.length > 0) {
                    setVendorDomainData(userManagementDetails && userManagementDetails.vndr_um_legal_domain)
                }
            }
            if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
                let data = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: entityid })
                setDomainData(data)
                let datas = _.filter(userManagementDetails && userManagementDetails.ltmg_um_legal_domain, { le_id: entityid })
                setLitigationDomainData(datas)
                let vendordata = _.filter(userManagementDetails && userManagementDetails.vndr_um_legal_domain, { le_id: entityid })
                setVendorDomainData(vendordata)
            }
        }
        if (localStorage.getItem("SelectedEntity") !== 'All Legal Entity') {
            if (userManagementDetails && userManagementDetails.um_legal_entity) {
                let entityData = _.find(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: entityid })
                // setEntityData(entityData)
                if (entityData) {
                    let entityDatas = _.filter(userManagementDetails && userManagementDetails.um_business_group, { bg_id: entityData.bg_id })
                    setBusinessGroupData(entityDatas)

                }

            }

        }

        else {

            setBusinessGroupData(userManagementDetails && userManagementDetails.um_business_group)

        }
        // if (userManagementDetails && userManagementDetails.um_group_category) {
        //     setBusinessGroupData(userManagementDetails && userManagementDetails.um_business_group)
        // }
        if (userManagementDetails && userManagementDetails.um_ltmg_business_group) {
            setlitigationbusinessGroupData(userManagementDetails && userManagementDetails.um_ltmg_business_group)
        }
        if (userManagementDetails && userManagementDetails.um_vndr_business_group) {
            setVendorBusinessGroupData(userManagementDetails && userManagementDetails.um_vndr_business_group)
        }
        if (userManagementDetails && userManagementDetails.um_group_category) {
            setCategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
            setvendorcategoryDatas(userManagementDetails && userManagementDetails.um_group_category)
        }
        if (userManagementDetails && userManagementDetails.um_cmpf_group_division) {
            setdivisionData(userManagementDetails && userManagementDetails.um_cmpf_group_division)
        }
        if (userManagementDetails && userManagementDetails.um_vndr_group_division) {
            setvendordivisionData(userManagementDetails && userManagementDetails.um_vndr_group_division)
        }
    }, [userManagementDetails, userManagementDetails && userManagementDetails.um_legal_entity, userManagementDetails && userManagementDetails.um_legal_domain, entityid])

    console.log(userManagementDetails, 'userManagementDetails');
    useEffect(() => {
        if (userManagementDetails && userManagementDetails.um_legal_units) {
            let entityData = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { is_closed: true })
            setClosedtUnitData(entityData)
            setCloseUnitDAta(entityData)
            let unitData = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { is_closed: false })
            setSelectUnitData(unitData)
            setunitDatas(unitData)
        }
    }, [userManagementDetails && userManagementDetails.um_legal_units])
    console.log(domainData, 'domainData');
    useEffect(() => {
        if (userManagementDetails && userManagementDetails.um_vndr_legal_units) {
            let vendorData = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_units, { is_closed: true })
            setVendorClosedUnit(vendorData)
            setvendorCloseUnitDAta(vendorData)
            let unitData = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_units, { is_closed: false })
            setVendorUnit(unitData)
            setvendorunitDatas(unitData)
        }
    }, [userManagementDetails && userManagementDetails.um_vndr_legal_units])

    useEffect(() => {
        let temp = []
        if (LitigationLegalEntityList.length > 0) {
            console.log(LitigationLegalEntityList, 'LitigationLegalEntityList');
            for (let i in LitigationLegalEntityList) {
                if (LitigationLegalEntityList[i].options.length > 0) {
                    for (let j in LitigationLegalEntityList[i].options) {
                        temp.push(LitigationLegalEntityList[i].options[j])
                    }
                }
            }
            console.log(temp, 'temp123459455');
            setlitigationEntityData(temp)
        }


    }, [LitigationLegalEntityList])

    useEffect(() => {
        if (EntityData.length) {
            let temparr = []
            for (let i in EntityData) {
                temparr.push({

                    label: EntityData[i].le_name,
                    value: EntityData[i].le_id
                })

                setEntityOptions(temparr)
                setLitigationEntityOptions(temparr)
                setVendorEntityOption(temparr)
            }
        }

        if (userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain) {
            let temparr = []
            for (let i in userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain) {
                temparr.push({

                    label: userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain[i].u_dm_name,
                    value: userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain[i].u_dm_id
                })

                // setDomainOptions(temparr)
                setLitigationDomainOptions(temparr)
                setVendorDomainOptions(temparr)
            }
        }
        if (userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division) {
            let temparr = []
            for (let i in userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division) {
                temparr.push({

                    label: userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division[i].d_name,
                    value: userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division[i].d_id
                })

                setDivisionOption(temparr)
            }
        }
        if (userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category) {
            let temparr = []
            for (let i in userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category) {
                temparr.push({

                    label: userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category[i].cat_name,
                    value: userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category[i].cat_id
                })

                setCategoryOptions(temparr)
            }
        }

    }, [EntityData && EntityData, userManagementDetails])

    useEffect(() => {
        if (unitAccess == false) {
            if (all && all.length > 0) {
                let tempIds = []
                let temparr = []
                console.log(all, 'all');

                for (let i in all) {
                    if (entityid === null || entityid === "null") {
                        console.log(all[i], 'all[i]');
                        if (unitEntity && unitEntity.length > 0) {
                            console.log(unitEntity, 'unitEntity9999999');
                            temparr.push(

                                {
                                    le_id: unitEntity && unitEntity.length > 0 && unitEntity[i].le_id,
                                    u_id: all[i]
                                }
                            )
                        }

                    }
                    else {
                        temparr.push(

                            {
                                le_id: entityid,
                                u_id: all[i]
                            }
                        )
                    }


                    setUserUnits(temparr)
                }
            }
            else {
                setUserUnits([])
            }

            if (closedAll && closedAll.length > 0) {
                let tempIds = []
                let temparr = []
                console.log(all, 'all');

                for (let i in closedAll) {
                    if (entityid === null || entityid === "null") {
                        console.log(all[i], 'all[i]');
                        if (closedEntityUnit && closedEntityUnit.length > 0) {
                            temparr.push(

                                {
                                    le_id: closedEntityUnit && closedEntityUnit.length > 0 && closedEntityUnit[i].le_id,
                                    u_id: closedAll[i]
                                }
                            )
                        }

                    }
                    else {
                        temparr.push(

                            {
                                le_id: entityid,
                                u_id: closedAll[i]
                            }
                        )
                    }


                    setClosedUserUnits(temparr)
                }
            }
            else {
                setClosedUserUnits([])
            }
            if (vendorAll && vendorAll.length > 0) {
                let tempIds = []
                let temparr = []
                console.log(all, 'all');

                for (let i in vendorAll) {
                    if (entityid === null || entityid === "null") {
                        console.log(all[i], 'all[i]');
                        if (vendorunitEntity && vendorunitEntity.length > 0) {
                            temparr.push(

                                {
                                    le_id: vendorunitEntity && vendorunitEntity.length > 0 && vendorunitEntity[i].le_id,
                                    u_id: vendorAll[i]
                                }
                            )
                        }

                    }
                    else {
                        temparr.push(

                            {
                                le_id: entityid,
                                u_id: vendorAll[i]
                            }
                        )
                    }


                    setVendorUnitDetails(temparr)
                }
            }
            else {
                setVendorUnitDetails
                    ([])
            }
            if (vendorClosedAll && vendorClosedAll.length > 0) {
                let tempIds = []
                let temparr = []
                console.log(all, 'all');

                for (let i in vendorClosedAll) {
                    if (entityid === null || entityid === "null") {

                        if (vendorClosedunitEntity && vendorClosedunitEntity.length > 0) {
                            temparr.push(

                                {
                                    le_id: vendorClosedunitEntity && vendorClosedunitEntity.length > 0 && vendorClosedunitEntity[i].le_id,
                                    u_id: vendorClosedAll[i]
                                }
                            )
                        }

                    }
                    else {
                        temparr.push(

                            {
                                le_id: entityid,
                                u_id: vendorClosedAll[i]
                            }
                        )
                    }


                    setVendorClosedUnitDetails(temparr)
                }
            }
            else {
                setVendorClosedUnitDetails
                    ([])
            }


        }
    }, [vendorAll, all, closedAll, vendorClosedAll, unitEntity, vendorunitEntity, closedEntityUnit, vendorClosedunitEntity])

    useEffect(() => {
        if (compfie.category != null) {
            if (entityValue && entityValue.length > 0) {
                console.log(entityValue, 'entityValue');
                let tempIds = []
                for (let i in entityValue) {
                    tempIds.push(entityValue[i])

                }
                if (tempIds.length) {
                    console.log(tempIds, 'tempIds123455');
                    setCompfie({
                        ...compfie,
                        legal_Entity: tempIds
                    })
                }
                else {
                    setCompfie({
                        ...compfie,
                        legal_Entity: []
                    })
                }

            }
        }


        if (litigationEntityValue && litigationEntityValue.length > 0) {
            let tempIds = []
            for (let i in litigationEntityValue) {
                tempIds.push(litigationEntityValue[i])

            }
            if (tempIds.length) {
                setCompfieLitigation({
                    ...compfieLitigation,
                    legal_Entity: tempIds
                })
            }

        }
        else {
            setCompfieLitigation({
                ...compfieLitigation,
                legal_Entity: []
            })
        }
        // if (litigationlegalEntity && litigationlegalEntity.length > 0) {
        //     let tempIds = []
        //     for (let i in litigationlegalEntity) {
        //         tempIds.push(litigationlegalEntity[i].value)

        //     }
        //     if (tempIds.length) {
        //         setCompfieLitigation({
        //             ...compfieLitigation,
        //             legal_Entity: tempIds
        //         })
        //     }




        // }
        // else {
        //     setCompfieLitigation({
        //         ...compfieLitigation,
        //         legal_Entity: []
        //     })
        // }


        // if (VendorEntity && VendorEntity.length > 0) {
        //     let tempIds = []
        //     for (let i in VendorEntity) {
        //         tempIds.push(VendorEntity[i].value)

        //     }
        //     if (tempIds.length) {
        //         setCompfieVendorValue({
        //             ...compfieVendorValue,
        //             legal_Entity: tempIds
        //         })
        //     }



        // }
        // else {
        //     setCompfieVendorValue({
        //         ...compfieVendorValue,
        //         legal_Entity: []
        //     })
        // }
        if (vendoreEntityValue && vendoreEntityValue.length > 0) {
            let tempIds = []
            for (let i in vendoreEntityValue) {
                tempIds.push(vendoreEntityValue[i])

            }
            if (tempIds.length) {
                setCompfieVendorValue({
                    ...compfieVendorValue,
                    legal_Entity: tempIds
                })
            }

        }
        else {
            setCompfieVendorValue({
                ...compfieVendorValue,
                legal_Entity: []
            })
        }

    }, [legalEntity, litigationlegalEntity, VendorEntity, entityValue, litigationEntityValue, vendoreEntityValue])

    useEffect(() => {
        if (compfie.legal_Entity.length > 0) {
            if (domainPayload && domainPayload.length > 0) {
                let tempIds = []
                let temparr = []
                for (let i in domainPayload) {
                    tempIds.push(domainPayload[i])

                }
                if (tempIds.length) {
                    console.log(tempIds, 'entityId');
                    console.log(domainEntity, 'legalEntity12345555');
                    for (let i = 0; i < tempIds.length; i++) {
                        if (entityid === null || entityid === "null") {
                            if (domainEntity && domainEntity.length > 0) {
                                if (domainEntity.length == tempIds.length) {
                                    temparr.push(

                                        {
                                            le_id: domainEntity && domainEntity.length > 0 && domainEntity[i].le_id,
                                            d_id: Number(tempIds[i])
                                        }
                                    )
                                }

                            }
                        }
                        else {
                            temparr.push(

                                {
                                    le_id: entityid,
                                    d_id: Number(tempIds[i])
                                }
                            )
                        }

                    }
                    console.log(temparr, 'temparr');
                    setCompfie({
                        ...compfie,
                        Domain: temparr
                    })
                }


            }
            else {
                setCompfie({
                    ...compfie,
                    Domain: []
                })
            }


        }

        if (compfieLitigation.legal_Entity.length > 0) {
            console.log('Aishwarya');
            if (litigationdomainPayload && litigationdomainPayload.length > 0) {
                let tempIds = []
                let temparr = []
                for (let i in litigationdomainPayload) {
                    tempIds.push(litigationdomainPayload[i])

                }
                console.log(litigationlegalEntity, "litigationlegalEntity");
                if (tempIds.length) {
                    for (let i in tempIds) {
                        if (entityid === null || entityid === "null") {
                            // for (let k in litigationlegalEntity) {
                            if (litigationdomainEntity && litigationdomainEntity.length > 0) {
                                if (litigationdomainEntity[i]) {
                                    if (litigationdomainEntity.length == tempIds.length) {
                                        temparr.push(
                                            {
                                                le_id: litigationdomainEntity && litigationdomainEntity[i].le_id,
                                                d_id: Number(tempIds[i])
                                            }
                                        )
                                    }
                                }
                            }

                            // }
                        }
                        else {
                            temparr.push(

                                {
                                    le_id: entityid,
                                    d_id: Number(tempIds[i])
                                }
                            )

                        }

                    }
                    console.log(temparr, 'temparrtemparrtemparrtemparrtemparr');
                    setCompfieLitigation({
                        ...compfieLitigation,
                        Domain: temparr
                    })
                }


            }
            else {
                setCompfieLitigation({
                    ...compfieLitigation,
                    Domain: []
                })
            }
        }


        if (compfieVendorValue.legal_Entity.length > 0) {
            if (vendordomainPayload && vendordomainPayload.length > 0) {
                let tempIds = []
                let temparr = []
                for (let i in vendordomainPayload) {
                    tempIds.push(vendordomainPayload[i])

                }
                console.log(tempIds, "tempIdstempIds");
                if (tempIds.length > 0) {
                    for (let i = 0; i < tempIds.length; i++) {
                        if (entityid === null || entityid === "null") {
                            if (vendorlegalEntity && vendorlegalEntity.length > 0) {
                                console.log(vendorlegalEntity, 'vendorlegalEntity');
                                if (vendorlegalEntity.length == tempIds.length) {
                                    // console.log( compfieVendorValue && compfieVendorValue[k], "8888999");
                                    temparr.push(
                                        {
                                            le_id: vendorlegalEntity && vendorlegalEntity[i].le_id,
                                            d_id: Number(tempIds[i])
                                        }
                                    )
                                }
                            }
                        }
                        else {
                            temparr.push(
                                {
                                    le_id: entityid,
                                    d_id: Number(tempIds[i])
                                }
                            )
                        }
                    }
                    setCompfieVendorValue({
                        ...compfieVendorValue,
                        Domain: temparr
                    })
                }


            }

            else {
                setCompfieVendorValue({
                    ...compfieVendorValue,
                    Domain: []
                })
            }

        }

    }, [entityid, domainPayload, litigationdomainPayload, vendordomainPayload, VendorDomainValue, compfie.legal_Entity, compfieLitigation.legal_Entity, compfieVendorValue.legal_Entity, litigationlegalEntity, legalEntity, VendorEntity])


    useEffect(() => {
        if (compfie.category != null) {
            if (GetUserDetails && GetUserDetails.ul_user_domains.length > 0) {
                let temparr = []
                for (let i in GetUserDetails && GetUserDetails.ul_user_domains) {
                    temparr.push(
                        {
                            le_id: GetUserDetails && GetUserDetails.ul_user_domains && GetUserDetails.ul_user_domains[i].le_id,
                            d_id: GetUserDetails && GetUserDetails.ul_user_domains && GetUserDetails.ul_user_domains[i].u_dm_id
                        }
                    )
                    setCompfie({
                        ...compfie,
                        Domain: temparr
                    })
                }
            }
        }
    }, [GetUserDetails && GetUserDetails.ul_user_domains, compfie.category])
    useEffect(() => {
        if (compfieVendorValue.category != null) {
            if (GetUserDetails && GetUserDetails.ul_vend_user_domains.length > 0) {
                let temparr = []
                for (let i in GetUserDetails && GetUserDetails.ul_vend_user_domains) {
                    temparr.push(
                        {
                            le_id: GetUserDetails && GetUserDetails.ul_vend_user_domains && GetUserDetails.ul_vend_user_domains[i].le_id,
                            d_id: GetUserDetails && GetUserDetails.ul_vend_user_domains && GetUserDetails.ul_vend_user_domains[i].u_dm_id
                        }
                    )
                    setCompfieVendorValue({
                        ...compfieVendorValue,
                        Domain: temparr
                    })
                }
            }
        }
    }, [GetUserDetails && GetUserDetails.ul_vend_user_domains, compfieVendorValue.category])
    useEffect(() => {
        if (compfieLitigation.category != null) {
            if (GetUserDetails && GetUserDetails.ul_ltg_user_domains.length > 0) {
                let temparr = []
                for (let i in GetUserDetails && GetUserDetails.ul_ltg_user_domains) {
                    temparr.push(
                        {
                            le_id: GetUserDetails && GetUserDetails.ul_ltg_user_domains && GetUserDetails.ul_ltg_user_domains[i].le_id,
                            d_id: GetUserDetails && GetUserDetails.ul_ltg_user_domains && GetUserDetails.ul_ltg_user_domains[i].u_dm_id
                        }
                    )
                    setCompfieLitigation({
                        ...compfieLitigation,
                        Domain: temparr
                    })
                }
            }
        }
    }, [GetUserDetails && GetUserDetails.ul_ltg_user_domains, compfieLitigation.category])
    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const handlePasting = (e, regex) => {
        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
    }
    return (
        <>
            {showList1 === false ?
                <div id='page-wrapper' className="page-wrapper">
                    <div className="page-titles pb-2 pt-1">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Master</span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span>User Management</span>

                                            {/* <Link>User Management</Link> */}
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="
        col-lg-4 col-md-6
        d-none d-md-flex
        align-items-center
        justify-content-end
      "
                            >
                                <div>
                                    <Button shape="round" className='addbutton' style={{ background: "#0096FF", float: 'left' }}
                                        icon={<ArrowLeftOutlined />} size='default' onClick={(e) => { previous() }}>
                                        Back
                                    </Button></div>
                                <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius"  >
                                    <Tooltip placement="left" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid pt-0">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card servcardround">
                                    <div className="card-body" style={{ padding: "5px 15px" }}>
                                        <div className="col-md-12" id={localStorage.getItem('currentTheme') + 'ed'}>
                                            <Tabs activeKey={active} onChange={(key) => {
                                                setActive(key)
                                            }} type="card" >
                                                <TabPane tab="User Details" key="1" >
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">

                                                                    <label htmlFor=""><b>Employee Name</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <div className="position-relative">
                                                                        <Input type="text" autocomplete="off" className='form-control' name="emp_name" placeholder='Enter Employee Name' onChange={EmployeeOnChange} id="" value={userDetails.emp_name || undefined}
                                                                            onKeyPress={(event) => {
                                                                                if (!/^[A-Za-z  ]+$/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                            onPaste={(e) => {
                                                                                handlePasting(e, /^[A-Za-z  ]+$/gi)
                                                                            }}
                                                                            maxlength='50'
                                                                            style={{ marginTop: '5px', borderColor: errorField1 === true && userDetails.emp_name !== '' ? '#87d068' : errorField1 === false && userDetails.emp_name !== '' ? '#fc4b6c' : '' }} />
                                                                        {userDetails.emp_name !== '' && errorField1 === true ?
                                                                            <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i> :
                                                                            userDetails.emp_name !== '' && errorField1 === false ?
                                                                                <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i> : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {validator.current.message(
                                                                    'emp_name',
                                                                    userDetails.emp_name,
                                                                    ['required', { regex: /^[A-Za-z ]+$/ }],
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Employee Name Required',
                                                                            regex: 'Enter valid name'
                                                                        }
                                                                    })}
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Employee ID</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <div className="position-relative">
                                                                        <Input
                                                                            // disabled={AddType == 'edit' ? true : false}
                                                                            onKeyPress={(event) => {
                                                                                if (!  /^[a-zA-Z0-9]*$/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                            onPaste={(e) => {

                                                                                handlePasting(e, /^[a-zA-Z0-9]*$/gi)
                                                                            }} type="text" maxlength='20' autocomplete="off" placeholder='Enter Employee ID' className='form-control' onChange={EmpIdOnChange} name="emp_id" id="" value={userDetails.emp_id} style={{ marginTop: '5px', borderColor: errorField2 === true && userDetails.emp_id != '' ? '#87d068' : errorField2 === false && userDetails.emp_id != '' ? '#fc4b6c' : '' }} />
                                                                        {userDetails.emp_id !== '' && errorField2 === true ?
                                                                            <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i> :
                                                                            userDetails.emp_id !== '' && errorField2 === false ?
                                                                                <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i> : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {validator.current.message(
                                                                    'emp_id',
                                                                    userDetails.emp_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Employee ID Required',
                                                                        }
                                                                    })}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Email ID</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <div className="position-relative">
                                                                        <Input type="text" maxLength={100} onPaste={(e) => {
                                                                            handlePasting(e, /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/gi)
                                                                        }} autocomplete="off" onChange={EmailOnChange}
                                                                            style={{ marginTop: '5px', borderColor: errorField === true && userDetails.email !== '' ? '#87d068' : errorField === false && userDetails.email !== '' ? '#fc4b6c' : '' }}
                                                                            placeholder='Enter Email ID' className='form-control' name="email" id="" value={userDetails.email} />
                                                                        {errorField === true && userDetails.email !== '' ? <i style={{ top: '7px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i> : errorField === false && userDetails.email !== '' ? <i style={{ top: '7px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i> : ''}
                                                                    </div>
                                                                </div>
                                                                {validator.current.message(
                                                                    'email',
                                                                    userDetails.email,
                                                                    ['required', { regex: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/ }],
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Email Id Required',
                                                                            regex: 'Enter valid email'
                                                                        }
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-2">
                                                        <div className="row">


                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Mobile No</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    {/* <Input.Group size="default" style={{ marginTop: "5px" }} > */}
                                                                    <Row gutter={10} style={{ marginTop: "5px" }}>
                                                                        <Col span={5}>
                                                                            <Input type="text" className='form-control' autocomplete="off" placeholder='+91'
                                                                                // onKeyDown={blockInvalidChar}
                                                                                value={userDetails.mobile_no_country_code} name='mobile_no_country_code'
                                                                                onChange={countryOnChange}
                                                                                onPaste={(e) => {
                                                                                    handlePasting(e, /[0-9 +]/gi)
                                                                                }}
                                                                                onKeyPress={(event) => {
                                                                                    if (!/[0-9 +]/.test(event.key)) {
                                                                                        event.preventDefault();
                                                                                    }
                                                                                }}
                                                                                maxlength='4'
                                                                                style={{ borderColor: errorField3 === true && userDetails.mobile_no_country_code !== '' ? '#87d068' : errorField3 === false && userDetails.mobile_no_country_code !== '' ? '#fc4b6c' : '' }}
                                                                            />
                                                                            [+91]

                                                                            {validator.current.message(
                                                                                'mobile_no_country_code',
                                                                                userDetails.mobile_no_country_code,
                                                                                ['required', { regex: /^\+(\d{1}\-)?(\d{1,4})$/ }],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Enter Country Code for Mobile No.',
                                                                                        regex: 'Please enter valid country code'
                                                                                    }
                                                                                })}
                                                                        </Col>

                                                                        <Col span={19}>
                                                                            <div className="position-relative">
                                                                                <Input type="text" max={10} className='form-control' autocomplete="off"
                                                                                    // onKeyDown={blockInvalidChar} 
                                                                                    placeholder='9876543210' value={userDetails.mobile_no} name='mobile_no' onChange={mobileOnChange}
                                                                                    onPaste={(e) => {
                                                                                        handlePasting(e, /^[0-9\b]+$/gi)
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    maxlength='10'
                                                                                    style={{ borderColor: errorField4 === true && userDetails.mobile_no !== '' ? '#87d068' : errorField4 === false && userDetails.mobile_no !== '' ? '#fc4b6c' : '' }}
                                                                                />
                                                                                [9876543210]
                                                                                {userDetails.mobile_no !== '' && errorField4 === true ?
                                                                                    <i style={{ top: '5px', right: '7px' }} className="ri-check-line position-absolute text-success fs-5 fw-bold"></i> :
                                                                                    userDetails.mobile_no !== '' && errorField4 === false ?
                                                                                        <i style={{ top: '5px', right: '7px' }} className="ri-close-line position-absolute text-danger fs-5 fw-bold"></i> : ''
                                                                                }
                                                                            </div>
                                                                            {validator.current.message(
                                                                                'mobile_no',
                                                                                userDetails.mobile_no,
                                                                                ['required', { regex: /^[0-9\b]+$/ }, `min:10`, `max:10`],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Mobile Number Required',
                                                                                        min: 'Mobile Number should be 10 Numbers',
                                                                                        max: 'Mobile Number should be 10 Numbers'
                                                                                    }
                                                                                })}
                                                                        </Col>
                                                                    </Row>
                                                                    {/* </Input.Group> */}

                                                                </div>
                                                            </div>

                                                            <div className='col-md-4'>
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Contact No</b></label>
                                                                    <Input.Group size="default" style={{ marginTop: "5px" }} >
                                                                        <Row gutter={8}>
                                                                            <Col span={5}>
                                                                                <Input type="text" className='form-control' autocomplete="off" placeholder='+00' name='emp_contact_country_code' value={userDetails.emp_contact_country_code} onChange={onUserDetailOnChange}
                                                                                    onPaste={(e) => {
                                                                                        handlePasting(e, /[0-9 +]/gi)
                                                                                    }}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9 +]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    maxlength='4'
                                                                                />
                                                                                &nbsp;[+91]
                                                                                {validator.current.message(
                                                                                    'emp_contact_country_code',
                                                                                    userDetails.emp_contact_country_code,
                                                                                    ((userDetails.emp_contact_code == '') && (userDetails.emp_contact_no == '')) ? [{ regex: /^\+(\d{1}\-)?(\d{1,4})$/ }] : ['required', { regex: /^\+(\d{1}\-)?(\d{1,4})$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            regex: 'Enter valid country code',
                                                                                            required: 'Please Enter Country Code for Contact No'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                            <Col span={5}>
                                                                                <Input type="text" className='form-control' autocomplete="off" placeholder='000' name='emp_contact_code' value={userDetails.emp_contact_code} onChange={onUserDetailOnChange}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    onPaste={(e) => {
                                                                                        handlePasting(e, /[0-9]/gi)
                                                                                    }}
                                                                                    maxlength='3'
                                                                                />
                                                                                [452]
                                                                                {validator.current.message(
                                                                                    'emp_contact_code',
                                                                                    userDetails.emp_contact_code,
                                                                                    ((userDetails.emp_contact_country_code == '') && (userDetails.emp_contact_no == '')) ? [{ regex: /^(\+\d{1,3}[- ]?)?\d{3}$/ }] : ['required', { regex: /^(\+\d{1,3}[- ]?)?\d{3}$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            regex: 'Enter valid input',
                                                                                            required: 'Please Enter Area Code for Contact No'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                            <Col span={14}>
                                                                                <Input type="text" autocomplete="off" className='form-control' placeholder='1234567890' name='emp_contact_no' value={userDetails.emp_contact_no} onChange={onUserDetailOnChange}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                    onPaste={(e) => {
                                                                                        handlePasting(e, /[0-9 +]/gi)
                                                                                    }}
                                                                                    maxlength='10'
                                                                                />
                                                                                [1234567890]
                                                                                {validator.current.message(
                                                                                    'emp_contact_no',
                                                                                    userDetails.emp_contact_no,
                                                                                    ((userDetails.emp_contact_country_code == '') && (userDetails.emp_contact_code == '')) ? [{ regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }] : ['required', { regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            regex: 'Enter valid mobile number',
                                                                                            required: 'Please Enter Contact Number'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>

                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-4">


                                                                <div className="col-md-3">

                                                                    <label htmlFor=""><b>Remarks</b></label> <span style={{ "color": "red" }}>*</span>


                                                                    <Input type="text" autocomplete="off" className='form-control' onChange={onUserDetailOnChange}
                                                                        // disabled={true}
                                                                        // value='OTP feature has been switched off'
                                                                        name="remarks" id="" style={{ marginTop: '5px', width: '100%' }} />
                                                                    <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                                                    >
                                                                        <PlusCircleOutlined />
                                                                    </span>


                                                                </div>
                                                                <div className="col-md-1">
                                                                    {/* <div className="form-group"> */}
                                                            {/* <label htmlFor=""><b>OTP</b> </label> <span style={{ "color": "red" }}>*</span> <br />

                                                                    <input type="radio" /> */}
                                                            {/* </div> */}
                                                            {/* {validator.current.message(
                                                                            'otp',
                                                                            userDetails.otp,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'OTP Required',
                                                                                }
                                                                            })} */}
                                                            {/* </div>

                                                            </div> */}
                                                            {userDetails.otp == 1 ?
                                                                <>
                                                                    <div className="col-md-1">
                                                                        <label htmlFor=""><b>OTP</b> </label> <span style={{ "color": "red" }}>*</span> <br />
                                                                        <Radio.Group onChange={onChange} value={userDetails.otp} name='otp' style={{ marginTop: "6px" }} >
                                                                            <Radio value={1}>On</Radio>
                                                                            {/* <Radio value={0} >Off</Radio> */}
                                                                        </Radio.Group>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label htmlFor=""><b>Remarks</b></label> <span style={{ "color": "red" }}>*</span>
                                                                        <Input type="text" autocomplete="off" placeholder='Enter Remarks' className='form-control' onChange={onUserDetailOnChange}
                                                                            // disabled={true}
                                                                            value={userDetails.remarks}
                                                                            name="remarks" id="" style={{ marginTop: '5px', width: '100%' }} />
                                                                        <span className="ms-1 text-info d-flex 
align-items-center justify-content-center" style={{ float: 'right' }} onClick={() => { showRemarksModal() }}
                                                                        >
                                                                            <PlusCircleOutlined />
                                                                        </span>
                                                                    </div>
                                                                </>
                                                                : false}


                                                            {/* <div className="col-md-4 mt-2">
                                                                <div className="form-group">

                                                                    <label htmlFor=""><b>Remarks</b></label> <span style={{ "color": "red" }}>*</span>
                                                                    <div className="d-flex align-items-end">

                                                                        <Input type="text" autocomplete="off" className='form-control' onChange={onUserDetailOnChange}
                                                                            disabled={true}
                                                                            value='OTP feature has been switched off'
                                                                            name="remarks" id="" style={{ marginTop: '5px' }} />
                                                                        <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'right' }}
                                                                        >
                                                                            <PlusCircleOutlined />
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </div> */}

                                                        </div>
                                                    </div>


                                                    <div className={"col-md-12"} style={{ marginTop: "15px" }} >
                                                        <Tabs type="card"
                                                            className={`${localStorage.getItem("currentTheme") + 's5'}`}
                                                            onChange={(key) => {
                                                                setTab(key)
                                                            }}
                                                            //  onChange={() => {
                                                            //     setCategory({
                                                            //         compfie: '',
                                                            //         compie_litigation: '',
                                                            //         compfie_vendor: ''
                                                            //     })
                                                            // }}
                                                            activeKey={tab}

                                                        >
                                                            {EntityDetails && EntityDetails.cmpf_user_le && EntityDetails.cmpf_user_le.length > 0 ?
                                                                <TabPane

                                                                    // disabled={tabValue.compfie == false ? true : false}
                                                                    // style={{ backgroundColor: 'green' }}
                                                                    tab={
                                                                        <span style={{ padding: "0px 30px", fontSize: "16px" }}>
                                                                            {
                                                                                tabValue.compfie == false ?


                                                                                    <FeatherIcon icon="x-circle" className={'pointer'} size='19' style={{ color: 'red', marginBottom: '-1%' }}
                                                                                        onClick={() => {
                                                                                            setTab("1")
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie: !tabValue.compfie
                                                                                            })
                                                                                            setCategoryFormSubmit(false)

                                                                                        }}
                                                                                    />



                                                                                    // <CloseOutlined 
                                                                                    // onClick={() => {
                                                                                    //     setTab("1")
                                                                                    //     setTabValue({
                                                                                    //         ...tabValue,
                                                                                    //         compfie: !tabValue.compfie
                                                                                    //     })


                                                                                    // }}
                                                                                    //     style={{ color: 'red' }} /> 
                                                                                    : <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'green', marginBottom: '-1%' }} size='19' onClick={() => {
                                                                                        setCategoryFormSubmit(false)
                                                                                        if ((AddType == 'add') || (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ct == 0)) {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie: !tabValue.compfie
                                                                                            })
                                                                                        }
                                                                                        else if ((AddType == 'edit' && login.usr_cat_id == 1) && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ct == 1)) {
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_ids": details.le_id,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "1"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            console.log(Editpayload, 'Editpayload65659898*');
                                                                                            EdithaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,
                                                                                                setTabValue: setTabValue,
                                                                                                tabValue: tabValue,
                                                                                                type: 'compfie',

                                                                                            })



                                                                                        }


                                                                                        console.log(AddType, 'AddTyp');
                                                                                        if (AddType == 'add') {

                                                                                            setEntityValue([])
                                                                                            setbusinessGroupValue([])
                                                                                            setdomainValue([])
                                                                                            setCategoryValues([])
                                                                                            setDivisionValue([])
                                                                                            setCompfie({
                                                                                                ...compfie,
                                                                                                category: null,
                                                                                                seating_Unit: null,
                                                                                                user_Group: '',
                                                                                                user_level: '1',
                                                                                                entity: '',
                                                                                                serviceProvider: '',
                                                                                                checked_value: '',
                                                                                                legal_Entity: [],
                                                                                                Domain: [],
                                                                                                domain_name: "",
                                                                                                Division: '',
                                                                                                businessGroup: ''
                                                                                            })
                                                                                        }


                                                                                    }}
                                                                                    />
                                                                            }
                                                                            <span style={{ marginBottom: '2%' }}><b> Compfie</b></span>
                                                                        </span>}

                                                                    key="1">
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor=""><b>Category</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                    <Select
                                                                                        style={{ width: '100%', marginTop: '5px' }}
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        placeholder="Enter Category"
                                                                                        onChange={categoryOnChange}
                                                                                        // onChange={(data) => {
                                                                                        //     setUnit({
                                                                                        //         ...unit,
                                                                                        //         unit: data
                                                                                        //     })
                                                                                        // }}
                                                                                        value={tabValue.compfie == true ? compfie.category : undefined}
                                                                                        disabled={tabValue.compfie == false || ((AddType == 'edit') && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ct == 1)) ? true : false}
                                                                                    >
                                                                                        {userManagementDetails && userManagementDetails.um_user_category && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category.map((item, i) => {

                                                                                            return (
                                                                                                <Option key={item.u_c_id}>
                                                                                                    {item.u_c_name}
                                                                                                </Option>


                                                                                            )


                                                                                        })}


                                                                                    </Select>
                                                                                    {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                        'mobile_no',
                                                                                        compfie.category,
                                                                                        ['required'],
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Category Required',
                                                                                            }
                                                                                        })) : ''}


                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor=""><b>User Group</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                <Select

                                                                                    showSearch
                                                                                    // optionFilterProp="children"
                                                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Enter User Group"

                                                                                    onChange={(data) => {
                                                                                        if (data != undefined) {
                                                                                            setValue({
                                                                                                ...value,
                                                                                                userGroup: data
                                                                                            })
                                                                                            setCompfie({
                                                                                                ...compfie,
                                                                                                user_Group: data
                                                                                            })
                                                                                            setActiveUnit(false)

                                                                                        }
                                                                                        else {
                                                                                            setValue({
                                                                                                ...value,
                                                                                                userGroup: ""
                                                                                            })
                                                                                            setCompfie({
                                                                                                ...compfie,
                                                                                                user_Group: ""
                                                                                            })
                                                                                            setActiveUnit(false)
                                                                                        }
                                                                                    }

                                                                                    }
                                                                                    value={tabValue.compfie == true ? compfie.user_Group : undefined}
                                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                                    disabled={tabValue.compfie == false ? true : false}
                                                                                >
                                                                                    {usergroupcompfie && usergroupcompfie.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.u_g_id}>
                                                                                                {item.u_g_name}
                                                                                            </Option>
                                                                                        );
                                                                                    })}

                                                                                </Select>
                                                                                {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                    'usergroup',
                                                                                    compfie.user_Group,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'User Group Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                                {/* <select name="cars" className='form-control' id="cars">
                                                                            <option value="volvo">None Selected</option>
                                                                          </select> */}
                                                                            </div>

                                                                            {category.compfie == '5' ?


                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor=""><b>User Level</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                        <Select className='form-control' placeholder='Enter User Level'
                                                                                            value={(compfie.user_level && tabValue.compfie == true) ? compfie.user_level : undefined}
                                                                                            allowClear={true}
                                                                                            style={{ marginTop: "5px", width: '100%' }}
                                                                                            onChange={(data) => {
                                                                                                if (data !== undefined) {
                                                                                                    setCompfie({
                                                                                                        ...compfie,
                                                                                                        user_level: data
                                                                                                    })
                                                                                                    setActiveUnit(false)
                                                                                                }
                                                                                                else {
                                                                                                    setCompfie({
                                                                                                        ...compfie,
                                                                                                        user_level: ""
                                                                                                    })
                                                                                                    setActiveUnit(false)
                                                                                                }
                                                                                            }}>
                                                                                            <Option value="1">Level 1 (Low)</Option>
                                                                                            <Option value="2">Level 2</Option>
                                                                                            <Option value="3">Level 3</Option>
                                                                                            <Option value="4">Level 4</Option>
                                                                                            <Option value="5">Level 5</Option>
                                                                                            <Option value="6">Level 6</Option>
                                                                                            <Option value="7">Level 7</Option>
                                                                                            <Option value="8">Level 8</Option>
                                                                                            <Option value="9">Level 9</Option>
                                                                                            <Option value="10">Level 10 (High)</Option>
                                                                                        </Select>
                                                                                        {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                            'user_level',
                                                                                            compfie.user_level,
                                                                                            ['required'],
                                                                                            {
                                                                                                className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'User Level Required',
                                                                                                    // regex: 'Please enter valid mobile number'
                                                                                                }
                                                                                            })) : ''}
                                                                                    </div>
                                                                                </div>

                                                                                : category.compfie == '6' || category.compfie_vendor == '6' ?
                                                                                    <div className="col-md-4">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>Service Provider</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                            <Select
                                                                                                // showSearch
                                                                                                // optionFilterProp="children"
                                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                                allowClear={true}
                                                                                                size="default"
                                                                                                placeholder="Enter Service Provider"
                                                                                                // onChange={categoryOnChange}
                                                                                                onChange={(data) => {
                                                                                                    if (data !== undefined) {
                                                                                                        setValue({
                                                                                                            ...value,
                                                                                                            serviceProvider: data
                                                                                                        })
                                                                                                        setCompfie({
                                                                                                            ...compfie,
                                                                                                            serviceProvider: data
                                                                                                        })
                                                                                                        setActiveUnit(false)
                                                                                                    }
                                                                                                    else {
                                                                                                        setValue({
                                                                                                            ...value,
                                                                                                            serviceProvider: ""
                                                                                                        })
                                                                                                        setCompfie({
                                                                                                            ...compfie,
                                                                                                            serviceProvider: ""
                                                                                                        })
                                                                                                        setActiveUnit(false)
                                                                                                    }
                                                                                                }}

                                                                                                value={tabValue.compfie == true ? compfie.serviceProvider : undefined}
                                                                                                style={{ width: '100%', marginTop: '5px' }}
                                                                                                disabled={tabValue.compfie == false ? true : false}
                                                                                            >
                                                                                                {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.u_sp_id}>
                                                                                                            {item.u_sp_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select>
                                                                                            {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                                'serviceProvider',
                                                                                                compfie.serviceProvider,
                                                                                                ['required'],
                                                                                                {
                                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                                    messages: {
                                                                                                        required: 'Service Provider Required',
                                                                                                        // regex: 'Please enter valid mobile number'
                                                                                                    }
                                                                                                })) : ''}
                                                                                        </div>
                                                                                    </div> : ''}

                                                                        </div>


                                                                    </div>
                                                                    {/* <Divider /> */}
                                                                    <h5 style={{ marginTop: "15px" }}><b>Settings</b></h5>
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-4" style={{ marginBottom: "5px" }}>
                                                                                <label htmlFor=""><b>Business Group</b></label><br />
                                                                                {businessGroupValue.length > 0 ?
                                                                                    <Tooltip title={bgTooltip}>
                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            name="units"
                                                                                            id="units"
                                                                                            disabled={compfie.category == null ? true : false}
                                                                                            className='form-control'
                                                                                            placeholder="Enter Business Group"
                                                                                            onDeselect={(e, value) => {
                                                                                                if (AddType == 'edit') {
                                                                                                    console.log(e, 'eeeeeeeeeee');
                                                                                                    let data = _.find(userManagementDetails && userManagementDetails.um_legal_entity, { bg_id: Number(e) })
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesLE",
                                                                                                                {
                                                                                                                    "le_ids": [data && data.le_id],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "1"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                }
                                                                                                setActiveUnit(false)
                                                                                            }}
                                                                                            onChange={(option, data) => businessGroupHandelChange(option, data)}

                                                                                            // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                            mode="multiple"
                                                                                            value={tabValue.compfie == true ? businessGroupValue : undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {businessGroupData && businessGroupData.length > 1 ?
                                                                                                <>
                                                                                                    <Option key="all" value="all" style={{ display: businessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: businessGroupUnselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}


                                                                                            {businessGroupData && businessGroupData.length > 0 &&
                                                                                                businessGroupData.map((item) => {
                                                                                                    let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                    return (

                                                                                                        <Option key={item.bg_id}>
                                                                                                            {item.bg_name}
                                                                                                        </Option>

                                                                                                    )

                                                                                                }

                                                                                                )}
                                                                                        </Select>
                                                                                    </Tooltip> :
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        disabled={compfie.category == null ? true : false}
                                                                                        placeholder="Enter Business Group"
                                                                                        onDeselect={(e, value) => {
                                                                                            if (AddType == 'edit') {
                                                                                                console.log(e, 'eeeeeeeeeee');
                                                                                                let data = _.find(userManagementDetails && userManagementDetails.um_legal_entity, { bg_id: Number(e) })
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_ids": [data && data.le_id],
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "1"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                                setActiveUnit(false)
                                                                                            }
                                                                                        }}
                                                                                        onChange={(option, data) => businessGroupHandelChange(option, data)}
                                                                                        // disabled={tabValue.compfie == false ? true : false}
                                                                                        // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                        mode="multiple"
                                                                                        value={tabValue.compfie == true ? businessGroupValue : undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {businessGroupData && businessGroupData.length > 1 ?
                                                                                            <>
                                                                                                <Option key="all" value="all" style={{ display: businessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: businessGroupUnselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}


                                                                                        {businessGroupData && businessGroupData.length > 0 &&
                                                                                            businessGroupData.map((item) => {
                                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <Option key={item.bg_id}>
                                                                                                        {item.bg_name}
                                                                                                    </Option>

                                                                                                )

                                                                                            }

                                                                                            )}
                                                                                    </Select>}
                                                                            </div>
                                                                            <div className="col-md-4" style={{ marginBottom: "5px" }}>

                                                                                <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span> </label><br />

                                                                                {entityValue.length > 0 && compfie.category !== null ?
                                                                                    <Tooltip title={entityTooltip}>
                                                                                        <Select
                                                                                            placement='topRight'
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={((compfie.category == null)&&(tabValue.compfie_litigation==true) )? true : false}
                                                                                            placeholder='Enter Legal Entity'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            className='form-control'
                                                                                            onDeselect={(e) => {
                                                                                                setActiveUnit(false)
                                                                                                if (AddType == 'edit') {
                                                                                                    console.log(e, 'eeeeeeeeeee');
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesLE",
                                                                                                                {
                                                                                                                    "le_ids": [e],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "1"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    console.log(Editpayload, 'Editpayload65659898*');
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                    if (entityValue && entityValue.value.length == 0) {
                                                                                                        setdomainValue([])
                                                                                                    }
                                                                                                }

                                                                                            }}
                                                                                            onChange={(option, value) => compfieEntityHandelChange(option, value)}
                                                                                            mode="multiple"
                                                                                            value={tabValue.compfie == true ? entityValue : undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {EntityData && EntityData.length > 1 ?
                                                                                                <><Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}

                                                                                            {/* {EntityData && EntityData.map((item, i) => {
            if (businessGroupData && businessGroupData.length > 0) {

                let data = _.find(businessGroupData, { bg_id: item.bg_id })
                return (
                    <OptGroup label={data && data.bg_name}>
                        <Option key={item.le_id}>
                            {item.le_name}
                        </Option>
                    </OptGroup>
                );
            }
            else {
                return (
                    <Option key={item.le_id}>
                        {item.le_name}
                    </Option>
                );
            }

        })} */}
                                                                                            {LegalEntityList && LegalEntityList.length > 0 ? LegalEntityList && LegalEntityList.length > 0 &&
                                                                                                LegalEntityList.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option key={subUnitData.le_id} value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                }) :
                                                                                                <> {EntityData.length > 0
                                                                                                    ? <OptGroup label="Others">
                                                                                                    </OptGroup> : false}
                                                                                                    {EntityData && EntityData.map((item, i) => {
                                                                                                        return (

                                                                                                            <Option key={item.le_id}>
                                                                                                                {item.le_name}
                                                                                                            </Option>

                                                                                                        );
                                                                                                    })}</>

                                                                                            }
                                                                                        </Select>
                                                                                    </Tooltip> :
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={((compfie.category == null)&&(tabValue.compfie_litigation==true) )? true : false}
                                                                                        placeholder='Enter Legal Entity'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        onDeselect={(e) => {
                                                                                            setActiveUnit(false)
                                                                                            if (AddType == 'edit') {
                                                                                                console.log(e, 'eeeeeeeeeee');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_ids": [e],
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "1"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                console.log(Editpayload, 'Editpayload65659898*');
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                                if (entityValue && entityValue.value.length == 0) {
                                                                                                    setdomainValue([])
                                                                                                }
                                                                                            }

                                                                                        }}
                                                                                        onChange={(option, value) => compfieEntityHandelChange(option, value)}
                                                                                        mode="multiple"
                                                                                        value={tabValue.compfie == true ? entityValue : undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {EntityData && EntityData.length > 1 ?
                                                                                            <><Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}

                                                                                        {/* {EntityData && EntityData.map((item, i) => {
            if (businessGroupData && businessGroupData.length > 0) {

                let data = _.find(businessGroupData, { bg_id: item.bg_id })
                return (
                    <OptGroup label={data && data.bg_name}>
                        <Option key={item.le_id}>
                            {item.le_name}
                        </Option>
                    </OptGroup>
                );
            }
            else {
                return (
                    <Option key={item.le_id}>
                        {item.le_name}
                    </Option>
                );
            }

        })} */}
                                                                                        {LegalEntityList && LegalEntityList.length > 0 ? LegalEntityList && LegalEntityList.length > 0 &&
                                                                                            LegalEntityList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option key={subUnitData.le_id} value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            }) :
                                                                                            <>  <OptGroup label="Others">
                                                                                            </OptGroup>{EntityData && EntityData.map((item, i) => {
                                                                                                return (

                                                                                                    <Option key={item.le_id}>
                                                                                                        {item.le_name}
                                                                                                    </Option>

                                                                                                );
                                                                                            })}</>

                                                                                        }
                                                                                    </Select>}

                                                                                {/* <Select
            // showSearch
            // optionFilterProp="children"
            // disabled={unit.legal_entity === "" ? true : false}
            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            allowClear={false}
            size="default"
            placeholder="Enter Entity"
            mode="multiple"
            defaultValue={`${compfie.legal_Entity}`}
            // defaultValue="Lulu Software Services Private Limited (India)"
            disabled={tabValue.compfie == false ? true : false}
            // onChange={(e) => {
            //     setCompfie({
            //         ...compfie,
            //         Domain: value
            //     })
            // }}
            onChange={(data) => handled(data)
            }
            // value={filter.seller}
            style={{ width: '65%' }}
        >

            {EntityData && EntityData.map((item, i) => {
                return (
                    <Option key={item.le_id}>
                        {item.le_name}
                    </Option>
                );
            })}
        </Select> */}




                                                                                {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                    'legal_Entity',
                                                                                    entityValue,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Legal Entity Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}




                                                                            </div>
                                                                            {category.compfie == '5' || category.compfie == '4' || category.compfie == '6' || category.compfie == '2' || category.compfie == '7' ?
                                                                                <div className="col-md-4" style={{ marginBottom: "5px" }}>
                                                                                    <label htmlFor=""><b>Domain</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                    {domainValue.length > 0 && tabValue.compfie == true ?
                                                                                        <Tooltip title={domainTooltip}>
                                                                                            <Select

                                                                                                style={{ marginTop: '5px' }}
                                                                                                disabled={((entityValue && entityValue.length <= 0 )&&(tabValue.compfie_litigation==true))? true : false}
                                                                                                placeholder='Enter Domain'
                                                                                                name="units"
                                                                                                id="units"
                                                                                                onDeselect={(e) => {
                                                                                                    setActiveUnit(false)
                                                                                                    let splitData = e.split("-")
                                                                                                    console.log(splitData[1], 'splitData[1]');
                                                                                                    if (AddType == 'edit') {
                                                                                                        const Editpayload = [
                                                                                                            authtoken,
                                                                                                            {
                                                                                                                "session_token": authtoken,
                                                                                                                "request": [
                                                                                                                    "HaveCompliancesDomain",
                                                                                                                    {
                                                                                                                        "le_ids": [splitData && splitData.length > 0 && splitData[0]],
                                                                                                                        "user_id": details.user_id,
                                                                                                                        "product_id": "1",
                                                                                                                        "d_ids": [Number(splitData && splitData.length > 0 && splitData[1])],


                                                                                                                    }
                                                                                                                ]

                                                                                                            }
                                                                                                        ]
                                                                                                        console.log(Editpayload, 'EditPayload123488659');
                                                                                                        selectHaveCompliance({
                                                                                                            payload: Editpayload,
                                                                                                            paramid: paramid,

                                                                                                        })
                                                                                                    }
                                                                                                    console.log(e, 'eeeeeeeeeee');

                                                                                                }}
                                                                                                className='form-control'
                                                                                                onChange={(option, value) => compfieDomainHandelChange(option, value)}
                                                                                                mode="multiple"
                                                                                                value={((entityValue && entityValue.length > 0) && (tabValue.compfie == true)) ? domainValue : undefined}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {domainData && domainData.length > 1 ? <> <Option key="all" value="all" style={{ display: domainselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: domainunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                                {DomainListData && DomainListData.length > 0 &&
                                                                                                    DomainListData.map((finalList_unitlist) => {
                                                                                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                            return (
                                                                                                                <OptGroup label={finalList_unitlist.label}>
                                                                                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                        <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                                    ))}
                                                                                                                </OptGroup>
                                                                                                            )
                                                                                                    })}
                                                                                            </Select>
                                                                                        </Tooltip> : <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={((entityValue && entityValue.length <= 0 )&&(tabValue.compfie_litigation==true))? true : false}
                                                                                            placeholder='Enter Domain'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            onDeselect={(e) => {
                                                                                                setActiveUnit(false)
                                                                                                let splitData = e.split("-")
                                                                                                console.log(splitData[1], 'splitData[1]');
                                                                                                if (AddType == 'edit') {
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesDomain",
                                                                                                                {
                                                                                                                    "le_ids": [splitData && splitData.length > 0 && splitData[0]],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "1",
                                                                                                                    "d_ids": [Number(splitData && splitData.length > 0 && splitData[1])],



                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    console.log(Editpayload, 'EditPayload123488659');
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                }
                                                                                                console.log(e, 'eeeeeeeeeee');

                                                                                            }}
                                                                                            className='form-control'
                                                                                            onChange={(option, value) => compfieDomainHandelChange(option, value)}
                                                                                            mode="multiple"
                                                                                            value={((entityValue && entityValue.length > 0) && (tabValue.compfie == true)) ? domainValue : undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {domainData && domainData.length > 1 ? <> <Option key="all" value="all" style={{ display: domainselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: domainunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}



                                                                                            {DomainListData && DomainListData.length > 0 &&
                                                                                                DomainListData.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>}

                                                                                    {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                        'domain',
                                                                                        domainValue,
                                                                                        ['required'],
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Domain Required',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}
                                                                                </div>
                                                                                : ""}


                                                                        </div>
                                                                    </div>

                                                                    {category.compfie == '2' || category.compfie == '7' ?
                                                                        <div className="col-md-12 mt-3">
                                                                            {/* <div className="form-group"> */}
                                                                            <div style={{ display: 'flex' }}>
                                                                                <label htmlFor=""><b>All Units Accessible</b></label><br />
                                                                                <input type="checkbox" name="" id="" style={{ width: "40px", height: "20px" }}
                                                                                    checked={unitAccess == true ? true : false}
                                                                                    onChange={(e) => {
                                                                                        setActiveUnit(false)
                                                                                        if ((e.target.checked == true)) {
                                                                                            let temp = []
                                                                                            setunitAccess(true)
                                                                                            console.log(userManagementDetails && userManagementDetails.um_legal_units.length, 'userManagementDetails && userManagementDetails.um_legal_units.length ');
                                                                                            if (userManagementDetails && userManagementDetails.um_legal_units.length > 0) {
                                                                                                for (let i in userManagementDetails && userManagementDetails.um_legal_units) {
                                                                                                    temp.push({
                                                                                                        le_id: userManagementDetails && userManagementDetails.um_legal_units[i].le_id,
                                                                                                        u_id: userManagementDetails && userManagementDetails.um_legal_units[i].u_unt_id
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                            setUserUnits(temp)
                                                                                        }
                                                                                        else {
                                                                                            setunitAccess(false)
                                                                                            setUserUnits([])
                                                                                            setAll([])
                                                                                            setclosedAll([])
                                                                                            // setAllUnitData([])
                                                                                        }
                                                                                    }} />
                                                                            </div>
                                                                            {/* </div> */}
                                                                        </div> : <div className="col-md-12">
                                                                            <div className="row">
                                                                                {category.compfie == '5' || category.compfie == '6' ?

                                                                                    <div className="col-md-4" >
                                                                                        <label htmlFor=""><b>Division</b></label><br />
                                                                                        {DivisionValue.length > 0 ?
                                                                                            <Tooltip title={divisionTooltip}>
                                                                                                <Select
                                                                                                    style={{ marginTop: '5px' }}
                                                                                                    name="units"
                                                                                                    id="units"
                                                                                                    title=" "
                                                                                                    className='form-control'
                                                                                                    placeholder="Enter Division"
                                                                                                    onChange={(option, data) => divisionHandelChange(option, data)}
                                                                                                    disabled={entityValue && entityValue.length <= 0 ? true : false}
                                                                                                    mode="multiple"
                                                                                                    onDeselect={(e) => {
                                                                                                        setActiveUnit(false)
                                                                                                        if (AddType == 'edit') {
                                                                                                            let data = _.find(userManagementDetails && userManagementDetails.um_legal_units, { d_id: e })
                                                                                                            console.log(data, 'eeeeeeeeeee');
                                                                                                            const Editpayload = [
                                                                                                                authtoken,
                                                                                                                {
                                                                                                                    "session_token": authtoken,
                                                                                                                    "request": [
                                                                                                                        "HaveCompliancesMultiUnits",
                                                                                                                        {
                                                                                                                            "le_ids": [data.le_id],
                                                                                                                            "user_id": details.user_id,
                                                                                                                            "unit_ids": [
                                                                                                                                data.u_unt_id
                                                                                                                            ],
                                                                                                                            "product_id": "1"
                                                                                                                        }
                                                                                                                    ]

                                                                                                                }
                                                                                                            ]
                                                                                                            selectHaveCompliance({
                                                                                                                payload: Editpayload,
                                                                                                                paramid: paramid,

                                                                                                            })

                                                                                                        }

                                                                                                    }}
                                                                                                    value={tabValue.compfie == true ? DivisionValue : undefined}
                                                                                                    maxTagCount='responsive'
                                                                                                >
                                                                                                    {divisionData && divisionData.length > 1 ?
                                                                                                        <>
                                                                                                            <Option key="all" value="all" style={{ display: divisionselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                            <Option key="unselect" value="unselect" style={{ display: divisionunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                        </> : false}


                                                                                                    {/* {divisionData && divisionData.length > 0 &&
                    divisionData.map((item) => {
                        if (EntityData && EntityData.length > 0) {
                            let data = _.find(EntityData, { le_id: item.le_id })
                            return (
                                <OptGroup label={data && data.le_name}>
                                    <Option key={item.d_id}>
                                        {item.d_name}
                                    </Option>
                                </OptGroup>
                            )
                        }
                        else {
                            return (
                                <Option key={item.d_id}>
                                    {item.d_name}
                                </Option>
                            )
                        }


                    }

                    )} */}



                                                                                                    {unitListData && unitListData.length > 0 &&
                                                                                                        unitListData.map((finalList_unitlist) => {
                                                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                                return (
                                                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                            <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                                                                                        ))}
                                                                                                                    </OptGroup>
                                                                                                                )
                                                                                                        })}
                                                                                                </Select>
                                                                                            </Tooltip> :
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                name="units"
                                                                                                id="units"
                                                                                                className='form-control'
                                                                                                placeholder="Enter Division"
                                                                                                onChange={(option, data) => divisionHandelChange(option, data)}
                                                                                                disabled={entityValue && entityValue.length <= 0 ? true : false}
                                                                                                mode="multiple"
                                                                                                onDeselect={(e) => {
                                                                                                    if (AddType == 'edit') {
                                                                                                        let data = _.find(userManagementDetails && userManagementDetails.um_legal_units, { d_id: e })
                                                                                                        console.log(data, 'eeeeeeeeeee');
                                                                                                        const Editpayload = [
                                                                                                            authtoken,
                                                                                                            {
                                                                                                                "session_token": authtoken,
                                                                                                                "request": [
                                                                                                                    "HaveCompliancesMultiUnits",
                                                                                                                    {
                                                                                                                        "le_ids": [data.le_id],
                                                                                                                        "user_id": details.user_id,
                                                                                                                        "unit_ids": [data.u_unt_id],
                                                                                                                        "product_id": "1"
                                                                                                                    }
                                                                                                                ]

                                                                                                            }
                                                                                                        ]
                                                                                                        selectHaveCompliance({
                                                                                                            payload: Editpayload,
                                                                                                            paramid: paramid,

                                                                                                        })

                                                                                                    }

                                                                                                }}
                                                                                                value={tabValue.compfie == true ? DivisionValue : undefined}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {divisionData && divisionData.length > 1 ?
                                                                                                    <>
                                                                                                        <Option key="all" value="all" style={{ display: divisionselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                        <Option key="unselect" value="unselect" style={{ display: divisionunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                    </> : false}





                                                                                                {unitListData && unitListData.length > 0 &&
                                                                                                    unitListData.map((finalList_unitlist) => {
                                                                                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                            return (
                                                                                                                <OptGroup label={finalList_unitlist.label}>
                                                                                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                        <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                                                                                    ))}
                                                                                                                </OptGroup>
                                                                                                            )
                                                                                                    })}
                                                                                            </Select>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    ''}




                                                                                {category.compfie == '5' || category.compfie == '6' ?
                                                                                    <div className="col-md-4">
                                                                                        <label htmlFor=""><b>Category</b></label><br />

                                                                                        {categoryValues.length > 0 ?
                                                                                            <Tooltip title={catTooltip}>
                                                                                                <Select
                                                                                                    style={{ marginTop: '5px' }}
                                                                                                    disabled={DivisionValue && DivisionValue.length <= 0 ? true : false}
                                                                                                    placeholder='Enter Category'
                                                                                                    name="units"
                                                                                                    id="units"
                                                                                                    onDeselect={(e) => {
                                                                                                        if (AddType == 'edit') {
                                                                                                            setActiveUnit(false)
                                                                                                            let data = _.find(userManagementDetails && userManagementDetails.um_legal_units
                                                                                                                , { cat_id: e })
                                                                                                            console.log(data, 'eeeeeeeeeee');
                                                                                                            const Editpayload = [
                                                                                                                authtoken,
                                                                                                                {
                                                                                                                    "session_token": authtoken,
                                                                                                                    "request": [
                                                                                                                        "HaveCompliancesMultiUnits",
                                                                                                                        {
                                                                                                                            "le_ids": [data.le_id],
                                                                                                                            "user_id": details.user_id,
                                                                                                                            "unit_ids": [data.u_unt_id],
                                                                                                                            "product_id": "1"
                                                                                                                        }
                                                                                                                    ]

                                                                                                                }
                                                                                                            ]
                                                                                                            selectHaveCompliance({
                                                                                                                payload: Editpayload,
                                                                                                                paramid: paramid,

                                                                                                            })

                                                                                                        }

                                                                                                    }}
                                                                                                    className='form-control'
                                                                                                    onChange={(option, value) => compfieCategoryHandelChange(option, value)}
                                                                                                    mode="multiple"
                                                                                                    value={tabValue.compfie == true ? categoryValues : undefined}
                                                                                                    maxTagCount='responsive'
                                                                                                >
                                                                                                    {categoryDatas && categoryDatas.length > 1 ?
                                                                                                        <>
                                                                                                            <Option key="all" value="all" style={{ display: categoryselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                            <Option key="unselect" value="unselect" style={{ display: categoryunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                        </> : false}

                                                                                                    {categoryList && categoryList.length > 0 &&
                                                                                                        categoryList.map((finalList_unitlist) => {
                                                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                                return (
                                                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                            <Option value={subUnitData.cat_id}>{subUnitData.cat_name}</Option>

                                                                                                                        ))}
                                                                                                                    </OptGroup>
                                                                                                                )
                                                                                                        })}
                                                                                                </Select>
                                                                                            </Tooltip> :
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                disabled={DivisionValue && DivisionValue.length <= 0 ? true : false}
                                                                                                placeholder='Enter Category'
                                                                                                name="units"
                                                                                                id="units"
                                                                                                onDeselect={(e) => {
                                                                                                    setActiveUnit(false)
                                                                                                    if (AddType == 'edit') {
                                                                                                        let data = _.find(userManagementDetails && userManagementDetails.um_legal_units
                                                                                                            , { cat_id: e })
                                                                                                        console.log(data, 'eeeeeeeeeee');
                                                                                                        const Editpayload = [
                                                                                                            authtoken,
                                                                                                            {
                                                                                                                "session_token": authtoken,
                                                                                                                "request": [
                                                                                                                    "HaveCompliancesMultiUnits",
                                                                                                                    {
                                                                                                                        "le_ids": [data.le_id],
                                                                                                                        "user_id": details.user_id,
                                                                                                                        "unit_ids": [data.u_unt_id],
                                                                                                                        "product_id": "3"
                                                                                                                    }
                                                                                                                ]

                                                                                                            }
                                                                                                        ]
                                                                                                        selectHaveCompliance({
                                                                                                            payload: Editpayload,
                                                                                                            paramid: paramid,

                                                                                                        })

                                                                                                    }

                                                                                                }}
                                                                                                className='form-control'
                                                                                                onChange={(option, value) => compfieCategoryHandelChange(option, value)}
                                                                                                mode="multiple"
                                                                                                value={tabValue.compfie == true ? categoryValues : undefined}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {categoryDatas && categoryDatas.length > 1 ?
                                                                                                    <>
                                                                                                        <Option key="all" value="all" style={{ display: categoryselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                        <Option key="unselect" value="unselect" style={{ display: categoryunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                    </> : false}

                                                                                                {/* {categoryDatas && categoryDatas.length > 0 && categoryDatas.map((item, i) => {
                if (EntityData && EntityData.length > 0) {
                    let data = _.find(EntityData, { le_id: item.le_id })
                    return (
                        <OptGroup label={data && data.le_name}>
                            <Option key={item.cat_id}>
                                {item.cat_name}
                            </Option>
                        </OptGroup>
                    );

                }
                else {
                    return (
                        <Option key={item.cat_id}>
                            {item.cat_name}
                        </Option>
                    );
                }

            })} */}
                                                                                                {categoryList && categoryList.length > 0 &&
                                                                                                    categoryList.map((finalList_unitlist) => {
                                                                                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                            return (
                                                                                                                <OptGroup label={finalList_unitlist.label}>
                                                                                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                        <Option value={subUnitData.cat_id}>{subUnitData.cat_name}</Option>

                                                                                                                    ))}
                                                                                                                </OptGroup>
                                                                                                            )
                                                                                                    })}
                                                                                            </Select>}
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </TabPane> : ''}

                                                            {EntityDetails && EntityDetails.ltmg_user_le && EntityDetails.ltmg_user_le.length > 0 ?
                                                                <TabPane
                                                                    // disabled={tabValue.compfie_litigation == false ? true : false}
                                                                    tab={
                                                                        <span style={{ padding: "0px 30px", fontSize: "16px" }}>
                                                                            {
                                                                                tabValue.compfie_litigation == false ?
                                                                                    <FeatherIcon icon="x-circle" className={'pointer'} style={{ color: 'red', marginBottom: '-1%' }} size='19' onClick={() => {
                                                                                        setCategoryFormSubmit(false)
                                                                                        setTab("2")
                                                                                        setTabValue({
                                                                                            ...tabValue,
                                                                                            compfie_litigation: !tabValue.compfie_litigation
                                                                                        })

                                                                                    }}

                                                                                    /> : <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'green', marginBottom: '-1%' }} size='19' onClick={() => {
                                                                                        // if (AddType == 'edit') {
                                                                                        setCategoryFormSubmit(false)
                                                                                        if ((AddType == 'add') || (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 0)) {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie_litigation: !tabValue.compfie_litigation
                                                                                            })
                                                                                        }
                                                                                        else if ((AddType == 'edit' && login.usr_cat_id == 1) && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1)) {
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_ids": details.le_id,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "2"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            EdithaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,
                                                                                                setTabValue: setTabValue,
                                                                                                tabValue: tabValue,
                                                                                                type: "litigation"
                                                                                            })



                                                                                        }
                                                                                        // }
                                                                                        // else {
                                                                                        //     setTabValue({
                                                                                        //         ...tabValue,
                                                                                        //         compfie_litigation: !tabValue.compfie_litigation
                                                                                        //     })
                                                                                        // }
                                                                                        if (AddType == 'add') {
                                                                                            setlititigationbusinessGroupValue([])
                                                                                            setlitigationdomainValue([])
                                                                                            setLitigationEntityValue([])
                                                                                            setVendorCategory("")
                                                                                            setCompfieLitigation({
                                                                                                ...compfieLitigation,
                                                                                                category: null,
                                                                                                user: '',
                                                                                                legal_Entity: [],
                                                                                                Domain: [],
                                                                                                businessGroup: '',
                                                                                                serviceProvider: ''
                                                                                            })
                                                                                        }

                                                                                    }}
                                                                                    />
                                                                            }

                                                                            <span style={{ marginBottom: '2%' }}><b> Compfie Litigation</b></span>
                                                                        </span>
                                                                    }
                                                                    key="2">
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor=""><b>User Category</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                    <Select className='form-control' placeholder="Enter User Group" disabled={tabValue.compfie_litigation == false ? true : false} value={tabValue.compfie_litigation == true ? compfieLitigation.category : undefined} style={{ marginTop: '5px' }}
                                                                                        onChange={(data) => {
                                                                                            setActiveUnit(false)
                                                                                            if (data !== undefined) {
                                                                                                setCompfieLitigation({
                                                                                                    ...compfieLitigation,
                                                                                                    category: data
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                setCompfieLitigation({
                                                                                                    ...compfieLitigation,
                                                                                                    category: null
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                        }
                                                                                        allowClear={true}
                                                                                    >

                                                                                        <Option key=''>Select</Option>
                                                                                        <Option key={2} value={2}>Creator</Option>
                                                                                        <Option key={3} value={3}>User</Option>
                                                                                    </Select>
                                                                                    {/* <select name="user_category" className='form-control' disabled={tabValue.compfie_litigation == false ? true : false} value={compfieLitigation.category} style={{ marginTop: '5px' }} onChange={(e) => {
                                                                                    setCompfieLitigation({
                                                                                        ...compfieLitigation,
                                                                                        category: e.target.value
                                                                                    })
                                                                                }} placeholder="Enter User Group">
                                                                                    <option value="">Select</option>
                                                                                    <option value="2">Creator</option>
                                                                                    <option value="3">User</option>
                                                                                </select> */}
                                                                                    {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                        'category',
                                                                                        compfieLitigation.category,
                                                                                        'required',
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'User Category Required',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor=""><b>User</b> <span style={{ "color": "red" }}>*</span></label>
                                                                                    {/* <select className='form-control' id="cars"
                                                                                    style={{ marginTop: '5px' }}
                                                                                    disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                    value={compfieLitigation.user}
                                                                                    onChange={(e) => {
                                                                                        setCompfieLitigation({
                                                                                            ...compfieLitigation,
                                                                                            user: e.target.value
                                                                                        })



                                                                                        if (e.target.value == "Service Provider User") {
                                                                                            let vendorCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category, { u_c_name: e.target.value })
                                                                                            setVendorCategory(vendorCategory)
                                                                                        }
                                                                                        //   setVendorCategories(category)
                                                                                    }}>
                                                                                    <option value={null}>Select</option>
                                                                                    <option value="Inhouse User">Inhouse User</option>
                                                                                    <option value="Service Provider User">Service Provider User</option>
                                                                                </select> */} <Select className='form-control' placeholder="Enter User"
                                                                                        style={{ marginTop: '5px' }}
                                                                                        allowClear={true}
                                                                                        disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                        value={tabValue.compfie_litigation == true ? compfieLitigation.user : undefined}
                                                                                        onChange={(data) => {
                                                                                            setActiveUnit(false)
                                                                                            if (data !== undefined) {
                                                                                                setCompfieLitigation({
                                                                                                    ...compfieLitigation,
                                                                                                    user: data
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                setCompfieLitigation({
                                                                                                    ...compfieLitigation,
                                                                                                    user: ""
                                                                                                })
                                                                                            }


                                                                                            if (data == "Service Provider User") {
                                                                                                let vendorCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category, { u_c_name: data })
                                                                                                setVendorCategory(vendorCategory)
                                                                                            }
                                                                                            //   setVendorCategories(category)
                                                                                        }}>
                                                                                        <Option value=''>Select</Option>
                                                                                        <Option value={2}>Inhouse User</Option>
                                                                                        <Option value={3}>Service Provider User</Option>
                                                                                    </Select>
                                                                                    {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                        'compie_litigation',
                                                                                        compfieLitigation.user,
                                                                                        'required',
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'User Required',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}
                                                                                </div>
                                                                            </div>

                                                                            {compfieLitigation.user == 'Service Provider User' ? <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor=""><b>Service Provider</b> <span style={{ "color": "red" }}>*</span> </label><br />
                                                                                    <Select
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        placeholder="Enter Service Provider"
                                                                                        // onChange={categoryOnChange}
                                                                                        onChange={(data) => {
                                                                                            setActiveUnit(false)
                                                                                            if (data !== undefined) {
                                                                                                setValue({
                                                                                                    ...value,
                                                                                                    serviceProvider: data
                                                                                                })
                                                                                                setCompfieLitigation({
                                                                                                    ...compfieLitigation,
                                                                                                    serviceProvider: data
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                setValue({
                                                                                                    ...value,
                                                                                                    serviceProvider: ""
                                                                                                })
                                                                                                setCompfieLitigation({
                                                                                                    ...compfieLitigation,
                                                                                                    serviceProvider: ""
                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                        // value={filter.seller}
                                                                                        style={{ width: '100%', marginTop: '5px' }}
                                                                                        disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                    >
                                                                                        {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.u_sp_id}>
                                                                                                    {item.u_sp_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select>
                                                                                    {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                        'mobile_no',
                                                                                        compfieLitigation.serviceProvider,
                                                                                        'required',
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Service Provider Required',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}
                                                                                </div>
                                                                            </div>

                                                                                : ''}
                                                                        </div>
                                                                    </div>{/* <Divider /> */}
                                                                    <h5 style={{ marginTop: "15px" }}><b>Settings</b></h5>
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-4" style={{ marginBottom: "5px" }}>
                                                                                <label htmlFor=""><b>Business Group</b></label><br />
                                                                                {lititigationbusinessGroupValue.length > 0 ?
                                                                                    <Tooltip title={litigationBgTooltip}>
                                                                                        <Select
                                                                                            disabled={compfieLitigation.category == null ? true : false}
                                                                                            style={{ marginTop: '5px' }}
                                                                                            name="units"
                                                                                            id="units"
                                                                                            className='form-control'
                                                                                            placeholder="Enter Business Group"
                                                                                            onDeselect={(e, value) => {
                                                                                                setActiveUnit(false)
                                                                                                if (AddType == 'edit') {
                                                                                                    console.log(e, 'eeeeeeeeeee');
                                                                                                    let data = _.find(userManagementDetails && userManagementDetails.um_ltmg_legal_entity, { bg_id: Number(e) })
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesLE",
                                                                                                                {
                                                                                                                    "le_ids": [data && data.le_id],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "2"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                            // disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                            onChange={(option, data) => litigationbusinessGroupHandelChange(option, data)}
                                                                                            // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                            mode="multiple"
                                                                                            value={tabValue.compfie_litigation == true ? lititigationbusinessGroupValue : undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {litigationbusinessGroupData && litigationbusinessGroupData.length > 1 ?
                                                                                                <>
                                                                                                    <Option key="all" value="all" style={{ display: litigationbusinessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: litigationbusinessGroupunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}


                                                                                            {litigationbusinessGroupData && litigationbusinessGroupData.length > 0 &&
                                                                                                litigationbusinessGroupData.map((item) => {
                                                                                                    let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                    return (

                                                                                                        <Option key={item.bg_id}>
                                                                                                            {item.bg_name}
                                                                                                        </Option>

                                                                                                    )

                                                                                                }

                                                                                                )}
                                                                                        </Select>
                                                                                    </Tooltip> :
                                                                                    <Select

                                                                                        style={{ marginTop: '5px' }}
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        placeholder="Enter Business Group"
                                                                                        onDeselect={(e, value) => {
                                                                                            setActiveUnit(false)
                                                                                            if (AddType == 'edit') {
                                                                                                console.log(e, 'eeeeeeeeeee');
                                                                                                let data = _.find(userManagementDetails && userManagementDetails.um_ltmg_legal_entity, { bg_id: Number(e) })
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_ids": [data && data.le_id],
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "2"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                        disabled={compfieLitigation.category == null ? true : false}
                                                                                        // disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                        onChange={(option, data) => litigationbusinessGroupHandelChange(option, data)}
                                                                                        // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                        mode="multiple"
                                                                                        value={tabValue.compfie_litigation == true ? lititigationbusinessGroupValue : undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {litigationbusinessGroupData && litigationbusinessGroupData.length > 1 ?
                                                                                            <>
                                                                                                <Option key="all" value="all" style={{ display: litigationbusinessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: litigationbusinessGroupunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}


                                                                                        {litigationbusinessGroupData && litigationbusinessGroupData.length > 0 &&
                                                                                            litigationbusinessGroupData.map((item) => {
                                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <Option key={item.bg_id}>
                                                                                                        {item.bg_name}
                                                                                                    </Option>

                                                                                                )

                                                                                            }

                                                                                            )}
                                                                                    </Select>}
                                                                            </div>
                                                                            <div className="col-md-4" style={{ marginBottom: "5px" }}>

                                                                                <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span> </label><br />

                                                                                {litigationEntityValue.length > 0 && compfieLitigation.category !== null ?
                                                                                    <Tooltip title={litigationEntityTooltip}>
                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={compfieLitigation.category == null && tabValue.compfie_litigation == false ? true : false}
                                                                                            placeholder='Enter Legal Entity'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            className='form-control'
                                                                                            onDeselect={(e) => {
                                                                                                setActiveUnit(false)
                                                                                                if (AddType == 'edit') {
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesLE",
                                                                                                                {
                                                                                                                    "le_ids": [e],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "2"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                    if (litigationEntityValue && litigationEntityValue.value.length == 0) {
                                                                                                        setlitigationdomainValue([])
                                                                                                    }
                                                                                                }

                                                                                                console.log(e, 'eeeeeeeeeee');

                                                                                            }}
                                                                                            onChange={(option) => litigationEntityHandelChange(option)}
                                                                                            mode="multiple"
                                                                                            value={tabValue.compfie_litigation == true ? litigationEntityValue : undefined}
                                                                                            maxTagCount='responsive'
                                                                                            tagRender={litigationEntityValue.length > 1 ? true : false}

                                                                                        >
                                                                                            {litigationEntityData && litigationEntityData.length > 1 ? <> <Option key="all" value="all" style={{ display: LitigationEntityselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: LitigationEntitysunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}
                                                                                            {LitigationLegalEntityList && LitigationLegalEntityList.length > 0 ? LitigationLegalEntityList && LitigationLegalEntityList.length > 0 &&
                                                                                                LitigationLegalEntityList.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                }) : <> {litigationEntityData && litigationEntityData.length > 0 ? <OptGroup label="Others">
                                                                                                </OptGroup> : false}{litigationEntityData && litigationEntityData.map((item, i) => {
                                                                                                    return (

                                                                                                        <Option key={item.le_id}>
                                                                                                            {item.le_name}
                                                                                                        </Option>

                                                                                                    );
                                                                                                })}</>}




                                                                                        </Select>
                                                                                    </Tooltip> : <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={compfieLitigation.category == null ? true : false}
                                                                                        placeholder='Enter Legal Entity'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        onDeselect={(e) => {
                                                                                            setActiveUnit(false)
                                                                                            if (AddType == 'edit') {
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_ids": [e],
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "2"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                                if (litigationEntityValue && litigationEntityValue.value.length == 0) {
                                                                                                    setlitigationdomainValue([])
                                                                                                }
                                                                                            }

                                                                                            console.log(e, 'eeeeeeeeeee');

                                                                                        }}
                                                                                        onChange={(option) => litigationEntityHandelChange(option)}
                                                                                        mode="multiple"
                                                                                        value={tabValue.compfie_litigation == true ? litigationEntityValue : undefined}
                                                                                        maxTagCount='responsive'
                                                                                        tagRender={litigationEntityValue.length > 1 ? true : false}

                                                                                    >
                                                                                        {litigationEntityData && litigationEntityData.length > 1 ? <> <Option key="all" value="all" style={{ display: LitigationEntityselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: LitigationEntitysunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                        {LitigationLegalEntityList && LitigationLegalEntityList.length > 0 ? LitigationLegalEntityList && LitigationLegalEntityList.length > 0 &&
                                                                                            LitigationLegalEntityList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            }) : <> {litigationEntityData && litigationEntityData.length > 0 ? <OptGroup label="Others">
                                                                                            </OptGroup> : false}{litigationEntityData && litigationEntityData.map((item, i) => {
                                                                                                return (

                                                                                                    <Option key={item.le_id}>
                                                                                                        {item.le_name}
                                                                                                    </Option>

                                                                                                );
                                                                                            })}</>}




                                                                                    </Select>}
                                                                                {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                    'legalentity',
                                                                                    litigationEntityValue,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Legal Entity Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                            </div>
                                                                            <div className="col-md-4" style={{ marginBottom: "5px" }}>
                                                                                <label htmlFor=""><b>Domain</b> < span style={{ "color": "red" }}>*</span> </label><br />
                                                                                {/* <select name="cars" className='form-control' id="cars">
                                                                                <option value="volvo">None Selected</option>
                                                                            </select> */}
                                                                                {/* <Select
                                                                                disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={false}
                                                                                size="default"
                                                                                mode='multiple'
                                                                                placeholder="Enter Unit"
                                                                                onChange={(data) => handleChange(data)
                                                                                }
                                                                                defaultValue={compfieLitigation.Domain}
                                                                                style={{ width: '65%' }}
                                                                            >
                                                                                {domain && domain.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.u_dm_id}>
                                                                                            {item.u_dm_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select> */}


                                                                                {/* <MultiSelect
                                                                            style={{ width: '100%', marginTop: '5px' }}
                                                                            // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                            options={LitigationDomainoptions}
                                                                            value={LitigationDomainValue}
                                                                            onChange={setLitigationDomainValue}
                                                                            labelledBy="Select"
                                                                            disabled={tabValue.compfie_litigation == false ? true : false}

                                                                        /> */}
                                                                                {litigationdomainValue.length > 0 && tabValue.compfie_litigation == true ?
                                                                                    <Tooltip title={litigationDomainTooltip}>

                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={litigationEntityValue && litigationEntityValue.length <= 0 ? true : false}
                                                                                            placeholder='Enter Domain'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            className='form-control'
                                                                                            onDeselect={(e) => {
                                                                                                setActiveUnit(false)
                                                                                                let splitData = e.split("-")
                                                                                                console.log(splitData, 'splitData');
                                                                                                if (AddType == 'edit') {
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesDomain",
                                                                                                                {
                                                                                                                    "le_ids": [splitData && splitData.length > 0 && splitData[0]],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "2",
                                                                                                                    "d_ids": [Number(splitData && splitData.length > 0 && splitData[1])],



                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                }
                                                                                                console.log(e, 'eeeeeeeeeee');

                                                                                            }}
                                                                                            onChange={(option) => LitigationDomainHandelChange(option)}
                                                                                            mode="multiple"
                                                                                            value={((litigationEntityValue && litigationEntityValue.length > 0) && (tabValue.compfie_litigation == true)) ? litigationdomainValue : undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {litigationDomainData && litigationDomainData.length > 1 ? <>
                                                                                                <Option key="all" value="all" style={{ display: litigationdomainselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: litigationdomainunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}

                                                                                            {/* {litigationDomainData && litigationDomainData.map((item, i) => {
                                                                            if (litigationEntityData && litigationEntityData.length > 0) {
                                                                                let data = _.find(litigationEntityData, { le_id: item.le_id })
                                                                                return (

                                                                                    <OptGroup label={data && data.le_name}>
                                                                                        <Option key={item.u_dm_id}>
                                                                                            {item.u_dm_name}
                                                                                        </Option>
                                                                                    </OptGroup>
                                                                                );
                                                                            }
                                                                            else {
                                                                                return (

                                                                                    <Option key={item.u_dm_id}>
                                                                                        {item.u_dm_name}
                                                                                    </Option>
                                                                                );
                                                                            }

                                                                        })} */}
                                                                                            {LitigationDomainList && LitigationDomainList.length > 0 &&
                                                                                                LitigationDomainList.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>
                                                                                    </Tooltip> :
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={litigationEntityValue && litigationEntityValue.length <= 0 ? true : false}
                                                                                        placeholder='Enter Domain'
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        onDeselect={(e) => {
                                                                                            setActiveUnit(false)
                                                                                            let splitData = e.split("-")
                                                                                            console.log(splitData, 'splitData');
                                                                                            if (AddType == 'edit') {
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesDomain",
                                                                                                            {
                                                                                                                "le_ids": [splitData && splitData.length > 0 && splitData[0]],
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "2",
                                                                                                                "d_ids": [Number(splitData && splitData.length > 0 && splitData[1])],



                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                            }
                                                                                            console.log(e, 'eeeeeeeeeee');

                                                                                        }}
                                                                                        onChange={(option) => LitigationDomainHandelChange(option)}
                                                                                        mode="multiple"
                                                                                        value={((litigationEntityValue && litigationEntityValue.length > 0) && (tabValue.compfie_litigation == true)) ? litigationdomainValue : undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {litigationDomainData && litigationDomainData.length > 1 ? <>
                                                                                            <Option key="all" value="all" style={{ display: litigationdomainselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: litigationdomainunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                        </> : false}

                                                                                        {/* {litigationDomainData && litigationDomainData.map((item, i) => {
                                                                     if (litigationEntityData && litigationEntityData.length > 0) {
                                                                         let data = _.find(litigationEntityData, { le_id: item.le_id })
                                                                         return (

                                                                             <OptGroup label={data && data.le_name}>
                                                                                 <Option key={item.u_dm_id}>
                                                                                     {item.u_dm_name}
                                                                                 </Option>
                                                                             </OptGroup>
                                                                         );
                                                                     }
                                                                     else {
                                                                         return (

                                                                             <Option key={item.u_dm_id}>
                                                                                 {item.u_dm_name}
                                                                             </Option>
                                                                         );
                                                                     }

                                                                 })} */}
                                                                                        {LitigationDomainList && LitigationDomainList.length > 0 &&
                                                                                            LitigationDomainList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}
                                                                                    </Select>}
                                                                                {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                    'domain',
                                                                                    litigationdomainValue,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Domain Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })
                                                                                ) : ''}
                                                                            </div>
                                                                            <div className="col-md-6"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div>


                                                                    </div>

                                                                </TabPane>

                                                                : ""}
                                                            {EntityDetails && EntityDetails.vndr_user_le && EntityDetails.vndr_user_le.length > 0 ?
                                                                <TabPane
                                                                    // disabled={tabValue.compfie_vendor == false ? true : false}
                                                                    tab={
                                                                        <span style={{ padding: "0px 30px", fontSize: "16px" }}>
                                                                            {
                                                                                tabValue.compfie_vendor == false ?
                                                                                    <FeatherIcon icon="x-circle" className={'pointer'} style={{ color: 'red', marginBottom: '-1%' }} size='19' onClick={() => {
                                                                                        setCategoryFormSubmit(false)
                                                                                        setTabValue({
                                                                                            ...tabValue,
                                                                                            compfie_vendor: !tabValue.compfie_vendor
                                                                                        })
                                                                                        setTab("3")
                                                                                    }}
                                                                                    /> : <FeatherIcon icon="check-circle" className={'pointer'} style={{ color: 'green', marginBottom: '-1%' }} size='19' onClick={() => {
                                                                                        // if (AddType == 'edit') {
                                                                                        //     setTabValue({
                                                                                        //         ...tabValue,
                                                                                        //         compfie_vendor: tabValue.compfie_vendor
                                                                                        //     })
                                                                                        // }
                                                                                        // else {
                                                                                        setCategoryFormSubmit(false)
                                                                                        if ((AddType == 'add') || (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 0)) {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie_vendor: !tabValue.compfie_vendor
                                                                                            })
                                                                                        }
                                                                                        else if ((AddType == 'edit' && login.usr_cat_id == 1) && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1)) {
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesLE",
                                                                                                        {
                                                                                                            "le_ids": details.le_id,
                                                                                                            "user_id": details.user_id,
                                                                                                            "product_id": "3"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            EdithaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,
                                                                                                setTabValue: setTabValue,
                                                                                                tabValue: tabValue,
                                                                                                type: "vendor"
                                                                                            })



                                                                                        }
                                                                                        // }
                                                                                        if (AddType == 'add') {
                                                                                            setVendorbusinessGroupValue([])
                                                                                            setVendoreEntityValue([])
                                                                                            setvendordomainValues([])
                                                                                            setvendorDivisionValue([])
                                                                                            setVendorCategoryValues([])
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                category: null,
                                                                                                seating_Unit: null,
                                                                                                user_Group: null,
                                                                                                user_level: '1',
                                                                                                entity: '',
                                                                                                serviceProvider: null,
                                                                                                checked_value: '',
                                                                                                legal_Entity: [],
                                                                                                Domain: [],
                                                                                                Division: '',
                                                                                            })
                                                                                        }

                                                                                    }} />
                                                                            }

                                                                            <span style={{ marginBottom: '2%' }}><b> Compfie Vendor</b></span>
                                                                        </span>
                                                                    }
                                                                    key="3">

                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor=""><b>Category</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                    <br />
                                                                                    <Select
                                                                                        disabled={tabValue.compfie_vendor == false || ((AddType == 'edit') && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1)) ? true : false}
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        placeholder="Enter Category"
                                                                                        // onChange={categoryOnChange}
                                                                                        onChange={(data) => {
                                                                                            setActiveUnit(false)
                                                                                            if (data !== undefined) {
                                                                                                let CategoryData = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_c_id: Number(data) })
                                                                                                setVendorgroupcompfie(CategoryData)
                                                                                                setCategory({
                                                                                                    ...category,
                                                                                                    compfie_vendor: data
                                                                                                })
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    category: data
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                setCategory({
                                                                                                    ...category,
                                                                                                    compfie_vendor: "",

                                                                                                })
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    category: null,
                                                                                                    user_Group: ''
                                                                                                })
                                                                                                setVendoreEntityValue([])
                                                                                            }
                                                                                        }}
                                                                                        value={tabValue.compfie_vendor == true ? compfieVendorValue.category : undefined}
                                                                                        style={{ width: '100%', marginTop: '5px' }}
                                                                                    >
                                                                                        {compfieLitigation.user == 'Service Provider User' ? vendorcategory.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.u_c_id}>
                                                                                                    {item.u_c_name}
                                                                                                </Option>
                                                                                            );
                                                                                        }) : userManagementDetails && userManagementDetails.um_user_category && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category.map((item, i) => {
                                                                                            console.log(item, "itemitemmmmmmm");
                                                                                            if (item.u_c_id == compfie.category) {
                                                                                                return (
                                                                                                    <Option key={item.u_c_id}>
                                                                                                        {item.u_c_name}
                                                                                                    </Option>

                                                                                                );
                                                                                            }
                                                                                        })}
                                                                                    </Select>

                                                                                    {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                        'category',
                                                                                        compfieVendorValue.category,
                                                                                        'required',
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Category Required',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}

                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor=""><b>User Group</b> <span style={{ "color": "red" }}>*</span></label><br />
                                                                                <Select
                                                                                    disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                    showSearch
                                                                                    // optionFilterProp="children"
                                                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Enter User Group"

                                                                                    onChange={(data) => {
                                                                                        setActiveUnit(false)
                                                                                        if (data !== undefined) {
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                user_Group: data
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                user_Group: ""
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                    }
                                                                                    value={tabValue.compfie_vendor == true ? compfieVendorValue.user_Group : undefined}
                                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                                >
                                                                                    {vendorgroupcompfie && vendorgroupcompfie.length > 0 && vendorgroupcompfie.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.u_g_id}>
                                                                                                {item.u_g_name}
                                                                                            </Option>
                                                                                        );
                                                                                    })}
                                                                                </Select>
                                                                                {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                    'category',
                                                                                    compfieVendorValue.user_Group,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'User Group Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}

                                                                            </div>

                                                                            {category.compfie_vendor == '5' ? <div className="col-md-4">
                                                                                <div className="form-group">
                                                                                    <label htmlFor=""><b>User Level</b> <span style={{ "color": "red" }}>*</span> </label>
                                                                                    {/* <select name="" id="" className='form-control' onChange={(e) => {
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    user_level: e.target.value
                                                                                                })


                                                                                            }}
                                                                                                value={compfieVendorValue.user_level}
                                                                                                style={{ width: '100%', marginTop: '5px' }}  >
                                                                                                <option value="1">Level 1(Low)</option>
                                                                                                <option value="2">Level 2</option>
                                                                                                <option value="3">Level 3</option>
                                                                                                <option value="4">Level 4</option>
                                                                                                <option value="5">Level 5</option>
                                                                                                <option value="6">Level 6</option>
                                                                                                <option value="7">Level 7</option>
                                                                                                <option value="8">Level 8</option>
                                                                                                <option value="9">Level 9</option>
                                                                                                <option value="10">Level 10(High)</option>

                                                                                            </select> */}


                                                                                    <Select className='form-control' placeholder='Enter User Level'
                                                                                        allowClear={true}
                                                                                        value={(compfieVendorValue.user_level) && (tabValue.compfie_vendor == true) ? compfieVendorValue.user_level : undefined} style={{ marginTop: "5px", width: '100%' }}
                                                                                        onChange={(data) => {
                                                                                            setActiveUnit(false)
                                                                                            if (data !== undefined) {
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    user_level: data
                                                                                                })
                                                                                            }
                                                                                            else {
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    user_level: ""
                                                                                                })
                                                                                            }
                                                                                        }}>
                                                                                        <Option value="1">Level 1 (Low)</Option>
                                                                                        <Option value="2">Level 2</Option>
                                                                                        <Option value="3">Level 3</Option>
                                                                                        <Option value="4">Level 4</Option>
                                                                                        <Option value="5">Level 5</Option>
                                                                                        <Option value="6">Level 6</Option>
                                                                                        <Option value="7">Level 7</Option>
                                                                                        <Option value="8">Level 8</Option>
                                                                                        <Option value="9">Level 9</Option>
                                                                                        <Option value="10">Level 10 (High)</Option>
                                                                                    </Select>
                                                                                    {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                        'user_level',
                                                                                        compfieVendorValue.user_level,
                                                                                        ['required'],
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'User Level Required',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}
                                                                                </div>
                                                                            </div> : ''}


                                                                            {category.compfie_vendor == '6' || category.compfie == '6' ?

                                                                                <div className="col-md-4">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor=""><b>Service Provider</b></label> <span style={{ "color": "red" }}>*</span> <br />
                                                                                        <Select
                                                                                            // showSearch
                                                                                            // optionFilterProp="children"
                                                                                            // disabled={unit.legal_entity === "" ? true : false}
                                                                                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                            allowClear={true}
                                                                                            size="default"
                                                                                            placeholder="Enter Service Provider"
                                                                                            // onChange={categoryOnChange}
                                                                                            onChange={(data) => {
                                                                                                setActiveUnit(false)
                                                                                                if (data !== undefined) {
                                                                                                    setCompfieVendorValue({
                                                                                                        ...compfieVendorValue,
                                                                                                        serviceProvider: data
                                                                                                    })
                                                                                                }
                                                                                                else {
                                                                                                    setCompfieVendorValue({
                                                                                                        ...compfieVendorValue,
                                                                                                        serviceProvider: ""
                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                            value={tabValue.compfie_vendor == true ? compfieVendorValue.serviceProvider : undefined}
                                                                                            style={{ width: '100%', marginTop: '5px' }}
                                                                                            disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                        >
                                                                                            {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                                return (
                                                                                                    <Option key={item.u_sp_id}>
                                                                                                        {item.u_sp_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            })}
                                                                                        </Select>
                                                                                        {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                            'serviceProvider',
                                                                                            compfieVendorValue.serviceProvider,
                                                                                            ['required'],
                                                                                            {
                                                                                                className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Service Provider Required',
                                                                                                    // regex: 'Please enter valid mobile number'
                                                                                                }
                                                                                            })) : ""}
                                                                                    </div>
                                                                                </div>

                                                                                : ''}
                                                                        </div>
                                                                    </div>
                                                                    {/* <Divider /> */}
                                                                    <h5 style={{ marginTop: "15px" }}>Settings</h5>

                                                                    <div className="col-md-12" >
                                                                        <div className="row">
                                                                            <div className="col-md-4" style={{ marginBottom: "5px" }}>
                                                                                <label htmlFor=""><b>Business Group</b></label><br />
                                                                                {vendorbusinessGroupValue.length > 0 ?
                                                                                    <Tooltip title={vendorBgTooltip}>
                                                                                        <Select
                                                                                            disabled={compfieVendorValue.category == null ? true : false}
                                                                                            style={{ marginTop: '5px' }}
                                                                                            name="units"

                                                                                            id="units"
                                                                                            className='form-control'
                                                                                            placeholder="Enter Business Group"
                                                                                            onDeselect={(e, value) => {
                                                                                                setActiveUnit(false)
                                                                                                if (AddType == 'edit') {
                                                                                                    console.log(e, 'eeeeeeeeeee');
                                                                                                    let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { bg_id: Number(e) })
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesLE",
                                                                                                                {
                                                                                                                    "le_ids": [data && data.le_id],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "3"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                }
                                                                                            }}
                                                                                            onChange={(option, data) => vendorbusinessGroupHandelChange(option, data)}
                                                                                            // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                            mode="multiple"
                                                                                            value={tabValue.compfie_vendor == true ? vendorbusinessGroupValue : undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {vendorbusinessGroupData && vendorbusinessGroupData.length > 1 ?
                                                                                                <>
                                                                                                    <Option key="all" value="all" style={{ display: vendorbusinessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: vendorbusinessGroupUnselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}


                                                                                            {vendorbusinessGroupData && vendorbusinessGroupData.length > 0 &&
                                                                                                vendorbusinessGroupData.map((item) => {
                                                                                                    let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                    return (

                                                                                                        <Option key={item.bg_id}>
                                                                                                            {item.bg_name}
                                                                                                        </Option>

                                                                                                    )

                                                                                                }

                                                                                                )}
                                                                                        </Select>
                                                                                    </Tooltip> :
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        placeholder="Enter Business Group"
                                                                                        onDeselect={(e, value) => {
                                                                                            setActiveUnit(false)
                                                                                            if (AddType == 'edit') {
                                                                                                console.log(e, 'eeeeeeeeeee');
                                                                                                let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_entity, { bg_id: Number(e) })
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_ids": [data && data.le_id],
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "3"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                            }
                                                                                        }}
                                                                                        onChange={(option, data) => vendorbusinessGroupHandelChange(option, data)}
                                                                                        disabled={compfieVendorValue.category == null ? true : false}
                                                                                        // disabled={domainValue && domainValue.length <= 0 ? true : false}
                                                                                        mode="multiple"
                                                                                        value={tabValue.compfie_vendor == true ? vendorbusinessGroupValue : undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {vendorbusinessGroupData && vendorbusinessGroupData.length > 1 ?
                                                                                            <>
                                                                                                <Option key="all" value="all" style={{ display: vendorbusinessGroupselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: vendorbusinessGroupUnselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}


                                                                                        {vendorbusinessGroupData && vendorbusinessGroupData.length > 0 &&
                                                                                            vendorbusinessGroupData.map((item) => {
                                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <Option key={item.bg_id}>
                                                                                                        {item.bg_name}
                                                                                                    </Option>

                                                                                                )

                                                                                            }

                                                                                            )}
                                                                                    </Select>}
                                                                                {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                            </div>

                                                                            <div className="col-md-4">
                                                                                <label htmlFor=""><b>Legal Entity</b> <span style={{ "color": "red" }}>*</span></label> <br />
                                                                                {/* <Select
                                                                                        disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={false}
                                                                                        size="default"
                                                                                        placeholder="Enter Unit"

                                                                                        onChange={(data) => {
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                legal_Entity: data
                                                                                            })

                                                                                            let division = _.filter(userManagementDetails && userManagementDetails.um_cmpf_group_division, { le_id: data })
                                                                                            setDivision(division)
                                                                                            let domain = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: data })
                                                                                            setDomain(domain)
                                                                                        }}
                                                                                        value={compfieVendorValue.legal_Entity}
                                                                                        style={{ width: '65%' }}
                                                                                    >
                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}

                                                                                {/* <Select
                                                                                 
                                                                                        allowClear={false}
                                                                                        size="default"
                                                                                        placeholder="Enter Entity"
                                                                                        mode="multiple"
                                                                                        onChange={(data) => handledChangeVendor(data)}
                                                                                        defaultValue={compfieVendorValue.legal_Entity}
                                                                                        disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                      
                                                                                        onChange={(data) => handledChangeVendor(data)
                                                                                        }
                                                                                        
                                                                                        style={{ width: '65%' }}
                                                                                    >

                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}
                                                                                {/* <MultiSelect
                                                                                    style={{ width: '100%', marginTop: '5px' }}
                                                                                    // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                    options={VendorEntityOption}
                                                                                    value={VendorEntity}
                                                                                    onChange={setVendorEntity}
                                                                                    labelledBy="Enter Legal Entity"
                                                                                    disabled={tabValue.compfie_vendor == false ? true : false}

                                                                                /> */}
                                                                                {vendoreEntityValue.length > 0 && compfieVendorValue.category !== null ?
                                                                                    <Tooltip title={vendorEntityTooltip}>
                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={compfieVendorValue.category == null ? true : false}
                                                                                            placeholder='Enter Legal Entity'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            onDeselect={(e) => {
                                                                                                setActiveUnit(false)
                                                                                                if (AddType == 'edit') {
                                                                                                    console.log(e, 'eeeeeeeeeee');
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesLE",
                                                                                                                {
                                                                                                                    "le_ids": [e],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "3"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                    if (vendoreEntityValue && vendoreEntityValue.value.length == 0) {
                                                                                                        setvendorDomainValue([])
                                                                                                    }
                                                                                                }

                                                                                            }}
                                                                                            className='form-control'
                                                                                            onChange={(option) => vendorEntityHandelChange(option)}
                                                                                            mode="multiple"
                                                                                            value={tabValue.compfie_vendor == true ? vendoreEntityValue : undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {vendorEntityData && vendorEntityData.length > 1 ? <>
                                                                                                <Option key="all" value="all" style={{ display: VendorEntityselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: VendorEntityunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                            </> : false}
                                                                                            {/* 
                                                                                {vendorEntityData && vendorEntityData.length > 0 && vendorEntityData.map((item, i) => {
                                                                                    if (vendorbusinessGroupData && vendorbusinessGroupData.length > 0) {
                                                                                        let data = _.find(vendorbusinessGroupData, { bg_id: item.bg_id })
                                                                                        return (
                                                                                            <OptGroup label={data && data.bg_name}>
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            </OptGroup>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Option key={item.le_id}>
                                                                                                {item.le_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }

                                                                                })} */}


                                                                                            {vendorEntityList && vendorEntityList.length > 0 ?
                                                                                                vendorEntityList && vendorEntityList.length > 0 &&
                                                                                                vendorEntityList.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                }) : <> {vendorEntityData && vendorEntityData.length > 0 ? <OptGroup label="Others">
                                                                                                </OptGroup> : false}{vendorEntityData && vendorEntityData.map((item, i) => {
                                                                                                    return (

                                                                                                        <Option key={item.le_id}>
                                                                                                            {item.le_name}
                                                                                                        </Option>

                                                                                                    );
                                                                                                })}</>}




                                                                                        </Select>
                                                                                    </Tooltip> :
                                                                                    <Select
                                                                                        style={{ marginTop: '5px' }}
                                                                                        disabled={compfieVendorValue.category == null ? true : false}
                                                                                        placeholder='Enter Legal Entity'
                                                                                        name="units"

                                                                                        id="units"
                                                                                        onDeselect={(e) => {
                                                                                            setActiveUnit(false)
                                                                                            if (AddType == 'edit') {
                                                                                                console.log(e, 'eeeeeeeeeee');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesLE",
                                                                                                            {
                                                                                                                "le_ids": [e],
                                                                                                                "user_id": details.user_id,
                                                                                                                "product_id": "3"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })
                                                                                                if (vendoreEntityValue && vendoreEntityValue.value.length == 0) {
                                                                                                    setvendorDomainValue([])
                                                                                                }
                                                                                            }

                                                                                        }}
                                                                                        className='form-control'
                                                                                        onChange={(option) => vendorEntityHandelChange(option)}
                                                                                        mode="multiple"
                                                                                        value={tabValue.compfie_vendor == true ? vendoreEntityValue : undefined}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {vendorEntityData && vendorEntityData.length > 1 ? <>
                                                                                            <Option key="all" value="all" style={{ display: VendorEntityselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: VendorEntityunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                        </> : false}
                                                                                        {/* 
                                                                                {vendorEntityData && vendorEntityData.length > 0 && vendorEntityData.map((item, i) => {
                                                                                    if (vendorbusinessGroupData && vendorbusinessGroupData.length > 0) {
                                                                                        let data = _.find(vendorbusinessGroupData, { bg_id: item.bg_id })
                                                                                        return (
                                                                                            <OptGroup label={data && data.bg_name}>
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            </OptGroup>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Option key={item.le_id}>
                                                                                                {item.le_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }

                                                                                })} */}


                                                                                        {vendorEntityList && vendorEntityList.length > 0 ?
                                                                                            vendorEntityList && vendorEntityList.length > 0 &&
                                                                                            vendorEntityList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.le_id}>{subUnitData.le_name}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            }) : <> {vendorEntityData && vendorEntityData.length > 0 ? <OptGroup label="Others">
                                                                                            </OptGroup> : false}{vendorEntityData && vendorEntityData.map((item, i) => {
                                                                                                return (

                                                                                                    <Option key={item.le_id}>
                                                                                                        {item.le_name}
                                                                                                    </Option>

                                                                                                );
                                                                                            })}</>}




                                                                                    </Select>}
                                                                                {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                    'legal_Entity',
                                                                                    vendoreEntityValue,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Legal Entity Required',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                                {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                            </div>



                                                                            {category.compfie_vendor == '5' || category.compfie_vendor == '4' || category.compfie_vendor == '6' || category.compfie_vendor == '2' || category.compfie_vendor == '7' ?

                                                                                <div className="col-md-4">
                                                                                    <label htmlFor=""><b>Domain</b> <span style={{ "color": "red" }}>*</span></label><br />

                                                                                    {vendordomainValue.length > 0 && tabValue.compfie_vendor == true ?
                                                                                        <Tooltip title={vendorDomainTooltip}>
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                disabled={vendoreEntityValue.length <= 0 ? true : false}
                                                                                                placeholder='Enter Domain'
                                                                                                name="units"
                                                                                                id="units"
                                                                                                className='form-control'
                                                                                                onDeselect={(e) => {
                                                                                                    setActiveUnit(false)
                                                                                                    let splitData = e.split("-")
                                                                                                    console.log(splitData, 'splitData');
                                                                                                    if (AddType == 'edit') {
                                                                                                        const Editpayload = [
                                                                                                            authtoken,
                                                                                                            {
                                                                                                                "session_token": authtoken,
                                                                                                                "request": [
                                                                                                                    "HaveCompliancesDomain",
                                                                                                                    {
                                                                                                                        "le_ids": [splitData && splitData.length > 0 && splitData[0]],
                                                                                                                        "user_id": details.user_id,
                                                                                                                        "product_id": "3",
                                                                                                                        "d_ids": [Number(splitData && splitData.length > 0 && splitData[1])],



                                                                                                                    }
                                                                                                                ]

                                                                                                            }
                                                                                                        ]
                                                                                                        selectHaveCompliance({
                                                                                                            payload: Editpayload,
                                                                                                            paramid: paramid,

                                                                                                        })
                                                                                                    }
                                                                                                    console.log(e, 'eeeeeeeeeee');

                                                                                                }}
                                                                                                onChange={(option) => vendorDoaminHandelChange(option)}
                                                                                                mode="multiple"
                                                                                                value={((vendoreEntityValue && vendoreEntityValue.length > 0) && (tabValue.compfie_vendor == true)) ? vendordomainValue : undefined}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {VendorDomainData && VendorDomainData.length > 1 ? <> <Option key="all" value="all" style={{ display: vendordomianselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: Vendordomainunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                                {/* {VendorDomainData && VendorDomainData.length > 0 && VendorDomainData.map((item, i) => {
                                                                                            if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                                let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <OptGroup label={data && data.le_name}>
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    </OptGroup>
                                                                                                );
                                                                                            }
                                                                                            else {
                                                                                                return (

                                                                                                    <Option key={item.u_dm_id}>
                                                                                                        {item.u_dm_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            }

                                                                                        })} */}
                                                                                                {VendorDomainList && VendorDomainList.length > 0 &&
                                                                                                    VendorDomainList.map((finalList_unitlist) => {
                                                                                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                            return (
                                                                                                                <OptGroup label={finalList_unitlist.label}>
                                                                                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                        <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                                    ))}
                                                                                                                </OptGroup>
                                                                                                            )
                                                                                                    })}
                                                                                            </Select>
                                                                                        </Tooltip> : <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={vendoreEntityValue.length <= 0 ? true : false}
                                                                                            placeholder='Enter Domain'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            className='form-control'
                                                                                            onDeselect={(e) => {
                                                                                                setActiveUnit(false)
                                                                                                let splitData = e.split("-")
                                                                                                console.log(splitData, 'splitData');
                                                                                                if (AddType == 'edit') {
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesDomain",
                                                                                                                {
                                                                                                                    "le_ids": [splitData && splitData.length > 0 && splitData[0]],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "product_id": "3",
                                                                                                                    "d_ids": [Number(splitData && splitData.length > 0 && splitData[1])],



                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })
                                                                                                }
                                                                                                console.log(e, 'eeeeeeeeeee');

                                                                                            }}
                                                                                            onChange={(option) => vendorDoaminHandelChange(option)}
                                                                                            mode="multiple"
                                                                                            value={((vendoreEntityValue && vendoreEntityValue.length > 0) && (tabValue.compfie_vendor == true)) ? vendordomainValue : undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {VendorDomainData && VendorDomainData.length > 1 ? <> <Option key="all" value="all" style={{ display: vendordomianselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                <Option key="unselect" value="unselect" style={{ display: Vendordomainunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                            {/* {VendorDomainData && VendorDomainData.length > 0 && VendorDomainData.map((item, i) => {
                                                                                            if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                                let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                                return (

                                                                                                    <OptGroup label={data && data.le_name}>
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    </OptGroup>
                                                                                                );
                                                                                            }
                                                                                            else {
                                                                                                return (

                                                                                                    <Option key={item.u_dm_id}>
                                                                                                        {item.u_dm_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            }

                                                                                        })} */}
                                                                                            {VendorDomainList && VendorDomainList.length > 0 &&
                                                                                                VendorDomainList.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={`${subUnitData.le_id}-${subUnitData.u_dm_id}`}>{subUnitData.u_dm_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>}
                                                                                    {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                        'domain',
                                                                                        vendordomainValue,
                                                                                        ['required'],
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Domain Required',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}
                                                                                </div>
                                                                                : ''}
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="row">


                                                                            {category.compfie_vendor == '5' || category.compfie_vendor == '6' ?
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor=""><b>Division</b>  </label><br />
                                                                                    {/* <select name="cars" className='form-control' id="cars">
                                                                                <option value="volvo">None Selected</option>
                                                                            </select> */}
                                                                                    {/* <Select
                                                                                              
                                                                                                allowClear={false}
                                                                                                size="default"
                                                                                                placeholder="Enter Unit"
                                                                                                onChange={(data) => handleChanged(data)
                                                                                                }
                                                                                                mode='multiple'
                                                                                                defaultValue={compfieVendorValue.Domain}

                                                                                                style={{ width: '65%' }}
                                                                                            >
                                                                                                {domain && domain.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}
                                                                                    {vendorDivisionValue.length > 0 ?
                                                                                        <Tooltip title={vendordivisionTooltip}>
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                name="units"
                                                                                                id="units"
                                                                                                className='form-control'
                                                                                                placeholder="Enter Division"
                                                                                                disabled={vendoreEntityValue && vendoreEntityValue.length <= 0 ? true : false}
                                                                                                onChange={(option) => VendordivisionHandelChange(option)}
                                                                                                mode="multiple"
                                                                                                onDeselect={(e) => {
                                                                                                    setActiveUnit(false)
                                                                                                    if (AddType == 'edit') {
                                                                                                        let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_units
                                                                                                            , { d_id: e })
                                                                                                        console.log(data, 'eeeeeeeeeee');
                                                                                                        const Editpayload = [
                                                                                                            authtoken,
                                                                                                            {
                                                                                                                "session_token": authtoken,
                                                                                                                "request": [
                                                                                                                    "HaveCompliancesMultiUnits",
                                                                                                                    {
                                                                                                                        "le_ids": [data.le_id],
                                                                                                                        "user_id": details.user_id,
                                                                                                                        "unit_ids": [data.u_unt_id],
                                                                                                                        "product_id": "3"
                                                                                                                    }
                                                                                                                ]

                                                                                                            }
                                                                                                        ]
                                                                                                        selectHaveCompliance({
                                                                                                            payload: Editpayload,
                                                                                                            paramid: paramid,

                                                                                                        })

                                                                                                    }

                                                                                                }}
                                                                                                value={tabValue.compfie_vendor == true ? vendorDivisionValue : undefined}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {vendordivisionData && vendordivisionData.length > 1 ?
                                                                                                    <>
                                                                                                        <Option key="all" value="all" style={{ display: vendordivisionselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                        <Option key="unselect" value="unselect" style={{ display: vendordivisionunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                    </> : false}



                                                                                                {vendorListData && vendorListData.length > 0 &&
                                                                                                    vendorListData.map((finalList_unitlist) => {
                                                                                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                            return (
                                                                                                                <OptGroup label={finalList_unitlist.label}>
                                                                                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                        <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                                                                                    ))}
                                                                                                                </OptGroup>
                                                                                                            )
                                                                                                    })}
                                                                                            </Select>
                                                                                        </Tooltip> : <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            name="units"
                                                                                            id="units"
                                                                                            className='form-control'
                                                                                            placeholder="Enter Division"
                                                                                            disabled={vendoreEntityValue && vendoreEntityValue.length <= 0 ? true : false}
                                                                                            onChange={(option) => VendordivisionHandelChange(option)}
                                                                                            mode="multiple"
                                                                                            onDeselect={(e) => {
                                                                                                setActiveUnit(false)
                                                                                                if (AddType == 'edit') {
                                                                                                    let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_units
                                                                                                        , { d_id: e })
                                                                                                    console.log(data, 'eeeeeeeeeee');
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesMultiUnits",
                                                                                                                {
                                                                                                                    "le_ids": [data.le_id],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "unit_ids": [data.u_unt_id],
                                                                                                                    "product_id": "3"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })

                                                                                                }

                                                                                            }}
                                                                                            value={tabValue.compfie_vendor == true ? vendorDivisionValue : undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {vendordivisionData && vendordivisionData.length > 1 ?
                                                                                                <>
                                                                                                    <Option key="all" value="all" style={{ display: vendordivisionselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: vendordivisionunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}


                                                                                            {vendorListData && vendorListData.length > 0 &&
                                                                                                vendorListData.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>}

                                                                                </div> : ""}
                                                                            {category.compfie_vendor == '5' || category.compfie_vendor == '6' ?
                                                                                <div className="col-md-4">
                                                                                    <label htmlFor=""><b>Category</b></label><br />
                                                                                    {/* <Select
                                                                                               
                                                                                                allowClear={false}
                                                                                                size="default"
                                                                                                placeholder="Enter Unit"

                                                                                                onChange={(data) => {
                                                                                                    setCompfieVendorValue({
                                                                                                        ...compfieVendorValue,
                                                                                                        categoryValue: data
                                                                                                    })
                                                                                                }}
                                                                                                value={compfieVendorValue.categoryValue}
                                                                                                style={{ width: '65%' }}
                                                                                                disabled={AddType == 'edit' ? true : false}
                                                                                            >
                                                                                                {categories && categories.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.cat_id}>
                                                                                                            {item.cat_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}
                                                                                    {VendorcategoryValues.length > 0 ?
                                                                                        <Tooltip title={vendorcatTooltip}>
                                                                                            <Select
                                                                                                style={{ marginTop: '5px' }}
                                                                                                disabled={vendorDivisionValue && vendorDivisionValue.length > 0 ? false : true}
                                                                                                placeholder='Enter Category'
                                                                                                name="units"
                                                                                                id="units"
                                                                                                onDeselect={(e) => {
                                                                                                    setActiveUnit(false)
                                                                                                    if (AddType == 'edit') {
                                                                                                        let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_units

                                                                                                            , { cat_id: e })
                                                                                                        console.log(data, 'eeeeeeeeeee');
                                                                                                        const Editpayload = [
                                                                                                            authtoken,
                                                                                                            {
                                                                                                                "session_token": authtoken,
                                                                                                                "request": [
                                                                                                                    "HaveCompliancesMultiUnits",
                                                                                                                    {
                                                                                                                        "le_ids": [data.le_id],
                                                                                                                        "user_id": details.user_id,
                                                                                                                        "unit_ids": [data.u_unt_id],
                                                                                                                        "product_id": "3"
                                                                                                                    }
                                                                                                                ]

                                                                                                            }
                                                                                                        ]
                                                                                                        selectHaveCompliance({
                                                                                                            payload: Editpayload,
                                                                                                            paramid: paramid,

                                                                                                        })

                                                                                                    }

                                                                                                }}
                                                                                                className='form-control'
                                                                                                onChange={(option) => vendorCategoryHandelChange(option)}
                                                                                                mode="multiple"
                                                                                                value={tabValue.compfie_vendor == true ? VendorcategoryValues : undefined}
                                                                                                maxTagCount='responsive'
                                                                                            >
                                                                                                {vendorcategoryDatas && vendorcategoryDatas.length > 1 ?
                                                                                                    <>
                                                                                                        <Option key="all" value="all" style={{ display: vendorcategoryselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                        <Option key="unselect" value="unselect" style={{ display: vendorcategoryunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                    </> : false}

                                                                                                {/* {categoryDatas && categoryDatas.length > 0 && categoryDatas.map((item, i) => {
                                                                                            if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                                let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                                return (
                                                                                                    <OptGroup label={data && data.le_name}>
                                                                                                        <Option key={item.cat_id}>
                                                                                                            {item.cat_name}
                                                                                                        </Option>
                                                                                                    </OptGroup>
                                                                                                );
                                                                                            }
                                                                                            else {
                                                                                                return (
                                                                                                    <Option key={item.cat_id}>
                                                                                                        {item.cat_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            }

                                                                                        })} */}
                                                                                                {vendorcategoryList && vendorcategoryList.length > 0 &&
                                                                                                    vendorcategoryList.map((finalList_unitlist) => {
                                                                                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                            return (
                                                                                                                <OptGroup label={finalList_unitlist.label}>
                                                                                                                    {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                        <Option value={subUnitData.cat_id}>{subUnitData.cat_name}</Option>

                                                                                                                    ))}
                                                                                                                </OptGroup>
                                                                                                            )
                                                                                                    })}
                                                                                            </Select>
                                                                                        </Tooltip> :
                                                                                        <Select
                                                                                            style={{ marginTop: '5px' }}
                                                                                            disabled={vendorDivisionValue && vendorDivisionValue.length > 0 ? false : true}
                                                                                            placeholder='Enter Category'
                                                                                            name="units"
                                                                                            id="units"
                                                                                            onDeselect={(e) => {
                                                                                                setActiveUnit(false)
                                                                                                if (AddType == 'edit') {
                                                                                                    let data = _.find(userManagementDetails && userManagementDetails.um_vndr_legal_units

                                                                                                        , { cat_id: e })
                                                                                                    console.log(data, 'eeeeeeeeeee');
                                                                                                    const Editpayload = [
                                                                                                        authtoken,
                                                                                                        {
                                                                                                            "session_token": authtoken,
                                                                                                            "request": [
                                                                                                                "HaveCompliancesMultiUnits",
                                                                                                                {
                                                                                                                    "le_ids": [data.le_id],
                                                                                                                    "user_id": details.user_id,
                                                                                                                    "unit_ids": [data.u_unt_id],
                                                                                                                    "product_id": "3"
                                                                                                                }
                                                                                                            ]

                                                                                                        }
                                                                                                    ]
                                                                                                    selectHaveCompliance({
                                                                                                        payload: Editpayload,
                                                                                                        paramid: paramid,

                                                                                                    })

                                                                                                }

                                                                                            }}
                                                                                            className='form-control'
                                                                                            onChange={(option) => vendorCategoryHandelChange(option)}
                                                                                            mode="multiple"
                                                                                            value={tabValue.compfie_vendor == true ? VendorcategoryValues : undefined}
                                                                                            maxTagCount='responsive'
                                                                                        >
                                                                                            {vendorcategoryDatas && vendorcategoryDatas.length > 1 ?
                                                                                                <>
                                                                                                    <Option key="all" value="all" style={{ display: vendorcategoryselectcheckbox }}>---SELECT ALL---</Option>
                                                                                                    <Option key="unselect" value="unselect" style={{ display: vendorcategoryunselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                                                </> : false}

                                                                                            {/* {categoryDatas && categoryDatas.length > 0 && categoryDatas.map((item, i) => {
                                                                                    if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                        let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                        return (
                                                                                            <OptGroup label={data && data.le_name}>
                                                                                                <Option key={item.cat_id}>
                                                                                                    {item.cat_name}
                                                                                                </Option>
                                                                                            </OptGroup>
                                                                                        );
                                                                                    }
                                                                                    else {
                                                                                        return (
                                                                                            <Option key={item.cat_id}>
                                                                                                {item.cat_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }

                                                                                })} */}
                                                                                            {vendorcategoryList && vendorcategoryList.length > 0 &&
                                                                                                vendorcategoryList.map((finalList_unitlist) => {
                                                                                                    if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist && finalList_unitlist.options.length > 0)
                                                                                                        return (
                                                                                                            <OptGroup label={finalList_unitlist.label}>
                                                                                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                    <Option value={subUnitData.cat_id}>{subUnitData.cat_name}</Option>

                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        )
                                                                                                })}
                                                                                        </Select>}
                                                                                    {/* <MultiSelect

                                                                                            // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                            options={categoryoptions}
                                                                                            value={compfieCategory}
                                                                                            onChange={setcompfieCategory}
                                                                                            labelledBy="Select"
                                                                                            style={{ width: '100%', marginTop: '5px' }}
                                                                                        /> */}
                                                                                </div>
                                                                                : ''}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        {category.compfie_vendor == '2' || category.compfie_vendor == '7' ?
                                                                            <div className="col-md-6">
                                                                                <div className="row">
                                                                                    <div className="col-md-5 d-flex mt-2">
                                                                                        <label htmlFor=""><b>All Vendor Accessible</b></label>
                                                                                        <input type="checkbox" name="" id="" style={{ width: "40px", height: "20px" }}
                                                                                            checked={vendorAccess == true ? true : false}
                                                                                            onChange={(e) => {
                                                                                                setActiveUnit(false)
                                                                                                if ((e.target.checked == true)) {
                                                                                                    let temp = []
                                                                                                    setVendorAccess(true)
                                                                                                    console.log(userManagementDetails && userManagementDetails.um_vndr_legal_units.length, 'userManagementDetails && userManagementDetails.um_legal_units.length ');
                                                                                                    if (userManagementDetails && userManagementDetails.um_vndr_legal_units.length > 0) {
                                                                                                        for (let i in userManagementDetails && userManagementDetails.um_vndr_legal_units) {
                                                                                                            temp.push({
                                                                                                                le_id: userManagementDetails && userManagementDetails.um_vndr_legal_units[i].le_id,
                                                                                                                u_id: userManagementDetails && userManagementDetails.um_vndr_legal_units[i].u_unt_id
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                    setVendorUnitDetails(temp)
                                                                                                }
                                                                                                else {
                                                                                                    setVendorAccess(false)
                                                                                                    setVendorUnitDetails([])
                                                                                                    setVendorAll([])
                                                                                                    setVendorClosedAll([])
                                                                                                    // setAllUnitData([])
                                                                                                }
                                                                                            }}

                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                {/* </div> */}

                                                                            </div> : false}
                                                                    </div>
                                                                </TabPane> : ''}

                                                        </Tabs>
                                                    </div>


                                                    {unitAccess == true || compfie.category == 3 ||
                                                        (vendorAccess == true && tabValue.compfie_vendor == true)
                                                        // || compfie.category == 7 || 
                                                        // compfie.category == "View Only Restricted" 
                                                        || compfie.category == "Legal Entity Admin" || compfie.category == "View Only" ? <a href={() => false} style={{ marginLeft: '90%', marginTop: '40%' }} >
                                                        {/* <Button   shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'left' }}
                                                            icon={<ArrowLeftOutlined />} size='default' onClick={previous}>
                                                            Previous
                                                        </Button>
                                                        <button style={{ float: "left" }} className="usrbutton button--anthe prev" id={localStorage.getItem('currentTheme')} onClick={previous}><span style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} class="fs-3 fw-bold">Back</span></button> */}

                                                        <Button shape="round" className='addbutton' style={{ background: "#198754", float: "right", borderColor: "#198754", marginTop: '2%' }}
                                                            icon={<PlayCircleOutlined />} size='default' onClick={userDetailsOnSubmit
                                                            }>
                                                            Submit
                                                        </Button>
                                                        {/* <div class="button2" style={{ float: 'right' }} id={localStorage.getItem('currentTheme')} onClick={userDetailsOnSubmit}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            Submit
                                        </div> */}
                                                        {/* <button onClick={userDetailsOnSubmit} className="noselect viewicon-button addbutton" style={{ float: 'right', backgroundColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }} ><span className='text'>Submit</span>
                                                            <span style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} className="icon"><PlayCircleOutlined />
                                                            </span>
                                                        </button> */}

                                                    </a> :
                                                        <>

                                                            {/* <Button   shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", float: 'left' }}
                                                                icon={<ArrowLeftOutlined />} size='default' onClick={previous}>
                                                                Previous
                                                            </Button> */}
                                                            {/* <a href={() => false} style={{ marginLeft: '90%' }}> */}
                                                            <div>
                                                                {tabValue.compfie_litigation == true && tabValue.compfie == false && tabValue.compfie_vendor == false ?
                                                                    <Button shape="round" className='addbutton'
                                                                        style={{ background: "#198754", borderColor: "#198754", marginTop: '2%', float: 'right', marginLeft: '33%' }}
                                                                        icon={<PlayCircleOutlined />} size='default'
                                                                        onClick={userDetailsSaved1}
                                                                    >Submit
                                                                    </Button> :
                                                                    <Button shape="round" className='addbutton' style={{ background: "#0096FF", float: "right", marginTop: '2%' }} key={unitAccess}
                                                                        size='default' onClick={userDetailOnSubmit}>
                                                                        Next <ArrowRightOutlined />
                                                                    </Button>}
                                                            </div>

                                                            {/* <button style={{float: "right" }} className="button button--anthe"><span style={{color:'white'}}>Next</span></button> */}
                                                            {/* <div id={localStorage.getItem('currentTheme')}>
                                                                <button style={{ float: "left" }} className="usrbutton button--anthe prev" onClick={previous}><span style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} class="fs-3 fw-bold">Back</span></button>
                                                                <button style={{ float: "right" }} className="usrbutton button--anthe" onClick={userDetailOnSubmit}><span style={{ color: localStorage.getItem("currentTheme") == 'theme_four' ? 'black' : 'white' }} class="fs-3 fw-bold">Next</span></button>
                                                            </div> */}
                                                            {/* </a> */}
                                                        </>}

                                                </TabPane>
                                                <TabPane tab="Select Units"
                                                    disabled={selectUnit === true ? false : true}
                                                    key="2">
                                                    <Tabs defaultActiveKey="1" type="card" onChange={onChange}>
                                                        {tabValue.compfie == true ?
                                                            <TabPane tab=" Compfie" key="1">
                                                                {/* <MultiSelect
                                                                    options={options}
                                                                    value={selected}
                                                                    onChange={setSelected}
                                                                    labelledBy="Select"
                                                                    style={{ width: '100px' }}

                                                                /><br /> */}
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label><b>Units</b></label>
                                                                            {all && all.length > 0 ?


                                                                                <Tooltip title={unitTooltip}>
                                                                                    <Select
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        onChange={(option, data) => HandelChange(option, data)}
                                                                                        mode="multiple"
                                                                                        optionFilterProp="children"
                                                                                        // allowClear
                                                                                        placeholder="Enter Units"
                                                                                        showSearch
                                                                                        // allowClear={true}
                                                                                        // filterOption={(inputValue, option) =>
                                                                                        //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                                        // }
                                                                                        filterOption={(input, option) =>

                                                                                            // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                                            option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                                        }
                                                                                        value={all}
                                                                                        onDeselect={(e) => {
                                                                                            if (AddType == 'edit') {
                                                                                                let data = _.find(selectUnitData, { u_unt_id: e })
                                                                                                console.log(data, 'eeeeeeeeeee');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesUnit",
                                                                                                            {
                                                                                                                "le_ids": [data.le_id],
                                                                                                                "user_id": details.user_id,
                                                                                                                "le_unit_ids": [
                                                                                                                    {
                                                                                                                        "le_id": data.le_id,
                                                                                                                        "unit_ids_str": `${e}`
                                                                                                                    }
                                                                                                                ],
                                                                                                                "product_id": "1"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                console.log(Editpayload, 'Editpayloaddddd');
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })

                                                                                            }

                                                                                        }}
                                                                                        // defaultValue={selected}
                                                                                        // key={selected}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {selectUnitData && selectUnitData.length > 1 ? <> <Option key="all" value="all" style={{ display: unitselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: unitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                        {/* {selectUnitData && selectUnitData.length > 0 &&
                                                                        selectUnitData.map((item) => {
                                                                            if (EntityData && EntityData.length > 0) {
                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                return (
                                                                                    <OptGroup label={data && data.le_name}>
                                                                                        <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                    </OptGroup>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                )
                                                                            }

                                                                        }

                                                                        )} */}
                                                                                        {selectUnitList && selectUnitList.length > 0 &&
                                                                                            selectUnitList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}


                                                                                    </Select>
                                                                                </Tooltip>


                                                                                :
                                                                                <Select
                                                                                    name="units"
                                                                                    id="units"
                                                                                    className='form-control'
                                                                                    onChange={(option, data) => HandelChange(option, data)}
                                                                                    mode="multiple"
                                                                                    optionFilterProp="children"
                                                                                    // allowClear
                                                                                    placeholder="Enter Units"
                                                                                    showSearch
                                                                                    // allowClear={true}
                                                                                    // filterOption={(inputValue, option) =>
                                                                                    //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                                    // }
                                                                                    filterOption={(input, option) =>

                                                                                        // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                                        option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                                    }
                                                                                    value={all}
                                                                                    onDeselect={(e) => {
                                                                                        if (AddType == 'edit') {
                                                                                            let data = _.find(selectUnitData, { u_unt_id: e })
                                                                                            console.log(data, 'eeeeeeeeeee');
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesUnit",
                                                                                                        {
                                                                                                            "le_ids": [data.le_id],
                                                                                                            "user_id": details.user_id,
                                                                                                            "le_unit_ids": [
                                                                                                                {
                                                                                                                    "le_id": data.le_id,
                                                                                                                    "unit_ids_str": `${e}`
                                                                                                                }
                                                                                                            ],
                                                                                                            "product_id": "1"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            console.log(Editpayload, 'Editpayloaddddd');
                                                                                            selectHaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,

                                                                                            })

                                                                                        }

                                                                                    }}
                                                                                    // defaultValue={selected}
                                                                                    // key={selected}
                                                                                    maxTagCount='responsive'
                                                                                >
                                                                                    {selectUnitData && selectUnitData.length > 1 ? <> <Option key="all" value="all" style={{ display: unitselectcheckbox }}>---SELECT ALL---</Option>
                                                                                        <Option key="unselect" value="unselect" style={{ display: unitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                    {/* {selectUnitData && selectUnitData.length > 0 &&
                                                                        selectUnitData.map((item) => {
                                                                            if (EntityData && EntityData.length > 0) {
                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                return (
                                                                                    <OptGroup label={data && data.le_name}>
                                                                                        <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                    </OptGroup>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                )
                                                                            }

                                                                        }

                                                                        )} */}
                                                                                    {selectUnitList && selectUnitList.length > 0 &&
                                                                                        selectUnitList.map((finalList_unitlist) => {
                                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                                return (
                                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                                        {finalList_unitlist.options.map((subUnitData) => (
                                                                                                            <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                                        ))}
                                                                                                    </OptGroup>
                                                                                                )
                                                                                        })}


                                                                                </Select>

                                                                            }



                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <label><b>Closed Units</b></label>
                                                                            {closedAll && closedAll.length > 0 ?
                                                                                <Tooltip title={unitClosedTooltip}>
                                                                                    <Select
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        onChange={(option) => CloseUnitHandelChange(option)}
                                                                                        optionFilterProp="children"
                                                                                        // allowClear
                                                                                        showSearch
                                                                                        placeholder="Enter Close Units"
                                                                                        // filterOption={(inputValue, option) =>
                                                                                        //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                                        // }
                                                                                        filterOption={(input, option) =>

                                                                                            // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                                            option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                                        }
                                                                                        mode="multiple" onDeselect={(e) => {
                                                                                            if (AddType == 'edit') {
                                                                                                let data = _.find(closedUnitData, { u_unt_id: e })
                                                                                                console.log(`${e}`, '`${e}`');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesUnit",
                                                                                                            {
                                                                                                                "le_ids": [data.le_id],
                                                                                                                "user_id": details.user_id,
                                                                                                                "le_unit_ids": [
                                                                                                                    {
                                                                                                                        "le_id": data.le_id,
                                                                                                                        "unit_ids_str": `${e}`
                                                                                                                    }
                                                                                                                ],
                                                                                                                "product_id": "1"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                console.log(Editpayload, 'Editpayload');
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })

                                                                                            }

                                                                                        }}

                                                                                        value={closedAll}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {closedUnitData && closedUnitData.length > 1 ? <>  <Option key="all" value="all" style={{ display: closeunitselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: closedunitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                        {/* {closedUnitData && closedUnitData.length > 0 &&
                                                                        closedUnitData.map((item) => {
                                                                            if (EntityData && EntityData.length > 0) {
                                                                                let data = _.find(EntityData, { le_id: item.le_id })
                                                                                return (
                                                                                    <OptGroup label={data && data.le_name}>
                                                                                        <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                    </OptGroup>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                )
                                                                            }
                                                                        }
                                                                        )} */}

                                                                                        {closedselectUnitList && closedselectUnitList.length > 0 &&
                                                                                            closedselectUnitList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                                    return (

                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options.length > 0 && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}
                                                                                    </Select>
                                                                                </Tooltip> :

                                                                                <Select
                                                                                    name="units"
                                                                                    id="units"
                                                                                    className='form-control'
                                                                                    onChange={(option) => CloseUnitHandelChange(option)}
                                                                                    optionFilterProp="children"
                                                                                    // allowClear
                                                                                    showSearch
                                                                                    placeholder="Enter Close Units"
                                                                                    // filterOption={(inputValue, option) =>
                                                                                    //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                                    // }
                                                                                    filterOption={(input, option) =>

                                                                                        // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                                        option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                                    }
                                                                                    mode="multiple" onDeselect={(e) => {
                                                                                        if (AddType == 'edit') {
                                                                                            let data = _.find(closedUnitData, { u_unt_id: e })
                                                                                            console.log(`${e}`, '`${e}`');
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesUnit",
                                                                                                        {
                                                                                                            "le_ids": [data.le_id],
                                                                                                            "user_id": details.user_id,
                                                                                                            "le_unit_ids": [
                                                                                                                {
                                                                                                                    "le_id": data.le_id,
                                                                                                                    "unit_ids_str": `${e}`
                                                                                                                }
                                                                                                            ],
                                                                                                            "product_id": "1"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            console.log(Editpayload, 'Editpayload');
                                                                                            selectHaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,

                                                                                            })

                                                                                        }

                                                                                    }}

                                                                                    value={closedAll}
                                                                                    maxTagCount='responsive'
                                                                                >
                                                                                    {closedUnitData && closedUnitData.length > 1 ? <>  <Option key="all" value="all" style={{ display: closeunitselectcheckbox }}>---SELECT ALL---</Option>
                                                                                        <Option key="unselect" value="unselect" style={{ display: closedunitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                    {/* {closedUnitData && closedUnitData.length > 0 &&
                                                                    closedUnitData.map((item) => {
                                                                        if (EntityData && EntityData.length > 0) {
                                                                            let data = _.find(EntityData, { le_id: item.le_id })
                                                                            return (
                                                                                <OptGroup label={data && data.le_name}>
                                                                                    <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                </OptGroup>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                            )
                                                                        }
                                                                    }
                                                                    )} */}

                                                                                    {closedselectUnitList && closedselectUnitList.length > 0 &&
                                                                                        closedselectUnitList.map((finalList_unitlist) => {
                                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                                return (

                                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                                        {finalList_unitlist.options.length > 0 && finalList_unitlist.options.map((subUnitData) => (
                                                                                                            <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                                        ))}
                                                                                                    </OptGroup>
                                                                                                )
                                                                                        })}
                                                                                </Select>}

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <h6 style={{ marginTop: '5px' }}>Closed Units</h6> */}
                                                                {/* <MultiSelect
                                                                    options={closedOption}
                                                                    value={closeselected}
                                                                    onChange={setCloseSelected}
                                                                    labelledBy="Select"

                                                                /> */}

                                                            </TabPane> : null
                                                        }                {tabValue.compfie_vendor == true ?
                                                            <TabPane tab="Compfie Vendor" key="2">

                                                                {/* <MultiSelect
                                                                    options={Vendoroptions}
                                                                    value={vendorselected}
                                                                    onChange={setvendorSelected}
                                                                    labelledBy="Select"

                                                                /><br /> */}
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label><b>Vendor</b></label>
                                                                            {vendorAll && vendorAll.length > 0 ?
                                                                                <Tooltip title={vendorTooltip}>
                                                                                    <Select
                                                                                        name="units"
                                                                                        id="units"
                                                                                        className='form-control'
                                                                                        onChange={(option) => VendorUnitHandelChange(option)}
                                                                                        mode="multiple"
                                                                                        optionFilterProp="children"
                                                                                        // allowClear
                                                                                        showSearch
                                                                                        placeholder="Enter Vendor"
                                                                                        // filterOption={(inputValue, option) =>
                                                                                        //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                                        // }
                                                                                        filterOption={(input, option) =>

                                                                                            // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                                            option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                                        }
                                                                                        onDeselect={(e) => {
                                                                                            if (AddType == 'edit') {
                                                                                                let data = _.find(vendorUnit, { u_unt_id: e })
                                                                                                console.log(data, 'eeeeeeeeeee');
                                                                                                const Editpayload = [
                                                                                                    authtoken,
                                                                                                    {
                                                                                                        "session_token": authtoken,
                                                                                                        "request": [
                                                                                                            "HaveCompliancesUnit",
                                                                                                            {
                                                                                                                "le_ids": [data.le_id],
                                                                                                                "user_id": details.user_id,
                                                                                                                "le_unit_ids": [
                                                                                                                    {
                                                                                                                        "le_id": data.le_id,
                                                                                                                        "unit_ids_str": `${e}`
                                                                                                                    }
                                                                                                                ],
                                                                                                                "product_id": "3"
                                                                                                            }
                                                                                                        ]

                                                                                                    }
                                                                                                ]
                                                                                                selectHaveCompliance({
                                                                                                    payload: Editpayload,
                                                                                                    paramid: paramid,

                                                                                                })

                                                                                            }

                                                                                        }}
                                                                                        value={vendorAll}
                                                                                        maxTagCount='responsive'
                                                                                    >
                                                                                        {vendorUnit && vendorUnit.length > 1 ? <>  <Option key="all" value="all" style={{ display: vendorunitselectcheckbox }}>---SELECT ALL---</Option>
                                                                                            <Option key="unselect" value="unselect" style={{ display: vendorunitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                        {/* {vendorUnit && vendorUnit.length > 0 &&
                                                                        vendorUnit.map((item) => {
                                                                            if (vendorEntityData && vendorEntityData.length > 0) {
                                                                                let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                                return (
                                                                                    <OptGroup label={data && data.le_name}>
                                                                                        <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                    </OptGroup>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                )
                                                                            }

                                                                        })} */}

                                                                                        {vendorUnitList && vendorUnitList.length > 0 &&
                                                                                            vendorUnitList.map((finalList_unitlist) => {
                                                                                                if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                                    return (
                                                                                                        <OptGroup label={finalList_unitlist.label}>
                                                                                                            {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                                <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                                            ))}
                                                                                                        </OptGroup>
                                                                                                    )
                                                                                            })}
                                                                                    </Select>
                                                                                </Tooltip> :
                                                                                <Select
                                                                                    name="units"
                                                                                    id="units"
                                                                                    className='form-control'
                                                                                    onChange={(option) => VendorUnitHandelChange(option)}
                                                                                    mode="multiple"
                                                                                    optionFilterProp="children"
                                                                                    // allowClear
                                                                                    showSearch
                                                                                    placeholder="Enter Vendor"
                                                                                    // filterOption={(inputValue, option) =>
                                                                                    //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                                    // }
                                                                                    filterOption={(input, option) =>

                                                                                        // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                                        option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                                    }
                                                                                    onDeselect={(e) => {
                                                                                        if (AddType == 'edit') {
                                                                                            let data = _.find(vendorUnit, { u_unt_id: e })
                                                                                            console.log(data, 'eeeeeeeeeee');
                                                                                            const Editpayload = [
                                                                                                authtoken,
                                                                                                {
                                                                                                    "session_token": authtoken,
                                                                                                    "request": [
                                                                                                        "HaveCompliancesUnit",
                                                                                                        {
                                                                                                            "le_ids": [data.le_id],
                                                                                                            "user_id": details.user_id,
                                                                                                            "le_unit_ids": [
                                                                                                                {
                                                                                                                    "le_id": data.le_id,
                                                                                                                    "unit_ids_str": `${e}`
                                                                                                                }
                                                                                                            ],
                                                                                                            "product_id": "3"
                                                                                                        }
                                                                                                    ]

                                                                                                }
                                                                                            ]
                                                                                            selectHaveCompliance({
                                                                                                payload: Editpayload,
                                                                                                paramid: paramid,

                                                                                            })

                                                                                        }

                                                                                    }}
                                                                                    value={vendorAll}
                                                                                    maxTagCount='responsive'
                                                                                >
                                                                                    {vendorUnit && vendorUnit.length > 1 ? <>  <Option key="all" value="all" style={{ display: vendorunitselectcheckbox }}>---SELECT ALL---</Option>
                                                                                        <Option key="unselect" value="unselect" style={{ display: vendorunitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                    {/* {vendorUnit && vendorUnit.length > 0 &&
                                                                      vendorUnit.map((item) => {
                                                                          if (vendorEntityData && vendorEntityData.length > 0) {
                                                                              let data = _.find(vendorEntityData, { le_id: item.le_id })
                                                                              return (
                                                                                  <OptGroup label={data && data.le_name}>
                                                                                      <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                                  </OptGroup>
                                                                              )
                                                                          }
                                                                          else {
                                                                              return (
                                                                                  <Option value={item.u_unt_id}>{item.u_unt_code + ' - ' + item.u_unt_name + ' - ' + item.u_unt_address}</Option>
                                                                              )
                                                                          }

                                                                      })} */}

                                                                                    {vendorUnitList && vendorUnitList.length > 0 &&
                                                                                        vendorUnitList.map((finalList_unitlist) => {
                                                                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                                return (
                                                                                                    <OptGroup label={finalList_unitlist.label}>
                                                                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                                                                            <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                                        ))}
                                                                                                    </OptGroup>
                                                                                                )
                                                                                        })}
                                                                                </Select>}
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label><b>Closed Vendor</b></label>
                                                                            {closedAll && closedAll.length > 0 ? <Tooltip title={vendorclosedTooltip}>  <Select
                                                                                name="units"
                                                                                id="units"
                                                                                className='form-control'
                                                                                onChange={(option) => VendorClosedUnitHandelChange(option)}
                                                                                mode="multiple"
                                                                                placeholder='Enter Closed Vendor'
                                                                                optionFilterProp="children"
                                                                                // allowClear
                                                                                showSearch
                                                                                // filterOption={(inputValue, option) =>
                                                                                //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                                // }
                                                                                filterOption={(input, option) =>

                                                                                    // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                                    option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                                }
                                                                                onDeselect={(e) => {
                                                                                    if (AddType == 'edit') {
                                                                                        let data = _.find(vendorClosedUnit, { u_unt_id: e })
                                                                                        console.log(data, 'eeeeeeeeeee');
                                                                                        const Editpayload = [
                                                                                            authtoken,
                                                                                            {
                                                                                                "session_token": authtoken,
                                                                                                "request": [
                                                                                                    "HaveCompliancesUnit",
                                                                                                    {
                                                                                                        "le_ids": [data.le_id],
                                                                                                        "user_id": details.user_id,
                                                                                                        "le_unit_ids": [
                                                                                                            {
                                                                                                                "le_id": data.le_id,
                                                                                                                "unit_ids_str": `${e}`
                                                                                                            }
                                                                                                        ],
                                                                                                        "product_id": "3"
                                                                                                    }
                                                                                                ]

                                                                                            }
                                                                                        ]
                                                                                        selectHaveCompliance({
                                                                                            payload: Editpayload,
                                                                                            paramid: paramid,

                                                                                        })

                                                                                    }

                                                                                }}
                                                                                value={vendorClosedAll}
                                                                                maxTagCount='responsive'
                                                                            >
                                                                                {vendorClosedUnit && vendorClosedUnit.length > 1 ? <>  <Option key="all" value="all" style={{ display: vendorcloseunitselectcheckbox }}>---SELECT ALL---</Option>
                                                                                    <Option key="unselect" value="unselect" style={{ display: vendorclosedunitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                {vendorClosedUnitList && vendorClosedUnitList.length > 0 &&
                                                                                    vendorClosedUnitList.map((finalList_unitlist) => {
                                                                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                            return (
                                                                                                <OptGroup label={finalList_unitlist.label}>
                                                                                                    {finalList_unitlist.options.length > 0 && finalList_unitlist.options.map((subUnitData) => (
                                                                                                        <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                                    ))}
                                                                                                </OptGroup>
                                                                                            )
                                                                                    })}
                                                                            </Select></Tooltip> : <Select
                                                                                name="units"
                                                                                id="units"
                                                                                className='form-control'
                                                                                onChange={(option) => VendorClosedUnitHandelChange(option)}
                                                                                mode="multiple"
                                                                                placeholder='Enter Closed Vendor'
                                                                                optionFilterProp="children"
                                                                                // allowClear
                                                                                showSearch
                                                                                // filterOption={(inputValue, option) =>
                                                                                //     option && option.children && option.children.join('').toLowerCase().includes(inputValue && inputValue.toLowerCase())
                                                                                // }
                                                                                filterOption={(input, option) =>

                                                                                    // option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                                                                                    option.children != undefined ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false


                                                                                }
                                                                                onDeselect={(e) => {
                                                                                    if (AddType == 'edit') {
                                                                                        let data = _.find(vendorClosedUnit, { u_unt_id: e })
                                                                                        console.log(data, 'eeeeeeeeeee');
                                                                                        const Editpayload = [
                                                                                            authtoken,
                                                                                            {
                                                                                                "session_token": authtoken,
                                                                                                "request": [
                                                                                                    "HaveCompliancesUnit",
                                                                                                    {
                                                                                                        "le_ids": [data.le_id],
                                                                                                        "user_id": details.user_id,
                                                                                                        "le_unit_ids": [
                                                                                                            {
                                                                                                                "le_id": data.le_id,
                                                                                                                "unit_ids_str": `${e}`
                                                                                                            }
                                                                                                        ],
                                                                                                        "product_id": "3"
                                                                                                    }
                                                                                                ]

                                                                                            }
                                                                                        ]
                                                                                        selectHaveCompliance({
                                                                                            payload: Editpayload,
                                                                                            paramid: paramid,

                                                                                        })

                                                                                    }

                                                                                }}
                                                                                value={vendorClosedAll}
                                                                                maxTagCount='responsive'
                                                                            >
                                                                                {vendorClosedUnit && vendorClosedUnit.length > 1 ? <>  <Option key="all" value="all" style={{ display: vendorcloseunitselectcheckbox }}>---SELECT ALL---</Option>
                                                                                    <Option key="unselect" value="unselect" style={{ display: vendorclosedunitunselectcheckbox }}>--UNSELECT ALL--</Option></> : false}

                                                                                {vendorClosedUnitList && vendorClosedUnitList.length > 0 &&
                                                                                    vendorClosedUnitList.map((finalList_unitlist) => {
                                                                                        if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0)
                                                                                            return (
                                                                                                <OptGroup label={finalList_unitlist.label}>
                                                                                                    {finalList_unitlist.options.length > 0 && finalList_unitlist.options.map((subUnitData) => (
                                                                                                        <Option value={subUnitData.u_unt_id}>{subUnitData.u_unt_code + ' - ' + subUnitData.u_unt_name + ' - ' + subUnitData.u_unt_address}</Option>

                                                                                                    ))}
                                                                                                </OptGroup>
                                                                                            )
                                                                                    })}
                                                                            </Select>}

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <h6 style={{ marginTop: '5px' }}>Closed Units</h6> */}



                                                                {/* <MultiSelect
                                                                    options={vendorClosedOption}
                                                                    value={vendorcloseselected}
                                                                    onChange={setVendorCloseSelected}
                                                                    labelledBy="Select"

                                                                /> */}
                                                            </TabPane> : null
                                                        }</Tabs>
                                                    {/* <div className="col-md-12"> */}
                                                    <div className="d-flex justify-content-between">
                                                        <div className={localStorage.getItem('currentTheme')} style={{ marginTop: '10px' }}>

                                                            {/* <ArrowRightOutlined /> */}


                                                            <Button shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: "1px" }}
                                                                icon={<ArrowLeftOutlined />} size='default'
                                                                onClick={() => {
                                                                    setActive('1')
                                                                }}
                                                            >
                                                                Previous
                                                            </Button>
                                                            {/* <button style={{ float: "left" }} className="pre-button"
                                                                onClick={() => {
                                                                    setActive('1')
                                                                }}
                                                            ><span>Previous</span></button> */}

                                                        </div>
                                                        {/* <div className="col-md-6" style={{ marginTop: '20px', float: 'right' }}> */}
                                                        {/* <a href={() => false} > */}
                                                        {/* <ArrowRightOutlined /> */}

                                                        <Button shape="round" className='addbutton'
                                                            style={{ background: "#198754", borderColor: "#198754", float: 'right', marginLeft: '33%', marginTop: '2%' }}
                                                            icon={<PlayCircleOutlined />} size='default'
                                                            onClick={userDetailsSaved}
                                                        >Submit
                                                        </Button>
                                                        {/* <div class="button2" style={{ float: 'right', marginLeft: '33%' }} id={localStorage.getItem('currentTheme')} onClick={userDetailsSaved}>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        Submit
                                                                    </div> */}
                                                        {/* <div id={localStorage.getItem('currentTheme')} style={{ textAlign: 'right', marginTop: '16px' }}>
                                                            <button class="button_v3 button_v3--mimas" style={{ height: '30px' }} onClick={userDetailsSaved} ><span >Submit</span></button>
                                                        </div> */}
                                                        {/* </a> */}
                                                    </div>
                                                    {/* </div> */}
                                                    {/* </div> */}

                                                </TabPane>
                                            </Tabs>

                                            {/* :""}/ */}

                                        </div>
                                    </div> </div> </div>
                        </div>
                    </div>


                </div>
                :
                <Usermanagement showList={showList1} setShowList={setShowList} userfullscreen1={userfullscreen} />}
            <Modal title="Remarks List" visible={remarksModal} className={"remark-header modelradius add-service-prv " + localStorage.getItem('currentTheme')}
                footer={false}
                onCancel={handleCancel}
            >
                <Table
                    // className='userprivclass'
                    size={'small'}
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    // pagination={{
                    //     defaultPageSize: dataTableProperties.pagesize,
                    //     showSizeChanger: dataTableProperties.sizechanger,
                    //     pageSizeOptions: dataTableProperties.pageSizeOptions
                    // }}
                    pagination={false}
                // style={{width:'300px'}}

                // onRowClick={{}}

                />
            </Modal>
        </>
    )
}

export default connect(mapStateToProps, {
    getUsermanagementDetails,
    addUserDetails,
    addUserManagementDetails,
    EdithaveCompliance,
    edituserManagementDetails,
    selectHaveCompliance,
    getById,
    GetRemarksData
})(AddUserManagement)