import { REASSIGNCOMPLAINCE, REASSIGLISTAPI, REASSIGNTABLELISTAPI, REASSIGGETUSERCOMPLIANCE, REASSIGNSAVECOMPLAINCE, SHOWMOREDATA } from '../../types/index'

const initialState = {
    reassigndata: {
        reassigndatalist: '',
        reassignunitdata: [],
        reassignusercompliance: '',
        savereassigndatalist: '',

    },
    reassigndatatablelist: '',
    showmorelist: [],

}
export default function foo(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REASSIGNCOMPLAINCE:
            {
                return {
                    ...state,
                    reassigndata: {
                        ...state.reassigndata,
                        reassigndatalist: payload[1],
                    },
                };
            }
        case REASSIGLISTAPI:
            {
                return {
                    ...state,
                    reassigndata: {
                        ...state.reassigndata,
                        reassignunitdata: payload[1],
                    },
                };
            }


        case REASSIGNTABLELISTAPI:
            {
                console.log(payload, "payload222");
                return {
                    ...state,
                    reassigndata: {
                        ...state.reassigndata,
                    },
                    reassigndatatablelist: payload[1],
                };
            }
        case SHOWMOREDATA:
            {
                return {
                    ...state,
                    reassigndata: {
                        ...state.reassigndata,
                    },
                    // showmorelist: payload,
                    showmorelist: [...state.showmorelist, payload],

                };

            }

        case REASSIGGETUSERCOMPLIANCE:
            {
                return {
                    ...state,
                    reassigndata: {
                        ...state.reassigndata,
                        reassignusercompliance: payload[1],
                    },
                };
            }

        case REASSIGNSAVECOMPLAINCE:
            {
                return {
                    ...state,
                    reassigndata: {
                        ...state.reassigndata,
                        savereassigndatalist: payload[1],
                    },
                };
            }


        default:

            return state;

    }

}