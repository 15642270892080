import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { SERVICEPROVIDERLIST, TOGGLE_COMMON_LOADER, INVALID_PASS } from './../../types/index';
import { toast } from 'react-toastify';
import alertmessage from './../../../Libs/Altermessage';
const urls = getUrl()

export const providerlist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: SERVICEPROVIDERLIST,
                    payload: decryptData,
                });
            }
        }

    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err);
    }
};

export const addprovider = ({ payload, executeCancel, paramid, sp_name }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'SaveServiceProviderSuccess') {
                toast.success('"' + sp_name + '" created successfully');
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else if (decryptData[0] === 'ServiceProviderNameAlreadyExists') {
                toast.error('Service Provider Name Already Exists');
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);

            } else if (decryptData[0] === 'CannotDeactivateUserExists') {
                toast.error('Cannot Deactivate User Exists');
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

            } else if (decryptData[0] === 'CannotChangeStatusOfContractExpiredSP') {
                toast.error('Cannot Change Status Of Contract Expired SP');
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error(decryptData[0]);
            }
        }



    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const updatestatus = ({ payload, executeCancel, paramid, spname }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let temp
        if (payload[1].request[0] == "ChangeServiceProviderStatus") {
            temp = payload[1].request[1].active_status
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData[0], 'decryptData[0]decryptData[0]');
            if (decryptData[0] === 'ChangeServiceProviderStatusSuccess') {
                if (temp == true)
                    Toaster.success('"' + spname + '"Activated Successfully')
                else
                    Toaster.success('"' + spname + '"Deactivated Successfully')
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else if (decryptData[0] === 'CannotChangeStatusOfContractExpiredSP') {
                Toaster.error('Cannot Change Status Of Contract Expired SP ')
            } else if (decryptData[0] === 'InvalidPassword') {
                Toaster.error('Invalid Password')
                dispatch({
                    type: INVALID_PASS,
                    payload: decryptData,
                });
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });

            } else {
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });
                toast.error(decryptData[0]);
            }
        }
    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const havecompliances = ({ payload, executeCancel, paramid, spname }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let temp
        if (payload[1].request[0] == "ChangeServiceProviderStatus") {
            temp = payload[1].request[1].active_status
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            // Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'HaveComplianceFailed') {
                toast.error('User have compliance. Reassign compliance');
            } else if (decryptData[0] === 'InvalidPassword') {
                toast.error('Invalid Password');
            }
        }
    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const updateblock = ({ payload, executeCancel, paramid, spname }) => async dispatch => {
    try {
       
        const encryptkey = localStorage.getItem('formkey')
        let temp
        if (payload[1].request[0] == "BlockServiceProvider") {
            temp = payload[1].request[1].is_blocked
        }
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'BlockServiceProviderSuccess') {
                if (temp == true)
                    Toaster.success('"' + spname + '" Service Provider Blocked Successfully')
                else
                    Toaster.success('"' + spname + '" Service Provider Unblocked Successfully')

                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else if (decryptData[0] === 'InvalidPassword') {
                Toaster.error('Invalid Password')
                dispatch({
                    type: INVALID_PASS,
                    payload: decryptData,
                });
                // dispatch({
                //     type: TOGGLE_COMMON_LOADER
                // });

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error(decryptData[0]);
            }

        }

    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const updateprovider = ({ payload, executeCancel, paramid, spname }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'UpdateServiceProviderSuccess') {
                toast.success('"' + spname + '" updated successfully', {
                    className: 'toast-text-wrap',
                });
                setTimeout(() => {
                    window.location.reload(false);
                }, 2000);
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else if (decryptData[0] === 'InvalidPassword') {
                Toaster.error('Invalid Password')
                    // dispatch({
                    //     type: TOGGLE_COMMON_LOADER
                    // });

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                toast.error(decryptData[0]);
            }
        }


    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};