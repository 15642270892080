import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Popover, Drawer, Button, Table, Tooltip, Modal, Descriptions, Empty, Space, Switch, Tag } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ArrowRightOutlined, FullscreenOutlined, CloseCircleOutlined, CloseOutlined, BookTwoTone, ArrowLeftOutlined, PlayCircleOutlined, SearchOutlined, FilterFilled, FilterOutlined, ExclamationCircleFilled, UpOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from 'moment';
import { getComplianceTrendchart, getCommonData, getComplianceTrendTableData, getTimeZoneData, getComplianceTrendShowMoreTableData } from '../../Store/Action/Dashboard/ComplianceTrend';
import { toast } from 'react-toastify';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { getHorizontalDistance } from '@amcharts/amcharts4/.internal/core/utils/Math';
import { HomeRedirect } from './../../Libs/country'
// import "core-js/stable";
// import "regenerator-runtime/runtime";
import { CountrySplit } from '../../Libs/country';
const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
    data: state.ComplianceTrend.complianceTrendChart.chartData,
    commonData: state.ComplianceTrend.complianceTrendChart.Data,
    TableValue: state.ComplianceTrend.complianceTrendChart.tableData,
    timeZoneData: state.ComplianceTrend.complianceTrendChart.timeZoneData,
    time: state.ComplianceTrend.complianceTrendChart.time,
    showMoreData: state.ComplianceTrend.complianceTrendChart.showMoreData,
    data1: state.ComplianceTrend.complianceTrendChart.chartData1,

    // tableData1: state.RiskChart.riskChart.delayedTableData,

})



const ComplianceTrend = ({
    getComplianceTrendchart,
    data,
    data1,
    getCommonData,
    commonData,
    getComplianceTrendTableData,
    TableValue,
    getTimeZoneData,
    timeZoneData,
    time,
    getComplianceTrendShowMoreTableData,
    showMoreData
}) => {
    var [indexValue, setIndexValue] = useState(1)
    const _ = require("lodash");
    const [open, setOpen] = useState(false)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [chartId, setChartId] = useState()
    const [table, setTable] = useState(false)
    const chart = useRef(null);
    const { Option } = Select;
    const { Panel } = Collapse;
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const [criticalityTable, setCriticalityTable] = useState([])
    const [criticalityValue, setCriticalityValue] = useState()
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    const location = useLocation();
    const [dataArray, setDataArray] = useState([])
    const [tableData, setTableData] = useState([])
    const [chartData, setChartData] = useState([])
    const [tempArr, setTempArr] = useState([])
    const [year, setYear] = useState(moment().year())
    const [prevYear, setPrevYear] = useState(moment().year() - 4)
    console.log(prevYear, 'prevYear');
    // const paramid = location.pathname;
    const [paramid, setParamId] = useState("")
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [dataCount, setDataCount] = useState()
    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    console.log(filterData.domain,'dommmmm');
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    const [textArray, setTextArray] = useState([])
    console.log(textArray, 'textArray');
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [unit, setUnit] = useState([])
    const [hiddenRiskData, setHiddenRiskData] = useState([])
    const [exposedRiskData, setExposedRiskData] = useState([])
    const [chartValue, setChartValue] = useState('')
    const [generatedOn, setGeneratedOn] = useState(false)
    const [toggleButton, setToggleButton] = useState('Month')
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const [domainName, setDomainName] = useState()
    const [totalCount, setTotalCount] = useState([])
    const [columnState, setCoulmnState] = useState(false)
    const [yearData, setYearData] = useState([])
    const [go, setGo] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const [count, setCount] = useState()
    const [countValue, setCountValue] = useState(0)
    const [countryselectcheckbox, Setcountryselectcheckbox] = useState('block');
    const [countryunselectcheckbox, Setcountryunselectcheckbox] = useState('none');
    const [domainselectcheckbox, Setdomainselectcheckbox] = useState('block');
    const [domainunselectcheckbox, Setdomainunselectcheckbox] = useState('none');
    const [country, setCountry] = useState([])
    const [domain, setDomain] = useState([])
    const [yearValue, setYearValue] = useState()
    const [type, setType] = useState('')
    const [newCountryDomain, setNewCountryDomain] = useState([])

    const country_info = sessionParsedValue.country_info

    const [fieldType, setFieldType] = useState("")
    const [fieldTypeClick, setFieldTypeClick] = useState("")
    const [chartMonth, setChartMonth] = useState(1)
    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [checkBox, setCheckBox] = useState(true)
    const [countryDomain, setCountryDomain] = useState([])
    const [dIds, setDIds] = useState([])
    const [cIds, setCIds] = useState([])
    const [countryName, setCountryName] = useState([])
    const [noDataState, setNoDataState] = useState(true)
    const [legalEntityArray, setLegalEntityArray] = useState([])
    const [resultArray, setResultArray] = useState([])
    const [errorField, setErrorField] = useState('')

    const [countryData,setCountryData] = useState([])
    const [domainData,setDomainData] = useState([])

    useEffect(()=>{
        var obj = {}
        countryData.forEach(function (a) {
            var arr2 = []
            domainData.forEach(function (b) {
                var key = a.c_name
                if (a.c_id == b.c_id) {
                    arr2.push(b.d_name)
                    obj[key] = arr2.join()
                }
            });
        });
        let temp = []
        for (let m in obj) {
            console.log(obj[m], 'objmm');
            // let sm = `${m} - ${obj[m]}`
            let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
            temp.push(expectedCountryDomain)
        }
        let newCountryDomain = temp.join("[bold] | [/]")
        setTextArray(newCountryDomain)

    },[countryData,domainData])

    useEffect(()=>{
        let newCountry = []
        let newDomain = []

        for (let j in filterData.country) {
           let filterCountry = _.filter(commonData.countries,{c_id:Number(filterData.country[j])})
            // temp2.push(filterDomain)
            for(let i in filterCountry){
                newCountry.push(filterCountry[i])
            }
            
        }
        for (let k in filterData.domain) {
            let filterDomain = _.filter(commonData.d_info,{d_id:Number(filterData.domain[k])})
             // temp2.push(filterDomain)
             for(let l in filterDomain){
                newDomain.push(filterDomain[l])
             }
             
         }
         const uniqueCountry = _.uniqBy(newCountry && newCountry, function (e) {
            return e.c_id;
        });
        setCountryData(uniqueCountry)
        setDomainData(newDomain)
        console.log(newCountry,'newCountry');
        console.log(newDomain,'newDomain');
        console.log(filterData.country,'filterData.country');
        console.log(filterData.domain,'filterData.domain');

    },[filterData.country,filterData.domain])

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            let textArr = []
            let cNames = []
            let uniqueCountry = []
            let countryName;
            setDomainData(commonData.d_info)
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
                uniqueCountry = _.uniqBy(commonData && commonData.countries, function (e) {
                    return e.c_id;
                });
                setCountryData(uniqueCountry)
                let id = uniqueCountry && uniqueCountry.length > 0 && uniqueCountry.map((item, i) => {
                    return item.c_id
                })
                // setCIds(id)
                console.log(uniqueCountry, 'uniqueCountry');
                countryName = _.find(uniqueCountry, { c_id: Number(commonData.d_info[i].c_id) })

                cNames.push(commonData.d_info[i].d_name)

            }
            // var arr = []
            var obj = {}
            uniqueCountry.forEach(function (a) {
                var arr2 = []
                commonData.d_info.forEach(function (b) {
                    var key = a.c_name
                    if (a.c_id == b.c_id) {
                        arr2.push(b.d_name)
                        obj[key] = arr2.join()
                    }
                });
            });
            let temp = []
            for (let m in obj) {
                console.log(obj[m], 'objmm');
                // let sm = `${m} - ${obj[m]}`
                let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
                temp.push(expectedCountryDomain)
            }
            let newCountryDomain = temp.join("[bold] | [/]")
            // setTextArray(newCountryDomain)
            setDIds(tempIds)
            setCountryDomain(tempArr)
        }


    }, [commonData])

    const HandelCountryChange = (obj) => {
        console.log(obj, 'objj');
        var numberArray = obj.map(Number);
        console.log(numberArray, 'numberArray');
        let temp5 = []
        let filterDomain = []
        for (let j in obj) {
            filterDomain = _.filter(commonData.d_info,{c_id:Number(obj[j])})
            // temp2.push(filterDomain)
            for(let i in filterDomain){
                temp5.push(filterDomain[i])
            }
            
        }
        let id = temp5 && temp5.length > 0 && temp5.map((item, i) => {
            return item.d_id
        })
        console.log(id,'temp5');
        setFieldTypeClick('Country')
        setType('')
        setFilterData({
            ...filterData,
            country: obj,
            countryArr: numberArray,
            domain:id
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            country: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
    }

    console.log(tableData, 'tableData');
    console.log(count, 'dataCount');
    console.log(showmoreCount, 'showmoreCount');
    console.log(noDataState, 'noDataState');
    console.log(data1, 'data1');
    console.log(yearData, 'yearData');
    console.log(yearData[0], 'yearooo');
    console.log(yearData[yearData.length - 1], 'yearLen');
    console.log(columnState, 'columnState');

    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            the_arr.pop();
            let a = the_arr.join('/')
            setParamId(a)
        }

    }, []);

    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');

            the_arr.pop();

            let a = the_arr.join('/')

            setParamId(a)

        }

    }, []);

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityData, entityid])



    useEffect(() => {
        if (commonData) {
            let unique = []
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                unique = _.uniqBy(commonData && commonData.bg_groups, function (e) {
                    return e.bg_id;
                });
            }
            setfield({
                ...field,
                domain: commonData && commonData.d_info,
                legalEntity: commonData && commonData.le_did_infos,
                country: commonData && commonData.countries,
                business: unique
            })
            let uniqueDiv = []
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                uniqueDiv = _.uniqBy(commonData && commonData.div_infos, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)
            setCategory(commonData && commonData.cat_info)
            setUnit(commonData && commonData.chart_units)
        }

    }, [isAuth, entityid, commonData])


    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)

        }

    }, [commonData])

    console.log(domainName, 'domainName');


    useEffect(() => {
        if (showMoreData && showMoreData.complied && showMoreData.complied.length > 0) {
            // for (let i in tableData) {
            //     console.log(showMoreData.complied, 'showMoreData.complied');
            //     for (let j in showMoreData.complied) {
            //         if (tableData[i].u_name == showMoreData.complied[j].u_name) {
            //             var newArray = Object.assign({}, tableData[i].drill_compliances, showMoreData.complied[j].drill_compliances);
            //             console.log(newArray, 'newArray');
            //             tableData[i].drill_compliances = newArray
            //         }

            //         else if (tableData[i].u_name != showMoreData.complied[j].u_name) {
            //             let temp = []
            //             temp.push(tableData[i], showMoreData.complied[j])
            //         }
            //     }
            // }
        }
        // if (showMoreData && showMoreData.delayed && showMoreData.delayed.length > 0) {
        //     for (let i in tableData) {

        //         for (let j in showMoreData.delayed) {
        //             if (tableData[i].u_name == showMoreData.delayed[j].u_name) {
        //                 var newArray = Object.assign({}, tableData[i].drill_compliances, showMoreData.delayed[j].drill_compliances);
        //                 tableData[i].drill_compliances = newArray
        //             }

        //             else if (tableData[i].u_name != showMoreData.delayed[j].u_name) {
        //                 let temp = []
        //                 temp.push(tableData[i], showMoreData.delayed[j])
        //             }
        //         }
        //     }
        // }
        if (showMoreData && showMoreData.not_complied && showMoreData.not_complied.length > 0) {
            let temp = []
            let newTable = tableData
            for (let i in newTable) {
                console.log(tableData[i], 'tableData[ii');
                console.log(showMoreData.complied, 'showMoreData.complied');
                for (let j in showMoreData.not_complied) {
                    console.log('mkkkkkk');
                    if (newTable[i].u_name == showMoreData.not_complied[j].u_name) {
                        var newArray = Object.assign({}, newTable[i].drill_compliances, showMoreData.not_complied[j].drill_compliances);
                        console.log(newArray, 'newArray');
                        newTable[i].drill_compliances = newArray
                    }

                    // else if (tableData[i].u_name != showMoreData.not_complied[j].u_name) {
                    //     console.log(tableData[i],'sneha');
                    //     console.log(showMoreData.not_complied[j],'showMoreData.not_complied[j]');
                    //     temp.push(tableData[i], showMoreData.not_complied[j])
                    //     console.log(temp, 'harishhh');

                    // }
                }
            }
            let mergedArray = newTable.concat(showMoreData.not_complied)
            console.log(mergedArray, 'mergedArray');

            setTableData(mergedArray)

            // setTableData(temp)
            // setTableData([...tableData,...temp])
        }

    }, [showMoreData])

    console.log(showMoreData, 'showMoreData');
    console.log(tableData, 'tableData');

    useEffect(() => {
        if (filterData.domain) {
            let tempDom = []
            for (let i in filterData.domain) {
                let filteredDomain = _.find(commonData.d_info, { d_id: filterData.domain[i] })
                tempDom.push({
                    "d_id": filteredDomain.d_id,
                    "c_id": filteredDomain.c_id,
                    "month_from": filteredDomain.month_from,
                    "month_to": filteredDomain.month_to
                })
                console.log(filteredDomain, 'filteredDomain');
            }
            setNewCountryDomain(tempDom)
            console.log(tempDom, 'tempDom');
        }

    }, [commonData.d_info, filterData.domain])

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            let count = []
            for (let i in tableData) {
                for (let j in tableData[i].drill_compliances) {
                    let a = tableData[i].drill_compliances[j]
                    let data = Object.values(a)


                    count.push(data.length)

                    // count.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');

                }
            }
            console.log(count, 'count');
            let countValues = count.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');
        }

    }, [tableData])

    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }
    console.log(unitListData, 'unitListDatalee');

    console.log(entityData, 'entityData');
    console.log(entityid, 'enid');
    console.log(TableValue, 'TableValue');
    console.log(commonData, 'commonData');
    console.log(tableData, 'avsm');
    console.log(fieldType, 'fieldType');

    useEffect(() => {
        if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceTrend",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": dIds,
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                            "country_domains": countryDomain,
                            "year": `${year}`,
                            "chart_types": "Month"
                        }
                    ]
                }
            ]

            if (paramid != "") {

                getComplianceTrendchart({
                    payload: payload,
                    paramid: paramid
                })

            }

        }

    }, [isAuth, entityid, commonData, entityData, year, countryDomain, dIds])

    useEffect(() => {
        if (TableValue) {
            if (TableValue.complied && TableValue.complied.length > 0) {
                setTableData(TableValue.complied)
            }
            else if (TableValue.delayed && TableValue.delayed.length > 0) {
                setTableData(TableValue.delayed)
            }
            else if (TableValue.not_complied && TableValue.not_complied.length > 0) {
                setTableData(TableValue.not_complied)
            }
        }
    }, [TableValue])

    console.log(TableValue, 'TableValue123');

    console.log(TableValue.complied, 'TableValue.complied');


    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    autoFocus={'on'}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    // const columns = [
    //     {
    //         title: 'Business Group',
    //         // dataIndex: 'name',
    //         // key: 'name',
    //     },
    //     {
    //         title: 'Legal Entity',
    //         // dataIndex: 'legal_entity_id',
    //         // key: 'legal_entity_id',
    //         render: () => {
    //             return (<p>{localStorage.getItem('SelectedEntity')}</p>)
    //         }
    //     },
    //     {
    //         title: 'Division',
    //         dataIndex: 'description',
    //         key: 'description',
    //     },
    //     {
    //         title: 'Compliance',
    //         dataIndex: 'comp_name',
    //         key: 'comp_name',
    //     },
    //     {
    //         title: 'Criticality',
    //         dataIndex: 'criticality',
    //         key: 'criticality',
    //     },
    //     {
    //         title: 'Assigned To',
    //         dataIndex: 'assignee_name',
    //         key: 'assignee_name',
    //     },

    // ];


    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: '10%',
            align: 'center'
        },
        ...((fieldType === 'LegalEntity' && go === true) || (fieldType === 'Category' && go === true) || (fieldType === 'Unit' && go === true) ?
            [] :
            [{
                title: "Business Group",
                ...getColumnSearchProps('bg_name', "Business Group"),
                dataIndex: 'bg_name',
                key: 'bg_name',
                ellipsis: false,
                width: '25%',
                filteredValue: filteredInfo.bg_name || null,
            },
            {
                title: 'Legal Entity',
                ...getColumnSearchProps('le_name', 'Legal Entity'),
                dataIndex: false,
                key: 'le_name',
                ellipse: false,
                width: '25%',
                filteredValue: filteredInfo.le_name || null,
                render: (record) => {
                    return <p title={record.le_name} className='cut-text'>
                        {record.le_name}</p>
                }
            }
            ]
        ),
        {
            title: 'Division',
            ...getColumnSearchProps('div_name', 'Division'),
            dataIndex: false,
            key: 'div_name',
            // width: '30%',
            ellipsis: false,
            filteredValue: filteredInfo.div_name || null,
            render: (record) => {
                return <p title={record.div_name} className='cut-text'>
                    {record.div_name}</p>
            }

        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            width: '90%',
            ellipse: false,
            render: (row, record) => {
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Tooltip title={
                                                <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                            <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                                            <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }></Tooltip><i class="bi-info-circle-fill"></i>
                                            {/* <Popover content={content} title="">
                                                <Button style={{ border: 0 }}>
                                                <i class="bi-info-circle-fill"></i>
                                                </Button>
                                                <ExclamationCircleFilled className='me-1' />
                                            </Popover> */}
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.div_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div>
                                {/* <Popover content={content} title="">
                                    <Button style={{ border: 0 }}>
                                    <i class="bi-info-circle-fill"></i>
                                    <ExclamationCircleFilled className='me-1' />
                                    </Button>
                                </Popover> */}
                                <Tooltip title={
                                    <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tbody>
                                            <tr>
                                                <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                            </tr>
                                            <tr>
                                                <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                                <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }><ExclamationCircleFilled className='me-1' /></Tooltip>
                                {record.comp_name}f
                            </div>
                        </div>

                    </>
                )
            },
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', "Criticality"),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: '100%',
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', "Assigned To"),
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: '100%',
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: (criticalityValue === "Complied" ? "Assigned to" : (criticalityValue === "Delayed Compliance" ? "Delayed By" : (criticalityValue === "Inprogress" ? "Days Left to Complete" : "Over Due"))),
            ...getColumnSearchProps('ageing', 'Over Due'),
            dataIndex: 'ageing',
            key: 'ageing',
            width: 12,

            ellipsis: false,
            render: (row, record) => {
                return (
                    <>
                        <p>{record.ageing}</p>
                    </>
                )
            },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ];

    const columns1 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 7,
            align: 'center'
        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: true,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.comp_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: 25,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', "Criticality"),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', "Assigned To"),
            dataIndex: false,
            key: 'assignee_name',
            ellipsis: false,
            render: (record) => {
                return <p title={record.assignee_name} className='cut-text'>
                    {record.assignee_name}</p>
            },
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 13,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: (criticalityValue === "Complied" ? "Assigned to" : (criticalityValue === "Delayed Compliance" ? "Delayed By" : (criticalityValue === "Inprogress" ? "Days Left to Complete" : "Over Due"))),
            ...getColumnSearchProps('ageing', 'Over Due'),
            dataIndex: 'ageing',
            key: 'ageing',
            width: 12,

            ellipsis: false,
            render: (row, record) => {
                return (
                    <>
                        <p>{record.ageing}</p>
                    </>
                )
            },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.ageing || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        }
    ]

    console.log(data.compliance_data, 'data.compliance_data');

    useEffect(() => {
        if (toggleButton === 'Year') {
            console.log(data.years, 'data.years');
            if (data && data.years && data.years.length > 0) {
                setYearData(data.years)
            }
        }
    }, [data.years, toggleButton])


    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])


    useEffect(() => {
        if (data && data.compliance_data && data.compliance_data.length > 0) {
            setDataArray(data.compliance_data)
            let temp = []
            let temp1 = []
            let temp3 = []
            for (let i in data.compliance_data) {
                let total = data.compliance_data[i].complied_count + data.compliance_data[i].delayed_compliance_count + data.compliance_data[i].not_complied_count
                if (toggleButton === 'Month') {
                    temp1.push({
                        'year': data.compliance_data[i].chart_month,
                        'complied_count': total != 0 ? (data.compliance_data[i].complied_count) / (total) * 100 : 0,
                        'delayed_compliance_count': total != 0 ? (data.compliance_data[i].delayed_compliance_count) / (total) * 100 : 0,
                        'not_complied_count': total != 0 ? (data.compliance_data[i].not_complied_count) / (total) * 100 : 0,
                        'total': total,
                        'compliedCount': data.compliance_data[i].complied_count,
                        'delayedCount': data.compliance_data[i].delayed_compliance_count,
                        'notCompliedCount': data.compliance_data[i].not_complied_count
                    })
                }
                else {
                    temp1.push({
                        'year': data.compliance_data[i].chart_year,
                        'complied_count': total != 0 ? (data.compliance_data[i].complied_count) / (total) * 100 : 0,
                        'delayed_compliance_count': total != 0 ? (data.compliance_data[i].delayed_compliance_count) / (total) * 100 : 0,
                        'not_complied_count': total != 0 ? (data.compliance_data[i].not_complied_count) / (total) * 100 : 0,
                        'total': total,
                        'compliedCount': data.compliance_data[i].complied_count,
                        'delayedCount': data.compliance_data[i].delayed_compliance_count,
                        'notCompliedCount': data.compliance_data[i].not_complied_count
                    })
                }
            }
            if (temp1 && temp1.length > 0) {
                for (let i in temp1) {
                    const removeZero = item => (
                        Object.keys(item)
                            .filter(key => item[key] !== 0)
                            .reduce((newObj, key) => {
                                newObj[key] = item[key]; return newObj;
                            }, {})); const result = removeZero(temp1[i]);
                    console.log(result, 'result');
                    temp.push(result)
                }
            }
            console.log(temp1, 'temp1temp1');
            console.log(temp, 'temp');
            setChartData(toggleButton === 'Month' ? temp : temp)
        }

    }, [data.compliance_data])

    useEffect(() => {
        if (data && data.compliance_data && data.compliance_data.length > 0) {
            setDataArray(data.compliance_data)
            let temp = []
            let temp1 = []
            let temp3 = []
            for (let i in data.compliance_data) {
                let total = data.compliance_data[i].complied_count + data.compliance_data[i].delayed_compliance_count + data.compliance_data[i].not_complied_count
                if (toggleButton === 'Month') {
                    temp1.push({
                        'year': data.compliance_data[i].chart_month,
                        'complied_count': total != 0 ? (data.compliance_data[i].complied_count) / (total) * 100 : 0,
                        'delayed_compliance_count': total != 0 ? (data.compliance_data[i].delayed_compliance_count) / (total) * 100 : 0,
                        'not_complied_count': total != 0 ? (data.compliance_data[i].not_complied_count) / (total) * 100 : 0,
                        'total': total,
                        'compliedCount': data.compliance_data[i].complied_count,
                        'delayedCount': data.compliance_data[i].delayed_compliance_count,
                        'notCompliedCount': data.compliance_data[i].not_complied_count
                    })
                }
                else {
                    temp1.push({
                        'year': data.compliance_data[i].chart_year,
                        'complied_count': total != 0 ? (data.compliance_data[i].complied_count) / (total) * 100 : 0,
                        'delayed_compliance_count': total != 0 ? (data.compliance_data[i].delayed_compliance_count) / (total) * 100 : 0,
                        'not_complied_count': total != 0 ? (data.compliance_data[i].not_complied_count) / (total) * 100 : 0,
                        'total': total,
                        'compliedCount': data.compliance_data[i].complied_count,
                        'delayedCount': data.compliance_data[i].delayed_compliance_count,
                        'notCompliedCount': data.compliance_data[i].not_complied_count
                    })
                }
            }
            if (temp1 && temp1.length > 0) {
                for (let i in temp1) {
                    const removeZero = item => (
                        Object.keys(item)
                            .filter(key => item[key] !== 0)
                            .reduce((newObj, key) => {
                                newObj[key] = item[key]; return newObj;
                            }, {})); const result = removeZero(temp1[i]);
                    console.log(result, 'result');
                    temp.push(result)
                }
            }
            console.log(temp1, 'temp1temp1');
            console.log(temp, 'temp');
            setTempArr(temp)
        }

    }, [data.compliance_data])

    // useEffect(() => {
    //     if (toggleButton === 'Year') {
    //         if (data && data.years ) {
    //             let chartFyear = data.years[0]
    //             let chartTyear = data.years[data.years.length - 1]
    //         }
    //     }
    // }, [toggleButton, data.years])


    console.log(chartData, 'chartData');
    // console.log(data && data.years.length > 0 && data.years[0],'data.years[0]');

    useEffect(() => {
        if (tempArr && tempArr.length > 0) {
            if (toggleButton === 'Year') {
                tempArr.forEach(object => {
                    delete object['year'];
                });

                const results = tempArr.filter(element => {
                    if (Object.keys(element).length !== 0) {
                        return true;
                    }

                    return false;
                });
                setResultArray(results)

                console.log(tempArr, '12345');
                console.log(chartData, 'charttt');
                console.log(results, 'results');
            }
            else {
                tempArr.forEach(object => {
                    delete object['year'];
                });

                const results = tempArr.filter(element => {
                    if (Object.keys(element).length !== 0) {
                        return true;
                    }

                    return false;
                });
                console.log(results, 'results123');
                setResultArray(results)

            }
        }

    }, [tempArr, toggleButton])

    // useEffect(() => {
    //     if (toggleButton === 'Year') {
    //         tempChartData.forEach(object => {
    //             delete object['year'];
    //         });

    //         const results = tempChartData.filter(element => {
    //             if (Object.keys(element).length !== 0) {
    //               return true;
    //             }

    //             return false;
    //           });

    //         console.log(tempChartData,'12345');
    //         console.log(results,'results');
    //     }
    //     else{
    //         tempChartData.forEach(object => {
    //             delete object['year'];
    //         });

    //         const results = tempChartData.filter(element => {
    //             if (Object.keys(element).length !== 0) {
    //               return true;
    //             }

    //             return false;
    //           });
    //     }

    // }, [tempChartData, toggleButton])

    console.log(chartData, 'chartData1');



    useEffect(() => {
        if (chartData && chartData.length > 0) {
            if (table == false) {
                am4core.useTheme(am4themes_animated);
                var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
                // Create a container
                var container = am4core.create("container", am4core.Container);
                container.width = am4core.percent(100);
                container.height = am4core.percent(100);
                container.layout = "vertical";
                container.background.fill = am4core.color("#000");
                container.background.fillOpacity = 0.1;
                container.background.stroke = am4core.color("#000");
                container.background.strokeOpacity = 0.2;
                container.background.strokeWidth = 2;
                // container.titleElement = "Hiiii"

                if (resultArray.length > 0) {
                    function addChart(data) {
                        // Create chart instance
                        var chart = container.createChild(am4charts.XYChart3D);
                        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
                        // Add data
                        chart.data = data;

                        chart.colors.list = [
                            am4core.color("#0cce73"),
                            am4core.color("#366cc2"),
                            am4core.color("#e53c5b"),
                            // am4core.color("#fe4365"),
                        ];

                        // chart.colors.step = 2;
                        // chart.padding(30, 30, 10, 30);
                        chart.legend = new am4charts.Legend();

                        chart.legend = new am4charts.Legend();
                        chart.legend.useDefaultMarker = true;
                        chart.legend.valueLabels.template.disabled = true;
                        var marker = chart.legend.markers.template.children.getIndex(0);
                        marker.cornerRadius(12, 12, 12, 12);
                        marker.strokeWidth = 2;
                        marker.strokeOpacity = 1;
                        marker.stroke = am4core.color("#ccc");

                        // chart.cursor = new am4charts.XYCursor();
                        // chart.legend = new am4charts.Legend();
                        // chart.legend.useDefaultMarker = true;
                        // var marker = chart.legend.markers.template.children.getIndex(0);
                        // marker.cornerRadius(12, 12, 12, 12);
                        // marker.strokeWidth = 2;
                        // marker.strokeOpacity = 1;
                        // marker.stroke = am4core.color("#ccc");


                        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "year";
                        categoryAxis.renderer.grid.template.location = 0;
                        categoryAxis.renderer.minGridDistance = 1
                        categoryAxis.renderer.cellStartLocation = 0.2;
                        categoryAxis.renderer.cellEndLocation = 0.8;
                        categoryAxis.title.text = "Month - Year";

                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        valueAxis.min = 0;
                        valueAxis.max = 100;
                        valueAxis.strictMinMax = true;
                        valueAxis.calculateTotals = true;
                        valueAxis.renderer.minWidth = 40;
                        valueAxis.renderer.minGridDistance = 40
                        valueAxis.title.text = "Total Compliance(%)";

                        var series1 = chart.series.push(new am4charts.ColumnSeries3D());
                        series1.columns.template.width = am4core.percent(toggleButton === 'Month' ? 90 : 50);
                        // series1.columns.template.tooltipText =
                        //     "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ";
                        series1.columns.template.tooltipText =
                            `{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{compliedCount}[\] out of [bold]{total}[/])`;
                        series1.name = "Complied";
                        series1.dataFields.customValue = "compliedCount";
                        series1.dataFields.categoryX = "year";
                        series1.dataFields.valueY = "complied_count";
                        series1.dataFields.valueYShow = "totalPercent";
                        series1.dataItems.template.locations.categoryX = 0.5;
                        series1.stacked = true;
                        series1.tooltip.pointerOrientation = "vertical";

                        var bullet1 = series1.bullets.push(new am4charts.LabelBullet());
                        bullet1.interactionsEnabled = false;
                        bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
                        bullet1.label.fill = am4core.color("#ffffff");
                        bullet1.locationY = 0.5;

                        var series2 = chart.series.push(new am4charts.ColumnSeries3D());
                        series2.columns.template.width = am4core.percent(toggleButton === 'Month' ? 90 : 50);
                        series2.columns.template.tooltipText =
                            "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{delayedCount}[/] out of [bold]{total}[/][/])";
                        series2.name = "Delayed Compliance";
                        series2.dataFields.customValue = "delayedCount";
                        series2.dataFields.categoryX = "year";
                        series2.dataFields.valueY = "delayed_compliance_count";
                        series2.dataFields.valueYShow = "totalPercent";
                        series2.dataItems.template.locations.categoryX = 0.5;
                        series2.stacked = true;
                        series2.tooltip.pointerOrientation = "vertical";

                        var bullet2 = series2.bullets.push(new am4charts.LabelBullet());
                        bullet2.interactionsEnabled = false;
                        bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
                        bullet2.locationY = 0.5;
                        bullet2.label.fill = am4core.color("#ffffff");

                        var series3 = chart.series.push(new am4charts.ColumnSeries3D());
                        series3.columns.template.width = am4core.percent(toggleButton === 'Month' ? 90 : 50);
                        series3.columns.template.tooltipText =
                            "{name}: {valueY.totalPercent.formatNumber('#.00')}% : ([bold]{notCompliedCount}[/] out of [bold]{total}[/][/])";
                        series3.name = "Not Complied";
                        series3.dataFields.customValue = "notCompliedCount";
                        series3.dataFields.categoryX = "year";
                        series3.dataFields.valueY = "not_complied_count";
                        series3.dataFields.valueYShow = "totalPercent";
                        series3.dataItems.template.locations.categoryX = 0.5;
                        series3.stacked = true;
                        series3.tooltip.pointerOrientation = "vertical";

                        var bullet3 = series3.bullets.push(new am4charts.LabelBullet());
                        bullet3.interactionsEnabled = false;
                        bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
                        bullet3.locationY = 0.5;
                        bullet3.label.fill = am4core.color("#ffffff");



                        series1.columns.template.events.on("hit", function (ev) {
                            console.log(ev.target.dataItem.categoryX, 'ev.target.dataItem.categoryX');
                            console.log(ev.target.dataItem._dataContext.complied_count, 'ev.target.dataItem._dataContext.complied_count');
                            const monthArray = ev.target.dataItem.categoryX.split("-");
                            console.log(monthArray[0], 'month[0]');
                            setYearValue(ev.target.dataItem.categoryX)
                            setCriticalityValue(ev.target.dataItem.component.name)
                            setCount(ev.target.dataItem.customValue)
                            setShowMoreCount(0)
                            setDataCount(ev.target.dataItem._dataContext.complied_count
                            )
                            setChartMonth(monthArray[0] === 'Jan' ? 1 : (monthArray[0] === 'Feb' ? 2 : (monthArray[0] === 'Mar' ? 3 : (monthArray[0] === 'Apr' ? 4 : (monthArray[0] === 'May' ? 5 : (monthArray[0] === 'Jun' ? 6 : (monthArray[0] === 'Jul' ? 7 : (monthArray[0] === 'Aug' ? 8 : (monthArray[0] === 'Sep' ? 9 : (monthArray[0] === 'Oct' ? 10 : (monthArray[0] === 'Nov' ? 11 : (monthArray[0] === 'Dec' ? 12 : 0))))))))))))
                            setTable(true)
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetComplianceTrendDrillDownData",
                                        {
                                            "d_ids": dIds
                                            ,
                                            "filter_type": fieldType != "" ? fieldType : "Group",
                                            "filter_ids": [
                                                fieldType == "LegalEntity" ? filterData.legalentity :
                                                    fieldType == 'Division' ? Number(filterData.division) :
                                                        fieldType == 'Category' ? Number(filterData.category) :
                                                            fieldType == 'Unit' ? Number(filterData.unit) :
                                                                fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                    1
                                            ],
                                            "chart_year": toggleButton === 'Month' ? year : Number(ev.target.dataItem.categoryX),
                                            "chart_month": (monthArray[0] === 'Jan' ? 1 : (monthArray[0] === 'Feb' ? 2 : (monthArray[0] === 'Mar' ? 3 : (monthArray[0] === 'Apr' ? 4 : (monthArray[0] === 'May' ? 5 : (monthArray[0] === 'Jun' ? 6 : (monthArray[0] === 'Jul' ? 7 : (monthArray[0] === 'Aug' ? 8 : (monthArray[0] === 'Sep' ? 9 : (monthArray[0] === 'Oct' ? 10 : (monthArray[0] === 'Nov' ? 11 : (monthArray[0] === 'Dec' ? 12 : 0)))))))))))),
                                            "record_count": 0,
                                            "le_ids":
                                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                            ,
                                            "country_domains": countryDomain,
                                            "compliance_status": ev.target.dataItem.component.name
                                        }
                                    ]
                                }
                            ]
                            if (paramid != "") {
                                getComplianceTrendTableData({
                                    payload: payload,
                                    paramid: paramid
                                })
                            }

                        });

                        series2.columns.template.events.on("hit", function (ev) {
                            console.log(ev.target.dataItem.categoryX, 'ev.target.dataItem.categoryX');

                            setCriticalityValue(ev.target.dataItem.component.name)
                            setYearValue(ev.target.dataItem.categoryX)

                            const monthArray = ev.target.dataItem.categoryX.split("-");
                            setTable(true)
                            setShowMoreCount(0)
                            setDataCount(ev.target.dataItem._dataContext.delayed_compliance_count
                            )
                            setChartMonth(monthArray[0] === 'Jan' ? 1 : (monthArray[0] === 'Feb' ? 2 : (monthArray[0] === 'Mar' ? 3 : (monthArray[0] === 'Apr' ? 4 : (monthArray[0] === 'May' ? 5 : (monthArray[0] === 'Jun' ? 6 : (monthArray[0] === 'Jul' ? 7 : (monthArray[0] === 'Aug' ? 8 : (monthArray[0] === 'Sep' ? 9 : (monthArray[0] === 'Oct' ? 10 : (monthArray[0] === 'Nov' ? 11 : (monthArray[0] === 'Dec' ? 12 : 0))))))))))))
                            setCount(ev.target.dataItem.customValue)
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetComplianceTrendDrillDownData",
                                        {
                                            "d_ids": dIds,
                                            "filter_type": fieldType != "" ? fieldType : "Group",
                                            "filter_ids": [
                                                fieldType == "LegalEntity" ? filterData.legalentity :
                                                    fieldType == 'Division' ? Number(filterData.division) :
                                                        fieldType == 'Category' ? Number(filterData.category) :
                                                            fieldType == 'Unit' ? Number(filterData.unit) :
                                                                fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                    1
                                            ],
                                            "chart_year": toggleButton === 'Month' ? year : Number(ev.target.dataItem.categoryX),
                                            "chart_month": (monthArray[0] === 'Jan' ? 1 : (monthArray[0] === 'Feb' ? 2 : (monthArray[0] === 'Mar' ? 3 : (monthArray[0] === 'Apr' ? 4 : (monthArray[0] === 'May' ? 5 : (monthArray[0] === 'Jun' ? 6 : (monthArray[0] === 'Jul' ? 7 : (monthArray[0] === 'Aug' ? 8 : (monthArray[0] === 'Sep' ? 9 : (monthArray[0] === 'Oct' ? 10 : (monthArray[0] === 'Nov' ? 11 : (monthArray[0] === 'Dec' ? 12 : 0)))))))))))),
                                            "record_count": 0,
                                            "le_ids":
                                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                            ,
                                            "country_domains": countryDomain,
                                            "compliance_status": ev.target.dataItem.component.name
                                        }
                                    ]
                                }
                            ]
                            if (paramid != "") {
                                getComplianceTrendTableData({
                                    payload: payload,
                                    paramid: paramid
                                })
                            }

                        });

                        series3.columns.template.events.on("hit", function (ev) {
                            console.log(ev.target.dataItem, 'ev.target.dataItem.categoryX');
                            setYearValue(ev.target.dataItem.categoryX)
                            setCriticalityValue(ev.target.dataItem.component.name)
                            const monthArray = ev.target.dataItem.categoryX.split("-");
                            setTable(true)
                            setShowMoreCount(0)
                            setDataCount(ev.target.dataItem._dataContext.not_complied_count
                            )
                            setCount(ev.target.dataItem.customValue)
                            setChartMonth(monthArray[0] === 'Jan' ? 1 : (monthArray[0] === 'Feb' ? 2 : (monthArray[0] === 'Mar' ? 3 : (monthArray[0] === 'Apr' ? 4 : (monthArray[0] === 'May' ? 5 : (monthArray[0] === 'Jun' ? 6 : (monthArray[0] === 'Jul' ? 7 : (monthArray[0] === 'Aug' ? 8 : (monthArray[0] === 'Sep' ? 9 : (monthArray[0] === 'Oct' ? 10 : (monthArray[0] === 'Nov' ? 11 : (monthArray[0] === 'Dec' ? 12 : 0))))))))))))
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetComplianceTrendDrillDownData",
                                        {
                                            "d_ids": dIds,
                                            "filter_type": fieldType != "" ? fieldType : "Group",
                                            "filter_ids": [
                                                fieldType == "LegalEntity" ? filterData.legalentity :
                                                    fieldType == 'Division' ? Number(filterData.division) :
                                                        fieldType == 'Category' ? Number(filterData.category) :
                                                            fieldType == 'Unit' ? Number(filterData.unit) :
                                                                fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                    1
                                            ],
                                            "chart_year": toggleButton === 'Month' ? year : Number(ev.target.dataItem.categoryX),
                                            "chart_month": (monthArray[0] === 'Jan' ? 1 : (monthArray[0] === 'Feb' ? 2 : (monthArray[0] === 'Mar' ? 3 : (monthArray[0] === 'Apr' ? 4 : (monthArray[0] === 'May' ? 5 : (monthArray[0] === 'Jun' ? 6 : (monthArray[0] === 'Jul' ? 7 : (monthArray[0] === 'Aug' ? 8 : (monthArray[0] === 'Sep' ? 9 : (monthArray[0] === 'Oct' ? 10 : (monthArray[0] === 'Nov' ? 11 : (monthArray[0] === 'Dec' ? 12 : 0)))))))))))),
                                            "record_count": 0,
                                            "le_ids":
                                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                            ,
                                            "country_domains": countryDomain,
                                            "compliance_status": ev.target.dataItem.component.name
                                        }
                                    ]
                                }
                            ]
                            if (paramid != "") {
                                getComplianceTrendTableData({
                                    payload: payload,
                                    paramid: paramid
                                })
                            }

                        });

                        chart.exporting.menu = new am4core.ExportMenu();
                        // temp1.push({
                        //     'year': data.compliance_data[i].chart_month,
                        //     'complied_count': total != 0 ? (data.compliance_data[i].complied_count) / (total) * 100 : 0,
                        //     'delayed_compliance_count': total != 0 ? (data.compliance_data[i].delayed_compliance_count) / (total) * 100 : 0,
                        //     'not_complied_count': total != 0 ? (data.compliance_data[i].not_complied_count) / (total) * 100 : 0,
                        //     'total': total,
                        //     'compliedCount': data.compliance_data[i].complied_count,
                        //     'delayedCount': data.compliance_data[i].delayed_compliance_count,
                        //     'notCompliedCount': data.compliance_data[i].not_complied_count
                        // })
                        chart.exporting.dataFields = {
                            "year": "Month - Year",
                            "total": "Total Tasks",
                            "complied_count": "Complied",
                            "compliedCount": "Complied Count",
                            "delayed_compliance_count": "Delayed Compliance",
                            "delayedCount": "Delayed Compliance",
                            "not_complied_count": "Not Complied",
                            "notCompliedCount": "Not Complied Count",
                        }
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";

                        console.log(chartData && chartData[0].chart_year, 'chartData[0].chart_year');

                        chart.scrollbarY = new am4core.Scrollbar();

                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on : ${timeZoneData.tz_name}  ${timeZone}` : `Generated on : ${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'
                        title.marginBottom = '4%'

                        var title1 = chart.titles.create();
                        title1.text =
                            ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Compliance Trend of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Compliance Trend of Division [bold]${filterDataNames.division}[/] (${year})` : (
                                    (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Compliance Trend of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                        (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Compliance Trend of Business Group [bold]${filterDataNames.unit}[/] (${year})` : (
                                            (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Compliance Trend of Unit [bold]${filterDataNames.business}[/] (${year})` : (
                                                (toggleButton === 'Month') ? `Compliance Trend of ${commonData && commonData.g_name} (${year})` :
                                                    (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Compliance Trend of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                                        (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Compliance Trend of Division [bold]${filterDataNames.division}[/] (${year})` : (
                                                            (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Compliance Trend of Category [bold]${filterDataNames.category}[/] (${year})` : (
                                                                (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Compliance Trend of Unit [bold]${filterDataNames.unit}[/] (${year})` : (
                                                                    (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Compliance Trend of Business Group [bold]${filterDataNames.business}[/] (${year})` :
                                                                        `Compliance Trend of ${commonData && commonData.g_name} (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
                                                                ))))))))
                            ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '2%'
                        title1.marginLeft = '2%'
                        title1.fontSize = '17px'
                        title1.fontWeight = '400'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        // "Series: [bold {color}]{name}[/]";


                        var label1 = chart.createChild(am4core.Label);

                        // label1.text = "[bold {commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}]-{domainName}"
                        // label1.text =` ${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}-${domainName}`
                        // label1.text = `[bold]${commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}[/]-${domainName}`
                        label1.text = `${textArray}`
                        // label1.text = `[bold]${countryName[i]}[/] - ${domainName[i]}`
                        label1.fontSize = 14;

                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '1%'


                    }

                    addChart(chartData);
                }
                else {
                    var title3 = container.createChild(am4core.Label)
                    title3.text = "No Data to Display"
                    title3.disabled = false;
                    title3.align = 'left'
                    title3.paddingTop = '45%'
                    title3.paddingLeft = '43%'
                    // title3.fontWeight = '500'
                    title3.fontSize = '16'
                }
            }

        }
    }, [chartData, table, timeZone, resultArray, timeZoneData, localStorage.getItem('chart')])

    //Use effects for filter

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if (commonData) {
            let temp = []
            let temp1 = []
            let temp2 = []
            for (let i in commonData.d_info) {
                temp.push(commonData.d_info[i].d_id)
            }
            for (let j in uniq_country_data) {
                temp1.push(`${uniq_country_data[j].c_id}`)
                temp2.push(uniq_country_data[j].c_id)
            }
            setFilterData({
                ...filterData,
                country: temp1,
                countryArr: temp2,
                domain: temp
            })
        }
    }, [commonData, uniq_country_data])


    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
                let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(commonData && commonData.bg_groups[0].bg_id) })
                console.log(filteredDivision, 'filteredDivision');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)
                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)
                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)

                }
            }
            else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                console.log('hello ais');
                let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: commonData.le_did_infos[0].le_id })
                console.log(filteredDivision, 'filteredDivision555');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)

                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)

                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)
                }
            }
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && bgIds.length > 0 && commonData) {
            if (bgIds.includes(null) === true) {
                console.log('snehaaa');
                setfield({
                    ...field,
                    legalEntity: commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos,
                })

            }
            else {
                let filteredLegalEntity = _.filter(commonData.le_did_infos, { bg_id: Number(commonData.bg_groups[0].bg_id) })
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                })
            }

        }
    }, [commonData, bgIds, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map(function (user) {
            // console.log(list.unitlist, 'list.unitlist');
            if ((arrunit.indexOf(user.c_id) < 0))
                arrunit.push(user.c_id);
        });

        arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            if (pmenu || pmenu == null) {
                commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((subunitlist) => {

                    if (pmenu == subunitlist.c_id) {
                        entityArrayunit = {

                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'd_name': subunitlist.d_name,
                            'is_active': subunitlist.is_active,
                            'month_from': subunitlist.month_from,
                            'month_to': subunitlist.month_to,
                            'label': subunitlist.d_name,
                            'values': subunitlist.d_id

                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
            }
            let countryName = _.find(uniq_country_data, { c_id: pmenu })
            console.log(countryName, 'countryName');
            totalListunit = {
                'label': countryName.c_name == null ? 'Others' : countryName.c_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setDomainListData(countrylisttempdataunit)


    }, [uniq_country_data, commonData])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredCategory, 'filteredCategory111');
                let temp = []
                temp.push({
                    'bg_id': filteredCategory[0].bg_id,
                    'c_id': filteredCategory[0].c_id,
                    'cat_id': filteredCategory[0].cat_id,
                    'cat_name': filteredCategory[0].cat_name,
                    'd_id': filteredCategory[0].d_id,
                    'div_id': filteredCategory[0].div_id,
                    'le_id': filteredCategory[0].le_id,
                    'div_name': commonData && commonData.div_infos[0].div_name
                })
                setCategory(temp)
                let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredUnit, 'filteredUnit123');
                setUnit(filteredUnit)
            }
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity && filteredLegalEntity[0].bg_id })
                console.log(filteredBusinessGroup, 'filteredBusinessGroup');
                const unique = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                console.log(unique, 'unique');
                setfield({
                    ...field,
                    business: unique,
                    legalEntity: filteredLegalEntity
                })
            }

        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if ((commonData) && (division && division.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            division && division.length > 0 && division.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');

                if (commonData && commonData.le_did_infos[0].le_id == subunitlist.le_id) {

                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.div_name,
                        'values': subunitlist.div_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            console.log(totalListunit, 'totalListunit');

            countrylisttempdataunit.push(totalListunit)
            console.log(countrylisttempdataunit, 'countrylisttempdataunit');
            setDivisionListData(countrylisttempdataunit)
        }
    }, [commonData, division, field.legalEntity])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length < 0) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
        }

    }, [commonData, legalEntityArray, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    console.log(legalEntityArray, 'legalEntityArray');

    useEffect(() => {
        if (field.legalEntity && field.legalEntity.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }
                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [field.legalEntity, division, category, unit])

    useEffect(() => {
        if ((commonData) && (category && category.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            category && category.length > 0 && category.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-category');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }
            countrylisttempdataunit.push(totalListunit)
            setCategoryListData(countrylisttempdataunit)
        }

    }, [commonData, category && category.length > 0])

    useEffect(() => {
        if ((commonData) && (unit && unit.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            unit && unit.length > 0 && unit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-unit');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'address': subunitlist.address,
                        'business_group_id': subunitlist.business_group_id,
                        'category_id': subunitlist.category_id,
                        'country_id': subunitlist.country_id,
                        'd_ids': subunitlist.d_ids,
                        'division_id': subunitlist.division_id,
                        'le_id': subunitlist.le_id,
                        'postal_code': subunitlist.postal_code,
                        'u_id': subunitlist.u_id,
                        'u_name': subunitlist.u_name,
                        'label': subunitlist.u_name,
                        'values': subunitlist.u_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setUnitListData(countrylisttempdataunit)
        }
    }, [commonData, unit && unit.length > 0])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            console.log('hiii');
            if ((commonData && commonData.bg_groups && commonData.bg_groups.length === 0) && (legalEntityArray && legalEntityArray.length > 0)) {
                console.log('hello');
                if (unit) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    unit && unit.length > 0 && unit.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-lee');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'address': subunitlist.address,
                                'business_group_id': subunitlist.business_group_id,
                                'category_id': subunitlist.category_id,
                                'country_id': subunitlist.country_id,
                                'd_ids': subunitlist.d_ids,
                                'division_id': subunitlist.division_id,
                                'le_id': subunitlist.le_id,
                                'postal_code': subunitlist.postal_code,
                                'u_id': subunitlist.u_id,
                                'u_name': subunitlist.u_name,
                                'label': subunitlist.u_name,
                                'values': subunitlist.u_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunitleee');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setUnitListData(countrylisttempdataunit)
                }
                if (division) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    division && division.length > 0 && division.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {
                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.div_name,
                                'values': subunitlist.div_id
                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }
                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunit');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setDivisionListData(countrylisttempdataunit)
                }
                if (category) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    category && category.length > 0 && category.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-category');
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'cat_id': subunitlist.cat_id,
                                'cat_name': subunitlist.cat_name,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.cat_name,
                                'values': subunitlist.cat_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                    setCategoryListData(countrylisttempdataunit)
                }
            }
        }

    }, [commonData, legalEntityArray, unit, division, category, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    console.log(dIds, 'dIds');


    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        setFieldTypeClick('Domain')
        setType('')
        setFilterData({
            ...filterData,
            domain: obj
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        for (let i in obj) {
            console.log(obj[i], 'obj[i');
            console.log(i, 'i');
            if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity[0].bg_id })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            } else {
                let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(obj[i])))
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(obj[i]) })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            }
            let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(obj[i]) })
            let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
            let temp1 = []
            temp1.push({
                'bg_id': filteredCategory[0].bg_id,
                'c_id': filteredCategory[0].c_id,
                'cat_id': filteredCategory[0].cat_id,
                'cat_name': filteredCategory[0].cat_name,
                'd_id': filteredCategory[0].d_id,
                'div_id': filteredCategory[0].div_id,
                'le_id': filteredCategory[0].le_id,
                'div_name': division[0].div_name
            })
            tempCat = temp1
            let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
            tempUnit = filteredUnit
            tempDiv = filteredDivision
            let uniqueDiv = []
            if (filteredDivision && filteredDivision.length > 0) {
                uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)

            setCategory(temp1)
            setUnit(filteredUnit)

        }
        console.log(tempCat, 'tempCat');
        console.log(bg, 'Aishuuuu');
        console.log(temp, 'SnehaaaaaaaaAsss');

    }

    const showmore = () => {
        setCountValue(countValue + 100)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceTrendDrillDownData",
                    {
                        "d_ids": dIds,
                        "filter_type": "Group",
                        "filter_ids": cIds,
                        "chart_year": year,
                        "chart_month": chartMonth,
                        "record_count": countValue + 100,
                        "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                        "country_domains": countryDomain,
                        "compliance_status": criticalityValue
                    }
                ]
            }
        ]
        if (paramid != "") {
            getComplianceTrendShowMoreTableData({
                payload: payload,
                paramid: paramid
            })
        }
    }



    const onClose = () => {
        setOpen(false);
    };

    console.log(errorField, 'errorField');
    console.log(groupCheckBox, 'groupCheckBox');

    const filterChange = () => {
        setGo(true)
        setTempArr([])
        setResultArray([])
        setGeneratedOn(false)
        if (groupCheckBox === true && errorField === '') {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceTrend",
                        {
                            "c_ids": filterData.countryArr,
                            "d_ids": filterData.domain,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids": [
                                (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                    (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                        (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                            (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                                (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                    (fieldType != "" && type === 'Group') ? 1 :
                                                        1
                            ],
                            "le_ids": filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": newCountryDomain,
                            "year": `${year}`,
                            "chart_types": "Month"
                        }
                    ]
                }
            ]

            getComplianceTrendchart({
                payload: payload,
                paramid: paramid
            })
            setOpen(false);
        }
        else {
            // toast.error('Please select the filter')
            toast.error(errorField ? errorField : 'Please select the filter')
        }
        // setOpen(false);
    }

    const CountryHandelChange = (obj) => {
        console.log(obj, 'objjjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (country_info && country_info.length > 0) {
                let temp = []
                for (let i in country_info) {
                    temp.push(`${country_info[i].c_id}`)
                }
                console.log(temp, 'tempCountery');
                setCountry(`${temp}`)

            }
            Setcountryselectcheckbox('none');
            Setcountryunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];

            setCountry([])
            Setcountryselectcheckbox('block');
            Setcountryunselectcheckbox('none');
        } else {
            setCountry(`${obj}`)

        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };

    const DomainHandleChane = (obj) => {
        console.log(field.domain, 'objjfield.domainjj');
        let allArray = [];
        if (obj.includes('all')) {
            if (field.domain && field.domain.length > 0) {
                let temp = []
                for (let i in field.domain) {
                    console.log(field.domain[i].d_id, 'domain[i].d_id');
                    temp.push(field.domain[i].d_id)


                }
                console.log(temp, 'temp');
                setDomain(temp)

            }
            Setdomainselectcheckbox('none');
            Setdomainunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];

            setDomain([])
            Setdomainselectcheckbox('block');
            Setdomainunselectcheckbox('none');
        } else {
            console.log(obj, 'obj');
            let temp = []
            for (let i in obj) {
                temp.push(obj[i])
            }
            console.log(temp, 'temp');
            setDomain(temp)

        }
        // setUserDetails({
        //     ...userDetails,
        //     units: (allArray && allArray.length > 0 ? allArray : obj)
        // })
        return obj;

    };

    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }

    console.log(country, 'country');
    console.log(resultArray, 'resultArray');

    useEffect(() => {
        if (filterOpen === true) {
            if (field.domain && field.domain.length > 0) {
                let temp = []
                for (let i in field.domain) {
                    console.log(field.domain[i].d_id, 'domain[i].d_id');
                    temp.push(field.domain[i].d_id)


                }
                console.log(temp, 'temp');
                setDomain(temp)

            }
            Setdomainselectcheckbox('none');
            Setdomainunselectcheckbox('block');
            if (country_info && country_info.length > 0) {
                let temp = []
                for (let i in country_info) {
                    temp.push(`${country_info[i].c_id}`)
                }
                setCountry(temp)

            }
            Setcountryselectcheckbox('none');
            Setcountryunselectcheckbox('block');
        }
    }, [filterOpen])

    useEffect(() => {
        if (fieldTypeClick === 'BusinessGroup') {
            if (filterDataNames.business === '') {
                setErrorField('Business Group Required')
            }
        }

        if (fieldTypeClick === 'LegalEntity') {
            if (filterDataNames.legalentity === '') {
                setErrorField('Legal Entity Required')
            }
        }
        if (fieldTypeClick === 'Division') {
            if (filterDataNames.division === '') {
                setErrorField('Division Required')
            }
        }
        if (fieldTypeClick === 'Category') {
            if (filterDataNames.category === '') {
                setErrorField('Category Required')
            }
        }
        if (fieldTypeClick === 'Unit') {
            if (filterDataNames.unit === '') {
                setErrorField('Unit Required')
            }
        }

    }, [fieldTypeClick])

    useEffect(() => {
        if (fieldTypeClick === 'Country') {
            if (filterData.country.length === 0) {
                setErrorField('Country Required')
            }
            else {
                setErrorField('')

            }
        }
        if (fieldTypeClick === 'Domain') {
            if (filterData.domain.length === 0) {
                setErrorField('Domain Required')
            }
            else {
                setErrorField('')

            }
        }

    }, [fieldTypeClick, filterData])

    console.log(filterDataNames, 'filterDataNames');
    console.log(fieldTypeClick, 'fieldTypeClick');
    console.log(tableData, 'tableData');
    console.log(criticalityTable, 'cricTable');
    console.log(data, 'data');
    console.log(totalCount, 'totalCount');
    // console.log(timeZoneData, 'timeZoneData');
    // console.log(tableData1, 'tableData1');

    return (
        <>
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            <div id="page-wrapper" className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-self-center" style={{ paddingLeft: '10px' }}>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Dashboard</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span style={{ fontSize: '16px' }}>Compliance Trend</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-end full_screeen_icon_align" style={{ paddingRight: '10px' }}>
                                {table == false ?
                                    <div className={localStorage.getItem('currentTheme')} style={{ textAlign: 'end' }}>
                                        <span style={{ fontSize: '18px', marginRight: '10px' }} className='togglecolour'> <Switch type='success' style={{ backgroundColor: toggleButton == 'Month' ? 'green' : 'blue', fontSize: '18px', height: '32px', width: '75px' }} checked={toggleButton === 'Month' ? true : false} checkedChildren="Month" unCheckedChildren="Year"
                                            onChange={(e) => {
                                                setToggleButton(e === false ? 'Year' : 'Month')
                                                if (entityData && entityData.length > 0) {
                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetComplianceTrend",
                                                                {
                                                                    "c_ids": [
                                                                        country_info[0].c_id
                                                                    ],
                                                                    "d_ids": dIds,
                                                                    "filter_type": "Group",
                                                                    "filter_ids": [
                                                                        1
                                                                    ],
                                                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                                    "country_domains": countryDomain,
                                                                    "year": `${year}`,
                                                                    "chart_types": e === false ? 'Year' : 'Month'
                                                                }
                                                            ]
                                                        }
                                                    ]

                                                    getComplianceTrendchart({
                                                        payload: payload,
                                                        paramid: paramid
                                                    })

                                                } else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                                                    const payload = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetComplianceTrend",
                                                                {
                                                                    "c_ids": [
                                                                        commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id

                                                                    ],
                                                                    "d_ids": dIds,
                                                                    "filter_type": "Group",
                                                                    "filter_ids": [
                                                                        1
                                                                    ],
                                                                    "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                                    "country_domains": countryDomain,
                                                                    "year": `${year}`,
                                                                    "chart_types": e === false ? 'Year' : 'Month'
                                                                }
                                                            ]
                                                        }
                                                    ]

                                                    getComplianceTrendchart({
                                                        payload: payload,
                                                        paramid: paramid
                                                    })

                                                }
                                            }} />
                                        </span>
                                    </div> : ''}
                                {table == false ?
                                    <Button icon={<FilterOutlined />} className='addbutton ms-2' style={{ background: "#0096FF", borderRadius: '15px', marginLeft: '5px' }} onClick={() => {
                                        setOpen(true)
                                    }} size='default' >
                                        <Tooltip placement='top' title='Filter' getPopupContainer={(triggerNode) => {

                                            return triggerNode.parentNode;

                                        }} className='gotop'>Filter</Tooltip>
                                    </Button> : <Button type="primary" shape="round" className='check me-1 addbutton' icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                        setTable(false)
                                    }}>
                                        <Tooltip placement='top' title='Back'>Back</Tooltip>
                                    </Button>}
                                <div className='comparative'>
                                    <Button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                        <Tooltip placement='left' title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                    </Button>
                                </div>
                            </div>

                            {/* <div className="col-md-8">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <span style={{ color: '#1890ff' }}>Dashboard</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span style={{ color: '#1890ff' }}>Compliance Trend</span>
                                        </li>
                                    </ol>
                                </nav>

                            </div>
                        </div> */}

                            {/* <div className="col-md-4 text-end full_screeen_icon_align" style={{ marginLeft: 'auto' }}>

                                <Button type="primary" shape="round" className='check me-1 addbutton' icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                    setTable(false)
                                }}>
                                    <Tooltip title='Back'>Back</Tooltip>
                                </Button>
                                <div className='check'>
                                    <Button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black  full-mode fullscreenradius" >
                                        <Tooltip placement="top" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                    </Button>
                                </div>
                            </div> */}


                        </div>
                    </div>
                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-1">
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className="col-md-8">
                                        <span style={{ marginTop: '2%', fontSize: '16px' }}><b style={{ marginTop: '2%', fontSize: '16px' }}>Compliance Trend of {commonData && commonData.g_name} for the year {yearValue}</b></span>
                                    </div>
                                    {/* <div className="col-md-4">
                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginLeft: '70%' }}
                                        icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                            setTable(false)
                                        }}>
                                        <Tooltip title='Back'>Back</Tooltip>
                                    </Button>
                                </div> */}
                                </div>
                            </div>
                            <Card className='rounded-3'>
                                {/* <h3>{criticalityValue != 'Complied' ? `${criticalityValue} compliance` : criticalityValue}</h3> */}
                                {/* <Tag style={{ height: '30px', width: criticalityValue != 'Complied' ? '25%' : '15%', fontSize: '17px', fontFamily: 'serif', textAlign: 'center' }} color="blue" >{criticalityValue != 'Complied' ? `${criticalityValue} compliance` : criticalityValue}</Tag> */}
                                <Tag style={{ borderRadius: '15px', fontSize: '17px', fontFamily: 'serif', textAlign: 'center' }} color="blue" >{criticalityValue != 'Complied' ? `${criticalityValue} compliance` : criticalityValue}</Tag>
                                <div>
                                    <Collapse className={localStorage.getItem('currentTheme') + ' cric'} defaultActiveKey={['0']} >
                                        {tableData && tableData.length > 0 &&
                                            tableData.map((items) => {
                                                // console.log(item, 'item');
                                                let unitName = items.drill_compliances
                                                let keys = Object.keys(unitName);
                                                console.log(items.drill_compliances, 'item.drill_compliances');
                                                return (
                                                    <Panel style={{ marginBottom: '5px' }} header={`${items.le_name}-${items.u_name}`}>
                                                        {keys && keys.length > 0 && keys.map((item, i) => {
                                                            let values = Object.values(unitName);
                                                            console.log(values[i], 'values');
                                                            let childDatas = values
                                                            let tempArr = []
                                                            if (childDatas && childDatas.length > 0) {
                                                                for (let m in childDatas[i]) {
                                                                    console.log(m, 'inndexxx');
                                                                    console.log(childDatas[i][m].comp_name, 'childDatas');
                                                                    tempArr.push({
                                                                        'bg_name': items.bg_name,
                                                                        'le_name': items.le_name,
                                                                        'legal_entity_id': items.legal_entity_id,
                                                                        'div_name': items.div_name,
                                                                        'comp_name': childDatas && childDatas[i][m].comp_name,
                                                                        'criticality': childDatas && childDatas[i][m].criticality,
                                                                        'assignee_name': childDatas && childDatas[i][m].assignee_name,
                                                                        'descp': childDatas && childDatas[i][m].descp,
                                                                        'compfielaw_url': childDatas && childDatas[i][m].compfielaw_url,
                                                                        'ageing': childDatas && childDatas[i][m].ageing,
                                                                        'index': indexValue

                                                                    })
                                                                    { <span hidden>{indexValue = indexValue + 1}</span> }
                                                                    // childDatas[i] = []
                                                                }
                                                                console.log(tempArr, 'tempArr');
                                                            }
                                                            childDatas[i] = tempArr

                                                            return (
                                                                <>
                                                                    <h6 className='m-2 ml-2 mb-2'>{keys[i]}</h6>

                                                                    <Table
                                                                        size={'small'} className='remark-header add-service-prv'
                                                                        columns={columnState === true ? columns1 : columns}
                                                                        dataSource={childDatas[i]}
                                                                        bordered
                                                                        // scroll={{ x: 1000 }}
                                                                        pagination={false}
                                                                        onChange={handleChange}

                                                                    />
                                                                </>
                                                            )

                                                        })}
                                                    </Panel>
                                                )
                                            })
                                        }
                                    </Collapse>
                                </div>
                                {count == showmoreCount ? false :
                                    <div className='text-center mt-2'>
                                        <Button shape='round' className='addbutton'
                                            onClick={showmore}
                                        >Show more records</Button>
                                    </div>
                                }
                            </Card>
                            {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                className='userprivclass' /> */}
                        </div> :
                        <div className="container-fluid pt-1">
                            <div className="row">
                                {/* {resultArray && resultArray.length > 0 ? */}
                                <>
                                    <div className="col-md-12">

                                        {/* <div className="card">
                                            <div className='card-body'> */}

                                        <>
                                            <div id="container" style={{ width: "100%", height: "500px" }}></div>
                                            {resultArray && resultArray.length > 0 ?
                                                <div className="col-md-12 mt-2">
                                                    <div className="row">
                                                        <div className="col-md-6"></div>
                                                        <div className="col-md-6" style={{ textAlign: 'end' }}>
                                                            <label htmlFor=""><b>Time Zone :</b></label>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                onChange={(data) => {
                                                                    setTimeZone(data)
                                                                    setGeneratedOn(true)
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetDateTime_ByZone",
                                                                                {
                                                                                    "tz_name": data
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getTimeZoneData({
                                                                        payload: payload,
                                                                        paramid: paramid
                                                                    })

                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                placeholder="Enter Time Zone"
                                                                style={{ marginLeft: '5%' }}
                                                            // value={compfie.user_Group}

                                                            >

                                                                {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.tz_name}>

                                                                            {item.tz_name}

                                                                        </Option>

                                                                    );

                                                                })}
                                                                {/* );
                                                })} */}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                        </>
                                        {/* </div>
                                        </div> */}
                                    </div>
                                </>
                                {/* : <p style={{ marginTop: '18%', marginLeft: '40%', marginBottom: '18%' }}>No Data to Display </p>} */}
                                {toggleButton === 'Month' ?
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className="col-md-2">
                                                {year === prevYear ? '' :
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                        icon={<ArrowLeftOutlined />} size='default'
                                                        onClick={() => {
                                                            setYear(year - 1)
                                                            setTempArr([])
                                                            setResultArray([])
                                                        }}
                                                    >
                                                        Previous Year
                                                    </Button>}
                                            </div>
                                            <div className="col-md-8"></div>
                                            <div className="col-md-2" style={{ textAlign: 'end' }}>
                                                {year != moment().year() ?
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                        size='default'
                                                        onClick={() => {
                                                            setYear(year + 1)
                                                            setTempArr([])
                                                            setResultArray([])

                                                        }}
                                                    >
                                                        Next Year<ArrowRightOutlined />
                                                    </Button>
                                                    : false}
                                            </div>
                                        </div>
                                    </div> : ''}
                            </div>

                            {/* } */}
                        </div>

                }


                <Drawer title="FILTER BY" className={localStorage.getItem("currentTheme") + ' resize-drawer'} placement="right" closable={false} onClose={false} visible={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', marginLeft: '75', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    // onChange={(data, values) => {
                                    //     setFieldTypeClick('Country')
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         country: data,
                                    //         domain: ''
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         country: values.children,
                                    //         domain: '',
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setType('')

                                    // }
                                    // }
                                    onChange={(option) => HandelCountryChange(option)}
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {uniq_country_data &&
                                        uniq_country_data.length > 0 &&
                                        uniq_country_data.map((item, i) => {
                                            return (
                                                <Option key={item.c_id}>
                                                    {item.c_name}
                                                </Option>
                                            );
                                        })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('BusinessGroup')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onClick={() => {
                                        setFieldTypeClick('LegalEntity')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        // let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // setfield({
                                        //     ...field,
                                        //     legalEntity: filteredLegalEntity
                                        // })
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                            setUnit(filteredUnit)
                                        }
                                        else {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                            console.log(filteredUnit, 'filteredUnit555');
                                            setUnit(filteredUnit)
                                        }

                                        console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Division')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Category')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Unit"
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Unit')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={'popupbtncolour text-center ' + localStorage.getItem('currentTheme')}>
                        <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: '15%', marginTop: '10%' }}
                            onClick={filterChange} icon={<ArrowRightOutlined />}
                            size='default' >
                            Go
                        </Button>
                    </div>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>
            </div>
        </>
    )


}

export default connect(mapStateToProps, {
    getCommonData,
    getComplianceTrendchart,
    getComplianceTrendTableData,
    getTimeZoneData,
    getComplianceTrendShowMoreTableData
})(ComplianceTrend);