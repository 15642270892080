import React from 'react'
import moment from 'moment';
import { Switch, Tag } from 'antd';
import { Collapse, Select, Input, DatePicker, Card, Pagination, Popover, Drawer, Space, Button, Table, Tooltip, Modal, Descriptions, Empty } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { ArrowRightOutlined, FullscreenOutlined, CloseCircleOutlined, CloseOutlined, BookTwoTone, ArrowLeftOutlined, PlayCircleOutlined, SearchOutlined, FilterFilled, FilterOutlined, ExclamationCircleFilled, UpOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getComparativechart, getCommonData, getTimeZoneData, getComparativeTableData } from '../../Store/Action/Dashboard/ComparativeTrendChart';
import { toast } from 'react-toastify';
import { HomeRedirect, CountrySplit } from './../../Libs/country'
// import "core-js/stable";
// import "regenerator-runtime/runtime";
import url, { getUrl } from './../../Libs/URL'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const urls = getUrl()

// import "core-js/stable";
// import "regenerator-runtime/runtime";

const mapStateToProps = (state) => ({
    commonData: state.ComparativeTrend.comparativeTrendChart.Data,
    dataYears: state.ComparativeTrend.comparativeTrendChart.yearData,
    data: state.ComparativeTrend.comparativeTrendChart.chartData,
    time: state.ComparativeTrend.comparativeTrendChart.time,
    timeZoneData: state.ComparativeTrend.comparativeTrendChart.timeZoneData,
    tableValue: state.ComparativeTrend.comparativeTrendChart.tableData
})

const ComparativeTrend = ({
    getFilterValue,
    getComparativechart,
    getCommonData,
    data,
    commonData,
    time,
    getTimeZoneData,
    timeZoneData,
    getComparativeTableData,
    tableValue,
    dataYears
    // tableData
}) => {
    console.log(data, 'data12345');
    console.log(dataYears, 'dataYears');
    const _ = require("lodash");
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [dataValue, setDataValue] = useState([])
    console.log(dataValue, 'dataValue');
    const [notcomplieddata, setNotcomplieddata] = useState([])
    const [delayedData, setDelayedData] = useState([])
    const [toggleButton, setToggleButton] = useState('Month')
    const [yearData, setYearData] = useState([])
    const [taskDistributionTable, setTaskDistributionTable] = useState([])
    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        countryArr: [],
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const { Panel } = Collapse;
    const [chartId, setChartId] = useState()
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    const [type, setType] = useState('')
    const [legalEntityArray, setLegalEntityArray] = useState([])
    const [columnState, setCoulmnState] = useState(false)
    const [open, setOpen] = useState(false)
    const [table, setTable] = useState(false)
    const [clickState, setClickState] = useState(false)
    var [indexValue, setIndexValue] = useState(1)
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [fieldType, setFieldType] = useState("")
    console.log(fieldType, 'fieldType');
    const [newCountryDomain, setNewCountryDomain] = useState([])

    const [unit, setUnit] = useState([])
    const chart = useRef(null);
    const { Option } = Select;
    const [year, setYear] = useState(moment().year())
    const [prevYear, setPrevYear] = useState(moment().year() - 4)
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const [chartType, setChartType] = useState("complied")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    console.log(commonData, 'commonData');
    const location = useLocation();
    const [paramid, setParamId] = useState("")
    const [generatedOn, setGeneratedOn] = useState(false)
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const [go, setGo] = useState(false)
    const [domainName, setDomainName] = useState()
    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [checkBox, setCheckBox] = useState(true)
    const [countryDomain, setCountryDomain] = useState([])
    const [dIds, setDIds] = useState([])
    const country_info = sessionParsedValue.country_info
    const [errorField, setErrorField] = useState('')
    const [fieldTypeClick, setFieldTypeClick] = useState("")
    const [textArray, setTextArray] = useState([])
    const [tableData, setTableData] = useState([])
    const [filteredInfo, setFilteredInfo] = useState({});
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const [countryData, setCountryData] = useState([])
    const [domainData, setDomainData] = useState([])
    const [countryName, setCountryName] = useState()
    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            let textArr = []
            let cNames = []
            let uniqueCountry = []
            let countryName;
            setDomainData(commonData.d_info)
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
                uniqueCountry = _.uniqBy(commonData && commonData.countries, function (e) {
                    return e.c_id;
                });
                setCountryData(uniqueCountry)
                let id = uniqueCountry && uniqueCountry.length > 0 && uniqueCountry.map((item, i) => {
                    return item.c_id
                })
                // setCIds(id)
                console.log(uniqueCountry, 'uniqueCountry');
                countryName = _.find(uniqueCountry, { c_id: Number(commonData.d_info[i].c_id) })

                cNames.push(commonData.d_info[i].d_name)

            }
            // var arr = []
            var obj = {}
            uniqueCountry.forEach(function (a) {
                var arr2 = []
                commonData.d_info.forEach(function (b) {
                    var key = a.c_name
                    if (a.c_id == b.c_id) {
                        arr2.push(b.d_name)
                        obj[key] = arr2.join()
                    }
                });
            });
            let temp = []
            for (let m in obj) {
                console.log(obj[m], 'objmm');
                // let sm = `${m} - ${obj[m]}`
                let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
                temp.push(expectedCountryDomain)
            }
            let newCountryDomain = temp.join("[bold] | [/]")
            // setTextArray(newCountryDomain)
            setDIds(tempIds)
            setCountryDomain(tempArr)
        }


    }, [commonData])

    useEffect(() => {
        var obj = {}
        countryData.forEach(function (a) {
            var arr2 = []
            domainData.forEach(function (b) {
                var key = a.c_name
                if (a.c_id == b.c_id) {
                    arr2.push(b.d_name)
                    obj[key] = arr2.join()
                }
            });
        });
        let temp = []
        for (let m in obj) {
            console.log(obj[m], 'objmm');
            // let sm = `${m} - ${obj[m]}`
            let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
            temp.push(expectedCountryDomain)
        }
        let newCountryDomain = temp.join("[bold] | [/]")
        setTextArray(newCountryDomain)

    }, [countryData, domainData])

    useEffect(() => {
        // if(clickState === true){
        console.log(tableValue, 'tableValue');
        if (tableValue && tableValue.t_drill_down_data && tableValue.t_drill_down_data.length > 0) {
            setTable(true)
        }
        else {
            setTable(false)
        }
        // }

    }, [tableValue])

    useEffect(() => {
        let newCountry = []
        let newDomain = []

        for (let j in filterData.country) {
            let filterCountry = _.filter(commonData.countries, { c_id: Number(filterData.country[j]) })
            // temp2.push(filterDomain)
            for (let i in filterCountry) {
                newCountry.push(filterCountry[i])
            }

        }
        for (let k in filterData.domain) {
            let filterDomain = _.filter(commonData.d_info, { d_id: Number(filterData.domain[k]) })
            // temp2.push(filterDomain)
            for (let l in filterDomain) {
                newDomain.push(filterDomain[l])
            }

        }
        const uniqueCountry = _.uniqBy(newCountry && newCountry, function (e) {
            return e.c_id;
        });
        setCountryData(uniqueCountry)
        setDomainData(newDomain)
        console.log(newCountry, 'newCountry');
        console.log(newDomain, 'newDomain');
        console.log(filterData.country, 'filterData.country');
        console.log(filterData.domain, 'filterData.domain');

    }, [filterData.country, filterData.domain])

    useEffect(() => {
        if (chartType == 'complied') {
            if (dataValue && dataValue.length > 0) {
                let counName = _.find(uniq_country_data, { c_id: Number(dataValue && dataValue[0].filter_id) })
                console.log(counName.c_name, 'cnameeee');
                setCountryName(counName.c_name)
            }
        }
        if (chartType == 'notComplied') {
            if (notcomplieddata && notcomplieddata.length > 0) {
                let counName = _.find(uniq_country_data, { c_id: Number(notcomplieddata && notcomplieddata[0].filter_id) })
                console.log(counName.c_name, 'cnameeee');
                setCountryName(counName.c_name)
            }
        }
        if (chartType == 'delayedComplied') {
            if (delayedData && delayedData.length > 0) {
                let counName = _.find(uniq_country_data, { c_id: Number(delayedData && delayedData[0].filter_id) })
                console.log(counName.c_name, 'cnameeee');
                setCountryName(counName.c_name)
            }
        }

    }, [dataValue, notcomplieddata, delayedData, uniq_country_data])


    const HandelCountryChange = (obj) => {
        console.log(obj, 'objj');
        var numberArray = obj.map(Number);
        console.log(numberArray, 'numberArray');
        let temp5 = []
        let filterDomain = []
        for (let j in obj) {
            filterDomain = _.filter(commonData.d_info, { c_id: Number(obj[j]) })
            // temp2.push(filterDomain)
            for (let i in filterDomain) {
                temp5.push(filterDomain[i])
            }

        }
        let id = temp5 && temp5.length > 0 && temp5.map((item, i) => {
            return item.d_id
        })
        console.log(id, 'temp5');
        setFieldTypeClick('Country')
        setType('')
        setFilterData({
            ...filterData,
            country: obj,
            countryArr: numberArray,
            domain: id
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            country: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
    }
    console.log(tableData, 'tableData');
    console.log(tableValue, 'tableValue');



    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    autoFocus={'on'}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: '10%',
            align: 'center'
        },
        ...((fieldType === 'LegalEntity' && go === true) || (fieldType === 'Category' && go === true) || (fieldType === 'Unit' && go === true) ?
            [] :
            [{
                title: "Business Group",
                ...getColumnSearchProps('bg_name', "Business Group"),
                dataIndex: 'bg_name',
                key: 'bg_name',
                ellipsis: false,
                width: '25%',
                filteredValue: filteredInfo.bg_name || null,
            },
            {
                title: 'Legal Entity',
                ...getColumnSearchProps('le_name', 'Legal Entity'),
                dataIndex: false,
                key: 'le_name',
                ellipse: false,
                width: '25%',
                filteredValue: filteredInfo.le_name || null,
                render: (record) => {
                    return <p title={record.le_name} className='cut-text'>
                        {record.le_name}</p>
                }
            }
            ]
        ),
        {
            title: 'Division',
            ...getColumnSearchProps('div_name', 'Division'),
            dataIndex: false,
            key: 'div_name',
            // width: '30%',
            ellipsis: false,
            filteredValue: filteredInfo.div_name || null,
            render: (record) => {
                return <p title={record.div_name} className='cut-text'>
                    {record.div_name}</p>
            }

        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            width: '90%',
            ellipse: false,
            render: (row, record) => {
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Tooltip title={
                                                <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                            <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                                        </tr>
                                                        <tr>
                                                            <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                                            <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }></Tooltip><ExclamationCircleFilled className='me-1' />
                                            {/* <Popover content={content} title="">
                                                <Button style={{ border: 0 }}>
                                                <i class="bi-info-circle-fill"></i>
                                                </Button>
                                                <ExclamationCircleFilled className='me-1' />
                                            </Popover> */}
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.div_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div>
                                {/* <Popover content={content} title="">
                                    <Button style={{ border: 0 }}>
                                    <i class="bi-info-circle-fill"></i>
                                    <ExclamationCircleFilled className='me-1' />
                                    </Button>
                                </Popover> */}
                                <Tooltip title={
                                    <table style={{ width: "100%", borderCollapse: 'collapse', border: '1px solid #636262', tableLayout: 'auto' }}>
                                        <tbody>
                                            <tr>
                                                <th style={{ border: '1px solid #636262', marginLeft: '5%', marginRight: '5%' }}><center>Compliance Description</center></th>
                                                <th style={{ border: '1px solid #636262' }}><center>Document Reference Number</center></th>

                                            </tr>
                                            <tr>
                                                <td style={{ border: '1px solid #636262' }}><center>{record.descp ? record.descp : '-'}</center></td>
                                                <td style={{ border: '1px solid #636262' }}><center>{record.doc_ref_num ? record.doc_ref_num : '-'}</center></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }><ExclamationCircleFilled className='me-1' /></Tooltip>
                                {record.comp_name}f
                            </div>
                        </div>

                    </>
                )
            },
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', "Criticality"),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: '100%',
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', "Assigned To"),
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: '100%',
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        ...(chartType === 'complied' ?
            [] :
            [{
                title: chartType === "notComplied" ? "Over Due" : chartType === 'complied' ? '' : "Delayed By",
                ...getColumnSearchProps('ageing', 'Over Due'),
                dataIndex: 'ageing',
                key: 'ageing',
                width: 12,
                ellipsis: false,
                render: (row, record) => {
                    if (chartType === 'complied') {
                        return null
                    }
                    else {
                        return (
                            <>
                                <p>{record.ageing}</p>
                            </>
                        )
                    }
                },
                width: 13,
                align: 'center',
                filteredValue: filteredInfo.ageing || null,
                // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
                // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


            }])
    ];

    const columns1 = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            // render: (row, index, i) => {
            //     return (
            //         <><p>{(counts + 1)}</p>
            //             <span hidden>{counts = counts + 1}</span>
            //         </>)
            // },
            width: 7,
            align: 'center'
        },
        {
            title: 'Compliance',
            ...getColumnSearchProps('comp_name', 'Compliance'),
            dataIndex: false,
            key: 'comp_name',
            ellipse: true,
            render: (row, record) => {
                console.log(record, 'record');
                if (record.compfielaw_url != '') {
                    const content = (
                        <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                            <tbody>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{record.descp}</td>
                                </tr>
                                <tr>
                                    <th>Document Reference Number</th>
                                    <td>{record.doc_ref_num}</td>
                                </tr>
                            </tbody>
                        </table>
                    );
                    return (
                        <>
                            <div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Popover content={content} title="">
                                                <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                            </Popover>
                                        </div>
                                        <div className="col-md-10" style={{ marginTop: '2%' }}>
                                            <p title={record.comp_name} className='cut-text'>
                                                <Tooltip style={{ marginRight: '20%' }} title='Compfie Law Knowlegde Portal'>
                                                    <a href={`${record.compfielaw_url}`}>
                                                        <BookTwoTone />
                                                    </a>
                                                </Tooltip>&nbsp;{record.comp_name}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </>
                    )
                }
                const content = (
                    <table style={{ width: "450px", display: 'block', height: '100%', overflowY: 'scroll' }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{record.descp}</td>
                            </tr>
                            <tr>
                                <th>Document Reference Number</th>
                                <td>{record.doc_ref_num}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>{record.comp_name}
                            </div>
                        </div>

                    </>
                )
            },
            width: 25,
            filteredValue: filteredInfo.comp_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        },
        {
            title: "Criticality",
            ...getColumnSearchProps('criticality', "Criticality"),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.criticality}</p>
            //         </>
            //     )
            // },
            width: 13,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,


        },
        {
            title: "Assigned To",
            ...getColumnSearchProps('assignee_name', "Assigned To"),
            dataIndex: false,
            key: 'assignee_name',
            ellipsis: false,
            render: (record) => {
                return <p title={record.assignee_name} className='cut-text'>
                    {record.assignee_name}</p>
            },
            // render: (row, record) => {
            //     return (
            //         <>
            //             <p>{record.assignee_name}</p>
            //         </>
            //     )
            // },
            width: 13,
            filteredValue: filteredInfo.assignee_name || null,
            // sorter: (a, b) => a.s_p_name.localeCompare(b.s_p_name),
            // sortOrder: sortedInfo.columnKey === 's_p_name' ? sortedInfo.order : null,

        }
    ]

    useEffect(() => {
        if (tableValue) {
            setTableData(tableValue.t_drill_down_data)
        }

    }, [tableValue])

    useEffect(() => {
        if (fieldTypeClick === 'BusinessGroup') {
            if (filterDataNames.business === '') {
                setErrorField('Business Group Required')
            }
        }

        if (fieldTypeClick === 'LegalEntity') {
            if (filterDataNames.legalentity === '') {
                setErrorField('Legal Entity Required')
            }
        }
        if (fieldTypeClick === 'Division') {
            if (filterDataNames.division === '') {
                setErrorField('Division Required')
            }
        }
        if (fieldTypeClick === 'Category') {
            if (filterDataNames.category === '') {
                setErrorField('Category Required')
            }
        }
        if (fieldTypeClick === 'Unit') {
            if (filterDataNames.unit === '') {
                setErrorField('Unit Required')
            }
        }

    }, [fieldTypeClick])

    useEffect(() => {
        if (fieldTypeClick === 'Country') {
            if (filterData.country.length === 0) {
                setErrorField('Country Required')
            }
            else {
                setErrorField('')

            }
        }
        if (fieldTypeClick === 'Domain') {
            if (filterData.domain.length === 0) {
                setErrorField('Domain Required')
            }
            else {
                setErrorField('')

            }
        }

    }, [fieldTypeClick, filterData])

    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    useEffect(() => {
        {
            var the_arr = location.pathname.split('/');
            the_arr.pop();
            let a = the_arr.join('/')
            setParamId(a)
        }
    }, []);
    console.log(data, 'datadatadata');
    console.log(go, 'goooooo');
    // useEffect(() => {
    //     if (tableData) {
    //         setTaskDistributionTable(tableData.r_drill_down_data)
    //     }

    // }, [tableData])
    // console.log(tableData, 'taskDistributionTable');

    useEffect(() => {
        if (commonData) {
            let unique = []
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                unique = _.uniqBy(commonData && commonData.bg_groups, function (e) {
                    return e.bg_id;
                });
            }
            setfield({
                ...field,
                domain: commonData && commonData.d_info,
                legalEntity: commonData && commonData.le_did_infos,
                country: commonData && commonData.countries,
                business: unique
            })
            let uniqueDiv = []
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                uniqueDiv = _.uniqBy(commonData && commonData.div_infos, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)
            setCategory(commonData && commonData.cat_info)
            setUnit(commonData && commonData.chart_units)
        }

    }, [isAuth, entityid, commonData])

    console.log(field, 'fieldfield');
    console.log(commonData, ' commonDatacommonDatacommonData');

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])

    console.log(data, 'data');

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') !== "All Legal Entity") {
            if (data && data.length > 0) {
                let temp = []
                let temp2 = []
                let temp3 = []
                console.log(data, 'data6767');
                for (let i in data) {
                    temp.push({
                        "filter_id": data && data.length > 0 && data[i].filter_id,
                        "month": data && data.length > 0 && data[i].chart_year,
                        "value": data && data.length > 0 && data[i].complied_compliances_count,
                        "total": data && data.length > 0 && data[i].total_compliances,
                        "valuecount": data && data.length > 0 && data[i].complied_compliances_count
                    })
                    temp2.push({
                        "filter_id": data && data.length > 0 && data[i].filter_id,
                        "month": data && data.length > 0 && data[i].chart_year,
                        "value": data && data.length > 0 && data[i].not_complied_count,
                        "total": data && data.length > 0 && data[i].total_compliances,
                        "valuecount": data && data.length > 0 && data[i].not_complied_count

                    })
                    temp3.push({
                        "filter_id": data && data.length > 0 && data[i].filter_id,
                        "month": data && data.length > 0 && data[i].chart_year,
                        "value": data && data.length > 0 && data[i].delayed_count,
                        "total": data && data.length > 0 && data[i].total_compliances,
                        "valuecount": data && data.length > 0 && data[i].delayed_count
                    })
                }
                // sortByMonth(temp);



                // function sortByMonth(arr) {
                //     console.log(arr, 'snehaaaaaaaa');
                //     var months = ["Jan 2022", "Feb 2022", "Mar 2022", "Apr 2022", "May 2022", "Jun 2022",
                //         "Jul 2022", "Aug 2022", "Sep 2022", "Aug 2022", "Nov 2022", "Dec 2022"];
                //     let data = arr.sort(function (a, b) {
                //         return months.indexOf(a.month
                //         )
                //             - months.indexOf(b.month
                //             );
                //     });
                //     console.log(data,'data5555');
                // }
                console.log(temp, 'temp12345');
                setDataValue(temp)
                setNotcomplieddata(temp2)
                setDelayedData(temp3)
            }
        }

    }, [data, localStorage.getItem('SelectedEntity')])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            if (data && data.length > 0) {
                let temparr = []
                let temparr2 = []
                let temp = []
                let temp2 = []
                let temp3 = []
                let notcompliedTemp = []
                let delayedTemp = []
                let totalTemp = []
                console.log(data, 'dataAishuVig');
                for (let i in data) {

                    let uniqueObjArray = [...new Map(data && data.length > 0 && data.map((item) => [item["chart_year"], item])).values()];
                    const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                        return item.chart_year
                    })
                    temp = uniqueIds
                    console.log(uniqueIds, 'uniqueIds');

                    // temp.push({
                    //     "month": data && data.length > 0 && data[i].chart_year,
                    //     "value": data && data.length > 0 && data[i].complied_compliances_count,
                    //     "total": data && data.length > 0 && data[i].total_compliances,
                    //     "values": data && data.length > 0 && data[i].complied_compliances_count
                    // })
                    // temp2.push({
                    //     "month": data && data.length > 0 && data[i].chart_year,
                    //     "value": data && data.length > 0 && data[i].not_complied_count,
                    //     "total": data && data.length > 0 && data[i].total_compliances,
                    //     "values": data && data.length > 0 && data[i].not_complied_count

                    // })
                    // temp3.push({
                    //     "month": data && data.length > 0 && data[i].chart_year,
                    //     "value": data && data.length > 0 && data[i].delayed_count,
                    //     "total": data && data.length > 0 && data[i].total_compliances,
                    //     "values": data && data.length > 0 && data[i].delayed_count
                    // })
                }
                for (let k in temp) {
                    if (data[k].chart_year == temp[k]) {
                        let filterValue = _.filter(data, { chart_year: temp[k] })
                        console.log(filterValue, 'aishhhhhhhhhh');
                        let compliedCount = filterValue.map((item, i) => {
                            return item.complied_compliances_count
                        })
                        let compliedCounts = compliedCount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        let notCount = filterValue.map((item, i) => {
                            return item.not_complied_count
                        })
                        let notcounts = notCount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        let deyaledcount = filterValue.map((item, i) => {
                            return item.delayed_count
                        })
                        let deyaledcounts = deyaledcount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        let totalcount = filterValue.map((item, i) => {
                            return item.total_compliances
                        })
                        let totalcounts = totalcount.reduce(function (accumulator, curValue) {
                            return accumulator + curValue
                        }, 0)
                        temp2.push(compliedCounts)
                        notcompliedTemp.push(notcounts)
                        delayedTemp.push(deyaledcounts)
                        totalTemp.push(totalcounts)


                    }
                }
                for (let v in temp) {
                    temparr.push({
                        "filter_id": data && data.length > 0 && data[v].filter_id,
                        "month": toggleButton === 'Month' ? data && data.length > 0 && data[v].chart_year : `${data && data.length > 0 && data[v].chart_year}`,
                        "value": temp2 && temp2.length > 0 && temp2[v],
                        "total": totalTemp && totalTemp.length > 0 && totalTemp[v],
                        "valuecount": temp2 && temp2.length > 0 && temp2[v],

                    })
                    temparr2.push({
                        "filter_id": data && data.length > 0 && data[v].filter_id,
                        "month": toggleButton === 'Month' ? data && data.length > 0 && data[v].chart_year : `${data && data.length > 0 && data[v].chart_year}`,
                        "value": notcompliedTemp && notcompliedTemp.length > 0 && notcompliedTemp[v],
                        "total": totalTemp && totalTemp.length > 0 && totalTemp[v],
                        "valuecount": notcompliedTemp && notcompliedTemp.length > 0 && notcompliedTemp[v],

                    })
                    temp3.push({
                        "filter_id": data && data.length > 0 && data[v].filter_id,
                        "month": toggleButton === 'Month' ? data && data.length > 0 && data[v].chart_year : `${data && data.length > 0 && data[v].chart_year}`,
                        "value": delayedTemp && delayedTemp.length > 0 && delayedTemp[v],
                        "total": totalTemp && totalTemp.length > 0 && totalTemp[v],
                        "valuecount": delayedTemp && delayedTemp.length > 0 && delayedTemp[v],

                    })
                }
                console.log(temparr, 'temparr');
                if (toggleButton === 'Month') {
                    sortByMonth(temparr);
                    function sortByMonth(arr) {
                        console.log(arr, 'arrrrrrrrr');
                        var months = ["Jan 2022", "Feb 2022", "Mar 2022", "Apr 2022", "May 2022", "Jun 2022",
                            "Jul 2022", "Aug 2022", "Sep 2022", "Aug 2022", "Nov 2022", "Dec 2022"];
                        arr.sort(function (a, b) {
                            return months.indexOf(a.month
                            )
                                - months.indexOf(b.month
                                );
                        });
                    }
                }
                console.log(temparr, 'complied');
                console.log(temparr2, 'notcomplied');
                console.log(temp3, 'delayedcomplied');
                console.log(totalTemp, 'totalcomplied');
                console.log(temp, 'temp');
                setDataValue(temparr)
                setNotcomplieddata(temparr2)
                setDelayedData(temp3)

                // setDataValue(temp)
                // setNotcomplieddata(temp2)
                // setDelayedData(temp3)
            }
        }


    }, [data, localStorage.getItem('SelectedEntity')])




    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityid])

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])

    useEffect(() => {
        if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":
                        ["GetComparativeChart",

                            {
                                "c_ids": [
                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                ],
                                "d_ids": dIds,
                                "filter_type": "Group",
                                "filter_ids": [
                                    1
                                ],
                                "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                                "country_domains": countryDomain,
                                "year": `${year}`,
                                "chart_types": "Month"
                            }
                        ]
                }
            ]
            if (paramid != "") {
                getComparativechart({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityid, commonData, entityData, year, countryDomain, dIds])


    useEffect(() => {
        if (toggleButton === 'Year') {
            let temp = []
            if (dataYears && dataYears.years) {
                for (let i in dataYears.years) {
                    temp.push(dataYears.years[i])
                }
                setYearData(temp)
            }
        }
    }, [dataYears.years, toggleButton])


    const dataSource = [
        {
            key: '1',
            name: 'Rule 18',
            task: 'Trail Task117',
            description: 'test',
            p_cons: "Shall be punishable with imprisonment for a term which shall not be less than six months but which may extend to three years and with fine which shall not be less than fifty thousand rupees but which may extend to two lakh rupees - Section 63",
            address: '10 Downing Street',
            frequency: 'periodical',
            task_status: 'opted',
            trigger_before_days: 10,
            task: 'Trail Task119',
            summary: "Repeats every 1 Month(s) "
        },
        {
            key: '2',
            name: 'Rule 3',
            description: 'Online application can be submitted for one musical license covering all branches of pan India by paying prescribed fees. Music license is applicable for music played for public in TV, radio, live performances, DJ by internet or even copied in CD, DVD or pen drives/hard disc',
            address: '10 Downing Street',
            frequency: 'Review',
            task_status: 'Not opted',
            task: 'Trail Task',
            summary: "Repeats every 6 Month(s) ",
            trigger_before_days: 20,
            p_cons: "Section 23 - Imprisonment up to 3 months or with fine up to Rs.1000/- or with both. If the contravention is continued fine may extend to Rs.100/- for each day",
        },
        {
            key: '3',
            name: 'Rule 12',
            description: 'test',
            frequency: 'OnOccurence',
            address: '10 Downing Street',
            p_cons: "test",
            task: 'Trail Task55',
            task_status: 'opted',
            trigger_before_days: 25,
            summary: "Repeats every 1 Month(s) "
        },
    ];

    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    const onClose = () => {
        setOpen(false);
    };
    const filterChange = () => {
        setDataValue([])
        setNotcomplieddata([])
        setGo(true)
        setGeneratedOn(false)
        setDelayedData([])
        if (groupCheckBox === true && errorField === '') {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComparativeChart",
                        {
                            "c_ids": filterData.countryArr,
                            "d_ids": filterData.domain,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids": [
                                (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                    (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                        (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                            (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                                (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                    (fieldType != "" && type === 'Group') ? 1 :
                                                        1
                            ],

                            "le_ids":
                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                            ,
                            "country_domains": newCountryDomain,
                            "year": `${year}`,
                            "chart_types": "Month"
                        }
                    ]
                }
            ]

            getComparativechart({
                payload: payload,
                paramid: paramid
            })
            setOpen(false);

        }
        else {
            // toast.error('Please select the filter')
            toast.error(errorField ? errorField : 'Please select the filter')
        }
    }


    console.log(dataValue, 'dataVallll');

    //reference chart for multiple chart

    // useEffect(() => {
    //     /**
    //      * ---------------------------------------
    //      * This demo was created using amCharts 4.
    //      *
    //      * For more information visit:
    //      * https://www.amcharts.com/
    //      *
    //      * Documentation is available at:
    //      * https://www.amcharts.com/docs/v4/
    //      * ---------------------------------------
    //      */

    //     am4core.useTheme(am4themes_animated);

    //     // Create chart instance
    //     var chart = am4core.create("chartdiv", am4charts.XYChart);

    //     // Add data
    //     chart.data = [{
    //         "date": new Date(2018, 0, 1),
    //         "value": 450,
    //         "value2": 362,
    //         "value3": 699
    //     }, {
    //         "date": new Date(2018, 0, 2),
    //         "value": 269,
    //         "value2": 450,
    //         "value3": 841
    //     }, {
    //         "date": new Date(2018, 0, 3),
    //         "value": 700,
    //         "value2": 358,
    //         "value3": 699
    //     }, {
    //         "date": new Date(2018, 0, 4),
    //         "value": 490,
    //         "value2": 367,
    //         "value3": 500
    //     }, {
    //         "date": new Date(2018, 0, 5),
    //         "value": 500,
    //         "value2": 485,
    //         "value3": 369
    //     }, {
    //         "date": new Date(2018, 0, 6),
    //         "value": 550,
    //         "value2": 354,
    //         "value3": 250
    //     }, {
    //         "date": new Date(2018, 0, 7),
    //         "value": 420,
    //         "value2": 350,
    //         "value3": 600
    //     }];

    //     // Create axes
    //     var categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
    //     categoryAxis.renderer.grid.template.location = 0;
    //     categoryAxis.renderer.minGridDistance = 30;


    //     // Create series
    //     function createSeriesAndAxis(field, name, topMargin, bottomMargin) {
    //         var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    //         var series = chart.series.push(new am4charts.LineSeries());
    //         series.dataFields.valueY = field;
    //         series.dataFields.dateX = "date";
    //         series.name = name;
    //         series.tooltipText = "{dateX}: [b]{valueY}[/]";
    //         series.strokeWidth = 2;
    //         series.yAxis = valueAxis;

    //         valueAxis.renderer.line.strokeOpacity = 1;
    //         valueAxis.renderer.line.stroke = series.stroke;
    //         valueAxis.renderer.grid.template.stroke = series.stroke;
    //         valueAxis.renderer.grid.template.strokeOpacity = 0.1;
    //         valueAxis.renderer.labels.template.fill = series.stroke;
    //         valueAxis.renderer.minGridDistance = 20;
    //         valueAxis.align = "right";

    //         if (topMargin && bottomMargin) {
    //             valueAxis.marginTop = 10;
    //             valueAxis.marginBottom = 10;
    //         }
    //         else {
    //             if (topMargin) {
    //                 valueAxis.marginTop = 20;
    //             }
    //             if (bottomMargin) {
    //                 valueAxis.marginBottom = 20;
    //             }
    //         }

    //         var bullet = series.bullets.push(new am4charts.CircleBullet());
    //         bullet.circle.stroke = am4core.color("#fff");
    //         bullet.circle.strokeWidth = 2;
    //     }

    //     createSeriesAndAxis("value", "Series #1", false, true);
    //     createSeriesAndAxis("value2", "Series #2", true, true);
    //     createSeriesAndAxis("value3", "Series #3", true, false);

    //     chart.legend = new am4charts.Legend();
    //     chart.cursor = new am4charts.XYCursor();

    //     chart.leftAxesContainer.layout = "vertical";
    // }, [])

    useEffect(() => {
        // if (dataValue && dataValue.length > 0) {
        console.log('test');
        if (table == false) {
            if (chartType == 'complied') {
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
                // Create a container
                var container = am4core.create("container", am4core.Container);
                container.width = am4core.percent(100);
                container.height = am4core.percent(100);
                container.layout = "vertical";
                container.background.fill = am4core.color("#000");
                container.background.fillOpacity = 0.1;
                container.background.stroke = am4core.color("#000");
                container.background.strokeOpacity = 0.2;
                container.background.strokeWidth = 2;

                if (dataValue && dataValue.length > 0) {

                    function addChart(data) {
                        console.log(data, 'data');
                        var chart = container.createChild(am4charts.XYChart3D);
                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        chart.exporting.menu.align = "right";
                        chart.exporting.menu.verticalAlign = "top";
                        // Add data
                        chart.data = data
                        chart.height = 550

                        // Create axes
                        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                        // dateAxis.renderer.minGridDistance = 4
                        dateAxis.title.text = "Month-Year";
                        dateAxis.renderer.minGridDistance = 50;
                        dateAxis.renderer.labels.template.text = `{month}`
                        // pieSeries.labels.template.text = "{category}: {value}";

                        var range = dateAxis.axisRanges.create();
                        range.label.text = `{month}`;


                        // categoryAxis.renderer.labels.template.events.on("hit", function (event) {}



                        // dateAxis.renderer.labels.template.fontSize = 8;
                        // Create value axis
                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        valueAxis.calculateTotals = true;
                        valueAxis.min = 0;
                        valueAxis.max = 100;
                        valueAxis.renderer.minGridDistance = 12;
                        valueAxis.title.text = "Compliance(%)";
                        // Create series
                        var lineSeries = chart.series.push(new am4charts.LineSeries());
                        lineSeries.dataFields.dateX = "month";
                        lineSeries.dataFields.valueY = "value";

                        console.log(lineSeries.dataItems, 'lineSeries.dataFields.value');

                        dateAxis.renderer.labels.template.events.on("hit", function (event) {
                            console.log('hiiiiii');
                            console.log(event, 'evee');
                            console.log(event.target.currentText, 'currrrr')
                            setClickState(true)
                            // setTable(true)
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetComparativeChartDrillDownData",
                                        {
                                            "c_ids": [
                                                filterData && filterData.country ? Number(filterData && filterData.country) : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                            ],
                                            "d_ids": dIds,
                                            "filter_type": fieldType != "" ? fieldType : "Group",
                                            "filter_ids": [
                                                fieldType == "LegalEntity" ? filterData.legalentity :
                                                    fieldType == 'Division' ? Number(filterData.division) :
                                                        fieldType == 'Category' ? Number(filterData.category) :
                                                            fieldType == 'Unit' ? Number(filterData.unit) :
                                                                fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                    1
                                            ],
                                            "year": `${year}`,
                                            "chart_month": (event.target.currentText === 'Feb' ? 2 : (event.target.currentText === 'Mar' ? 3 : (event.target.currentText === 'Apr' ? 4 : (event.target.currentText === 'May' ? 5 : (event.target.currentText === 'Jun' ? 6 : (event.target.currentText === 'Jul' ? 7 : (event.target.currentText === 'Aug' ? 8 : (event.target.currentText === 'Sep' ? 9 : (event.target.currentText === 'Oct' ? 10 : (event.target.currentText === 'Nov' ? 11 : (event.target.currentText === 'Dec' ? 12 : 1))))))))))),
                                            "record_count": 0,
                                            "le_ids":
                                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                            ,
                                            "country_domains": countryDomain,
                                            "compliance_status": "Complied"
                                        }
                                    ]
                                }
                            ]
                            if (paramid != "") {
                                getComparativeTableData({
                                    payload: payload,
                                    paramid: paramid
                                })
                            }
                        })
                        dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;



                        // lineSeries.name = countryName
                        lineSeries.name = ((fieldType == "LegalEntity" && go == true) ? filterDataNames.legalentity : (
                            (fieldType == "Division" && go == true) ? filterDataNames.division : (
                                (fieldType == "Category" && go == true) ? filterDataNames.category : (
                                    (fieldType == "Unit" && go == true) ? filterDataNames.unit : countryName
                                ))
                        ))
                        lineSeries.dataFields.value = "totalPercent";
                        lineSeries.calculatePercent = true;
                        lineSeries.calculateTotals = true;
                        lineSeries.tooltipText = "{valueX}"
                        lineSeries.strokeWidth = 2;
                        lineSeries.dataFields.valueXShow = "totalPercent";
                        lineSeries.dataItems.template.locations.categoryY = 0.5;
                        // Add simple bullet
                        var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
                        bullet.circle.stroke = am4core.color("#fff");
                        bullet.circle.strokeWidth = 2;
                        bullet.tooltipText = "{name}: {valueY.formatNumber('#.00')}[/]% : ({valuecount} out of {total}[/])";
                        chart.legend = new am4charts.Legend();


                        chart.exporting.dataFields = {
                            "month": "Month - Year",
                            "valuecount": `${countryName}_Complied`,
                            "total":`${countryName}_TotalCompliance`
                        }

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        var title = chart.titles.create();
                        title.text = generatedOn == true ? `Generated on : ${timeZoneData.tz_name} ${timeZone}` : `Generated on : ${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'
                        title.marginBottom = '2%'

                        // var title1 = chart.titles.create();
                        // title1.text = 'Complied'
                        // title1.disabled = false;
                        // title1.align = 'center'
                        // title1.marginTop = '2%'
                        // title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        var title1 = chart.titles.create();
                        // title1.text =
                        //     ((fieldType == "LegalEntity" && go == true && type === '') ? `Complied of Legal Entity [bold]${filterDataNames.legalentity}[/] (${year})` : (
                        //         (fieldType == "Division" && go == true && type === '') ? `Complied of Division [bold]${filterDataNames.division}[/] (${year})` : (
                        //             (fieldType == "Category" && go == true && type === '') ? `Complied of Category [bold]${filterDataNames.category}[/] (${year})` : (
                        //                 (fieldType == "BusinessGroup" && go == true && type === '') ? `Complied of Business Group [bold]${filterDataNames.business}[/] (${year})` : (
                        //                     (fieldType == "Unit" && go == true && type === '') ? `Complied of Unit [bold]${filterDataNames.unit}[/] (${year})` : `Complied (${year})`
                        //                 )))
                        //     ))
                        title1.text =
                            ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Complied of [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Complied of [bold]${filterDataNames.division}[/] (${year})` : (
                                    (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Complied of [bold]${filterDataNames.category}[/] (${year})` : (
                                        (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Complied of [bold]${filterDataNames.unit}[/] (${year})` : (
                                            (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Complied of [bold]${filterDataNames.business}[/] (${year})` : (
                                                (toggleButton === 'Month') ? `Complied (${year})` :
                                                    (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Complied of [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                                        (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Complied of [bold]${filterDataNames.division}[/] (${year})` : (
                                                            (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Complied of [bold]${filterDataNames.category}[/] (${year})` : (
                                                                (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Complied of [bold]${filterDataNames.unit}[/] (${year})` : (
                                                                    (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Complied of [bold]${filterDataNames.business}[/] (${year})` :
                                                                        `Complied ${commonData && commonData.g_name} (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
                                                                ))))))))
                            ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        title1.fontSize = '15px'
                        title1.fontWeight = '400'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'

                    }

                    addChart(dataValue);
                }

                else {
                    var title3 = container.createChild(am4core.Label)
                    title3.text = "No Data to Display"
                    title3.disabled = false;
                    title3.align = 'left'
                    title3.paddingTop = '45%'
                    title3.paddingLeft = '43%'
                    // title3.fontWeight = '500'
                    title3.fontSize = '16'
                }

            }
            else if (chartType == "notComplied") {
                // Themes begin
                am4core.useTheme(am4themes_animated);
                // var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
                // Create a container
                var container = am4core.create("container", am4core.Container);
                container.width = am4core.percent(100);
                container.height = am4core.percent(100);
                container.layout = "vertical";
                container.background.fill = am4core.color("#000");
                container.background.fillOpacity = 0.1;
                container.background.stroke = am4core.color("#000");
                container.background.strokeOpacity = 0.2;
                container.background.strokeWidth = 2;

                if (notcomplieddata && notcomplieddata.length > 0) {
                    function addChart(data) {
                        var chart = container.createChild(am4charts.XYChart3D);
                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        chart.exporting.menu.align = "right";
                        chart.exporting.menu.verticalAlign = "top";
                        // Add data
                        chart.data = data
                        // Create axes
                        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                        dateAxis.renderer.minGridDistance = 1
                        dateAxis.title.text = "Month-Year";
                        // Create value axis
                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        valueAxis.calculateTotals = true;
                        valueAxis.min = 0;
                        valueAxis.max = 100;
                        valueAxis.renderer.minGridDistance = 10;
                        valueAxis.title.text = "Compliance(%)";
                        // Create series
                        var lineSeries = chart.series.push(new am4charts.LineSeries());
                        lineSeries.dataFields.valueY = "value";
                        lineSeries.dataFields.dateX = "month";

                        lineSeries.name = ((fieldType == "LegalEntity" && go == true) ? filterDataNames.legalentity : (
                            (fieldType == "Division" && go == true) ? filterDataNames.division : (
                                (fieldType == "Category" && go == true) ? filterDataNames.category : (
                                    (fieldType == "Unit" && go == true) ? filterDataNames.unit : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name
                                ))
                        ))

                        dateAxis.renderer.labels.template.events.on("hit", function (event) {
                            console.log('hiiiiii');
                            console.log(event, 'evee');
                            console.log(event.target.currentText, 'currrrr')
                            setClickState(true)
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetComparativeChartDrillDownData",
                                        {
                                            "c_ids": [
                                                filterData && filterData.country ? Number(filterData && filterData.country) : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                            ],
                                            "d_ids": dIds,
                                            "filter_type": fieldType != "" ? fieldType : "Group",
                                            "filter_ids": [
                                                fieldType == "LegalEntity" ? filterData.legalentity :
                                                    fieldType == 'Division' ? Number(filterData.division) :
                                                        fieldType == 'Category' ? Number(filterData.category) :
                                                            fieldType == 'Unit' ? Number(filterData.unit) :
                                                                fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                    1
                                            ],
                                            "year": `${year}`,
                                            "chart_month": (event.target.currentText === 'Feb' ? 2 : (event.target.currentText === 'Mar' ? 3 : (event.target.currentText === 'Apr' ? 4 : (event.target.currentText === 'May' ? 5 : (event.target.currentText === 'Jun' ? 6 : (event.target.currentText === 'Jul' ? 7 : (event.target.currentText === 'Aug' ? 8 : (event.target.currentText === 'Sep' ? 9 : (event.target.currentText === 'Oct' ? 10 : (event.target.currentText === 'Nov' ? 11 : (event.target.currentText === 'Dec' ? 12 : 1))))))))))),
                                            "record_count": 0,
                                            "le_ids":
                                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                            ,
                                            "country_domains": countryDomain,
                                            "compliance_status": "Not Complied"
                                        }
                                    ]
                                }
                            ]
                            if (paramid != "") {
                                getComparativeTableData({
                                    payload: payload,
                                    paramid: paramid
                                })
                            }
                        })
                        dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

                        lineSeries.dataFields.value = "totalPercent";
                        lineSeries.calculatePercent = true;
                        lineSeries.calculateTotals = true;
                        lineSeries.tooltipText = "{valueX}"
                        lineSeries.strokeWidth = 2;
                        lineSeries.dataFields.valueXShow = "totalPercent";
                        // Add simple bullet
                        var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
                        bullet.circle.stroke = am4core.color("#fff");
                        bullet.circle.strokeWidth = 2;
                        bullet.tooltipText = "{name}: {valueY.formatNumber('#.00')}[/]% : ({valuecount} out of {total}[/]}";
                        chart.legend = new am4charts.Legend();

                        chart.exporting.dataFields = {
                            "month": "Month - Year",
                            "valuecount": `${countryName}_Not Complied`,
                            "total":`${countryName}_TotalCompliance`
                        }

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        var title = chart.titles.create();
                        title.text = generatedOn === true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)}`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'
                        title.marginBottom = '2%'

                        // var title1 = chart.titles.create();
                        // title1.text = 'Not Complied'
                        // title1.disabled = false;
                        // title1.align = 'center'
                        // title1.marginTop = '2%'
                        // title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        var title1 = chart.titles.create();
                        title1.text =
                            ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.division}[/] (${year})` : (
                                    (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.category}[/] (${year})` : (
                                        (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.unit}[/] (${year})` : (
                                            (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.business}[/] (${year})` : (
                                                (toggleButton === 'Month') ? `Not Complied (${year})` :
                                                    (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                                        (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.division}[/] (${year})` : (
                                                            (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.category}[/] (${year})` : (
                                                                (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.unit}[/] (${year})` : (
                                                                    (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Not Complied of [bold]${filterDataNames.business}[/] (${year})` :
                                                                        `Not Complied ${commonData && commonData.g_name} (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
                                                                ))))))))
                            ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        title1.fontSize = '15px'

                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'
                    }
                    addChart(notcomplieddata);
                }
                else {
                    var title3 = container.createChild(am4core.Label)
                    title3.text = "No Data to Display"
                    title3.disabled = false;
                    title3.align = 'left'
                    title3.paddingTop = '45%'
                    title3.paddingLeft = '43%'
                    // title3.fontWeight = '500'
                    title3.fontSize = '16'
                }
            }
            else if (chartType == "delayedComplied") {

                am4core.useTheme(am4themes_animated);
                // var total = (data && data.T_0_to_30_days_count) + (data && data.T_31_to_60_days_count) + (data && data.T_61_to_90_days_count) + (data && data.Above_90_days_count)
                // Create a container
                var container = am4core.create("container", am4core.Container);
                container.width = am4core.percent(100);
                container.height = am4core.percent(100);
                container.layout = "vertical";
                container.background.fill = am4core.color("#000");
                container.background.fillOpacity = 0.1;
                container.background.stroke = am4core.color("#000");
                container.background.strokeOpacity = 0.2;
                container.background.strokeWidth = 2;

                if (delayedData && delayedData.length > 0) {
                    function addChart(data) {
                        var chart = container.createChild(am4charts.XYChart3D);

                        chart.exporting.dataFields = {
                            "month": "Month - Year",
                            "valuecount": `${countryName}_Delayed Compliance`,
                            "total":`${countryName}_TotalCompliance`
                        }

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        chart.exporting.menu.align = "right";
                        chart.exporting.menu.verticalAlign = "top";
                        // Add data
                        chart.data = data
                        // Create axes
                        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
                        dateAxis.renderer.minGridDistance = 1
                        dateAxis.title.text = "Month-Year";
                        // Create value axis
                        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                        valueAxis.calculateTotals = true;
                        // valueAxis.min = 0;
                        // valueAxis.max = 100;
                        // valueAxis.renderer.minGridDistance = 10;
                        valueAxis.min = 0;
                        valueAxis.max = 100;
                        valueAxis.renderer.minGridDistance = 10;
                        valueAxis.renderer.cellStartLocation = 0.2;
                        valueAxis.renderer.cellEndLocation = 0.6;
                        valueAxis.title.text = "Compliance(%)";
                        // Create series
                        var lineSeries = chart.series.push(new am4charts.LineSeries());
                        lineSeries.dataFields.valueY = "value";
                        lineSeries.dataFields.dateX = "month";
                        lineSeries.name = ((fieldType == "LegalEntity" && go == true) ? filterDataNames.legalentity : (
                            (fieldType == "Division" && go == true) ? filterDataNames.division : (
                                (fieldType == "Category" && go == true) ? filterDataNames.category : (
                                    (fieldType == "Unit" && go == true) ? filterDataNames.unit : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name
                                ))
                        ))

                        dateAxis.renderer.labels.template.events.on("hit", function (event) {
                            console.log('hiiiiii');
                            console.log(event, 'evee');
                            console.log(event.target.currentText, 'currrrr')
                            setClickState(true)
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetComparativeChartDrillDownData",
                                        {
                                            "c_ids": [
                                                filterData && filterData.country ? Number(filterData && filterData.country) : commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                            ],
                                            "d_ids": dIds,
                                            "filter_type": fieldType != "" ? fieldType : "Group",
                                            "filter_ids": [
                                                fieldType == "LegalEntity" ? filterData.legalentity :
                                                    fieldType == 'Division' ? Number(filterData.division) :
                                                        fieldType == 'Category' ? Number(filterData.category) :
                                                            fieldType == 'Unit' ? Number(filterData.unit) :
                                                                fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                    1
                                            ],
                                            "year": `${year}`,
                                            "chart_month": (event.target.currentText === 'Feb' ? 2 : (event.target.currentText === 'Mar' ? 3 : (event.target.currentText === 'Apr' ? 4 : (event.target.currentText === 'May' ? 5 : (event.target.currentText === 'Jun' ? 6 : (event.target.currentText === 'Jul' ? 7 : (event.target.currentText === 'Aug' ? 8 : (event.target.currentText === 'Sep' ? 9 : (event.target.currentText === 'Oct' ? 10 : (event.target.currentText === 'Nov' ? 11 : (event.target.currentText === 'Dec' ? 12 : 1))))))))))),
                                            "record_count": 0,
                                            "le_ids":
                                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                            ,
                                            "country_domains": countryDomain,
                                            "compliance_status": "Delayed Compliance"
                                        }
                                    ]
                                }
                            ]
                            if (paramid != "") {
                                getComparativeTableData({
                                    payload: payload,
                                    paramid: paramid
                                })
                            }
                        })
                        dateAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

                        lineSeries.dataFields.value = "totalPercent";
                        lineSeries.calculatePercent = true;
                        lineSeries.calculateTotals = true;
                        lineSeries.tooltipText = "{valueX}"
                        lineSeries.strokeWidth = 2;
                        lineSeries.dataFields.valueXShow = "totalPercent";
                        // Add simple bullet
                        var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
                        bullet.circle.stroke = am4core.color("#fff");
                        bullet.circle.strokeWidth = 2;
                        bullet.tooltipText = "{name}: {valueY.formatNumber('#.00')}[/]% : ({valuecount} out of {total}[/]}";
                        chart.legend = new am4charts.Legend();

                        chart.exporting.menu = new am4core.ExportMenu();
                        chart.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        chart.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";
                        var title = chart.titles.create();
                        title.text = timeZoneData && timeZoneData.length > 0 ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'
                        title.marginBottom = '2%'

                        // var title1 = chart.titles.create();
                        // title1.text = 'Delayed Compliance'
                        // title1.disabled = false;
                        // title1.align = 'center'
                        // title1.marginTop = '2%'
                        // title1.marginLeft = '2%'
                        // title1.fontWeight = 'bold'

                        var title1 = chart.titles.create();
                        title1.text =
                            ((toggleButton === 'Month' && fieldType == "LegalEntity" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                (toggleButton === 'Month' && fieldType == "Division" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.division}[/] (${year})` : (
                                    (toggleButton === 'Month' && fieldType == "Category" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.category}[/] (${year})` : (
                                        (toggleButton === 'Month' && fieldType == "Unit" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.unit}[/] (${year})` : (
                                            (toggleButton === 'Month' && fieldType == "BusinessGroup" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.business}[/] (${year})` : (
                                                (toggleButton === 'Month') ? `Delayed Compliance (${year})` :
                                                    (toggleButton === 'Year' && fieldType == "LegalEntity" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.legalentity}[/] (${year})` : (
                                                        (toggleButton === 'Year' && fieldType == "Division" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.division}[/] (${year})` : (
                                                            (toggleButton === 'Year' && fieldType == "Category" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.category}[/] (${year})` : (
                                                                (toggleButton === 'Year' && fieldType == "Unit" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.unit}[/] (${year})` : (
                                                                    (toggleButton === 'Year' && fieldType == "BusinessGroup" && go == true && type === '') ? `Delayed Compliance of [bold]${filterDataNames.business}[/] (${year})` :
                                                                        `Delayed Compliance ${commonData && commonData.g_name} (${yearData && yearData.length > 0 && yearData[0]} to ${yearData && yearData.length > 0 && yearData[yearData.length - 1]} )`
                                                                ))))))))
                            ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        title1.fontSize = '15px'

                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = chart.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = chart.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'
                    }
                    addChart(delayedData);
                }
                else {
                    var title3 = container.createChild(am4core.Label)
                    title3.text = "No Data to Display"
                    title3.disabled = false;
                    title3.align = 'left'
                    title3.paddingTop = '45%'
                    title3.paddingLeft = '43%'
                    // title3.fontWeight = '500'
                    title3.fontSize = '16'
                }
            }
        }
        // }
    }, [dataValue, table, timeZone, chartType, yearData, timeZoneData, localStorage.getItem('chart')])




    //Use effects for filter

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        setFieldTypeClick('Domain')
        setType('')
        setFilterData({
            ...filterData,
            domain: obj
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        for (let i in obj) {
            console.log(obj[i], 'obj[i');
            console.log(i, 'i');
            if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity[0].bg_id })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            } else {
                let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(obj[i])))
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(obj[i]) })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            }
            let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(obj[i]) })
            let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
            let temp1 = []
            temp1.push({
                'bg_id': filteredCategory[0].bg_id,
                'c_id': filteredCategory[0].c_id,
                'cat_id': filteredCategory[0].cat_id,
                'cat_name': filteredCategory[0].cat_name,
                'd_id': filteredCategory[0].d_id,
                'div_id': filteredCategory[0].div_id,
                'le_id': filteredCategory[0].le_id,
                'div_name': division[0].div_name
            })
            tempCat = temp1
            let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
            tempUnit = filteredUnit
            tempDiv = filteredDivision
            let uniqueDiv = []
            if (filteredDivision && filteredDivision.length > 0) {
                uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)

            setCategory(temp1)
            setUnit(filteredUnit)

        }
        console.log(tempCat, 'tempCat');
        console.log(bg, 'Aishuuuu');
        console.log(temp, 'SnehaaaaaaaaAsss');

    }


    useEffect(() => {
        if (filterData.domain) {
            let tempDom = []
            for (let i in filterData.domain) {
                let filteredDomain = _.find(commonData.d_info, { d_id: filterData.domain[i] })
                tempDom.push({
                    "d_id": filteredDomain.d_id,
                    "c_id": filteredDomain.c_id,
                    "month_from": filteredDomain.month_from,
                    "month_to": filteredDomain.month_to
                })
                console.log(filteredDomain, 'filteredDomain');
            }
            setNewCountryDomain(tempDom)
            console.log(tempDom, 'tempDom');
        }

    }, [commonData.d_info, filterData.domain])

    useEffect(() => {
        if (commonData) {
            let temp = []
            let temp1 = []
            let temp2 = []
            for (let i in commonData.d_info) {
                temp.push(commonData.d_info[i].d_id)
            }
            for (let j in uniq_country_data) {
                temp1.push(`${uniq_country_data[j].c_id}`)
                temp2.push(uniq_country_data[j].c_id)
            }
            setFilterData({
                ...filterData,
                country: temp1,
                countryArr: temp2,
                domain: temp
            })
        }
    }, [commonData, uniq_country_data])

    console.log(filterData.domain, 'domainn');

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map(function (user) {
            // console.log(list.unitlist, 'list.unitlist');
            if ((arrunit.indexOf(user.c_id) < 0))
                arrunit.push(user.c_id);
        });

        arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            if (pmenu || pmenu == null) {
                commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((subunitlist) => {

                    if (pmenu == subunitlist.c_id) {
                        entityArrayunit = {

                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'd_name': subunitlist.d_name,
                            'is_active': subunitlist.is_active,
                            'month_from': subunitlist.month_from,
                            'month_to': subunitlist.month_to,
                            'label': subunitlist.d_name,
                            'values': subunitlist.d_id

                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
            }
            let countryName = _.find(uniq_country_data, { c_id: pmenu })
            console.log(countryName, 'countryName');
            totalListunit = {
                'label': countryName.c_name == null ? 'Others' : countryName.c_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setDomainListData(countrylisttempdataunit)


    }, [uniq_country_data, commonData])

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            console.log('hiii');
            if ((commonData && commonData.bg_groups && commonData.bg_groups.length === 0) && (legalEntityArray && legalEntityArray.length > 0)) {
                console.log('hello');
                if (unit) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    unit && unit.length > 0 && unit.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-lee');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'address': subunitlist.address,
                                'business_group_id': subunitlist.business_group_id,
                                'category_id': subunitlist.category_id,
                                'country_id': subunitlist.country_id,
                                'd_ids': subunitlist.d_ids,
                                'division_id': subunitlist.division_id,
                                'le_id': subunitlist.le_id,
                                'postal_code': subunitlist.postal_code,
                                'u_id': subunitlist.u_id,
                                'u_name': subunitlist.u_name,
                                'label': subunitlist.u_name,
                                'values': subunitlist.u_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunitleee');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setUnitListData(countrylisttempdataunit)
                }
                if (division) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    division && division.length > 0 && division.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {

                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.div_name,
                                'values': subunitlist.div_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunit');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setDivisionListData(countrylisttempdataunit)
                }
                if (category) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    category && category.length > 0 && category.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-category');
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'cat_id': subunitlist.cat_id,
                                'cat_name': subunitlist.cat_name,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.cat_name,
                                'values': subunitlist.cat_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                    setCategoryListData(countrylisttempdataunit)
                }
            }
        }

    }, [commonData, legalEntityArray, unit, division, category, localStorage.getItem('SelectedEntity') === "All Legal Entity"])


    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
                let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(commonData && commonData.bg_groups[0].bg_id) })
                console.log(filteredDivision, 'filteredDivision');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)
                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)
                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)

                }
            }
            else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                console.log('hello ais');
                let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: commonData.le_did_infos[0].le_id })
                console.log(filteredDivision, 'filteredDivision555');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)

                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)

                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)
                }
            }
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && bgIds.length > 0 && commonData) {
            if (bgIds.includes(null) === true) {
                console.log('snehaaa');
                setfield({
                    ...field,
                    legalEntity: commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos,
                })

            }
            else {
                let filteredLegalEntity = _.filter(commonData.le_did_infos, { bg_id: Number(commonData.bg_groups[0].bg_id) })
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                })
            }

        }
    }, [commonData, bgIds, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredCategory, 'filteredCategory111');
                let temp = []
                temp.push({
                    'bg_id': filteredCategory[0].bg_id,
                    'c_id': filteredCategory[0].c_id,
                    'cat_id': filteredCategory[0].cat_id,
                    'cat_name': filteredCategory[0].cat_name,
                    'd_id': filteredCategory[0].d_id,
                    'div_id': filteredCategory[0].div_id,
                    'le_id': filteredCategory[0].le_id,
                    'div_name': commonData && commonData.div_infos[0].div_name
                })
                setCategory(temp)
                let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredUnit, 'filteredUnit123');
                setUnit(filteredUnit)
            }
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity && filteredLegalEntity[0].bg_id })
                console.log(filteredBusinessGroup, 'filteredBusinessGroup');
                const unique = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                console.log(unique, 'unique');
                setfield({
                    ...field,
                    business: unique,
                    legalEntity: filteredLegalEntity
                })
            }

        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if ((commonData) && (division && division.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            division && division.length > 0 && division.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');

                if (commonData && commonData.le_did_infos[0].le_id == subunitlist.le_id) {

                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.div_name,
                        'values': subunitlist.div_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            console.log(totalListunit, 'totalListunit');

            countrylisttempdataunit.push(totalListunit)
            console.log(countrylisttempdataunit, 'countrylisttempdataunit');
            setDivisionListData(countrylisttempdataunit)
        }
    }, [commonData, division, field.legalEntity])

    useEffect(() => {
        if (field.legalEntity && field.legalEntity.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [field.legalEntity, division, category, unit])

    useEffect(() => {
        if ((commonData) && (category && category.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            category && category.length > 0 && category.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-category');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }
            countrylisttempdataunit.push(totalListunit)
            setCategoryListData(countrylisttempdataunit)
        }

    }, [commonData, category && category.length > 0])

    useEffect(() => {
        if ((commonData) && (unit && unit.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            unit && unit.length > 0 && unit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-unit');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'address': subunitlist.address,
                        'business_group_id': subunitlist.business_group_id,
                        'category_id': subunitlist.category_id,
                        'country_id': subunitlist.country_id,
                        'd_ids': subunitlist.d_ids,
                        'division_id': subunitlist.division_id,
                        'le_id': subunitlist.le_id,
                        'postal_code': subunitlist.postal_code,
                        'u_id': subunitlist.u_id,
                        'u_name': subunitlist.u_name,
                        'label': subunitlist.u_name,
                        'values': subunitlist.u_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setUnitListData(countrylisttempdataunit)
        }
    }, [commonData, unit && unit.length > 0])

    console.log(dataValue, 'dataValue');

    return (
        <div>
            <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div>
                        <div className="row">
                            <div className="col-lg-6" style={{ paddingLeft: '10px' }}>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <span style={{ fontSize: '16px' }}>Dashboard</span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <span style={{ fontSize: '16px' }}>Comparative Trend</span>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-end full_screeen_icon_align" style={{ paddingRight: '10px' }}>
                                {table == false ?
                                    <>
                                        <Select className='me-2' style={{ width: '176px' }} value={chartType} onChange={(data) => {
                                            setChartType(data)
                                            setTable(false)
                                            setClickState(false)
                                        }}>
                                            <Option key='complied'>Complied</Option>
                                            <Option key='notComplied'>Not Complied</Option>
                                            <Option key='delayedComplied'>Delayed Compliance</Option>
                                        </Select>
                                        <div className={localStorage.getItem('currentTheme')} style={{ textAlign: 'end' }}>
                                            <span className='togglecolour' style={{ fontSize: '18px' }}> <Switch type='success' style={{ backgroundColor: toggleButton == 'Month' ? 'green' : 'grey', fontSize: '18px', height: '32px', width: '75px' }} checked={toggleButton === 'Month' ? true : false} checkedChildren="Month" unCheckedChildren="Year"
                                                onChange={(e) => {
                                                    setToggleButton(e === false ? 'Year' : 'Month')
                                                    setDelayedData([])
                                                    setNotcomplieddata([])
                                                    setDataValue([])
                                                    if (entityData && entityData.length > 0) {
                                                        const payload = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request":
                                                                    ["GetComparativeChart",

                                                                        {
                                                                            "c_ids": [
                                                                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                                            ],
                                                                            "d_ids": dIds,
                                                                            "filter_type": "Group",
                                                                            "filter_ids": [
                                                                                1
                                                                            ],
                                                                            "le_ids":
                                                                                localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid], "country_domains": countryDomain,
                                                                            "year": `${year}`,
                                                                            "chart_types": e === false ? 'Year' : 'Month'
                                                                        }
                                                                    ]
                                                            }
                                                        ]

                                                        getComparativechart({
                                                            payload: payload,
                                                            paramid: paramid
                                                        })

                                                    } else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                                                        const payload = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request":
                                                                    ["GetComparativeChart",

                                                                        {
                                                                            "c_ids": [
                                                                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                                            ],
                                                                            "d_ids": dIds,
                                                                            "filter_type": "Group",
                                                                            "filter_ids": [
                                                                                1
                                                                            ],
                                                                            "le_ids":
                                                                                localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                                                                            "country_domains": countryDomain,
                                                                            "year": `${year}`,
                                                                            "chart_types": e === false ? 'Year' : 'Month'
                                                                        }
                                                                        // {
                                                                        //     "c_ids": [
                                                                        //         commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                                        //     ],
                                                                        //     "d_ids": [
                                                                        //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                                        //     ],
                                                                        //     "filter_type": "Group",
                                                                        //     "filter_ids": [
                                                                        //         1
                                                                        //     ],
                                                                        //     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                                        //         commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                                        //     ],
                                                                        //     "country_domains": [
                                                                        //         {
                                                                        //             "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                                        //             "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                                        //             "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                                        //             "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                                        //         }
                                                                        //     ],
                                                                        //     "year": year,
                                                                        //     "chart_types": "Month"
                                                                        // }
                                                                    ]
                                                            }
                                                        ]

                                                        getComparativechart({
                                                            payload: payload,
                                                            paramid: paramid
                                                        })

                                                    }

                                                }}
                                            /></span>
                                        </div>

                                        <Button icon={<FilterOutlined />} className='addbutton ms-2' style={{ background: "#0096FF", borderRadius: '15px', marginLeft: '5px' }} onClick={() => {
                                            setOpen(true)
                                        }} size='default' >
                                            <Tooltip placement='top' title='Filter' getPopupContainer={(triggerNode) => {

                                                return triggerNode.parentNode;

                                            }} className='gotop'>Filter</Tooltip>
                                        </Button></> : <Button type="primary" shape="round" className='check me-1 addbutton' icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                            setTable(false)
                                            setClickState(false)
                                        }}>
                                        <Tooltip placement='top' title='Back'>Back</Tooltip>
                                    </Button>}
                                <div className='comparative'>
                                    <Button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius" >
                                        <Tooltip placement='left' title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                                    </Button>
                                </div>



                                {/* <button onClick={() => {
            setShowList(true)
            setAddType('add')
        }} className="icons twitter" style={{ border: 'none', margin: '0px' }}>
            <span className="tooltips" style={{ right: '105%' }}>Add</span>
            <span><i id={localStorage.getItem('currentTheme')}><PlusCircleOutlined /></i></span>
        </button> */}


                            </div>
                        </div>
                    </div>
                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-0">


                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className="col-md-8">
                                        {/* <span style={{ marginTop: '2%', fontSize: '16px' }}><b style={{ marginTop: '2%', fontSize: '16px' }}>Compliance Trend of {commonData && commonData.g_name} for the year {yearValue}</b></span> */}
                                    </div>
                                    {/* <div className="col-md-4">
                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginLeft: '70%' }}
                                        icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                            setTable(false)
                                        }}>
                                        <Tooltip title='Back'>Back</Tooltip>
                                    </Button>
                                </div> */}
                                </div>
                            </div><br />
                            <Card className='rounded-3'>
                                {/* <h3>{criticalityValue != 'Complied' ? `${criticalityValue} compliance` : criticalityValue}</h3> */}
                                {/* <Tag style={{ height: '30px', width: criticalityValue != 'Complied' ? '25%' : '15%', fontSize: '17px', fontFamily: 'serif', textAlign: 'center' }} color="blue" >{criticalityValue != 'Complied' ? `${criticalityValue} compliance` : criticalityValue}</Tag> */}
                                {/* <Tag style={{ borderRadius: '15px', fontSize: '17px', fontFamily: 'serif', textAlign: 'center' }} color="blue" >{criticalityValue != 'Complied' ? `${criticalityValue} compliance` : criticalityValue}</Tag> */}
                                <div>
                                    <Collapse className={localStorage.getItem('currentTheme') + ' cric'} defaultActiveKey={['0']} >
                                        {tableData && tableData.length > 0 &&
                                            tableData.map((items) => {
                                                console.log(items, 'item');
                                                let unitName = items.t_compliances
                                                let keys = Object.keys(unitName);
                                                console.log(items.t_compliances, 'item.drill_compliances');
                                                return (
                                                    <Panel style={{ marginBottom: '5px' }} header={`${items.le_name}-${items.u_name}`}>
                                                        {keys && keys.length > 0 && keys.map((item, i) => {
                                                            let values = Object.values(unitName);
                                                            console.log(values[i], 'values');
                                                            let childDatas = values
                                                            let tempArr = []
                                                            if (childDatas && childDatas.length > 0) {
                                                                for (let m in childDatas[i]) {
                                                                    console.log(m, 'inndexxx');
                                                                    console.log(childDatas[i][m].comp_name, 'childDatas');
                                                                    tempArr.push({
                                                                        'bg_name': items.bg_name,
                                                                        'le_name': items.le_name,
                                                                        'legal_entity_id': items.legal_entity_id,
                                                                        'div_name': items.div_name,
                                                                        'comp_name': childDatas && childDatas[i][m].comp_name,
                                                                        'criticality': childDatas && childDatas[i][m].criticality,
                                                                        'assignee_name': childDatas && childDatas[i][m].assignee_name,
                                                                        'descp': childDatas && childDatas[i][m].descp,
                                                                        'compfielaw_url': childDatas && childDatas[i][m].compfielaw_url,
                                                                        'ageing': childDatas && childDatas[i][m].ageing,
                                                                        'index': indexValue

                                                                    })
                                                                    { <span hidden>{indexValue = indexValue + 1}</span> }
                                                                    // childDatas[i] = []
                                                                }
                                                                console.log(tempArr, 'tempArr');
                                                            }
                                                            childDatas[i] = tempArr

                                                            return (
                                                                <>
                                                                    <h6 className='m-2 ml-2 mb-2'>{keys[i]}</h6>

                                                                    <Table
                                                                        size={'small'} className='remark-header add-service-prv'
                                                                        columns={columnState === true ? columns1 : columns}
                                                                        dataSource={childDatas[i]}
                                                                        bordered
                                                                        // scroll={{ x: 1000 }}
                                                                        pagination={false}
                                                                    // onChange={handleChange}

                                                                    />
                                                                </>
                                                            )

                                                        })}
                                                    </Panel>
                                                )
                                            })
                                        }
                                    </Collapse>
                                </div>
                                {/* {count == showmoreCount ? false : */}
                                {/* <div className='text-center mt-2'>
                                    <Button shape='round' className='addbutton'
                                    // onClick={showmore}
                                    >Show more records</Button>
                                </div> */}
                                {/* } */}
                            </Card>


                            {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                        </div> :
                        <div className="container-fluid pt-1">
                            <div className="row" >
                                <div className="col-md-12">

                                </div>
                                {/* {data && data.length > 0 ? */}
                                <>
                                    <div className="col-md-12">

                                        {/* <div className="card">
                                                <div className='card-body'> */}

                                        <>
                                            <div id="container" style={{ width: "100%", height: "550px" }}></div>
                                            {data && data.length > 0 ?
                                                <div className="col-md-12 mt-2">
                                                    <div className="row">
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-3">
                                                        </div>
                                                        <div className="col-md-6 col-md-6 d-none d-md-flex
align-items-center
justify-content-end">
                                                            <label htmlFor=""><b>Time Zone :</b></label>
                                                            <Select
                                                                // allowClear={true}
                                                                size="default"
                                                                onChange={(data) => {
                                                                    setTimeZone(data)
                                                                    setGeneratedOn(true)
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetDateTime_ByZone",
                                                                                {
                                                                                    "tz_name": data
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getTimeZoneData({
                                                                        payload: payload,
                                                                        paramid: paramid
                                                                    })

                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                placeholder="Enter Time Zone"
                                                                style={{ marginLeft: '5%' }}
                                                            // value={compfie.user_Group}
                                                            >
                                                                {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.tz_name}>
                                                                            {item.tz_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                                {/* );
                                                })} */}
                                                            </Select>
                                                        </div>

                                                    </div>
                                                </div> : ''}

                                        </>



                                        {/* </div>
                                            </div> */}
                                    </div>

                                </>
                                {/* <p style={{ marginTop: '18%', marginLeft: '40%', marginBottom: '18%' }}>No Data to Display </p>
                                 } */}
                                {/* {data && data.length > 0 ? */}
                                {toggleButton === 'Month' ?
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className="col-md-2">
                                                {year === prevYear ? '' :
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                        icon={<ArrowLeftOutlined />} size='default'
                                                        onClick={() => {
                                                            setYear(year - 1)
                                                            setDataValue([])
                                                            setNotcomplieddata([])
                                                            setDelayedData([])
                                                        }}
                                                    >
                                                        Previous Year
                                                    </Button>}
                                            </div>
                                            <div className="col-md-8"></div>
                                            <div className="col-md-2 text-end">
                                                {year != moment().year() ?
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                        size='default'
                                                        onClick={() => {
                                                            setYear(year + 1)
                                                            setDataValue([])
                                                            setNotcomplieddata([])
                                                            setDelayedData([])
                                                        }}
                                                    >
                                                        Next Year<ArrowRightOutlined />
                                                    </Button>
                                                    : false}
                                            </div>
                                        </div>
                                    </div> : ''}
                                {/* : ''} */}

                            </div>
                        </div>
                }

                <Drawer title="FILTER BY" className={localStorage.getItem("currentTheme") + ' resize-drawer'} placement="right" closable={false} onClose={false} visible={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', marginLeft: '75', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    // onChange={(data, values) => {
                                    //     setFieldTypeClick('Country')
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         country: data,
                                    //         domain: ''
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         country: values.children,
                                    //         domain: '',
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setType('')

                                    // }
                                    // }
                                    onChange={(option) => HandelCountryChange(option)}
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {uniq_country_data &&
                                        uniq_country_data.length > 0 &&
                                        uniq_country_data.map((item, i) => {
                                            return (
                                                <Option key={item.c_id}>
                                                    {item.c_name}
                                                </Option>
                                            );
                                        })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('BusinessGroup')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onClick={() => {
                                        setFieldTypeClick('LegalEntity')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        // let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // setfield({
                                        //     ...field,
                                        //     legalEntity: filteredLegalEntity
                                        // })
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                            setUnit(filteredUnit)
                                        }
                                        else {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                            console.log(filteredUnit, 'filteredUnit555');
                                            setUnit(filteredUnit)
                                        }

                                        console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Division')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Category')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Unit"
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Unit')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={'popupbtncolour text-center ' + localStorage.getItem('currentTheme')}>
                        <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: '15%', marginTop: '10%' }}
                            onClick={filterChange} icon={<ArrowRightOutlined />}
                            size='default' >
                            Go
                        </Button>
                    </div>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>


            </div>
        </div>

    )


}

export default connect(mapStateToProps, {
    getCommonData,
    getComparativechart,
    getTimeZoneData,
    getComparativeTableData
})(ComparativeTrend);
