import React from 'react'
import moment from 'moment';
import { Collapse, Select, Input, DatePicker, Card, Pagination, Popover, Drawer, Button, Table, Tooltip, Modal, Descriptions, Empty, Space } from 'antd';

import DataTable from "react-data-table-component";
import { Fragment, useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ArrowRightOutlined, CheckOutlined, CloseOutlined, ArrowLeftOutlined, FilterOutlined, UpOutlined, DownOutlined, FullscreenOutlined, CloseCircleOutlined, SearchOutlined, FilterFilled, PlayCircleOutlined } from '@ant-design/icons';
import ScrollToTop from 'react-scroll-to-top';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getCommonData, getTaskDistributionchart, getTaskDistributionTable, getTimeZoneData, getTaskDistributionShowMoreTableData } from '../../Store/Action/Dashboard/TaskDistribution';
import { toast } from 'react-toastify';
import url, { getUrl } from './../../Libs/URL'
import { HomeRedirect,CountrySplit } from './../../Libs/country'
const homepage = HomeRedirect()
const uniq_country_data = CountrySplit()
const urls = getUrl()

// import "core-js/stable";
// import "regenerator-runtime/runtime";

const mapStateToProps = (state) => ({
    data: state.TaskDistributionCharts.taskDistribution.chartData,
    time: state.TaskDistributionCharts.taskDistribution.time,
    commonData: state.TaskDistributionCharts.taskDistribution.Data,
    tableData: state.TaskDistributionCharts.taskDistribution.tableData,
    timeZoneData: state.TaskDistributionCharts.taskDistribution.timeZoneData,
    showMoreData: state.TaskDistributionCharts.taskDistribution.showMoreData

})

const TaskDistribution = ({
    getFilterValue,
    getCommonData,
    getTaskDistributionchart, getTaskDistributionTable,
    data,
    commonData,
    time,
    tableData,
    getTimeZoneData,
    timeZoneData,
    getTaskDistributionShowMoreTableData,
    showMoreData
}) => {
    console.log(tableData, 'tableDatatableData');
    console.log(timeZoneData, 'timeZoneData');
    const _ = require("lodash");
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [taskDistributionTable, setTaskDistributionTable] = useState([])
    const [newCountryDomain, setNewCountryDomain] = useState([])

    const [filterData, setFilterData] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: '',
        countryArr:[]
    })
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState({
        data: '',
        index: 0
    });
    const [searchedColumn, setSearchedColumn] = useState('');
    const { Panel } = Collapse;
    const [chartId, setChartId] = useState()
    const [field, setfield] = useState({
        country: [],
        domain: [],
        legalEntity: [],
        business: [],

    })
    var [count, setCount] = useState(1)
    const [open, setOpen] = useState(false)
    const [showmoreCount, setShowMoreCount] = useState(0)
    const [table, setTable] = useState(false)
    const [division, setDivision] = useState([])
    const [category, setCategory] = useState([])
    const [fieldType, setFieldType] = useState("")
    const [unit, setUnit] = useState([])
    const chart = useRef(null);
    const { Option } = Select;
    const [year, setYear] = useState(moment().year())
    const [timeZone, setTimeZone] = useState("Afica/Accara")
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionvalue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionvalue)
    const [entityData, setEntityData] = useState([])
    const TimeZoneValue = sessionParsedValue.login_response
    const sessionArr = sessionParsedValue.entity_info
    console.log(commonData, 'commonData');
    const location = useLocation();
    const [paramid, setParamId] = useState("")
    const [generatedOn, setGeneratedOn] = useState(false)
    const [go, setGo] = useState(false)
    const [filterDataNames, setFilterDataNames] = useState({
        country: '',
        domain: '',
        legalentity: '',
        division: '',
        unit: '',
        business: '',
        category: '',
        unit: ''
    })
    const country_info = sessionParsedValue.country_info
    const [criticalityValue, setCriticalityValue] = useState()
    const [domainName, setDomainName] = useState()
    const [countValue, setCountValue] = useState(0)
    const [dataCount, setDataCount] = useState()
    console.log(dataCount,'dataCount');
    console.log(showmoreCount,'showmoreCount');
    const { OptGroup } = Select;
    const [domainListData, setDomainListData] = useState([])
    const [divisionListData, setDivisionListData] = useState([])
    const [categoryListData, setCategoryListData] = useState([])
    const [unitListData, setUnitListData] = useState([])
    const [bgIds, setBgIds] = useState([])
    const [groupCheckBox, setGroupCheckBox] = useState(true)
    const [legalEntityArray, setLegalEntityArray] = useState([])
    const [checkBox, setCheckBox] = useState(true)
    const [textArray, setTextArray] = useState([])
    const [countryDomain, setCountryDomain] = useState([])
    const [dIds, setDIds] = useState([])
    const [type, setType] = useState('')
    const [errorField, setErrorField] = useState('')
    const [fieldTypeClick, setFieldTypeClick] = useState("")
    const [countryData,setCountryData] = useState([])
    const [domainData,setDomainData] = useState([])

    useEffect(()=>{
        var obj = {}
        countryData.forEach(function (a) {
            var arr2 = []
            domainData.forEach(function (b) {
                var key = a.c_name
                if (a.c_id == b.c_id) {
                    arr2.push(b.d_name)
                    obj[key] = arr2.join()
                }
            });
        });
        let temp = []
        for (let m in obj) {
            console.log(obj[m], 'objmm');
            // let sm = `${m} - ${obj[m]}`
            let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
            temp.push(expectedCountryDomain)
        }
        let newCountryDomain = temp.join("[bold] | [/]")
        setTextArray(newCountryDomain)

    },[countryData,domainData])

    useEffect(()=>{
        let newCountry = []
        let newDomain = []

        for (let j in filterData.country) {
           let filterCountry = _.filter(commonData.countries,{c_id:Number(filterData.country[j])})
            // temp2.push(filterDomain)
            for(let i in filterCountry){
                newCountry.push(filterCountry[i])
            }
            
        }
        for (let k in filterData.domain) {
            let filterDomain = _.filter(commonData.d_info,{d_id:Number(filterData.domain[k])})
             // temp2.push(filterDomain)
             for(let l in filterDomain){
                newDomain.push(filterDomain[l])
             }
             
         }
         const uniqueCountry = _.uniqBy(newCountry && newCountry, function (e) {
            return e.c_id;
        });
        setCountryData(uniqueCountry)
        setDomainData(newDomain)
        console.log(newCountry,'newCountry');
        console.log(newDomain,'newDomain');
        console.log(filterData.country,'filterData.country');
        console.log(filterData.domain,'filterData.domain');

    },[filterData.country,filterData.domain])

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let tempArr = []
            let tempIds = []
            let textArr = []
            let cNames = []
            let uniqueCountry = []
            let countryName;
            setDomainData(commonData.d_info)
            for (let i in commonData.d_info) {
                tempIds.push(commonData.d_info[i].d_id)
                tempArr.push({
                    'd_id': commonData.d_info[i].d_id,
                    'c_id': commonData.d_info[i].c_id,
                    "month_from": commonData.d_info[i].month_from,
                    "month_to": commonData.d_info[i].month_to
                })
                uniqueCountry = _.uniqBy(commonData && commonData.countries, function (e) {
                    return e.c_id;
                });
                setCountryData(uniqueCountry)
                let id = uniqueCountry && uniqueCountry.length > 0 && uniqueCountry.map((item, i) => {
                    return item.c_id
                })
                // setCIds(id)
                console.log(uniqueCountry, 'uniqueCountry');
                countryName = _.find(uniqueCountry, { c_id: Number(commonData.d_info[i].c_id) })

                cNames.push(commonData.d_info[i].d_name)

            }
            // var arr = []
            var obj = {}
            uniqueCountry.forEach(function (a) {
                var arr2 = []
                commonData.d_info.forEach(function (b) {
                    var key = a.c_name
                    if (a.c_id == b.c_id) {
                        arr2.push(b.d_name)
                        obj[key] = arr2.join()
                    }
                });
            });
            let temp = []
            for (let m in obj) {
                console.log(obj[m], 'objmm');
                // let sm = `${m} - ${obj[m]}`
                let expectedCountryDomain = `[bold]${m}[/] - ${obj[m]}`
                temp.push(expectedCountryDomain)
            }
            let newCountryDomain = temp.join("[bold] | [/]")
            // setTextArray(newCountryDomain)
            setDIds(tempIds)
            setCountryDomain(tempArr)
        }


    }, [commonData])

    const HandelCountryChange = (obj) => {
        console.log(obj, 'objj');
        var numberArray = obj.map(Number);
        console.log(numberArray, 'numberArray');
        let temp5 = []
        let filterDomain = []
        for (let j in obj) {
            filterDomain = _.filter(commonData.d_info,{c_id:Number(obj[j])})
            // temp2.push(filterDomain)
            for(let i in filterDomain){
                temp5.push(filterDomain[i])
            }
            
        }
        let id = temp5 && temp5.length > 0 && temp5.map((item, i) => {
            return item.d_id
        })
        console.log(id,'temp5');
        setFieldTypeClick('Country')
        setType('')
        setFilterData({
            ...filterData,
            country: obj,
            countryArr: numberArray,
            domain:id
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            country: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
    }

    useEffect(() => {
        if (fieldTypeClick === 'BusinessGroup') {
            if (filterDataNames.business === '') {
                setErrorField('Business Group Required')
            }
        }

        if (fieldTypeClick === 'LegalEntity') {
            if (filterDataNames.legalentity === '') {
                setErrorField('Legal Entity Required')
            }
        }
        if (fieldTypeClick === 'Division') {
            if (filterDataNames.division === '') {
                setErrorField('Division Required')
            }
        }
        if (fieldTypeClick === 'Category') {
            if (filterDataNames.category === '') {
                setErrorField('Category Required')
            }
        }
        if (fieldTypeClick === 'Unit') {
            if (filterDataNames.unit === '') {
                setErrorField('Unit Required')
            }
        }

    }, [fieldTypeClick])

    useEffect(() => {
        if (fieldTypeClick === 'Country') {
            if (filterData.country.length === 0) {
                setErrorField('Country Required')
            }
            else {
                setErrorField('')

            }
        }
        if (fieldTypeClick === 'Domain') {
            if (filterData.domain.length === 0) {
                setErrorField('Domain Required')
            }
            else {
                setErrorField('')

            }
        }

    }, [fieldTypeClick, filterData])

    console.log(filterData, 'filterData');

    useEffect(() => {
        {

            var the_arr = location.pathname.split('/');

            the_arr.pop();

            let a = the_arr.join('/')

            setParamId(a)

        }

    }, []);
    console.log(generatedOn, 'generatedOn');
    console.log(timeZone, 'timeZone');

    useEffect(() => {
        if (tableData) {
            setTaskDistributionTable(tableData.r_drill_down_data)
        }

    }, [tableData])
    console.log(tableData, 'taskDistributionTable');

    useEffect(() => {
        if (commonData) {
            let unique = []
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                unique = _.uniqBy(commonData && commonData.bg_groups, function (e) {
                    return e.bg_id;
                });
            }
            setfield({
                ...field,
                domain: commonData && commonData.d_info,
                legalEntity: commonData && commonData.le_did_infos,
                country: commonData && commonData.countries,
                business: unique
            })
            let uniqueDiv = []
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                uniqueDiv = _.uniqBy(commonData && commonData.div_infos, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)
            setCategory(commonData && commonData.cat_info)
            setUnit(commonData && commonData.chart_units)
        }

    }, [isAuth, entityid, commonData])

    console.log(field, 'fieldfield');
    console.log(commonData, ' commonDatacommonDatacommonData');

    useEffect(() => {
        if (commonData && commonData.d_info && commonData.d_info.length > 0) {
            let domainNameArray = commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((item, i) => {
                return item.d_name
            })
            setDomainName(domainNameArray)
            console.log(domainName, 'domainName');

        }

    }, [commonData])

    useEffect(() => {
        // if (localStorage.getItem('SelectedEntity') == "All Legal Entity") {
        let id = sessionArr && sessionArr.length > 0 && sessionArr.map((item, i) => {

            return item.le_id
        })
        setEntityData(id)

        // }
    }, [])
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetChartFilters",
                        {
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getCommonData({
                    payload: payload,
                    paramid: paramid
                })
            }


        }
    }, [isAuth, authtoken, paramid, entityid])
    useEffect(() => {
        if ((entityData && entityData.length > 0) && (commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
            console.log('sneha');
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":
                        ["GetTskDistributionStatusChart",
                            {
                                "c_ids": [
                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                ],
                                "d_ids": dIds,
                                "filter_type": "Group",
                                "filter_ids": [
                                    1
                                ],
                                "le_ids": localStorage.getItem('SelectedEntity') === 'All Legal Entity' ? entityData : [entityid],
                                "country_domains": countryDomain,
                                "chart_year": year
                            }
                        ]
                }
            ]
            if (paramid != "") {
                getTaskDistributionchart({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }, [isAuth, entityData, year, commonData, dIds, countryDomain, year])

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != '	All Legal Entity') {
    //         if ((commonData) && (dIds && dIds.length > 0) && (countryDomain && countryDomain.length > 0)) {
    //            console.log('aishuuu');
    //             const payload = [
    //                 authtoken,
    //                 {
    //                     "session_token": authtoken,
    //                     "request":
    //                         ["GetTskDistributionStatusChart",
    //                             {
    //                                 "c_ids": [
    //                                     commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                 ],
    //                                 "d_ids": dIds,
    //                                 "filter_type": "Group",
    //                                 "filter_ids": [
    //                                     1
    //                                 ],
    //                                 "le_ids": [entityid],
    //                                 "country_domains": countryDomain,
    //                                 "chart_year": year
    //                             }
    //                         ]
    //                 }
    //             ]
    //             if (paramid != "") {
    //                 getTaskDistributionchart({
    //                     payload: payload,
    //                     paramid: paramid
    //                 })
    //             }
    //         }
    //     }

    // }, [localStorage.getItem('SelectedEntity') != '	All Legal Entity', isAuth, entityid, commonData, year, countryDomain, dIds])

    const dataSource = [
        {
            key: '1',
            name: 'Rule 18',
            task: 'Trail Task117',
            description: 'test',
            p_cons: "Shall be punishable with imprisonment for a term which shall not be less than six months but which may extend to three years and with fine which shall not be less than fifty thousand rupees but which may extend to two lakh rupees - Section 63",
            address: '10 Downing Street',
            frequency: 'periodical',
            task_status: 'opted',
            trigger_before_days: 10,
            task: 'Trail Task119',
            summary: "Repeats every 1 Month(s) "
        },
        {
            key: '2',
            name: 'Rule 3',
            description: 'Online application can be submitted for one musical license covering all branches of pan India by paying prescribed fees. Music license is applicable for music played for public in TV, radio, live performances, DJ by internet or even copied in CD, DVD or pen drives/hard disc',
            address: '10 Downing Street',
            frequency: 'Review',
            task_status: 'Not opted',
            task: 'Trail Task',
            summary: "Repeats every 6 Month(s) ",
            trigger_before_days: 20,
            p_cons: "Section 23 - Imprisonment up to 3 months or with fine up to Rs.1000/- or with both. If the contravention is continued fine may extend to Rs.100/- for each day",
        },
        {
            key: '3',
            name: 'Rule 12',
            description: 'test',
            frequency: 'OnOccurence',
            address: '10 Downing Street',
            p_cons: "test",
            task: 'Trail Task55',
            task_status: 'opted',
            trigger_before_days: 25,
            summary: "Repeats every 1 Month(s) "
        },
    ];
    useEffect(() => {
        if (taskDistributionTable && taskDistributionTable.length > 0) {
            let count = []
            for (let i in taskDistributionTable) {
                for (let j in taskDistributionTable[i].ap_compliances) {
                    let a = taskDistributionTable[i].ap_compliances[j]
                    let data = Object.values(a)


                    count.push(data.length)

                    // count.push(data[i])
                    console.log(data, ' taskDistributionTable[i].ap_compliances');

                }
            }
            console.log(count, 'count');
            let countValues = count.reduce(function (accumulator, curValue) {
                return accumulator + curValue
            }, 0)
            setShowMoreCount(countValues)
            console.log(countValues, 'countValue9999');
        }

    }, [taskDistributionTable])
    console.log(showmoreCount, 'showmoreCount');
    useEffect(() => {
        console.log(taskDistributionTable, 'taskDistributionTable');
        console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data5555');
        if (showMoreData && showMoreData.r_drill_down_data && showMoreData.r_drill_down_data.length > 0) {
            let temp = []
            let temp2 = []
            console.log(showMoreData.r_drill_down_data, 'showMoreData.r_drill_down_data');
            for (let i in taskDistributionTable) {
                for (let j in showMoreData.r_drill_down_data) {
                    if (taskDistributionTable[i].level1_statutory_name == showMoreData.r_drill_down_data[j].level1_statutory_name) {
                        for (let s in taskDistributionTable[i].ap_compliances) {
                            for (let a in showMoreData.r_drill_down_data[j].ap_compliances) {
                                console.log(s, 'taskDistributionTable[i].ap_compliances[s]');
                                console.log(showMoreData.r_drill_down_data[j].ap_compliances[a], 'showMoreData.r_drill_down_data[j].ap_compliances[a]');
                                if (s == a) {
                                    let arr1 = taskDistributionTable[i].ap_compliances[s]
                                    let arr2 = showMoreData.r_drill_down_data[j].ap_compliances[a]
                                    let arr3 = arr1.concat(arr2)
                                    console.log(arr3, 'arr3');
                                    taskDistributionTable[i].ap_compliances[s] = arr3
                                    console.log(s, 'sneha');
                                }
                                else {
                                    taskDistributionTable[i].ap_compliances[a] = showMoreData.r_drill_down_data[j].ap_compliances[a]
                                }
                            }
                        }
                        // let newArray = { ...obj, ...objdata }

                        // var newArray = Object.assign({}, taskDistributionTable[i].ap_compliances, showMoreData.r_drill_down_data[j].ap_compliances);
                        // taskDistributionTable[i].ap_compliances = newArray
                        temp2.push(taskDistributionTable[i].level1_statutory_name)
                        // setTaskDistributionTable(showMoreData.r_drill_down_data)
                    }

                }
            }
            console.log(temp2, 'temp2');
            for (let i in showMoreData.r_drill_down_data) {

                if ((temp2.includes(showMoreData.r_drill_down_data[i].level1_statutory_name)) == false) {
                    // let data = _.filter(showMoreData.r_drill_down_data, { level1_statutory_name: taskDistributionTable[j].level1_statutory_name })
                    console.log(showMoreData.r_drill_down_data[i], 'datadata');
                    temp.push(showMoreData.r_drill_down_data[i])
                }



            }

            setTaskDistributionTable([...taskDistributionTable, ...temp])



        }

    }, [showMoreData])

    // useEffect(() => {
    //     if(showMoreData && showMoreData.r_drill_down_data && showMoreData.r_drill_down_data.length > 0){
    //         for(let i in showMoreData.r_drill_down_data){
    //             for(let j in taskDistributionTable){
    //                 if(showMoreData.r_drill_down_data[i].level1_statutory_name === taskDistributionTable[j].level1_statutory_name){
    //                     const filterValue = _.find(showMoreData.r_drill_down_data,{level1_statutory_name:taskDistributionTable[j].level1_statutory_name})
    //                     console.log(filterValue,'filterValue');
    //                     let employee = {
    //                         ...taskDistributionTable[j].ap_compliances,
    //                         ...filterValue.ap_compliances
    //                     };
    //                     console.log(employee,'employee');
    //                 }
    //                 else{
    //                     setTaskDistributionTable(showMoreData.r_drill_down_data)
    //                 }

    //             }
    //         }
    //     }

    // },[showMoreData])


    console.log(showMoreData, 'showMoreData');
    console.log(taskDistributionTable, 'taskDistributionTable');

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    const HandelChange = (obj) => {
        console.log(obj, 'objj');
        setFieldTypeClick('Domain')
        setType('')
        setFilterData({
            ...filterData,
            domain: obj
        })
        setFieldType('')
        setFilterDataNames({
            ...filterDataNames,
            domain: obj,
            legalentity: '',
            division: '',
            unit: '',
            business: '',
            category: '',
            unit: ''
        })
        let temp = []
        let bg = []
        let tempDiv = []
        let tempCat = []
        let tempUnit = []
        for (let i in obj) {
            console.log(obj[i], 'obj[i');
            console.log(i, 'i');
            if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity[0].bg_id })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            } else {
                let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(obj[i])))
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(obj[i]) })
                const uniqueBg = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                    business: uniqueBg
                })
            }
            let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(obj[i]) })
            let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
            let temp1 = []
            temp1.push({
                'bg_id': filteredCategory[0].bg_id,
                'c_id': filteredCategory[0].c_id,
                'cat_id': filteredCategory[0].cat_id,
                'cat_name': filteredCategory[0].cat_name,
                'd_id': filteredCategory[0].d_id,
                'div_id': filteredCategory[0].div_id,
                'le_id': filteredCategory[0].le_id,
                'div_name': division[0].div_name
            })
            tempCat = temp1
            let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
            tempUnit = filteredUnit
            tempDiv = filteredDivision
            let uniqueDiv = []
            if (filteredDivision && filteredDivision.length > 0) {
                uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                    return e.div_id;
                });
            }
            setDivision(uniqueDiv)

            setCategory(temp1)
            setUnit(filteredUnit)

        }
        console.log(tempCat, 'tempCat');
        console.log(bg, 'Aishuuuu');
        console.log(temp, 'SnehaaaaaaaaAsss');

    }

   

    useEffect(() => {
        if (filterData.domain) {
            let tempDom = []
            for (let i in filterData.domain) {
                let filteredDomain = _.find(commonData.d_info, { d_id: filterData.domain[i] })
                tempDom.push({
                    "d_id": filteredDomain.d_id,
                    "c_id": filteredDomain.c_id,
                    "month_from": filteredDomain.month_from,
                    "month_to": filteredDomain.month_to
                })
                console.log(filteredDomain, 'filteredDomain');
            }
            setNewCountryDomain(tempDom)
            console.log(tempDom, 'tempDom');
        }

    }, [commonData.d_info, filterData.domain])

    useEffect(() => {
        if (commonData) {
            let temp = []
            let temp1 = []
            let temp2 = []
            for (let i in commonData.d_info) {
                temp.push(commonData.d_info[i].d_id)
            }
            for (let j in uniq_country_data) {
                temp1.push(`${uniq_country_data[j].c_id}`)
                temp2.push(uniq_country_data[j].c_id)
            }
            setFilterData({
                ...filterData,
                country: temp1,
                countryArr: temp2,
                domain: temp
            })
        }
    }, [commonData, uniq_country_data])

    useEffect(() => {
        let arrunit = [];
        let totalListunit = []
        let countrylisttempdataunit = [];
        uniq_country_data && uniq_country_data.length > 0 && uniq_country_data.map(function (user) {
            // console.log(list.unitlist, 'list.unitlist');
            if ((arrunit.indexOf(user.c_id) < 0))
                arrunit.push(user.c_id);
        });

        arrunit.map((pmenu, index) => {
            let entityArrayunit = []
            let tempArrayunit = []
            if (pmenu || pmenu == null) {
                commonData.d_info && commonData.d_info.length > 0 && commonData.d_info.map((subunitlist) => {

                    if (pmenu == subunitlist.c_id) {
                        entityArrayunit = {

                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'd_name': subunitlist.d_name,
                            'is_active': subunitlist.is_active,
                            'month_from': subunitlist.month_from,
                            'month_to': subunitlist.month_to,
                            'label': subunitlist.d_name,
                            'values': subunitlist.d_id

                        }
                        tempArrayunit.push(entityArrayunit)
                    }
                })
            }
            let countryName = _.find(uniq_country_data, { c_id: pmenu })
            console.log(countryName, 'countryName');
            totalListunit = {
                'label': countryName.c_name == null ? 'Others' : countryName.c_name,
                'options': tempArrayunit
            }
            console.log(totalListunit, 'unitListData')
            countrylisttempdataunit.push(totalListunit)
        })
        setDomainListData(countrylisttempdataunit)


    }, [uniq_country_data, commonData])

    // useEffect(() => {
    //     if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
    //         let arrunit = [];
    //         let totalListunit = []
    //         let countrylisttempdataunit = [];
    //         let entityArrayunit = []
    //         let tempArrayunit = []
    //         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData && commonData.d_info && commonData.d_info.map((subunitlist) => {
    //             console.log(subunitlist, 'subunitlist-domain');
    //             if (country_info && country_info[0].c_id == subunitlist.c_id) {
    //                 entityArrayunit = {

    //                     'c_id': subunitlist.c_id,
    //                     'd_id': subunitlist.d_id,
    //                     'd_name': subunitlist.d_name,
    //                     'is_active': subunitlist.is_active,
    //                     'month_from': subunitlist.month_from,
    //                     'month_to': subunitlist.month_to,
    //                     'label': subunitlist.d_name,
    //                     'values': subunitlist.d_id

    //                 }
    //                 tempArrayunit.push(entityArrayunit)
    //                 console.log(entityArrayunit, 'entityArrayunit');
    //             }

    //         })
    //         totalListunit = {
    //             'label': country_info && country_info && country_info[0].c_id == null ? 'Others' : country_info && country_info[0].c_name,
    //             'options': tempArrayunit
    //         }

    //         countrylisttempdataunit.push(totalListunit)
    //         setDomainListData(countrylisttempdataunit)
    //     }
    // }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && commonData) {
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                const uniqueIds = commonData.le_did_infos && commonData.le_did_infos.map((item) => {
                    return item.bg_id
                })
                setBgIds(uniqueIds)
                let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(commonData && commonData.bg_groups[0].bg_id) })
                console.log(filteredDivision, 'filteredDivision');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)
                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)
                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)

                }
            }
            else if (commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0) {
                console.log('hello ais');
                let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: commonData.le_did_infos[0].le_id })
                console.log(filteredDivision, 'filteredDivision555');
                let uniqueDiv = []
                if (filteredDivision && filteredDivision.length > 0) {
                    uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                        return e.div_id;
                    });
                }
                setDivision(uniqueDiv)

                if (filteredDivision && filteredDivision.length > 0) {
                    let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredCategory, 'filteredCategory111');
                    let temp = []
                    temp.push({
                        'bg_id': filteredCategory[0].bg_id,
                        'c_id': filteredCategory[0].c_id,
                        'cat_id': filteredCategory[0].cat_id,
                        'cat_name': filteredCategory[0].cat_name,
                        'd_id': filteredCategory[0].d_id,
                        'div_id': filteredCategory[0].div_id,
                        'le_id': filteredCategory[0].le_id,
                        'div_name': filteredDivision[0].div_name
                    })
                    setCategory(temp)
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision && filteredDivision[0].div_id) })
                    console.log(filteredUnit, 'filteredUnit123');
                    setUnit(filteredUnit)

                }
                else {
                    console.log('hiii');
                    let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: commonData && commonData.le_did_infos[0].le_id })
                    console.log(filteredUnit, 'filteredUnit');
                    setUnit(filteredUnit)
                }
            }
        }
    }, [commonData, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity" && bgIds.length > 0 && commonData) {
            if (bgIds.includes(null) === true) {
                console.log('snehaaa');
                setfield({
                    ...field,
                    legalEntity: commonData && commonData.le_did_infos.length > 0 && commonData.le_did_infos,
                })

            }
            else {
                let filteredLegalEntity = _.filter(commonData.le_did_infos, { bg_id: Number(commonData.bg_groups[0].bg_id) })
                setfield({
                    ...field,
                    legalEntity: filteredLegalEntity,
                })
            }

        }
    }, [commonData, bgIds, localStorage.getItem('SelectedEntity') === "All Legal Entity"])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity" && commonData) {
            if (commonData && commonData.div_infos && commonData.div_infos.length > 0) {
                let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredCategory, 'filteredCategory111');
                let temp = []
                temp.push({
                    'bg_id': filteredCategory[0].bg_id,
                    'c_id': filteredCategory[0].c_id,
                    'cat_id': filteredCategory[0].cat_id,
                    'cat_name': filteredCategory[0].cat_name,
                    'd_id': filteredCategory[0].d_id,
                    'div_id': filteredCategory[0].div_id,
                    'le_id': filteredCategory[0].le_id,
                    'div_name': commonData && commonData.div_infos[0].div_name
                })
                setCategory(temp)
                let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(commonData && commonData.div_infos[0].div_id) })
                console.log(filteredUnit, 'filteredUnit123');
                setUnit(filteredUnit)
            }
            if (commonData && commonData.bg_groups && commonData.bg_groups.length > 0) {
                let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: entityid })
                console.log(filteredLegalEntity, 'filteredLegalEntity');
                let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { bg_id: filteredLegalEntity && filteredLegalEntity[0].bg_id })
                console.log(filteredBusinessGroup, 'filteredBusinessGroup');
                const unique = _.uniqBy(filteredBusinessGroup && filteredBusinessGroup, function (e) {
                    return e.bg_id;
                });
                console.log(unique, 'unique');
                setfield({
                    ...field,
                    business: unique,
                    legalEntity: filteredLegalEntity
                })
            }

        }
    }, [commonData, localStorage.getItem('SelectedEntity') != "All Legal Entity"])

    useEffect(() => {
        if ((commonData) && (division && division.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            division && division.length > 0 && division.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist');

                if (commonData && commonData.le_did_infos[0].le_id == subunitlist.le_id) {

                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.div_name,
                        'values': subunitlist.div_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            console.log(totalListunit, 'totalListunit');

            countrylisttempdataunit.push(totalListunit)
            console.log(countrylisttempdataunit, 'countrylisttempdataunit');
            setDivisionListData(countrylisttempdataunit)
        }
    }, [commonData, division, field.legalEntity])

    useEffect(() => {
        if (type === 'Group')
            setGroupCheckBox(checkBox)
    }, [type, checkBox])

    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }

    useEffect(() => {
        if (field.legalEntity && field.legalEntity.length > 0) {
            if (division) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                division && division.length > 0 && division.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist');

                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {

                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.div_name,
                            'values': subunitlist.div_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                console.log(totalListunit, 'totalListunit');

                countrylisttempdataunit.push(totalListunit)
                console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                setDivisionListData(countrylisttempdataunit)
            }
            if (category) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                category && category.length > 0 && category.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-category');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'bg_id': subunitlist.bg_id,
                            'c_id': subunitlist.c_id,
                            'cat_id': subunitlist.cat_id,
                            'cat_name': subunitlist.cat_name,
                            'd_id': subunitlist.d_id,
                            'div_id': subunitlist.div_id,
                            'div_name': subunitlist.div_name,
                            'le_id': subunitlist.le_id,
                            'label': subunitlist.cat_name,
                            'values': subunitlist.cat_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }
                countrylisttempdataunit.push(totalListunit)
                setCategoryListData(countrylisttempdataunit)
            }
            if (unit) {
                let totalListunit = []
                let countrylisttempdataunit = [];
                let entityArrayunit = []
                let tempArrayunit = []
                unit && unit.length > 0 && unit.map((subunitlist) => {
                    console.log(subunitlist, 'subunitlist-unit');
                    if (field && field.legalEntity[0].le_id == subunitlist.le_id) {
                        entityArrayunit = {

                            'address': subunitlist.address,
                            'business_group_id': subunitlist.business_group_id,
                            'category_id': subunitlist.category_id,
                            'country_id': subunitlist.country_id,
                            'd_ids': subunitlist.d_ids,
                            'division_id': subunitlist.division_id,
                            'le_id': subunitlist.le_id,
                            'postal_code': subunitlist.postal_code,
                            'u_id': subunitlist.u_id,
                            'u_name': subunitlist.u_name,
                            'label': subunitlist.u_name,
                            'values': subunitlist.u_id

                        }
                        tempArrayunit.push(entityArrayunit)
                        console.log(entityArrayunit, 'entityArrayunit');
                    }

                })
                totalListunit = {
                    'label': field && field.legalEntity && field.legalEntity[0].le_id == null ? 'Others' : field.legalEntity[0].le_name,
                    'options': tempArrayunit
                }

                countrylisttempdataunit.push(totalListunit)
                setUnitListData(countrylisttempdataunit)
            }
        }
    }, [field.legalEntity, division, category, unit])

    useEffect(() => {
        if ((commonData) && (category && category.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            category && category.length > 0 && category.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-category');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'bg_id': subunitlist.bg_id,
                        'c_id': subunitlist.c_id,
                        'cat_id': subunitlist.cat_id,
                        'cat_name': subunitlist.cat_name,
                        'd_id': subunitlist.d_id,
                        'div_id': subunitlist.div_id,
                        'div_name': subunitlist.div_name,
                        'le_id': subunitlist.le_id,
                        'label': subunitlist.cat_name,
                        'values': subunitlist.cat_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }
            countrylisttempdataunit.push(totalListunit)
            setCategoryListData(countrylisttempdataunit)
        }

    }, [commonData, category && category.length > 0])

    useEffect(() => {
        if ((commonData) && (unit && unit.length > 0)) {
            let totalListunit = []
            let countrylisttempdataunit = [];
            let entityArrayunit = []
            let tempArrayunit = []
            unit && unit.length > 0 && unit.map((subunitlist) => {
                console.log(subunitlist, 'subunitlist-unit');
                if (commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == subunitlist.le_id) {
                    entityArrayunit = {

                        'address': subunitlist.address,
                        'business_group_id': subunitlist.business_group_id,
                        'category_id': subunitlist.category_id,
                        'country_id': subunitlist.country_id,
                        'd_ids': subunitlist.d_ids,
                        'division_id': subunitlist.division_id,
                        'le_id': subunitlist.le_id,
                        'postal_code': subunitlist.postal_code,
                        'u_id': subunitlist.u_id,
                        'u_name': subunitlist.u_name,
                        'label': subunitlist.u_name,
                        'values': subunitlist.u_id

                    }
                    tempArrayunit.push(entityArrayunit)
                    console.log(entityArrayunit, 'entityArrayunit');
                }

            })
            totalListunit = {
                'label': commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_id == null ? 'Others' : commonData && commonData.le_did_infos && commonData.le_did_infos[0].le_name,
                'options': tempArrayunit
            }

            countrylisttempdataunit.push(totalListunit)
            setUnitListData(countrylisttempdataunit)
        }
    }, [commonData, unit && unit.length > 0])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') === "All Legal Entity") {
            console.log('hiii');
            if ((commonData && commonData.bg_groups && commonData.bg_groups.length === 0) && (legalEntityArray && legalEntityArray.length > 0)) {
                console.log('hello');
                if (unit) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    unit && unit.length > 0 && unit.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-lee');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'address': subunitlist.address,
                                'business_group_id': subunitlist.business_group_id,
                                'category_id': subunitlist.category_id,
                                'country_id': subunitlist.country_id,
                                'd_ids': subunitlist.d_ids,
                                'division_id': subunitlist.division_id,
                                'le_id': subunitlist.le_id,
                                'postal_code': subunitlist.postal_code,
                                'u_id': subunitlist.u_id,
                                'u_name': subunitlist.u_name,
                                'label': subunitlist.u_name,
                                'values': subunitlist.u_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunitleee');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setUnitListData(countrylisttempdataunit)
                }
                if (division) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    division && division.length > 0 && division.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist');

                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {

                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.div_name,
                                'values': subunitlist.div_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunitsnehaaa');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }

                    console.log(totalListunit, 'totalListunit');

                    countrylisttempdataunit.push(totalListunit)
                    console.log(countrylisttempdataunit, 'countrylisttempdataunit');
                    setDivisionListData(countrylisttempdataunit)
                }
                if (category) {
                    let totalListunit = []
                    let countrylisttempdataunit = [];
                    let entityArrayunit = []
                    let tempArrayunit = []
                    category && category.length > 0 && category.map((subunitlist) => {
                        console.log(subunitlist, 'subunitlist-category');
                        if (legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == subunitlist.le_id) {
                            entityArrayunit = {

                                'bg_id': subunitlist.bg_id,
                                'c_id': subunitlist.c_id,
                                'cat_id': subunitlist.cat_id,
                                'cat_name': subunitlist.cat_name,
                                'd_id': subunitlist.d_id,
                                'div_id': subunitlist.div_id,
                                'div_name': subunitlist.div_name,
                                'le_id': subunitlist.le_id,
                                'label': subunitlist.cat_name,
                                'values': subunitlist.cat_id

                            }
                            tempArrayunit.push(entityArrayunit)
                            console.log(entityArrayunit, 'entityArrayunit');
                        }

                    })
                    totalListunit = {
                        'label': legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_id == null ? 'Others' : legalEntityArray && legalEntityArray.length > 0 && legalEntityArray[0].le_name,
                        'options': tempArrayunit
                    }
                    countrylisttempdataunit.push(totalListunit)
                    setCategoryListData(countrylisttempdataunit)
                }
            }
        }

    }, [commonData, legalEntityArray, unit, division, category, localStorage.getItem('SelectedEntity') === "All Legal Entity"])



    console.log(checkBox, 'checkBox');


    const showmore = () => {
        setCountValue(countValue + 100)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    ["GetTskDistributionStatusDrillDown",
                        {
                            "c_ids": [
                                commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                            ],
                            "d_ids": dIds,
                            "filter_type": "Group",
                            "filter_ids": [
                                1
                            ],
                            "record_count": countValue + 100,
                            "applicability_status": criticalityValue,
                            "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid],
                            "country_domains": countryDomain,
                            "chart_year": year
                        }
                    ]
            }
        ]
        if (paramid != "") {
            getTaskDistributionShowMoreTableData({
                payload: payload,
                paramid: paramid
            })
        }
    }



    const onClose = () => {
        setOpen(false);
    };

    console.log(groupCheckBox, 'groupCheckBox');
    console.log(checkBox, 'checkBox');


    const filterChange = () => {
        setGo(true)
        setGeneratedOn(false)
        if (groupCheckBox === true && errorField === '') {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetTskDistributionStatusChart",
                        {
                            "c_ids": filterData.countryArr,
                            "d_ids": filterData.domain,
                            "filter_type": ((fieldType != "") && (type === '')) ? fieldType : "Group",
                            "filter_ids": [
                                (fieldType == "LegalEntity" && type === '') ? filterData.legalentity :
                                    (fieldType == 'Division' && type === '') ? Number(filterData.division) :
                                        (fieldType == 'Category' && type === '') ? Number(filterData.category) :
                                            (fieldType == 'Unit' && type === '') ? Number(filterData.unit) :
                                                (fieldType == 'BusinessGroup' && type === '') ? Number(filterData.business) :
                                                    (fieldType != "" && type === 'Group') ? 1 :
                                                        1
                            ],
                            "le_ids":
                                filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                            ,
                            "country_domains": newCountryDomain,
                            "chart_year": year
                        }
                    ]
                }
            ]
            if (paramid != "") {
                getTaskDistributionchart({
                    payload: payload,
                    paramid: paramid
                })
            }
            setOpen(false);

        }
        else {
            // toast.error('Please select the filter')
            toast.error(errorField ? errorField : 'Please select the filter')
        }
    }

    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
            >
                <Input className='form-control'
                    ref={searchInput}
                    placeholder={'Search ' + placeholder}
                    autoFocus={'on'}
                    value={selectedKeys[0]}
                    // onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}

                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    //     // color: filtered ? '#1890ff' : undefined,
                    //     fontWeight: 'bold',
                    //     // backgroundColor:'blue',
                    //     fontSize: '15px',
                    //     color: 'black'
                }}
            />
            // <FileSearchOutlined 
            // style={{
            //     // color: filtered ? '#1890ff' : undefined,
            //     fontWeight:'bold',
            //     // backgroundColor:'blue',
            //     // fontSize:'20px',
            //     color:'black'
            // }}
            // />
            // <i class="bi bi-search"></i>
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };


    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: 'index',
            key: 'index',
            width: 5,
            align: 'center'
        },

        {
            title: "Statutory",
            ...getColumnSearchProps('s_prov', 'Statutory'),
            dataIndex: false,
            key: 's_prov',
            width: 10,
            filteredValue: filteredInfo.s_prov || null,
            render: (record) => {
                console.log(record,'recordrecord');
                return <p title={record.s_prov} className='cut-text'>
                    {record.s_prov}</p>
            }
        },
        {
            title: 'Compliance Task',
            ...getColumnSearchProps('comp_name', 'Compliance Task'),
            dataIndex: false,
            key: 'comp_name',
            ellipsis: false,
            width: 10,
            render: (row, record) => {
                if (record.download_url_list) {
                    return <p title={record.comp_name} className='cut-text'><a href={`${urls + '/compliance-approval/' + record.download_url_list}`}>{record.comp_name}</a></p>
                }
                else {
                    return <p title={record.comp_name} className='cut-text'>{record.comp_name}</p>
                }
            },
            filteredValue: filteredInfo.comp_name || null,

        },
        {
            title: 'Criticality',
            ...getColumnSearchProps('criticality', 'Criticality'),
            dataIndex: 'criticality',
            key: 'criticality',
            ellipsis: false,
            width: 10,
            align: 'center',
            filteredValue: filteredInfo.criticality || null,

        },
        {
            title: 'Compliance Description',
            ...getColumnSearchProps('descp', 'Description'),
            dataIndex: false,
            key: 'descp',
            ellipsis: false,
            width: 10,
            filteredValue: filteredInfo.descp || null,
            render: (record) => {
                return <p title={record.descp} className='cut-text'>{record.descp}</p>
            }

        },
        {
            title: 'Penal Conseqeunces',
            ...getColumnSearchProps('p_cons', 'Penal Conseqeunces'),
            dataIndex: false,
            key: 'p_cons',
            ellipsis: false,
            width: 10,
            filteredValue: filteredInfo.p_cons || null,
            render: (record) => {
                return <p title={record.p_cons} className='cut-text'>{record.p_cons}</p>
            }

        },
        {
            title: "Compliance Frequency",
            ...getColumnSearchProps('frequency', 'Compliance Frequency'),
            dataIndex: 'frequency',
            key: 'frequency',
            ellipsis: false,
            width: 10,
            filteredValue: filteredInfo.frequency || null,

        },
        {
            title: 'Repeats',
            ...getColumnSearchProps('summary', 'Repeats'),
            dataIndex: false,
            key: 'summary',
            ellipsis: false,
            width: 10,
            filteredValue: filteredInfo.summary || null,
            render: (record) => {
                return <p title={record.summary} className='cut-text'>{record.summary}</p>
            }

        },

        {
            title: 'Trigger Before',
            ...getColumnSearchProps('statu_dates'),
            dataIndex: 'false',
            key: 'statu_dates',
            ellipsis: false,
            width: 10,
            dataIndex: false,
            key: 'statu_dates',
            render: (record) => {
                console.log(record && record.length > 0 && record.statu_dates && record.statu_dates[0].trigger_before_days, 'record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days');
                if (record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days) {
                    return <p title={record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days} className='cut-text'>{record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days} Days</p>
                }
                else {
                    return null
                }

            },
            filteredValue: filteredInfo.statu_dates || null,
        },
    ];



    // const columns = [
    //     {
    //         title: 'Statutory',
    //         dataIndex: 's_prov',
    //         key: 's_prov',
    //     },
    //     {
    //         title: 'Compliance Task',
    //         dataIndex: 'comp_name',
    //         key: 'comp_name',
    //     },
    //     {
    //         title: 'Criticality',
    //         dataIndex: 'criticality',
    //         key: 'criticality',
    //     },
    //     {
    //         title: 'Compliance Description',
    //         dataIndex: 'descp',
    //         key: 'descp',
    //     },
    //     {
    //         title: 'Panel Conseqeunces',
    //         dataIndex: 'p_cons',
    //         key: 'p_cons',
    //     },
    //     {
    //         title: 'Compliance Frequency',
    //         dataIndex: 'frequency',
    //         key: 'frequency',
    //     },
    //     {
    //         title: 'Repeats',
    //         dataIndex: 'summary',
    //         key: 'summary',
    //     },
    //     {
    //         title: 'Trigger Before',
    //         dataIndex: 'statu_dates',
    //         key: 'statu_dates',
    //         render: (record) => {
    //             console.log(record && record.length > 0 && record[0].trigger_before_days, 'record && record.statu_dates && record.statu_dates.length > 0 && record.statu_dates[0].trigger_before_days');
    //             return <p>{record && record.length > 0 && record[0].trigger_before_days
    //             }</p>
    //         }
    //     },
    // ];

    // useEffect(() => {
    //     if (data && data.length > 0) {
    //         console.log('test');
    //         let total = ((data && data.length > 0 && data[0].assigned_count) + (data && data.length > 0 && data[0].not_opted_count) + (data && data.length > 0 && data[0].unassign_count) + (data && data.length > 0 && data[0].not_applicable_count))
    //         if (table == false) {

    //             let x = am4core.create("chartdiv", am4charts.PieChart3D);
    //             am4core.useTheme(am4themes_animated);
    //             x.paddingRight = 20;
    //             x.data = [{
    //                 "task": "Assigned",
    //                 "count": data && data.length > 0 && data[0].assigned_count,
    //                 "color": am4core.color("#00a3a3")
    //             }, {
    //                 "task": "Not Opted",
    //                 "count": data && data.length > 0 && data[0].not_opted_count,
    //                 "color": am4core.color("#dd070c")
    //             },
    //             {
    //                 "task": "Unassigned",
    //                 "count": data && data.length > 0 && data[0].unassign_count,
    //                 "color": am4core.color("#ff9c80")
    //             },
    //             {
    //                 "task": "Not Applicable",
    //                 "count": data && data.length > 0 && data[0].not_applicable_count,
    //                 "color": am4core.color("#808080")
    //             },
    //             ];

    //             var pieSeries = x.series.push(new am4charts.PieSeries3D());
    //             pieSeries.dataFields.value = "count";
    //             console.log(pieSeries.dataFields.value, ' pieSeries.dataFields.value');
    //             pieSeries.dataFields.category = "task";
    //             pieSeries.slices.template.propertyFields.fill = "color";
    //             pieSeries.calculatePercent = true;
    //             pieSeries.calculateTotals = true;


    //             pieSeries.slices.template.tooltipText = `{category}: {value.percent.formatNumber('#.00')}[/]% : ({value.value} out of ${total})`;
    //             chart.current = x;
    //             x.legend = new am4charts.Legend();
    //             x.legend.valueLabels.template.disabled = true;
    //             x.legend.readerValueText = "{category}";
    //             x.legend.position = "right";
    //             x.legend.useDefaultMarker = true;
    //             var marker = x.legend.markers.template.children.getIndex(0);
    //             marker.cornerRadius(12, 12, 12, 12);
    //             marker.strokeWidth = 1;
    //             marker.strokeOpacity = 0.5;
    //             pieSeries.slices.template.events.on("hit", function (ev) {
    //                 setTable(true)
    //                 const payload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request":
    //                             ["GetTskDistributionStatusDrillDown",
    //                                 {
    //                                     "c_ids": [
    //                                         commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
    //                                     ],
    //                                     "d_ids": [
    //                                         commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
    //                                     ],
    //                                     "filter_type": "Group",
    //                                     "filter_ids": [
    //                                         1
    //                                     ],
    //                                     "record_count": 0,
    //                                     "applicability_status": ev.target.dataItem.category,
    //                                     "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
    //                                         commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
    //                                     ],
    //                                     "country_domains": [
    //                                         {
    //                                             "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
    //                                             "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
    //                                             "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
    //                                             "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
    //                                         }
    //                                     ],
    //                                     "chart_year": year
    //                                 }
    //                             ]
    //                     }
    //                 ]
    //                 getTaskDistributionTable({
    //                     payload: payload,
    //                     paramid: paramid
    //                 })
    //             });

    //         }
    //     }
    // }, [data, table, localStorage.getItem('chart')])

    useEffect(() => {
        if (data && data.length > 0) {
            console.log('test');
            let total = ((data && data.length > 0 && data[0].assigned_count) + (data && data.length > 0 && data[0].not_opted_count) + (data && data.length > 0 && data[0].unassign_count) + (data && data.length > 0 && data[0].not_applicable_count))
            if (table == false) {
                var container = am4core.create("container", am4core.Container);
                container.width = am4core.percent(100);
                container.height = am4core.percent(100);
                container.layout = "vertical";
                container.background.fill = am4core.color("#000");
                container.background.fillOpacity = 0.1;
                container.background.stroke = am4core.color("#000");
                container.background.strokeOpacity = 0.2;
                container.background.strokeWidth = 2;

                if ((data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0))) {
                    var title3 = container.createChild(am4core.Label)
                    title3.text = "No Data to Display"
                    title3.disabled = false;
                    title3.align = 'left'
                    title3.paddingTop = '45%'
                    title3.paddingLeft = '43%'
                    // title3.fontWeight = '500'
                    title3.fontSize = '16'
                }

                else {

                    function addChart(data) {

                        let x = container.createChild(am4charts.PieChart3D);
                        am4core.useTheme(am4themes_animated);
                        x.paddingRight = 20;
                        x.data = data

                        var pieSeries = x.series.push(new am4charts.PieSeries3D());
                        pieSeries.dataFields.value = "Count";
                        console.log(pieSeries.dataFields.value, ' pieSeries.dataFields.value');
                        pieSeries.dataFields.category = "Status";
                        pieSeries.slices.template.propertyFields.fill = "color";
                        pieSeries.calculatePercent = true;
                        pieSeries.calculateTotals = true;
                        
                        pieSeries.labels.template.text = "{category}: {value}";
                        pieSeries.slices.template.tooltipText = `{category}: {value} out of ${total} ({value.percent.formatNumber('#.00')}[/]%)`;
                        chart.current = x;
                        x.legend = new am4charts.Legend();
                        x.legend.valueLabels.template.disabled = true;
                        x.legend.valueLabels.template.text = `{value}`;
                        // x.legend.readerValueText = "{category}";
                        x.legend.position = "right";
                        x.legend.useDefaultMarker = true;
                        var marker = x.legend.markers.template.children.getIndex(0);
                        marker.cornerRadius(12, 12, 12, 12);
                        marker.strokeWidth = 1;
                        marker.strokeOpacity = 0.5;
                        pieSeries.slices.template.events.on("hit", function (ev) {
                            setCountValue(0)
                            setShowMoreCount(0)
                            setDataCount(ev.target.dataItem._dataContext.Count)
                            setCriticalityValue(ev.target.dataItem.category)

                            setTable(true)
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusDrillDown",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": dIds,
                                                "filter_type": fieldType != "" ? fieldType : "Group",
                                                "filter_ids": [
                                                    fieldType == "LegalEntity" ? filterData.legalentity :
                                                        fieldType == 'Division' ? Number(filterData.division) :
                                                            fieldType == 'Category' ? Number(filterData.category) :
                                                                fieldType == 'Unit' ? Number(filterData.unit) :
                                                                    fieldType == 'BusinessGroup' ? Number(filterData.business) :
                                                                        1
                                                ],
                                                "record_count": 0,
                                                "applicability_status": ev.target.dataItem.category,
                                                "le_ids":
                                                    filterData && filterData.legalentity ? [filterData.legalentity] : localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [entityid]
                                                ,
                                                "country_domains": countryDomain,
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getTaskDistributionTable({
                                payload: payload,
                                paramid: paramid
                            })
                        });

                        x.exporting.menu = new am4core.ExportMenu();
                        x.exporting.dataFields = {
                            "Status": "Status",
                            "Count": "Count"
                        }
                        x.exporting.menu.items = [{
                            "label": "...",
                            "menu": [
                                {
                                    "label": "Image",
                                    "menu": [
                                        { "type": "png", "label": "PNG" },
                                        { "type": "jpg", "label": "JPG" },
                                        { "type": "svg", "label": "SVG" },
                                        { "type": "pdf", "label": "PDF" },
                                        { "type": "gif", "label": "GIF" }
                                    ]
                                }, {
                                    "label": "Data",
                                    "menu": [
                                        { "type": "csv", "label": "CSV" },
                                        { "type": "xlsx", "label": "XLSX" },
                                    ]
                                }, {
                                    "label": "Print", "type": "print"
                                }
                            ]
                        }];
                        x.exporting.menu.items[0].icon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+";



                        var title = x.titles.create();
                        title.text = generatedOn === true ? `Generated on :${timeZoneData.tz_name} ${timeZone}` : `Generated on :${time} (UTC)`
                        title.disabled = false;
                        title.align = 'left'
                        title.marginTop = '2%'
                        title.marginLeft = '2%'

                        var title1 = x.titles.create();
                        title1.text =
                            ((fieldType == "LegalEntity" && go == true && type === '') ? `Task Distribution of Legal Entity [bold]${filterDataNames.legalentity} (${year})[/]` : (
                                (fieldType == "Division" && go == true && type === '') ? `Task Distribution of Division [bold]${filterDataNames.division} (${year})[/]` : (
                                    (fieldType == "Category" && go == true && type === '') ? `Task Distribution of Category [bold]${filterDataNames.category} (${year})[/]` : (
                                        (fieldType == "BusinessGroup" && go == true && type === '') ? `Task Distribution of Business Group [bold]${filterDataNames.business} (${year})[/]` : (
                                            (fieldType == "Unit" && go == true && type === '') ? `Task Distribution of Unit [bold]${filterDataNames.unit} (${year})[/]` : `Task Distribution of ${commonData && commonData.g_name} (${year})`
                                        )))
                            ))
                        title1.disabled = false;
                        title1.align = 'center'
                        title1.marginTop = '5%'
                        title1.marginLeft = '2%'
                        title1.fontSize = '17px'
                        title1.fontWeight = '400'
                        // title1.fontWeight = 'bold'

                        // <b><p>Country-Domain</p></b>
                        // <b><p>{commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_name}</p></b><p>{commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_name}</p>

                        var label = x.createChild(am4core.Label);
                        label.text = "Country-Domain";
                        label.fontSize = 14;
                        label.align = "left";
                        label.marginLeft = '2%';
                        label.marginBottom = '2%'
                        label.fontWeight = 'bold'

                        var label1 = x.createChild(am4core.Label);
                        label1.text = `${textArray}`
                        label1.fontSize = 14;
                        label1.align = "left";
                        label1.marginLeft = '2%';
                        label1.marginBottom = '5%'

                        var label2 = x.createChild(am4core.Label);
                        label2.text = `Total Task : ${total}`;
                        label2.fontSize = 14;
                        label2.align = "center";
                        label2.marginLeft = '2%';
                        label2.marginBottom = '4%'
                        label2.fontWeight = 'bold'

                    }

                    addChart([
                        (data && data.length > 0 && data[0].assigned_count > 0 ?
                            {
                                "Status": "Assigned",
                                "Count": data && data.length > 0 && data[0].assigned_count,
                                "color": am4core.color("#00a3a3")
                            } : {})
                        , (data && data.length > 0 && data[0].not_opted_count > 0 ?
                            {
                                "Status": "Not Opted",
                                "Count": data && data.length > 0 && data[0].not_opted_count,
                                "color": am4core.color("#dd070c")
                            } : {}),
                        (data && data.length > 0 && data[0].unassign_count > 0 ?
                            {
                                "Status": "Unassigned",
                                "Count": data && data.length > 0 && data[0].unassign_count,
                                "color": am4core.color("#ff9c80")
                            } : {}),
                        (data && data.length > 0 && data[0].not_applicable_count > 0 ?
                            {
                                "Status": "Not Applicable",
                                "Count": data && data.length > 0 && data[0].not_applicable_count,
                                "color": am4core.color("#808080")
                            } : {})]);
                }

            }
        }

    }, [data, timeZone, timeZoneData, table, localStorage.getItem('chart')])
    const [fullscreen, setfullscreen] = useState(false);
    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    return (
        <div>
            <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            <div id='page-wrapper' className="page-wrapper">
                <div className="page-titles pb-0 pt-1">

                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <span style={{ fontSize: '16px' }}>Dashboard</span>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <span style={{ fontSize: '16px' }}>Task Distribution Chart</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                        <div
                            className="
                            col-lg-6 col-md-6 col-sm-6 col-xs-6
                d-none d-md-flex
                align-items-center
                justify-content-end">
                            {/* <div> <Tooltip title="" color='#1890ff'>
                                <Button type="primary" shape="round" className='addbutton'

                                    icon={<PlusCircleOutlined />} size='default' onClick={() => {
                                        setShowList(true)
                                        setAddType('add')
                                    }}>
                                    Add
                                </Button>

                            </Tooltip></div> */}


                            {table == false ? <Button icon={<FilterOutlined />} type="primary" className='addbutton' style={{ background: "#0096FF", marginRight: '3%', borderRadius: '15px' }} onClick={() => {
                                setOpen(true)
                            }}
                                size='default' >
                                <Tooltip placement='top' title='Filter'>Filter</Tooltip>
                            </Button> : <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", marginRight: '3%' }}
                                icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                    setTable(false)
                                }}>
                                <Tooltip placement='top' title='Back'>Back</Tooltip>
                            </Button>}




                            <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black  full-mode fullscreenradius" >
                                <Tooltip placement='left' title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                            </button>

                            {/* <button onClick={() => {
                                    setShowList(true)
                                    setAddType('add')
                                }} className="icons twitter" style={{ border: 'none', margin: '0px' }}>
                                    <span className="tooltips" style={{ right: '105%' }}>Add</span>
                                    <span><i id={localStorage.getItem('currentTheme')}><PlusCircleOutlined /></i></span>
                                </button> */}


                        </div>
                    </div>

                </div>
                {
                    table == true ?
                        <div className="container-fluid pt-1">
                            <p style={{ fontSize: '16px' }}><b>{`${commonData && commonData.g_name} - ${criticalityValue} Compliance`}</b></p>
                            <Collapse className='cric' defaultActiveKey={['0']}>
                                {taskDistributionTable && taskDistributionTable.length > 0 &&
                                    taskDistributionTable.map((item, i) => {
                                        let unitName = item.ap_compliances
                                        let keys = Object.keys(unitName);
                                        let values = Object.values(unitName);


                                        return (
                                            <Panel style={{ marginBottom: '5px' }} header={item.level1_statutory_name}>
                                                {keys && keys.length > 0 && keys.map((items, j) => {
                                                    console.log(values[j], 'values[j]');
                                                    let tempArr = []
                                                    if (values && values.length > 0) {
                                                        for (let m in values[j]) {
                                                            tempArr.push({
                                                                'index': count,
                                                                'ageing': values[j][m].ageing,
                                                                'assignee_name': values[j][m].assignee_name,
                                                                'bg_name': values[j][m].bg_name,
                                                                'comp_name': values[j][m].comp_name,
                                                                'comp_id': values[j][m].comp_id,
                                                                'frequency': values[j][m].frequency,
                                                                'compfielaw_url': values[j][m].compfielaw_url,
                                                                'criticality': values[j][m].criticality,
                                                                'descp': values[j][m].descp,
                                                                'is_active': values[j][m].is_active,
                                                                'div_name': values[j][m].div_name,
                                                                'p_cons': values[j][m].p_cons,
                                                                'remarks': values[j][m].remarks,
                                                                's_prov': values[j][m].s_prov,
                                                                'statu_dates': values[j][m].statu_dates,
                                                                'summary': values[j][m].summary,
                                                                // 'index': values[j][m].,
                                                                'le_name': values[j][m].le_name,
                                                                'download_url_list': values[j][m].download_url_list
                                                            })
                                                            { <span hidden>{count = count + 1}</span> }
                                                            // childDatas[i] = []
                                                        }
                                                        console.log(tempArr, 'tempArr');
                                                    }
                                                    values[j] = tempArr
                                                    console.log(values[j],'values[j]');
                                                    return (
                                                        <>
                                                            <p><b>{keys[j]}</b></p>

                                                            <Table
                                                                scroll={{ x: 1200 }}
                                                                size={'small'}
                                                                columns={columns}
                                                                dataSource={values[j]}
                                                                bordered
                                                                id='service-user-priv'
                                                                className={localStorage.getItem("currentTheme")}
                                                                pagination={false}
                                                                onChange={handleChange}


                                                            />
                                                        </>
                                                    )

                                                })}


                                            </Panel>
                                        )
                                    })
                                }
                            </Collapse>
                            {dataCount == showmoreCount ? false : <div className='col-md-12 mt-2'>
                                <div className='row'>
                                    <div className='col-md-4'></div>
                                    <div className='col-md-4'>
                                        <button className="btn btn-success" style={{ float: "center", marginLeft: "50px", borderRadius: '15px' }}
                                            onClick={showmore}
                                        >Show More <i> <DownOutlined /></i></button>
                                    </div>
                                    <div className='col-md-4'></div>
                                </div>
                            </div>
                            }


                            {/* <Table dataSource={dataSource} columns={columns} pagination={false}
                    className='userprivclass' /> */}
                        </div> :
                        <div className="container-fluid pt-1">
                            <div className="row" >
                                <div className="col-md-12">

                                </div>
                                {/* {(data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0)) ? */}

                                {/* <p style={{ marginTop: '18%', marginLeft: '35%', marginBottom: '18%' }}>No Data to Display </p> : */}
                                <>
                                    <div className="col-md-12 mt-0">
                                        <>
                                            <div id="container" style={{ width: "100%", marginTop: '10px', height: "400px" }}></div>
                                            {(data && data.length > 0) && ((data && data.length && data[0].assigned_count == 0) && (data && data.length && data[0].not_applicable_count == 0) && (data && data.length && data[0].not_opted_count == 0) && (data && data.length && data[0].unassign_count == 0)) ? '' :
                                                <div className="col-md-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-3">

                                                        </div>
                                                        <div className="col-md-3">
                                                        </div>
                                                        <div className="col-md-6  d-none d-md-flex
                align-items-center
                justify-content-end" >
                                                            <label htmlFor=""><b>Time Zone :</b></label>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                onChange={(data) => {
                                                                    setTimeZone(data)
                                                                    setGeneratedOn(true)
                                                                    const payload = [
                                                                        authtoken,
                                                                        {
                                                                            "session_token": authtoken,
                                                                            "request": [
                                                                                "GetDateTime_ByZone",
                                                                                {
                                                                                    "tz_name": data
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                    getTimeZoneData({
                                                                        payload: payload,
                                                                        paramid: paramid
                                                                    })

                                                                }}
                                                                placeholder="Enter Time Zone"
                                                                style={{ marginLeft: '5%' }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            // value={compfie.user_Group}

                                                            >

                                                                {TimeZoneValue && TimeZoneValue.tz_list.length > 0 && TimeZoneValue.tz_list.map((item, i) => {

                                                                    return (

                                                                        <Option key={item.tz_name}>

                                                                            {item.tz_name}

                                                                        </Option>

                                                                    );

                                                                })}
                                                                {/* );
                                                })} */}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>}

                                        </>
                                    </div>

                                </>
                                {/* } */}
                            </div>
                        </div>
                }
              <Drawer title="FILTER BY" className={localStorage.getItem("currentTheme") + ' resize-drawer'} placement="right" closable={false} onClose={false} visible={open}
                    extra={
                        <Space>
                            <CloseCircleOutlined style={{ marginTop: '2%', marginLeft: '75', fontSize: '140%' }} onClick={onClose} />
                        </Space>
                    }
                >
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Country</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Country"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.country ? filterData.country : undefined}
                                    // onChange={(data, values) => {
                                    //     setFieldTypeClick('Country')
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         country: data,
                                    //         domain: ''
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         country: values.children,
                                    //         domain: '',
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setType('')

                                    // }
                                    // }
                                    onChange={(option) => HandelCountryChange(option)}
                                    onDeselect={(data) => {

                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {uniq_country_data &&
                                        uniq_country_data.length > 0 &&
                                        uniq_country_data.map((item, i) => {
                                            return (
                                                <Option key={item.c_id}>
                                                    {item.c_name}
                                                </Option>
                                            );
                                        })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label><b>Domain</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Domain"
                                    mode='multiple'
                                    maxTagCount='responsive'
                                    value={filterData.domain ? filterData.domain : undefined}
                                    // onChange={(data, values) => {
                                    //     console.log(data,'data-domain');
                                    //     let filteredBusinessGroup = _.filter(commonData && commonData.bg_groups, { d_id: Number(data) })
                                    //     let filteredDivision = _.filter(commonData && commonData.div_infos, { d_id: Number(data) })
                                    //     // let filteredCategory = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     // let filteredUnit = _.filter(commonData && commonData.bg_groups,{d_id:Number(data)})
                                    //     let filteredLegalEntity = commonData && commonData.le_did_infos.filter(item => item.d_ids.includes(Number(data)))
                                    //     let filteredCategory = _.filter(commonData.cat_info, { div_id: Number(filteredDivision[0].div_id) })
                                    //     let filteredUnit = _.filter(commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                    //     let temp = []
                                    //     temp.push({
                                    //         'bg_id': filteredCategory[0].bg_id,
                                    //         'c_id': filteredCategory[0].c_id,
                                    //         'cat_id': filteredCategory[0].cat_id,
                                    //         'cat_name': filteredCategory[0].cat_name,
                                    //         'd_id': filteredCategory[0].d_id,
                                    //         'div_id': filteredCategory[0].div_id,
                                    //         'le_id': filteredCategory[0].le_id,
                                    //         'div_name': division[0].div_name
                                    //     })
                                    //     setCategory(temp)
                                    //     setUnit(filteredUnit)
                                    //     setfield({
                                    //         ...field,
                                    //         legalEntity: filteredLegalEntity,
                                    //         business: filteredBusinessGroup
                                    //     })
                                    //     let temp1=[]
                                    //     for(let i in commonData.d_info){
                                    //         temp1.push(commonData.d_info[i].d_id)
                                    //     }
                                    //     setFilterData({
                                    //         ...filterData,
                                    //         domain: temp1
                                    //     })
                                    //     setFilterDataNames({
                                    //         ...filterDataNames,
                                    //         domain: values.children,
                                    //         legalentity: '',
                                    //         division: '',
                                    //         unit: '',
                                    //         business: '',
                                    //         category: '',
                                    //         unit: ''
                                    //     })
                                    //     setDivision(filteredDivision)
                                    //     // let temp = []
                                    //     // temp.push({
                                    //     //     'bg_id': filteredCategory[0].bg_id,
                                    //     //     'c_id': filteredCategory[0].c_id,
                                    //     //     'cat_id': filteredCategory[0].cat_id,
                                    //     //     'cat_name': filteredCategory[0].cat_name,
                                    //     //     'd_id': filteredCategory[0].d_id,
                                    //     //     'div_id': filteredCategory[0].div_id,
                                    //     //     'le_id': filteredCategory[0].le_id,
                                    //     //     'div_name': filteredDivision[0].div_name
                                    //     // })
                                    //     // setCategory(temp)
                                    //     // setUnit(filteredUnit)
                                    // }
                                    // }
                                    onChange={(option) => HandelChange(option)}
                                    // onDeselect={(data) => {
                                    //     console.log(data,'data-deselect');
                                    //     filterData && filterData.domain.splice(data,1)
                                    // }}
                                    style={{ width: '100%' }}
                                    disabled={filterData.country == '' ? true : false}
                                >
                                    {domainListData && domainListData.length > 0 &&
                                        domainListData.map((finalList_unitlist) => (
                                            <OptGroup label={finalList_unitlist.label}>
                                                {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                    <Option value={subUnitData.d_id}>{subUnitData.d_name}</Option>

                                                ))}
                                            </OptGroup>
                                        ))}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-1">
                                <input type='checkbox' checked={checkBox === true ? true : false} onChange={() => {
                                    setType('Group')
                                    setCheckBox(!checkBox)
                                }}></input>
                            </div>
                            <div className="col-md-4">
                                <label><b>Group</b></label>
                            </div>
                            {/* <div className="col-md-2">

                        </div> */}
                            <div className="col-md-1">
                                {/* <input type='checkbox' onChange={(e) => {
                                if (e.target.value) {
                                    setFilterData({
                                        ...filterData,
                                        chartType: 'Consolidated'
                                    })
                                }
                            }}></input> */}
                            </div>
                            <div className="col-md-4">
                                {/* <label><b>Consolidated</b></label> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-6">

                                <label><b>From Date</b></label>
                                <DatePicker
                                    disabled={true}
                                    allowClear={() => {
                                        setFilterData({
                                            ...filterData,
                                            from_date: null,
                                        })
                                    }}
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        setFilterData({
                                            ...filterData,
                                            from_date: dateString,
                                        })

                                    }}

                                />

                            </div>
                            <div className="col-md-6">

                                <label><b>To Date</b></label>

                                <DatePicker
                                    // value={consolidatedData.from_date ? moment(consolidatedData.from_date) : ''}
                                    // value={consolidatedData.from_date}
                                    // disabled={consolidatedData.domainy == '' ? true : false}
                                    // onChange={(date, dateString) => {

                                    //     console.log(dateString, 'dateString');
                                    //     setConsolidatedData({
                                    //         ...consolidatedData,
                                    //         from_date: dateString,
                                    //         to_date: moment(dateString).add(3, 'months')
                                    //     })

                                    // }}
                                    disabled={true}
                                    onChange={(date, dateString) => {

                                        console.log(dateString, 'dateString');
                                        if (dateString != undefined) {
                                            setFilterData({
                                                ...filterData,
                                                to_date: dateString,
                                            })
                                        }
                                        else {
                                            setFilterData({
                                                ...filterData,
                                                to_date: null,
                                            })
                                        }


                                    }}

                                />

                            </div>
                        </div>
                    </div>

                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'BusinessGroup' ? 'blue' : 'black' }}><b>Business Group</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Business Group"
                                    value={filterDataNames.business ? filterDataNames.business : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('BusinessGroup')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredEntity = _.filter(commonData && commonData.le_did_infos, { bg_id: Number(data) })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { bg_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { bg_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                        setfield({
                                            ...field,
                                            legalEntity: filteredEntity
                                        })
                                        setFieldType('BusinessGroup')
                                        setFilterData({
                                            ...filterData,
                                            business: data,
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            business: values.children,
                                            legalentity: '',
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        setUnit(filteredUnit)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}
                                >
                                    {field && field.business && field.business.length && field.business.map((item, i) => {
                                        return (
                                            <Option key={item.bg_id}>
                                                {item.bg_name}
                                            </Option>
                                        );
                                    })}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'LegalEntity' ? 'blue' : 'black' }}><b>Legal Entity</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    value={filterDataNames.legalentity ? filterDataNames.legalentity : undefined}
                                    placeholder="Enter Legal Entity"
                                    onClick={() => {
                                        setFieldTypeClick('LegalEntity')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        // let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        let filteredDivision = _.filter(commonData && commonData.div_infos, { le_id: data })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { le_id: data })
                                        let filteredLegalEntity = _.filter(commonData && commonData.le_did_infos, { le_id: data })
                                        setLegalEntityArray(filteredLegalEntity)
                                        // setfield({
                                        //     ...field,
                                        //     legalEntity: filteredLegalEntity
                                        // })
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(filteredDivision[0].div_id) })
                                            setUnit(filteredUnit)
                                        }
                                        else {
                                            let filteredUnit = _.filter(commonData && commonData.chart_units, { le_id: data })
                                            console.log(filteredUnit, 'filteredUnit555');
                                            setUnit(filteredUnit)
                                        }

                                        console.log(filteredDivision, 'filteredDivisionLeg');
                                        setFilterData({
                                            ...filterData,
                                            legalentity: data
                                        })
                                        setFieldType('LegalEntity')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            legalentity: values.children,
                                            division: '',
                                            unit: '',
                                            category: '',
                                            unit: ''
                                        })
                                        let uniqueDiv = []
                                        if (filteredDivision && filteredDivision.length > 0) {
                                            uniqueDiv = _.uniqBy(filteredDivision && filteredDivision, function (e) {
                                                return e.div_id;
                                            });
                                        }
                                        setDivision(uniqueDiv)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision[0].div_name
                                        })
                                        setCategory(temp)
                                        // setUnit(filteredUnit)
                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {field && field.legalEntity && field.legalEntity.length && field.legalEntity.map((item, i) => {
                                        return (
                                            <Option key={item.le_id}>
                                                {item.le_name}
                                            </Option>
                                        );
                                    })}

                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Division' ? 'blue' : 'black' }}><b>Division</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Division"
                                    value={filterDataNames.division ? filterDataNames.division : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Division')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        let filteredDivision = _.find(commonData && commonData.div_infos, { div_id: Number(data) })
                                        let getLEname = _.find(commonData && commonData.le_did_infos, { le_id: filteredDivision.le_id })
                                        let filteredCategory = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        let filteredUnit = _.filter(commonData && commonData.chart_units, { division_id: Number(data) })
                                        setFilterData({
                                            ...filterData,
                                            division: data
                                        })

                                        // let subData = _.filter(commonData && commonData.cat_info, { div_id: Number(data) })
                                        console.log(filteredDivision, 'filteredDivision');
                                        // setDivisionObj(filteredDivision)
                                        // setLEObj(getLEname)
                                        // setCategory(subData)
                                        setFieldType('Division')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            division: values.children,
                                            unit: '',
                                            category: '',
                                        })
                                        setUnit(filteredUnit)
                                        let temp = []
                                        temp.push({
                                            'bg_id': filteredCategory[0].bg_id,
                                            'c_id': filteredCategory[0].c_id,
                                            'cat_id': filteredCategory[0].cat_id,
                                            'cat_name': filteredCategory[0].cat_name,
                                            'd_id': filteredCategory[0].d_id,
                                            'div_id': filteredCategory[0].div_id,
                                            'le_id': filteredCategory[0].le_id,
                                            'div_name': filteredDivision.div_name
                                        })
                                        setCategory(temp)

                                        console.log(category, 'catStateeeee');

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >

                                    {divisionListData && divisionListData.length > 0 &&
                                        divisionListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.div_id}>{subUnitData.div_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }

                                        }
                                        )}


                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Category' ? 'blue' : 'black' }}><b>Category</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Category"
                                    value={filterDataNames.category ? filterDataNames.category : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Category')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            category: data,
                                            unit: '',
                                        })
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            category: values.children
                                        })
                                        let subData = _.filter(commonData && commonData.chart_units, { category_id: Number(data) })
                                        setUnit(subData)
                                        setFieldType('Category')
                                        setGo(false)

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {categoryListData && categoryListData.length > 0 &&
                                        categoryListData.map((finalList_unitlist) => {
                                            console.log(finalList_unitlist, 'finalList_unitlist');
                                            if (finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.cat_id}>{`${subUnitData.div_name}-${subUnitData.cat_name}`}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-md-4">
                                <label style={{ color: fieldTypeClick == 'Unit' ? 'blue' : 'black' }}><b>Unit</b></label>&nbsp;
                            </div>
                            <div className="col-md-8">
                                <Select
                                    getPopupContainer={trigger => trigger.parentNode}
                                    allowClear={false}
                                    size="default"
                                    placeholder="Enter Unit"
                                    value={filterDataNames.unit ? filterDataNames.unit : undefined}
                                    onClick={() => {
                                        setFieldTypeClick('Unit')
                                    }}
                                    onChange={(data, values) => {
                                        setType('')
                                        setErrorField('')
                                        setGroupCheckBox(true)
                                        setCheckBox(false)
                                        setFilterData({
                                            ...filterData,
                                            unit: data
                                        })
                                        setFieldType('Unit')
                                        setGo(false)
                                        setFilterDataNames({
                                            ...filterDataNames,
                                            unit: values.children
                                        })

                                    }
                                    }
                                    style={{ width: '100%' }}
                                    disabled={filterData.domain == '' ? true : false}

                                >
                                    {unitListData && unitListData.length > 0 &&
                                        unitListData.map((finalList_unitlist) => {
                                            if (finalList_unitlist && finalList_unitlist.options && finalList_unitlist.options.length > 0) {
                                                return (
                                                    <OptGroup label={finalList_unitlist.label}>
                                                        {finalList_unitlist.options && finalList_unitlist.options.map((subUnitData) => (
                                                            <Option value={subUnitData.u_id}>{subUnitData.u_name}</Option>

                                                        ))}
                                                    </OptGroup>
                                                )
                                            }
                                            else {
                                                return null
                                            }
                                        }

                                        )}



                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={'popupbtncolour text-center ' + localStorage.getItem('currentTheme')}>
                        <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ marginLeft: '15%', marginTop: '10%' }}
                            onClick={filterChange} icon={<ArrowRightOutlined />}
                            size='default' >
                            Go
                        </Button>
                    </div>
                    {/* <Button type="primary" className='addbutton' style={{ background: "#0096FF", marginLeft: '25%', marginTop: '10%' }}
                        onClick={() => {
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request":
                                        ["GetTskDistributionStatusChart",
                                            {
                                                "c_ids": [
                                                    commonData && commonData.countries && commonData.countries.length > 0 && commonData.countries[0].c_id
                                                ],
                                                "d_ids": [
                                                    commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id
                                                ],
                                                "filter_type": "Group",
                                                "filter_ids": [
                                                    1
                                                ],
                                                "le_ids": localStorage.getItem('SelectedEntity') == "All Legal Entity" ? entityData : [
                                                    commonData && commonData.le_did_infos && commonData.le_did_infos.length > 0 && commonData.le_did_infos[0].le_id
                                                ],
                                                "country_domains": [
                                                    {
                                                        "d_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].d_id,
                                                        "c_id": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].c_id,
                                                        "month_from": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_from,
                                                        "month_to": commonData && commonData.d_info && commonData.d_info.length > 0 && commonData.d_info[0].month_to
                                                    }
                                                ],
                                                "chart_year": year
                                            }
                                        ]
                                }
                            ]
                            getCriticalitychart({
                                payload: payload,
                                paramid: paramid
                            })
                            setOpen(false)

                            setFieldType("")
                            setFilterData({
                                ...filterData,
                                country: '',
                                domain: '',
                                legalentity: '',
                                division: '',
                                unit: '',
                                business: '',
                                category: '',
                                unit: ''
                            })
                        }}
                        size='default' >
                        Reset
                    </Button> */}
                </Drawer>
            </div>
        </div>

    )


}

export default connect(mapStateToProps, {
    getCommonData,
    getTaskDistributionchart,
    getTaskDistributionTable,
    getTimeZoneData,
    getTaskDistributionShowMoreTableData
})(TaskDistribution);

