import React, { useEffect, useState, useRef, Fragment, useCallback, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Tooltip, DatePicker, Tabs, Tree, Space, Button, Checkbox, Drawer, Upload, Select, Radio, Col, Row } from 'antd';
import { PlayCircleOutlined, DownloadOutlined, DoubleRightOutlined, DoubleLeftOutlined, DeleteOutlined, PlusOutlined, CloseOutlined, FullscreenOutlined, FilterOutlined, UpOutlined, InboxOutlined, EyeOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import EncryptDecrypt from '../../Libs/Encrypt&Decrypt'
import { GetGroupListData, FetchEmails, emailsave, verifypassword, updateblockoption } from '../../Store/Action/Master/CV_CertificateMailMapping';
import { constant, filter, indexOf, merge } from 'lodash';
import { InfoCircleOutlined, PlusCircleOutlined, SearchOutlined, FilterFilled, ExclamationCircleTwoTone, PlusSquareTwoTone } from '@ant-design/icons';
import { getFilterDataValues, getShowdata, getTabledata, EasyUploadFile, saveuploadStatus } from '../../Store/Action/Transcations/EXE_EasyUpload';
import { GetRemarksData } from '../../Store/Action/Transcations/Taskrecall'
import { Toaster } from '../../Libs/Toaster';
import { toast } from 'react-toastify';
// import Calendar from 'react-awesome-calendar';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment/moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { RESET_REMARK_HISTORY } from '../../Store/types';
import ScrollButton from '../Scrollbar';
import ScrollToTop from "react-scroll-to-top";
import { HomeRedirect } from './../../Libs/country'
const homepage = HomeRedirect()

const _ = require("lodash");

const mapStateToProps = (state) => ({
    filterrecall: state.recallfilters.recallfilters,
    complianceTaskDetail: state.EXE_Easyupload.complianceTaskDetails

})


const CV_Easyupload = ({
    Downloadfile,
    removedocument,
    getCalenderView,
    updateStatus,
    saveStatus,
    getFilterDataValues,
    getCurrentTaskDetails,
    getUpcomigTaskDetails,
    GetRemarksData,
    getShowdata,
    getTabledata,
    EasyUploadFile,
    saveuploadStatus,
    filterrecall: { recallremarks },
    complianceTaskDetail: { compliancefilter, complianceshowdata, compliancetabledata },
    // onoccurrencecompliances: { compliancefilter, compliancefiltershow, complianceview }
}) => {

    const { Panel } = Collapse;
    const { Option } = Select;
    const { Search, TextArea } = Input;
    const { Dragger } = Upload;
    const [tab, setTab] = useState()
    const location = useLocation();
    const [show, setShow] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const [counts, setCount] = useState(0)
    const [FormShow, setFormShow] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [passvisible, Setpassvisible] = useState(false);
    const [date, setDate] = useState(new Date())
    const searchInput = useRef(null);
    const [status, setStatus] = useState({
        overDue: false,
        inprogress: false,
        recall: false
    })
    const [searchedColumn, setSearchedColumn] = useState('');
    const [childId, setChildId] = useState()
    const [monthPrview, setMonthPrview] = useState({
        yearvalue: moment().format('YYYY'),
        monthvalue: moment().format('MMM').toUpperCase()
    })

    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [documentNo, setDocumentNo] = useState("")
    const [tempList, setTempList] = useState([])
    const [showValueModal, setShowValueModal] = useState(false)
    const [particularevent, setParticularevent] = useState({
        data: '',
        value: false
    })
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        active_status: ''
    })
    const [payloadArray, setPayloadArray] = useState([])
    const [updateRemarks, setUpdateRemarks] = useState([])
    console.log(updateRemarks, "updateRemarks");
    const [checkBoxValue, setCheckBoxValue] = useState([])
    const [checkBoxValue1, setCheckBoxValue1] = useState([])
    console.log(checkBoxValue1, 'checkBoxValue1');
    const [completionDate, setCompletionDate] = useState("")
    const [valueModal, setValueModal] = useState({
        unit: '',
        recall: '',
        address: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: [],
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        description: "",
        completion_date: "",
        unit_id: null,
        approve_status: "",
        actiondata: "",
        start_date: "",
        domain_id: null,
        history_count: '',
        occurrence_remarks: '',
    })
    const paramid = location.pathname;
    const [checkdata, setCheckdata] = useState([])
    const { TabPane } = Tabs;
    var [count1, setCount1] = useState(1);
    const currentdate = new Date();
    const [today, setToday] = useState(moment(currentdate).format('DD-MMM-YYYY'))
    let [count, setcount] = useState(0)
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [eventData, setEventData] = useState([])
    const localizer = momentLocalizer(moment);
    const validator = useRef(new SimpleReactValidator());
    const [uid, setUId] = useState([])
    console.log(uid, "uiuiduidd");
  

    const [showdata, Setshowdata] = useState({
        domain: '',
        unit: [],
        frequency: '',
        category: '',
        act: '',
        division: '',
        // legal_entity: '',
        legal_entity: '',
        task_category: '',
        tasksub_category: '',
        domainName: '',
        unit_name: ''
    })
    console.log(showdata, "showdata123");

    const validator1 = useRef(new SimpleReactValidator());
    const validator2 = useRef(new SimpleReactValidator());
    const [fileData, setFileData] = useState([])
    const [valueForFileUpload, setValueForFileUpload] = useState({
        d_id: '',
        u_id: '',
        start_date: '',
        file_info: [],
    })
    const [fileupload, setFileUpload] = useState([])
    console.log(fileupload, "fileupload");
    const [currentMonth, setCurrentMonth] = useState(moment(new Date()).startOf('month'))
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalVisible, setModaVisible] = useState(false)
    const [taskvalue, setTaskvalue] = useState()
    const [division, setDivision] = useState([])
    const [frequency, setfrequency] = useState([])
    const [category, setCategoryList] = useState([])
    const [units, setUnits] = useState([])
    const [domain, setDomain] = useState([])
    const [legalentity, setLegalentity] = useState()
    const [taskcategory, setTaskcategory] = useState([])
    console.log(taskcategory, "taskcategory");
    const [tasksubcategory, setTasksubcategory] = useState([])
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [titledata, Settitledata] = useState([])
    const [finaldata, Setfinaldata] = useState([])
    const [monthValue, setmonthValue] = useState(monthPrview.monthvalue);
    const [complianceID, setComplianceID] = useState([]);
    console.log(complianceID, 'complianceID');
    const [downloadclick, setDownloadClick] = useState(false);

    const [modalData, setModalData] = useState({
        password: '',
    })

    const [tempvalue, setTempvalue] = useState([])

    const [remarks, SetRemarks] = useState({
        remarks: "",
    });
    console.log(remarks, "remarksremarks");
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [currentDetails, setCurrentDetails] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const dispatch = useDispatch()
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);

    useEffect(() => {

        if (domain && domain.length > 0) {
            Setshowdata({
                ...showdata,
                domain: domain && domain.length > 0 && domain[0].d_id

            })
        }

    }, [domain])


    console.log(monthPrview.yearvalue,'monthPrview.yearvalue');
    console.log(filteredArrayState,'filterArr');


    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a onClick={() => {
                console.log(record, "recordrecord");
                // setModalData({
                //     ...modalData,
                //     password: text
                // })
                // setComplianceData[{
                //     ...compliancedata
                // }]
                // SetRemarks({
                //     ...remarks,
                //     remarks: record.r_description,
                // });
                updateRemarks[childId] = record.r_description
                setUpdateRemarks([...updateRemarks])
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    useEffect(() => {
        if (recallremarks && recallremarks.length > 0) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
            if (temp && temp.length == 0) {
                Toaster.error("No Remarks Available")
            }
            else {
                setRemarksModal(true);
                dispatch({
                    type: RESET_REMARK_HISTORY
                })
            }
        }

    }, [recallremarks])



    const Cancelmodal = () => {
        // setAddFormSubmit1(false)
        setShowValueModal(false);
        // setValueModal({
        //     ...valueModal,
        //     action: '',
        //     remarks: '',
        //     validity_date: '',
        //     next_due_date: ''
        // })
        // setEdit(false)

    }

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') == null || localStorage.getItem('SelectedEntityid') == 'null') {

        } else {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
            if (localStorage.getItem('SelectedEntityid')) {
                setLegalentity(localStorage.getItem('SelectedEntityid'))
            }
        }
    }, [localStorage.getItem('SelectedEntityid'), compliancefilter, entityid])


    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != "All Legal Entity") {
            setShow(true)
        }
    }, [])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != 'null' || localStorage.getItem('SelectedEntityid') == '') {
            console.log('checkinnnnn')
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetEasyUploadFilters",
                        {
                            "le_id": localStorage.getItem('SelectedEntityid')
                        }
                    ]
                }
            ]
            getFilterDataValues({
                payload: payload,
                paramid: paramid
            })

        }
    }, [localStorage.getItem('SelectedEntityid') != null])






    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()

    }, [])
    const [fullscreen, setfullscreen] = useState(false);
    const fullscreenMode = () => {
        let topBar = document.getElementById('top-nav')
        let leftbar = document.getElementsByClassName('left-sidebar');
        let leftnone = leftbar[0];
        let pageWrapper = document.getElementById('page-wrapper')
        if (fullscreen == false) {
            document.documentElement.requestFullscreen();
            topBar.style.display = 'none'
            leftnone.style.display = 'none'
            pageWrapper.style.padding = '0'
            pageWrapper.style.marginLeft = '0'
            setfullscreen(true);
        } else {
            document.exitFullscreen();
            topBar.style.display = 'block'
            leftnone.style.display = 'block'
            pageWrapper.style.marginLeft = '65px'
            pageWrapper.style.paddingTop = '70px'
            setfullscreen(false);
        }
    }

    const onsubmitData = () => {
        setFormShow(true)
        if (formValidator.current.allValid()) {
            // if ((complianceshowdata && complianceshowdata.max_year) < monthPrview.yearvalue) {

            setExitCollapse(true);
            setFilterModalVisible(false);
            setFilterTaskAcc(false);
            setShow(true)

            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetEasyUploadRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : 0,
                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "completion_date": `${monthPrview.monthvalue}-${monthPrview.yearvalue}`


                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getTabledata({
                    payload: payload1,
                    paramid: paramid
                })
            }
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetYearWiseRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : 0,

                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "year": monthPrview.yearvalue ? monthPrview.yearvalue : "2023"
                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getShowdata({
                    payload: payload,
                    paramid: paramid
                })
            }
            // }
        }
    }

    const Monthwisechange = (monthType, e, monthVal) => {
        setmonthValue(monthType)
        let monthBtn = document.getElementsByClassName('month-btn');
        for (let i = 1; i <= monthBtn.length; i++) {
            (monthVal == i) ? monthBtn[i - 1].classList.add('active') : monthBtn[i - 1].classList.remove('active')

        }


        e.preventDefault();
        // if ((complianceshowdata && complianceshowdata.max_year) < monthPrview.yearvalue) {
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetEasyUploadRecords",
                    {
                        "legal_entity_id": showdata.legal_entity,
                        "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                        "division_id": showdata.division ? Number(showdata.division) : 0,
                        "category_id": showdata.category ? Number(showdata.category) : 0,
                        "unit_ids": showdata.unit ? showdata.unit : 0,




                        "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                        "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                        "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                        "completion_date": `${monthType}-${monthPrview.yearvalue}`

                    }
                ]
            }
        ]
        if (showdata.legal_entity != '') {
            getTabledata({
                payload: payload1,
                paramid: paramid
            })
        }
        const payload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetYearWiseRecords",
                    {
                        "legal_entity_id": showdata.legal_entity,
                        "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                        "division_id": showdata.division ? Number(showdata.division) : 0,
                        "category_id": showdata.category ? Number(showdata.category) : 0,
                        "unit_ids": showdata.unit ? showdata.unit : 0,




                        "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                        "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                        "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                        "year": `${monthPrview.yearvalue}`

                    }
                ]
            }
        ]
        if (showdata.legal_entity != '') {
            getShowdata({
                payload: payload,
                paramid: paramid
            })
            // }
        }
    }

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetEasyUploadFilters",
                        {
                            "le_id": showdata.legal_entity
                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getFilterDataValues({
                    payload: payload,
                    paramid: paramid
                })
            }
        }
    }, [isAuth, showdata.legal_entity])

    useEffect(() => {
        if (compliancefilter) {
            setDomain(compliancefilter && compliancefilter.domains_list)
            setfrequency(compliancefilter && compliancefilter.frequency_list)
            setCategoryList(compliancefilter && compliancefilter.categories_list)
            setDivision(compliancefilter && compliancefilter.divisions_list)
            setUnits(compliancefilter && compliancefilter.user_units)
            let listtasksub = _.filter(compliancefilter.task_sub_category_list, { task_category_id: Number(showdata.task_category) });
            setTasksubcategory(listtasksub)
            let uniq_task = _.uniqBy(compliancefilter && compliancefilter.task_category_list, function (e) {
                return e.task_category_id;
            });
            setTaskcategory(uniq_task)

        }
    }, [compliancefilter, showdata.task_category])
    
    console.log(units,'unitsss');

    useEffect(() => {
        if (showdata.unit) {
            let newUnit = []
            for (let k in showdata.unit) {
                let filteredUnit = _.find(compliancefilter && compliancefilter.user_units, { unit_id: Number(showdata.unit[k]) })
                // temp2.push(filterDomain)
                newUnit.push(filteredUnit.unit_name)

            }
            Setshowdata({
                ...showdata,
                unit_name:newUnit
            })
            console.log(newUnit,'newUnit');
        }

    }, [showdata.unit,compliancefilter.user_units])

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
        validator2.current.showMessages()
    }, [])

    const handleCancel = () => {
        setRemarksModal(false)
    };

    const passwordcancel = () => {
        setModaVisible(false)
        Setpassvisible(false);
        setModalData({
            ...modalData,
            password: ""
        })
    }


    const showRemarksModal = (recordChildId) => {
        // console.log(item,'item');
        setChildId(recordChildId)
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
    };

    const onInputChange = e => {
        const { value } = e.target;
        const re = /^[A-Z0-9a-z _ .,-]+$/;
        if (value === "" || re.test(value)) {
            SetRemarks(value)
        }
    }

    useEffect(() => {


    }, [showdata.unit])

    const onInputChange1 = e => {
        const { value } = e.target;
        const re = /^[A-Z0-9a-z- _.,/)(]+$/;
        if (value === "" || re.test(value)) {
            setDocumentNo(value)
        }
    }

    const homescreen = () => {
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }


    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        // setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const onMonthPrvChange = () => {
        if ((complianceshowdata && complianceshowdata.max_year - 7) < monthPrview.yearvalue) {

            setMonthPrview({
                ...monthPrview,
                yearvalue: monthPrview.yearvalue - 1
            })

            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetEasyUploadRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : 0,




                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "completion_date": `${monthPrview.monthvalue}-${monthPrview.yearvalue - 1}`

                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getTabledata({
                    payload: payload1,
                    paramid: paramid
                })
            }
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetYearWiseRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : 0,




                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "year": `${monthPrview.yearvalue - 1}`


                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getShowdata({
                    payload: payload,
                    paramid: paramid
                })
            }

        }
    }

    const onMonthNxtChange = () => {
        if ((complianceshowdata && complianceshowdata.max_year) > monthPrview.yearvalue) {

            setMonthPrview({
                ...monthPrview,
                yearvalue: monthPrview.yearvalue + 1
            })

            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetEasyUploadRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,

                            "unit_ids": showdata.unit ? showdata.unit : 0,



                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "completion_date": `${monthPrview.monthvalue}-${monthPrview.yearvalue - 1}`


                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getTabledata({
                    payload: payload1,
                    paramid: paramid
                })
            }
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetYearWiseRecords",
                        {
                            "legal_entity_id": showdata.legal_entity,
                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                            "division_id": showdata.division ? Number(showdata.division) : 0,
                            "category_id": showdata.category ? Number(showdata.category) : 0,
                            "unit_ids": showdata.unit ? showdata.unit : 0,

                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                            "year": `${monthPrview.yearvalue + 1}`

                        }
                    ]
                }
            ]
            if (showdata.legal_entity != '') {
                getShowdata({
                    payload: payload,
                    paramid: paramid
                })
            }
        }

    }


    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content,
        };

        return result;
    }

    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }

    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };

    // const SavepastRecordOnChange = (e) => {
    //     // if (e.target.name === 'file') {
    //     var results = [];
    //     const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
    //     let files = e.fileList
    //     let file_max_size = 26214400
    //     for (var i = 0; i < files.length; i++) {
    //         var file = files[i];
    //         var file_name = file.name;
    //         // const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
    //         // if (fileRegex.test(f_name) === false) {
    //         //     return;
    //         // }
    //         let file_size = file[i].size;
    //         // if (file_size > file_max_size) {
    //         //     toast.error("Maximum filesize limit exceed")
    //         //     return;
    //         // }
    //         if (file) {
    //             convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
    //                 var fN = name.substring(0, name.lastIndexOf('.'));
    //                 var fE = name.substring(name.lastIndexOf('.') + 1);
    //                 var uniqueId = Math.floor(Math.random() * 90000) + 10000;
    //                 var f_Name = fN + '-' + uniqueId + '.' + fE;
    //                 var type =""

    //                 var result = uploadFileFormat(file_size, f_Name, file_content,type);
    //                 setFileData(result)
    //                 results.push(result);


    //                 let temp1 = fileupload
    //                 let temp = []
    //                 if (results && results.length == 1) {
    //                     for (let i in results) {
    //                         temp1.push(results[i])
    //                     }
    //                     setFileUpload([...temp1])
    //                 }
    //                 else {
    //                     for (let i in results) {
    //                         temp.push(results[i])
    //                     }
    //                     setFileUpload([...temp])
    //                 }


    //                 const filePayload = [
    //                     authtoken,
    //                     {
    //                         "session_token": authtoken,
    //                         "request": [
    //                             "UploadComplianceTask_temp_upload",
    //                             {
    //                                 "le_id": showdata.legal_entity,
    //                                 "c_id": showdata.country ? Number(showdata.country) : 0,
    //                                 "d_id": showdata.domain ? Number(showdata.domain) : 0,
    //                                 "u_id": showdata.unit ? Number(showdata.unit) : 0,
    //                                 "start_date": "11-Jan-2023",
    //                                 "file_info": results,
    //                                 "compliance_history_id": 4
    //                             }

    //                         ]
    //                     }
    //                 ]
    //                 EasyUploadFile({
    //                     payload: filePayload,
    //                     paramid: paramid
    //                 })

    //             });
    //         }
    //     }
    //     // }
    //     React.forwardRef(({ onClick, ...rest }, ref) => {
    //         return (
    //             <>
    //                 <div className="form-check pb-5" style={{ backgroundColor: '' }}>
    //                     <input
    //                         type="checkbox"
    //                         className="form-check-input"
    //                         style={{ height: '20px', width: '20px' }}
    //                         ref={ref}
    //                         onClick={onClick}
    //                         {...rest}
    //                     />
    //                     <label className="form-check-label" id="booty-check" />
    //                 </div>
    //             </>
    //         )
    //     })
    // }
    const SavepastRecordOnChange = (e) => {
        if (e.target.name === 'file') {
            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            let files = e.target.files
            let file_max_size = 26214400
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                if (fileRegex.test(f_name) === false) {
                    return;
                }
                let file_size = file.size;
                if (file_size > file_max_size) {
                    toast.error("Maximum filesize limit exceed")
                    return;
                }
                if (file) {
                    convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                        var fN = name.substring(0, name.lastIndexOf('.'));
                        var fE = name.substring(name.lastIndexOf('.') + 1);
                        var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                        var f_Name = fN + '-' + uniqueId + '.' + fE;
                        var type = ""
                        var result = uploadFileFormat(size, f_Name, file_content, type);
                        setFileData(result)
                        results.push(result);


                        let temp1 = fileupload
                        let temp = []
                        if (results && results.length == 1) {
                            for (let i in results) {
                                temp1.push(results[i])
                            }
                            setFileUpload([...temp1])
                        }
                        else {
                            for (let i in results) {
                                temp.push(results[i])
                            }
                            setFileUpload([...temp])
                        }
                        const filePayload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "UploadComplianceTask_temp_upload",
                                    {
                                        "le_id": showdata.legal_entity,
                                        "c_id": showdata.country ? Number(showdata.country) : 0,
                                        "d_id": showdata.domain ? Number(showdata.domain) : 0,
                                        "u_id": showdata.unit ? Number(showdata.unit) : 0,
                                        "start_date": "11-Jan-2023",
                                        "file_info": results,
                                        "compliance_history_id": 4
                                    }

                                ]
                            }
                        ]
                        EasyUploadFile({
                            payload: filePayload,
                            paramid: paramid
                        })
                    });
                }
            }
        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            )
        })
    }
    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }
    const download = (item) => {
        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFile_temp",
                        {
                            "le_id": showdata.legal_entity,
                            "c_id": 1,
                            "d_id": valueForFileUpload.d_id,
                            "u_id": valueForFileUpload.u_id,
                            "start_date": valueForFileUpload.start_date,
                            "compliance_history_id": valueModal.compliance_history_id,
                            "upload_type": 2,
                            "file_name": item,
                        }
                    ]
                }
            ]
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });
        // if (item) {
        //     window.open('https:/' + item);
        // }
    }
    const Remove = (index) => {

        let temp = fileupload
        for (let i in fileupload) {
            const removepayload =
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "RemoveFile_temp",
                            {
                                "le_id": showdata.legal_entity,
                                "c_id": 1,
                                "selected_task_info": [
                                    {
                                        "d_id": valueForFileUpload.d_id,
                                        "u_id": valueForFileUpload.u_id,
                                        "start_date": valueForFileUpload.start_date,
                                        "compliance_history_id": valueModal.compliance_history_id,
                                        "file_info": [
                                            {
                                                "file_size": fileupload.file_size,
                                                "file_name": fileupload.file_name,
                                                "file_content": null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            removedocument({
                payload: removepayload,
                paramid: paramid
            })


            if (fileupload[i]['file_name'] == index) {
                fileupload.splice(i, 1)
            }

        }
        setFileUpload([...fileupload])

    }


    useEffect(() => {
        if (compliancetabledata && compliancetabledata.comp_list && compliancetabledata.comp_list.length > 0) {
            let uniqueObjArray = [...new Map(compliancetabledata.comp_list && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.map((item) => [item["unit_name"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_name
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(compliancetabledata && compliancetabledata.comp_list && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list, { unit_name: uniqueIds[i] })
                tempArr.push(filteredArr)
            }
            setFilteredArray([...filteredArray, tempArr])
        }
    }, [compliancetabledata.comp_list])

    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {
            filteredArray && filteredArray.map((item, i) => {
                return (
                    setfilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])

    console.log(compliancetabledata, 'compliancetabledata');

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (compliancetabledata && compliancetabledata.comp_list) {
            const ab = compliancetabledata && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.map(function (item) {
                if ((array.indexOf(item.unit_name) < 0))
                    if (item.unit_name != '') {
                        array.push(item.unit_name)
                    }
            })
            array.map((data, i) => {
                let temp = [];
                let entityArray = [];
                let datavalue = [];
                if (data) {
                    compliancetabledata && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.map((child, childIndex) => {
                        if (data == child.unit_name) {
                            entityArray = {
                                "act_name": child.act_name,
                                "approval_documents": child.approval_documents,
                                "approve_status": child.approve_status,
                                "compliance_history_id": child.compliance_history_id,
                                "compliance_task": child.compliance_task,
                                "concurrence_status": child.concurrence_status,
                                "concurrer_documents": child.concurrer_documents,
                                "documents": child.documents,
                                "domain_id": child.domain_id,
                                "due_date": child.due_date,
                                "extn_due_date": child.extn_due_date,
                                "recall_status": child.recall_status,
                                "remarks": updateRemarks[childIndex],
                                "start_date": child.start_date,
                                "task_group_name": child.task_group_name,
                                "task_status": child.task_status,
                                "task_sub_group_name": child.task_sub_group_name,
                                "tz_name": child.tz_name,
                                "unit_id": child.unit_id,
                                "unit_name": child.unit_name,
                                'childId': childIndex,
                                'parentId': i,
                                'is_not_applicable': checkBoxValue[childIndex]
                            }
                            temp.push(entityArray)
                            updateRemarks[childIndex] = child.remarks
                            checkBoxValue[childIndex] = child.is_not_applicable
                            checkBoxValue1[childIndex] = 0
                        }
                    })
                }
                datavalue = {
                    index: i,
                    parent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Setfinaldata(finalarray)
            setUpdateRemarks([...updateRemarks])
            setCheckBoxValue([...checkBoxValue])
            setCheckBoxValue1([...checkBoxValue1])
        }
    }, [compliancetabledata.comp_list])

    console.log(checkBoxValue, 'checkBoxValue');

    const downloadselectAllCheckBox = (checked) => {
        var ele = document.getElementsByName("checked");
        let tempAllIds = [];
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                }
            }
            let ids =
                compliancetabledata &&
                compliancetabledata.comp_list &&
                compliancetabledata.comp_list.length > 0 &&
                compliancetabledata.comp_list.map((item) => {
                    return item.compliance_history_id;
                });
            setComplianceID(ids);

            compliancetabledata && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.map((child, childIndex) => {

                checkBoxValue1[childIndex] = 1

            })

            setCheckBoxValue1([...checkBoxValue1])




        } else {
            var ele = document.getElementsByName("checked");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") ele[i].checked = false;
            }
            compliancetabledata && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.map((child, childIndex) => {

                checkBoxValue1[childIndex] = 0

            })

            setCheckBoxValue1([...checkBoxValue1])

            setComplianceID([]);
        }
    };
    const downloadcheckBoxOnChange = (e, item) => {
        if (e == true) {
            checkBoxValue1[item.childId] = 1
            setCheckBoxValue1([...checkBoxValue1])
        }
        else {
            checkBoxValue1[item.childId] = 0
            setCheckBoxValue1([...checkBoxValue1])
            updateRemarks[item.childId] = ''
            setUpdateRemarks([...updateRemarks])
        }

        let tempArr = [];
        tempArr.push({
            "compliance_history_id": item.compliance_history_id,
            "remarks": updateRemarks[item.childId],
            "documents": [],
            "uploaded_documents": null,
            "decreased_size": 0,
            "removed_documents": [],
            "is_not_applicable": checkBoxValue[item.childId]
        })
        setPayloadArray([...payloadArray, ...tempArr])
        let temp = complianceID;
        let tempIds = [];
        if (temp.includes(Number(item.compliance_history_id)) == true) {
            for (let i in temp) {
                if (temp[i] == Number(item.compliance_history_id)) {
                    temp.splice(i, 1);
                }
            }
            setComplianceID([...temp]);
        } else {
            tempIds.push(Number(item.compliance_history_id));
            setComplianceID([...complianceID, ...tempIds]);
        }
    };

    // Final Submit
    const finalSubmit = () => {

        setDownloadClick(true)

        if (checkBoxValue && checkBoxValue[0] > 0) {
            if (updateRemarks && updateRemarks.length > 0) {
                Swal.fire({
                    title: 'Are You Sure?',
                    text: 'This occurrence of task has been marked as Not applicable. Once approved, it will be shown in Task recall proces',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (formValidator1.current.allValid()) {

                            const pay = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "SaveEasyUploadTask",
                                        {
                                            "selected_compliance": payloadArray,
                                            "legal_entity_id": showdata.legal_entity
                                        }
                                    ]
                                }
                            ]
                            saveuploadStatus({
                                payload: pay,
                                paramid: paramid
                            })
                            const payload1 = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetEasyUploadRecords",
                                        {
                                            "legal_entity_id": showdata.legal_entity,
                                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                                            "division_id": showdata.division ? Number(showdata.division) : 0,
                                            "category_id": showdata.category ? Number(showdata.category) : 0,
                                            "unit_ids": showdata.unit ? showdata.unit : 0,
                                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                                            "completion_date": `${monthPrview.monthvalue}-${monthPrview.yearvalue - 1}`

                                        }
                                    ]
                                }
                            ]
                            if (showdata.legal_entity != '') {
                                getTabledata({
                                    payload: payload1,
                                    paramid: paramid
                                })
                            }
                            const payload = [
                                authtoken,
                                {
                                    "session_token": authtoken,
                                    "request": [
                                        "GetYearWiseRecords",
                                        {
                                            "legal_entity_id": showdata.legal_entity,
                                            "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                                            "division_id": showdata.division ? Number(showdata.division) : 0,
                                            "category_id": showdata.category ? Number(showdata.category) : 0,
                                            "unit_ids": showdata.unit ? showdata.unit : 0,




                                            "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                                            "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                                            "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                                            "year": `${monthPrview.yearvalue}`

                                        }
                                    ]
                                }
                            ]
                            if (showdata.legal_entity != '') {
                                getShowdata({
                                    payload: payload,
                                    paramid: paramid
                                })
                            }

                        }
                    }
                });
            }
        }
        else {
            setAddFormSubmit1(true)
            if (updateRemarks && updateRemarks.length > 0) {
                if (formValidator1.current.allValid()) {
                    const pay = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveEasyUploadTask",
                                {
                                    "selected_compliance": payloadArray,
                                    "legal_entity_id": showdata.legal_entity
                                }
                            ]
                        }
                    ]
                    saveuploadStatus({
                        payload: pay,
                        paramid: paramid
                    })
                    const payload1 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetEasyUploadRecords",
                                {
                                    "legal_entity_id": showdata.legal_entity,
                                    "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                                    "division_id": showdata.division ? Number(showdata.division) : 0,
                                    "category_id": showdata.category ? Number(showdata.category) : 0,
                                    "unit_ids": showdata.unit ? showdata.unit : 0,




                                    "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                                    "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                                    "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                                    "completion_date": `${monthPrview.monthvalue}-${monthPrview.yearvalue - 1}`

                                }
                            ]
                        }
                    ]
                    if (showdata.legal_entity != '') {
                        getTabledata({
                            payload: payload1,
                            paramid: paramid
                        })
                    }
                    const payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetYearWiseRecords",
                                {
                                    "legal_entity_id": showdata.legal_entity,
                                    "domain_id": showdata.domain ? Number(showdata.domain) : 0,
                                    "division_id": showdata.division ? Number(showdata.division) : 0,
                                    "category_id": showdata.category ? Number(showdata.category) : 0,
                                    "unit_ids": showdata.unit ? showdata.unit : 0,

                                    "frequency_id": showdata.frequency ? Number(showdata.frequency) : 0,
                                    "task_category_id": showdata.task_category ? Number(showdata.task_category) : 0,
                                    "task_sub_category_id": showdata.tasksub_category ? Number(showdata.tasksub_category) : 0,
                                    "year": `${monthPrview.yearvalue}`

                                }
                            ]
                        }
                    ]
                    if (showdata.legal_entity != '') {
                        getShowdata({
                            payload: payload,
                            paramid: paramid
                        })
                    }

                }
            }
        }
    }



    const selectAllCheckbox = (checked) => {
        var ele = document.getElementsByName("chk");
        let tempAllIds = [];
        if (checked == true) {
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") {
                    ele[i].checked = true;
                    checkBoxValue[i] = 1
                }
            }
            setCheckBoxValue([...checkBoxValue])
        } else {
            var ele = document.getElementsByName("chk");
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == "checkbox") ele[i].checked = false;
                checkBoxValue[i] = 0
            }
            setCheckBoxValue([...checkBoxValue])
        }
    };






    return (
        <div className="page-wrapper" id='page-wrapper'>
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles pb-2 pt-2">
                <div className="row d-flex">
                    <div className="col-lg-6 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Easy Upload</span>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-6 text-end">
                        {checkBoxValue1.includes(1) == true ? (
                            <Button
                                type="primary"
                                shape="round"
                                className="addbutton mx-1"
                                icon={<PlayCircleOutlined />}
                                onClick={finalSubmit}
                            >
                                Submit Selected
                            </Button>
                        ) : (
                            false
                        )}
                        <Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button>

                        <button
                            onClick={(e) => {
                                fullscreenMode();
                            }}
                            className="bg-transparent text-black ms-1 full-mode fullscreenradius"
                        >
                            <Tooltip
                                placement="left"
                                title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}
                            >
                                <FullscreenOutlined />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div style={{ borderRadius: '0px' }} className={localStorage.getItem('currentTheme')}>
                            <Collapse accordion defaultActiveKey={['1']}
                                style={{ display: exitCollapse ? "none" : "block" }}>
                                <Panel header="Easy Upload" key="1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4" >
                                                <label htmlFor=""><b>Legal Entity: </b></label><span style={{ color: "red" }}> *</span><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Legal Entity"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        legal_entity: data ? data : '',

                                                                    })
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        legal_entity: '',
                                                                        domain: '',
                                                                        division: '',
                                                                        category: '',
                                                                        unit: '',
                                                                        frequency: ''

                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.legal_entity || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'legal_entity',
                                                            showdata.legal_entity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Domain: </b></label><br />
                                                    {domain && domain.length > 0 ?
                                                        <p style={{ marginTop: '10px' }} value={domain && domain.length > 0 && domain[0].d_name}>{domain && domain.length > 0 && domain[0].d_name}</p>
                                                        :
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            name="domain"
                                                            id="domain"
                                                            placeholder="Enter domain"
                                                            onChange={(data, value) => {
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        domain: data,
                                                                        domainName: value.children
                                                                    })
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        domain: '',
                                                                        domainName: ''

                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showdata.domainName || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            {domain && domain.length > 0 && domain.map((item, i) => {
                                                                return (
                                                                    <Option key={item.d_id}>
                                                                        {item.d_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    }

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Division: </b></label><br />
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Division"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    division: data
                                                                })
                                                                let filCat = _.filter(compliancefilter && compliancefilter.categories_list, { div_id: Number(data) })
                                                                setCategoryList(filCat)
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    division: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.division || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {division && division.length > 0 && division.map((item, i) => {
                                                            return (
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Category: </b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Category"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    category: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    category: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.category || undefined}

                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {category && category.length > 0 && category.map((item, i) => {
                                                            return (
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">

                                                <div className="form-group">

                                                    <label htmlFor=""><b>Vendor: </b></label>
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}

                                                        mode="multiple"
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Vendors"

                                                        maxTagCount='responsive'
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9]+$/gi)
                                                        }}
                                                        onChange={(value, data) => {
                                                            console.log(data.children, "datachildren");
                                                            console.log(data, "datadata");
                                                            console.log(value, "valuevalue");


                                                            let temp = []
                                                            let temp1 = []
                                                            for (let i in value) {
                                                                temp1.push(`${Number(value[i])}`)
                                                                temp.push(Number(value[i]))
                                                            }
                                                            console.log(temp, "temptemptemp");
                                                            let temp_name = []
                                                            for (let i in data.children) {
                                                                temp_name.push(data.children[i])
                                                            }
                                                            console.log(temp_name, "temp_nametemp_name");

                                                            Setshowdata({
                                                                ...showdata,
                                                                unit: temp,
                                                                unit_name: temp1
                                                            })


                                                            setFormShow(false)

                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.unit_name ? showdata.unit_name : ''}


                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {units && units.length > 0 && units.map((item, i) => {
                                                            <Option defaultValue >None Selected</Option>
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Frequency: </b></label>
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        defaultValue={"All"}
                                                        placeholder="Enter Frequency"
                                                        onChange={(data) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: data
                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    frequency: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        value={showdata.frequency || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        <Option key=''>All</Option>
                                                        {frequency && frequency.length > 0 && frequency.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>Task Category: </b></label>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                defaultValue={"All"}
                                                                placeholder="Enter Task Category"
                                                                onChange={(data) => {
                                                                    if (data !== undefined) {
                                                                        Setshowdata({
                                                                            ...showdata,
                                                                            task_category: data
                                                                        })
                                                                    }
                                                                    else {
                                                                        Setshowdata({
                                                                            ...showdata,
                                                                            task_category: ''
                                                                        })
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                value={showdata.task_category || undefined}
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                            >
                                                                <Option key=''>All</Option>
                                                                {taskcategory && taskcategory.length > 0 && taskcategory.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.task_category_id}>
                                                                            {item.task_category_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>Task Sub Category: </b></label>
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                defaultValue={"All"}
                                                                placeholder="Enter Task Sub Category"
                                                                onChange={(data) => {
                                                                    if (data !== undefined) {
                                                                        Setshowdata({
                                                                            ...showdata,
                                                                            tasksub_category: data
                                                                        })

                                                                    }
                                                                    else {
                                                                        Setshowdata({
                                                                            ...showdata,
                                                                            tasksub_category: ''
                                                                        })
                                                                    }
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                value={showdata.tasksub_category || undefined}
                                                                style={{ width: '100%', marginTop: "5px" }}
                                                            >
                                                                <Option key=''>All</Option>
                                                                {tasksubcategory && tasksubcategory.length > 0 && tasksubcategory.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.task_sub_category_id}>
                                                                            {item.task_sub_category_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-md-12 mt-2 popupbtncolour mb-2 " + localStorage.getItem('currentTheme')}>

                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '46%', marginTop: "5px" }}
                                            size='default'
                                            onClick={onsubmitData} icon={<EyeOutlined />}
                                        >Show
                                        </Button>
                                    </div>
                                </Panel>
                            </Collapse>
                            <Modal
                                onCancel={setcancelFilter}
                                footer={false}
                                title="Easy Upload"
                                className={
                                    "add-service-prv remark-header cons-report " +
                                    localStorage.getItem("currentTheme")
                                }
                                visible={filterModalVisible}
                            >
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4" >
                                            <label htmlFor=""><b>Legal Entity: </b></label><span style={{ color: "red" }}> *</span><br />
                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                <div className="form-group">
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}

                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Select Legal Entity"
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {

                                                                Setshowdata({
                                                                    ...showdata,
                                                                    legal_entity: data,

                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    legal_entity: '',
                                                                    domain: '',
                                                                    division: '',
                                                                    category: '',
                                                                    unit: '',
                                                                    frequency: ''

                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.legal_entity || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                            return (
                                                                <Option key={item.le_id}>
                                                                    {item.le_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {formValidator.current.message(
                                                        'legal_entity',
                                                        showdata.legal_entity,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Legal Entity Required',
                                                            }
                                                        })}
                                                </div> :
                                                <p style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Domain: </b></label><br />
                                                {domain && domain.length > 0 ?
                                                    <p style={{ marginTop: '10px' }} value={domain && domain.length > 0 && domain[0].d_name}>{domain && domain.length > 0 && domain[0].d_name}</p>

                                                    :
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        allowClear={true}
                                                        size="default"
                                                        name="domain"
                                                        id="domain"
                                                        placeholder="Enter domain"
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: data,
                                                                    domainName: value.children

                                                                })
                                                            }
                                                            else {
                                                                Setshowdata({
                                                                    ...showdata,
                                                                    domain: '',
                                                                    domainName: ''

                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showdata.domainName || undefined}
                                                        style={{ width: '100%', marginTop: "5px" }}
                                                    >
                                                        {domain && domain.length > 0 && domain.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                }

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Division: </b></label><br />
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Division"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                division: data
                                                            })
                                                            let filCat = _.filter(compliancefilter && compliancefilter.categories_list, { div_id: Number(data) })
                                                            setCategoryList(filCat)
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                division: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.division || undefined}
                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    {division && division.length > 0 && division.map((item, i) => {
                                                        return (
                                                            <Option key={item.div_id}>
                                                                {item.div_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Category: </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Enter Category"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                category: data
                                                            })
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                category: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.category || undefined}

                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    {category && category.length > 0 && category.map((item, i) => {
                                                        return (
                                                            <Option key={item.cat_id}>
                                                                {item.cat_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">

                                            <div className="form-group">

                                                <label htmlFor=""><b>Vendor: </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}

                                                    mode="multiple"
                                                    allowClear={false}
                                                    size="default"
                                                    placeholder="Enter Vendors"

                                                    maxTagCount='responsive'
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9]+$/gi)
                                                    }}
                                                    onChange={(value, data) => {
                                                        console.log(data.children, "datachildren");
                                                        console.log(data, "datadata");
                                                        console.log(value, "valuevalue");


                                                        let temp = []
                                                        for (let i in value) {
                                                            temp.push(Number(value[i]))
                                                        }
                                                        console.log(temp, "temptemptemp");
                                                        let temp_name = []
                                                        for (let i in data.children) {
                                                            temp_name.push(data.children[i])
                                                        }
                                                        console.log(temp_name, "temp_nametemp_name");

                                                        Setshowdata({
                                                            ...showdata,
                                                            unit: temp,
                                                            // unit_name: temp_name
                                                        })


                                                        setFormShow(false)

                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showdata.unit ? showdata.unit : ''}

                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    {units && units.length > 0 && units.map((item, i) => {
                                                        <Option defaultValue >None Selected</Option>
                                                        return (
                                                            <Option key={item.unit_id}>
                                                                {item.unit_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Frequency: </b></label>
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={false}
                                                    size="default"
                                                    defaultValue={"All"}
                                                    placeholder="Enter Frequency"
                                                    onChange={(data) => {
                                                        if (data !== undefined) {
                                                            Setshowdata({
                                                                ...showdata,
                                                                frequency: data
                                                            })
                                                        }
                                                        else {
                                                            Setshowdata({
                                                                ...showdata,
                                                                frequency: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    value={showdata.frequency || undefined}
                                                    style={{ width: '100%', marginTop: "5px" }}
                                                >
                                                    <Option key=''>All</Option>
                                                    {frequency && frequency.length > 0 && frequency.map((item, i) => {
                                                        return (
                                                            <Option key={item.frequency_id}>
                                                                {item.frequency_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Task Category: </b></label>
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            // allowClear={true}
                                                            size="default"
                                                            defaultValue={"All"}
                                                            placeholder="Enter Task Category"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        task_category: data
                                                                    })
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        task_category: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            value={showdata.task_category || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            <Option key=''>All</Option>
                                                            {taskcategory && taskcategory.length > 0 && taskcategory.map((item, i) => {
                                                                return (
                                                                    <Option key={item.task_category_id}>
                                                                        {item.task_category_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Task Sub Category: </b></label>
                                                        <Select
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            // allowClear={true}
                                                            size="default"
                                                            defaultValue={"All"}
                                                            Setshowdata placeholder="Enter Task Sub Category"
                                                            onChange={(data) => {
                                                                if (data !== undefined) {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        tasksub_category: data
                                                                    })
                                                                }
                                                                else {
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        tasksub_category: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            value={showdata.tasksub_category || undefined}
                                                            style={{ width: '100%', marginTop: "5px" }}
                                                        >
                                                            <Option key=''>All</Option>
                                                            {tasksubcategory && tasksubcategory.length > 0 && tasksubcategory.map((item, i) => {
                                                                return (
                                                                    <Option key={item.task_sub_category_id}>
                                                                        {item.task_sub_category_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"col-md-12 mt-2 popupbtncolour mb-2 " + localStorage.getItem('currentTheme')}>

                                    <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{ background: "#0096FF", borderColor: "#0096FF", marginLeft: '46%', marginTop: "5px" }}
                                        size='default'
                                        onClick={onsubmitData} icon={<EyeOutlined />}
                                    >Show
                                    </Button>
                                </div>
                            </Modal>

                            {show == true ?


                                <>


                                    <div className='card px-2 py-1 p-0 mt-1 mb-2' style={{ background: '#e3e2e2' }}>
                                        <div className="row">
                                            <div className="col-md-9 ">
                                                <div className="date-filter d-flex  align-items-center">
                                                    <div className={localStorage.getItem("currentTheme") + ' ant-btn label-block h-100'} >
                                                        <h4 className='text-white mb-0 d-flex'>
                                                            <span className='rounded-circle arr-btn' onClick={onMonthPrvChange}> <i class="ri-arrow-left-s-line "></i></span>
                                                            <span className='px-2'> {monthPrview && monthPrview.yearvalue}</span>
                                                            <span className='rounded-circle arr-btn' onClick={onMonthNxtChange}><i class="ri-arrow-right-s-line "></i></span>
                                                        </h4>

                                                    </div>
                                                    <div style={{ marginLeft: '5%' }}>
                                                        <div className='ps-2'>

                                                            <label className='h5'>Due for the month of</label>

                                                            <div className="d-flex align-items-center month-wrapper" style={{ cursor: 'pointer' }}>
                                                                <a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[0]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('JAN', e, 1)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }} >Jan</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[1]} ` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('FEB', e, 2)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>Feb</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[2]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('MAR', e, 3)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>Mar</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[3]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('APR', e, 4)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>Apr</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[4]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('MAY', e, 5)
                                                                    setComplianceID([])
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>May</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[5]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('JUN', e, 6)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>Jun</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[6]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('JUL', e, 7)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>Jul</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[7]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('AUG', e, 8)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>Aug</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[8]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('SEP', e, 9)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>Sep</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[9]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('OCT', e, 10)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>Oct</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[10]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('NOV', e, 11)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>Nov</a>
                                                                < a className={`${complianceshowdata && complianceshowdata.task_status && complianceshowdata.task_status[11]}` + ' h3 month-btn'} onClick={(e) => {
                                                                    Monthwisechange('DEC', e, 12)
                                                                    setComplianceID([]);
                                                                    setCheckBoxValue1([])
                                                                    setCheckBoxValue([])
                                                                }}>Dec</a>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-md-3 text-center'>
                                                <div className="p-1  record-upload">
                                                    <span className="text-white h5 fw-bold "><b>Upload {monthValue && monthValue == 'JAN' ? "DEC" : monthValue && monthValue == 'FEB' ? "JAN" : monthValue && monthValue == 'MAR' ? 'FEB' : monthValue && monthValue == 'APR' ? 'MAR' : monthValue && monthValue == 'MAY' ? 'APR' : monthValue && monthValue == 'JUN' ? 'MAY' : monthValue && monthValue == 'JUL' ? "JUN" : monthValue && monthValue == "AUG" ? "JUL" : monthValue && monthValue == "SEP" ? "AUG" : monthValue && monthValue == "OCT" ? "SEP" : monthValue && monthValue == "NOV" ? "OCT" : monthValue && monthValue == 'DEC' ? "NOV" : ''} 
                                                    &nbsp;{monthValue && monthValue == 'JAN' ? monthPrview.yearvalue -1 : monthPrview.yearvalue } records</b></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='ant-table-wrapper'>
                                        <div className={localStorage.getItem("currentTheme") + ' ant-table-wrapper'}>
                                            <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                <div className='ant-table-container'>
                                                    <div className='ant-table-content userprivclass1'>
                                                        <table>
                                                            <colgroup>
                                                                <col style={{ width: '10px' }}></col>
                                                            </colgroup>
                                                            <thead className="ant-table-thead antable-head">
                                                                <tr>
                                                                    {console.log(checkBoxValue1,'checkBoxValue1')}
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '30px' }}> S.no
                                                                        <input
                                                                            type="checkbox"
                                                                            // checked={((complianceID && complianceID.length > 0 && complianceID.length) === (compliancetabledata && compliancetabledata.comp_list.length > 0 && compliancetabledata.comp_list.length)) ? true : false}
                                                                            // checked={((checkBoxValue1.includes(0) == true) && (filteredArrayState.length > 0))  ? false : filteredArrayState && filteredArrayState.length < 0 ? false : true}
                                                                            checked={(((filteredArrayState && filteredArrayState.length > 0 && checkBoxValue1.includes(0)) || filteredArrayState && filteredArrayState.length <= 0) ? false : true)}
                                                                            // checked={
                                                                            //     filterValue.ConsolidatedReportList
                                                                            //         .length == complianceID.length
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                            onChange={(e) => {
                                                                                downloadselectAllCheckBox(
                                                                                    e.target.checked
                                                                                );

                                                                            }}
                                                                        /> </th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '200px' }}>Act</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '230px' }}>Compliance Task</th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '100px' }}><center>Due Date</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '120px' }}><center> <input type="checkbox" name="" id=""
                                                                        checked={checkBoxValue && checkBoxValue.length <= 0 ? false : checkBoxValue.includes(0) ? false : true}
                                                                        onChange={(e) => {
                                                                            selectAllCheckbox(
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                    /> Not Applicable</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '180px' }}><center>Documents</center></th>
                                                                    <th className={fullscreen == false ? 'ant-table-cell' : 'fullscreenclass ant-table-cell'} style={{ width: '130px' }}>Remarks</th>


                                                                </tr>
                                                            </thead>
                                                            {filteredArrayState && filteredArrayState.length ? filteredArrayState.map((item, i) => {
                                                                console.log(item, 'item');
                                                                return (
                                                                    <tbody className='ant-table-tbody' key={i}>

                                                                        {finaldata && finaldata.map((itemdata, i) => {

                                                                            let data = _.find(itemdata.child, { unit_name: item[0].unit_name })
                                                                            console.log(itemdata.child && itemdata.child.length, "childLength");
                                                                            if (data != undefined) {

                                                                                if (data.unit_name == itemdata.parent) {
                                                                                    return <Fragment key={i}>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td colSpan={6} className='ant-table-cell' ><b style={{ backgroundColor: "lightyellow" }}>{itemdata.parent}</b></td>
                                                                                        </tr>

                                                                                        {itemdata.child.map((items, itemskj) => {

                                                                                            if (item[0].unit_name == items.unit_name) {
                                                                                                return <tr key={itemskj}>
                                                                                                    <td className="ant-table-cell">
                                                                                                        <center>
                                                                                                            {count + 1} <br />
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="checked"
                                                                                                                checked={checkBoxValue1[items.childId] == 1 ? true : false}
                                                                                                                onChange={(e) => { downloadcheckBoxOnChange(e.target.checked, items); }} />
                                                                                                        </center>
                                                                                                    </td>
                                                                                                    <td className='ant-table-cell'>{items.act_name}</td>
                                                                                                    <td className='ant-table-cell'>{items.compliance_task}</td>
                                                                                                    <td className='ant-table-cell'><center>{items.due_date}</center></td>
                                                                                                    <td><center><input type="checkbox" name="chk"
                                                                                                        // value={checkValue} 
                                                                                                        // defaultValue={checkBoxValue[items.childId]}
                                                                                                        // key={checkBoxValue[items.childId]}
                                                                                                        value={checkBoxValue[items.childId]}

                                                                                                        id=""
                                                                                                        onChange={(e) => {
                                                                                                            checkBoxValue[items.childId] = e.target.checked === true ? 1 : 0
                                                                                                            setCheckBoxValue([...checkBoxValue])
                                                                                                            checkBoxValue1[items.childId] = e.target.checked === true ? 1 : 0
                                                                                                            setCheckBoxValue1([...checkBoxValue1])
                                                                                                            downloadcheckBoxOnChange(true, items)
                                                                                                        }}

                                                                                                    /></center></td>
                                                                                                    <td className='ant-table-cell'>
                                                                                                        <div className="col-md-12 ">
                                                                                                            <div className="col-md-10">
                                                                                                                {/* <Dragger multiple onChange={(e) => {
                                                                                                                    SavepastRecordOnChange(e)
                                                                                                                }}> */}
                                                                                                                <InboxOutlined style={{ fontSize: '25px', color: 'blue' }} />
                                                                                                                {/* <p> Drag or Click here to upload</p> */}
                                                                                                                <input type="file" multiple name="file" style={{
                                                                                                                    'max-width': '200px', 'overflow': 'hidden',
                                                                                                                    'text- overflow': 'ellipsis', 'white-space': 'nowrap'
                                                                                                                }}
                                                                                                                    onChange={(e) => {
                                                                                                                        SavepastRecordOnChange(e)
                                                                                                                        checkBoxValue1[items.childId] = 1
                                                                                                                        setCheckBoxValue1([...checkBoxValue1])
                                                                                                                        downloadcheckBoxOnChange(true, items)
                                                                                                                    }}
                                                                                                                    id="" />
                                                                                                                {/* </Dragger>  */}
                                                                                                            </div>
                                                                                                            {/* {fileupload && fileupload.length > 0 ? <span className='col-md-2' style={{ marginLeft: '85%' }}><Tooltip title='Click to View Document History'> <ExclamationCircleTwoTone /> </Tooltip></span> : ''} */}

                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className='ant-table-cell'>{
                                                                                                        <div className="col-md-12 ">
                                                                                                            <div className="form-group">
                                                                                                                <label htmlFor="">
                                                                                                                    <b>Remarks : </b>
                                                                                                                </label>
                                                                                                                <span style={{ color: "red" }}> *</span>
                                                                                                                <div class="d-flex align-items-end">
                                                                                                                    <TextArea
                                                                                                                        name="remarks"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Remarks"
                                                                                                                        // value={remarks.remarks || undefined}
                                                                                                                        maxLength={500}
                                                                                                                        id="remarks"
                                                                                                                        cols="5"
                                                                                                                        rows="1"
                                                                                                                        onKeyPress={(event) => {
                                                                                                                            if (! /^[A-Z0-9a-z _ .,-]+$/.test(event.key)) {
                                                                                                                                event.preventDefault();
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        value={updateRemarks[items.childId]}
                                                                                                                        // key={updateRemarks[items.childId]}
                                                                                                                        onChange={(e) => {
                                                                                                                            // SetRemarks({
                                                                                                                            //     ...remarks,
                                                                                                                            //     remarks: e.target.value,
                                                                                                                            // });
                                                                                                                            // let temp1 = []
                                                                                                                            // temp1.push(e.target.value)
                                                                                                                            // if (items.childId in updateRemarks) {
                                                                                                                            //     let index = updateRemarks.indexOf(items)
                                                                                                                            //     updateRemarks[items.childId] = [];
                                                                                                                            //     if (index == -1) {
                                                                                                                            //         updateRemarks[items.childId] = e.target.value;
                                                                                                                            //     }
                                                                                                                            // } else {
                                                                                                                            //     updateRemarks[items.childId] = [];
                                                                                                                            //     updateRemarks[items.childId] = e.target.value
                                                                                                                            // }
                                                                                                                            updateRemarks[items.childId] = e.target.value
                                                                                                                            setUpdateRemarks([...updateRemarks])
                                                                                                                            if (e.target.value == '') {
                                                                                                                                checkBoxValue1[items.childId] = 0
                                                                                                                                setCheckBoxValue1([...checkBoxValue1])
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                checkBoxValue1[items.childId] = 1
                                                                                                                                setCheckBoxValue1([...checkBoxValue1])
                                                                                                                                downloadcheckBoxOnChange(true, items)
                                                                                                                            }
                                                                                                                        }}
                                                                                                                    >

                                                                                                                    </TextArea>
                                                                                                                    <span
                                                                                                                        className="ms-1 text-info d-flex 
                                align-items-center justify-content-center"
                                                                                                                        style={{ float: "right" }}
                                                                                                                        onClick={() => {
                                                                                                                            showRemarksModal(items.childId);
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <PlusCircleOutlined />
                                                                                                                    </span>
                                                                                                                    {formValidator1.current.message(
                                                                                                                        "updateRemarks",
                                                                                                                        updateRemarks[items.childId],
                                                                                                                        ["required", { regex: /^[A-Z0-9a-z _ .,-]+$/ }],
                                                                                                                        {
                                                                                                                            className: `invalid-feedback ${AddFormSubmit1 ? "show" : "hide"
                                                                                                                                }`,
                                                                                                                            messages: {
                                                                                                                                required: "Remarks Required",
                                                                                                                                regex: "Enter Valid Remarks"
                                                                                                                            },
                                                                                                                        }
                                                                                                                    )}
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </div>
                                                                                                    }
                                                                                                    </td>
                                                                                                    <span hidden>{count = count + 1}</span>
                                                                                                </tr>
                                                                                            }
                                                                                        })
                                                                                        }
                                                                                    </Fragment>
                                                                                }

                                                                            }
                                                                        })
                                                                        }
                                                                    </tbody>
                                                                )
                                                            }) :
                                                                <tr>
                                                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>
                                                            }
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>


                                : ''
                            }

                        </div>
                    </div>


                </div >
            </div >

            <Modal title="Remarks List" visible={remarksModal}
                onCancel={handleCancel}
                className={' remarksClass  add-service-prv ' + localStorage.getItem("currentTheme")} footer={false}>
                <Table
                    // className='userprivclass'
                    size={'small'}
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 ? remarksvalue : ''}
                    bordered
                    pagination={false}
                />
            </Modal>


        </div >

    )
}

export default connect(mapStateToProps, {
    emailsave,
    GetGroupListData,
    FetchEmails,
    verifypassword,
    updateblockoption,
    GetRemarksData,
    getFilterDataValues,
    EasyUploadFile,
    saveuploadStatus,
    getShowdata,
    getTabledata
})(CV_Easyupload)
