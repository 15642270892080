import { WIDGET_DATA, RISK_WIDGET, OVERDUE_WIDGET, COMPLIANCE_STATUS_WIDGET, TASKDISTRIBUTION_WIDGET, CRITICALITY_WIDGET, COMPLIANCE_TREND_WIDGET, COUNTRY_DATA } from './../types/index'



const initialState = {
    widInfo: '',
    riskWid: '',
    overDueWid: '',
    complianceStatusChart: '',
    taskdistributionWid: '',
    criticalityWid: '',
    complianceTrendWid: '',
    countryInfo: ''

}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case WIDGET_DATA:
            {
                return {
                    ...state,
                    widInfo: payload[1],
                };
            }

        case RISK_WIDGET:
            {
                console.log(payload, 'payloadRisk');
                return {
                    ...state,
                    riskWid: payload,
                };
            }

        case OVERDUE_WIDGET:
            {
                console.log(payload, 'payloadRisk');
                return {
                    ...state,
                    overDueWid: payload,
                };
            }

        case COMPLIANCE_STATUS_WIDGET:
            {
                console.log(payload, 'payloadRisk');
                return {
                    ...state,
                    complianceStatusChart: payload,
                };
            }

        case TASKDISTRIBUTION_WIDGET:
            {
                console.log(payload, 'payloadRisk');
                return {
                    ...state,
                    taskdistributionWid: payload,
                };
            }

        case CRITICALITY_WIDGET:
            {
                console.log(payload, 'payloadRisk');
                return {
                    ...state,
                    criticalityWid: payload,
                };
            }

        case COMPLIANCE_TREND_WIDGET:
            {
                console.log(payload, 'payloadRisk');
                return {
                    ...state,
                    complianceTrendWid: payload,
                };
            }

        case COUNTRY_DATA:
            {
                return {
                    ...state,
                    countryInfo: payload[1],
                };
            }

        default:
            return state;
    }
}