import React from "react";
import {
  Collapse,
  Select,
  Input,
  DatePicker,
  Card,
  Pagination,
  Drawer,
  Button,
  Progress,
  Table,
  Tooltip,
  Modal,
  Descriptions,
  Empty,
  Popover, Popconfirm
} from "antd";
import DataTable from "react-data-table-component";
import { useState, useEffect, useRef, Fragment } from "react";
import {
  ExclamationCircleTwoTone,
  InfoCircleOutlined,
  ArrowRightOutlined,
  EyeOutlined,
  ExportOutlined,
  ArrowLeftOutlined,
  PlayCircleOutlined,
  DownloadOutlined,
  UpOutlined,
  FullscreenOutlined,
  FilterOutlined,
  StopOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {
  getFilterValue,
  getConsolidatedReportData,
  getConsolidatedExport,
  downloadConsolidatedReport,
  getHistoryRemarks
} from "../../../../src/Store/Action/Report/ConsolidatedReports";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { getUnitData } from "../../../Store/Action/Report/TaskWiseReport";
import ScrollToTop from "react-scroll-to-top";
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../../Libs/fullscreenmode";
import ScrollButton from "../../Scrollbar";
import { Toaster } from '../../../Libs/Toaster';
import { CountrySplit, HomeRedirect } from './../../../Libs/country'
const uniq_country_data = CountrySplit()
const homepage = HomeRedirect()

const mapStateToProps = (state) => ({
  filterValue: state.ConsolidatedReports.ConsolidatedReports,
  
});
const ConsolidatedReport = ({
  getFilterValue,
  filterValue,
  getConsolidatedReportData,
  getConsolidatedExport,
  getHistoryRemarks,
  downloadConsolidatedReport,
}) => {
  console.log(filterValue, 'filterValue')
  const _ = require("lodash");
  const validator = useRef(new SimpleReactValidator());
  const [current, setCurrent] = useState(1);
  const [pageState, setpageState] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let [index, setIndex] = useState(0);
  let [serialNo, setSerialNo] = useState(1);
  const [childCompliceId, setChildComplinaceId] = useState([]);
  const [entityid, setCurrentEntity] = useState(
    localStorage.getItem("SelectedEntityid")
  );
  const [entity, setEntity] = useState([])
  const [entityData, setEntityData] = useState([])
  console.log(entityData, 'entityData');
  const [complianceID, setComplianceID] = useState([]);
  console.log(complianceID, 'complianceID');
  const [totalCount, setTotal] = useState(50);
  const [array, setArray] = useState([]);
  const [downloadclick, setDownloadClick] = useState(false);
  const [uniqueData, setUniqueData] = useState([]);
  console.log(uniqueData, 'uniqueData');
  const [AddFormSubmit, setAddFormSubmit] = useState(false);
  const [tableshow, setTableShow] = useState(false);
  const [statkey, setStatKey] = useState("0");
  const [key, setKey] = useState("1");
  const [actData, setActData] = useState();
  const [remarksModal, setRemarksModal] = useState(false)
  const [checkboxValue, setCheckBoxValue] = useState([
    1,2,7,8,11,12,32,35
  ]);
  const [exportButton, setExportButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataTableProperties, setDataTableProperties] = useState({
    fixedHeader: true,
    pagesize: 10,
    sizechanger: true,
  });
  const [titledata, Settitledata] = useState();
  console.log(titledata, 'titledata')
  const [filteredArray, setFilteredArray] = useState([]);
  const [filteredArrayState, setfilteredArrayState] = useState([]);
  const [consolidatedData, setConsolidatedData] = useState({
    country: "",
    category: "",
    user_type: 0,
    legel_entity: "",
    legal_entity_name: "",
    unit: "",
    user: "",
    domain: "",
    act: "",
    from_date: "",
    task_category: "",
    compliance_task: "",
    to_date: "",
    task_sub_category: 0,
    compliance_frequency: "",
    compliance_task_status: "All",
    division: "",
    domainName: "",
    countryName: "",
    entityname: "",
  });
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const paramid = location.pathname;
  const isAuth = localStorage.getItem("isAuthenticated");
  const authtoken = localStorage.getItem("authToken");
  const sessionvalue = localStorage.getItem("sessionValue");
  const sessionParsedValue = JSON.parse(sessionvalue);
  const country_info = sessionParsedValue.country_info;
  const [data, setData] = useState({
    le_name: '',
    domaindata: '',
    country: '',
  });
  const [newdata, setNewData] = useState({
    le_name: '',
    domaindata: '',
    country: ''
  })
  const sessionArr = sessionParsedValue.entity_info;
  const [countryData, setCountryData] = useState([])
  const [domain, setDomain] = useState([]);
  const [finalResult, SetfinalResult] = useState({
    Resultdata: [],
  });
  const [division, setDivision] = useState([]);
  const [taskCategory, setaskCategory] = useState([]);
  const [Acts, setActs] = useState([]);
  const [SubtaskCategory, setSubtaskCategory] = useState([]);
  const [userData, setUserData] = useState([]);
  const [frequency, setfrequency] = useState([]);
  const [category, setCategoryList] = useState([]);
  const [unitcode, setUnitcode] = useState([]);
  const [units, setUnits] = useState([]);
  const [filterTaskAcc, setFilterTaskAcc] = useState(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [exitCollapse, setExitCollapse] = useState(false);

  const { Panel } = Collapse;
  const { Option } = Select;
  const [disableButton, setDisableButton] = useState(false);
  const onshow = () => {
    setAddFormSubmit(true);
    Settitledata([]);
    setfilteredArrayState([]);
    setIndex(0);
    setCurrent(1);
    setpageState(false)
    setPageSize(25)
    if (validator.current.allValid()) {
      setExitCollapse(true);
      setFilterTaskAcc(false);

      setFilterModalVisible(false);
      setTableShow(true);
      setKey(0);
      setStatKey(1);
      const payload = [
        authtoken,
        {
          session_token: authtoken,
          request: [
            "GetStatusReportConsolidated",
            {
              c_id: Number(consolidatedData.country),
              le_id: entityid,
              d_id: Number(consolidatedData.domain),
              task_category_id: consolidatedData.task_category
                ? Number(consolidatedData.task_category)
                : 0,
              task_sub_category_id: consolidatedData.task_sub_category
                ? Number(consolidatedData.task_sub_category)
                : 0,
              division_id: consolidatedData.division
                ? Number(consolidatedData.division)
                : 0,
              category_id: consolidatedData.category
                ? Number(consolidatedData.category)
                : 0,
              unit_id: consolidatedData.unit
                ? Number(consolidatedData.unit)
                : null,
              parent_id: consolidatedData.act
                ? Number(consolidatedData.act)
                : 0,
              compliance_task: consolidatedData.compliance_task
                ? consolidatedData.compliance_task
                : null,
              frequency_id: consolidatedData.compliance_frequency
                ? Number(consolidatedData.compliance_frequency)
                : 0,
              user_type_id: consolidatedData.user_type
                ? Number(consolidatedData.user_type)
                : 0,
              status_name: consolidatedData.compliance_task_status
                ? consolidatedData.compliance_task_status
                : "All",
              usr_id: consolidatedData.user
                ? Number(consolidatedData.user)
                : null,
              from_date: moment(consolidatedData.from_date).format(
                "DD-MMM-YYYY"
              ),
              to_date: moment(consolidatedData.to_date._d).format(
                "DD-MMM-YYYY"
              ),
              csv: false,
              f_count: 1,
              t_count: 25,
              count_qry: true,
              list_check: null,
            },
          ],
        },
      ];
      getConsolidatedReportData({
        payload: payload,
        paramid: paramid,
      });
    }
  };
  const filterHandle = () => {
    setFilterTaskAcc(true);
    setFilterModalVisible(true);
  };
  const remarksHistory = (leId, HistoryId, unitId) => {
    const payload = [
      authtoken,
      {
        session_token: authtoken,
        request: [
          "GetComplianceRemarksHistoryData",
          {
            "legal_entity_id": entityid,
            "unit_id": unitId,
            "compliance_history_id": HistoryId
          }
        ]
      },
    ];
    getHistoryRemarks({
      payload: payload,
      paramid: paramid,
    });
  }
  const onClose = () => {
    setOpen(false);
  };
  const showModal = () => {
    setAddFormSubmit(true);
    if (validator.current.allValid()) {
      setIsModalOpen(true);
    }
  };
  const setcancelFilter = () => {
    setFilterModalVisible(false);
    setFilterTaskAcc(false);
  };
  console.log(filterValue.RemarksData, 'RemarksData');
  const Activate = () => {
    Swal.fire({
      title: "Are You Sure?",
      text: `Total Selected Tasks : ${complianceID.length}`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        showModal();

        // Swal.fire('Closed.!', 'Closed...', 'success');
      }
      else {
        setIsCheckedAll(false)
        if (filterValue.total_history_ids.length > 0) {
          for (let i in filterValue.total_history_ids) {
            console.log(filterValue.total_history_ids[i], 'filterValue.total_history_ids[i]')
            isChecked[parseInt(filterValue.total_history_ids[i])] = false
            setIsChecked([...isChecked])

          }
        }
        setComplianceID([])
      }
    });
  };
  const content = (

    <div
      className="col-md-12"
      style={{ marginLeft: "5%", marginRight: "5%" }}
    >
      <div className="row m-2">
        <div className="col-md-3  d-flex flex-column">
          <Progress
            type="circle"
            percent={30}
            width={70}
            format={(percent) => `${120}`}
          />
          <p>Complied</p>
        </div>
        <div className="col-md-3  d-flex flex-column">
          <Progress
            type="circle"
            percent={20}
            width={70}
            status="exception"
            format={(percent) => `${50}`}
          />
          <p>Delayed Complied</p>
        </div>
        <div className="col-md-3  d-flex flex-column">
          <Progress
            type="circle"
            percent={100}
            width={70}
            format={(percent) => `${200}`}
          />
          <p>Inprogress</p>
        </div>
        <div className="col-md-3 d-flex flex-column">
          <Progress
            type="circle"
            percent={80}
            width={70}
            strokeColor={{
              "0%": "#f5b342",
              "100%": "#f5b342",
            }}
            format={(percent) => `${180}`}
          />
          <p>Not Complied</p>
        </div>
      </div>
    </div>
  );

  const handlePasting = (e, regex) => {
    var regex = regex;
    var key = e.clipboardData.getData('text')
    if (!regex.test(key)) {
      e.preventDefault();
      Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
      return false;
    }
  }

  const handleOk = () => {
    setAddFormSubmit(true);
    setExportButton(true);
    if (validator.current.allValid() && downloadclick != true) {
      setTableShow(true);
      setKey(0);
      setStatKey(1);
      const payload = [
        authtoken,
        {
          session_token: authtoken,
          request: [
            "GetStatusReportConsolidated",
            {
              c_id: Number(consolidatedData.country),
              le_id: entityid,
              d_id: Number(consolidatedData.domain),
              task_category_id: consolidatedData.task_category ? Number(consolidatedData.task_category) : 0,
              task_sub_category_id: consolidatedData.task_sub_category ? Number(consolidatedData.task_sub_category) : 0,
              division_id: consolidatedData.division ? Number(consolidatedData.division) : 0,
              category_id: consolidatedData.category ? Number(consolidatedData.category) : 0,
              unit_id: consolidatedData.unit ? Number(consolidatedData.unit) : null,
              parent_id: consolidatedData.act ? Number(consolidatedData.act) : 0,
              compliance_task: consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
              frequency_id: consolidatedData.compliance_frequency ? Number(consolidatedData.compliance_frequency) : 0,
              user_type_id: consolidatedData.user_type ? Number(consolidatedData.user_type) : 0,
              status_name: consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
              usr_id: consolidatedData.user ? Number(consolidatedData.user) : null,
              from_date: moment(consolidatedData.from_date).format("DD-MMM-YYYY"),
              to_date: moment(consolidatedData.to_date._d).format("DD-MMM-YYYY"),
              csv: true,
              f_count: 1,
              t_count: 25,
              count_qry: true,
              list_check: checkboxValue,
            },
          ],
        },
      ];
      getConsolidatedExport({
        payload: payload,
        paramid: paramid,
      });
    }
    if (downloadclick === true) {
      const payload = [
        authtoken,
        {
          session_token: authtoken,
          request: [
            "GetBulkDocumentsDownload",
            {
              c_id: Number(consolidatedData.country),
              le_id: entityid,
              d_id: Number(consolidatedData.domain),
              task_category_id: consolidatedData.task_category ? Number(consolidatedData.task_category) : 0,
              task_sub_category_id: consolidatedData.task_sub_category ? Number(consolidatedData.task_sub_category) : 0,
              division_id: consolidatedData.division ? Number(consolidatedData.division) : 0,
              category_id: consolidatedData.category ? Number(consolidatedData.category) : 0,
              unit_id: consolidatedData.unit ? Number(consolidatedData.unit) : null,
              parent_id: consolidatedData.act ? Number(consolidatedData.act) : 0,
              compliance_task: consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
              frequency_id: consolidatedData.compliance_frequency ? Number(consolidatedData.compliance_frequency) : 0,
              user_type_id: consolidatedData.user_type ? Number(consolidatedData.user_type) : 0,
              status_name: consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
              usr_id: consolidatedData.user ? Number(consolidatedData.user) : null,
              from_date: moment(consolidatedData.from_date).format("DD-MMM-YYYY"),
              to_date: moment(consolidatedData.to_date._d).format("DD-MMM-YYYY"),
              ch_ids: complianceID,
              csv_bulk: true,
              list_check: checkboxValue,
            },
          ],
        },
      ];
      console.log(payload, 'payloadddddddddddddddd');
      downloadConsolidatedReport({
        payload: payload,
        paramid: paramid,
      });
    }
    setIsModalOpen(false);
    setCheckBoxValue([1,2,7,8,11,12,32,35]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalVisible(false);
    setRemarksModal(false);
    setCheckBoxValue([1,2,7,8,11,12,32,35]);
  };
  const chilData = (statusArray) => {
    setIsModalVisible(true);
    setChildComplinaceId(statusArray);

    // if (
    //   filterValue &&
    //   filterValue.ConsolidatedReportList &&
    //   filterValue.ConsolidatedReportList.length > 25
    // ) {
    //   let tempArr = [];
    //   for (let i in filterValue &&
    //     filterValue.ConsolidatedReportList &&
    //     filterValue.ConsolidatedReportList.length &&
    //     filterValue.ConsolidatedReportList) {
    //     if (
    //       filterValue &&
    //       filterValue.ConsolidatedReportList &&
    //       filterValue.ConsolidatedReportList.length &&
    //       filterValue.ConsolidatedReportList[i].compliance_id == id
    //     ) {
    //       if (
    //         filterValue &&
    //         filterValue.ConsolidatedReportList &&
    //         filterValue.ConsolidatedReportList.length &&
    //         filterValue.ConsolidatedReportList[i].unit_id == unitId
    //       ) {
    //         tempArr.push(
    //           filterValue &&
    //           filterValue.ConsolidatedReportList &&
    //           filterValue.ConsolidatedReportList.length &&
    //           filterValue.ConsolidatedReportList[i]
    //         );
    //       }
    //     }
    //   }
    // }
  };
  const [isChecked, setIsChecked] = useState([])
  const [isCheckedAll, setIsCheckedAll] = useState(false)

  /*const downloadcheckBoxOnChange = (e, item) => {
    let temp = complianceID;
    let tempIds = [];
    isChecked[parseInt(item.compliance_history_id)] = e.target.checked
    setIsChecked([...isChecked])

    if (temp.includes(Number(item.compliance_history_id)) == true) {

      for (let i in temp) {
        if (temp[i] == Number(item.compliance_history_id)) {
          temp.splice(i, 1);
        }
      }
      setComplianceID([...temp]);
    } else {
      tempIds.push(Number(item.compliance_history_id));
      setComplianceID([...complianceID, ...tempIds]);
    }

  };*/
  const downloadcheckBoxOnChange = (e, item) => {
    let temp = complianceID;
    let tempIds = [];
    isChecked[parseInt(item.compliance_history_id)] = e.target.checked
    setIsChecked([...isChecked])

    if (temp.includes(Number(item.compliance_history_id)) == true) {
      
      for (let i in temp) {
        if (temp[i] == Number(item.compliance_history_id)) {
          temp.splice(i, 1);
        }
      }
      setComplianceID([...temp]);
    } else {
      tempIds.push(Number(item.compliance_history_id));
      setComplianceID([...complianceID, ...tempIds]);
    }
    
  };

  useEffect(() => {
    if (sessionArr && sessionArr.length > 0) {
      let uniqueObjArray = [
        ...new Map(
          sessionArr &&
          sessionArr.length &&
          sessionArr.map((item) => [
            item["c_id"],
            item,
          ])
        ).values(),
      ];
      setCountryData(uniqueObjArray)

      setEntityData(sessionArr)
    }
  }, [])
 /* useEffect(() => {
    console.log(filterValue.total_history_ids, 'filterValue.total_history_ids');
    if (filterValue.total_history_ids.length > 0) {
      if (complianceID.length == parseInt(filterValue.total_history_ids.length)) {
        console.log('snehaaaaaaaaaaaaa');
        setIsCheckedAll(true)
      } else {
        setIsCheckedAll(false)
      }
    }
  }, [complianceID, filterValue.total_history_ids])*/
  useEffect(() =>{    
    if(complianceID.length == parseInt(filterValue.count) && complianceID.length > 0){
      setIsCheckedAll(true)
    }else{
      setIsCheckedAll(false)
    }
  },[complianceID])



 /* const downloadselectAllCheckBox = (checked) => {
    var ele = document.getElementsByClassName("checkbox");
    let tempAllIds = [];
    if (checked == true) {
      setIsChecked(true)
      for (let i in filterValue.total_history_ids) {
        console.log(filterValue.total_history_ids[i], 'filterValue.total_history_ids[i]')
        isChecked[parseInt(filterValue.total_history_ids[i])] = true


      }
      setIsChecked([...isChecked])
      // for (var i = 0; i < ele.length; i++) {
      //   if (ele[i].type == "checkbox") {
      //     ele[i].checked = true;
      //   }
      // }   
      // let ids =
      //   filterValue &&
      //   filterValue.ConsolidatedReportList &&
      //   filterValue.ConsolidatedReportList.length &&
      //   filterValue.ConsolidatedReportList.map((item) => {
      //     return item.compliance_id;
      //   });
      //   console.log(ids,'complianceIDs')
      console.log(filterValue.total_history_ids, 'filterValue.total_history_ids');
      setComplianceID(filterValue.total_history_ids);
      setIsCheckedAll(true)
    } else {
      setIsChecked([])
      setIsCheckedAll(false)
      // var ele = document.getElementsByName("checked");
      // for (var i = 0; i < ele.length; i++) {
      //   if (ele[i].type == "checkbox") ele[i].checked = false;
      // }
      setComplianceID([]);
    }
  }; */
  const downloadselectAllCheckBox = (checked) => {
    var ele = document.getElementsByClassName("checkbox");
    let tempAllIds = [];
    if (checked == true) {
      setIsChecked(true)
      for(let i in filterValue.total_history_ids){
        console.log(filterValue.total_history_ids[i],'filterValue.total_history_ids[i]')
        isChecked[parseInt(filterValue.total_history_ids[i])] = true
        setIsChecked([...isChecked])
        
      }     
      // for (var i = 0; i < ele.length; i++) {
      //   if (ele[i].type == "checkbox") {
      //     ele[i].checked = true;
      //   }
      // }   
      // let ids =
      //   filterValue &&
      //   filterValue.ConsolidatedReportList &&
      //   filterValue.ConsolidatedReportList.length &&
      //   filterValue.ConsolidatedReportList.map((item) => {
      //     return item.compliance_id;
      //   });
      //   console.log(ids,'complianceIDs')
      setComplianceID(filterValue.total_history_ids);
      setIsCheckedAll(true)
    } else {
      setIsChecked([])
      setIsCheckedAll(false)
      // var ele = document.getElementsByName("checked");
      // for (var i = 0; i < ele.length; i++) {
      //   if (ele[i].type == "checkbox") ele[i].checked = false;
      // }
      setComplianceID([]);
    }
  };


  const checkBoxOnChange = (e) => {
    let temp = checkboxValue;
    let tempIds = [];
    if (temp.includes(Number(e.target.value)) == true) {
      for (let i in temp) {
        if (temp[i] == Number(e.target.value)) {
          temp.splice(i, 1);
        }
      }
      setCheckBoxValue([...temp]);
    } else {
      tempIds.push(Number(e.target.value));
      setCheckBoxValue([...checkboxValue, ...tempIds]);
    }
  };

  const selectAllCheckBox = (checked) => {
    var ele = document.getElementsByName("chk");
    let tempAllIds = [];
    if (checked == true) {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") {
          ele[i].checked = true;
          tempAllIds.push(Number(ele[i].value));
        }
      }
      setCheckBoxValue(tempAllIds);
    } else {
      var ele = document.getElementsByName("chk");
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = false;
      }
      setCheckBoxValue([]);
    }
  };

  useEffect(() => {
    if (pageState == true) {
      const payload = [
        authtoken,
        {
          session_token: authtoken,
          request: [
            "GetStatusReportConsolidated",
            {
              c_id: Number(consolidatedData.country),
              le_id: entityid,
              d_id: Number(consolidatedData.domain),
              task_category_id: consolidatedData.task_category ? Number(consolidatedData.task_category) : 0,
              task_sub_category_id: consolidatedData.task_sub_category ? Number(consolidatedData.task_sub_category) : 0,
              division_id: consolidatedData.division ? Number(consolidatedData.division) : 0,
              category_id: consolidatedData.category ? Number(consolidatedData.category) : 0,
              unit_id: consolidatedData.unit ? Number(consolidatedData.unit) : null,
              parent_id: consolidatedData.act ? Number(consolidatedData.act) : 0,
              compliance_task: consolidatedData.compliance_task ? consolidatedData.compliance_task : null,
              frequency_id: consolidatedData.compliance_frequency ? Number(consolidatedData.compliance_frequency) : 0,
              user_type_id: consolidatedData.user_type ? Number(consolidatedData.user_type) : 0,
              status_name: consolidatedData.compliance_task_status ? consolidatedData.compliance_task_status : "All",
              usr_id: consolidatedData.user ? Number(consolidatedData.user) : null,
              from_date: moment(consolidatedData.from_date).format("DD-MMM-YYYY"),
              to_date: moment(consolidatedData.to_date._d).format("DD-MMM-YYYY"),
              csv: false,
              f_count: Math.max((current - 1) * pageSize, 0) + 1,
              t_count: current != 0 ? current * pageSize : pageSize,
              count_qry: true,
              list_check: null,
            },
          ],
        },
      ];
      getConsolidatedReportData({
        payload: payload,
        paramid: paramid,
      });
    }
  }, [current, pageSize, pageState]);

  useEffect(() => {
    let tempArr = [];
    if (
      filterValue &&
      filterValue.ConsolidatedReportList &&
      filterValue.ConsolidatedReportList.length > 0
    ) {
      filteredArrayState &&
        filteredArrayState.length > 0 &&
        filteredArrayState.map((item, i) => {
          console.log(filteredArrayState, 'filteredArrayState');
          console.log(item, 'item5555555555555');
          let uniqueObjArray = [
            ...new Map(
              item &&
              item.length &&
              item.map((items) => [items["compliance_history_id"], items])
            ).values(),
          ];
          const uniqueIds =
            uniqueObjArray &&
            uniqueObjArray.map((itemData) => {
              return itemData.compliance_history_id;
            });

          for (let i in uniqueIds) {
            let filteredArr = _.find(item && item.length && item, {
              compliance_history_id: uniqueIds[i],
            });
            tempArr.push(filteredArr);
          }
        });

      setUniqueData(tempArr);
    }
  }, [filteredArrayState]);

  const columns = [
    {
      title: "User Name",
      ellipsis: true,
      width: "50px",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Activity Status",
      ellipsis: true,
      width: "50px",
      dataIndex: "activity_status",
      key: "activity_status",
    },
    {
      title: "Activity Date",
      ellipsis: true,
      width: "40px",
      dataIndex: "activity_on",
      key: "activity_on",
    },
    {
      title: "Task Completion Date/Document issued Date",
      ellipsis: true,
      width: "120px",
      align: "center",
      dataIndex: "completion_date",
      key: "completion_date",
    },
  ];
  const columns1 = [
    {
      title: "#",
      // ellipsis: true,
      align: 'center',
      width: "20px",
      dataIndex: "user_category_id",
      key: "user_category_id",
    },
    {
      title: "Activity Date",
      ellipsis: true,
      width: "70px",
      dataIndex: "activity_on",
      key: "activity_on",
    },
    {
      title: "Reviewer Report",
      ellipsis: true,
      width: "40px",
      dataIndex: "documents",
      key: "documents",
    },
    {
      title: "Remarks",
      ellipsis: true,
      width: "120px",
      align: "center",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];
  useEffect(() => {
    if (entityid === null) {
      setCurrentEntity(consolidatedData.legel_entity);
    }
  }, [entityid]);

  // useEffect(() => {
  //   if (
  //     filterValue &&
  //     filterValue.ConsolidatedReportList &&
  //     filterValue.ConsolidatedReportList.length &&
  //     filterValue.ConsolidatedReportList
  //   ) {
  //     setArray([
  //       ...array,
  //       ...(filterValue &&
  //         filterValue.ConsolidatedReportList &&
  //         filterValue.ConsolidatedReportList.length &&
  //         filterValue.ConsolidatedReportList),
  //     ]);
  //   }
  // }, [
  //   filterValue &&
  //   filterValue.ConsolidatedReportList &&
  //   filterValue.ConsolidatedReportList.length &&
  //   filterValue.ConsolidatedReportList,
  // ]);

  useEffect(() => {
    if (
      filterValue &&
      filterValue.ConsolidatedReportList &&
      filterValue.ConsolidatedReportList.length > 0
    ) {
      let uniqueObjArray = [
        ...new Map(
          filterValue &&
          filterValue.ConsolidatedReportList &&
          filterValue.ConsolidatedReportList.length &&
          filterValue.ConsolidatedReportList.map((item) => [
            item["unit_id"],
            item,
          ])
        ).values(),
      ];
      const uniqueIds =
        uniqueObjArray &&
        uniqueObjArray.map((item) => {
          return item.unit_id;
        });

      let tempArr = [];
      for (let i in uniqueIds) {
        let filteredArr = _.filter(
          filterValue &&
          filterValue.ConsolidatedReportList &&
          filterValue.ConsolidatedReportList.length &&
          filterValue.ConsolidatedReportList,
          { unit_id: uniqueIds[i] }
        );

        tempArr.push(filteredArr);
      }
      setFilteredArray([...filteredArray, tempArr]);
    }
  }, [filterValue && filterValue.ConsolidatedReportList]);
  const onChange = (key) => {
    console.log(key);
  };
  const onStatChange = (key) => {
    console.log(key);
  };
  const downloadOnChange = () => {
    const payload = [
      authtoken,
      {
        session_token: authtoken,
        request: [
          "GetBulkDocumentsDownload",
          {
            c_id: Number(consolidatedData.country),
            le_id: entityid,
            d_id: Number(consolidatedData.domain),
            task_category_id: consolidatedData.task_category
              ? Number(consolidatedData.task_category)
              : 0,
            task_sub_category_id: consolidatedData.task_sub_category
              ? Number(consolidatedData.task_sub_category)
              : 0,
            division_id: consolidatedData.division
              ? Number(consolidatedData.division)
              : 0,
            category_id: consolidatedData.category
              ? Number(consolidatedData.category)
              : 0,
            unit_id: consolidatedData.unit
              ? Number(consolidatedData.unit)
              : null,
            parent_id: consolidatedData.act ? Number(consolidatedData.act) : 0,
            compliance_task: consolidatedData.compliance_task
              ? consolidatedData.compliance_task
              : null,
            frequency_id: consolidatedData.compliance_frequency
              ? Number(consolidatedData.compliance_frequency)
              : 0,
            user_type_id: consolidatedData.user_type
              ? Number(consolidatedData.user_type)
              : 0,
            status_name: consolidatedData.compliance_task_status
              ? consolidatedData.compliance_task_status
              : "All",
            usr_id: consolidatedData.user
              ? Number(consolidatedData.user)
              : null,
            from_date: moment(consolidatedData.from_date).format("DD-MMM-YYYY"),
            to_date: moment(consolidatedData.to_date._d).format("DD-MMM-YYYY"),
            ch_ids: complianceID,
            csv_bulk: true,
            list_check: checkboxValue,
          },
        ],
      },
    ];
    downloadConsolidatedReport({
      payload: payload,
      paramid: paramid,
    });
  };
  useEffect(() => {
    if (isAuth) {
      const payload = [
        authtoken,
        {
          session_token: authtoken,
          request: [
            "GetStatusReportConsolidatedFilters",
            {
              le_id: entityid,
            },
          ],
        },
      ];
      if (entityid != "null" && entityid !== "") {
        getFilterValue({
          payload: payload,
          paramid: paramid,
        });
      }
    }
  }, [isAuth, authtoken, paramid, entityid]);
  useEffect(() => {
    validator.current.showMessages();
  }, []);
  useEffect(() => {
    if (filteredArray.length) {
      let temp = [];
      for (let i in filteredArray) {
        temp = filteredArray[i];
      }
      setfilteredArrayState([...temp]);
    }
  }, [filteredArray]);
  useEffect(() => {
    if (filterValue) {
      setaskCategory(
        filterValue && filterValue.Data && filterValue.Data.task_category_list
      );
      setDomain(filterValue && filterValue.Data && filterValue.Data.domains);
      let uniqueAct = _.uniqBy(filterValue.Data.acts, function (e) {
        return e.act;
      })
      setActs(filterValue && filterValue.Data && uniqueAct);

      setfrequency(
        filterValue && filterValue.Data && filterValue.Data.compliance_frequency
      );
      setCategoryList(
        filterValue && filterValue.Data && filterValue.Data.categories_list
      );
      setSubtaskCategory(
        filterValue &&
        filterValue.Data &&
        filterValue.Data.task_sub_category_list
      );
      setUnitcode(
        filterValue && filterValue.Data && filterValue.Data.unit_code_label_list
      );
      setUnits(filterValue && filterValue.Data && filterValue.Data.units);
      setDivision(
        filterValue && filterValue.Data && filterValue.Data.divisions_list
      );
      setUserData(
        filterValue && filterValue.Data && filterValue.Data.legal_entity_users
      );
    }
  }, [filterValue]);
  const addAllSelectedDataValues = (checked) => { };
  useEffect(() => {
    if (localStorage.getItem("SelectedEntity") != "All Legal Entity") {
      let temp = localStorage.getItem("SelectedEntity")
      let tempcountry = _.filter(sessionArr, { le_name: temp })
      setConsolidatedData({
        ...consolidatedData,
        legel_entity: localStorage.getItem("SelectedEntityid"),
        country: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_id : '',
        countryName: tempcountry && tempcountry.length > 0 ? tempcountry[0].c_name : ''
      });
    }
  }, [localStorage.getItem("SelectedEntity") != "All Legal Entity"]);

  useEffect(() => {
    let array = [];
    let finalarray = [];
    let datavalue = [];
    let group = [];
    let array1 = []
    let array2 = []
    if (
      filterValue &&
      filterValue.ConsolidatedReportList &&
      filterValue.ConsolidatedReportList.length > 0
    ) {
      // setTempeditdata(editdata)
      if (uniqueData && uniqueData.length > 0) {
        const ab = uniqueData && uniqueData.length && uniqueData.map(function (item) {
          // if (item.task_group_name !== null) {
          //   array1.push(item.act_name);
          // }
          // else
          if (array.indexOf(item.act_name) < 0) array.push(item.act_name);
        });
        // array = array1.concat(array2)
        array.map((data, i) => {
          console.log(data, 'data6599999999');
          let temp = [];
          let temp2 = []
          let temp3 = []
          let entityArray = [];
          let datavalue = [];
          let checkarray = []
          if (data) {
            uniqueData && uniqueData.length && uniqueData.map((child, childIndex) => {
              console.log(child.task_group_name, 'child.task_group_name');
              if (child.task_group_name !== null) {
                console.log('Aishwaryaaaaaaa');
                if (data === child.act_name) {
                  entityArray = {
                    act_name: child.act_name,
                    activity_on: child.activity_on,
                    activity_status: child.activity_status,
                    category_name: child.category_name,
                    completion_date: child.completion_date,
                    compliance_activity_id: child.compliance_activity_id,
                    compliance_description: child.compliance_description,
                    compliance_history_id: child.compliance_history_id,
                    compliance_id: child.compliance_id,
                    compliance_name: child.compliance_name,
                    criticality_name: child.criticality_name,
                    division_name: child.division_name,
                    doc_ref_num: child.doc_ref_num,
                    due_date: child.due_date,
                    extend_due_date: child.extend_due_date,
                    frequency_name: child.frequency_name,
                    history_count: child.history_count,
                    interim_count: child.interim_count,
                    legal_entity_id: child.legal_entity_id,
                    start_date: child.start_date,
                    task_group_name: child.task_group_name,
                    task_status: child.task_status,
                    task_sub_group_name: child.task_sub_group_name,
                    tz_name: child.tz_name,
                    unit: child.unit,
                    unit_address: child.unit_address,
                    unit_id: child.unit_id,
                    uploaded_document: child.uploaded_document,
                    user_name: child.user_name,
                    childId: childIndex,
                    parentId: i,
                  };
                  temp.push(entityArray);
                }
              }

              else if (data === child.act_name) {
                entityArray = {
                  act_name: child.act_name,
                  activity_on: child.activity_on,
                  activity_status: child.activity_status,
                  category_name: child.category_name,
                  completion_date: child.completion_date,
                  compliance_activity_id: child.compliance_activity_id,
                  compliance_description: child.compliance_description,
                  compliance_history_id: child.compliance_history_id,
                  compliance_id: child.compliance_id,
                  compliance_name: child.compliance_name,
                  criticality_name: child.criticality_name,
                  division_name: child.division_name,
                  doc_ref_num: child.doc_ref_num,
                  due_date: child.due_date,
                  extend_due_date: child.extend_due_date,
                  frequency_name: child.frequency_name,
                  history_count: child.history_count,
                  interim_count: child.interim_count,
                  legal_entity_id: child.legal_entity_id,
                  start_date: child.start_date,
                  task_group_name: child.task_group_name,
                  task_status: child.task_status,
                  task_sub_group_name: child.task_sub_group_name,
                  tz_name: child.tz_name,
                  unit: child.unit,
                  unit_address: child.unit_address,
                  unit_id: child.unit_id,
                  uploaded_document: child.uploaded_document,
                  user_name: child.user_name,
                  childId: childIndex,
                  parentId: i,
                };
                temp2.push(entityArray);

              }

            });

          }
          console.log(temp, 'temp2127');
          console.log(temp2, "temptemptemp123")

          datavalue = {
            index: i,
            parent: data,
            child: temp2,
            child2: temp
          };

          finalarray.push(datavalue);
        });
        Settitledata(finalarray);
      }
    }
    // else {
    //   let group = [];
    //   if (
    //     filterValue &&
    //     filterValue.ConsolidatedReportList &&
    //     filterValue.ConsolidatedReportList.length > 0
    //   ) {
    //     const ab =
    //       filterValue &&
    //       filterValue.ConsolidatedReportList &&
    //       filterValue.ConsolidatedReportList.length &&
    //       filterValue.ConsolidatedReportList.map(function (item) {
    //         if (array.indexOf(item.act_name) < 0) array.push(item.act_name);
    //       });
    //     // const group123 =
    //     //   filterValue &&
    //     //   filterValue.ConsolidatedReportList &&
    //     //   filterValue.ConsolidatedReportList.length &&
    //     //   filterValue.ConsolidatedReportList.map(function (item) {
    //     //     return item.task_group_name;
    //     //   });
    //     // const Gfliter = group123.map((item)=>{
    //     //     return item.task_group_name
    //     // })
    //     array.map((data, i) => {
    //       console.log(data, 'data6599999999');
    //       let temp = [];
    //       let temp2 = []
    //       let temp3 = []
    //       let entityArray = [];
    //       let datavalue = [];
    //       let checkarray = []
    //       if (data) {
    //         filterValue &&
    //           filterValue.ConsolidatedReportList &&
    //           filterValue.ConsolidatedReportList.length &&
    //           filterValue.ConsolidatedReportList.map((child, childIndex) => {
    //           console.log(child.task_group_name, 'child.task_group_name');
    //           if (child.task_group_name !== null) {
    //             console.log('Aishwaryaaaaaaa');
    //             if (data === child.act_name) {
    //               entityArray = {
    //                 act_name: child.act_name,
    //                 activity_on: child.activity_on,
    //                 activity_status: child.activity_status,
    //                 category_name: child.category_name,
    //                 completion_date: child.completion_date,
    //                 compliance_activity_id: child.compliance_activity_id,
    //                 compliance_description: child.compliance_description,
    //                 compliance_history_id: child.compliance_history_id,
    //                 compliance_id: child.compliance_id,
    //                 compliance_name: child.compliance_name,
    //                 criticality_name: child.criticality_name,
    //                 division_name: child.division_name,
    //                 doc_ref_num: child.doc_ref_num,
    //                 due_date: child.due_date,
    //                 extend_due_date: child.extend_due_date,
    //                 frequency_name: child.frequency_name,
    //                 history_count: child.history_count,
    //                 interim_count: child.interim_count,
    //                 legal_entity_id: child.legal_entity_id,
    //                 start_date: child.start_date,
    //                 task_group_name: child.task_group_name,
    //                 task_status: child.task_status,
    //                 task_sub_group_name: child.task_sub_group_name,
    //                 tz_name: child.tz_name,
    //                 unit: child.unit,
    //                 unit_address: child.unit_address,
    //                 unit_id: child.unit_id,
    //                 uploaded_document: child.uploaded_document,
    //                 user_name: child.user_name,
    //                 childId: childIndex,
    //                 parentId: i,
    //               };
    //               temp.push(entityArray);
    //             }
    //           }

    //           else if (data === child.act_name) {
    //             entityArray = {
    //               act_name: child.act_name,
    //               activity_on: child.activity_on,
    //               activity_status: child.activity_status,
    //               category_name: child.category_name,
    //               completion_date: child.completion_date,
    //               compliance_activity_id: child.compliance_activity_id,
    //               compliance_description: child.compliance_description,
    //               compliance_history_id: child.compliance_history_id,
    //               compliance_id: child.compliance_id,
    //               compliance_name: child.compliance_name,
    //               criticality_name: child.criticality_name,
    //               division_name: child.division_name,
    //               doc_ref_num: child.doc_ref_num,
    //               due_date: child.due_date,
    //               extend_due_date: child.extend_due_date,
    //               frequency_name: child.frequency_name,
    //               history_count: child.history_count,
    //               interim_count: child.interim_count,
    //               legal_entity_id: child.legal_entity_id,
    //               start_date: child.start_date,
    //               task_group_name: child.task_group_name,
    //               task_status: child.task_status,
    //               task_sub_group_name: child.task_sub_group_name,
    //               tz_name: child.tz_name,
    //               unit: child.unit,
    //               unit_address: child.unit_address,
    //               unit_id: child.unit_id,
    //               uploaded_document: child.uploaded_document,
    //               user_name: child.user_name,
    //               childId: childIndex,
    //               parentId: i,
    //             };
    //             temp2.push(entityArray);

    //           }

    //         });

    //       }
    //       console.log(temp, 'temp2127');
    //       console.log(temp2, "temptemptemp123")

    //       datavalue = {
    //         index: i,
    //         parent: data,
    //         child: temp2,
    //         child2: temp
    //       };

    //       finalarray.push(datavalue);
    //     });
    //     Settitledata(finalarray);
    //     // array.map((data, i) => {
    //     //   let temp = [];
    //     //   let entityArray = [];
    //     //   let datavalue = [];
    //     //   let checkarray = []
    //     //   if (data) {
    //     //     filterValue &&
    //     //       filterValue.ConsolidatedReportList &&
    //     //       filterValue.ConsolidatedReportList.length &&
    //     //       filterValue.ConsolidatedReportList.map((child, childIndex) => {
    //     //         if (data === child.act_name) {
    //     //           entityArray = {
    //     //             act_name: child.act_name,
    //     //             activity_on: child.activity_on,
    //     //             activity_status: child.activity_status,
    //     //             category_name: child.category_name,
    //     //             completion_date: child.completion_date,
    //     //             compliance_activity_id: child.compliance_activity_id,
    //     //             compliance_description: child.compliance_description,
    //     //             compliance_history_id: child.compliance_history_id,
    //     //             compliance_id: child.compliance_id,
    //     //             compliance_name: child.compliance_name,
    //     //             criticality_name: child.criticality_name,
    //     //             division_name: child.division_name,
    //     //             doc_ref_num: child.doc_ref_num,
    //     //             due_date: child.due_date,
    //     //             extend_due_date: child.extend_due_date,
    //     //             frequency_name: child.frequency_name,
    //     //             history_count: child.history_count,
    //     //             interim_count: child.interim_count,
    //     //             legal_entity_id: child.legal_entity_id,
    //     //             start_date: child.start_date,
    //     //             task_group_name: child.task_group_name,
    //     //             task_status: child.task_status,
    //     //             task_sub_group_name: child.task_sub_group_name,
    //     //             tz_name: child.tz_name,
    //     //             unit: child.unit,
    //     //             unit_address: child.unit_address,
    //     //             unit_id: child.unit_id,
    //     //             uploaded_document: child.uploaded_document,
    //     //             user_name: child.user_name,
    //     //             childId: childIndex,
    //     //             parentId: i,
    //     //           };
    //     //           temp.push(entityArray);
    //     //           console.log(temp, "temptemptemp123")

    //     //         }
    //     //       });

    //     //   }

    //     //   datavalue = {
    //     //     index: i,
    //     //     parent: data,
    //     //     child: temp,
    //     //     group_name: group123,
    //     //   };
    //     //   finalarray.push(datavalue);
    //     // });
    //     // Settitledata(finalarray);
    //   }
    // }
  }, [
    filterValue &&
    filterValue.ConsolidatedReportList &&
    filterValue.ConsolidatedReportList,
    uniqueData,
  ]);

  useEffect(() => {
    if (pageState === true) {
      setIndex(pageSize * (current - 1));
    }
  }, [pageState, pageSize, current]);

  const addSelectedDataValues = (checked, u_id, act_name) => { };

  const homescreen = () => {
    window.location.href = homepage
    document.getElementById('homehover').style.color = "blue"
  };

  const disablecondition = (e) => {
    var currentToDate = moment(consolidatedData.from_date).add(3, "months");
    if (currentToDate < moment(e)) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  const [fullscreen, setfullscreen] = useState(false);
  useEffect(() => {
    if (fullscreen == true) {
      document.addEventListener('fullscreenchange', handleKeyDown, false);
      document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
      return () => {
        document.addEventListener('fullscreenchange', handleKeyDown, false);
        document.removeEventListener('fullscreenchange', handleKeyDown, false);
        document.removeEventListener('webkitfullscreenchange', handleKeyDown);
      };
    }
  }, [fullscreen]);

  const fullscreenMode = () => {
    if (fullscreen == false) {
      fullscreenmode();
      setfullscreen(true);
    } else {
      fullscreenexit();
      setfullscreen(false);
    }
  }

  return (
    <>
      <div className="page-wrapper" id="page-wrapper">
        {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} /> */}
        {tableshow == true ?
          <div className='back-to-top'>
            <ScrollButton />
          </div> : ''}
        {/* style={{ position: 'sticky', zIndex: '1', top: '69px', background: '#f3f3f3' }} */}
        <div className={"page-titles pb-2 pt-1 page-title-sticky"} >
          {tableshow == true ?

            <div className="row">

              <nav
                className="col-lg-6 col-md-6 col-12 align-self-center"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb mb-0 d-flex align-items-center p-0">
                  <li className="breadcrumb-item">
                    <span className="pointer" id='homehover' onClick={homescreen}>
                      <i className="ri-home-3-line fs-5"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item active " aria-current="page">
                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                  </li>
                  <li
                    className="breadcrumb-item active breadcrumsheader"
                    aria-current="page"
                    style={{ fontSize: "15px", fontWeight: "bold" }}
                  >
                    <span>Compliance Reports</span>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    style={{ fontSize: "large", fontWeight: "bold" }}
                  >
                    <span>Consolidated Report</span>
                  </li>
                  {/* <li className="mx-1 mb-2">
                    <Popover
                      style={{ borderRadius: "10px" }}
                      placement="right"
                      content={content}
                      title='Overall Statistics'
                    >
                      {" "}
                      <ExclamationCircleTwoTone
                        style={{ fontSize: "12px" }}
                      />{" "}
                    </Popover>
                  </li> */}
                </ol>
              </nav>


              <div className="col-lg-6 text-end">

                {complianceID.length > 0 ? (
                  <Tooltip title={'Download'}><Button
                    type="primary"
                    shape="round"
                    className="addbutton mx-1"
                    icon={<DownloadOutlined />}
                    // onClick={downloadOnChange}
                    onClick={() => {
                      Activate();
                      // showModal()
                      setDownloadClick(true);
                    }}
                  >
                    Download
                  </Button></Tooltip>
                ) : (
                  false
                )}
                <Tooltip title={'Advance Filter'}>
                  <Button
                    className="mx-1"
                    type="primary"
                    shape="round"
                    style={{
                      display: filterTaskAcc ? "none" : "initial",
                    }}
                    onClick={filterHandle}
                  >
                    <FilterOutlined /> Filter
                  </Button></Tooltip>
                <button onClick={(e) => {
                  fullscreenMode();
                }}
                  className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                  <Tooltip
                    placement="left"
                    title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}
                  >
                    <FullscreenOutlined />
                  </Tooltip>
                </button>

              </div>
            </div> : <div className="row">

              <nav
                className="col-lg-6 col-md-6 col-12 align-self-center"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb mb-0 d-flex align-items-center p-0">
                  <li className="breadcrumb-item">
                    <span className="pointer" id='homehover' onClick={homescreen}>
                      <i className="ri-home-3-line fs-5"></i>
                    </span>
                  </li>
                  <li className="breadcrumb-item active " aria-current="page">
                    <span style={{ fontSize: '14px', fontWeight: "bold" }}>Report</span>
                  </li>
                  <li
                    className="breadcrumb-item active breadcrumsheader"
                    aria-current="page"
                    style={{ fontSize: "15px", fontWeight: "bold" }}
                  >
                    <span>Compliance Reports</span>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                    style={{ fontSize: "large", fontWeight: "bold" }}
                  >
                    <span>Consolidated Report</span>
                  </li>
                  {/* <li className="mx-1 mb-2">
                    <Popover
                      style={{ borderRadius: "10px" }}
                      placement="right"
                      content={content}
                      title='Overall Statistics'
                    >
                      {" "}
                      <ExclamationCircleTwoTone
                        style={{ fontSize: "12px" }}
                      />{" "}
                    </Popover>
                  </li> */}
                </ol>
              </nav>

              <div className="col-lg-6 text-end">

                {complianceID.length > 0 ? (
                  <Button
                    type="primary"
                    shape="round"
                    className="addbutton mx-1"
                    icon={<DownloadOutlined />}
                    // onClick={downloadOnChange}
                    onClick={() => {
                      Activate();
                      // showModal()
                      setDownloadClick(true);
                    }}
                  >
                    Download
                  </Button>
                ) : (
                  false
                )}
                <Button
                  className="mx-1"
                  type="primary"
                  shape="round"
                  style={{
                    display: filterTaskAcc ? "none" : "initial",
                  }}
                  onClick={filterHandle}
                >
                  <FilterOutlined /> Filter
                </Button>
                <button onClick={(e) => {
                  fullscreenMode();
                }} className="bg-transparent text-black ms-1 full-mode fullscreenradius">
                  <Tooltip
                    placement="left"
                    title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}
                  >
                    <FullscreenOutlined />
                  </Tooltip>
                </button>

              </div>
            </div>
          }

        </div>
        <div className={"container-fluid pt-1"}>
          <div className="row">
            <div className="col-12">
              <div
                className={"card-body " + localStorage.getItem("currentTheme")}
                id="only-restricted"
                style={{ position: "relative", padding: "5px" }}
              >
                <Collapse
                  className="report-collapse"
                  accordion
                  defaultActiveKey={["1"]}
                  onChange={onChange}
                  style={{ display: exitCollapse ? "none" : "block" }}
                >
                  <Panel
                    header=" Consolidated Report"
                    key={key}
                  >
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <label>
                            <b>Country : </b>
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {localStorage.getItem("SelectedEntity") ===
                            "All Legal Entity" ? (
                            <div className="form-group">
                              <Select
                                allowClear={true}
                                size="default"
                                placeholder="Enter Country"
                                value={consolidatedData.country || undefined}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                onDrop={(e) => {
                                  e.preventDefault();
                                  // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                  return false;
                                }}
                                onPaste={(e) => {
                                  handlePasting(e, /^[a-z ]+$/gi)
                                }}
                                onChange={(data, value) => {
                                  if (data != undefined) {
                                    let le = _.filter(sessionArr, { c_id: Number(data) })
                                    setEntity(le)
                                    setConsolidatedData({
                                      ...consolidatedData,
                                      country: data,
                                      countryName: value.children,
                                      category: "",
                                      user_type: 0,
                                      legel_entity: "",
                                      unit: "",
                                      user: "",
                                      domain: "",
                                      act: "",
                                      from_date: "",
                                      task_category: "",
                                      compliance_task: "",
                                      to_date: "",
                                      task_sub_category: 0,
                                      compliance_frequency: "",
                                      compliance_task_status: "All",
                                      division: "",
                                      domainName: "",
                                      entityname: "",
                                    });
                                    let entity = _.filter(sessionArr, { c_id: Number(data) })
                                    setEntityData(entity)
                                    Settitledata([])

                                  } else {
                                    setEntity([])
                                    setConsolidatedData({
                                      ...consolidatedData,
                                      country: "",
                                      countryName: "",
                                      category: "",
                                      user_type: 0,
                                      legel_entity: "",
                                      unit: "",
                                      user: "",
                                      domain: "",
                                      act: "",
                                      from_date: "",
                                      task_category: "",
                                      compliance_task: "",
                                      to_date: "",
                                      task_sub_category: 0,
                                      compliance_frequency: "",
                                      compliance_task_status: "All",
                                      division: "",
                                      domainName: "",
                                      entityname: "",
                                    });
                                    setEntityData(sessionArr)
                                    // Settitledata([])
                                  }
                                  setData({
                                    ...data,
                                    country: value.children,
                                  });
                                  setNewData({
                                    ...newdata,
                                    country: value.children
                                  })
                                }}
                                // value={compfie.user_Group}
                                style={{ width: "100%", marginTop: "5px" }}
                              >
                                {uniq_country_data &&
                                  uniq_country_data.length > 0 &&
                                  uniq_country_data.map((item, i) => {
                                    return (
                                      <Option key={item.c_id}>
                                        {item.c_name}
                                      </Option>
                                    );
                                  })}

                              </Select>

                              {validator.current.message(
                                "Country",
                                consolidatedData.country,
                                "required",
                                {
                                  className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                    }`,
                                  messages: {
                                    required: "Country Required",
                                  },
                                }
                              )}
                            </div>
                          ) : (
                            <p>
                              {consolidatedData.countryName}
                            </p>
                          )}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>Legal Entity : </b>
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {localStorage.getItem("SelectedEntity") ===
                            "All Legal Entity" ? (
                            <div className="form-group">
                              <Select
                                disabled={
                                  consolidatedData.country == "" ? true : false
                                }
                                allowClear={true}
                                size="default"
                                placeholder="Select Legal Entity"
                                onDrop={(e) => {
                                  e.preventDefault();
                                  // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                  return false;
                                }}
                                onPaste={(e) => {
                                  handlePasting(e, /^[a-z -]+$/gi)
                                }}
                                onChange={(data, value) => {
                                  // setCurrentEntity(data)
                                  if (data != undefined) {
                                    setConsolidatedData({
                                      ...consolidatedData,
                                      legel_entity: data,
                                      category: "",
                                      user_type: 0,
                                      unit: "",
                                      user: "",
                                      domain: "",
                                      act: "",
                                      from_date: "",
                                      task_category: "",
                                      compliance_task: "",
                                      to_date: "",
                                      task_sub_category: 0,
                                      compliance_frequency: "",
                                      compliance_task_status: "All",
                                      division: "",
                                      domainName: "",
                                      entityname: "",

                                    });
                                    setData({
                                      ...data,
                                      le_name: value.children,
                                    });
                                    setNewData({
                                      ...newdata,
                                      le_name: value.children
                                    })
                                    setCurrentEntity(data);
                                    Settitledata([])

                                  } else {
                                    setConsolidatedData({
                                      ...consolidatedData,
                                      legel_entity: "",
                                      category: "",
                                      user_type: 0,
                                      unit: "",
                                      user: "",
                                      domain: "",
                                      act: "",
                                      from_date: "",
                                      task_category: "",
                                      compliance_task: "",
                                      to_date: "",
                                      task_sub_category: 0,
                                      compliance_frequency: "",
                                      compliance_task_status: "All",
                                      division: "",
                                      domainName: "",
                                      entityname: "",
                                    });

                                    setData({
                                      ...data,
                                      le_name: "",
                                    });
                                    setNewData({
                                      ...newdata,
                                      le_name: ''
                                    })
                                    setCurrentEntity("");
                                    Settitledata([])


                                  }
                                }}
                                value={
                                  consolidatedData.legel_entity || undefined
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                //value={Settings.le_id}
                                style={{ width: "100%", marginTop: "5px" }}
                              >
                                {entity &&
                                  entity.length > 0 &&
                                  entity.map((item, i) => {
                                    return (
                                      <Option key={item.le_id}>
                                        {item.le_name}
                                      </Option>
                                    );
                                  })}
                              </Select>

                              {validator.current.message(
                                "legalEntity",
                                consolidatedData.legel_entity,
                                "required",
                                {
                                  className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                    }`,
                                  messages: {
                                    required: "Legal Entity Required",
                                  },
                                }
                              )}
                            </div>
                          ) : (
                            <p>{localStorage.getItem("SelectedEntity")}</p>
                          )}
                        </div>
                        <div className="col-md-4 ">
                          <label>
                            <b>Domain : </b>
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <Select
                            allowClear={true}
                            // disabled={consolidatedData.country==''?true:false}
                            size="default"
                            placeholder="Enter Domain"
                            value={consolidatedData.domain || undefined}
                            disabled={
                              localStorage.getItem("SelectedEntity") ===
                                "All Legal Entity" &&
                                consolidatedData.legel_entity == ""
                                ? true
                                : false
                            }
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z -]+$/gi)
                            }}
                            onChange={(data, value) => {
                              if (data != undefined) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  domain: data,
                                  domainName: value.children,
                                  category: "",
                                  user_type: 0,
                                  unit: "",
                                  user: "",
                                  act: "",
                                  from_date: "",
                                  task_category: "",
                                  compliance_task: "",
                                  to_date: "",
                                  task_sub_category: 0,
                                  compliance_frequency: "",
                                  compliance_task_status: "All",
                                  division: "",
                                  entityname: "",
                                });

                                let domainData = _.filter(
                                  filterValue &&
                                  filterValue.Data &&
                                  filterValue.Data.task_category_list,
                                  { task_domain_id: Number(data) }
                                );
                                setaskCategory(domainData);
                                Settitledata([])


                              } else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  domain: "",
                                  domainName: "",
                                  category: "",
                                  user_type: 0,
                                  unit: "",
                                  user: "",
                                  act: "",
                                  from_date: "",
                                  task_category: "",
                                  compliance_task: "",
                                  to_date: "",
                                  task_sub_category: 0,
                                  compliance_frequency: "",
                                  compliance_task_status: "All",
                                  division: "",
                                  entityname: "",
                                });
                                Settitledata([])


                              }
                              setData({
                                ...data,
                                domaindata: value.children,
                              });
                              setNewData({
                                ...newdata,
                                domaindata: value.children
                              })
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            {domain &&
                              domain.length > 0 &&
                              domain.map((item, i) => {
                                return (
                                  <Option key={item.d_id}>{item.d_name}</Option>
                                );
                              })}
                          </Select>
                          {validator.current.message(
                            "domain",
                            consolidatedData.domain,
                            "required",
                            {
                              className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                }`,
                              messages: {
                                required: "Domain Required",
                              },
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "5px" }}>
                      <div className="row">
                        <div className="col-md-4">
                          <label>
                            <b>From Date : </b>
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <DatePicker
                            format={"DD-MMM-YYYY"}
                            className={"form-control"}
                            placeholder="DD-MMM-YYYY"
                            style={{ width: "100%", marginTop: "5px" }}
                            allowClear={true}
                            value={
                              consolidatedData.from_date
                                ? moment(consolidatedData.from_date)
                                : ""
                            }
                            // value={consolidatedData.from_date}
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            onKeyDown={(event) => {
                              event.preventDefault()
                            }}
                            onChange={(date, dateString) => {
                              setDisableButton(false);
                              if (consolidatedData.task_category !== '' || consolidatedData.task_sub_category !== 0) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  from_date: dateString ? dateString : '',
                                  to_date: dateString ? moment(dateString).add(7, "years") : '',
                                });
                              }
                              else if (consolidatedData.unit !== '') {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  from_date: dateString ? dateString : '',
                                  to_date: dateString ? moment(dateString).add(1, "year") : '',
                                });
                              }

                              else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  from_date: dateString ? dateString : '',
                                  to_date: dateString ? moment(dateString).add(3, "months") : '',
                                });
                              }

                              // Settitledata([])


                            }}
                          />
                          {validator.current.message(
                            "Fromdate",
                            consolidatedData.from_date,
                            "required",
                            {
                              className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                }`,
                              messages: {
                                required: "From Date Required",
                              },
                            }
                          )}
                          {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>To Date : </b>{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <br />
                          <DatePicker
                            format={"DD-MMM-YYYY"}
                            className={"form-control"}
                            placeholder="DD-MMM-YYYY"
                            allowClear={true}
                            onKeyDown={(event) => {
                              event.preventDefault()
                            }}
                            style={{ width: "100%", marginTop: "5px" }}
                            onChange={(date, dateString) => {
                              dateString && disablecondition(dateString);
                              setConsolidatedData({
                                ...consolidatedData,
                                to_date: dateString ? moment(dateString) : '',
                              });
                              // Settitledata([])


                            }}
                            value={consolidatedData.to_date}
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            disabledDate={(current) => {
                              return (
                                moment(consolidatedData.from_date).add(
                                  1,
                                  "days"
                                ) >= current ||
                                moment(consolidatedData.from_date).add(
                                  1,
                                  "year"
                                ) < current
                              );
                            }}
                          />
                          {validator.current.message(
                            "To-Date",
                            consolidatedData.to_date,
                            "required",
                            {
                              className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                }`,
                              messages: {
                                required: "To Date Required",
                              },
                            }
                          )}
                          {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>Unit : </b>
                          </label>
                          <Select
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            allowClear={true}
                            size="default"
                            placeholder="Enter Unit"
                            value={consolidatedData.unit || undefined}
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-.,]+$/gi)
                            }}
                            onChange={(data) => {
                              if (data != undefined) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  unit: data,
                                  // from_date: '',
                                  // to_date: ''
                                });
                              } else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  unit: "",
                                  // to_date: "",
                                  // from_date: ''
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            {units &&
                              units.length > 0 &&
                              units.map((item, i) => {
                                return (
                                  <Option key={item.unit_id}>
                                    {item.unit_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "5px" }}>
                      <div className="row">
                        <div className="col-md-4">
                          <label>
                            <b>Act : </b>
                          </label>

                          <Select
                            allowClear={true}
                            size="default"
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            value={consolidatedData.act || undefined}
                            placeholder="Enter Act"
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-.,]+$/gi)
                            }}
                            onChange={(data) => {
                              if (data != undefined) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  act: data,
                                });
                              } else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  act: "",
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            {Acts &&
                              Acts.length > 0 &&
                              Acts.map((item, i) => {
                                return (
                                  <Option key={item.parent_id}>
                                    {item.act}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>Category : </b>
                          </label>
                          <Select
                            allowClear={true}
                            size="default"
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            placeholder="Enter Category"
                            value={consolidatedData.category || undefined}
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                            }}
                            onChange={(data) => {
                              if (data != undefined) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  category: data,
                                });
                                let unitData = _.filter(
                                  filterValue &&
                                  filterValue.Data &&
                                  filterValue.Data.units &&
                                  filterValue &&
                                  filterValue.Data &&
                                  filterValue.Data.units.length > 0 &&
                                  filterValue &&
                                  filterValue.Data &&
                                  filterValue.Data.units,
                                  { category_id: Number(data) }
                                );
                                setUnits(unitData);
                              } else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  category: "",
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // value={compfie.user_Group}
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            {category &&
                              category.length > 0 &&
                              category.map((item, i) => {
                                return (
                                  <Option key={item.cat_id}>
                                    {item.cat_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>User Type : </b>
                          </label>
                          <Select
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            defaultValue="All"
                            allowClear={true}
                            size="default"
                            placeholder="Enter Usertype"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                            }}
                            value={consolidatedData.user_type || undefined}
                            onChange={(data) => {
                              if (data != undefined) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  user_type: data,
                                });
                              } else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  user_type: "",
                                });
                              }
                            }}
                            // value={compfie.user_Group}
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            <Option key="0">All</Option>
                            <Option key="1">Assignee</Option>
                            <Option key="2">Concurrence</Option>
                            <Option key="3">Approval</Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "5px" }}>
                      <div className="row">
                        <div className="col-md-4">
                          <label>
                            <b>Task Category : </b>
                          </label>
                          <Select
                            allowClear={true}
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            size="default"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                            }}
                            value={consolidatedData.task_category || undefined}
                            placeholder="Enter Task Category"
                            onChange={(data) => {
                              if (data != undefined) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  task_category: data,
                                  from_date: '',
                                  to_date: '',
                                  task_sub_category: 0
                                });
                                let subData = _.filter(
                                  filterValue &&
                                  filterValue.Data &&
                                  filterValue.Data.task_sub_category_list,
                                  { task_category_id: Number(data) }
                                );
                                setSubtaskCategory(subData);
                              } else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  task_category: "",
                                  from_date: '',
                                  to_date: '',
                                  task_sub_category: 0
                                });
                              }
                            }}
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            {taskCategory &&
                              taskCategory.length > 0 &&
                              taskCategory.map((item, i) => {
                                return (
                                  <Option key={item.task_category_id}>
                                    {item.task_category_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>Compliance Task : </b>
                          </label>

                          <Input
                            placeholder="Enter Compliance Task"
                            style={{ width: "100%", marginTop: "5px" }}
                            onChange={(e) => {
                              setConsolidatedData({
                                ...consolidatedData,
                                compliance_task: e.target.value,
                              });
                            }}
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                            }}
                          />
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>User : </b>
                          </label>
                          <Select
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            allowClear={true}
                            value={consolidatedData.user || undefined}
                            size="default"
                            placeholder="Enter User"
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                            }}
                            onChange={(data) => {
                              if (data != undefined) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  user: data,
                                });
                              } else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  user: "",
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            <Option key="1">Administrator</Option>
                            {userData &&
                              userData.length > 0 &&
                              userData.map((item, i) => {
                                return (
                                  <Option key={item.user_id}>
                                    {item.employee_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "5px" }}>
                      <div className="row">
                        <div className="col-md-4">
                          <label>
                            <b>Task Sub Category : </b>
                          </label>
                          <Select
                            disabled={
                              consolidatedData.task_category == ""
                                ? true
                                : false
                            }
                            value={
                              consolidatedData.task_sub_category || undefined
                            }
                            allowClear={true}
                            defaultValue="All"
                            size="default"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            placeholder="Enter Subcategory"
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                            }}
                            onChange={(data) => {
                              if (data != undefined) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  task_sub_category: data,
                                });
                              } else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  task_sub_category: "",
                                });
                              }
                            }}
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            <Option key={0}>All</Option>
                            {SubtaskCategory &&
                              SubtaskCategory.length > 0 &&
                              SubtaskCategory.map((item, i) => {
                                return (
                                  <Option key={item.task_sub_category_id}>
                                    {item.task_sub_category_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>Compliance Frequency : </b>
                          </label>
                          <Select
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            allowClear={true}
                            value={
                              consolidatedData.compliance_frequency || undefined
                            }
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            size="default"
                            defaultValue={"All"}
                            placeholder="Enter Compliance Frequency"
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                            }}
                            onChange={(data) => {
                              if (data != undefined) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  compliance_frequency: data ? data : '',
                                });
                              } else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  compliance_frequency: "",
                                });
                              }
                            }}
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            <Option key={0}>All</Option>

                            {frequency &&
                              frequency.length > 0 &&
                              frequency.map((item, i) => {
                                return (
                                  <Option key={item.frequency_id}>
                                    {item.frequency}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>Compliance Task Status : </b>
                          </label>
                          <Select
                            allowClear={true}
                            defaultValue="All"
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                            }}
                            onChange={(value) => {
                              setConsolidatedData({
                                ...consolidatedData,
                                compliance_task_status: value ? value : '',
                              });
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            value={
                              consolidatedData.compliance_task_status ||
                              undefined
                            }
                            placeholder="Enter Compliance Task Status"
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            <Option key="All">All</Option>
                            <Option key="Complied">Complied</Option>
                            <Option key="Delayed Compliance">
                              Delayed Compliance
                            </Option>
                            <Option key="In Progress">In Progress</Option>
                            <Option key="Not Complied">Not Complied</Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12" style={{ marginTop: "5px" }}>
                      <div className="row">
                        <div className="col-md-4">
                          <label>
                            <b>Division : </b>
                          </label>
                          <Select
                            allowClear={true}
                            size="default"
                            disabled={
                              consolidatedData.domain == "" ? true : false
                            }
                            value={consolidatedData.division || undefined}
                            placeholder="Enter Division"
                            onDrop={(e) => {
                              e.preventDefault();
                              // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                              return false;
                            }}
                            onPaste={(e) => {
                              handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                            }}
                            onChange={(data) => {
                              if (data != undefined) {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  division: data,
                                });
                                let categorydata = _.filter(
                                  filterValue &&
                                  filterValue.Data &&
                                  filterValue.Data.categories_listtegory,
                                  { div_id: Number(data) }
                                );
                                setCategoryList(categorydata);
                              } else {
                                setConsolidatedData({
                                  ...consolidatedData,
                                  division: "",
                                });
                              }
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ width: "100%", marginTop: "5px" }}
                          >
                            {division &&
                              division.length > 0 &&
                              division.map((item, i) => {
                                return (
                                  <Option key={item.div_id}>
                                    {item.div_name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>
                      </div>
                    </div>
                    <div className="text-center popupbtncolour">
                      {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                      <Button
                        disabled={disableButton}
                        type="primary"
                        shape="round"
                        className={
                          "addbutton " +
                          localStorage.getItem("currentTheme")
                        }

                        onClick={onshow}
                        icon={<EyeOutlined />}
                      >
                        Show
                      </Button>
                      <Button
                        type="primary"
                        shape="round"
                        className="mx-1 addbutton"
                        style={{
                          background: "#32a852",
                          borderColor: "#32a852",
                        }}
                        icon={<ExportOutlined />}
                        onClick={showModal}
                      >
                        Export
                      </Button>
                    </div>
                  </Panel>
                </Collapse>
                <Modal
                  onCancel={setcancelFilter}
                  footer={false}
                  title="Consolidated Report"
                  className={
                    "add-service-prv cons-report " +
                    localStorage.getItem("currentTheme")
                  }
                  visible={filterModalVisible}
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          <b>Country : </b>
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {localStorage.getItem("SelectedEntity") ===
                          "All Legal Entity" ? (
                          <div className="form-group">
                            <Select
                              getPopupContainer={trigger => trigger.parentNode}
                              allowClear={true}
                              size="default"
                              placeholder="Enter Country"
                              value={consolidatedData.country || undefined}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onDrop={(e) => {
                                e.preventDefault();
                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                return false;
                              }}
                              onPaste={(e) => {
                                handlePasting(e, /^[a-z ]+$/gi)
                              }}
                              onChange={(data, value) => {
                                if (data != undefined) {
                                  let le = _.filter(sessionArr, { c_id: Number(data) })
                                  setEntity(le)
                                  setConsolidatedData({
                                    ...consolidatedData,
                                    country: data,
                                    countryName: value.children,
                                    category: "",
                                    user_type: 0,
                                    legel_entity: "",
                                    unit: "",
                                    user: "",
                                    domain: "",
                                    act: "",
                                    from_date: "",
                                    task_category: "",
                                    compliance_task: "",
                                    to_date: "",
                                    task_sub_category: 0,
                                    compliance_frequency: "",
                                    compliance_task_status: "All",
                                    division: "",
                                    domainName: "",
                                    entityname: "",
                                  });
                                  let entity = _.filter(sessionArr, { c_id: Number(data) })
                                  setEntityData(entity)
                                  Settitledata([])

                                } else {
                                  setEntity([])
                                  setConsolidatedData({
                                    ...consolidatedData,
                                    country: "",
                                    countryName: "",
                                    category: "",
                                    user_type: 0,
                                    legel_entity: "",
                                    unit: "",
                                    user: "",
                                    domain: "",
                                    act: "",
                                    from_date: "",
                                    task_category: "",
                                    compliance_task: "",
                                    to_date: "",
                                    task_sub_category: 0,
                                    compliance_frequency: "",
                                    compliance_task_status: "All",
                                    division: "",
                                    domainName: "",
                                    entityname: "",
                                  });

                                  setEntityData(sessionArr)
                                  // Settitledata([])
                                }
                                setData({
                                  ...data,
                                  country: value.children,
                                });
                                setNewData({
                                  ...newdata,
                                  country: value.children
                                })
                              }}
                              // value={compfie.user_Group}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              {uniq_country_data &&
                                uniq_country_data.length > 0 &&
                                uniq_country_data.map((item, i) => {
                                  return (
                                    <Option key={item.c_id}>
                                      {item.c_name}
                                    </Option>
                                  );
                                })}

                            </Select>

                            {validator.current.message(
                              "Country",
                              consolidatedData.country,
                              "required",
                              {
                                className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Country Required",
                                },
                              }
                            )}
                          </div>
                        ) : (
                          <p>
                            {consolidatedData.countryName}
                          </p>
                        )}
                      </div>
                      <div className="col-md-4">
                        <label>
                          <b>Legal Entity : </b>
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {localStorage.getItem("SelectedEntity") ===
                          "All Legal Entity" ? (
                          <div className="form-group">
                            <Select
                              getPopupContainer={trigger => trigger.parentNode}
                              disabled={
                                consolidatedData.country == "" ? true : false
                              }
                              allowClear={true}
                              size="default"
                              placeholder="Select Legal Entity"
                              onDrop={(e) => {
                                e.preventDefault();
                                // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                return false;
                              }}
                              onPaste={(e) => {
                                handlePasting(e, /^[a-z -]+$/gi)
                              }}
                              onChange={(data, value) => {
                                // setCurrentEntity(data)
                                if (data != undefined) {
                                  setConsolidatedData({
                                    ...consolidatedData,
                                    legel_entity: data,
                                    category: "",
                                    user_type: 0,
                                    unit: "",
                                    user: "",
                                    domain: "",
                                    act: "",
                                    from_date: "",
                                    task_category: "",
                                    compliance_task: "",
                                    to_date: "",
                                    task_sub_category: 0,
                                    compliance_frequency: "",
                                    compliance_task_status: "All",
                                    division: "",
                                    domainName: "",
                                    entityname: "",

                                  });
                                  setData({
                                    ...data,
                                    le_name: value.children,
                                  });
                                  setNewData({
                                    ...newdata,
                                    le_name: value.children
                                  })
                                  setCurrentEntity(data);
                                  Settitledata([])

                                } else {
                                  setConsolidatedData({
                                    ...consolidatedData,
                                    legel_entity: "",
                                    category: "",
                                    user_type: 0,
                                    unit: "",
                                    user: "",
                                    domain: "",
                                    act: "",
                                    from_date: "",
                                    task_category: "",
                                    compliance_task: "",
                                    to_date: "",
                                    task_sub_category: 0,
                                    compliance_frequency: "",
                                    compliance_task_status: "All",
                                    division: "",
                                    domainName: "",
                                    entityname: "",
                                  });

                                  setData({
                                    ...data,
                                    le_name: "",
                                  });
                                  setNewData({
                                    ...newdata,
                                    le_name: ''
                                  })
                                  setCurrentEntity("");
                                  Settitledata([])


                                }
                              }}
                              value={
                                consolidatedData.legel_entity || undefined
                              }
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              //value={Settings.le_id}
                              style={{ width: "100%", marginTop: "5px" }}
                            >
                              {entity &&
                                entity.length > 0 &&
                                entity.map((item, i) => {
                                  return (
                                    <Option key={item.le_id}>
                                      {item.le_name}
                                    </Option>
                                  );
                                })}
                            </Select>

                            {validator.current.message(
                              "legalEntity",
                              consolidatedData.legel_entity,
                              "required",
                              {
                                className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Legal Entity Required",
                                },
                              }
                            )}
                          </div>
                        ) : (
                          <p>{localStorage.getItem("SelectedEntity")}</p>
                        )}
                      </div>
                      <div className="col-md-4 ">
                        <label>
                          <b>Domain : </b>
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          allowClear={true}
                          // disabled={consolidatedData.country==''?true:false}
                          size="default"
                          placeholder="Enter Domain"
                          value={consolidatedData.domain || undefined}
                          disabled={
                            localStorage.getItem("SelectedEntity") ===
                              "All Legal Entity" &&
                              consolidatedData.legel_entity == ""
                              ? true
                              : false
                          }
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z -]+$/gi)
                          }}
                          onChange={(data, value) => {
                            if (data != undefined) {
                              setConsolidatedData({
                                ...consolidatedData,
                                domain: data,
                                domainName: value.children,
                                category: "",
                                user_type: 0,
                                unit: "",
                                user: "",
                                act: "",
                                from_date: "",
                                task_category: "",
                                compliance_task: "",
                                to_date: "",
                                task_sub_category: 0,
                                compliance_frequency: "",
                                compliance_task_status: "All",
                                division: "",
                                entityname: "",
                              });

                              let domainData = _.filter(
                                filterValue &&
                                filterValue.Data &&
                                filterValue.Data.task_category_list,
                                { task_domain_id: Number(data) }
                              );
                              setaskCategory(domainData);
                              Settitledata([])


                            } else {
                              setConsolidatedData({
                                ...consolidatedData,
                                domain: "",
                                domainName: "",
                                category: "",
                                user_type: 0,
                                unit: "",
                                user: "",
                                act: "",
                                from_date: "",
                                task_category: "",
                                compliance_task: "",
                                to_date: "",
                                task_sub_category: 0,
                                compliance_frequency: "",
                                compliance_task_status: "All",
                                division: "",
                                entityname: "",
                              });
                              Settitledata([])


                            }
                            setData({
                              ...data,
                              domaindata: value.children,
                            });
                            setNewData({
                              ...newdata,
                              domaindata: value.children
                            })
                          }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          {domain &&
                            domain.length > 0 &&
                            domain.map((item, i) => {
                              return (
                                <Option key={item.d_id}>{item.d_name}</Option>
                              );
                            })}
                        </Select>
                        {validator.current.message(
                          "domain",
                          consolidatedData.domain,
                          "required",
                          {
                            className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                              }`,
                            messages: {
                              required: "Domain Required",
                            },
                          }
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "5px" }}>
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          <b>From Date : </b>
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <DatePicker
                          format={"DD-MMM-YYYY"}
                          getPopupContainer={trigger => trigger.parentNode}
                          className={"form-control"}
                          onKeyDown={(event) => {
                            event.preventDefault()
                          }}
                          // pickerValue={moment(currentdate)}
                          // showToday={true}
                          // defaultValue={moment('23-12-2022').format("DD-MMM-YYYY")}
                          placeholder="DD-MMM-YYYY"
                          style={{ width: "100%", marginTop: "5px" }}
                          allowClear={true}
                          value={
                            consolidatedData.from_date
                              ? moment(consolidatedData.from_date)
                              : ""
                          }
                          // value={ratingAdvanceSearch.updated_from != '' && ratingAdvanceSearch.updated_to != '' ? [moment(ratingAdvanceSearch.updated_from), moment(ratingAdvanceSearch.updated_to)] : ['', '']}
                          // value={consolidatedData.from_date}
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          onChange={(date, dateString) => {
                            setDisableButton(false);

                            if (consolidatedData.task_category !== '' || consolidatedData.task_sub_category !== 0) {
                              setConsolidatedData({
                                ...consolidatedData,
                                from_date: dateString ? dateString : '',
                                to_date: dateString ? moment(dateString).add(7, "years") : '',
                              });
                            }
                            else if (consolidatedData.unit !== '') {
                              setConsolidatedData({
                                ...consolidatedData,
                                from_date: dateString ? dateString : '',
                                to_date: dateString ? moment(dateString).add(1, "year") : '',
                              });
                            }
                            else {
                              setConsolidatedData({
                                ...consolidatedData,
                                from_date: dateString ? dateString : '',
                                to_date: dateString ? moment(dateString).add(3, "months") : '',
                              });
                            }

                            // Settitledata([])


                          }}
                        // getPopupContainer={trigger => trigger.parentNode}
                        />
                        {validator.current.message(
                          "Fromdate",
                          consolidatedData.from_date,
                          "required",
                          {
                            className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                              }`,
                            messages: {
                              required: "From Date Required",
                            },
                          }
                        )}
                        {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                      </div>
                      <div className="col-md-4">
                        <label>
                          <b>To Date : </b>{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <br />
                        <DatePicker
                          format={"DD-MMM-YYYY"}
                          getPopupContainer={trigger => trigger.parentNode}
                          className={"form-control"}
                          placeholder="DD-MMM-YYYY"
                          allowClear={true}
                          onKeyDown={(event) => {
                            event.preventDefault()
                          }}
                          style={{ width: "100%", marginTop: "5px" }}
                          onChange={(date, dateString) => {
                            dateString && disablecondition(dateString);
                            setConsolidatedData({
                              ...consolidatedData,
                              to_date: dateString ? moment(dateString) : '',
                            });
                            // Settitledata([])


                          }}
                          value={consolidatedData.to_date}
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          disabledDate={(current) => {
                            return (
                              moment(consolidatedData.from_date).add(
                                1,
                                "days"
                              ) >= current ||
                              moment(consolidatedData.from_date).add(
                                1,
                                "year"
                              ) < current
                            );
                          }}
                        />
                        {validator.current.message(
                          "To-Date",
                          consolidatedData.to_date,
                          "required",
                          {
                            className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                              }`,
                            messages: {
                              required: "To Date Required",
                            },
                          }
                        )}
                        {/* <input type="date" className='form-control' style={{ width: '300px' }} /> */}
                      </div>
                      <div className="col-md-4">
                        <label>
                          <b>Unit : </b>
                        </label>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          allowClear={true}
                          size="default"
                          placeholder="Enter Unit"
                          value={consolidatedData.unit || undefined}
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-.,]+$/gi)
                          }}
                          onChange={(data) => {
                            if (data != undefined) {
                              setConsolidatedData({
                                ...consolidatedData,
                                unit: data,
                                // from_date: '',
                                // to_date: ''
                              });
                            } else {
                              setConsolidatedData({
                                ...consolidatedData,
                                unit: "",
                                // to_date: "",
                                // from_date: ''
                              });
                            }
                          }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          {units &&
                            units.length > 0 &&
                            units.map((item, i) => {
                              return (
                                <Option key={item.unit_id}>
                                  {item.unit_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "5px" }}>
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          <b>Act : </b>
                        </label>

                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          allowClear={true}
                          size="default"
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          value={consolidatedData.act || undefined}
                          placeholder="Enter Act"
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-.,]+$/gi)
                          }}
                          onChange={(data) => {
                            if (data != undefined) {
                              setConsolidatedData({
                                ...consolidatedData,
                                act: data,
                              });
                            } else {
                              setConsolidatedData({
                                ...consolidatedData,
                                act: "",
                              });
                            }
                          }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          {Acts &&
                            Acts.length > 0 &&
                            Acts.map((item, i) => {
                              return (
                                <Option key={item.parent_id}>
                                  {item.act}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="col-md-4">
                        <label>
                          <b>Category : </b>
                        </label>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          allowClear={true}
                          size="default"
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          placeholder="Enter Category"
                          value={consolidatedData.category || undefined}
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                          }}
                          onChange={(data) => {
                            if (data != undefined) {
                              setConsolidatedData({
                                ...consolidatedData,
                                category: data,
                              });
                              let unitData = _.filter(
                                filterValue &&
                                filterValue.Data &&
                                filterValue.Data.units &&
                                filterValue &&
                                filterValue.Data &&
                                filterValue.Data.units.length > 0 &&
                                filterValue &&
                                filterValue.Data &&
                                filterValue.Data.units,
                                { category_id: Number(data) }
                              );
                              setUnits(unitData);
                            } else {
                              setConsolidatedData({
                                ...consolidatedData,
                                category: "",
                              });
                            }
                          }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          // value={compfie.user_Group}
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          {category &&
                            category.length > 0 &&
                            category.map((item, i) => {
                              return (
                                <Option key={item.cat_id}>
                                  {item.cat_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="col-md-4">
                        <label>
                          <b>User Type : </b>
                        </label>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          defaultValue="All"
                          allowClear={true}
                          size="default"
                          placeholder="Enter Usertype"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                          }}
                          value={consolidatedData.user_type || undefined}
                          onChange={(data) => {
                            if (data != undefined) {
                              setConsolidatedData({
                                ...consolidatedData,
                                user_type: data,
                              });
                            } else {
                              setConsolidatedData({
                                ...consolidatedData,
                                user_type: "",
                              });
                            }
                          }}
                          // value={compfie.user_Group}
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          <Option key="0">All</Option>
                          <Option key="1">Assignee</Option>
                          <Option key="2">Concurrence</Option>
                          <Option key="3">Approval</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "5px" }}>
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          <b>Task Category : </b>
                        </label>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          allowClear={true}
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          size="default"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                          }}
                          value={consolidatedData.task_category || undefined}
                          placeholder="Enter Task Category"
                          onChange={(data) => {
                            if (data != undefined) {
                              setConsolidatedData({
                                ...consolidatedData,
                                task_category: data,
                                from_date: '',
                                to_date: '',
                                task_sub_category: 0
                              });
                              let subData = _.filter(
                                filterValue &&
                                filterValue.Data &&
                                filterValue.Data.task_sub_category_list,
                                { task_category_id: Number(data) }
                              );
                              setSubtaskCategory(subData);
                            } else {
                              setConsolidatedData({
                                ...consolidatedData,
                                task_category: "",
                                from_date: '',
                                to_date: '',
                                task_sub_category: 0
                              });
                            }
                          }}
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          {taskCategory &&
                            taskCategory.length > 0 &&
                            taskCategory.map((item, i) => {
                              return (
                                <Option key={item.task_category_id}>
                                  {item.task_category_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="col-md-4">
                        <label>
                          <b>Compliance Task : </b>
                        </label>

                        <Input
                          placeholder="Enter Compliance Task"
                          style={{ width: "100%", marginTop: "5px" }}
                          onChange={(e) => {
                            setConsolidatedData({
                              ...consolidatedData,
                              compliance_task: e.target.value,
                            });
                          }}
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>
                          <b>User : </b>
                        </label>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          allowClear={true}
                          value={consolidatedData.user || undefined}
                          size="default"
                          placeholder="Enter User"
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                          }}
                          onChange={(data) => {
                            if (data != undefined) {
                              setConsolidatedData({
                                ...consolidatedData,
                                user: data,
                              });
                            } else {
                              setConsolidatedData({
                                ...consolidatedData,
                                user: "",
                              });
                            }
                          }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          <Option key="1">Administrator</Option>
                          {userData &&
                            userData.length > 0 &&
                            userData.map((item, i) => {
                              return (
                                <Option key={item.user_id}>
                                  {item.employee_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "5px" }}>
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          <b>Task Sub Category : </b>
                        </label>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          disabled={
                            consolidatedData.task_category == ""
                              ? true
                              : false
                          }
                          value={
                            consolidatedData.task_sub_category || undefined
                          }
                          allowClear={true}
                          defaultValue="All"
                          size="default"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Enter Subcategory"
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                          }}
                          onChange={(data) => {
                            if (data != undefined) {
                              setConsolidatedData({
                                ...consolidatedData,
                                task_sub_category: data,
                              });
                            } else {
                              setConsolidatedData({
                                ...consolidatedData,
                                task_sub_category: "",
                              });
                            }
                          }}
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          <Option key={0}>All</Option>
                          {SubtaskCategory &&
                            SubtaskCategory.length > 0 &&
                            SubtaskCategory.map((item, i) => {
                              return (
                                <Option key={item.task_sub_category_id}>
                                  {item.task_sub_category_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="col-md-4">
                        <label>
                          <b>Compliance Frequency : </b>
                        </label>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          allowClear={true}
                          value={
                            consolidatedData.compliance_frequency || undefined
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          size="default"
                          defaultValue={"All"}
                          placeholder="Enter Compliance Frequency"
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                          }}
                          onChange={(data) => {
                            if (data != undefined) {
                              setConsolidatedData({
                                ...consolidatedData,
                                compliance_frequency: data ? data : '',
                              });
                            } else {
                              setConsolidatedData({
                                ...consolidatedData,
                                compliance_frequency: "",
                              });
                            }
                          }}
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          <Option key={0}>All</Option>

                          {frequency &&
                            frequency.length > 0 &&
                            frequency.map((item, i) => {
                              return (
                                <Option key={item.frequency_id}>
                                  {item.frequency}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="col-md-4">
                        <label>
                          <b>Compliance Task Status : </b>
                        </label>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          allowClear={true}
                          defaultValue="All"
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                          }}
                          onChange={(value) => {
                            setConsolidatedData({
                              ...consolidatedData,
                              compliance_task_status: value ? value : '',
                            });
                          }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          value={
                            consolidatedData.compliance_task_status ||
                            undefined
                          }
                          placeholder="Enter Compliance Task Status"
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          <Option key="All">All</Option>
                          <Option key="Complied">Complied</Option>
                          <Option key="Delayed Compliance">
                            Delayed Compliance
                          </Option>
                          <Option key="In Progress">In Progress</Option>
                          <Option key="Not Complied">Not Complied</Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: "5px" }}>
                    <div className="row">
                      <div className="col-md-4">
                        <label>
                          <b>Division : </b>
                        </label>
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          allowClear={true}
                          size="default"
                          disabled={
                            consolidatedData.domain == "" ? true : false
                          }
                          value={consolidatedData.division || undefined}
                          placeholder="Enter Division"
                          onDrop={(e) => {
                            e.preventDefault();
                            // Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                            return false;
                          }}
                          onPaste={(e) => {
                            handlePasting(e, /^[a-z 0-9-().,]+$/gi)
                          }}
                          onChange={(data) => {
                            if (data != undefined) {
                              setConsolidatedData({
                                ...consolidatedData,
                                division: data,
                              });
                              let categorydata = _.filter(
                                filterValue &&
                                filterValue.Data &&
                                filterValue.Data.categories_listtegory,
                                { div_id: Number(data) }
                              );
                              setCategoryList(categorydata);
                            } else {
                              setConsolidatedData({
                                ...consolidatedData,
                                division: "",
                              });
                            }
                          }}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ width: "100%", marginTop: "5px" }}
                        >
                          {division &&
                            division.length > 0 &&
                            division.map((item, i) => {
                              return (
                                <Option key={item.div_id}>
                                  {item.div_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                  <div className="text-center popupbtncolour">
                    {/* <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                <button className="btn btn-success" style={{ width: "100px" }} >Export</button> */}
                    <Button
                      disabled={disableButton}
                      type="primary"
                      shape="round"
                      className={
                        "addbutton " +
                        localStorage.getItem("currentTheme")
                      }

                      onClick={onshow}
                      icon={<EyeOutlined />}
                    >
                      Show
                    </Button>
                    <Button
                      type="primary"
                      shape="round"
                      className="mx-1 addbutton"
                      style={{
                        background: "#32a852",
                        borderColor: "#32a852",
                      }}
                      icon={<ExportOutlined />}
                      onClick={showModal}
                    >
                      Export
                    </Button>
                  </div>
                </Modal>
                {tableshow == true ? (
                  <Card className="rounded-3">
                    {/* style={{ position: 'sticky', zIndex: '1', top: '113px', paddingBlock: '10px', background: 'white' }} */}
                    <div className="col-md-12 mt-0 mb-2 filter-detial-sticky" >

                      <div className="row" >
                        <div className="col-md-4">
                          <label>
                            <b>Legal Entity :</b>{" "}
                            {localStorage.getItem("SelectedEntity") ===
                              "All Legal Entity"
                              ? newdata.le_name
                              : localStorage.getItem("SelectedEntity")}
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>Country :</b>{" "}
                            {localStorage.getItem("SelectedEntity") ===
                              "All Legal Entity"
                              ? newdata.country
                              : consolidatedData.countryName}
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label>
                            <b>Domain :</b> {newdata.domaindata}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* {filteredArrayState.length > 0 ? */}
                    <div>
                      <div
                        className={
                          localStorage.getItem("currentTheme") +
                          " ant-table-wrapper"
                        }
                      >
                        <div className="ant-table ant-table-bordered ant-table-fixed-header">
                          <div className="ant-table-container">
                            <div className="ant-table-content  userprivclass1">
                              <table>
                                <colgroup>
                                  <col style={{ width: "10px" }}></col>
                                </colgroup>
                                {/*  style={{ position: 'sticky', zIndex: '1', top: '154px', background: 'white' }} */}
                                <thead className="ant-table-thead table-head-stick">
                                  <tr>
                                    {filterValue.ConsolidatedReportList
                                      .length == 0 ? (
                                      ""
                                    ) : (
                                      <th
                                        className={
                                          fullscreen == false
                                            ? "ant-table-cell"
                                            : "fullscreenclass ant-table-cell"
                                        }
                                        style={{ width: "10px" }}
                                      >
                                        {" "}
                                        <input
                                          type="checkbox"
                                          className="checkboxall"
                                          checked={isCheckedAll}
                                          // checked={((complianceID.length) == parseInt(filterValue && filterValue.total_history_ids.length))}
                                          // checked={filterValue.ConsolidatedReportList.length == complianceID.length? true : false}
                                          onChange={(e) => {
                                            downloadselectAllCheckBox(
                                              e.target.checked
                                            );
                                          }}
                                        />
                                        {" "}
                                      </th>
                                    )}
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "10px" }}
                                    >
                                      {" "}
                                      #{" "}
                                    </th>
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "300px" }}
                                    >
                                      Compliance Task
                                    </th>
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "50px" }}
                                    >
                                      Criticality
                                    </th>
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "90px" }}
                                    >
                                      Frequency
                                    </th>
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "150px" }}
                                    >
                                      <div>
                                        Due Date
                                      </div>
                                    </th>
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "120px" }}
                                    >
                                      Compliance Task Status
                                    </th>
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "100px" }}
                                    >
                                      User Name
                                    </th>
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "120px" }}
                                    >
                                      Activity Status
                                    </th>
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "120px" }}
                                    >
                                      Activity Date
                                    </th>
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "100px" }}
                                    >
                                      Uploaded Document
                                    </th>
                                    <th
                                      className={
                                        fullscreen == false
                                          ? "ant-table-cell"
                                          : "fullscreenclass ant-table-cell"
                                      }
                                      style={{ width: "120px" }}
                                    >
                                      <Tooltip title="Task Completion Date/Document issued Date">
                                        Date
                                      </Tooltip>
                                    </th>
                                  </tr>
                                </thead>
                                {filteredArrayState &&
                                  filteredArrayState.length > 0 ? (
                                  filteredArrayState.map((item, i) => {
                                    return (
                                      <tbody
                                        className="ant-table-tbody "
                                        key={i}
                                      >
                                        <>
                                          <tr className="bg-lightdark">
                                            <td
                                              style={{
                                                paddingLeft: "0 !important",
                                                paddingRight: "0 !important",
                                              }}
                                              colSpan="100%"
                                            >
                                              <div className="bg-white shadow-sm border-radius-reports">
                                                <div className="col-md-12">
                                                  <div className="row m-2">
                                                    <div className="col-md-3">
                                                      <label>
                                                        <b>Unit :</b>{" "}
                                                        {item[0].unit}
                                                      </label>
                                                    </div>
                                                    <div className="col-md-3">
                                                      <label>
                                                        <b>Division :</b>{" "}
                                                        {item[0].division_name}
                                                      </label>
                                                    </div>
                                                    <div className="col-md-3">
                                                      <label>
                                                        <b>Category :</b>{" "}
                                                        {item[0].category_name}
                                                      </label>
                                                    </div>
                                                    <div className="col-md-3">
                                                      <label>
                                                        <b>Time Zone :</b>{" "}
                                                        {item[0].tz_name}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                          {titledata &&
                                            titledata.map((itemdata, i) => {
                                              console.log(itemdata.child2, 'itemdata.child2');

                                              let data = _.find(
                                                itemdata.child,
                                                { unit_id: item[0].unit_id }
                                              )
                                              let data1 = _.find(
                                                itemdata.child2,
                                                { unit_id: item[0].unit_id }
                                              )
                                              if (data != undefined) {
                                                if (data.act_name == itemdata.parent) {
                                                  return (
                                                    <Fragment key={i}>
                                                      <tr className="ant-table-row ant-table-row-level-0">
                                                        <td
                                                          colSpan={12}
                                                          className="ant-table-cell"
                                                        >
                                                          <b>
                                                            {itemdata.parent}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                      {itemdata.child.length > 0 ?
                                                        itemdata.child.map(
                                                          (items) => {
                                                            if (
                                                              item[0].unit_id ==
                                                              items.unit_id
                                                            ) {
                                                              let statusArray1 = _.filter(filterValue
                                                                .ConsolidatedReportList, { compliance_history_id: items.compliance_history_id })
                                                              let statusArray = _.reject(statusArray1, { compliance_activity_id: items.compliance_activity_id })
                                                              let id = statusArray && statusArray.length > 0 && statusArray.map((itemns) => {
                                                                return itemns.compliance_history_id
                                                              })

                                                              console.log(statusArray, 'statusArray');
                                                              {/* let array = [];

                                                          for (let i in filterValue &&
                                                            filterValue.ConsolidatedReportList &&
                                                            filterValue
                                                              .ConsolidatedReportList
                                                              .length &&
                                                            filterValue.ConsolidatedReportList) {
                                                            if (
                                                              filterValue &&
                                                              filterValue.ConsolidatedReportList &&
                                                              filterValue
                                                                .ConsolidatedReportList
                                                                .length &&
                                                              filterValue
                                                                .ConsolidatedReportList[
                                                                i
                                                              ]
                                                                .compliance_id ==
                                                              items.compliance_id
                                                            ) {
                                                              if (
                                                                filterValue &&
                                                                filterValue.ConsolidatedReportList &&
                                                                filterValue
                                                                  .ConsolidatedReportList
                                                                  .length &&
                                                                filterValue
                                                                  .ConsolidatedReportList[
                                                                  i
                                                                ].unit_id ==
                                                                items.unit_id
                                                              ) {
                                                                array.push(
                                                                  filterValue &&
                                                                  filterValue.ConsolidatedReportList &&
                                                                  filterValue
                                                                    .ConsolidatedReportList
                                                                    .length &&
                                                                  filterValue
                                                                    .ConsolidatedReportList[
                                                                  i
                                                                  ]
                                                                );
                                                              }
                                                            }
                                                          }*/}
                                                              return (
                                                                <tr className="ant-table-row ant-table-row-level-0">
                                                                  <td className="ant-table-cell">
                                                                    {" "}
                                                                    <center>
                                                                      <input
                                                                        type="checkbox"
                                                                        name="checked"
                                                                        className={items.compliance_history_id}
                                                                        checked={isChecked[items.compliance_history_id]}
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          downloadcheckBoxOnChange(
                                                                            e,
                                                                            items
                                                                          );
                                                                        }}
                                                                      />
                                                                    </center>
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    <center>
                                                                      {index + 1}
                                                                    </center>
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    <Tooltip placement="bottomRight"
                                                                      title={
                                                                        items.compliance_description
                                                                      }
                                                                    >
                                                                      <ExclamationCircleTwoTone />
                                                                    </Tooltip>{" "}
                                                                    &nbsp;
                                                                    {id.length >
                                                                      0 && id.includes(items.compliance_history_id) ? (
                                                                      <a
                                                                        href="javascript:;"
                                                                        onClick={() => {
                                                                          chilData(
                                                                            statusArray
                                                                          );
                                                                        }}
                                                                      >
                                                                        {
                                                                          items.compliance_name
                                                                        }
                                                                      </a>
                                                                    ) : (
                                                                      <a>
                                                                        {
                                                                          items.compliance_name
                                                                        }
                                                                      </a>
                                                                    )}
                                                                    {/* {
                                                                      items.compliance_name
                                                                    } */}
                                                                  </td>
                                                                  {/* <td title={items.compliance_name} className='ant-table-cell'>{items.compliance_name}</td> */}
                                                                  <td className="ant-table-cell">
                                                                    <center>
                                                                      {
                                                                        items.criticality_name
                                                                      }
                                                                    </center>
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    {
                                                                      items.frequency_name
                                                                    }
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    <center>
                                                                      {
                                                                        items.due_date
                                                                      }
                                                                    </center>
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    {
                                                                      items.task_status
                                                                    }
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    {
                                                                      items.user_name
                                                                    }
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    {items.history_count > 0 ?
                                                                      <>
                                                                        <Tooltip
                                                                          title={
                                                                            "Click to view Remarks History"
                                                                          }
                                                                        >
                                                                          <ExclamationCircleTwoTone
                                                                            onClick={() => {
                                                                              const payload = [
                                                                                authtoken,
                                                                                {
                                                                                  session_token: authtoken,
                                                                                  request: [
                                                                                    "GetComplianceRemarksHistoryData",
                                                                                    {
                                                                                      "legal_entity_id": entityid,
                                                                                      "unit_id": items.unit_id,
                                                                                      "compliance_history_id": items.compliance_history_id
                                                                                    }
                                                                                  ]
                                                                                },
                                                                              ];
                                                                              console.log(items, 'items');
                                                                              console.log(payload, 'payloadddddd');
                                                                              getHistoryRemarks({
                                                                                payload: payload,
                                                                                paramid: paramid,
                                                                              });
                                                                              setRemarksModal(true)
                                                                            }
                                                                            }

                                                                          // remarksHistory(items.le_id, items.compliance_history_id, items.unit_id)}
                                                                          />
                                                                        </Tooltip>{" "}
                                                                        &nbsp;{
                                                                          items.activity_status
                                                                        }
                                                                      </>
                                                                      :
                                                                      <span>{items.activity_status}</span>
                                                                    }
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    <center>
                                                                      {
                                                                        items.activity_on
                                                                      }
                                                                    </center>
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    {
                                                                      items.uploaded_document
                                                                    }
                                                                  </td>
                                                                  <td className="ant-table-cell border-less">
                                                                    <center>
                                                                      {
                                                                        items.completion_date
                                                                      }
                                                                    </center>
                                                                  </td>
                                                                  {/* </tr> */}
                                                                  <span hidden>
                                                                    {
                                                                      (index =
                                                                        index + 1)
                                                                    }
                                                                  </span>
                                                                </tr>
                                                              );
                                                            }
                                                          }
                                                        ) : ''}
                                                      {itemdata.child2 && itemdata.child2.length > 0 ?

                                                        <tr className="ant-table-row ant-table-row-level-0">
                                                          <td
                                                            colSpan={12}
                                                            className="ant-table-cell"
                                                          >
                                                            <div className="col-md-12">
                                                              <div className="row">
                                                                <div className="col-md-6">
                                                                  <b>
                                                                    {itemdata.parent}
                                                                  </b>
                                                                </div>
                                                                <div className="col-md-3">
                                                                  <b>{data1 && data1.task_group_name}</b>
                                                                </div>
                                                                <div className="col-md-3">
                                                                  <b>{data1 && data1.task_sub_group_name}</b>
                                                                </div>
                                                              </div>
                                                            </div>





                                                          </td>
                                                        </tr>
                                                        : false}


                                                      {itemdata.child2 && itemdata.child2.map(
                                                        (items) => {
                                                          if (
                                                            item[0].unit_id ==
                                                            items.unit_id
                                                          ) {
                                                            let statusArray1 = _.filter(filterValue
                                                              .ConsolidatedReportList, { compliance_history_id: items.compliance_history_id })
                                                            let statusArray = _.reject(statusArray1, { compliance_activity_id: items.compliance_activity_id })
                                                            let id = statusArray && statusArray.length > 0 && statusArray.map((itemns) => {
                                                              return itemns.compliance_history_id
                                                            })
                                                            {/* let array = [];

                                                            for (let i in filterValue &&
                                                              filterValue.ConsolidatedReportList &&
                                                              filterValue
                                                                .ConsolidatedReportList
                                                                .length &&
                                                              filterValue.ConsolidatedReportList) {
                                                              if (
                                                                filterValue &&
                                                                filterValue.ConsolidatedReportList &&
                                                                filterValue
                                                                  .ConsolidatedReportList
                                                                  .length &&
                                                                filterValue
                                                                  .ConsolidatedReportList[
                                                                  i
                                                                ]
                                                                  .compliance_id ==
                                                                items.compliance_id
                                                              ) {
                                                                if (
                                                                  filterValue &&
                                                                  filterValue.ConsolidatedReportList &&
                                                                  filterValue
                                                                    .ConsolidatedReportList
                                                                    .length &&
                                                                  filterValue
                                                                    .ConsolidatedReportList[
                                                                    i
                                                                  ].unit_id ==
                                                                  items.unit_id
                                                                ) {
                                                                  array.push(
                                                                    filterValue &&
                                                                    filterValue.ConsolidatedReportList &&
                                                                    filterValue
                                                                      .ConsolidatedReportList
                                                                      .length &&
                                                                    filterValue
                                                                      .ConsolidatedReportList[
                                                                    i
                                                                    ]
                                                                  );
                                                                }
                                                              }
                                                            }*/}
                                                            return (
                                                              <>

                                                                <tr className="ant-table-row ant-table-row-level-0">
                                                                  <td className="ant-table-cell">
                                                                    {" "}
                                                                    <center>
                                                                      <input
                                                                        type="checkbox"
                                                                        name="checked"
                                                                        className={items.compliance_history_id}
                                                                        checked={isChecked[items.compliance_history_id]}
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          downloadcheckBoxOnChange(
                                                                            e,
                                                                            items
                                                                          );
                                                                        }}
                                                                      />
                                                                    </center>
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    <center>
                                                                      {index + 1}
                                                                    </center>
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    <Tooltip placement="bottomRight"
                                                                      title={
                                                                        items.compliance_description
                                                                      }
                                                                    >
                                                                      <ExclamationCircleTwoTone />
                                                                    </Tooltip>{" "}
                                                                    &nbsp;
                                                                    {id && id.length >
                                                                      0 && id.includes(items.compliance_history_id) ? (
                                                                      <a
                                                                        href="javascript:;"
                                                                        onClick={() => {
                                                                          chilData(
                                                                            statusArray)
                                                                        }}
                                                                      >
                                                                        {
                                                                          items.compliance_name
                                                                        }
                                                                      </a>
                                                                    ) : (
                                                                      <a>
                                                                        {
                                                                          items.compliance_name
                                                                        }
                                                                      </a>
                                                                    )}
                                                                    {/* {
                                                                      items.compliance_name
                                                                    } */}
                                                                  </td>
                                                                  {/* <td title={items.compliance_name} className='ant-table-cell'>{items.compliance_name}</td> */}
                                                                  <td className="ant-table-cell">
                                                                    <center>
                                                                      {
                                                                        items.criticality_name
                                                                      }
                                                                    </center>
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    {
                                                                      items.frequency_name
                                                                    }
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    <center>
                                                                      {
                                                                        items.due_date
                                                                      }
                                                                    </center>
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    {
                                                                      items.task_status
                                                                    }
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    {
                                                                      items.user_name
                                                                    }
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    {items.history_count > 0 ?
                                                                      <>
                                                                        <Tooltip
                                                                          title={
                                                                            "Click to view Remarks History"
                                                                          }
                                                                        >
                                                                          <ExclamationCircleTwoTone
                                                                            onClick={() => {
                                                                              const payload = [
                                                                                authtoken,
                                                                                {
                                                                                  session_token: authtoken,
                                                                                  request: [
                                                                                    "GetComplianceRemarksHistoryData",
                                                                                    {
                                                                                      "legal_entity_id": entityid,
                                                                                      "unit_id": items.unit_id,
                                                                                      "compliance_history_id": items.compliance_history_id
                                                                                    }
                                                                                  ]
                                                                                },
                                                                              ];
                                                                              console.log(items, 'items');
                                                                              console.log(payload, 'payloadddddd');
                                                                              getHistoryRemarks({
                                                                                payload: payload,
                                                                                paramid: paramid,
                                                                              });
                                                                              setRemarksModal(true)
                                                                            }
                                                                            }
                                                                          // onClick={remarksHistory(items.le_id, items.compliance_history_id, items.unit_id)} 
                                                                          />
                                                                        </Tooltip>{" "}
                                                                        &nbsp;{
                                                                          items.activity_status
                                                                        }
                                                                      </>
                                                                      :
                                                                      <span>{items.activity_status}</span>
                                                                    }
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    <center>
                                                                      {
                                                                        items.activity_on
                                                                      }
                                                                    </center>
                                                                  </td>
                                                                  <td className="ant-table-cell">
                                                                    {
                                                                      items.uploaded_document
                                                                    }
                                                                  </td>
                                                                  <td className="ant-table-cell border-less">
                                                                    <center>
                                                                      {
                                                                        items.completion_date
                                                                      }
                                                                    </center>
                                                                  </td>
                                                                  {/* </tr> */}
                                                                  <span hidden>
                                                                    {
                                                                      (index =
                                                                        index + 1)
                                                                    }
                                                                  </span>
                                                                </tr>
                                                              </>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                    </Fragment>
                                                  );
                                                }
                                              }
                                              else {
                                                if (data1 != undefined) {
                                                  if (data1.act_name == itemdata.parent) {
                                                    return (
                                                      <Fragment key={i}>
                                                        <tr className="ant-table-row ant-table-row-level-0">
                                                          <td
                                                            colSpan={12}
                                                            className="ant-table-cell"
                                                          >
                                                            <div className="col-md-12">
                                                              <div className="row">
                                                                <div className="col-md-6">
                                                                  <b>
                                                                    {itemdata.parent}
                                                                  </b>
                                                                </div>
                                                                <div className="col-md-3">
                                                                  <b>{data1 && data1.task_group_name}</b>
                                                                </div>
                                                                <div className="col-md-3">
                                                                  <b style={{ marginLeft: '5%' }}>{data1 && data1.task_sub_group_name}</b>
                                                                </div>
                                                              </div>
                                                            </div>




                                                          </td>
                                                          {/* <td></td> */}
                                                        </tr>
                                                        {itemdata.child2 && itemdata.child2.length > 0 && itemdata.child2.map(
                                                          (items) => {
                                                            if (
                                                              item[0].unit_id ==
                                                              items.unit_id
                                                            ) {


                                                              let statusArray1 = _.filter(filterValue
                                                                .ConsolidatedReportList, { compliance_history_id: items.compliance_history_id })
                                                              let statusArray = _.reject(statusArray1, { compliance_activity_id: items.compliance_activity_id })
                                                              let id = statusArray && statusArray.length > 0 && statusArray.map((itemns) => {
                                                                return itemns.compliance_history_id
                                                              })
                                                              {/* let array = [];

                for (let i in filterValue &&
                  filterValue.ConsolidatedReportList &&
                  filterValue
                    .ConsolidatedReportList
                    .length &&
                  filterValue.ConsolidatedReportList) {
                  if (
                    filterValue &&
                    filterValue.ConsolidatedReportList &&
                    filterValue
                      .ConsolidatedReportList
                      .length &&
                    filterValue
                      .ConsolidatedReportList[
                      i
                    ]
                      .compliance_id ==
                    items.compliance_id
                  ) {
                    if (
                      filterValue &&
                      filterValue.ConsolidatedReportList &&
                      filterValue
                        .ConsolidatedReportList
                        .length &&
                      filterValue
                        .ConsolidatedReportList[
                        i
                      ].unit_id ==
                      items.unit_id
                    ) {
                      array.push(
                        filterValue &&
                        filterValue.ConsolidatedReportList &&
                        filterValue
                          .ConsolidatedReportList
                          .length &&
                        filterValue
                          .ConsolidatedReportList[
                        i
                        ]
                      );
                    }
                  }
                }*/}
                                                              return (
                                                                <>
                                                                  {/* <tr className="ant-table-row ant-table-row-level-0">
                                                                    <td
                                                                      colSpan={12}
                                                                      className="ant-table-cell"
                                                                    >
                                                                      <b>
                                                                        {itemdata.parent}
                                                                      </b>
                                                                    </td>
                                                                  </tr> */}
                                                                  <tr className="ant-table-row ant-table-row-level-0">
                                                                    <td className="ant-table-cell">
                                                                      {" "}
                                                                      <center>
                                                                        <input
                                                                          type="checkbox"
                                                                          name="checked"
                                                                          className={items.compliance_history_id}
                                                                          checked={isChecked[items.compliance_history_id]}
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            downloadcheckBoxOnChange(
                                                                              e,
                                                                              items
                                                                            );
                                                                          }}
                                                                        />
                                                                      </center>
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                      <center>
                                                                        {index + 1}
                                                                      </center>
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                      <Tooltip placement="bottomRight"
                                                                        title={
                                                                          items.compliance_description
                                                                        }
                                                                      >
                                                                        <ExclamationCircleTwoTone />
                                                                      </Tooltip>{" "}
                                                                      &nbsp;
                                                                      {id && id.length >
                                                                        0 && id.includes(items.compliance_history_id) ? (
                                                                        <a
                                                                          href="javascript:;"
                                                                          onClick={() => {
                                                                            chilData(
                                                                              statusArray
                                                                            );
                                                                          }}
                                                                        >
                                                                          {
                                                                            items.compliance_name
                                                                          }
                                                                        </a>
                                                                      ) : (
                                                                        <a>
                                                                          {
                                                                            items.compliance_name
                                                                          }
                                                                        </a>
                                                                      )}
                                                                      {/* {
                                                                        items.compliance_name
                                                                      } */}
                                                                    </td>
                                                                    {/* <td title={items.compliance_name} className='ant-table-cell'>{items.compliance_name}</td> */}
                                                                    <td className="ant-table-cell">
                                                                      <center>
                                                                        {
                                                                          items.criticality_name
                                                                        }
                                                                      </center>
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                      {
                                                                        items.frequency_name
                                                                      }
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                      <center>
                                                                        {
                                                                          items.due_date
                                                                        }
                                                                      </center>
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                      {
                                                                        items.task_status
                                                                      }
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                      {
                                                                        items.user_name
                                                                      }
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                      {items.history_count > 0 ?
                                                                        <>
                                                                          <Tooltip
                                                                            title={
                                                                              "Click to view Remarks History"
                                                                            }
                                                                          >
                                                                            <ExclamationCircleTwoTone
                                                                              onClick={() => {
                                                                                const payload = [
                                                                                  authtoken,
                                                                                  {
                                                                                    session_token: authtoken,
                                                                                    request: [
                                                                                      "GetComplianceRemarksHistoryData",
                                                                                      {
                                                                                        "legal_entity_id": entityid,
                                                                                        "unit_id": items.unit_id,
                                                                                        "compliance_history_id": items.compliance_history_id
                                                                                      }
                                                                                    ]
                                                                                  },
                                                                                ];
                                                                                console.log(items, 'items');
                                                                                console.log(payload, 'payloadddddd');
                                                                                getHistoryRemarks({
                                                                                  payload: payload,
                                                                                  paramid: paramid,
                                                                                });
                                                                                setRemarksModal(true)
                                                                              }
                                                                              }
                                                                            // onClick={remarksHistory(items.le_id, items.compliance_history_id, items.unit_id)}
                                                                            />
                                                                          </Tooltip>{" "}
                                                                          &nbsp;{
                                                                            items.activity_status
                                                                          }
                                                                        </>
                                                                        :
                                                                        <span>{items.activity_status}</span>
                                                                      }
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                      <center>
                                                                        {
                                                                          items.activity_on
                                                                        }
                                                                      </center>
                                                                    </td>
                                                                    <td className="ant-table-cell">
                                                                      {
                                                                        items.uploaded_document
                                                                      }
                                                                    </td>
                                                                    <td className="ant-table-cell border-less">
                                                                      <center>
                                                                        {
                                                                          items.completion_date
                                                                        }
                                                                      </center>
                                                                    </td>
                                                                    {/* </tr> */}
                                                                    <span hidden>
                                                                      {
                                                                        (index =
                                                                          index + 1)
                                                                      }
                                                                    </span>
                                                                  </tr>
                                                                </>
                                                              );
                                                            }
                                                          }
                                                        )}
                                                      </Fragment>
                                                    );
                                                  }
                                                }
                                              }
                                              // if (itemdata.child2.length > 0) {
                                              //   console.log(itemdata.child2, 'itemdata.child25635333');
                                              //   if (data1 != undefined) {
                                              //     if (data1.act_name == itemdata.parent) {
                                              //       return (
                                              //         <Fragment key={i}>
                                              //           <tr className="ant-table-row ant-table-row-level-0">
                                              //             <td
                                              //               colSpan={12}
                                              //               className="ant-table-cell"
                                              //             >
                                              //               <b>
                                              //                 {itemdata.parent}
                                              //               </b>
                                              //             </td>
                                              //           </tr>


                                              //         </Fragment>
                                              //       );
                                              //     }
                                              //   }
                                              // }


                                            })}
                                        </>
                                      </tbody>
                                    );
                                  })
                                ) :
                                  <tr>
                                    <td colSpan="100%" style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                  </tr>
                                }
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      {filteredArrayState && filteredArrayState.length > 0 ? (
                        <div className="col-md-6">
                          <label>
                            Showing {pageSize * (current - 1) + 1} to {index} of{" "}
                            {filterValue && filterValue.count} entries
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {filteredArrayState && filteredArrayState.length > 0 ?
                        <div className="col-md-6 text-right">
                          <Pagination
                            current={current}
                            showSizeChanger={true}
                            // showQuickJumper={true}
                            onShowSizeChange={() => {
                              setCurrent(1);
                              // setpageState(true)
                            }}
                            pageSizeOptions={[25, 50, 100]}
                            hideOnSinglePage={
                              filterValue && filterValue.count > 25 ? false : true
                            }
                            defaultPageSize={25}
                            onChange={(page, pageSizes) => {
                              setpageState(true);
                              setCurrent(pageSize !== pageSizes ? 1 : page);
                              setPageSize(pageSizes);
                            }}
                            total={filterValue && filterValue.count}
                          // showTotal={(total) => `Showing 1 to ${index} of ${filterValue && filterValue.count} entries`}
                          />
                        </div> : false}
                    </div>
                  </Card>
                ) : (
                  ""
                )}

                <Modal
                  visible={isModalVisible}
                  footer={null}
                  onCancel={handleCancel}
                  onOk={() => {
                    setIsModalVisible(false);
                  }}
                >
                  <Table
                    style={{ marginTop: "2%" }}
                    className={localStorage.getItem("currentTheme")}
                    columns={columns}
                    dataSource={childCompliceId}
                    bordered
                    pagination={false}
                  />
                </Modal>
                <Modal
                  visible={remarksModal}
                  footer={null}
                  onCancel={handleCancel}
                  onOk={() => {
                    setRemarksModal(false);
                  }}
                >
                  <Table
                    style={{ marginTop: "2%" }}
                    className={localStorage.getItem("currentTheme")}
                    columns={columns1}
                    dataSource={filterValue && filterValue.RemarksData && filterValue.RemarksData.remarks_history
                    }
                    bordered
                    pagination={false}
                  />
                </Modal>
                <Drawer title="Select Fields" className={'add-service-prv export-checkbox  resize-drawer ' + localStorage.getItem('currentTheme')} visible={isModalOpen} footer={null} onCancel={handleCancel} onClose={handleCancel} width={600}>
                  <div className='d-flex flex-wrap'>
                    <input id="select_all" width="100%" type="checkbox" autocomplete="off"
                      checked={checkboxValue.length === 32 ? true : false}
                      onClick={(e) => {
                        let checked = e.target.checked
                        selectAllCheckBox(checked)
                      }} />  <label for='select_all'>Select All</label>


                    <input id="column_1" name='chk' type="checkbox" checked disabled="disabled" value='1' onChange={(e) => (e) => checkBoxOnChange(e)} /> <label for='column_1'>Unit Code</label>
                    <input id="column_2" name='chk' type="checkbox" checked disabled="disabled" value='2' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_2'> Unit Name</label>
                    <input id="column_7" name='chk' type="checkbox" value='7' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_7'> Location</label>


                    <input id="column_8" name='chk' type="checkbox" checked disabled="disabled" value='8' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_8'> Act / Rules</label>
                    <input id="column_9" name='chk' type="checkbox" checked disabled="disabled" value='11' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_9'> Compliance Task</label>
                    <input id="column_8" name='chk' type="checkbox" checked disabled="disabled" value='9' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_32'>  Task Category</label>
                    <input id="column_9" name='chk' type="checkbox" checked disabled="disabled" value='10' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_32'> Task Sub Category</label>
                    <input id="column_10" name='chk' type="checkbox" checked disabled="disabled" value='12' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_10'> Frequency</label>

                    <input id="column_32" name='chk' type="checkbox" checked disabled="disabled" value='32' onChange={(e) => checkBoxOnChange(e)} /> <label for='column_32'> Compliance Task Status</label>
                    <input id="column_35" name='chk' type="checkbox" value='35' checked disabled="disabled" onChange={(e) => checkBoxOnChange(e)} /> <label for='column_35'> Remarks</label>
                    <input id="column_3" name='chk' type="checkbox" value='3' checked={checkboxValue.includes(3)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_3'>Unit Code 1</label>
                    <input id="column_4" name='chk' type="checkbox" value='4' checked={checkboxValue.includes(4)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_4'>Unit Code 2</label>
                    <input id="column_5" name='chk' type="checkbox" value='5' checked={checkboxValue.includes(5)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_5'>Unit Code 3</label>



                    <input id="column_6" name='chk' type="checkbox" value='6' checked={checkboxValue.includes(6)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_6'>Unit Code 4</label>
                    <input id="column_11" name='chk' type="checkbox" value='13' checked={checkboxValue.includes(13)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_11'>Periodicity</label>
                    <input id="column_11" name='chk' type="checkbox" value='14' checked={checkboxValue.includes(14)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_11'>Criticality</label>
                    <input id="column_12" name='chk' type="checkbox" value='15' checked={checkboxValue.includes(15)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_12'>Division</label>
                    <input id="column_13" name='chk' type="checkbox" value='16' checked={checkboxValue.includes(16)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_13'>Category</label>



                    <input id="column_14" name='chk' type="checkbox" value='17' checked={checkboxValue.includes(17)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_14'>Assigned by</label>
                    <input id="column_16" name='chk' type="checkbox" value='18' checked={checkboxValue.includes(18)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_16'>Assigned Date</label>
                    <input id="column_17" name='chk' type="checkbox" value='19' checked={checkboxValue.includes(19)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_17'>Assignee</label>



                    <input id="column_18" name='chk' type="checkbox" value='20' checked={checkboxValue.includes(20)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_18'>Completed on</label>
                    <input id="column_19" name='chk' type="checkbox" value='21' checked={checkboxValue.includes(21)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_19'>Concurrer</label>
                    <input id="column_20" name='chk' type="checkbox" value='22' checked={checkboxValue.includes(22)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_20'>Concurred on</label>
                    <input id="column_21" name='chk' type="checkbox" value='23' checked={checkboxValue.includes(23)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_21'>Approver</label>



                    <input id="column_22" name='chk' type="checkbox" value='24' checked={checkboxValue.includes(24)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_22'>Approved on</label>
                    <input id="column_22" name='chk' type="checkbox" value='25' checked={checkboxValue.includes(25)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_22'> Activity Status</label>
                    <input id="column_23" name='chk' type="checkbox" value='26' checked={checkboxValue.includes(26)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_23'>Start Date</label>
                    <input id="column_24" name='chk' type="checkbox" value='27' checked={checkboxValue.includes(27)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_24'>Due Date </label>
                    <input id="column_25" name='chk' type="checkbox" value='28' checked={checkboxValue.includes(28)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_25'>Extend Due Date</label>



                    <input id="column_26" name='chk' type="checkbox" value='29' checked={checkboxValue.includes(29)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_26'>Task Completion Date / Document Issued Date</label>
                    <input id="column_27" name='chk' type="checkbox" value='30' checked={checkboxValue.includes(30)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_27'>Month </label>
                    <input id="column_28" name='chk' type="checkbox" value='31' checked={checkboxValue.includes(31)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_28'>Validity Date</label>
                    <input id="column_29" name='chk' type="checkbox" value='33' checked={checkboxValue.includes(33)} onChange={(e) => checkBoxOnChange(e)} /> <label for='column_29'> Duration </label>
                    <input id="column_31" name='chk' type="checkbox" value='34' checked={checkboxValue.includes(34)} onChange={(e) => checkBoxOnChange(e)} />  <label for='column_31'>Document Reference Number</label>
                    {/* <input id="column_40" name='chk' type="checkbox" checked disabled="disabled" value='40' onChange={(e) => (e) => checkBoxOnChange(e)} /> <label for='column_1'>Remarks</label> */}
                  </div>




                  <div className="form-actions popupbtncolour text-center">
                    <Button type="primary" shape="round" className={'addbutton mt-3 ' + localStorage.getItem('currentTheme')}
                      icon={<PlayCircleOutlined />} size='default' onClick={handleOk}> Submit
                    </Button>
                  </div>

                </Drawer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(mapStateToProps, {
  getFilterValue,
  getConsolidatedExport,
  getConsolidatedReportData,
  downloadConsolidatedReport,
  getHistoryRemarks
})(ConsolidatedReport);