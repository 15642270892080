import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url, { getUrl } from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { CERTIFICATE_LEVEL_LIST, CERTIFICATE_LEVEL_ADD, TOGGLE_COMMON_LOADER } from './../../types/index';
import alertmessage from './../../../Libs/Altermessage';
import { toast } from 'react-toastify';
const urls = getUrl()

export const CertificateLevelTable = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/certi_save`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: CERTIFICATE_LEVEL_LIST,
                    payload: decryptData,
                });
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            }
        }
    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const CertificateLevelAdd = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')

        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/certi_save`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            } else {
                dispatch({
                    type: CERTIFICATE_LEVEL_ADD,
                    payload: decryptData,
                });
            }
        }
    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err)
    }
};
export const savelevelmapping = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/certi_save`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData444");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'CertificateMappingSaveSuccess') {
                toast.success('User Mapping Saved Successfully');
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            }
            // else if (decryptData[0] === 'CretificateLabelAlreadyExists') {
            //     toast.error("Certificate Label Already Exits");
            //     setTimeout(() => {
            //         window.location.reload(false);
            //     }, 3000);
            //     dispatch({
            //         type: TOGGLE_COMMON_LOADER
            //     });
            // }
            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

            }
        }
    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const updatelevelmapping = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: urls + `/api/certi_save`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            alertmessage.sweetalert();
            // Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            console.log(decryptData, "decryptData444");

            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'CertificateMappingUpdateSuccess') {
                toast.success('Update Certificate Mapping List is Success');
                setTimeout(() => {
                    window.location.reload(false);
                }, 3000);
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();
            }
            // else if (decryptData[0] === 'CretificateLabelAlreadyExists') {
            //     toast.error("Certificate Label Already Exits");
            //     setTimeout(() => {
            //         window.location.reload(false);
            //     }, 3000);
            //     dispatch({
            //         type: TOGGLE_COMMON_LOADER
            //     });
            // }
            else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });

            }
        }



    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};




