import React, { useEffect, useState, useRef, Fragment } from 'react'
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Collapse, Input, Modal, Tooltip, Card, Select, Button, Table, Space, Drawer, DatePicker, Popconfirm, Checkbox, Form } from 'antd';
import { InfoCircleOutlined, PlusSquareTwoTone, UpOutlined, PlayCircleOutlined, CloseCircleOutlined, FullscreenOutlined, SearchOutlined, FilterFilled, PlusOutlined, DownloadOutlined, ExclamationCircleTwoTone, CloudUploadOutlined, CloseOutlined, PlusCircleOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { complianceApprovallist, getdownloadpath, getremarkhistory, getComplianceFilters, approveCompliance, UploadFile, Downloadfile, removedocument } from '../../Store/Action/Transcations/ComplianceApproval';
import { GetRemarksData } from '../../Store/Action/Transcations/Taskrecall';
import { useLocation, Link } from 'react-router-dom';
import DataTable from "react-data-table-component";
import moment from 'moment';
import EncryptDecrypt from './../../../src/Libs/Encrypt&Decrypt'
import SimpleReactValidator from "simple-react-validator";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import ScrollToTop from "react-scroll-to-top";
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { log } from '@antv/g2plot/lib/utils';
import { fullscreenmode, fullscreenexit, handleKeyDown } from "./../../Libs/fullscreenmode";
import ScrollButton from '../Scrollbar';
import Stickicon from "./Stickyicon";
import { Toaster } from '../../Libs/Toaster';
import { HomeRedirect } from './../../Libs/country'
import { property } from 'lodash';
const homepage = HomeRedirect()
const _ = require("lodash");

const mapStateToProps = (state) => ({
    list: state.ComplianceApproval.complianceApproval.complianceList,
    frequencyList: state.ComplianceApproval.complianceApproval.complianceFrequencyList,
    unitList: state.ComplianceApproval.complianceApproval.complianceUnitList,
    domainList: state.ComplianceApproval.complianceApproval.complianceDomainList,
    userList: state.ComplianceApproval.complianceApproval.complianceUserList,
    filterrecall: state.recallfilters.recallfilters,
    nextRecords: state.ComplianceApproval.complianceApproval.nextrecord,
    fileremove: state.ComplianceApproval.complianceApproval.removefile,
    remarklist: state.ComplianceApproval.complianceApproval.remarkmodaldata,
    passwordvisible: state.ComplianceApproval.complianceApproval.passwordstate

})

const ComplianceApproval = ({
    getdownloadpath,
    getremarkhistory,
    complianceApprovallist,
    getComplianceFilters,
    approveCompliance,
    GetRemarksData,
    UploadFile,
    list,
    frequencyList,
    nextRecords,
    unitList,
    domainList,
    userList,
    Downloadfile,
    removedocument,
    fileremove,
    filterrecall: { recallremarks },
    remarklist,
    passwordvisible

}) => {
    const [tooltip, setToolTip] = useState([])
    const { Panel } = Collapse;
    const { Search } = Input;
    const { Option } = Select;
    const { TextArea } = Input;
    const currentDate = new Date().toJSON().slice(0, 10);
    const location = useLocation();
    const [fullscreen, setfullscreen] = useState(false);
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    //   const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [all, setAll] = useState("All");
    const [passvisible1, Setpassvisible1] = useState(false);
    const countryidd = sessionParsedValue.country_info[0].c_id
    const currentdate = new Date();
    const [listArray, setListArray] = useState(false)
    const [remarksModal, setRemarksModal] = useState(false);
    const [titleDynamic, setTitleDynamic] = useState([]);
    const [mergedArray, setMergedArray] = useState([]);
    const validator = useRef(new SimpleReactValidator());
    let [counts, setcounts] = useState(0);
    const [tempFilterArray, setTempFilterArray] = useState([]);
    const validator1 = useRef(new SimpleReactValidator());
    const formvalidator = useRef(new SimpleReactValidator);
    const [filterTaskAcc, setFilterTaskAcc] = useState(true);
    const [temp, setTemp] = useState([]);
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [exitCollapse, setExitCollapse] = useState(false);
    const [index, setIndex] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [passvisible, Setpassvisible] = useState(false);
    const [tabledata, setTabledata] = useState([])
    const [assigneename, setAssigneename] = useState([]);
    const [nextDatas, setNextData] = useState([])
    console.log(nextDatas, 'nextDatas');
    const [finallist, Setfinallist] = useState([])
    const [updatedArray, setUpdatedArray] = useState([]);
    const [stat, setStat] = useState({
        periodical: 0,
        review: 0,
        flexiReview: 0,
        onoccurence: 0,
        onTime: 0
    })
    const [showFilterData, setShowFilterData] = useState({
        le_id: '',
        unit_id: null,
        unit_name: '',
        d_id: null,
        domain_name: '',
        frequency_id: null,
        frequency_name: '',
        us_id: null,
        user_name: '',
        start_count: 0
    })
    console.log(showFilterData, 'showFilterData')
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        pageSizeOptions: ['10', '30', '50']
    })
    const [page, setPage] = useState(1);
    const [filteredArray, setFilteredArray] = useState([])
    console.log(filteredArray, 'filteredArray');
    const [filteredArrayState, setFilteredArrayState] = useState([])
    console.log(filteredArrayState, "filteredArrayState")
    const [showValueModal, setShowValueModal] = useState(false)
    const [valueModal, setValueModal] = useState({
        unit: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: [],
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        description: "",
        completion_date: "",
        due_date: '',
        unit_id: null,
        approve_status: "",
        actiondata: "",
        start_date: "",
        domain_id: null,
        history_count: '',
        uniqueId: '',
        occurrence_remarks: '',
    })

    const [password, setpassword] = useState({
        passvalue: ''
    });
    const [valueForFileUpload, setValueForFileUpload] = useState({
        d_id: '',
        u_id: '',
        start_date: '',
        file_info: [],
    })
    const [edit, setEdit] = useState(false)
    const [fileData, setFileData] = useState([])

    const [fileupload, setFileUpload] = useState([])

    const [tabValue, setTabvalue] = useState(false);
    const [remarksvalue, Setremarksvalue] = useState();
    const [entityid, setCurrentEntity] = useState("");
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [isModalOpenremark, setIsModalOpenremark] = useState(false);
    const showModalremark = () => {
        setIsModalOpenremark(true);
    };
    const handleCancelremark = () => {
        setIsModalOpenremark(false);
    };
    const handleSearch = (e, confirm, dataIndex, setSelectedKeys) => {
        setSelectedKeys(e.target.value ? [e.target.value] : [])
        confirm({ closeDropdown: false });
        console.log(dataIndex, 'SEARCH VALUE')
        setSearchText(e.target.value);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const [defaultRemarks, setDefaultRemarks] = useState();
    // Frequency Filter
    const wrapperRef = useRef(null);
    const [showDropDown, setshowDropDown] = useState(false);
    const [totalRecord, settotalRecord] = useState([]);


    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setshowDropDown(false)
        }
    };
    const [filterValues, setFilterValues] = useState(
        {
            All: '',
            periodical: '',
            flexi: '',
            review: '',
            on_occurrence: '',
            one_time: ''
        }
    )
    const [checked, setChecked] = useState({
        All: false,
        periodical: false,
        flexi: false,
        review: false,
        on_occurrence: false,
        one_time: false

    })

    const resetfilter = () => {
        setFilterValues({
            ...filterValues,
            All: '',
            periodical: '',
            flexi_review: '',
            review: '',
            on_occurrence: '',
            one_time: ''
        })
        setChecked({
            ...checked,
            All: false,
            periodical: false,
            flexi: false,
            review: false,
            on_occurrence: false,
            one_time: false
        })
    }

    const onFinish = () => {
        const res = []
        const keys = Object.keys(filterValues)
        keys.forEach(key => {
            if (filterValues[key] === true) {
                if (key == 'on_occurrence') {
                    key = 'on occurrence'
                }
                if (key == 'one_time') {
                    key = 'one time'
                }
                res.push(key)
            }
            //filterValues[key] === true && res.push(key)

        })
        console.log(res, 'SSSSS')
        let filterTable;
        let temp = []
        let a
        if (res[0] == "All" || res.length == 0) {
            filterTable = updatedArray;
            settotalRecord(filterTable.length);
        } else {
            for (let i in res) {
                a = updatedArray.filter((o) =>
                    Object.keys(o).some((k) =>
                        String(o[k]).toLowerCase().includes(res[i].toLowerCase())
                    )
                );

                console.log(temp, a, 'LLLLLLLL')
                Array.prototype.push.apply(temp, a);


            }
            filterTable = temp
            console.log(filterTable, 'filterTable');
            settotalRecord(filterTable.length);
        }

        let totalList = [];
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = [];
        if (filterTable && filterTable.length > 0) {
            assigneename &&
                assigneename.length > 0 &&
                assigneename.map(function (user) {
                    if (arr.indexOf(user.assignee_name) < 0)
                        arr.push({
                            assignee_name: user.assignee_name,
                            unit_name: user.unit_name,
                        });
                });

            arr.map((pmenu, index) => {
                // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
                let entityArray = [];
                let tempArray = [];
                let unit_name = [];
                if (pmenu) {
                    filterTable &&
                        filterTable.map((subMenuList, childIndex) => {
                            if (
                                pmenu.assignee_name === subMenuList.assignee_name &&
                                pmenu.unit_name === subMenuList.unit_name
                            ) {
                                entityArray = {
                                    action: subMenuList.action,
                                    approve_status: subMenuList.approve_status,
                                    assignee_id: subMenuList.assignee_id,
                                    assignee_name: subMenuList.assignee_name,
                                    comp_history_count: subMenuList.comp_history_count,
                                    compfielaw_url: subMenuList.compfielaw_url,
                                    completion_date: subMenuList.completion_date,
                                    compliance_history_id: subMenuList.compliance_history_id,
                                    compliance_name: subMenuList.compliance_name,
                                    compliance_task_frequency: subMenuList.compliance_task_frequency,
                                    concurrence_status: subMenuList.concurrence_status,
                                    concurrenced_by: subMenuList.concurrenced_by,
                                    current_status: subMenuList.current_status,
                                    delayed_by: subMenuList.delayed_by,

                                    description: subMenuList.description,

                                    doc_ref_num: subMenuList.doc_ref_num,

                                    domain_id: subMenuList.domain_id,

                                    domain_name: subMenuList.domain_name,

                                    due_date: subMenuList.due_date,

                                    extend_due_date: subMenuList.extend_due_date,

                                    fileSize: subMenuList.fileSize,

                                    file_names: subMenuList.file_names,

                                    history_count: subMenuList.history_count,

                                    interim_count: subMenuList.interim_count,

                                    is_active: subMenuList.is_active,


                                    is_ctcy_task: subMenuList.is_ctcy_task,

                                    next_due_date: subMenuList.next_due_date,

                                    occurrence_remarks: subMenuList.occurrence_remarks,

                                    remarks: subMenuList.remarks,

                                    start_date: subMenuList.start_date,

                                    statu: subMenuList.statu,

                                    statutory_dates: subMenuList.statutory_dates,

                                    system_remarks: subMenuList.system_remarks,

                                    tz_date: subMenuList.tz_date,

                                    tz_name: subMenuList.tz_name,

                                    unit_address: subMenuList.unit_address,

                                    unit_id: subMenuList.unit_id,

                                    unit_name: subMenuList.unit_name,

                                    upload_date: subMenuList.upload_date,

                                    uploaded_documents: subMenuList.uploaded_documents,

                                    validity_date: subMenuList.validity_date,

                                    validity_settings_days: subMenuList.validity_settings_days,

                                    parentId: index,
                                    childId: childIndex,
                                    indexNo: childIndex + 1,
                                    uniqueId: Math.floor(Math.random() * 100)
                                };
                                tempArray.push(entityArray);
                                unit_name.push(subMenuList.unit_name);
                            }
                        });
                }
                totalList = {
                    index: index,
                    parentData: pmenu,
                    child: tempArray,
                };
                titleDynamic[index] = pmenu;
                countrylisttempdata.push(totalList);
                tempSetArray.push(tempArray);
                parId.push(unit_name);
            });
            console.log([...new Set([].concat.apply([], parId))], "parIdId");
            setTitleDynamic([...titleDynamic]);
            var merged = [].concat.apply([], tempSetArray);
            setTemp(countrylisttempdata);
            setMergedArray(merged);
            setTempFilterArray([...new Set([].concat.apply([], parId))]);
            // setChecked({
            //   All: false,
            //   periodical: false,
            //   flexi: false,
            //   review: false,
            //   on_occurrence: false,
            //   one_time: false

            // })
        } else {
            setMergedArray([]);
            setTemp([]);
            setTitleDynamic([]);
            setTempFilterArray([]);
        }
        setshowDropDown(false)
    }

    const compliancetaskfilter = (e, type) => {
        let filterTable;
        if (type == "Compliance_Task") {
            filterTable = updatedArray.filter((o) => {
                if (all == "All") {
                    if (o["statu"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["statu"].toLowerCase().includes(e.toLowerCase()) &&
                        o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        } else if (type == "Domain") {
            filterTable = updatedArray.filter((o) => {
                if (all == "All") {
                    if (o["domain_name"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["domain_name"].toLowerCase().includes(e.toLowerCase()) &&
                        o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        } else if (type == "Start Date") {
            filterTable = updatedArray.filter((o) => {
                if (all == "All") {
                    if (o["start_date"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["start_date"].toLowerCase().includes(e.toLowerCase()) &&
                        o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        } else if (type == "Due Date") {
            filterTable = updatedArray.filter((o) => {
                if (all == "All") {
                    if (o["due_date"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["due_date"].toLowerCase().includes(e.toLowerCase()) &&
                        o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        } else if (type == "Days & Timeline") {
            filterTable = updatedArray.filter((o) => {
                if (all == "All") {
                    if (o["delayed_by"].toLowerCase().includes(e.toLowerCase())) {
                        return true;
                    }
                } else {
                    if (
                        o["delayed_by"].toLowerCase().includes(e.toLowerCase()) &&
                        o["compliance_task_frequency"].toLowerCase().includes(all.toLowerCase())
                    ) {
                        return true;
                    }
                }
            });
        }

        let totalList = [];
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = [];
        if (filterTable && filterTable.length > 0) {
            assigneename &&
                assigneename.length > 0 &&
                assigneename.map(function (user) {
                    if (arr.indexOf(user.assignee_name) < 0)
                        arr.push({
                            assignee_name: user.assignee_name,
                            unit_name: user.unit_name,
                        });
                });

            arr.map((pmenu, index) => {

                let entityArray = [];
                let tempArray = [];
                let unit_name = [];
                if (pmenu) {
                    filterTable &&
                        filterTable.map((subMenuList, childIndex) => {
                            if (
                                pmenu.assignee_name === subMenuList.assignee_name &&
                                pmenu.unit_name === subMenuList.unit_name
                            ) {

                                entityArray = {
                                    action: subMenuList.action,
                                    approve_status: subMenuList.approve_status,
                                    assignee_id: subMenuList.assignee_id,
                                    assignee_name: subMenuList.assignee_name,
                                    comp_history_count: subMenuList.comp_history_count,
                                    compfielaw_url: subMenuList.compfielaw_url,
                                    completion_date: subMenuList.completion_date,
                                    compliance_history_id: subMenuList.compliance_history_id,
                                    compliance_name: subMenuList.compliance_name,
                                    compliance_task_frequency: subMenuList.compliance_task_frequency,
                                    concurrence_status: subMenuList.concurrence_status,
                                    concurrenced_by: subMenuList.concurrenced_by,
                                    current_status: subMenuList.current_status,
                                    delayed_by: subMenuList.delayed_by,

                                    description: subMenuList.description,

                                    doc_ref_num: subMenuList.doc_ref_num,

                                    domain_id: subMenuList.domain_id,

                                    domain_name: subMenuList.domain_name,

                                    due_date: subMenuList.due_date,

                                    extend_due_date: subMenuList.extend_due_date,

                                    fileSize: subMenuList.fileSize,

                                    file_names: subMenuList.file_names,

                                    history_count: subMenuList.history_count,

                                    interim_count: subMenuList.interim_count,

                                    is_active: subMenuList.is_active,


                                    is_ctcy_task: subMenuList.is_ctcy_task,

                                    next_due_date: subMenuList.next_due_date,

                                    occurrence_remarks: subMenuList.occurrence_remarks,

                                    remarks: subMenuList.remarks,

                                    start_date: subMenuList.start_date,

                                    statu: subMenuList.statu,

                                    statutory_dates: subMenuList.statutory_dates,

                                    system_remarks: subMenuList.system_remarks,

                                    tz_date: subMenuList.tz_date,

                                    tz_name: subMenuList.tz_name,

                                    unit_address: subMenuList.unit_address,

                                    unit_id: subMenuList.unit_id,

                                    unit_name: subMenuList.unit_name,

                                    upload_date: subMenuList.upload_date,

                                    uploaded_documents: subMenuList.uploaded_documents,

                                    validity_date: subMenuList.validity_date,

                                    validity_settings_days: subMenuList.validity_settings_days,

                                    parentId: index,
                                    childId: childIndex,
                                    indexNo: childIndex + 1,
                                    uniqueId: Math.floor(Math.random() * 100)
                                };
                                tempArray.push(entityArray);
                                unit_name.push(subMenuList.unit_name);

                            }
                        });
                }
                totalList = {
                    index: index,
                    parentData: pmenu,
                    child: tempArray,
                };
                titleDynamic[index] = pmenu;
                countrylisttempdata.push(totalList);
                tempSetArray.push(tempArray);
                parId.push(unit_name);
            });
            console.log([...new Set([].concat.apply([], parId))], "parIdId");
            setTitleDynamic([...titleDynamic]);
            var merged = [].concat.apply([], tempSetArray);
            setTemp(countrylisttempdata);
            setMergedArray(merged);
            setTempFilterArray([...new Set([].concat.apply([], parId))]);
        } else {
            setMergedArray([]);
            setTemp([]);
            setTitleDynamic([]);
            setTempFilterArray([]);
        }
    };
    const frequencyfilter = (data) => {
        setAll(data);
        let filterTable;
        if (data == "All") {
            filterTable = updatedArray;
            //   settotalRecord(filterTable.length);
        } else {
            filterTable = updatedArray.filter((o) =>
                Object.keys(o).some((k) =>
                    String(o[k]).toLowerCase().includes(data.toLowerCase())
                )
            );
            //   settotalRecord(filterTable.length);
        }
        let totalList = [];
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = [];
        if (filterTable && filterTable.length > 0) {
            assigneename &&
                assigneename.length > 0 &&
                assigneename.map(function (user) {
                    if (arr.indexOf(user.assignee_name) < 0)
                        arr.push({
                            assignee_name: user.assignee_name,
                            unit_name: user.unit_name,
                        });
                });

            arr.map((pmenu, index) => {
                // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
                let entityArray = [];
                let tempArray = [];
                let unit_name = [];
                if (pmenu) {
                    filterTable &&
                        filterTable.map((subMenuList, childIndex) => {
                            if (
                                pmenu.assignee_name === subMenuList.assignee_name &&
                                pmenu.unit_name === subMenuList.unit_name
                            ) {
                                entityArray = {
                                    action: subMenuList.action,
                                    approve_status: subMenuList.approve_status,
                                    assignee_id: subMenuList.assignee_id,
                                    assignee_name: subMenuList.assignee_name,
                                    comp_history_count: subMenuList.comp_history_count,
                                    compfielaw_url: subMenuList.compfielaw_url,
                                    completion_date: subMenuList.completion_date,
                                    compliance_history_id: subMenuList.compliance_history_id,
                                    compliance_name: subMenuList.compliance_name,
                                    compliance_task_frequency: subMenuList.compliance_task_frequency,
                                    concurrence_status: subMenuList.concurrence_status,
                                    concurrenced_by: subMenuList.concurrenced_by,
                                    current_status: subMenuList.current_status,
                                    delayed_by: subMenuList.delayed_by,

                                    description: subMenuList.description,

                                    doc_ref_num: subMenuList.doc_ref_num,

                                    domain_id: subMenuList.domain_id,

                                    domain_name: subMenuList.domain_name,

                                    due_date: subMenuList.due_date,

                                    extend_due_date: subMenuList.extend_due_date,

                                    fileSize: subMenuList.fileSize,

                                    file_names: subMenuList.file_names,

                                    history_count: subMenuList.history_count,

                                    interim_count: subMenuList.interim_count,

                                    is_active: subMenuList.is_active,


                                    is_ctcy_task: subMenuList.is_ctcy_task,

                                    next_due_date: subMenuList.next_due_date,

                                    occurrence_remarks: subMenuList.occurrence_remarks,

                                    remarks: subMenuList.remarks,

                                    start_date: subMenuList.start_date,

                                    statu: subMenuList.statu,

                                    statutory_dates: subMenuList.statutory_dates,

                                    system_remarks: subMenuList.system_remarks,

                                    tz_date: subMenuList.tz_date,

                                    tz_name: subMenuList.tz_name,

                                    unit_address: subMenuList.unit_address,

                                    unit_id: subMenuList.unit_id,

                                    unit_name: subMenuList.unit_name,

                                    upload_date: subMenuList.upload_date,

                                    uploaded_documents: subMenuList.uploaded_documents,

                                    validity_date: subMenuList.validity_date,

                                    validity_settings_days: subMenuList.validity_settings_days,

                                    parentId: index,
                                    childId: childIndex,
                                    indexNo: childIndex + 1,
                                    uniqueId: Math.floor(Math.random() * 100)
                                };
                                tempArray.push(entityArray);
                                unit_name.push(subMenuList.unit_name);
                            }
                        });
                }
                totalList = {
                    index: index,
                    parentData: pmenu,
                    child: tempArray,
                };
                // titleDynamic[index] = pmenu;
                countrylisttempdata.push(totalList);
                tempSetArray.push(tempArray);
                parId.push(unit_name);
            });
            console.log([...new Set([].concat.apply([], parId))], "parIdId");
            setTitleDynamic([...titleDynamic]);
            var merged = [].concat.apply([], tempSetArray);
            setTemp(countrylisttempdata);
            setMergedArray(merged);
            setTempFilterArray([...new Set([].concat.apply([], parId))]);
        } else {
            setMergedArray([]);
            setTemp([]);
            setTitleDynamic([]);
            setTempFilterArray([]);
        }
    };
    const compliancesubmit1 = () => {
        setAddFormSubmit2(true)
        if (formvalidator.current.allValid()) {
            Setpassvisible(false);
            // setShowValueModal(false)
            setEdit(false)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "ApproveCompliance",
                        {
                            "le_id": entityid,
                            "compliance_history_id": Number(valueModal.compliance_history_id),
                            "approval_status": [
                                "Approve"
                            ],
                            "remarks": defaultRemarks ? defaultRemarks : null,
                            "next_due_date": valueModal.next_due_date ? moment(new Date(valueModal.next_due_date)).format('DD-MMM-YYYY') : null,
                            "validity_date": valueModal.validity_date ? moment(new Date(valueModal.validity_date)).format('DD-MMM-YYYY') : null,
                            "password": "",
                            "pwd_validate": false,
                            "documents": null,
                            "file_size": 0
                        }
                    ]
                }
            ]
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApprovalList",
                        {
                            "le_id": entityid,
                            "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                            "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                            "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                            "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                            "start_count": 0
                        }
                    ]
                }
            ]
            setEdit(false)
            approveCompliance({
                payload: payload,
                paramid: paramid,
                tempvalue: 'Approve',
                nextData: nextDatas,
                payload1: payload1

            })
            setAddFormSubmit2(false)
            setAddFormSubmit1(false)
            Setpassvisible1(false)
            setValueModal({
                ...valueModal,
                unit: '',
                compliance_history_id: '',
                compliance_task: '',
                compliance_frequency: '',
                uploaded_document: '',
                document_issued_date: '',
                document_reference_number: '',
                validity_date: '',
                next_due_date: '',
                status: '',
                remarks: '',
                action: '',
                password: '',
                history_count: '',
                occurrence_remarks: '',
            })
        }
        //}
    }
    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div>
                <Input
                    ref={searchInput}
                    autoFocus={'on'}
                    placeholder={'Search ' + placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => handleSearch(e, confirm, dataIndex, setSelectedKeys)}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });
    console.log(remarklist, 'remarklist');
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    console.log(nextRecords, 'nextRecords55555');

    useEffect(() => {
        if (nextRecords.length > 0) {

            setValueModal({
                domain_id: nextRecords && nextRecords[0].domain_id,
                start_date: nextRecords && nextRecords[0].start_date,
                uploaded_document: nextRecords && nextRecords[0].uploaded_documents,
                actiondata: nextRecords && nextRecords[0].action,
                approve_status: nextRecords && nextRecords[0].approve_status,
                unit_id: nextRecords && nextRecords[0].unit_id,
                completion_date: nextRecords && nextRecords[0].completion_date,
                remarks: nextRecords && nextRecords[0].remarks,
                description: nextRecords && nextRecords[0].description,
                compliance_history_id: nextRecords && nextRecords[0].compliance_history_id,
                unit: nextRecords && nextRecords[0].unit_name,
                compliance_task: nextRecords && nextRecords[0].compliance_name,
                compliance_frequency: nextRecords && nextRecords[0].compliance_task_frequency,
                document_issued_date: nextRecords && nextRecords[0].completion_date,
                document_reference_number: nextRecords && nextRecords[0].doc_ref_num,
                next_due_date: nextRecords && nextRecords[0].next_due_date != null ? nextRecords && nextRecords[0].next_due_date : nextRecords && nextRecords[0].due_date,
                history_count: nextRecords && nextRecords[0].history_count,
                occurrence_remarks: nextRecords && nextRecords[0].occurrence_remarks,
                uniqueId: nextRecords && nextRecords[0].uniqueId
            })
            setValueForFileUpload({
                d_id: nextRecords && nextRecords[0].domain_id,
                u_id: nextRecords && nextRecords[0].unit_id,
                start_date: nextRecords && nextRecords[0].start_date,
            })
            showValue()
        }
        else {
            setShowValueModal(false)
        }

    }, [nextRecords])
    useEffect(() => {
        if (localStorage.getItem('SelectedEntity') != 'All Legal Entity') {
            if (entityid !== '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceApprovalList",
                            {
                                "le_id": entityid,
                                "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                                "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                                "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                                "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                                "start_count": 0
                            }
                        ]
                    }
                ]

                complianceApprovallist({
                    payload: payload,
                    paramid: paramid
                })
                setListArray(true)
                setTabvalue(true);
            }
        }
    }, [])

    const columnsremark = [
        {
            title: '#',
            dataIndex: false,
            width: '10px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{(page - 1) * 10 + index + 1}</p>
            }
        },
        {
            title: 'Activity Date',
            dataIndex: "activity_on",
            key: "activity_on",
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{record.activity_on}</p>
            }
        },
        {
            title: 'Usertype',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return record.action === "Submitted" ? <p>Assignee</p> : <p>Approver</p>
            }
        },
        {
            title: 'Reviewer Report',
            dataIndex: false,
            width: '30px',
            align: 'center',
            // render: (text, record, index) => {
            //     return <p>Assignee</p>
            // }
        },
        {
            title: 'Remarks',
            dataIndex: "remarks",
            key: "remarks",
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return <p>{record.remarks}</p>
            }
        }
    ]
    const column = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '30px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            ellipsis: true,
            render: (text, record) => <a href={() => false} onClick={() => {

                setDefaultRemarks(record.r_description)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },

    ]

    const columns = [
        {
            title: "#",
            filterable: true,
            dataIndex: false,
            key: false,
            render: (row, record, index) => {
                // if (record.compliance_task_frequency == 'Periodical') {
                //     return <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#4FC742" }} class="bi bi-square-fill"></i></p></Tooltip>
                // } else if (record.compliance_task_frequency == 'FlexiReview') {
                //     return <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i></p></Tooltip>
                // } else if (record.compliance_task_frequency == 'Review') {
                //     return <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#F78453" }} class="bi bi-square-fill"></i></p></Tooltip>
                // }
                // else if (record.compliance_task_frequency == 'On Occurrence') {
                //     return <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i></p></Tooltip>
                // } else {
                //     return <Tooltip title={row && row.compliance_task_frequency}><p><i style={{ color: "#F21625" }} class="bi bi-square-fill"></i></p></Tooltip>
                // }
                return (
                    <>
                        <p>{(page - 1) * 10 + index + 1}</p>
                        {record.compliance_task_frequency == 'Periodical' ?
                            <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "green" }} className="bi bi-square-fill"></i></p></Tooltip> :
                            record.compliance_task_frequency == 'FlexiReview' ?
                                <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "pink" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                record.compliance_task_frequency == 'Review' ?
                                    <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "orange" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                    record.compliance_task_frequency == 'On Occurrence' ?
                                        <Tooltip title={record && record.compliance_task_frequency}><p><i style={{ color: "yellow" }} className="bi bi-square-fill"></i></p></Tooltip> :
                                        <Tooltip title={row && row.compliance_task_frequency}><p><i style={{ color: "red" }} className="bi bi-square-fill"></i></p></Tooltip>
                        }
                    </>
                )
            },
            width: '50px',
            align: 'center',
            filters: [
                {
                    text: <span><i style={{ color: "green" }} className="bi bi-square-fill"></i> Periodical</span>,
                    value: 'Periodical',
                },
                {
                    text: <span><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</span>,
                    value: 'FlexiReview',
                },
                {
                    text: <span><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</span>,
                    value: 'Review',
                },
                {
                    text: <span><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> On Occurrence</span>,
                    value: 'On Occurrence',
                },
                {
                    text: <span><i style={{ color: "red" }} className="bi bi-square-fill"></i> One Time</span>,
                    value: 'One Time',
                },
            ],
            onFilter: (value, record) => record.compliance_task_frequency == value,
        },
        {
            title: 'Compliance Task',
            ...getColumnSearchProps('compliance_name', 'Compliance Task'),
            dataIndex: false,
            key: false,
            ellipse: true,
            render: (row, record, index) => {
                return (
                    <>
                        <p className="mb-0 pointer"
                            onClick={() => {
                                console.log(record, "777777");
                                setIndex(index)
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>{record.statu}
                            {/* <div className="mb-3">
                                <Tooltip title={record.description}>
                                    <p style={{ whiteSpace: 'unset' }}><InfoCircleOutlined />&nbsp;{record.compliance_name}</p>
                                </Tooltip>
                            </div> */}
                        </p>
                        <div>
                            <Tooltip title={`${record.description}`} ><ExclamationCircleTwoTone style={{ marginRight: '5px' }} /></Tooltip><span>{record.compliance_name}</span>
                        </div>
                    </>
                )
            },
            width: '240px',
        },
        {
            title: "Domain",
            ...getColumnSearchProps('domain_name', "Domain"),
            dataIndex: 'false',
            key: 'false',
            width: '150px',
            // ellipsis: true,
            render: (row, record) => {
                return (
                    <>
                        <p title={record.domain_name} className="mb-0 pointer"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>{record.domain_name}
                            {/* <div className="mb-3">
                                <Tooltip title={record.description}>
                                    <p style={{ whiteSpace: 'unset' }}><InfoCircleOutlined />&nbsp;{record.compliance_name}</p>
                                </Tooltip>
                            </div> */}
                        </p>
                    </>
                )
            },


        },
        {
            title: "Assignee Name",
            ...getColumnSearchProps('assignee_name', "Assignee Name"),
            dataIndex: 'false',
            key: 'false',
            render: (row, record) => {
                console.log(record, 'record')
                return (
                    <>
                        <span className="mb-0 pointer"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>{record.assignee_name}
                        </span>
                    </>
                )
            },
            width: '100px',


        },
        {
            title: "Start Date",
            ...getColumnSearchProps('start_date', "Start Date"),
            dataIndex: 'false',
            key: 'start_date',
            render: (row, record) => {
                return (
                    <>
                        <p className="mb-0 pointer"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>{record.start_date}
                        </p>
                    </>
                )
            },
            width: '75px',


        },
        {
            title: "Due Date",
            ...getColumnSearchProps('due_date', "Due Date"),
            dataIndex: 'false',
            key: 'due_date',
            render: (row, record) => {
                return (
                    <>
                        <p className="mb-0 pointer"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>{record.due_date}
                        </p>
                    </>
                )
            },
            width: '70px',


        },
        {
            title: "Days & Timeline",
            ...getColumnSearchProps('delayed_by', "Days & Timeline"),
            render: (row, record) => {
                console.log(record, 'record');
                return (
                    <>
                        <p className="mb-0 pointer"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    domain_id: record.domain_id,
                                    start_date: record.start_date,
                                    uploaded_document: record.uploaded_documents,
                                    actiondata: record.action,
                                    approve_status: record.approve_status,
                                    unit_id: record.unit_id,
                                    completion_date: record.completion_date,
                                    remarks: record.remarks,
                                    description: record.description,
                                    compliance_history_id: record.compliance_history_id,
                                    unit: record.unit_name,
                                    compliance_task: record.compliance_name,
                                    compliance_frequency: record.compliance_task_frequency,
                                    document_issued_date: record.completion_date,
                                    document_reference_number: record.doc_ref_num,
                                    next_due_date: record.next_due_date != null ? record.next_due_date : record.due_date,
                                    history_count: record.history_count,
                                    occurrence_remarks: record.occurrence_remarks
                                })
                                setValueForFileUpload({
                                    d_id: record.domain_id,
                                    u_id: record.unit_id,
                                    start_date: record.start_date,
                                })
                            }}>            <span style={{ color: '#FF0000' }}>{record.delayed_by}</span>

                        </p>
                    </>
                )
            },

            sortable: true,
            width: '120px',
            ellipsis: true,
            dataIndex: false,
            key: false,


        },
    ];

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    useEffect(() => {
        validator.current.showMessages();
        validator1.current.showMessages();
        formvalidator.current.showMessages();
    }, [])

    useEffect(() => {

        if (list && list.length > 0) {
            let periodical = _.filter(list, { compliance_task_frequency: 'Periodical' })
            let flexiReview = _.filter(list, { compliance_task_frequency: 'FlexiReview' })
            let Review = _.filter(list, { compliance_task_frequency: 'Review' })
            let OnOccurence = _.filter(list, { compliance_task_frequency: 'On Occurrence' })
            let oneTime = _.filter(list, { compliance_task_frequency: 'OnTime' })
            setStat({
                periodical: periodical && periodical.length,
                flexiReview: flexiReview && flexiReview.length,
                review: Review && Review.length,
                onoccurence: OnOccurence && OnOccurence.length,
                onTime: oneTime && oneTime.length
            })
        }
    }, [list])
    // useEffect(() => {
    //     if (filteredArray && filteredArray.length > 0) {
    //         filteredArray && filteredArray.map((item, i) => {
    //             return (
    //                 setFilteredArrayState(item)
    //             )
    //         })
    //     }
    // }, [filteredArray])

    // useEffect(() => {
    //     if (filteredArrayState && filteredArrayState.length > 0) {
    //         let checkarray = []
    //         for (let i in filteredArrayState) {
    //             let listArray_One_time = _.filter(filteredArrayState[i], { compliance_task_frequency: "One Time" });
    //             if (listArray_One_time && listArray_One_time.length > 0) {
    //                 for (let i in listArray_One_time) {
    //                     checkarray.push(listArray_One_time[i])
    //                 }
    //             }
    //             console.log(listArray_One_time, "listArray_One_time")
    //             let listArray_periodical = _.filter(filteredArrayState[i], { compliance_task_frequency: "Periodical" });
    //             if (listArray_periodical && listArray_periodical.length > 0) {
    //                 for (let i in listArray_periodical) {
    //                     checkarray.push(listArray_periodical[i])
    //                 }
    //             }
    //             console.log(listArray_periodical, "listArray_periodical")

    //             let listArray_Review = _.filter(filteredArrayState[i], { compliance_task_frequency: "Review" });
    //             if (listArray_Review && listArray_Review.length > 0) {
    //                 for (let i in listArray_Review) {
    //                     checkarray.push(listArray_Review[i])
    //                 }
    //             }
    //             console.log(listArray_Review, "listArray_Review")

    //             let listArray_Flexi_Review = _.filter(filteredArrayState[i], { compliance_task_frequency: "Flexi Review" });
    //             if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
    //                 for (let i in listArray_Flexi_Review) {
    //                     checkarray.push(listArray_Flexi_Review[i])
    //                 }
    //             }
    //             console.log(listArray_Flexi_Review, "listArray_Flexi_Review")

    //             let listArray_On_Occurrence = _.filter(filteredArrayState[i], { compliance_task_frequency: "On Occurrence" });
    //             if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
    //                 for (let i in listArray_On_Occurrence) {
    //                     checkarray.push(listArray_On_Occurrence[i])
    //                 }
    //             }
    //             console.log(listArray_On_Occurrence, "listArray_On_Occurrence")

    //         }
    //         console.log(checkarray, "checkarraycheckarray")
    //         setTabledata(checkarray)
    //         // filteredArray && filteredArray.map((item, i) => {
    //         //     return (
    //         //         setFilteredArrayState(item)
    //         //     )setFilteredArray
    //         // })
    //     }
    // }, [filteredArrayState])


    // useEffect(() => {
    //     let tempArr = [];
    //     if (updatedArray && updatedArray.length > 0) {
    //       filteredArrayState &&
    //         filteredArrayState.length > 0 &&
    //         filteredArrayState.map((item, i) => {
    //           let uniqueObjArray = [
    //             ...new Map(
    //               item &&
    //               item.length > 0 &&
    //               item.map((items) => [items["assignee_name"], items])
    //             ).values(),
    //           ];
    //           const uniqueIds =
    //             uniqueObjArray.length > 0 &&
    //             uniqueObjArray.map((itemData) => {
    //               return itemData.assignee_name;
    //             });
    //           for (let i in uniqueIds) {
    //             let filteredArr = _.find(item && item.length > 0 && item, {
    //               assignee_name: uniqueIds[i],
    //             });
    //             tempArr.push(filteredArr);
    //           }
    //         });
    //       setAssigneename(tempArr);
    //     }
    //   }, [filteredArrayState]);
    console.log(filteredArrayState, 'filteredArrayState');
    useEffect(() => {
        if (filteredArray.length > 0) {
            let temp = [];
            for (let i in filteredArray) {
                temp = filteredArray[i];
            }
            setFilteredArrayState([...temp]);
        }
    }, [filteredArray]);
    useEffect(() => {
        if (list) {
            let arrayTemp = [];
            for (let i in list) {
                arrayTemp.push(list[i]);
            }
            var merged = [].concat.apply([], arrayTemp);
            setUpdatedArray([...merged]);
        }
    }, [list]);

    useEffect(() => {
        if (list && list.length > 0) {
            console.log(list, "Listlist9999999999")
            let checkarray1 = []
            // for (let i in list) {
            // console.log(list[i],"listiiiiiiiiiilist")
            let listArray_One_time = _.filter(list, { compliance_task_frequency: "One Time" });
            if (listArray_One_time && listArray_One_time.length > 0) {
                for (let i in listArray_One_time) {
                    checkarray1.push(listArray_One_time[i])
                }
            }
            console.log(listArray_One_time, "listArray_One_time444")
            let listArray_periodical = _.filter(list, { compliance_task_frequency: "Periodical" });
            if (listArray_periodical && listArray_periodical.length > 0) {
                for (let i in listArray_periodical) {
                    checkarray1.push(listArray_periodical[i])
                }
            }
            console.log(listArray_periodical, "listArray_periodical")

            let listArray_Review = _.filter(list, { compliance_task_frequency: "Review" });
            if (listArray_Review && listArray_Review.length > 0) {
                for (let i in listArray_Review) {
                    checkarray1.push(listArray_Review[i])
                }
            }
            console.log(listArray_Review, "listArray_Review")

            let listArray_Flexi_Review = _.filter(list, { compliance_task_frequency: "Flexi Review" });
            if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
                for (let i in listArray_Flexi_Review) {
                    checkarray1.push(listArray_Flexi_Review[i])
                }
            }
            console.log(listArray_Flexi_Review, "listArray_Flexi_Review")

            let listArray_On_Occurrence = _.filter(list, { compliance_task_frequency: "On Occurrence" });
            if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
                for (let i in listArray_On_Occurrence) {
                    checkarray1.push(listArray_On_Occurrence[i])
                }
            }
            console.log(listArray_On_Occurrence, "listArray_On_Occurrence")

            // }
            console.log(checkarray1, "checkarraycheckarray77777")
            Setfinallist(checkarray1)
            // filteredArray && filteredArray.map((item, i) => {
            //     return (
            //         setFilteredArrayState(item)
            //     )setFilteredArray
            // })
        }
    }, [list])

    useEffect(() => {
        if (list && list.length > 0) {
            let uniqueObjArray = [...new Map(list && list.length && list.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(list, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)

                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [list])


    useEffect(() => {
        if (isAuth) {
            if (entityid !== '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceApprovalList",
                            {
                                "le_id": entityid,
                                "unit_id": null,
                                "d_id": null,
                                "frequency_id": null,
                                "us_id": null,
                                "start_count": 0
                            }
                        ]
                    }
                ]
                const filterAPI = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "complianceApprovalFilters",
                            {
                                "le_id": entityid
                            }
                        ]
                    }
                ]
                if (entityid != "null") {
                    getComplianceFilters({
                        payload: filterAPI,
                        paramid: paramid
                    })
                }
            }

        }
    }, [isAuth, entityid])

    const showValue = () => {
        setShowValueModal(true);
    };

    const handleOk = () => {
        setRemarksModal(false);

    };

    const handlePasting = (e, regex) => {

        var regex = regex;
        var key = e.clipboardData.getData('text')
        if (!regex.test(key)) {
            e.preventDefault();
            Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
            return false;
        }
        // e.clipboardData.getData('text')
        // Toaster.warning('System autocorrected and removed the invalid characters entered, Please validate the data.')
        // e.preventDefault();
        // return false;
    }

    const download = (item) => {
        console.log(item, 'itemitemitem')
        const downloadpayload =
            [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "DownloadFile_temp",
                        {
                            "le_id": entityid,
                            "c_id": 1,
                            "d_id": valueForFileUpload.d_id,
                            "u_id": valueForFileUpload.u_id,
                            "start_date": valueForFileUpload.start_date,
                            "compliance_history_id": Number(valueModal.compliance_history_id),
                            "upload_type": 2,
                            "file_name": item,
                        }
                    ]
                }
            ]
        Downloadfile({
            payload: downloadpayload,
            paramid: paramid
        });
        // if (item) {
        //     window.open('https:/' + item);
        // }
    }
    const Remove = (index) => {

        let temp = fileupload
        for (let i in fileupload) {
            const removepayload =
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "RemoveFile_temp",
                            {
                                "le_id": entityid,
                                "c_id": 1,
                                "selected_task_info": [
                                    {
                                        "d_id": valueForFileUpload.d_id,
                                        "u_id": valueForFileUpload.u_id,
                                        "start_date": valueForFileUpload.start_date,
                                        "compliance_history_id": Number(valueModal.compliance_history_id),
                                        "file_info": [
                                            {
                                                "file_size": fileupload.file_size,
                                                "file_name": fileupload.file_name,
                                                "file_content": null
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            removedocument({
                payload: removepayload,
                paramid: paramid
            })


            console.log(temp[i]['file_name'], 'indexxx')
            if (fileupload[i]['file_name'] == index) {
                fileupload.splice(i, 1)
            }

        }
        setFileUpload([...fileupload])

    }


    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    useEffect(() => {
        if (list && list.length == 0) {
            setFilteredArrayState([])
            setUpdatedArray([]);
            setFilteredArray([])
        }
    }, [list]);

    // useEffect(()=>{
    //   if(passwordvisible == true){
    //      setValueModal({
    //             unit: '',
    //             compliance_history_id: '',
    //             compliance_task: '',
    //             compliance_frequency: '',
    //             uploaded_document: '',
    //             document_issued_date: '',
    //             document_reference_number: '',
    //             validity_date: '',
    //             next_due_date: '',
    //             status: '',
    //             remarks: '',
    //             action: '',
    //             password: '',
    //             history_count: '',
    //             occurrence_remarks: '',
    //         })
    //   }
    // },[passwordvisible])
    const passwordcancel1 = () => {
        setAddFormSubmit1(false)
        Setpassvisible1(false);
        setValueModal({
            ...valueModal,
            password: ''
        })
        setpassword({
            ...password,
            passvalue: ''
        })
    }

    const handleModalValue = () => {
        let payload;
        let documents = [];
        setAddFormSubmit1(true)
        if (validator1.current.allValid()) {
            if (valueModal.action == 'Approve') {
                // setShowValueModal(false)
                if (moment(valueModal.due_date).isSameOrBefore(valueModal.document_issued_date)) {
                    Swal.fire({
                        title: 'Are You Sure?',
                        text: 'Task will move to Delayed Complied Status,do you still want to proceed',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            Setpassvisible1(true);
                            // setShowValueModal(false)

                            // Swal.fire('Closed.!', 'Closed...', 'success');
                        }
                    });
                    setEdit(false)

                }
                else {
                    payload = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "ApproveCompliance",
                                {
                                    "le_id": entityid,
                                    "compliance_history_id": Number(valueModal.compliance_history_id),
                                    "approval_status": [
                                        "Approve"
                                    ],
                                    "remarks": defaultRemarks ? defaultRemarks : null,
                                    "next_due_date": valueModal.next_due_date ? moment(new Date(valueModal.next_due_date)).format('DD-MMM-YYYY') : null,
                                    "validity_date": valueModal.validity_date ? moment(new Date(valueModal.validity_date)).format('DD-MMM-YYYY') : null,
                                    "password": "",
                                    "pwd_validate": false,
                                    "documents": null,
                                    "file_size": 0
                                }
                            ]
                        }
                    ]
                    const payload1 = [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetComplianceApprovalList",
                                {
                                    "le_id": entityid,
                                    "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                                    "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                                    "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                                    "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                                    "start_count": 0
                                }
                            ]
                        }
                    ]
                    setEdit(false)
                    approveCompliance({
                        payload: payload,
                        paramid: paramid,
                        tempvalue: 'Approve',
                        nextData: nextDatas,
                        payload1: payload1

                    })
                    setAddFormSubmit1(false);
                    setValueModal({
                        unit: '',
                        compliance_history_id: '',
                        compliance_task: '',
                        compliance_frequency: '',
                        uploaded_document: '',
                        document_issued_date: '',
                        document_reference_number: '',
                        validity_date: '',
                        next_due_date: '',
                        status: '',
                        remarks: '',
                        action: '',
                        password: '',
                        history_count: '',
                        occurrence_remarks: ''
                    })
                }
                // setShowValueModal(false);
            } else if (valueModal.action == 'Reject Approval') {
                Swal.fire({
                    title: 'Are You Sure?',
                    icon: 'info',
                    html: 'If you Reject,task will move to Not Complied Status,<br/>do you still want to proceed?',
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: 'Ok',
                    cancelButtonText:
                        'Cancel',
                    customClass: localStorage.getItem("currentTheme") == 'theme_four' ? "customSWal" : 'customSWal2',
                    confirmButtonColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 '
                }).then((result) => {
                    if (result.isConfirmed) {
                        Setpassvisible(true);
                    }
                })

            } else {
                if (fileupload && fileupload.length > 0) {
                    for (let i in fileupload) {
                        documents.push({
                            "file_size": fileupload && fileupload.length && fileupload[i] && fileupload[i].file_size,
                            "file_name": fileupload && fileupload.length && fileupload[i] && fileupload[i].file_name,
                            "file_content": null
                        })
                    }
                }
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            'ApproveCompliance', {
                                'le_id': entityid,
                                "compliance_history_id": Number(valueModal.compliance_history_id),
                                'approval_status': [valueModal.action],
                                "remarks": defaultRemarks ? defaultRemarks : null,
                                "next_due_date": valueModal.next_due_date ? moment(new Date(valueModal.next_due_date)).format('DD-MMM-YYYY') : null,
                                "validity_date": valueModal.validity_date ? moment(new Date(valueModal.validity_date)).format('DD-MMM-YYYY') : null,
                                'password': "",
                                'pwd_validate': false,
                                'documents': documents,
                                'file_size': 0
                            }
                        ]
                    }
                ]
                const payload1 = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetComplianceApprovalList",
                            {
                                "le_id": entityid,
                                "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                                "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                                "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                                "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                                "start_count": 0
                            }
                        ]
                    }
                ]
                approveCompliance({
                    payload: payload,
                    paramid: paramid,
                    tempvalue: 'Rectify',
                    nextData: nextDatas,
                    payload1: payload1
                })

                setAddFormSubmit1(false);
                setValueModal({
                    unit: '',
                    compliance_history_id: '',
                    compliance_task: '',
                    compliance_frequency: '',
                    uploaded_document: '',
                    document_issued_date: '',
                    document_reference_number: '',
                    validity_date: '',
                    next_due_date: '',
                    status: '',
                    remarks: '',
                    action: '',
                    password: '',
                    history_count: '',
                    occurrence_remarks: '',
                })
                // setShowValueModal(false);


            }

        }
    }


    const compliancesubmit = () => {
        setAddFormSubmit2(true)
        if (formvalidator.current.allValid()) {
            setEdit(false)
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        'ApproveCompliance', {
                            'le_id': entityid,
                            "compliance_history_id": Number(valueModal.compliance_history_id),
                            'approval_status': [valueModal.action],
                            "remarks": defaultRemarks ? defaultRemarks : null,
                            "next_due_date": valueModal.next_due_date ? moment(new Date(valueModal.next_due_date)).format('DD-MMM-YYYY') : null,
                            "validity_date": valueModal.validity_date ? moment(new Date(valueModal.validity_date)).format('DD-MMM-YYYY') : null,
                            'password': password.passvalue,
                            'pwd_validate': true,
                            'documents': null,
                            'file_size': 0
                        }
                    ]
                }
            ]
            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApprovalList",
                        {
                            "le_id": entityid,
                            "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                            "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                            "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                            "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                            "start_count": 0
                        }
                    ]
                }
            ]
            approveCompliance({
                payload: payload,
                paramid: paramid,
                tempvalue: 'Reject',
                nextData: nextDatas,
                payload1: payload1,
                modal: Setpassvisible
            })
            setAddFormSubmit2(false)
            setAddFormSubmit1(false)
            setpassword({
                ...password,
                passvalue: ''
            })
            // setValueModal({
            //     unit: '',
            //     compliance_history_id: '',
            //     compliance_task: '',
            //     compliance_frequency: '',
            //     uploaded_document: '',
            //     document_issued_date: '',
            //     document_reference_number: '',
            //     validity_date: '',
            //     next_due_date: '',
            //     status: '',
            //     remarks: '',
            //     action: '',
            //     password: '',
            //     history_count: '',
            //     occurrence_remarks: '',
            // })
        }
        //}
    }



    const handleCancel = () => {
        setRemarksModal(false)
        setEdit(false)
    };

    const Cancelmodal = () => {
        setAddFormSubmit1(false)
        setShowValueModal(false);
        setValueModal({
            ...valueModal,
            action: '',
            remarks: '',
            validity_date: '',
            next_due_date: ''
        })
        setEdit(false)

    }

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content,
        };

        return result;
    }

    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }
    useEffect(() => {
        if (updatedArray && updatedArray.length > 0) {
            let uniqueObjArray = [
                ...new Map(
                    updatedArray &&
                    updatedArray.length > 0 &&
                    updatedArray.map((item) => [item["unit_id"], item])
                ).values(),
            ];
            const uniqueIds =
                uniqueObjArray &&
                uniqueObjArray.map((item) => {
                    return item.unit_id;
                });
            let tempArr = [];
            for (let i in uniqueIds) {
                let filteredArr = _.filter(
                    updatedArray && updatedArray.length > 0 && updatedArray,
                    { unit_id: uniqueIds[i] }
                );
                tempArr.push(filteredArr);
            }
            setFilteredArray([tempArr]);
        } else {
            setFilteredArray([]);
        }
    }, [updatedArray]);
    useEffect(() => {
        let tempArr = [];
        if (updatedArray && updatedArray.length > 0) {
            filteredArrayState &&
                filteredArrayState.length > 0 &&
                filteredArrayState.map((item, i) => {
                    let uniqueObjArray = [
                        ...new Map(
                            item &&
                            item.length > 0 &&
                            item.map((items) => [items["assignee_name"], items])
                        ).values(),
                    ];
                    const uniqueIds =
                        uniqueObjArray.length > 0 &&
                        uniqueObjArray.map((itemData) => {
                            return itemData.assignee_name;
                        });
                    for (let i in uniqueIds) {
                        let filteredArr = _.find(item && item.length > 0 && item, {
                            assignee_name: uniqueIds[i],
                        });
                        tempArr.push(filteredArr);
                    }
                });
            setAssigneename(tempArr);
        }
    }, [filteredArrayState]);
    useEffect(() => {
        if (updatedArray !== null) {
            // settotalRecord(updatedArray.length);
            let totalList = [];
            let arr = [];
            let countrylisttempdata = [];
            let tempSetArray = [];
            let parId = [];
            assigneename &&
                assigneename.length > 0 &&
                assigneename.map(function (user) {
                    if (arr.indexOf(user.assignee_name) < 0)
                        arr.push({
                            assignee_name: user.assignee_name,
                            unit_name: user.unit_name,
                        });
                });
            arr.map((pmenu, index) => {
                // deepscan-disable-line ARRAY_CALLBACK_RETURN_MISSING
                let entityArray = [];
                let tempArray = [];
                let unit_name = [];
                if (pmenu) {
                    updatedArray &&
                        updatedArray.map((subMenuList, childIndex) => {
                            if (
                                pmenu.assignee_name === subMenuList.assignee_name &&
                                pmenu.unit_name === subMenuList.unit_name
                            ) {
                                entityArray = {
                                    action: subMenuList.action,
                                    approve_status: subMenuList.approve_status,
                                    assignee_id: subMenuList.assignee_id,
                                    assignee_name: subMenuList.assignee_name,
                                    comp_history_count: subMenuList.comp_history_count,
                                    compfielaw_url: subMenuList.compfielaw_url,
                                    completion_date: subMenuList.completion_date,
                                    compliance_history_id: subMenuList.compliance_history_id,
                                    compliance_name: subMenuList.compliance_name,
                                    compliance_task_frequency: subMenuList.compliance_task_frequency,
                                    concurrence_status: subMenuList.concurrence_status,
                                    concurrenced_by: subMenuList.concurrenced_by,
                                    current_status: subMenuList.current_status,
                                    delayed_by: subMenuList.delayed_by,

                                    description: subMenuList.description,

                                    doc_ref_num: subMenuList.doc_ref_num,

                                    domain_id: subMenuList.domain_id,

                                    domain_name: subMenuList.domain_name,

                                    due_date: subMenuList.due_date,

                                    extend_due_date: subMenuList.extend_due_date,

                                    fileSize: subMenuList.fileSize,

                                    file_names: subMenuList.file_names,

                                    history_count: subMenuList.history_count,

                                    interim_count: subMenuList.interim_count,

                                    is_active: subMenuList.is_active,


                                    is_ctcy_task: subMenuList.is_ctcy_task,

                                    next_due_date: subMenuList.next_due_date,

                                    occurrence_remarks: subMenuList.occurrence_remarks,

                                    remarks: subMenuList.remarks,

                                    start_date: subMenuList.start_date,

                                    statu: subMenuList.statu,

                                    statutory_dates: subMenuList.statutory_dates,

                                    system_remarks: subMenuList.system_remarks,

                                    tz_date: subMenuList.tz_date,

                                    tz_name: subMenuList.tz_name,

                                    unit_address: subMenuList.unit_address,

                                    unit_id: subMenuList.unit_id,

                                    unit_name: subMenuList.unit_name,

                                    upload_date: subMenuList.upload_date,

                                    uploaded_documents: subMenuList.uploaded_documents,

                                    validity_date: subMenuList.validity_date,

                                    validity_settings_days: subMenuList.validity_settings_days,

                                    parentId: index,
                                    childId: childIndex,
                                    indexNo: childIndex + 1,
                                    uniqueId: Math.floor(Math.random() * 100)
                                };
                                tempArray.push(entityArray);
                                console.log(tempArray, "tempArray_task");
                                unit_name.push(subMenuList.unit_name);
                            }
                        });
                }
                let checkarray = []
                sortByMonth(tempArray);
                function sortByMonth(arr) {
                    console.log(arr, 'arrrrrrrrr');
                    var compliance_frequency = ["One Time", "Periodical", "Review", "Flexi Review", "On Occurrence",];
                    arr.sort(function (a, b) {
                        return compliance_frequency.indexOf(a.compliance_task_frequency
                        )
                            - compliance_frequency.indexOf(b.compliance_task_frequency
                            );
                    });
                }
                console.log(tempArray, 'tempArray555');
                // let listArray_One_time = _.filter(tempArray, { compliance_frequency: "One Time" });
                // if (listArray_One_time && listArray_One_time.length > 0) {
                //     for (let i in listArray_One_time) {
                //         checkarray.push(listArray_One_time[i])
                //     }
                // }
                // let listArray_periodical = _.filter(tempArray, { compliance_frequency: "Periodical" });
                // if (listArray_periodical && listArray_periodical.length > 0) {
                //     for (let i in listArray_periodical) {
                //         checkarray.push(listArray_periodical[i])
                //     }
                // }
                // let listArray_Review = _.filter(tempArray, { compliance_frequency: "Review" });
                // if (listArray_Review && listArray_Review.length > 0) {
                //     for (let i in listArray_Review) {
                //         checkarray.push(listArray_Review[i])
                //     }
                // }
                // let listArray_Flexi_Review = _.filter(tempArray, { compliance_frequency: "Flexi Review" });
                // if (listArray_Flexi_Review && listArray_Flexi_Review.length > 0) {
                //     for (let i in listArray_Flexi_Review) {
                //         checkarray.push(listArray_Flexi_Review[i])
                //     }
                // }
                // let listArray_On_Occurrence = _.filter(tempArray, { compliance_frequency: "On Occurrence" });
                // if (listArray_On_Occurrence && listArray_On_Occurrence.length > 0) {
                //     for (let i in listArray_On_Occurrence) {
                //         checkarray.push(listArray_On_Occurrence[i])
                //     }
                // }
                console.log(checkarray, 'checkarray');

                totalList = {
                    index: index,
                    parentData: pmenu,
                    child: tempArray,
                };

                // titleDynamic[index] = pmenu;
                countrylisttempdata.push(totalList);
                tempSetArray.push(tempArray);
                parId.push(unit_name);
            });
            // setTitleDynamic([...titleDynamic]);
            var merged = [].concat.apply([], tempSetArray);
            setTemp(countrylisttempdata);
            // setMergedArray(merged);
            setTempFilterArray([...new Set([].concat.apply([], parId))]);
        }
    }, [updatedArray, assigneename]);

    const SavepastRecordOnChange = (e) => {
        if (e.target.name === 'file') {
            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            let files = e.target.files
            let file_max_size = 26214400
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));

                if (fileRegex.test(f_name) === false) {
                    // toast.error("File Name have not allowed special charaters")

                    return;
                }
                let file_size = file.size;
                if (file_size > file_max_size) {
                    toast.error("Maximum filesize limit exceed")
                    return;
                }
                if (file) {
                    convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                        var fN = name.substring(0, name.lastIndexOf('.'));
                        var fE = name.substring(name.lastIndexOf('.') + 1);
                        var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                        var f_Name = fN + '-' + uniqueId + '.' + fE;

                        var result = uploadFileFormat(size, f_Name, file_content);
                        setFileData(result)
                        results.push(result);


                        let temp1 = fileupload
                        let temp = []
                        if (results && results.length == 1) {
                            for (let i in results) {
                                temp1.push(results[i])
                            }
                            setFileUpload([...temp1])
                        }
                        else {
                            for (let i in results) {
                                temp.push(results[i])
                            }
                            setFileUpload([...temp])
                        }

                        const filePayload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "UploadComplianceTask_temp_upload",
                                    {
                                        "le_id": entityid,
                                        "c_id": 1,
                                        "d_id": valueForFileUpload.d_id,
                                        "u_id": valueForFileUpload.u_id,
                                        "start_date": valueForFileUpload.start_date,
                                        "file_info": results,
                                        "compliance_history_id": Number(valueModal.compliance_history_id),
                                    }
                                ]
                            }
                        ]
                        if (fileRegex.test(f_name) === false) {
                            UploadFile({
                                payload: filePayload,
                                paramid: paramid
                            })
                        }
                        else {
                            console.log("truefalse");

                        }
                    });
                }
            }
        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            )
        })
    }


    const passwordcancel = () => {
        setAddFormSubmit1(false)
        Setpassvisible(false);
        setValueModal({
            ...valueModal,
            password: ''
        })
        setpassword({
            ...password,
            passvalue: ''
        })

    }

    useEffect(() => {
        if (fullscreen == true) {
            document.addEventListener('fullscreenchange', handleKeyDown, false);
            document.addEventListener('webkitfullscreenchange', handleKeyDown, false);
            return () => {
                document.addEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('fullscreenchange', handleKeyDown, false);
                document.removeEventListener('webkitfullscreenchange', handleKeyDown);
            };
        }
    }, [fullscreen]);

    const fullscreenMode = () => {
        if (fullscreen == false) {
            fullscreenmode();
            setfullscreen(true);
        } else {
            fullscreenexit();
            setfullscreen(false);
        }
    }
    const setcancelFilter = () => {
        setFilterModalVisible(false);
        setFilterTaskAcc(false);
    };
    const filterHandle = () => {
        setFilterTaskAcc(true);
        setFilterModalVisible(true);
    };
    const homescreen = () => {
        console.log(list, "listlis55555t");
        window.location.href = homepage
        document.getElementById('homehover').style.color = "blue"
    }
    console.log(list, "listlist");
    console.log(valueModal, "valueModalvalueModal");
    return (
        <div className="page-wrapper" id='page-wrapper'>
            {/* <ScrollToTop smooth color="red" component={<Tooltip title="Back to top"><UpOutlined /></Tooltip>} />
            {document.body.scrollHeight > document.body.clientHeight ?
                <div className='back-to-top'>
                    <ScrollButton />
                </div> : ''} */}
            <div className='back-to-top'>
                <ScrollButton />
            </div>
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <span className='pointer' id='homehover' onClick={homescreen}><i className="ri-home-3-line fs-5"></i></span>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <span style={{ fontSize: '16px' }}>Transaction</span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <span>Compliance Approval</span>
                                </li>
                            </ol>
                            {/* <h2 className="mb-0 fw-bold"><b>Compliance Approval</b></h2> */}
                        </nav>

                    </div>
                    <div className="col-lg-4 text-end">
                        <Tooltip placement='top' title='Advanced Filter'><Button
                            className="mx-1"
                            type="primary"
                            shape="round"
                            style={{
                                display: filterTaskAcc ? "none" : "initial",
                            }}
                            onClick={filterHandle}
                        >
                            <FilterOutlined /> Filter
                        </Button></Tooltip>
                        <button onClick={(e) => { fullscreenMode() }} className="bg-transparent text-black ms-2 full-mode fullscreenradius"  >
                            <Tooltip placement="top" title={sessionStorage.getItem('isFullScreen') == 'true' ? "Exit Full Screen Mode" : "Full Screen Mode"}><FullscreenOutlined /></Tooltip>
                        </button>
                    </div>

                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className={localStorage.getItem('currentTheme') + ' col-lg-12 card-body1-statuatory'}>
                        <Collapse accordion bordered={true} defaultActiveKey={['1']} style={{ display: exitCollapse ? 'none' : 'block' }}>
                            <Panel className='report-collapse' header="Compliance Approval" key="1">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}> *</span><br />
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Legal Entity"
                                                            onDrop={(e) => {
                                                                e.preventDefault();
                                                                Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                                return false;
                                                            }}
                                                            onPaste={(e) => {
                                                                handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                            }}
                                                            onChange={(data, value) => {
                                                                if (data != undefined) {
                                                                    setCurrentEntity(data)
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        le_id: data,
                                                                        domain_name: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        frequency_name: ''
                                                                    })
                                                                }
                                                                else {
                                                                    setCurrentEntity('')
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        le_id: '',
                                                                        domain_name: '',
                                                                        unit_name: '',
                                                                        user_name: '',
                                                                        frequency_name: ''
                                                                    })
                                                                }
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            value={showFilterData.le_id || undefined}
                                                            style={{ width: '100%', marginTop: '5px' }}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'le_id',
                                                            showFilterData.le_id,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Required',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p title={localStorage.getItem('SelectedEntity')} className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Domain : </b></label><br />
                                                <div className="form-group">
                                                    <Select
                                                        disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Domain"
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z -]+$/gi)
                                                        }}
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                setShowFilterData({
                                                                    ...showFilterData,
                                                                    d_id: data,
                                                                    domain_name: value.children,
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    frequency_name: ''
                                                                })
                                                            }
                                                            else {
                                                                setShowFilterData({
                                                                    ...showFilterData,
                                                                    d_id: '',
                                                                    domain_name: '',
                                                                    unit_name: '',
                                                                    user_name: '',
                                                                    frequency_name: ''
                                                                })
                                                            }

                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showFilterData.domain_name || undefined}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                    >
                                                        {domainList && domainList.length && domainList.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Unit : </b></label><br />
                                                <div className="form-group">
                                                    <Select
                                                        disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Unit"
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        }}
                                                        onChange={(data, value) => {
                                                            console.log(data, value, 'samefeeling');
                                                            if (data !== undefined) {
                                                                setShowFilterData({
                                                                    ...showFilterData,
                                                                    unit_id: data,
                                                                    unit_name: value.children
                                                                })
                                                            }
                                                            else {
                                                                setShowFilterData({
                                                                    ...showFilterData,
                                                                    unit_id: '',
                                                                    unit_name: ''
                                                                })
                                                            }
                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showFilterData.unit_name || undefined}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                    >
                                                        {unitList && unitList.length && unitList.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ marginTop: '10px' }}>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Frequency : </b></label><br />

                                                <div className="form-group">
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Select Frequency"
                                                        // className='select2 form-control custom-select bg-white'
                                                        value={showFilterData.frequency_name || undefined}
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                setShowFilterData({
                                                                    ...showFilterData,
                                                                    frequency_id: data,
                                                                    frequency_name: value.children
                                                                })
                                                            }
                                                            else {
                                                                setShowFilterData({
                                                                    ...showFilterData,
                                                                    frequency_id: '',
                                                                    frequency_name: ''
                                                                })
                                                            }
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                    >
                                                        <Option key={0}>All</Option>
                                                        {frequencyList && frequencyList.length && frequencyList.map((item) => {
                                                            return <Option key={item.frequency_id}>{item.frequency_name}</Option>
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>User : </b></label><br />
                                                <div className="form-group">
                                                    <Select

                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter User"
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                            return false;
                                                        }}
                                                        onPaste={(e) => {
                                                            handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                        }}
                                                        onChange={(data, value) => {
                                                            if (data !== undefined) {
                                                                setShowFilterData({
                                                                    ...showFilterData,
                                                                    us_id: data,
                                                                    user_name: value.children
                                                                })
                                                            }
                                                            else {
                                                                setShowFilterData({
                                                                    ...showFilterData,
                                                                    us_id: '',
                                                                    user_name: ''
                                                                })
                                                            }
                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={showFilterData.user_name || undefined}
                                                        style={{ width: '100%', marginTop: '5px' }}
                                                    >
                                                        {userList && userList.length && userList.map((item, i) => {
                                                            return (
                                                                <Option key={item.user_id}>
                                                                    {item.employee_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 pt-2 pb-1" style={{ textAlign: 'center' }}>
                                    {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                    <Button type="primary" shape="round" className='addbutton' style={{
                                        background: "#0096FF", borderColor: "#0096FF"
                                    }} icon={<EyeOutlined />}
                                        size='default'
                                        onClick={() => {
                                            setListArray(true)
                                            setAddFormSubmit(true);
                                            setTabvalue(true);
                                            if (validator.current.allValid()) {
                                                setExitCollapse(true);
                                                setFilterTaskAcc(false);
                                                setFilterModalVisible(false);
                                                const payload = [
                                                    authtoken,
                                                    {
                                                        "session_token": authtoken,
                                                        "request": [
                                                            "GetComplianceApprovalList",
                                                            {
                                                                "le_id": entityid,
                                                                "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                                                                "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                                                                "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                                                                "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                                                                "start_count": 0
                                                            }
                                                        ]
                                                    }
                                                ]

                                                complianceApprovallist({
                                                    payload: payload,
                                                    paramid: paramid
                                                })
                                            }
                                        }}
                                    >Show</Button>
                                </div>
                            </Panel>
                        </Collapse>
                        <Modal
                            onCancel={setcancelFilter}
                            footer={false}
                            title="Compliance Approval"
                            className={
                                "add-service-prv modal-service-priv " +
                                localStorage.getItem("currentTheme")
                            }
                            visible={filterModalVisible}
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor=""><b>Legal Entity : </b></label><span style={{ color: "red" }}> *</span><br />
                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                            <div className="form-group">
                                                <Select
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Select Legal Entity"
                                                    onDrop={(e) => {
                                                        e.preventDefault();
                                                        Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                        return false;
                                                    }}
                                                    onPaste={(e) => {
                                                        handlePasting(e, /^[a-z 0-9 ()]+$/gi)
                                                    }}
                                                    onChange={(data, value) => {
                                                        if (data != undefined) {
                                                            setCurrentEntity(data)
                                                            setShowFilterData({
                                                                ...showFilterData,
                                                                le_id: data,
                                                                domain_name: '',
                                                                unit_name: '',
                                                                user_name: '',
                                                                frequency_name: ''
                                                            })
                                                        }
                                                        else {
                                                            setCurrentEntity('')
                                                            setShowFilterData({
                                                                ...showFilterData,
                                                                le_id: '',
                                                                domain_name: '',
                                                                unit_name: '',
                                                                user_name: '',
                                                                frequency_name: ''
                                                            })
                                                        }
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={showFilterData.le_id || undefined}
                                                    style={{ width: '100%', marginTop: '5px' }}
                                                >
                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                        return (
                                                            <Option key={item.le_id}>
                                                                {item.le_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'le_id',
                                                    showFilterData.le_id,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Legal Entity Required',
                                                        }
                                                    })}
                                            </div> :
                                            <p title={localStorage.getItem('SelectedEntity')} className='cut-text-word' style={{ width: '100%', marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>

                                        }
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor=""><b>Domain : </b></label><br />
                                        <div className="form-group">
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Enter Domain"
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z -]+$/gi)
                                                }}
                                                onChange={(data, value) => {
                                                    if (data !== undefined) {
                                                        setShowFilterData({
                                                            ...showFilterData,
                                                            d_id: data,
                                                            domain_name: value.children,
                                                            unit_name: '',
                                                            user_name: '',
                                                            frequency_name: ''
                                                        })
                                                    }
                                                    else {
                                                        setShowFilterData({
                                                            ...showFilterData,
                                                            d_id: '',
                                                            domain_name: '',
                                                            unit_name: '',
                                                            user_name: '',
                                                            frequency_name: ''
                                                        })
                                                    }

                                                }}
                                                // onSearch={onSearch}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                value={showFilterData.domain_name || undefined}
                                                style={{ width: '100%', marginTop: '5px' }}
                                            >
                                                {domainList && domainList.length && domainList.map((item, i) => {
                                                    return (
                                                        <Option key={item.d_id}>
                                                            {item.d_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6 mt-1">
                                    <div className="form-group">
                                        <label htmlFor=""><b>Unit : </b></label><br />
                                        <div className="form-group">
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Enter Unit"
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                }}
                                                onChange={(data, value) => {
                                                    if (data !== undefined) {
                                                        setShowFilterData({
                                                            ...showFilterData,
                                                            unit_id: data,
                                                            unit_name: value.children
                                                        })
                                                    }
                                                    else {
                                                        setShowFilterData({
                                                            ...showFilterData,
                                                            unit_id: '',
                                                            unit_name: ''
                                                        })
                                                    }
                                                }}
                                                // onSearch={onSearch}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                value={showFilterData.unit_name || undefined}
                                                style={{ width: '100%', marginTop: '5px' }}
                                            >
                                                {unitList && unitList.length && unitList.map((item, i) => {
                                                    return (
                                                        <Option key={item.unit_id}>
                                                            {item.unit_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>


                                    </div>
                                </div>
                                <div className="col-md-6 mt-1">
                                    <div className="form-group">
                                        <label htmlFor=""><b>Frequency : </b></label><br />

                                        <div className="form-group">
                                            <Select allowClear={true}
                                                getPopupContainer={trigger => trigger.parentNode}
                                                size="default"
                                                placeholder="Select Frequency"
                                                // className='select2 form-control custom-select bg-white'
                                                value={showFilterData.frequency_name || undefined}
                                                onChange={(data, value) => {
                                                    if (data !== undefined) {
                                                        setShowFilterData({
                                                            ...showFilterData,
                                                            frequency_id: data,
                                                            frequency_name: value.children
                                                        })
                                                    }
                                                    else {
                                                        setShowFilterData({
                                                            ...showFilterData,
                                                            frequency_id: '',
                                                            frequency_name: ''
                                                        })
                                                    }
                                                }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                style={{ width: '100%', marginTop: '5px' }}
                                            >
                                                <Option key={0}>All</Option>
                                                {frequencyList && frequencyList.length && frequencyList.map((item) => {
                                                    return <Option key={item.frequency_id}>{item.frequency_name}</Option>
                                                })}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-1">
                                    <div className="form-group">
                                        <label htmlFor=""><b>User : </b></label><br />
                                        <div className="form-group">
                                            <Select
                                                getPopupContainer={trigger => trigger.parentNode}
                                                allowClear={true}
                                                size="default"
                                                placeholder="Enter User"
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    Toaster.warning('System removed the drop characters, Please enter or paste the  valid data.')
                                                    return false;
                                                }}
                                                onPaste={(e) => {
                                                    handlePasting(e, /^[a-z 0-9 -]+$/gi)
                                                }}
                                                onChange={(data, value) => {
                                                    if (data !== undefined) {
                                                        setShowFilterData({
                                                            ...showFilterData,
                                                            us_id: data,
                                                            user_name: value.children
                                                        })
                                                    }
                                                    else {
                                                        setShowFilterData({
                                                            ...showFilterData,
                                                            us_id: '',
                                                            user_name: ''
                                                        })
                                                    }
                                                }}
                                                // onSearch={onSearch}
                                                showSearch
                                                disabled={entityid == "" || entityid == null || entityid == 'null' ? true : false}
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                value={showFilterData.user_name || undefined}
                                                style={{ width: '100%', marginTop: '5px' }}
                                            >
                                                {userList && userList.length && userList.map((item, i) => {
                                                    return (
                                                        <Option key={item.user_id}>
                                                            {item.employee_name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </div>


                                    </div>
                                </div>
                                <div className="col-md-6 mt-1">
                                </div>
                            </div>
                            <div className={"col-md-12 pt-2 pb-1 popupbtncolour " + localStorage.getItem('currentTheme')} style={{ textAlign: 'center' }}>
                                {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} style={{
                                    background: "#0096FF", borderColor: "#0096FF"
                                }} icon={<EyeOutlined />}
                                    size='default'
                                    onClick={() => {
                                        setListArray(true)
                                        setAddFormSubmit(true);
                                        setTabvalue(true);
                                        if (validator.current.allValid()) {
                                            setExitCollapse(true);
                                            setFilterTaskAcc(false);
                                            setShowValueModal(false);
                                            setFilterModalVisible(false);
                                            const payload = [
                                                authtoken,
                                                {
                                                    "session_token": authtoken,
                                                    "request": [
                                                        "GetComplianceApprovalList",
                                                        {
                                                            "le_id": entityid,
                                                            "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                                                            "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                                                            "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                                                            "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                                                            "start_count": 0
                                                        }
                                                    ]
                                                }
                                            ]

                                            complianceApprovallist({
                                                payload: payload,
                                                paramid: paramid
                                            })
                                        }
                                    }}
                                >Show</Button>
                            </div>
                        </Modal>
                        {tabValue == true ?
                            // <div>

                            //     {listArray === false ?
                            //         <div>
                            //             <Collapse className='mt-2' defaultActiveKey={['1']} accordion>
                            //                 <Panel className='report-collapse' header="Compliance Table" key="0">

                            //                     {tabledata && Array.isArray(tabledata) && tabledata.length > 0 ?
                            //                         <div>
                            //                             {tabledata.map((item, i) => {

                            //                                 return (

                            //                                     <Table
                            //                                         columns={columns}
                            //                                         className={localStorage.getItem("currentTheme") + 'edit-user-priv'}
                            //                                         // size={'small'}
                            //                                         // className='userprivclass'
                            //                                         dataSource={item}
                            //                                         bordered
                            //                                         title={() => {
                            //                                             return item ? item && item.length && item[0].unit_name : 'Table'
                            //                                         }}
                            //                                         scroll={{ y: 400 }}
                            //                                         pagination={{
                            //                                             defaultPageSize: dataTableProperties.pagesize,
                            //                                             showSizeChanger: dataTableProperties.sizechanger
                            //                                         }}
                            //                                     />
                            //                                 )
                            //                             })}</div> :
                            //                         <div>
                            //                             <span>No Compliance Available</span>
                            //                         </div>}

                            //                 </Panel>
                            //             </Collapse>
                            //             {tabledata.length > 0 ?
                            //                 <div className='col-md-12' style={{ marginTop: "10px" }}>
                            //                     <div className='row'>
                            //                         <div className='col-md-6' >
                            //                             <label style={{ marginLeft: "28px" }}>Total Compliance : {tabledata.length}</label>
                            //                         </div>
                            //                     </div>
                            //                 </div> : false}
                            //         </div> :
                            //         <div>
                            //             <Collapse className='mt-2' defaultActiveKey={['0']} accordion>
                            //                 <Panel className='report-collapse' header="Compliance Table" key="0">
                            //                     {finallist && finallist.length > 0 ?
                            //                         <Table
                            //                             // size={'small'}
                            //                             // className='userprivclass'
                            //                             id="ComplianceApproval"
                            //                             className={localStorage.getItem("currentTheme")}
                            //                             columns={columns}
                            //                             dataSource={finallist}
                            //                             bordered
                            //                             title={() => {
                            //                                 return finallist && finallist.length > 0 ? <><span>{finallist[0].unit_name}</span> </> : null
                            //                             }}
                            //                             scroll={{ y: 1000 }}
                            //                             pagination={false}
                            //                         /> :
                            //                         <div>
                            //                             <span style={{ marginLeft: '40%', fontWeight: 'bold' }}>No Compliance Available</span>
                            //                         </div>}
                            //                 </Panel>
                            //             </Collapse>
                            //             {tabledata.length > 0 ?
                            //                 <div className='col-md-12 mt-1'>
                            //                     <div className='row'>
                            //                         <div className='col-md-6' >
                            //                             <label>Total Compliance : {list.length}</label>
                            //                         </div>
                            //                     </div>
                            //                 </div> : false}
                            //         </div>
                            //     }
                            // </div> 
                            <>
                                <div
                                    className={
                                        localStorage.getItem("currentTheme") +
                                        " ant-table-wrapper"
                                    } style={{ width: '100%' }}
                                >
                                    <div className="row">

                                        <div className="ant-table ant-table-bordered ant-table-fixed-header"
                                            //  id='service-table-action'
                                            style={{ width: showValueModal == true ? '60%' : '100%', height: showValueModal == true ? '750px' : '100%', overflowY: showValueModal == true ? 'scroll' : '' }}>
                                            <div className="ant-table-container">
                                                <div className="ant-table-content taskrecalltable service-table">

                                                    <table className="table-fixed" >
                                                        <thead className="ant-table-thead">
                                                            <tr>
                                                                <th className="ant-table-cell" style={{ width: "5px" }}>
                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                        <span className="me-1"># </span>
                                                                        <div className="position-relative" ref={wrapperRef}>
                                                                            <button onClick={(e) => { setshowDropDown(current => !current) }}>
                                                                                <FilterFilled />
                                                                            </button>

                                                                            {/* <button className="col-form-label" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                              <FilterFilled />
                                            </button> */}
                                                                            {showDropDown && (
                                                                                <Form onFinish={onFinish} className='bg-white position-absolute text-start shadow-lg' style={{ borderRadius: '15px', top: '32px', width: '165px' }}>

                                                                                    <Form.Item className="mt-1" name={'periodical'} >
                                                                                        &nbsp;<Checkbox id={'periodical'} value={'periodical'} checked={checked.periodical} onChange={(e) => {
                                                                                            e.target.checked ? setChecked({
                                                                                                ...checked,
                                                                                                periodical: true
                                                                                            }) : setChecked({
                                                                                                ...checked,
                                                                                                periodical: false
                                                                                            })
                                                                                            console.log(e.target.checked)
                                                                                            setFilterValues({
                                                                                                ...filterValues,
                                                                                                periodical: e.target.checked
                                                                                            })
                                                                                        }} />&nbsp; <label className="fw-normal user-select-none" htmlFor="periodical"><i style={{ color: 'green' }} className="bi bi-square-fill"></i> Periodical</label>
                                                                                    </Form.Item>
                                                                                    <Form.Item name={'flexi'}>
                                                                                        &nbsp;<Checkbox id="flexi" value={'flexi'} checked={checked.flexi} onChange={(e) => {
                                                                                            e.target.checked ? setChecked({
                                                                                                ...checked,
                                                                                                flexi: true
                                                                                            }) : setChecked({
                                                                                                ...checked,
                                                                                                flexi: false
                                                                                            })
                                                                                            console.log(e, e.target.value)
                                                                                            setFilterValues({
                                                                                                ...filterValues,
                                                                                                flexi: e.target.checked
                                                                                            })
                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="flexi"><i style={{ color: "pink" }} className="bi bi-square-fill"></i> Flexi Review</label>
                                                                                    </Form.Item>
                                                                                    <Form.Item name={'Review'}>
                                                                                        &nbsp;<Checkbox id="Review" value={'Review'} checked={checked.review} onChange={(e) => {
                                                                                            e.target.checked ? setChecked({
                                                                                                ...checked,
                                                                                                review: true
                                                                                            }) : setChecked({
                                                                                                ...checked,
                                                                                                review: false
                                                                                            })
                                                                                            console.log(e, e.target.value)
                                                                                            setFilterValues({
                                                                                                ...filterValues,
                                                                                                review: e.target.checked
                                                                                            })
                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="Review"><i style={{ color: "orange" }} className="bi bi-square-fill"></i> Review</label>
                                                                                    </Form.Item>
                                                                                    <Form.Item name={'On Occurrence'}>
                                                                                        &nbsp;<Checkbox id="On-Occurrence" value={'On Occurrence'} checked={checked.on_occurrence} onChange={(e) => {
                                                                                            e.target.checked ? setChecked({
                                                                                                ...checked,
                                                                                                on_occurrence: true
                                                                                            }) : setChecked({
                                                                                                ...checked,
                                                                                                on_occurrence: false
                                                                                            })
                                                                                            console.log(e, e.target.value)
                                                                                            setFilterValues({
                                                                                                ...filterValues,
                                                                                                on_occurrence: e.target.checked
                                                                                            })
                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="On-Occurrence"><i style={{ color: "yellow" }} className="bi bi-square-fill"></i> On Occurrence</label>
                                                                                    </Form.Item>
                                                                                    <Form.Item className="mb-1" name={' One Time'}>
                                                                                        &nbsp;<Checkbox id="One-Time" value={'One Time'} checked={checked.one_time} onChange={(e) => {
                                                                                            e.target.checked ? setChecked({
                                                                                                ...checked,
                                                                                                one_time: true
                                                                                            }) : setChecked({
                                                                                                ...checked,
                                                                                                one_time: false
                                                                                            })
                                                                                            console.log(e, e.target.value)
                                                                                            setFilterValues({
                                                                                                ...filterValues,
                                                                                                one_time: e.target.checked
                                                                                            })
                                                                                        }} /> &nbsp;<label className="fw-normal user-select-none" htmlFor="One-Time"><i style={{ color: "red" }} className="bi bi-square-fill"></i> One Time</label>
                                                                                    </Form.Item>
                                                                                    <Form.Item className="submitButtonFrm" >
                                                                                        <Button style={{ borderRadius: '10px', border: 'unset' }} className="statistic" type="primary" htmlType="submit">OK</Button>
                                                                                        <a className="link ms-2" onClick={() => { resetfilter() }}>Reset</a>
                                                                                    </Form.Item>
                                                                                </Form>
                                                                            )}
                                                                        </div>

                                                                        {/* <button className="col-form-label" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <FilterFilled />
                                                                    </button> */}
                                                                        {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                        <li onClick={(e) => { frequencyfilter('All') }}><a className="dropdown-item"><b>All</b></a></li>
                                                                        <li onClick={(e) => { frequencyfilter('periodical') }}><a className="dropdown-item" style={{ background: "green", color: "white" }}>Periodical</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('flexi') }}><a className="dropdown-item" style={{ background: "pink", color: "black" }}>FlexiReview</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('Review') }}><a className="dropdown-item" style={{ background: "orange", color: "black" }}>Review</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('On Occurrence') }}><a className="dropdown-item" style={{ background: "yellow", color: "black" }}>OnOccurrence</a></li>
                                                                        <li onClick={(e) => { frequencyfilter('One Time') }}><a className="dropdown-item" style={{ background: "red", color: "white" }}>Onetime</a></li>
                                                                    </ul> */}
                                                                    </div>
                                                                    {/* <div id="filter" style={{position: 'relative'}}>
                                            <div style={{position: 'absolute',marginLeft: '33px', cursor: 'pointer' }} > # 
                                          <FilterFilled style={{ marginLeft: '8px' }} /></div>
                                          <Select  style={{ width: "80px", opacity: 0 }} value={all}
                                          
                                            onChange={(data) => {
                                              frequencyfilter(data);
                                            }}
                                          >
                                            <Option l value="All">All</Option>
                                            <Option value="periodical" style={{ background: "green", color: "white" }}>
                                              Periodical
                                            </Option>
                                            <Option
                                              value="flexi"
                                              style={{
                                                background: "pink",
                                                color: "black",
                                              }}
                                            >
                                              FlexiReview
                                            </Option>
                                            <Option
                                              value="Review"
                                              style={{
                                                background: "orange",
                                                color: "black",
                                              }}
                                            >
                                              Review
                                            </Option>
                                            <Option
                                              value="On Occurrence"
                                              style={{
                                                background: "yellow",
                                                color: "black",
                                              }}
                                            >
                                              OnOccurrence
                                            </Option>
                                            <Option
                                              value="One Time"
                                              style={{
                                                background: "red",
                                                color: "white",
                                              }}
                                            >
                                              Onetime
                                            </Option>
                                          </Select>
                                          </div> */}
                                                                </th>
                                                                {/* <th
                                          className="ant-table-cell"
                                          style={{ width: "20px" }}
                                        >
                                          {/* Action {' '} */}
                                                                {/* <input
                                                            type="checkbox"
                                                            title="Click here to approve all"
                                                            name="allchecked"
                                                            className={"allChecked"}
                                                            onClick={(e) => {
                                                                let checked = e.target.checked;
                                                                addAllSelectedDataValues(checked);
                                                            }}
                                                        /> */}
                                                                {/* </th> */}
                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "288px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p
                                                                            className="p-0 mb-0"
                                                                            style={{
                                                                                marginLeft: "36%",
                                                                                display: "inline",
                                                                            }}
                                                                        >
                                                                            Compliance Task
                                                                        </p>{" "}
                                                                        <Popconfirm
                                                                            placement="bottom"
                                                                            title={
                                                                                <Input
                                                                                    placeholder="Compliance Task"
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "Compliance_Task"
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>
                                                                    {/* <Input placeholder='Compliance Task' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Compliance_Task')
                                                                          }} /> */}
                                                                </th>
                                                                {/* <th className='ant-table-cell' style={{ width: '10%' }}>Assignee <Input placeholder='Assignee' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Assignee')
                                                                          }} /></th> */}
                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "139px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p
                                                                            className="mb-0"
                                                                            style={{
                                                                                marginLeft: "2px",
                                                                                display: "inline",
                                                                            }}
                                                                        >
                                                                            Domain
                                                                        </p>
                                                                        <Popconfirm
                                                                            placement="bottom"
                                                                            title={
                                                                                <Input
                                                                                    placeholder="Domain"
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "Domain"
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>
                                                                    {/* <Input placeholder='Compliance Status' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Compliance_Status')
                                                                          }} /> */}
                                                                </th>
                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "78px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="mb-0"
                                                                            style={{
                                                                                marginLeft: "20%",
                                                                                display: "inline",
                                                                            }}
                                                                        >
                                                                            Start Date
                                                                        </p>{" "}
                                                                        <Popconfirm
                                                                            placement="bottom"
                                                                            title={
                                                                                <Input
                                                                                    placeholder="Start Date"
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "Start Date"
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>

                                                                    {/* <Input placeholder='Due Date' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Due_Date')
                                                                          }} /> */}
                                                                </th>
                                                                <th
                                                                    className="ant-table-cell"
                                                                    style={{ width: "78px" }}
                                                                >
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="mb-0"
                                                                            style={{
                                                                                marginLeft: "12%",
                                                                                display: "inline",
                                                                            }}
                                                                        >
                                                                            Due Date
                                                                        </p>
                                                                        <Popconfirm
                                                                            placement="bottom"
                                                                            title={
                                                                                <Input
                                                                                    placeholder="Due Date"
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "Due Date"
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>


                                                                    {/* <Input placeholder='Completion Date' onKeyUp={(e) => {
                                                                              compliancetaskfilter(e.target.value, 'Completion_Date')
                                                                          }} /> */}
                                                                </th>
                                                                <th className="ant-table-cell"
                                                                    style={{ width: "90px" }}>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <p className="mb-0"
                                                                            style={{
                                                                                marginLeft: "12%",
                                                                                display: "inline",
                                                                            }}
                                                                        >
                                                                            Days & Timeline
                                                                        </p>
                                                                        <Popconfirm
                                                                            placement="bottomLeft"
                                                                            title={
                                                                                <Input
                                                                                    placeholder="Days & Timeline"
                                                                                    onKeyUp={(e) => {
                                                                                        compliancetaskfilter(
                                                                                            e.target.value,
                                                                                            "Days & Timeline"
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            }
                                                                        >
                                                                            <SearchOutlined
                                                                                style={{ float: 'right', marginTop: '4px' }}
                                                                            />
                                                                        </Popconfirm>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ant-table-tbody">
                                                            {filteredArrayState &&
                                                                filteredArrayState.length > 0 ? (
                                                                filteredArrayState.map((list, ii) => {
                                                                    return (
                                                                        <Fragment key={ii}>
                                                                            {tempFilterArray.includes(
                                                                                list[0].unit_name
                                                                            ) == true ? (
                                                                                <tr className="bg-lightdark">
                                                                                    <td colSpan={7}>
                                                                                        <div
                                                                                            className='bg-white shadow-sm border-radius-reports'
                                                                                            style={{ padding: "0" }}
                                                                                        >
                                                                                            {list[0].unit_name}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {temp && temp.length > 0 ? (
                                                                                temp.map((itemdata, i) => {
                                                                                    let data = _.find(
                                                                                        itemdata.child,
                                                                                        {
                                                                                            unit_name:
                                                                                                list[0].unit_name,
                                                                                        }
                                                                                    );
                                                                                    if (data != undefined) {
                                                                                        if (
                                                                                            data.assignee_name ==
                                                                                            itemdata.parentData
                                                                                                .assignee_name
                                                                                        ) {
                                                                                            return (
                                                                                                <Fragment key={i}>
                                                                                                    <tr className="ant-table-row ant-table-row-level-0">
                                                                                                        <td
                                                                                                            colSpan={12}
                                                                                                            className="ant-table-cell"
                                                                                                        >
                                                                                                            <b>
                                                                                                                {
                                                                                                                    itemdata
                                                                                                                        .parentData
                                                                                                                        .assignee_name
                                                                                                                }
                                                                                                            </b>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    {itemdata.child.map(
                                                                                                        (items, l) => {
                                                                                                            console.log(items, "itemssssss");
                                                                                                            let text = items.delayed_by

                                                                                                            return (
                                                                                                                <tr
                                                                                                                    className="ant-table-row ant-table-row-level-0"
                                                                                                                    key={l}
                                                                                                                    style={{
                                                                                                                        backgroundColor:
                                                                                                                            ((valueModal && valueModal.uniqueId == items.uniqueId && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_four'))
                                                                                                                                ? '#ffff66' : ((valueModal && valueModal.uniqueId == items.uniqueId && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_three')) ? '#DDA0DD' : ((valueModal && valueModal.uniqueId == items.uniqueId && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_one')) ? '#F0F8FF' : ((valueModal && valueModal.uniqueId == items.uniqueId && (showValueModal == true)) && (localStorage.getItem("currentTheme") == 'theme_two')) ? '#D3D3D3' : ''
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <td className="ant-table-cell">
                                                                                                                        <center>
                                                                                                                            {counts + 1}
                                                                                                                            <span
                                                                                                                                hidden
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    (counts =
                                                                                                                                        counts +
                                                                                                                                        1)
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                            {items.compliance_task_frequency ==
                                                                                                                                "Periodical" ? (
                                                                                                                                <p>
                                                                                                                                    <i
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "green",
                                                                                                                                        }}
                                                                                                                                        title={
                                                                                                                                            items.compliance_task_frequency
                                                                                                                                        }
                                                                                                                                        className="bi bi-square-fill"
                                                                                                                                    ></i>
                                                                                                                                </p>
                                                                                                                            ) : items.compliance_task_frequency ==
                                                                                                                                "FlexiReview" ? (
                                                                                                                                <p>
                                                                                                                                    <i
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "pink",
                                                                                                                                        }}
                                                                                                                                        title={
                                                                                                                                            items.compliance_task_frequency
                                                                                                                                        }
                                                                                                                                        className="bi bi-square-fill"
                                                                                                                                    ></i>
                                                                                                                                </p>
                                                                                                                            ) : items.compliance_task_frequency ==
                                                                                                                                "Review" ? (
                                                                                                                                <p>
                                                                                                                                    <i
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "orange",
                                                                                                                                        }}
                                                                                                                                        title={
                                                                                                                                            items.compliance_task_frequency
                                                                                                                                        }
                                                                                                                                        className="bi bi-square-fill"
                                                                                                                                    ></i>
                                                                                                                                </p>
                                                                                                                            ) : items.compliance_task_frequency ==
                                                                                                                                "On Occurrence" ? (
                                                                                                                                <p>
                                                                                                                                    <i
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "yellow",
                                                                                                                                        }}
                                                                                                                                        title={
                                                                                                                                            items.compliance_task_frequency
                                                                                                                                        }
                                                                                                                                        className="bi bi-square-fill"
                                                                                                                                    ></i>
                                                                                                                                </p>
                                                                                                                            ) : (
                                                                                                                                <p>
                                                                                                                                    <i
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "red",
                                                                                                                                        }}
                                                                                                                                        title={
                                                                                                                                            items.compliance_task_frequency
                                                                                                                                        }
                                                                                                                                        className="bi bi-square-fill"
                                                                                                                                    ></i>
                                                                                                                                </p>
                                                                                                                            )}
                                                                                                                        </center>
                                                                                                                    </td>
                                                                                                                    {/* <td>
                                                                                                                <Fragment>
                                                                                                                    <center>
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            title="Click here to Task Recall"
                                                                                                                            name={
                                                                                                                                "checked"
                                                                                                                            }
                                                                                                                            onClick={(
                                                                                                                                e
                                                                                                                            ) => {
                                                                                                                                let checked =
                                                                                                                                    e
                                                                                                                                        .target
                                                                                                                                        .checked;
                                                                                                                                addSelectedDataValues(
                                                                                                                                    checked,
                                                                                                                                    items.compliance_history_id,
                                                                                                                                    items.parentId
                                                                                                                                );
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </center>
                                                                                                                </Fragment>
                                                                                                            </td> */}
                                                                                                                    <td className='pointer'>
                                                                                                                        <>
                                                                                                                            <span
                                                                                                                                onClick={() => {
                                                                                                                                    // console.log(record, "777777");
                                                                                                                                    setIndex(index)
                                                                                                                                    setNextData(itemdata.child[l + 1])
                                                                                                                                    showValue()
                                                                                                                                    setValueModal({
                                                                                                                                        domain_id: items.domain_id,
                                                                                                                                        start_date: items.start_date,
                                                                                                                                        uploaded_document: items.uploaded_documents,
                                                                                                                                        actiondata: items.action,
                                                                                                                                        approve_status: items.approve_status,
                                                                                                                                        unit_id: items.unit_id,
                                                                                                                                        completion_date: items.completion_date,
                                                                                                                                        remarks: items.remarks,
                                                                                                                                        description: items.description,
                                                                                                                                        compliance_history_id: items.compliance_history_id,
                                                                                                                                        unit: items.unit_name,
                                                                                                                                        due_date: items.due_date,
                                                                                                                                        compliance_task: items.compliance_name,
                                                                                                                                        compliance_frequency: items.compliance_task_frequency,
                                                                                                                                        document_issued_date: items.completion_date,
                                                                                                                                        document_reference_number: items.doc_ref_num,
                                                                                                                                        next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                        history_count: items.history_count,
                                                                                                                                        occurrence_remarks: items.occurrence_remarks,
                                                                                                                                        uniqueId: items.uniqueId
                                                                                                                                    })
                                                                                                                                    setValueForFileUpload({
                                                                                                                                        d_id: items.domain_id,
                                                                                                                                        u_id: items.unit_id,
                                                                                                                                        start_date: items.start_date,
                                                                                                                                    })
                                                                                                                                    // window.scrollTo(0, 0);
                                                                                                                                }}>{items.statu}
                                                                                                                                {/* <div className="mb-3">
                                <Tooltip title={record.description}>
                                    <p style={{ whiteSpace: 'unset' }}><InfoCircleOutlined />&nbsp;{record.compliance_name}</p>
                                </Tooltip>
                            </div> */}
                                                                                                                            </span>
                                                                                                                            <div className="approve-task-reacall-tooltip">
                                                                                                                                <Tooltip placement="topRight" title={`${items.description}`} > <ExclamationCircleTwoTone /> </Tooltip><span onClick={() => {
                                                                                                                                    setNextData(itemdata.child[l + 1])
                                                                                                                                    showValue()
                                                                                                                                    setValueModal({
                                                                                                                                        domain_id: items.domain_id,
                                                                                                                                        start_date: items.start_date,
                                                                                                                                        uploaded_document: items.uploaded_documents,
                                                                                                                                        actiondata: items.action,
                                                                                                                                        due_date: items.due_date,
                                                                                                                                        approve_status: items.approve_status,
                                                                                                                                        unit_id: items.unit_id,
                                                                                                                                        completion_date: items.completion_date,
                                                                                                                                        remarks: items.remarks,
                                                                                                                                        description: items.description,
                                                                                                                                        compliance_history_id: items.compliance_history_id,
                                                                                                                                        unit: items.unit_name,
                                                                                                                                        compliance_task: items.compliance_name,
                                                                                                                                        compliance_frequency: items.compliance_task_frequency,
                                                                                                                                        document_issued_date: items.completion_date,
                                                                                                                                        document_reference_number: items.doc_ref_num,
                                                                                                                                        next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                        history_count: items.history_count,
                                                                                                                                        occurrence_remarks: items.occurrence_remarks,
                                                                                                                                        uniqueId: items.uniqueId
                                                                                                                                    })
                                                                                                                                    setValueForFileUpload({
                                                                                                                                        d_id: items.domain_id,
                                                                                                                                        u_id: items.unit_id,
                                                                                                                                        start_date: items.start_date,
                                                                                                                                    })
                                                                                                                                    // window.scrollTo(0, 0);
                                                                                                                                }}>{items.compliance_name}</span>
                                                                                                                            </div>

                                                                                                                        </>
                                                                                                                    </td>
                                                                                                                    {/* <td>
                                                                                                                  {items.assignee_name}
                                                                                                              </td> */}
                                                                                                                    <td className='pointer'>
                                                                                                                        <span onClick={() => {
                                                                                                                            setNextData(itemdata.child[l + 1])
                                                                                                                            showValue()
                                                                                                                            setValueModal({
                                                                                                                                domain_id: items.domain_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                                uploaded_document: items.uploaded_documents,
                                                                                                                                actiondata: items.action,
                                                                                                                                approve_status: items.approve_status,
                                                                                                                                unit_id: items.unit_id,
                                                                                                                                completion_date: items.completion_date,
                                                                                                                                due_date: items.due_date,
                                                                                                                                remarks: items.remarks,
                                                                                                                                description: items.description,
                                                                                                                                compliance_history_id: items.compliance_history_id,
                                                                                                                                unit: items.unit_name,
                                                                                                                                compliance_task: items.compliance_name,
                                                                                                                                compliance_frequency: items.compliance_task_frequency,
                                                                                                                                document_issued_date: items.completion_date,
                                                                                                                                document_reference_number: items.doc_ref_num,
                                                                                                                                next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                history_count: items.history_count,
                                                                                                                                occurrence_remarks: items.occurrence_remarks,
                                                                                                                                uniqueId: items.uniqueId
                                                                                                                            })
                                                                                                                            setValueForFileUpload({
                                                                                                                                d_id: items.domain_id,
                                                                                                                                u_id: items.unit_id,
                                                                                                                                start_date: items.start_date,
                                                                                                                            })
                                                                                                                            // window.scrollTo(0, 0);
                                                                                                                        }}>{
                                                                                                                                items.domain_name
                                                                                                                            }</span>
                                                                                                                    </td>
                                                                                                                    <td className='pointer'>
                                                                                                                        <center>
                                                                                                                            <span
                                                                                                                                onClick={() => {
                                                                                                                                    setNextData(itemdata.child[l + 1])
                                                                                                                                    showValue()
                                                                                                                                    setValueModal({
                                                                                                                                        domain_id: items.domain_id,
                                                                                                                                        start_date: items.start_date,
                                                                                                                                        uploaded_document: items.uploaded_documents,
                                                                                                                                        actiondata: items.action,
                                                                                                                                        approve_status: items.approve_status,
                                                                                                                                        unit_id: items.unit_id,
                                                                                                                                        due_date: items.due_date,
                                                                                                                                        completion_date: items.completion_date,
                                                                                                                                        remarks: items.remarks,
                                                                                                                                        description: items.description,
                                                                                                                                        compliance_history_id: items.compliance_history_id,
                                                                                                                                        unit: items.unit_name,
                                                                                                                                        compliance_task: items.compliance_name,
                                                                                                                                        compliance_frequency: items.compliance_task_frequency,
                                                                                                                                        document_issued_date: items.completion_date,
                                                                                                                                        document_reference_number: items.doc_ref_num,
                                                                                                                                        next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                        history_count: items.history_count,
                                                                                                                                        occurrence_remarks: items.occurrence_remarks,
                                                                                                                                        uniqueId: items.uniqueId
                                                                                                                                    })
                                                                                                                                    setValueForFileUpload({
                                                                                                                                        d_id: items.domain_id,
                                                                                                                                        u_id: items.unit_id,
                                                                                                                                        start_date: items.start_date,
                                                                                                                                    })
                                                                                                                                    // window.scrollTo(0, 0);

                                                                                                                                }}>{items.start_date}
                                                                                                                            </span>
                                                                                                                        </center>
                                                                                                                    </td>

                                                                                                                    <td className='pointer'>
                                                                                                                        <center>
                                                                                                                            <span
                                                                                                                                onClick={() => {
                                                                                                                                    setNextData(itemdata.child[l + 1])
                                                                                                                                    showValue()
                                                                                                                                    setValueModal({
                                                                                                                                        domain_id: items.domain_id,
                                                                                                                                        start_date: items.start_date,
                                                                                                                                        uploaded_document: items.uploaded_documents,
                                                                                                                                        actiondata: items.action,
                                                                                                                                        approve_status: items.approve_status,
                                                                                                                                        due_date: items.due_date,
                                                                                                                                        unit_id: items.unit_id,
                                                                                                                                        completion_date: items.completion_date,
                                                                                                                                        remarks: items.remarks,
                                                                                                                                        description: items.description,
                                                                                                                                        compliance_history_id: items.compliance_history_id,
                                                                                                                                        unit: items.unit_name,
                                                                                                                                        compliance_task: items.compliance_name,
                                                                                                                                        compliance_frequency: items.compliance_task_frequency,
                                                                                                                                        document_issued_date: items.completion_date,
                                                                                                                                        document_reference_number: items.doc_ref_num,
                                                                                                                                        next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                        history_count: items.history_count,
                                                                                                                                        occurrence_remarks: items.occurrence_remarks,
                                                                                                                                        uniqueId: items.uniqueId
                                                                                                                                    })
                                                                                                                                    setValueForFileUpload({
                                                                                                                                        d_id: items.domain_id,
                                                                                                                                        u_id: items.unit_id,
                                                                                                                                        start_date: items.start_date,
                                                                                                                                    })
                                                                                                                                    // window.scrollTo(0, 0);
                                                                                                                                }}> {items && items.extend_due_date !== "None" ? <span>
                                                                                                                                    <Tooltip title={"The Due Date has been extended to " + items.extend_due_date} >
                                                                                                                                        {items.due_date}  <ExclamationCircleTwoTone />
                                                                                                                                    </Tooltip> </span> : <span>{items.due_date} </span>}
                                                                                                                            </span>
                                                                                                                        </center>
                                                                                                                    </td>
                                                                                                                    <td className='pointer'>
                                                                                                                        <center>
                                                                                                                            <span
                                                                                                                                onClick={() => {
                                                                                                                                    setNextData(itemdata.child[l + 1])
                                                                                                                                    showValue()
                                                                                                                                    setValueModal({
                                                                                                                                        domain_id: items.domain_id,
                                                                                                                                        start_date: items.start_date,
                                                                                                                                        uploaded_document: items.uploaded_documents,
                                                                                                                                        actiondata: items.action,
                                                                                                                                        approve_status: items.approve_status,
                                                                                                                                        unit_id: items.unit_id,
                                                                                                                                        completion_date: items.completion_date,
                                                                                                                                        remarks: items.remarks,
                                                                                                                                        description: items.description,
                                                                                                                                        compliance_history_id: items.compliance_history_id,
                                                                                                                                        unit: items.unit_name,
                                                                                                                                        compliance_task: items.compliance_name,
                                                                                                                                        compliance_frequency: items.compliance_task_frequency,
                                                                                                                                        document_issued_date: items.completion_date,
                                                                                                                                        document_reference_number: items.doc_ref_num,
                                                                                                                                        next_due_date: items.next_due_date != null ? items.next_due_date : items.due_date,
                                                                                                                                        history_count: items.history_count,
                                                                                                                                        occurrence_remarks: items.occurrence_remarks,
                                                                                                                                        uniqueId: items.uniqueId
                                                                                                                                    })
                                                                                                                                    setValueForFileUpload({
                                                                                                                                        d_id: items.domain_id,
                                                                                                                                        u_id: items.unit_id,
                                                                                                                                        start_date: items.start_date,
                                                                                                                                    })
                                                                                                                                    // window.scrollTo(0, 0);
                                                                                                                                }}>            <span style={{ color: text.includes("Left") ? '' : '#FF0000' }}>{items.delayed_by}</span>

                                                                                                                            </span>
                                                                                                                        </center>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                                </Fragment>
                                                                                            );
                                                                                        }
                                                                                    }
                                                                                })
                                                                            ) : ii == 0 ? (
                                                                                <tr>
                                                                                    <td
                                                                                        colSpan={7}
                                                                                        style={{
                                                                                            textAlign: "center",
                                                                                            fontSize: "14px",
                                                                                            fontWeight: "bold",
                                                                                        }}
                                                                                    >
                                                                                        No Compliance Available
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                false
                                                                            )}
                                                                        </Fragment>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td
                                                                        colSpan={7}
                                                                        style={{
                                                                            textAlign: "center",
                                                                            fontSize: "14px",
                                                                            fontWeight: "bold",
                                                                        }}
                                                                    >
                                                                        No Compliance Available
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>


                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                        {showValueModal == true ?
                                            <div style={{ width: '40%' }}>
                                                <Card className='approval-unit-details' style={{ borderRadius: "15px", height: '750px', overflowY: 'scroll', borderColor: localStorage.getItem("currentTheme") == 'theme_four' ? '#FFC700' : localStorage.getItem("currentTheme") == 'theme_two' ? '#595F72' : localStorage.getItem("currentTheme") == 'theme_three' ? '#650887' : localStorage.getItem("currentTheme") == 'theme_one' ? '#5daae3' : '#5daae3 ' }}>
                                                    <span style={{ marginLeft: '95%' }}>
                                                        <CloseCircleOutlined onClick={Cancelmodal} style={{ fontSize: '20px', color: 'red' }} /></span>

                                                    <div className="col-md-12 d-flex" >
                                                        <div>
                                                            <button
                                                                type="button"
                                                                class="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                                                            >
                                                                <i
                                                                    class="fas fa-list-alt"
                                                                ></i>
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <p className='mb-1'><b>Unit Name</b></p>
                                                            <h4 className='fw-bolder'> {valueModal.unit}
                                                                <Tooltip title={list && list.length > 0 && list[0].unit_address} >
                                                                    <ExclamationCircleTwoTone />
                                                                </Tooltip>
                                                            </h4>
                                                        </div>
                                                    </div>
                                                    <div className="container py-2">
                                                        <div className="row">
                                                            <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                                                <p className="mb-1"><b>Compliance Task</b></p>
                                                                <h5>{valueModal.compliance_task}<Tooltip placement='bottom' title={valueModal.description} >
                                                                    <ExclamationCircleTwoTone />
                                                                </Tooltip></h5>
                                                            </div>
                                                            <div className="col-12  ModalCard border p-2 bg-light mb-2">
                                                                <p className="mb-1"><b>Compliance Frequency</b> - {valueModal.compliance_frequency}</p>
                                                                {/* <h5>{valueModal.compliance_frequency}</h5> */}
                                                            </div>
                                                            <div className="col-12 ModalCard  border p-2 bg-light mb-2">
                                                                <p className="mb-1"><b>Document Reference Number</b></p>
                                                                <h5>{valueModal.document_reference_number}</h5>
                                                            </div>
                                                            <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                                                <p className="mb-1"><b>Uploaded Documents</b></p>
                                                                {valueModal.uploaded_document != null ?
                                                                    valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                                                                        return (
                                                                            <div className='d-flex justify-content-between pb-1'> <h5 className='mb-0 pb-0'> {item}</h5>
                                                                                <span onClick={(e) => {
                                                                                    const remarkapi = [
                                                                                        authtoken,
                                                                                        {
                                                                                            "session_token": authtoken,
                                                                                            "request": [
                                                                                                "DownloadFile",
                                                                                                {
                                                                                                    "le_id": entityid,
                                                                                                    "c_id": countryidd,
                                                                                                    "d_id": valueModal.domain_id,
                                                                                                    "u_id": Number(valueModal.unit_id),
                                                                                                    "start_date": valueModal.start_date,
                                                                                                    "file_name": valueModal.uploaded_document[i]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                    if (entityid != "null") {
                                                                                        getdownloadpath({
                                                                                            payload: remarkapi,
                                                                                            paramid: paramid,
                                                                                        })
                                                                                    }
                                                                                }} className={'btn btn-primary p-2 py-0 me-2 ' + localStorage.getItem('currentTheme')}> <DownloadOutlined

                                                                                    /> Download</span>
                                                                            </div>)
                                                                    }) : ""}
                                                            </div>
                                                            <div className="col-md-12 ModalCard border  p-2 bg-light mb-2">
                                                                <p className="mb-1"><b>Task Completion Date /</b></p>
                                                                <p>  <b> Document Issued Date</b> -  {valueModal.document_issued_date}</p>

                                                            </div>
                                                            <div className="col-md-12 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                                                <p className="mb-1 me-2"><b>Status:</b></p>
                                                                <span class="">
                                                                    Submitted
                                                                </span>
                                                            </div>
                                                            {valueModal.compliance_frequency == 'Periodical' || valueModal.compliance_frequency == 'FlexiReview' || valueModal.compliance_frequency == 'Review' ?
                                                                <>
                                                                    <div className="col-md-12 d-flex ModalCard border  p-2 bg-light mb-2">
                                                                        <p className='mb-1 me-1'><b>Validity Date</b></p>
                                                                        {edit === false ?
                                                                            <a href={() => false}>
                                                                                <span
                                                                                    onClick={() => {
                                                                                        setEdit(true)
                                                                                    }}
                                                                                    className="btn btn-sm btn-light-info text-info  d-flex align-items-center justify-content-center"
                                                                                >
                                                                                    <i className="ri-edit-line"></i>Edit
                                                                                </span>
                                                                            </a> :
                                                                            <h5>
                                                                                <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder={'DD-MMM-YYYY'} className='form-control' value={valueModal.validity_date ? moment(valueModal.validity_date) : ''} name="validity_date" id="" onChange={(dateString) => {
                                                                                    setValueModal({
                                                                                        ...valueModal,
                                                                                        validity_date: dateString
                                                                                    })
                                                                                }}

                                                                                    disabledDate={(current) => {
                                                                                        let check = current.diff(valueModal.next_due_date, 'days')
                                                                                        return !(check <= 180 && check >= -180)
                                                                                    }}
                                                                                />
                                                                            </h5>
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-12 d-flex ModalCard border  p-2 bg-light mb-2" >
                                                                        <p className='mb-1 me-1'><b>Next Due Date:</b> <span style={{ "color": "red" }}>*</span></p>
                                                                        {edit === true ?
                                                                            <h5>
                                                                                <DatePicker allowClear={false} format={'DD-MMM-YYYY'} className='form-control fw-bold' disabled={moment(valueModal.next_due_date) < moment(currentDate)} value={valueModal.next_due_date ? moment(valueModal.next_due_date) : ''} name="next_due_date" id="" onChange={(dateString) => {
                                                                                    setValueModal({
                                                                                        ...valueModal,
                                                                                        next_due_date: dateString
                                                                                    })

                                                                                }} disabledDate={(current) => {
                                                                                    return moment().add(-1, 'days') >= current
                                                                                }} />
                                                                            </h5>
                                                                            :
                                                                            <> <p>{valueModal.next_due_date}</p> </>
                                                                        }

                                                                    </div>
                                                                </>
                                                                : ''}
                                                            {valueModal.compliance_frequency == 'On Occurrence' ?
                                                                <>
                                                                    <div className="col-md-12 border ModalCard p-2 bg-light mb-2" >
                                                                        <p className="mb-1"><b>Onoccurance Remarks</b></p>
                                                                        <h5>{valueModal.occurrence_remarks}</h5>
                                                                    </div>
                                                                </>
                                                                : ''}

                                                            <div className="col-md-12 border ModalCard p-2 bg-light mb-2">
                                                                <p className="mb-1"><b>Remarks</b></p>
                                                                <h5>{valueModal.remarks}</h5>
                                                                {valueModal.history_count != 0 ?
                                                                    <>
                                                                        <Tooltip title="click to view remark history" ><ExclamationCircleTwoTone style={{ marginLeft: '5px' }} onClick={(e) => {
                                                                            showModalremark()
                                                                            setToolTip([valueModal])
                                                                            const remarkapi = [
                                                                                authtoken,
                                                                                {
                                                                                    "session_token": authtoken,
                                                                                    "request": [
                                                                                        "GetComplianceRemarksHistoryData",
                                                                                        {
                                                                                            "legal_entity_id": entityid,
                                                                                            "unit_id": Number(valueModal.unit_id),
                                                                                            "compliance_history_id": Number(valueModal.compliance_history_id)
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                            getremarkhistory({
                                                                                payload: remarkapi,
                                                                                paramid: paramid
                                                                            })
                                                                        }
                                                                        } /></Tooltip></> : ''
                                                                }
                                                            </div>
                                                            <div className="col-md-12 border ModalCard p-2 bg-light mb-2">
                                                                <p className="mb-1"><b>Action:</b><span style={{ "color": "red" }}>*</span></p>
                                                                <div className="form-group">
                                                                    <Select name="action" className='form-control' value={valueModal.action} placeholder="Select"
                                                                        style={{ width: '82%' }} onChange={(e) => {
                                                                            setValueModal({
                                                                                ...valueModal,
                                                                                action: e
                                                                            })
                                                                            setDefaultRemarks('')
                                                                        }} id="">
                                                                        {/* <Option defaultValue value="">Select</Option> */}
                                                                        <Option value="Approve">Approve</Option>
                                                                        <Option value="Rectify Approval">Rectify</Option>
                                                                        <Option value="Reject Approval">Reject</Option>
                                                                    </Select>
                                                                    {validator1.current.message(
                                                                        'action',
                                                                        valueModal.action,
                                                                        ['required'],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: ' Action Required'
                                                                            }
                                                                        })}
                                                                </div>
                                                                {valueModal.action === 'Rectify Approval' ?
                                                                    <div className="col-md-12 bg-light mb-2 mt-1">
                                                                        <div className="">
                                                                            <label htmlFor=""><b>Remarks: <span style={{ "color": "red" }}>*</span></b></label>


                                                                            <div className="d-flex align-items-end">
                                                                                <div className="col-md-10" style={{ paddingRight: '0px' }}>
                                                                                    <TextArea maxLength={500} name="remarks" className='form-control' placeholder="Enter Remarks" id="remarks" cols="5" rows="2" value={defaultRemarks} onChange={(e) => {
                                                                                        setDefaultRemarks(e.target.value)
                                                                                    }}></TextArea></div>
                                                                                <div className='col-md-2 p-0'>
                                                                                    <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center"
                                                                                        onClick={() => { showRemarksModal() }}>
                                                                                        <PlusCircleOutlined />
                                                                                    </span>
                                                                                    {validator1.current.message(
                                                                                        'remarks',
                                                                                        defaultRemarks,
                                                                                        ['required'],
                                                                                        {
                                                                                            className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Remarks Required'
                                                                                            }
                                                                                        })}
                                                                                </div>

                                                                            </div><span>(max 500 characters)</span>
                                                                        </div>
                                                                    </div>
                                                                    : valueModal.action === 'Reject Approval' ?
                                                                        <div className="col-md-12 bg-light mb-2 mt-1">
                                                                            <div className="">
                                                                                <label htmlFor=""><b>Remarks: <span style={{ "color": "red" }}>*</span></b></label>


                                                                                <div className="d-flex align-items-end">
                                                                                    <div className="col-md-10" style={{ paddingRight: '0px' }}>
                                                                                        <TextArea maxLength={500} name="remarks" className='form-control' placeholder="Enter Remarks" id="remarks" cols="5" rows="2" value={defaultRemarks} onChange={(e) => {
                                                                                            setDefaultRemarks(e.target.value)
                                                                                        }}></TextArea></div>
                                                                                    <div className='col-md-2 p-0'>
                                                                                        <span className="ms-1 text-info d-flex 
                            align-items-center justify-content-center" style={{ float: 'left' }}
                                                                                            onClick={() => { showRemarksModal() }}>
                                                                                            <PlusCircleOutlined />
                                                                                        </span>
                                                                                        {validator1.current.message(
                                                                                            'remarks',
                                                                                            defaultRemarks,
                                                                                            ['required'],
                                                                                            {
                                                                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Remarks Required'
                                                                                                }
                                                                                            })}
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                {valueModal.action === 'Rectify Approval' ? <div className="col-md-12 bg-light mb-2 mt-1">
                                                                    <div className="">
                                                                        <label htmlFor=""><b>Upload Documents:</b></label>


                                                                        <div className="d-flex align-items-end">
                                                                            <div>
                                                                                <label htmlFor="file-upload" className='btn btn-primary upload-btn p-1 d-flex justify-content-center align-items-center'><CloudUploadOutlined /></label>
                                                                                <input
                                                                                    id="file-upload"
                                                                                    type="file"
                                                                                    name="file"
                                                                                    className={'file-upload-ctcy'}
                                                                                    onChange={(e) => {
                                                                                        SavepastRecordOnChange(e)
                                                                                    }
                                                                                    }
                                                                                />
                                                                                <p>(Maximum 25MB per file/task)</p>
                                                                                <p> Upload Date  :{moment(currentdate).format("DD-MMM-YYYY")} </p>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div> : false}
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className={"form-actions text-center popupbtncolour " + localStorage.getItem('currentTheme')}>
                                                        <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                                            style={{ background: "#198754", borderColor: "#198754", textAlign: 'center' }}
                                                            icon={<PlayCircleOutlined />} size='default'
                                                            onClick={handleModalValue}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Card>
                                            </div> : false}
                                    </div>
                                </div>
                                <p className='mt-2'>Total Compliance : {list && list.length}</p>
                            </>
                            : null}

                        {/* <Drawer title="" onClose={Cancelmodal} className={localStorage.getItem("currentTheme") + ' resize-drawer1'} visible={showValueModal} width={500} footer={null} cancelText={false} onCancel={Cancelmodal} >
                         
                            <div className="col-md-12 d-flex" >
                                <div>
                                    <button
                                        type="button"
                                        class="btn btn-light-primary text-primary btn-circle btn-xl me-3 mb-1 pe-none"
                                    >
                                        <i
                                            class="fas fa-list-alt"
                                        ></i>
                                    </button>
                                </div>
                                <div>
                                    <p className='mb-1'><b>Unit Name</b></p>
                                    <h4 className='fw-bolder'> {valueModal.unit}&nbsp;<Tooltip title={list && list.length > 0 && list[0].unit_address} >
                                        <ExclamationCircleTwoTone />
                                    </Tooltip></h4>
                                </div>
                            </div>
                            <div className="container py-2">
                                <div className="row">
                                    <div className="col-4 ModalCard border  p-2 bg-light mb-2">
                                        <p className="mb-1"><b>Compliance Task</b></p>
                                        <h5>{valueModal.compliance_task}&nbsp;<Tooltip placement='bottom' title={valueModal.description} >
                                            <ExclamationCircleTwoTone />
                                        </Tooltip></h5>
                                    </div>
                                    <div className="col-4  ModalCard border p-2 bg-light mb-2">
                                        <p className="mb-1"><b>Compliance Frequency</b></p>
                                        <h5>{valueModal.compliance_frequency}</h5>
                                    </div>
                                    <div className="col-4 ModalCard  border p-2 bg-light mb-2">
                                        <p className="mb-1"><b>Document Reference Number</b></p>
                                        <h5>{valueModal.document_reference_number}</h5>
                                    </div>
                                    <div className="col-12 ModalCard border  p-2 bg-light mb-2">
                                        <p className="mb-1"><b>Uploaded Documents</b></p>
                                        {valueModal.uploaded_document != null ?
                                            valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                                                return (
                                                    <div className='d-flex justify-content-between pb-1'> <h5 className='mb-0 pb-0'> {item}</h5>
                                                        <span className={'btn btn-primary p-2 py-0 me-2 ' + localStorage.getItem('currentTheme')}> <DownloadOutlined
                                                            onClick={(e) => {
                                                                const remarkapi = [
                                                                    authtoken,
                                                                    {
                                                                        "session_token": authtoken,
                                                                        "request": [
                                                                            "DownloadFile",
                                                                            {
                                                                                "le_id": entityid,
                                                                                "c_id": countryidd,
                                                                                "d_id": valueModal.domain_id,
                                                                                "u_id": Number(valueModal.unit_id),
                                                                                "start_date": valueModal.start_date,
                                                                                "file_name": valueModal.uploaded_document[i]
                                                                            }
                                                                        ]
                                                                    }
                                                                ]
                                                                if (entityid != "null") {
                                                                    getdownloadpath({
                                                                        payload: remarkapi,
                                                                        paramid: paramid,
                                                                    })
                                                                }
                                                            }}
                                                        /> Download</span>
                                                    </div>)
                                            }) : ""}
                                    </div>
                                    <div className="col-md-6 ModalCard border  p-2 bg-light mb-2">
                                        <p className="mb-1"><b>Task Completion Date/Document Issued Date</b></p>
                                        <h5>{valueModal.document_issued_date}</h5>
                                    </div>
                                    <div className="col-md-6 d-flex align-items-center ModalCard border p-2 bg-light mb-2" >
                                        <p className="mb-1 me-2"><b>Status:</b></p>
                                        <button type="button" class="btn text-success btn-rounded bg-light-success">
                                            Submitted
                                        </button>
                                    </div>
                                    {valueModal.compliance_frequency == 'Periodical' || valueModal.compliance_frequency == 'FlexiReview' || valueModal.compliance_frequency == 'Review' ?
                                        <>
                                            <div className="col-md-6 ModalCard border  p-2 bg-light mb-2">
                                                <p className='mb-1'><b>Validity Date</b></p>
                                                {edit === false ?
                                                    <a href={() => false}>
                                                        <span
                                                            onClick={() => {
                                                                setEdit(true)
                                                            }}
                                                            className="btn btn-sm btn-light-info text-info col-4 d-flex align-items-center justify-content-center"
                                                        >
                                                            <i className="ri-edit-line"></i>Edit
                                                        </span>
                                                    </a> :
                                                    <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder={'DD-MMM-YYYY'} className='form-control' value={valueModal.validity_date ? moment(valueModal.validity_date) : ''} name="validity_date" id="" onChange={(dateString) => {
                                                        setValueModal({
                                                            ...valueModal,
                                                            validity_date: dateString
                                                        })
                                                    }}

                                                        disabledDate={(current) => {
                                                            let check = current.diff(valueModal.next_due_date, 'days')
                                                            return !(check <= 180 && check >= -180)
                                                        }}
                                                    />
                                                }
                                            </div>
                                            <div className="col-md-6 ModalCard border  p-2 bg-light mb-2" >
                                                <p className='mb-1'><b>Next Due Date:</b> <span style={{ "color": "red" }}>*</span></p>
                                                {edit === true ?
                                                    <h5>
                                                        <DatePicker allowClear={false} format={'DD-MMM-YYYY'} className='form-control fw-bold' disabled={moment(valueModal.next_due_date) < moment(currentDate)} value={valueModal.next_due_date ? moment(valueModal.next_due_date) : ''} name="next_due_date" id="" onChange={(dateString) => {
                                                            setValueModal({
                                                                ...valueModal,
                                                                next_due_date: dateString
                                                            })

                                                        }} disabledDate={(current) => {
                                                            return moment().add(-1, 'days') >= current
                                                        }} />
                                                    </h5>
                                                    :
                                                    <> <p>{valueModal.next_due_date}</p> </>
                                                }

                                            </div>
                                        </>
                                        : ''}
                                    {valueModal.compliance_frequency == 'On Occurrence' ?
                                        <>
                                            <div className="col-md-6 border ModalCard p-2 bg-light mb-2" >
                                                <p className="mb-1"><b>Onoccurance Remarks</b></p>
                                                <h5>{valueModal.occurrence_remarks}</h5>
                                            </div>
                                        </>
                                        : ''}

                                    <div className="col-md-6 border ModalCard p-2 bg-light mb-2">
                                        <p className="mb-1"><b>Remarks</b></p>
                                        <h5>{valueModal.remarks}</h5>
                                        {valueModal.history_count != 0 ?
                                            <>
                                                <Tooltip title="click to view remark history" ><ExclamationCircleTwoTone style={{ marginLeft: '5px' }} onClick={(e) => {
                                                    showModalremark()
                                                    setToolTip([valueModal])
                                                    const remarkapi = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetComplianceRemarksHistoryData",
                                                                {
                                                                    "legal_entity_id": entityid,
                                                                    "unit_id": Number(valueModal.unit_id),
                                                                    "compliance_history_id": Number(valueModal.compliance_history_id)
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                    getremarkhistory({
                                                        payload: remarkapi,
                                                        paramid: paramid
                                                    })
                                                }
                                                } /></Tooltip></> : ''
                                        }
                                    </div>
                                    <div className="col-md-6 border ModalCard p-2 bg-light mb-2">
                                        <p className="mb-1"><b>Action:</b><span style={{ "color": "red" }}>*</span></p>
                                        <div className="form-group">
                                            <Select name="action" className='form-control' value={valueModal.action} placeholder="Select" onChange={(e) => {
                                                setValueModal({
                                                    ...valueModal,
                                                    action: e
                                                })
                                                setDefaultRemarks('')
                                            }} id="">
                                                {/* <Option defaultValue value="">Select</Option> */}
                        {/* <Option value="Approve">Approve</Option>
                                                <Option value="Rectify Approval">Rectify</Option>
                                                <Option value="Reject Approval">Reject</Option>
                                            </Select>
                                            {validator1.current.message(
                                                'action',
                                                valueModal.action,
                                                ['required'],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: ' Action Required'
                                                    }
                                                })}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="form-actions text-center popupbtncolour">
                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                    style={{ background: "#198754", borderColor: "#198754", textAlign: 'center' }}
                                    icon={<PlayCircleOutlined />} size='default'
                                    onClick={handleModalValue}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Drawer> */}

                        {/* <Drawer title="" placement='right'
                            closable={false} width={600}
                            height={'100%'} visible={showValueModal} footer={null} cancelText={false} onClose={Cancelmodal} >

                            <div className="col-md-12" >
                                <div className="row" >
                                    <div className="col-md-6" >
                                        <label htmlFor=""><h5>Unit Name:</h5></label>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={{ float: 'left' }}><Tooltip title={list && list.length > 0 && list[0].unit_address} >
                                            <ExclamationCircleTwoTone />
                                        </Tooltip> &nbsp;{valueModal.unit}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor=""><h5>Compliance Task:</h5></label>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={{ float: 'left' }}><Tooltip placement='bottom' title={valueModal.description} >
                                            <ExclamationCircleTwoTone />
                                        </Tooltip> &nbsp;{valueModal.compliance_task}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor=""><h5>Compliance Frequency:</h5></label>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={{ float: 'left' }}>{valueModal.compliance_frequency}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor=""><h5>Uploaded Documents:</h5></label>
                                    </div>
                                    <div className="col-md-6">
                                        {valueModal.uploaded_document != null ?
                                            valueModal.uploaded_document.length > 0 && valueModal.uploaded_document.map((item, i) => {
                                                return (
                                                    <> < p style={{ float: 'left' }}> {item} <span> <DownloadOutlined
                                                        onClick={(e) => {
                                                            const remarkapi = [
                                                                authtoken,
                                                                {
                                                                    "session_token": authtoken,
                                                                    "request": [
                                                                        "DownloadFile",
                                                                        {
                                                                            "le_id": entityid,
                                                                            "c_id": countryidd,
                                                                            "d_id": valueModal.domain_id,
                                                                            "u_id": Number(valueModal.unit_id),
                                                                            "start_date": valueModal.start_date,
                                                                            "file_name": valueModal.uploaded_document[i]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                            if (entityid != "null") {
                                                                getdownloadpath({
                                                                    payload: remarkapi,
                                                                    paramid: paramid,
                                                                })
                                                            }
                                                        }}
                                                        style={{ color: "blue" }} /></span> </p>
                                                       
                                                    </>)
                                            }) : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginTop: "10px" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor=""><h5>Task Completion Date/Document Issued Date:</h5></label>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={{ float: 'left' }}>{valueModal.document_issued_date}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginTop: "10px" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor=""><h5>Document Reference Number:</h5></label>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={{ float: 'left' }}>{valueModal.document_reference_number}</p>
                                    </div>
                                </div>
                            </div>
                            {valueModal.compliance_frequency == 'Periodical' || valueModal.compliance_frequency == 'FlexiReview' || valueModal.compliance_frequency == 'Review' ?
                                <>
                                    <div className="col-md-12" style={{ marginTop: "10px" }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor=""><h5>Validity Date:</h5></label>
                                            </div>
                                            <div className="col-md-6">
                                                {edit === false ?
                                                    <a href={() => false}>
                                                        <span
                                                            className="btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                                        >
                                                            <i className="ri-edit-line" onClick={() => {
                                                                setEdit(true)
                                                            }}></i>
                                                        </span>
                                                    </a> :
                                                    <DatePicker allowClear={false} format={'DD-MMM-YYYY'} placeholder={'DD-MMM-YYYY'} className='form-control' value={valueModal.validity_date ? moment(valueModal.validity_date) : ''} name="validity_date" id="" onChange={(dateString) => {
                                                        setValueModal({
                                                            ...valueModal,
                                                            validity_date: dateString
                                                        })
                                                    }} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ marginTop: "10px" }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor=""><h5>Next Due Date: <span style={{ "color": "red" }}>*</span></h5></label>
                                            </div>
                                            <div className="col-md-6">
                                                {edit === true ?
                                                    <DatePicker allowClear={false} format={'DD-MMM-YYYY'} className='form-control' disabled={moment(valueModal.next_due_date) < moment(currentDate)} value={valueModal.next_due_date ? moment(valueModal.next_due_date) : ''} name="next_due_date" id="" onChange={(dateString) => {
                                                        setValueModal({
                                                            ...valueModal,
                                                            next_due_date: dateString
                                                        })
                                                    }} />
                                                    :
                                                    <> <p style={{ float: 'left' }}>{valueModal.next_due_date}</p> </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {valueModal.compliance_frequency == 'On Occurrence' ?
                                <>
                                    <div className="col-md-12" style={{ marginTop: "5px" }} >
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor=""><h5>Onoccurance Remarks:</h5></label>
                                            </div>
                                            <div className="col-md-6">
                                                <p style={{ float: 'left' }}>{valueModal.occurrence_remarks}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : ''}
                            <div className="col-md-12" style={{ marginTop: "5px" }} >
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor=""><h5>Status:</h5></label>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={{ float: 'left' }}>Submitted</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginTop: "5px" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor=""><h5>Remarks: </h5></label>
                                    </div>
                                    <div className="col-md-6">
                                     

                                        <span>{valueModal.remarks}</span>
                                        {valueModal.history_count != 0 ?
                                            <>
                                                <Tooltip title="click to view remark history" ><ExclamationCircleTwoTone style={{ marginLeft: '5px' }} onClick={(e) => {
                                                    showModalremark()
                                                    setToolTip([valueModal])
                                                    const remarkapi = [
                                                        authtoken,
                                                        {
                                                            "session_token": authtoken,
                                                            "request": [
                                                                "GetComplianceRemarksHistoryData",
                                                                {
                                                                    "legal_entity_id": entityid,
                                                                    "unit_id": Number(valueModal.unit_id),
                                                                    "compliance_history_id": Number(valueModal.compliance_history_id)
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                    getremarkhistory({
                                                        payload: remarkapi,
                                                        paramid: paramid
                                                    })
                                                }
                                                } /></Tooltip></> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginTop: "10px" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor=""><h5>Action:<span style={{ "color": "red" }}>*</span></h5>
                                        </label>
                                    </div>
                                    <div className="col-md-5 ">
                                        <div className="form-group">
                                            <Select name="action" className='form-control' value={valueModal.action} placeholder="Select" onChange={(e) => {
                                                setValueModal({
                                                    ...valueModal,
                                                    action: e
                                                })
                                                setDefaultRemarks('')
                                            }} id="">
                                               
                                                <Option value="Approve">Approve</Option>
                                                <Option value="Rectify Approval">Rectify</Option>
                                                <Option value="Reject Approval">Reject</Option>
                                            </Select>
                                            {validator1.current.message(
                                                'action',
                                                valueModal.action,
                                                ['required'],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: ' Action Required'
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                               
                                {valueModal.action === 'Rectify Approval' ?
                                    <div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Remarks: <span style={{ "color": "red" }}>*</span></h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className="d-flex align-items-end">
                                                        <div className="col-md-10" style={{ paddingRight: '0px' }}>
                                                            <TextArea name="remarks" className='form-control' placeholder="Enter Remarks" id="remarks" cols="3" rows="1" value={defaultRemarks} onChange={(e) => {
                                                                setDefaultRemarks(e.target.value)
                                                            }}></TextArea></div>
                                                        <div className='col-md-2 p-0'>
                                                            <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'left' }}
                                                                onClick={() => { showRemarksModal() }}>
                                                                <PlusCircleOutlined />
                                                            </span>
                                                            {validator1.current.message(
                                                                'remarks',
                                                                defaultRemarks,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Remarks Required'
                                                                    }
                                                                })}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Upload Documents: </h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <input type="file" multiple name="file" onChange={(e) => {
                                                        SavepastRecordOnChange(e)
                                                    }} id="" />
                                                    <p>(Maximum 25MB per file/task)</p>
                                                    <p> Upload Date  :{moment(currentdate).format("DD-MMM-YYYY")} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Uploaded File Size: </h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                                        return <p>{item.file_size} KB</p>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Attached Documents: </h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    {fileupload && fileupload.length > 0 && fileupload.map((item, i) => {

                                                        return <p>{item.file_name}
                                                            <Button type="primary" class='fa fa-download text-primary c-pointer'
                                                                icon={<DownloadOutlined />} size='small' onClick={(e) => { download(item.file_name) }}>
                                                            </Button> &nbsp;
                                                            <Button type="primary" class='fa fa-times text-primary removeicon'
                                                                icon={< CloseOutlined />} size='small' onClick={(e) => { Remove(item.file_name) }}>
                                                            </Button></p>
                                                    })}



                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : valueModal.action === 'Reject Approval' ?
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Remarks : </h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className="d-flex align-items-end">
                                                        <div className="col-md-10" style={{ paddingRight: '0px' }}>
                                                            <TextArea name="" className='form-control' id="remarks" cols="3" rows="1" value={defaultRemarks} onChange={(e) => {
                                                                setDefaultRemarks(e.target.value)
                                                            }}></TextArea>
                                                        </div><div className="col-md-2 p-0">
                                                            <span className="ms-1 text-info d-flex 
                                align-items-center justify-content-center" style={{ float: 'left', marginTop: '18px' }}
                                                                onClick={() => { showRemarksModal() }}>
                                                                <PlusCircleOutlined />
                                                            </span>
                                                            {validator1.current.message(
                                                                'remarks',
                                                                defaultRemarks,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Remarks Required'
                                                                    }
                                                                })}
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        : ''

                                }
                            </div>
                            <br />
                            <div className="form-actions text-center popupbtncolour">
                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                    style={{ background: "#198754", borderColor: "#198754", marginRight: '4%' }}
                                    icon={<PlayCircleOutlined />} size='default'
                                    onClick={handleModalValue}
                                >
                                    Submit
                                </Button>
                                <Button type="primary" shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')}
                                    icon={<CloseCircleOutlined />} size='default'
                                    onClick={Cancelmodal}
                                >Cancel</Button>
                              
                            </div>
                        </Drawer> */}
                        <Modal title="Remarks List" footer={null} visible={remarksModal} onOk={handleOk}
                            onCancel={handleCancel} className={' remarksClass  add-service-prv ' + localStorage.getItem("currentTheme")}>
                            <Table
                                // className='userprivclass'
                                size={'small'}
                                columns={column}
                                dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                                bordered
                                pagination={false}
                            // pagination={{
                            //     defaultPageSize: dataTableProperties.pagesize,
                            //     showSizeChanger: dataTableProperties.sizechanger,
                            //     pageSizeOptions: dataTableProperties.pageSizeOptions
                            // }}
                            />
                        </Modal>
                        <Modal autoFocus={false} visible={passvisible} className="newStyleModalPassword" footer={null} onCancel={passwordcancel}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-6">
                                        <label className="control-label"> <b>Password: </b><span style={{ "color": "red" }}>*</span></label>
                                        <input
                                            autoFocus={true}
                                            type="password"
                                            id="password"
                                            name='passwordvalue'
                                            autoComplete='off'
                                            placeholder="Enter password"
                                            onChange={(e) => {
                                                setpassword({
                                                    ...password,
                                                    passvalue: e.target.value
                                                })
                                            }}
                                            value={password.passvalue}
                                            style={{ "margin-top": "5px", width: '100%' }}
                                            className="form-control"
                                        />
                                        {formvalidator.current.message(
                                            'Password',
                                            password.passvalue,
                                            ['required'],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Password Required'
                                                }
                                            })}
                                    </div>
                                </div>

                            </div>
                            <br />
                            <div className={"form-actions popupbtncolour " + localStorage.getItem('currentTheme')}>
                                <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} s
                                    style={{ marginLeft: '33%' }}
                                    icon={<PlayCircleOutlined />} size='default' onClick={compliancesubmit}>Submit
                                </Button>
                            </div>
                        </Modal>

                        {valueModal.remarks != "" || null ?
                            <Modal title="Remarks History" className={' remarksClass  add-service-prv ' + localStorage.getItem("currentTheme")} visible={isModalOpenremark} footer={null} onCancel={handleCancelremark}>

                                <Table
                                    size={'small'}
                                    className={localStorage.getItem("currentTheme")}
                                    columns={columnsremark}
                                    dataSource={remarklist.remarks_history}
                                    bordered
                                    pagination={false}
                                />
                            </Modal> : ""}
                        <Modal autoFocus={false} visible={passvisible1} className="newStyleModalPassword" footer={null} onCancel={passwordcancel1}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-6">
                                        <label className="control-label"> <b>Password: </b><span style={{ "color": "red" }}>*</span></label>
                                        <input
                                            autoFocus={true}
                                            type="password"
                                            id="password"
                                            name='passwordvalue'
                                            autoComplete='off'
                                            placeholder="Enter password"
                                            onChange={(e) => {
                                                setpassword({
                                                    ...password,
                                                    passvalue: e.target.value
                                                })
                                            }}
                                            value={password.passvalue}
                                            style={{ "margin-top": "5px", width: '100%' }}
                                            className="form-control"
                                        />
                                        {formvalidator.current.message(
                                            'Password',
                                            password.passvalue,
                                            ['required'],
                                            {
                                                className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Password Required'
                                                }
                                            })}
                                    </div>
                                </div>

                            </div>
                            <br />
                            <div className={"form-actions popupbtncolour " + localStorage.getItem('currentTheme')}>
                                <Button shape="round" className={'addbutton ' + localStorage.getItem('currentTheme')} s
                                    style={{ marginLeft: '33%' }}
                                    icon={<PlayCircleOutlined />} size='default' onClick={compliancesubmit1}>Submit
                                </Button>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div >
            {tabValue == true && list.length > 0 ?
                <Stickicon />
                : false}
        </div >

    )
}


export default connect(mapStateToProps, {
    complianceApprovallist,
    getComplianceFilters,
    approveCompliance,
    GetRemarksData,
    UploadFile,
    Downloadfile,
    removedocument,
    getremarkhistory,
    getdownloadpath
})(ComplianceApproval)